const paths = [
    {
        path: '/automatic_test_list',
        title: 'AUTOMATIC_TESTS',
        name: 'AutomaticTestList',
        icon: 'mdi-settings',
        menu: 'security',
        component: () => import(/* webpackChunkName: "automaticTest" */ /* webpackMode: "lazy" */ '@/pages/automaticTests/AutomaticTestList.vue'),
    },
    {
        path: '/automatic_test_detail',
        title: 'NEW_TEST',
        name: 'AutomaticTestDetail',
        favCategory: 'security',
        component: () => import(/* webpackChunkName: "automaticTest" */ /* webpackMode: "lazy" */ '@/pages/automaticTests/AutomaticTestDetail.vue'),
    },
    {
        path: '/runner_list',
        title: 'RUNNERS',
        name: 'RunnerList',
        icon: 'mdi-settings',
        menu: 'security',
        component: () => import(/* webpackChunkName: "automaticTest" */ /* webpackMode: "lazy" */ '@/pages/automaticTests/RunnerList.vue'),
    },
    {
        path: '/runner_detail',
        title: 'RUNNER',
        name: 'RunnerDetail',
        favCategory: 'security',
        component: () => import(/* webpackChunkName: "automaticTest" */ /* webpackMode: "lazy" */ '@/pages/automaticTests/RunnerDetail.vue'),
    },
    {
        path: '/runner_results',
        title: 'RUNNER_RESULTS',
        name: 'RunnerResults',
        component: () => import(/* webpackChunkName: "automaticTest" */ /* webpackMode: "lazy" */ '@/pages/automaticTests/RunnerResults.vue'),
    },
];

export default paths;
