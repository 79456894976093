const paths = [
    {
        path: '/custom-report-list',
        name: 'CustomReportList',
        title: 'CUSTOM_REPORTS',
        icon: 'star',
        menu: 'customization',
        component: () => import(/* webpackChunkName: "customReport" */ /* webpackMode: "lazy" */ '@/pages/customReport/CustomReportList.vue'),
    },
    {
        path: '/custom-report-details',
        name: 'CustomReportDetails',
        title: 'CUSTOM_REPORT',
        favCategory: 'customization',
        component: () => import(/* webpackChunkName: "customReport" */ /* webpackMode: "lazy" */ '@/pages/customReport/CustomReportDetails.vue'),
    },
    {
        path: '/custom-report-register-list',
        name: 'CustomReportRegisterList',
        title: 'CUSTOM_REPORTS_REGISTER',
        icon: 'star',
        menu: 'customization',
        component: () => import(/* webpackChunkName: "customReport" */ /* webpackMode: "lazy" */ '@/pages/customReport/CustomReportRegisterList.vue'),
    },
    {
        path: '/custom-report-register',
        name: 'CustomReportRegister',
        title: 'CUSTOM_REPORT',
        icon: 'star',
        favCategory: 'customization',
        component: () => import(/* webpackChunkName: "customReport" */ /* webpackMode: "lazy" */ '@/pages/customReport/CustomReportRegister.vue'),
    },
    {
        path: '/custom-report-register-field',
        name: 'CustomReportRegisterField',
        title: 'CHANGE_FIELD',
        component: () => import(/* webpackChunkName: "customReport" */ /* webpackMode: "lazy" */ '@/pages/customReport/CustomReportRegisterField.vue'),
    },
    {
        path: '/custom-report-register-reports-file',
        name: 'CustomReportRegisterReportsFile',
        title: 'REPORT',
        component: () => import(/* webpackChunkName: "customReport" */ /* webpackMode: "lazy" */ '@/pages/customReport/CustomReportRegisterReportsFile.vue'),
    },
];

export default paths;
