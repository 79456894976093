import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
export class GetHelp extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarAjuda',
            type: 'SEARCH',
            fields: {
                videoLanguage: new Field({
                    name: 'LINGUAGEM_VIDEO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveHelp extends CallCommand {
    constructor() {
        super({
            name: 'SalvarAjuda',
            type: 'SAVE',
            fields: {
                helpId: new Field({
                    name: 'ID_AJUDA',
                }),
                videoTitle: new Field({
                    name: 'TITULO_VIDEO',
                }),
                videoUrl: new Field({
                    name: 'URL_VIDEO',
                }),
                screenName: new Field({
                    name: 'NOME_TELA',
                }),
                videoLanguage: new Field({
                    name: 'LINGUAGEM_VIDEO',
                }),
                videoType: new Field({
                    name: 'TIPO_VIDEO',
                }),
                entityId: new Field({
                    name: 'ID_ENTIDADE',
                }),
                openInNewTab: new Field({
                    name: 'ABRIR_NOVA_ABA',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class DeleteHelpById extends CallCommand {
    constructor() {
        super({
            name: 'ExcluirAjuda',
            type: 'DELETE',
            fields: {
                helpId: new Field({
                    name: 'ID_AJUDA',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
