import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import { parseSuccessResponse, showErrorsResponse } from '../utils';
export class SaveHardwareAlertTypeIcon extends CallCommand {
    constructor() {
        super({
            name: 'IncluirIconeTipoAlertaHardware',
            type: 'SAVE',
            fields: {
                hardwareAlertTypeId: new Field({
                    name: 'ID_TIPO_ALERTA_HARDWARE',
                }),
                filedata: new Field({
                    name: 'filedata',
                }),
                companyId: new Field({
                    name: 'ID_EMPRESA',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class DeleteHardwareAlertTypeIcon extends CallCommand {
    constructor() {
        super({
            name: 'ExcluirIconeTipoAlertaHardware',
            type: 'DELETE',
            fields: {
                hardwareAlertTypeId: new Field({
                    name: 'ID_TIPO_ALERTA_HARDWARE',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class DeleteAlertTypeIcon extends CallCommand {
    constructor() {
        super({
            name: 'ExcluirIconeTipoAlerta',
            type: 'DELETE',
            fields: {
                alertTypeId: new Field({
                    name: 'ID_TIPO_ALERTA',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveAlertTypeIcon extends CallCommand {
    constructor() {
        super({
            name: 'IncluirIconeTipoAlerta',
            type: 'SAVE',
            fields: {
                alertTypeId: new Field({
                    name: 'ID_TIPO_ALERTA',
                }),
                filedata: new Field({
                    name: 'filedata',
                }),
                companyId: new Field({
                    name: 'ID_EMPRESA',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetHardwareAlertTypeIcon extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_hardware-alert-type-icon_0',
            type: 'NONE',
            node: 'RESPONSE',
            fields: {
                hardwareAlertTypeId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetAlertTypeIcon extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarIconeTipoAlerta',
            type: 'FILE',
            config: { type: 'file' },
            fields: {
                filePath: new Field({
                    name: 'FILE_PATH',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetAlertTypesTg extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_alert-type',
            type: 'NONE',
            fields: {
                filter: new Field({
                    name: 'BODY',
                    parser: (value) => `filter=${encodeURIComponent(JSON.stringify(value))}`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetHardwareAlertTypes extends CallCommand {
    constructor() {
        super({
            name: 'get_hardware-alert-type',
            type: 'NONE',
            fields: {},
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(config, debug) {
        return super.callCommand({}, config, debug);
    }
}
export class GetHardwareAlertType extends CallCommand {
    constructor() {
        super({
            name: 'get_hardware-alert-type_0',
            type: 'NONE',
            fields: {
                hardwareAlertTypeId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
                filter: new Field({
                    name: 'BODY',
                    parser: (value) => `filter=${encodeURIComponent(JSON.stringify(value))}`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetAlertTypeById extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_alert-type_0',
            type: 'NONE',
            fields: {
                alertTypeId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
                filter: new Field({
                    name: 'BODY',
                    parser: (value) => `filter=${encodeURIComponent(JSON.stringify(value))}`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveAlertType extends CallCommand {
    constructor() {
        super({
            name: 'post_comp_alert-type',
            type: 'SAVE',
            fields: {
                alertType: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class EditAlertType extends CallCommand {
    constructor() {
        super({
            name: 'patch_comp_alert-type_0',
            type: 'SAVE',
            fields: {
                alertType: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
                alertTypeId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class DeleteAlertType extends CallCommand {
    constructor() {
        super({
            name: 'delete_comp_alert-type_0',
            type: 'DELETE',
            fields: {
                alertTypeId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
