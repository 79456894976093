const paths = [
    {
        path: '/material-application-devolution-list',
        title: 'MATERIAL_APPLICATION_DEVOLUTION_LIST',
        name: 'MaterialApplicationDevolutionList',
        icon: 'mdi-tools',
        menu: 'construction',
        component: () => import(/* webpackChunkName: "materialApplicationDevolution" */ /* webpackMode: "lazy" */ '@/pages/materialApplicationDevolution/MaterialApplicationDevolutionList.vue'),
    },
    {
        path: '/material-application-devolution-general',
        title: 'MATERIAL_APPLICATION_DEVOLUTION',
        name: 'MaterialApplicationDevolutionGeneral',
        favCategory: 'construction',
        component: () => import(/* webpackChunkName: "materialApplicationDevolution" */ /* webpackMode: "lazy" */ '@/pages/materialApplicationDevolution/MaterialApplicationDevolutionGeneral.vue'),
    },
    {
        path: '/material-application-devolution-wizard',
        title: 'MATERIAL_APPLICATION_DEVOLUTION_WIZARD',
        name: 'MaterialApplicationDevolutionWizard',
        component: () => import(/* webpackChunkName: "materialApplicationDevolution" */ /* webpackMode: "lazy" */ '@/pages/materialApplicationDevolution/MaterialApplicationDevolutionWizard.vue'),
    },
    {
        path: '/material-application-devolution-new-items',
        title: 'MATERIAL_APPLICATION_DEVOLUTION_NEW_ITEMS',
        name: 'MaterialApplicationDevolutionNewItems',
        component: () => import(/* webpackChunkName: "materialApplicationDevolution" */ /* webpackMode: "lazy" */ '@/pages/materialApplicationDevolution/MaterialApplicationDevolutionNewItems.vue'),
    },
];

export default paths;
