<template>
    <a-container ref='container'>
        <a-row>
            <a-col cols='12' align='center' justify='center'>
                <span style='font-size:50px' v-text='data' />
            </a-col>
        </a-row>
    </a-container>
</template>

<script>
    import {
        GetTicketList,
    } from '@/pages/ticket/services/request';

    export default {
        name: 'TicketsOpenMonitor',
        props: {
            /**
             * Command filter values sent from issue monitor
             * @type {Array}
             * @default {{ [] }}
             */
            _filters: {
                type: Array,
                default: () => ([]),
            },
        },
        data: function () {
            return {
                data: '',
            };
        },
        computed: {
            getTicketList: function () {
                const command = new GetTicketList();
                const values = {
                    onlyOpen: 1,
                    onlyTotals: 1,
                };
                command.values = values;
                command.initializeLoading = false;
                return command;
            },
        },
        watch: {
            _filters: {
                immediate: true,
                deep: true,
                handler: function (value) {
                    if (value) {
                        const filters = this.$lodash.cloneDeep(this._filters);
                        this.$util.loadCommandFilter(this.getTicketList, this.filtersParser(filters));
                    }
                },
            },
        },
        methods: {
            load: async function () {
                const response = await this.getTicketList.callCommand();
                this.data = response.RAIZ.TOTAIS.TOTAL_PONTOS;
            },
            filtersParser: function (filters) {
                const parser = [
                    {
                        origin: 'DATA_INICIO',
                        destination: 'DATA_RECLAMACAO',
                    },
                    {
                        origin: 'DATA_FIM',
                        destination: 'DATA_FINAL',
                    },
                    {
                        origin: 'TIPOS_OCORRENCIA',
                        destination: 'ID_TIPO_SOLICITACAO',
                    },
                    {
                        origin: 'ID_TIPO_ORIGEM_OCORRENCIA',
                        destination: 'ID_TIPO_ORIGEM_SOLICITACAO',
                    },
                    {
                        origin: 'ID_PARQUE_SERVICO',
                        destination: 'IDS_PARQUE_SERVICO',
                    },
                ];

                filters.forEach(element => {
                    const found = parser.find(e => e.origin === element.key);
                    if (found) {
                        element.key = found.destination;
                    }
                });

                return filters;
            },
        },
    };
</script>
