import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
export class GetDefaultPassword extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarPadraoSenha',
            type: 'SEARCH',
            node: 'PADROES_SENHA.PADRAO_SENHA',
            fields: {
                patternId: new Field({
                    name: 'ID_PADRAO',
                }),
            },
        });
    }
    async callCommand(param, debug, config) {
        return super.callCommand(param, debug, config);
    }
}
export class GetPasswordProgrammingPermissions extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarPermissoesProgramacaoSenha',
            type: 'SEARCH',
            node: 'PERMISSOES.PERMISSAO',
            fields: {
                passwordProgrammingId: new Field({
                    name: 'ID_PROGRAMACAO_SENHA',
                }),
            },
        });
    }
    async callCommand(param, debug, config) {
        return super.callCommand(param, debug, config);
    }
}
export class GetPasswordProgramming extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarProgramacaoSenha',
            type: 'SEARCH',
            node: 'PROGRAMACOES_SENHA.PROGRAMACAO_SENHA',
            fields: {
                passwordProgrammingId: new Field({
                    name: 'ID_PROGRAMACAO_SENHA',
                }),
            },
        });
    }
    async callCommand(param, debug, config) {
        return super.callCommand(param, debug, config);
    }
}
export class DeletePasswordProgramming extends CallCommand {
    constructor() {
        super({
            name: 'ExcluirProgramacaoSenha',
            type: 'DELETE',
            fields: {
                passwordProgrammingId: new Field({
                    name: 'ID_PROGRAMACAO_SENHA',
                }),
            },
        });
    }
    async callCommand(param, debug, config) {
        return super.callCommand(param, debug, config);
    }
}
export class DeletePasswordP extends CallCommand {
    constructor() {
        super({
            name: 'ExcluirPadraoSenha',
            type: 'DELETE',
            fields: {
                patternId: new Field({
                    name: 'ID_PADRAO',
                }),
            },
        });
    }
    async callCommand(param, debug, config) {
        return super.callCommand(param, debug, config);
    }
}
export class SavePasswordProgramming extends CallCommand {
    constructor() {
        super({
            name: 'SalvarProgramacaoSenha',
            type: 'SAVE',
            fields: {
                passwordProgrammingId: new Field({
                    name: 'ID_PROGRAMACAO_SENHA',
                }),
                permissionGroupIds: new Field({
                    name: 'IDS_GRUPO_PERMISSAO',
                }),
                executionPeriod: new Field({
                    name: 'PERIODO_EXECUCAO',
                }),
                executionDay: new Field({
                    name: 'DIA_DO_MES_EXECUCAO',
                }),
                executionTime: new Field({
                    name: 'HORA_DO_MES_EXECUCAO',
                }),
            },
        });
    }
    async callCommand(param, debug, config) {
        return super.callCommand(param, debug, config);
    }
}
export class GetGroups extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarGruposPermissoes',
            type: 'SEARCH',
            node: 'GRUPOS_PERMISSOES.GRUPO_PERMISSOES',
            fields: {
                groupType: new Field({
                    name: 'TIPO_GRUPO_PERMISSOES',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetPasswordValidity extends CallCommand {
    constructor() {
        super({
            name: 'VerificaValidadeSenha',
            type: 'SEARCH',
            node: 'SENHA_VALIDA',
            fields: {
                userId: new Field({
                    name: 'ID_USUARIO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SavePasswordValidation extends CallCommand {
    constructor() {
        super({
            name: 'RevalidarSenha',
            type: 'SAVE',
            fields: {
                userId: new Field({
                    name: 'ID_USUARIO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
