/* eslint-disable no-console */
const colors = {
    teal: 'font-weight: bold; color: #5FB3B3;',
    gray: 'font-weight: bold; color: #afafaf;',
    blue: 'font-weight: bold; color: #6699CC;',
    error: 'font-weight: bold; color: #EC5f67;',
    success: 'font-weight: bold; color: #99C794;',
    warning: 'font-weight: bold; color: #F3BC47;',
};

const log = {
    separator: function () {
        console.log('%c#####################', colors.gray);
    },
    logDefault: function (value) {
        console.log(value);
    },
    logError: function (value) {
        console.log(`%c${value}`, colors.error);
    },
    logInfo: function (value) {
        console.log(`%c${value}`, colors.blue);
    },
    logSuccess: function (value) {
        console.log(`%c${value}`, colors.success);
    },
    logWarning: function (value) {
        console.log(`%c${value}`, colors.warning);
    },
};

export default log;
