import { ISSUE_PANEL_MODES } from '@/pages/issue/services/consts';


const paths = [
    {
        path: '/issue-panel',
        title: 'ISSUE_PANEL',
        name: 'IssuePanel',
        icon: 'fa fa-hard-hat',
        menu: 'maintenance',
        component: () => import(/* webpackChunkName: "issue" */ /* webpackMode: "lazy" */ '@/pages/issue/IssuePanel.vue'),
    },
    {
        path: '/issue-to-authorize-list',
        title: 'ISSUE_TO_AUTHORIZE',
        name: 'IssueToAuthorizeList',
        icon: 'fa fa-hard-hat',
        menu: 'maintenance',
        categories: ['configurations'],
        props: {
            _mode: ISSUE_PANEL_MODES.AUTHORIZE_ISSUE,
        },
        component: () => import(/* webpackChunkName: "issue" */ /* webpackMode: "lazy" */ '@/pages/issue/IssuePanel.vue'),
    },
    {
        path: '/issue-maintenance-preventive',
        title: 'MAINTENANCE_PREVENTIVE',
        name: 'IssueMaintenancePreventive',
        icon: 'fa fa-hard-hat',
        menu: 'maintenance',
        categories: ['configurations'],
        props: {
            _mode: ISSUE_PANEL_MODES.MAINTENANCE_PREVENTIVE,
        },
        component: () => import(/* webpackChunkName: "issue" */ /* webpackMode: "lazy" */ '@/pages/issue/IssuePanel.vue'),
    },
    {
        path: '/issue-project-list',
        title: 'PROJECT_ISSUES',
        name: 'IssueProjectList',
        icon: 'fa fa-hard-hat',
        menu: 'maintenance',
        categories: ['configurations'],
        component: () => import(/* webpackChunkName: "issue" */ /* webpackMode: "lazy" */ '@/pages/issue/IssueProjectList.vue'),
    },
    {
        path: '/grouped-issue-referral',
        title: 'GROUPED_ISSUE_REFERRAL',
        name: 'GroupedIssueReferral',
        component: () => import(/* webpackChunkName: "issue" */ /* webpackMode: "lazy" */ '@/pages/issue/GroupedIssueReferral.vue'),
    },
    {
        path: '/issue-type-registration',
        name: 'IssueTypeRegistration',
        title: 'ISSUE_TYPE_REGISTRATION',
        component: () => import(/* webpackChunkName: "issue" */ /* webpackMode: "lazy" */ '@/pages/issue/IssueTypeRegistration.vue'),
    },
    {
        path: '/issue-type-priority-general',
        name: 'IssueTypePriorityGeneral',
        title: 'ISSUE_TYPE_PRIORITY',
        component: () => import(/* webpackChunkName: "issue" */ /* webpackMode: "lazy" */ '@/pages/issue/IssueTypePriorityGeneral.vue'),
    },
    {
        path: '/issue-type-family-registration',
        name: 'IssueTypeFamilyRegistration',
        title: 'Issue_TYPE_FAMILY_REGISTRATION',
        component: () => import(/* webpackChunkName: "issue" */ /* webpackMode: "lazy" */ '@/pages/issue/IssueTypeFamilyRegistration.vue'),
    },
    {
        path: '/issue-type-list',
        name: 'IssueTypeList',
        title: 'ISSUE_TYPES',
        icon: 'widgets',
        menu: 'ticket',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "issue" */ /* webpackMode: "lazy" */ '@/pages/issue/IssueTypeList.vue'),
    },
    {
        path: '/issue-type-table',
        name: 'IssueTypeTable',
        title: 'ISSUE_TYPES',
        component: () => import(/* webpackChunkName: "issue" */ /* webpackMode: "lazy" */ '@/pages/issue/IssueTypeTable.vue'),
    },
    {
        path: '/issue-type-priority',
        name: 'IssueTypePriority',
        title: 'ISSUE_TYPES',
        component: () => import(/* webpackChunkName: "issue" */ /* webpackMode: "lazy" */ '@/pages/issue/IssueTypePriority.vue'),
    },
    {
        path: '/issue-type',
        name: 'IssueType',
        title: 'ISSUE_TYPE',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "issue" */ /* webpackMode: "lazy" */ '@/pages/issue/IssueType.vue'),
    },
    {
        path: '/issue-authorization-details',
        title: 'ISSUE_AUTHORIZATION_DETAILS',
        name: 'IssueAuthorizationDetails',
        component: () => import(/* webpackChunkName: "issue" */ /* webpackMode: "lazy" */ '@/pages/issue/IssueAuthorizationDetails.vue'),
    },
    {
        path: '/issue-details',
        name: 'IssueDetails',
        title: 'ISSUE_DETAILS',
        favCategory: 'ticket',
        component: () => import(/* webpackChunkName: "issue" */ /* webpackMode: "lazy" */ '@/pages/issue/IssueDetails.vue'),
    },
    {
        path: '/issue-details-address',
        name: 'IssueDetailsAddress',
        title: 'EDIT_ADDRESS',
        component: () => import(/* webpackChunkName: "issue" */ /* webpackMode: "lazy" */ '@/pages/issue/IssueDetailsAddress.vue'),
    },
    {
        path: '/issue-details-use-tag',
        name: 'IssueDetailsUseTag',
        component: () => import(/* webpackChunkName: "issue" */ /* webpackMode: "lazy" */ '@/pages/issue/IssueDetailsUseTag.vue'),
    },
];

export default paths;
