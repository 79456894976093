const paths = [
    {
        path: '/attendance-deadline-per-priority',
        name: 'AttendanceDeadlinePerPriority',
        title: 'ATTENDANCE_DEADLINE_PER_PRIORITY',
        icon: 'mdi-chart-pie',
        menu: 'maintenance',
        categories: [
          'reports',
        ],
        component: () => import(/* webpackChunkName: "reportAttendances" */ /* webpackMode: "lazy" */ '@/pages/report/attendances/AttendanceDeadlinePerPriority.vue'),
    },
];

export default paths;
