import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import { showErrorsResponse, parseSuccessResponse } from '@/pages/remoteManagement/services/utils';
export class GetDeviceTypeById extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_device-type_0',
            type: 'NONE',
            fields: {
                deviceTypeId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetDeviceTypes extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_device-type',
            type: 'NONE',
            fields: {
                filter: new Field({
                    name: 'BODY',
                    parser: (value) => `filter=${encodeURIComponent(JSON.stringify(value))}`,
                    value: {},
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class EditDeviceType extends CallCommand {
    constructor() {
        super({
            name: 'patch_device-type_0',
            type: 'SAVE',
            fields: {
                deviceType: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
                deviceTypeId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
