const paths = [
    {
        path: '/items-modules-list',
        name: 'ItemsModulesList',
        title: 'MODULES_LIST',
        icon: 'fas fa-boxes',
        menu: 'ticket',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "itemsModules" */ /* webpackMode: "lazy" */ '@/pages/itemsModules/ItemsModulesList.vue'),
    },
    {
        path: '/items-modules',
        name: 'ItemsModules',
        title: 'MODULE',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "itemsModules" */ /* webpackMode: "lazy" */ '@/pages/itemsModules/ItemsModules.vue'),
    },
    {
        path: '/items-modules-general',
        name: 'ItemsModulesGeneral',
        component: () => import(/* webpackChunkName: "itemsModules" */ /* webpackMode: "lazy" */ '@/pages/itemsModules/ItemsModulesGeneral.vue'),
    },
    {
        path: '/items-modules-composition',
        name: 'ItemsModulesComposition',
        component: () => import(/* webpackChunkName: "itemsModules" */ /* webpackMode: "lazy" */ '@/pages/itemsModules/ItemsModulesComposition.vue'),
    },
    {
        path: '/items-modules-teams-types',
        name: 'ItemsModulesTeamsTypes',
        component: () => import(/* webpackChunkName: "itemsModules" */ /* webpackMode: "lazy" */ '@/pages/itemsModules/ItemsModulesTeamsTypes.vue'),
    },
    {
        path: '/items-modules-kit-minimum-stock',
        name: 'ItemsModulesKitMinimumStock',
        component: () => import(/* webpackChunkName: "itemsModules" */ /* webpackMode: "lazy" */ '@/pages/itemsModules/ItemsModulesKitMinimumStock.vue'),
    },
];

export default paths;
