<template>
    <a-container>
        <a-row no-gutters class='fill-height' align='center' justify='center'>
            <h1>
                <a-icon x-large color='primary' class='mr-2'>
                    fas fa-tools
                </a-icon>
                <span class='primary--text'>{{$t('UNDER_CONSTRUCTION')}}</span>
            </h1>
        </a-row>
        <a-row no-gutters class='fill-height' align='center' justify='center'>
            <h2 class='inactive--text'>
                {{$t('CURRENTLY_WORKING_THIS_PAGE')}}
            </h2>
        </a-row>
    </a-container>
</template>

<script>
    export default {
        name: 'AToDo',
    };
</script>
