<template>
    <vue-tel-input-vuetify
        :value='phone'
        @input='handleInput'
        v-bind='$attrs'
        :input-options='{ showDialCode }'
        :placeholder='placeholder'
        outlined
        :rules='allRules'
        :default-country='defaultCountry'
        :only-countries='onlyCountries'
        :show-dial-code='showDialCode'
        valid-characters-only
        dense />
</template>
<script>
export default {
    name: 'ATextFieldPhone',
    model: {
        prop: '_value',
        event: 'input',
    },
    components: {
        VueTelInputVuetify: () => import('../../lib/vue-tel-input-vuetify/vue-tel-input-vuetify.vue'),
    },
    props: {
        /**
         * value of v-model
         * @type {String}
         * @default {[]}
         */
        _value: {
            type: String,
            default: '',
        },
        showDialCode: {
            type: Boolean,
            required: false,
            default: false,
        },
        placeholder: {
            type: String,
            required: false,
            default: '',
        },
        validate: {
            type: Boolean,
            required: false,
            default: true,
        },
        rules: {
            type: Array,
            required: false,
            default: () => [],
        },
        unmask: {
            type: Boolean,
            required: false,
            default: true,
        },
        _defaultCountry: {
            type: String,
            required: false,
            default: undefined,
        },
        _onlyCountries: {
            type: Array,
            required: false,
            default: () => []
        },
        userCountry: {
            type: Boolean,
            required: false,
            default: true,
        }
    },
    data: function () {
        return {
            phone: this._value,
            phoneErrors: [],
            phoneObject: {},
            defaultCountry: this._defaultCountry || (this.userCountry ? this.$_aura.getUser().countryCode : undefined),
            onlyCountries: this._onlyCountries.length ? this._onlyCountries : (this.userCountry ? [this.$_aura.getUser().countryCode] : []),
            allRules: this.rules.concat(() => !this.validate || !this.phoneObject || !this.phoneObject.number || this.phoneObject.number.input === '' || this.phoneObject.isValid || this.$t('INVALID_NUMBER'))
        };
    },
    watch: {
        _value: {
            deep: true,
            immediate: true,
            handler: function (val) {
                this.phone = val;
            },
        },
        phone: {
            deep: true,
            immediate: true,
            handler: function (val) {
                if (this.unmask && this.phoneObject.number) {
                    this.$emit('input', this.phoneObject.number.e164);
                } else {
                    this.$emit('input', val);
                }
            },
        },
    },
    methods: {
        handleInput: function (phone, phoneObject) {
            this.phone = phone;
            this.phoneObject = phoneObject;
        },
    }
};
</script>
