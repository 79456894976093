const paths = [
    {
        path: '/attribute-mtz-wizzard',
        name: 'AttributeMtzWizzard',
        title: 'ATTRIBUTE_LUX',
        component: () => import(/* webpackChunkName: "assets" */ /* webpackMode: "lazy" */ '@/components/attribute/AttributeMtzWizzard.vue'),
    },
];

export default paths;
