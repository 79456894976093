const { context } = localStorage && localStorage.preferences ? JSON.parse(localStorage.preferences) : { context: '' };
const paths = context !== 'gestaosmartluz' ? [
    {
        path: '/subdivision-list',
        name: 'SubdivisionList',
        title: 'SUBDIVISIONS',
        icon: 'fa fa-map-marked',
        menu: 'analysis',
        component: () => import(/* webpackChunkName: "generalSubdivision" */ /* webpackMode: "lazy" */ '@/pages/subdivision/generalSubdivision/SubdivisionList.vue'),
    },
    {
        path: '/subdivision-technical-evaluations',
        name: 'SubdivisionTechnicalEvaluations',
        title: 'HISTORY',
        icon: 'fa fa-history',
        component: () => import(/* webpackChunkName: "generalSubdivision" */ /* webpackMode: "lazy" */ '@/pages/subdivision/generalSubdivision/SubdivisionTechnicalEvaluations.vue'),
    },
    {
        path: '/subdivision-history',
        name: 'SubdivisionHistory',
        title: 'HISTORY',
        icon: 'fa fa-history',
        component: () => import(/* webpackChunkName: "generalSubdivision" */ /* webpackMode: "lazy" */ '@/pages/subdivision/generalSubdivision/SubdivisionHistory.vue'),
    },
    {
        path: '/subdivisionNewTechnicalEvaluationDialog',
        name: 'SubdivisionNewTechnicalEvaluationDialog',
        title: 'NEW_TECHNICAL_ASSESSMENT',
        component: () => import(/* webpackChunkName: "generalSubdivision" */ /* webpackMode: "lazy" */ '@/pages/subdivision/generalSubdivision/SubdivisionNewTechnicalEvaluationDialog.vue'),
    },
] : [];

export default paths;
