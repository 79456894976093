const paths = [
    {
        path: '/properties-editor',
        name: 'Properties',
        title: 'PROPERTIES_EDITOR',
        menu: 'customization',
        icon: 'mdi-language-javascript',
        component: () => import(/* webpackChunkName: "propertiesEditor" */ /* webpackMode: "lazy" */ '@/pages/propertiesEditor/PropertiesUI.vue'),
    },
];

export default paths;
