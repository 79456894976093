const paths = [
    {
        path: '/contributor-list',
        name: 'ContributorList',
        title: 'CONTRIBUTORS',
        icon: 'fa fa-user-circle',
        menu: 'humanResources',
        component: () => import(/* webpackChunkName: "contributor" */ /* webpackMode: "lazy" */ '@/pages/contributor/ContributorList.vue'),
    },
    {
        path: '/contributor-days-selection',
        name: 'ContributorDaySelection',
        title: 'CONTRIBUTOR_DAYS_SELECTION',
        component: () => import(/* webpackChunkName: "contributor" */ /* webpackMode: "lazy" */ '@/pages/contributor/ContributorDaySelection.vue'),
    },

];

export default paths;
