const paths = [
    {
        path: '/issue-type-asset-type-link',
        name: 'IssueTypeAssetTypeLink',
        title: 'ISSUE_TYPE_ASSET_TYPE_LINK',
        icon: 'fa fa-link',
        menu: 'maintenance',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "issueTypeAssetTypeLink" */ /* webpackMode: "lazy" */ '@/pages/issueTypeAssetTypeLink/IssueTypeAssetTypeLink.vue'),
    },
];

export default paths;
