import StaticProvider from './staticProvider/static-provider';
import CustomProvider from './customProvider/custom-provider';
import DefaultProvider from './defaultProvider/default-provider';

const entities = {
    ...StaticProvider,
    ...CustomProvider,
    ...DefaultProvider,
};

export default entities;
