const paths = [
    {
        path: '/web-service-list',
        title: 'WEB_SERVICES',
        name: 'WebServiceList',
        menu: 'registration',
        component: () => import(/* webpackChunkName: "webservices" */ /* webpackMode: "lazy" */ '@/pages/webservices/WebServicesList.vue'),
    },
    {
        path: '/web-service-general',
        name: 'WebServiceGeneral',
        component: () => import(/* webpackChunkName: "webservices" */ /* webpackMode: "lazy" */ '@/pages/webservices/WebServiceGeneral.vue'),
    },
    {
        path: '/web-service-config',
        title: 'WEB_SERVICE_CONFIG',
        name: 'WebServiceConfig',
        component: () => import(/* webpackChunkName: "webservices" */ /* webpackMode: "lazy" */ '@/pages/webservices/WebServiceConfig.vue'),
    },
    {
        path: '/test-web-service',
        title: 'TEST_WEB_SERVICE',
        name: 'TestWebService',
        component: () => import(/* webpackChunkName: "webservices" */ /* webpackMode: "lazy" */ '@/pages/webservices/TestWebService.vue'),
    },
    {
        path: '/edit-value-test',
        title: 'EDIT_VALUE_TEST',
        name: 'EditValueTest',
        component: () => import(/* webpackChunkName: "webservices" */ /* webpackMode: "lazy" */ '@/pages/webservices/EditValueTest.vue'),
    },
];

export default paths;
