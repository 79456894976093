const paths = [
    {
        path: '/manufacturer-list',
        name: 'ManufacturerList',
        title: 'MANUFACTURER_LIST',
        icon: 'widgets',
        menu: 'stock',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "manufacturerList" */ /* webpackMode: "lazy" */ '@/pages/manufacturerList/ManufacturerList.vue'),
    },
    {
        path: '/manufacturer-list-general',
        name: 'ManufacturerListGeneral',
        title: 'MANUFACTURER_LIST_GENERAL',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "manufacturerList" */ /* webpackMode: "lazy" */ '@/pages/manufacturerList/ManufacturerListGeneral.vue'),
    },
];

export default paths;
