import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import { parseSuccessResponse, showErrorsResponse } from '../utils';
export class GetWebhook extends CallCommand {
    constructor() {
        super({
            name: 'get_webhooks',
            type: 'NONE',
            fields: {
                name: new Field({
                    name: 'name',
                    value: true,
                }),
                createdAt: new Field({
                    name: 'created_at',
                    value: true,
                }),
                hideDeleted: new Field({
                    name: 'deleted_at',
                    value: true,
                }),
            },
            customRequestParser: function (params) {
                const filter = {
                    where: {},
                };
                if (params.hideDeleted) {
                    filter.where.deleted_at = { eq: null };
                }
                return {
                    BODY: `filter=${encodeURIComponent(JSON.stringify(filter))}`,
                };
            },
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        resolve.RAIZ.RESPONSE = response;
        return resolve;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class GetWebhookById extends CallCommand {
    constructor() {
        super({
            node: 'RESPONSE',
            name: 'get_webhook_0',
            type: 'NONE',
            fields: {
                name: new Field({
                    name: 'name',
                }),
                createdAt: new Field({
                    name: 'created_at',
                    value: true,
                }),
                webhookId: new Field({
                    name: 'webhook_id',
                }),
            },
            customRequestParser: function (params) {
                const pathParams = {
                    PARAM_0: params.webhookId,
                };
                return {
                    PATH_PARAMS: JSON.stringify(pathParams),
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveWebhook extends CallCommand {
    constructor() {
        super({
            node: 'RESPONSE',
            name: 'post_webhooks',
            type: 'SAVE',
            fields: {
                name: new Field({
                    name: 'name',
                }),
            },
            customRequestParser: function (params) {
                const data = {
                    name: params.name,
                };
                return {
                    BODY: JSON.stringify(data),
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class EditWebhook extends CallCommand {
    constructor() {
        super({
            node: 'RESPONSE',
            name: 'patch_webhooks_0',
            type: 'SAVE',
            fields: {
                name: new Field({
                    name: 'name',
                }),
                webhookId: new Field({
                    name: 'webhook_id',
                }),
            },
            customRequestParser: function (params) {
                const data = {
                    name: params.name,
                };
                const pathParams = {
                    PARAM_0: params.webhookId,
                };
                return {
                    PATH_PARAMS: JSON.stringify(pathParams),
                    BODY: JSON.stringify(data),
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class DeleteWebhook extends CallCommand {
    constructor() {
        super({
            node: 'RESPONSE',
            name: 'delete_webhooks_0',
            type: 'SAVE',
            fields: {
                webhookId: new Field({
                    name: 'webhook_id',
                }),
            },
            customRequestParser: function (params) {
                const pathParams = {
                    PARAM_0: params.webhookId,
                };
                return {
                    PATH_PARAMS: JSON.stringify(pathParams),
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
