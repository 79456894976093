const paths = [
  {
      path: '/tracking-integrations',
      title: 'TRACKING_INTEGRATIONS',
      name: 'TrackingIntegrationsList',
      icon: 'mdi-radar',
      menu: 'registration',
      component: () => import(/* webpackChunkName: "trackingIntegrations" */ /* webpackMode: "lazy" */ '@/pages/trackingIntegrations/TrackingIntegrationsList.vue'),
  },
  {
      path: '/tracking-integrations-general',
      name: 'TrackingIntegrationsGeneral',
      component: () => import(/* webpackChunkName: "trackingIntegrations" */ /* webpackMode: "lazy" */ '@/pages/trackingIntegrations/TrackingIntegrationsGeneral.vue'),
  },
];

export default paths;
