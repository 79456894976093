const paths = [
    {
        path: '/attendance-stardards-highway',
        name: 'AttendanceStardardsHighway',
        title: 'ATTENDANCE_STANDARDS_HIGHWAY',
        menu: 'asset',
        categories: [
            'reports',
        ],
        component: () => import(/* webpackChunkName: "attendanceStardardsHighway" */ /* webpackMode: "lazy" */ '@/pages/attendanceStardardsHighway/AttendanceStardardsHighway.vue'),
    },
];

export default paths;
