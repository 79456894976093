import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import { showErrorsResponse, parseSuccessResponse } from '@/pages/remoteManagement/services/utils';
export class GetCommandTypes extends CallCommand {
    constructor() {
        super({
            name: 'get_device-config_command-types',
            type: 'NONE',
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class GetDeviceConfigs extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_device-config',
            type: 'NONE',
            fields: {},
            customRequestParser: function () {
                const filter = {
                    where: {
                        deleted_at: { eq: null },
                    },
                };
                return {
                    BODY: `filter=${encodeURIComponent(JSON.stringify(filter))}`,
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class SaveDeviceConfig extends CallCommand {
    constructor() {
        super({
            name: 'post_comp_device-config',
            type: 'SAVE',
            fields: {
                deviceConfig: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, debug) {
        return super.callCommand(param, { silent: true }, debug);
    }
}
export class EditDeviceConfig extends CallCommand {
    constructor() {
        super({
            name: 'patch_comp_device-config_0',
            type: 'SAVE',
            fields: {
                deviceConfig: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
                deviceConfigId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class DeleteDeviceConfig extends CallCommand {
    constructor() {
        super({
            name: 'delete_comp_device-config_0',
            type: 'SAVE',
            fields: {
                deviceConfigId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class SaveDeviceAlertConfig extends CallCommand {
    constructor() {
        super({
            name: 'post_device-config_alert_0',
            type: 'SAVE',
            fields: {
                deviceAlertConfig: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
                deviceId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, debug) {
        return super.callCommand(param, { silent: true }, debug);
    }
}
export class SaveDeviceLuxConfig extends CallCommand {
    constructor() {
        super({
            name: 'post_device-config_lux_0',
            type: 'SAVE',
            fields: {
                deviceLuxConfig: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
                deviceId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, debug) {
        return super.callCommand(param, { silent: true }, debug);
    }
}
export class SaveDeviceControlConfig extends CallCommand {
    constructor() {
        super({
            name: 'post_device-config_control_0',
            type: 'SAVE',
            fields: {
                deviceControlConfig: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
                deviceId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, debug) {
        return super.callCommand(param, { silent: true }, debug);
    }
}
export class SaveDeviceBaseConfig extends CallCommand {
    constructor() {
        super({
            name: 'post_device-config_base_0',
            type: 'SAVE',
            fields: {
                deviceBaseConfig: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
                deviceId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, debug) {
        return super.callCommand(param, { silent: true }, debug);
    }
}
