<template>
    <div>
        <div
            v-if="!_hideActions"
            v-show='!sidebarIsVisible'
            :class='fab ? "fab-container" : ""'
            :style='fab ? { position: position } : {}'
        >
            <a-row dense align='center' :class='noWrap ? "flex-nowrap" : ""'>
                <a-col :cols='fab ? "12" : "auto"' :align='fab ? "end" : ""'>
                    <div v-if='filters'>
                        <a-badge left overlap color='red' :value='(!!activeFilters || !!activeFiltersCount)'>
                            <template v-slot:badge>
                                <span>{{activeFilters || activeFiltersCount}}</span>
                            </template>
                            <a-btn
                                :fab='true'
                                :tile='true'
                                small
                                depressed
                                @click='toggleVisible'
                                filter
                                :color='filterColor'
                                :color-icon='filterColorIcon'
                                small-icon
                            />
                        </a-badge>
                    </div>
                </a-col>
                <a-col :cols='fab ? "12" : "auto"' :align='fab ? "end" : ""'>
                    <div v-if='refresh'>
                        <a-btn
                            :fab='true'
                            :tile='true'
                            :outlined='refreshOutlined'
                            small
                            depressed

                            :loading='loading'
                            :disabled='loading'
                            @click='emitRefreshEvent'
                            search
                            :color='refreshColor'
                            :color-icon='refreshColorIcon || refreshColor'
                            small-icon
                        />
                    </div>
                </a-col>
            </a-row>
        </div>
        <v-navigation-drawer
            v-model='sidebarIsVisible'
            right
            fixed
            :absolute='_absolute'
            hide-overlay
            :clipped='clipped'
            :width='width'
            style="z-index:5"
            class='elevation-0'
            temporary
        >
            <div v-if='sidebarIsVisible' v-shortkey.once='["esc"]' class='d-none' @shortkey='sidebarIsVisible = false' />

            <a-toolbar height='60' dense flat>
                <h3 class="title">{{$t('FILTERS')}}</h3>
                <a-spacer />
                <slot name='card-header' />
                <a-icon 
                    close
                    :small='false'
                    color='mono-grey-40'
                    @click='toggleVisible'
                />
            </a-toolbar>

            <a-card flat ref='sidebar'>
                <a-card-text cols='12'>
                    <slot />
                </a-card-text>
            </a-card>

            <div slot='append' class="pa-6 d-flex justify-end">
                <a-btn 
                    :loading='loading'
                    :disabled='loading'
                    @click='clearData'
                    class="mr-2"
                    outlined
                    color='mono-grey-40'
                >
                    {{$t('CLEAR')}}
                </a-btn>
                <a-btn
                    color='primary'
                    :loading='loading'
                    :disabled='loading'
                    @click='$emit("apply")'>
                    {{$t('APPLY')}}
                </a-btn>
            </div>
         
        </v-navigation-drawer>
    </div>
</template>

<script>
    export default {
        name: 'AFilterSidebar',
        props: {
            clipped: {
                type: Boolean,
                required: false,
                default: true,
            },
            width: {
                type: [String, Number],
                default: 600,
            },
            _showQuestionIcon: {
                type: Boolean,
                default: false,
            },
            refresh: {
                type: Boolean,
                default: true,
            },
            open: {
                type: Boolean,
                default: false,
            },
            filters: {
                type: Boolean,
                default: true,
            },
            cleanNull: {
                type: Boolean,
                default: false,
            },
            activeFilters: {
                type: Number,
                default: 0,
            },
            fab: {
                type: Boolean,
                default: true,
            },
            position: {
                type: String,
                default: 'fixed',
            },
            loading: {
                type: Boolean,
                default: false,
            },
            filterColor: {
                type: String,
                default: null,
            },
            filterColorIcon: {
                type: String,
                default: 'white',
            },
            refreshColor: {
                type: String,
                default: 'black',
            },
            refreshColorIcon: {
                type: String,
                default: null,
            },
            _pulseApply: {
                type: Boolean,
                require: true,
                default: false,
            },
            noWrap: {
                type: Boolean,
                default: false,
            },
            refreshOutlined: {
                type: Boolean,
                default: true,
            },
            _absolute: {
                type: Boolean,
                default: false,
            },
            _hideActions: {
                type: Boolean,
                default: false,
            },
        },
        data: function () {
            return {
                sidebarIsVisible: false,
                activeFiltersCount: 0,
                hideParentOnClose: false,
                hideOnClose: false,
            };
        },
        mounted() {
            setTimeout(() => {
                this.activeFiltersCount = this.countChild(this.$slots.default);
            }, 500)
        },
        methods: {
            toggleVisible: function () {
                this.sidebarIsVisible = !this.sidebarIsVisible;
            },
            emitRefreshEvent: function () {
                this.$emit('refresh');
            },
            countChild: function (list) {
                if(!list) {
                    return 0;
                }
                let count = 0;
                list.forEach(vnode => {
                    let comp = vnode.componentInstance ? vnode.componentInstance : vnode;
                    if ((!comp && vnode.children) || (comp.inputs && comp.inputs.length > 0)) {
                        count += this.countChild(vnode.children ? vnode.children : comp.inputs);
                    } else if (comp) {
                        if(comp.value instanceof Array && comp.value.length > 0) {
                            count++;
                        } else if(comp.value && String(comp.value).length > 0) {
                            count++;
                        }

                    }
                });
                return count;
            },
            clearData: function () {
                this.$emit('clear');
                if (this.cleanNull) {
                    this.clearChildren(this.$slots.default);
                }
            },
            clearChildren: function (list) {
                list.forEach(vnode => {
                    if (!vnode.componentInstance && vnode.children) {
                        this.clearChildren(vnode.children);
                    } else if (vnode.componentInstance) {
                        vnode.componentInstance.$emit('input', null);
                    }
                });
            },
        },
        watch: {
            open: {
                immediate: true,
                handler: function (value) {
                    this.sidebarIsVisible = value;
                },
            },
            sidebarIsVisible: function (newVal, oldVal) {
                this.activeFiltersCount = this.countChild(this.$slots.default);
                this.$emit('update:open', newVal);

                if(newVal != oldVal) {
                    if(newVal === true) {
                        this.hideParentOnClose = false;
                        this.hideOnClose = false;

                        if(this.$parent.$el.style.display == 'none') {
                            this.$parent.$el.style.display = null;
                            this.hideParentOnClose = true;
                        }

                        if(this.$el.style.display == 'none') {
                            this.$el.style.display = null;
                            this.hideOnClose = true;
                        }
                    }else {
                        if(this.hideParentOnClose) {
                            this.$parent.$el.style.display = 'none';
                        }else if(this.hideOnClose) {
                            this.$el.style.display = 'none';
                        }
                    }
                }
            },
        },
    };
</script>

<style lang='stylus'>
    .fab-container
        top 50%
        right 0
        z-index 4
        display flex
        align-items center
        flex-direction column
        justify-content center
        transform translateY(-50%)
        width 50px

    .aBtnHover
        transform scale(1)
        right 0
        margin-right -1rem
        -webkit-transition-duration 1s
        -moz-transition-duration 1s
        -o-transition-duration 1s
        transition-duration 1s

    .aBtnHover_inner
        right -1rem
        -webkit-transition-duration 1s
        -moz-transition-duration 1s
        -o-transition-duration 1s
        transition-duration 1s

    .aBtnHover:hover
        right 1.1rem
        transform scale(1.2)

    .aBtnHover:hover #aBtnHover_inner
        right 0

    .aBtnHoveActive
        @extend .aBtnHover:hover

    @-webkit-keyframes pulse
        0%
            box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.3);
        50%
            box-shadow: 0 0 3px 10px rgba(255, 0, 0, 0.05);
        100%
            box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.3);
    @-moz-keyframes pulse
        0%
            box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.3);
        50%
            box-shadow: 0 0 3px 10px rgba(255, 0, 0, 0.05);
        100%
            box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.3);
    @-ms-keyframes pulse
        0%
            box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.3);
        50%
            box-shadow: 0 0 3px 10px rgba(255, 0, 0, 0.05);
        100%
            box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.3);
    @keyframes pulse
        0%
            box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.3);
        50%
            box-shadow: 0 0 3px 10px rgba(255, 0, 0, 0.05);
        100%
            box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.3);

    .pulse-button
        -webkit-animation pulse 2s infinite
        -moz-animation pulse 2s infinite
        -ms-animation pulse 2s infinite
        animation pulse 2s infinite
</style>
