const paths = [
    {
        path: '/load-change',
        name: 'LoadChange',
        title: 'LOAD_CHANGE',
        icon: 'mdi-file-document',
        menu: 'asset',
        categories: [
          'reports',
        ],
        component: () => import(/* webpackChunkName: "energy" */ /* webpackMode: "lazy" */ '@/pages/energy/LoadChange.vue'),
    },
    {
        path: '/energy-consumption-comparison',
        name: 'EnergyConsumptionComparison',
        title: 'ENERGY_CONSUMPTION_COMPARISON',
        menu: 'asset',
        categories: [
          'reports',
        ],
        component: () => import(/* webpackChunkName: "energy" */ /* webpackMode: "lazy" */ '@/pages/energy/EnergyConsumptionComparison.vue'),
    },
    {
        path: '/energy-consumption-report',
        name: 'EnergyConsumptionReport',
        title: 'ENERGY_CONSUMPTION_REPORT',
        menu: 'asset',
        categories: [
          'reports',
        ],
        component: () => import(/* webpackChunkName: "energy" */ /* webpackMode: "lazy" */ '@/pages/energy/EnergyConsumptionReport.vue'),
    },
    {
      path: '/energy-billing-list',
      name: 'EnergyBillingList',
      title: 'ENERGY_BILLINGS',
      menu: 'financial',
      component: () => import(/* webpackChunkName: "energy" */ /* webpackMode: "lazy" */ '@/pages/energy/EnergyBillingList.vue'),
    },
    {
      path: '/energy-billing-details',
      name: 'EnergyBillingDetails',
      title: 'ENERGY_BILLING',
      component: () => import(/* webpackChunkName: "energy" */ /* webpackMode: "lazy" */ '@/pages/energy/EnergyBillingDetails.vue'),
    },
    {
      path: '/local-type-energy-consumption-list',
      name: 'LocalTypeEnergyConsumptionList',
      title: 'LOCAL_TYPES_ENERGY_CONSUMPTION',
      menu: 'asset',
      component: () => import(/* webpackChunkName: "energy" */ /* webpackMode: "lazy" */ '@/pages/energy/LocalTypeEnergyConsumptionList.vue'),
    },
    {
      path: '/local-type-energy-consumption-general',
      name: 'LocalTypeEnergyConsumptionGeneral',
      title: 'LOCAL_TYPE_ENERGY_CONSUMPTION',
      component: () => import(/* webpackChunkName: "energy" */ /* webpackMode: "lazy" */ '@/pages/energy/LocalTypeEnergyConsumptionGeneral.vue'),
    },
    {
      path: '/local-type-energy-consumption-report',
      name: 'LocalTypeEnergyConsumptionReport',
      title: 'ENERGY_CONSUMPTION_BY_PLACE',
      menu: 'asset',
      categories: [
        'reports',
      ],
      component: () => import(/* webpackChunkName: "energy" */ /* webpackMode: "lazy" */ '@/pages/energy/LocalTypeEnergyConsumptionReport.vue'),
  },
  {
    path: '/energy-consumption-configuration',
    name: 'EnergyConsumptionConfiguration',
    title: 'ENERGY_CONSUMPTION_CONFIGURATION',
    menu: 'asset',
    component: () => import(/* webpackChunkName: "energy" */ /* webpackMode: "lazy" */ '@/pages/energy/EnergyConsumptionConfiguration.vue'),
  },
];

export default paths;
