const paths = [
    {
        path: '/issue-log',
        title: 'ISSUE_LOG_REPORT',
        name: 'IssueLog',
        icon: 'mdi-file-document',
        menu: 'ticket',
        categories: [
          'reports',
        ],
        component: () => import(/* webpackChunkName: "issueLog" */ /* webpackMode: "lazy" */ '@/pages/issueLog/IssueLog.vue'),
    },
];

export default paths;
