const paths = [
    {
        path: '/category-absence-employee-list',
        name: 'CategoryAbsenceEmployeeList',
        icon: 'fa fa-briefcase',
        title: 'CATEGORY_ABSENCE_EMPLOYEE',
        menu: 'humanResources',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "humansResources" */ /* webpackMode: "lazy" */ '@/pages/humansResources/categoryAbsenceEmployee/CategoryAbsenceEmployeeList.vue'),
    },
    {
        path: '/category-absence-employee-details',
        name: 'CategoryAbsenceEmployeeDetails',
        title: 'CATEGORY_ABSENCE_EMPLOYEE',
        favCategory: 'humanResources',
        component: () => import(/* webpackChunkName: "humansResources" */ /* webpackMode: "lazy" */ '@/pages/humansResources/categoryAbsenceEmployee/CategoryAbsenceEmployeeDetails.vue'),
    },
];

export default paths;
