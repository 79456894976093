<script>
    export default {
        name: 'AIconSave',
        functional: true,
        render: function (createElement, { data, parent }) {
            delete data.attrs.save;
            const children = ['fas fa-save'];
            if ((!data.scopedSlots || !data.scopedSlots['tooltip-body']) && !data.attrs['_tooltip-text']) {
                data.attrs['_tooltip-text'] = parent.$t('SAVE');
            }
            return createElement('AIconDefault', data, children);
        },
    };
</script>
