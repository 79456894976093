const paths = [
    {
        path: '/courses-and-certificates-colaborators',
        name: 'CoursesAndCertificatesColaborators',
        title: 'COURSES_AND_CERTIFICATES_COLABORATORS',
        icon: 'fa fa-briefcase',
        menu: 'humanResources',
        component: () => import(/* webpackChunkName: "coursesAndCertificates" */ /* webpackMode: "lazy" */ '@/pages/coursesAndCertificates/CoursesAndCertificatesColaborators.vue'),
    },
    {
        path: '/certificate-reports',
        name: 'CertificateReports',
        component: () => import(/* webpackChunkName: "coursesAndCertificates" */ /* webpackMode: "lazy" */ '@/pages/coursesAndCertificates/CertificateReports.vue'),

    },
    {
        path: '/function-reports',
        name: 'FunctionReports',
        component: () => import(/* webpackChunkName: "coursesAndCertificates" */ /* webpackMode: "lazy" */ '@/pages/coursesAndCertificates/FunctionReports.vue'),

    },
];

export default paths;
