import { StaticEntity } from '@inputs/entity.ts';

import AttributeValueComparators from '@inputs/static/provider/AttributeValueComparators';
import YesNo from '@inputs/static/provider/YesNo';
import YesNoAll from '@inputs/static/provider/YesNoAll';
import AssistanceSituation from '@inputs/static/provider/AssistanceSituation';
import DisplayType from '@inputs/static/provider/DisplayType';
import InterventionOperation from '@inputs/static/provider/InterventionOperation';
import DaysHours from '@inputs/static/provider/DaysHours';
import RegisteredAssetStatus from '@inputs/static/provider/RegisteredAssetStatus';
import MeasuredEstimated from '@inputs/static/provider/MeasuredEstimated';
import PolygonFunction from '@inputs/static/provider/PolygonFunction';
import TypeVersionApp from '@inputs/static/provider/TypeVersionApp';


const staticProvider = {
    attributeValueComparators: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: AttributeValueComparators }),
    yesNo: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: YesNo }),
    yesNoAll: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: YesNoAll }),
    assistanceSituation: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: AssistanceSituation }),
    interventionOperation: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: InterventionOperation }),
    displayType: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: DisplayType }),
    daysHours: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: DaysHours }),
    registeredAssetStatus: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: RegisteredAssetStatus }),
    measuredEstimated: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: MeasuredEstimated }),
    polygonFunction: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: PolygonFunction }),
    typeVersionApp: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: TypeVersionApp }),
};

export default staticProvider;
