const paths = [
    {
        path: '/asset-grouping-type',
        name: 'AssetGroupingTypeList',
        title: 'ASSET_GROUPING_TYPE',
        menu: 'maintenance',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "assetGroupingType" */ /* webpackMode: "lazy" */ '@/pages/assetGroupingType/AssetGroupingTypeList.vue'),
    },
    {
        path: '/asset-grouping-type-tabs',
        name: 'AssetGroupingTypeTabs',
        title: 'ASSET_GROUPING_TYPE',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "assetGroupingType" */ /* webpackMode: "lazy" */ '@/pages/assetGroupingType/AssetGroupingTypeTabs.vue'),
    },
];

export default paths;
