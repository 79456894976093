const paths = [
    {
        path: '/version-apps',
        name: 'VersionApps',
        title: 'VERSIONING_APPLICATION',
        menu: 'registration',
        component: () => import(/* webpackChunkName: "versionApps" */ /* webpackMode: "lazy" */ '@/pages/scale/VersionApps.vue'),
    },
];

export default paths;
