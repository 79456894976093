<script>
    export default {
        name: 'AIconAssignUser',
        functional: true,
        render: function (createElement, { data, parent }) {
            data.attrs = data.attrs || {};

            delete data.attrs['assign-user'];
            const children = ['assignment_ind'];
            if ((!data.scopedSlots || !data.scopedSlots['tooltip-body']) && !data.attrs['_tooltip-text']) {
                data.attrs['_tooltip-text'] = parent.$t('ASSIGN');
            }
            return createElement('AIconDefault', data, children);
        },
    };
</script>
