const paths = [
    {
        path: '/external-integration-log-requests-list',
        title: 'EXTERNAL_INTEGRATION_LOG_REQUESTS',
        name: 'ExternalIntegrationLogRequestsList',
        icon: 'fas fa-file-invoice',
        menu: 'ticket',
        component: () => import(/* webpackChunkName: "externalIntegrationLogRequests." */ /* webpackMode: "lazy" */ '@/pages/externalIntegrationLogRequests/ExternalIntegrationLogRequestsList.vue'),
    },
];

export default paths;
