const paths = [
    {
        path: '/project-devolution',
        name: 'ProjectDevolution',
        title: 'PROJECT_DEVOLUTION',
        component: () => import(/* webpackChunkName: "projectDevolution" */ /* webpackMode: "lazy" */ '@/pages/project/projectDevolution/ProjectDevolution'),
    },
    {
        path: '/project-devolution-request',
        name: 'ProjectDevolutionRequest',
        title: 'PROJECT_DEVOLUTION_REQUEST',
        component: () => import(/* webpackChunkName: "projectDevolution" */ /* webpackMode: "lazy" */ '@/pages/project/projectDevolution/ProjectDevolutionRequest'),
    },
    {
        path: '/project-devolution-wizard',
        name: 'ProjectDevolutionWizard',
        title: 'NEW_DEVOLUTION_REQUEST',
        component: () => import(/* webpackChunkName: "projectDevolution" */ /* webpackMode: "lazy" */ '@/pages/project/projectDevolution/ProjectDevolutionWizard'),
    },
    {
        path: '/project-devolution-items',
        name: 'ProjectDevolutionItems',
        title: 'DEVOLUTION_ITEMS',
        component: () => import(/* webpackChunkName: "projectDevolution" */ /* webpackMode: "lazy" */ '@/pages/project/projectDevolution/ProjectDevolutionItems'),
    },
    {
        path: '/project-devolution-refund',
        name: 'ProjectDevolutionRefund',
        title: 'DEVOLUTION_REFUND',
        component: () => import(/* webpackChunkName: "projectDevolution" */ /* webpackMode: "lazy" */ '@/pages/project/projectDevolution/ProjectDevolutionRefund'),
    },
];

export default paths;
