<template>
    <a-row class="fill-height a-catalog-draggable-list" no-gutters>
        <a-col cols="12" :md="editableModel ? 6 : 12" class="a-catalog-items-selected-column">
            <a-row no-gutters style="height:calc(100% - 65px)">
                <a-col cols="12">

                    <a-toolbar flat>
                        <a-icon v-if="_titleIcon" class="mr-3">{{_titleIcon}}</a-icon>
                        <img
                            v-else-if="_titleImg"
                            class="image mr-3"
                            :src="_titleImg"
                            alt=""
                            width="25px"
                            height="25px"
                        >
                        <a-toolbar-title v-if="_title">{{ _title }}</a-toolbar-title>

                        <a-spacer></a-spacer>

                        <a-btn v-if="!editableModel" text color="save" @click="editableModel = true">
                            {{ $t("OPEN_ENTITY", { entity: $t("CATALOG") }) }}
                            <a-icon v-if="!isMobileOrTablet" right>mdi-chevron-right</a-icon>
                        </a-btn>
                        <a-btn
                            v-else-if='!isMobileOrTablet'
                            text
                            color="save"
                            @click="editableModel = !editableModel"
                        >
                            {{ $t("MINIMIZE_CATALOG") }}
                            <a-icon right>
                                mdi-chevron-left
                            </a-icon>
                        </a-btn>
                    </a-toolbar>

                    <a-divider></a-divider>

                </a-col>

                <a-col cols="12" style="position: relative" class="fill-height">
                    <a-data-table
                        v-if='!_childrenKey'
                        ref='selectedTable'
                        :items='selectedItems'
                        :headers='_selectedHeaders'
                        :height="_tableHeight"
                        _hide-header
                        :group-by='_groupBy'
                        :item-key='_itemKey'
                    >
                        <template v-slot:item='props'>
                            <tr
                                :ref="itemRef(itemIndex(props.item))"
                                class="transparent"
                                :tabindex="!isMobileOrTablet ? '0' : undefined"
                                @keydown.up.prevent="focusPreviousSelectedItem(itemIndex(props.item))"
                                @keydown.down.prevent="focusNextSelectedItem(itemIndex(props.item))"
                                @keydown.right.prevent="focusNextItem(-1)"
                            >
                                <slot
                                    name="item-selected"
                                    :item="props.item"
                                    :removeItem="() => removeItem(props.item)"
                                />
                            </tr>
                        </template>
                        <template v-slot:no-data>
                            {{null}}
                        </template>
                    </a-data-table>

                    <a-tree-table
                        v-if='_childrenKey'
                        ref='selectedTable'
                        :items='selectedItems'
                        :headers='_selectedHeaders'
                        :height="_tableHeight"
                        _hide-header
                        :_item-key='_itemKey'
                        :_children-key='_childrenKey'
                    >
                        <template v-for='slot in Object.keys($scopedSlots)' :slot='slot' slot-scope='scope'>
                            <slot :name='slot' v-bind='scope' />
                        </template>
                        <template v-slot:no-data>
                            {{null}}
                        </template>
                    </a-tree-table>

                    <draggable
                        :sort="false"
                        group="items"
                        handle=".drag-handle"
                        ghost-class="text--disabled"
                    >

                        <a-row
                            slot="footer"
                            v-if="showDragItemsCard"
                            align="center"
                            class="center-items-card"
                        >
                            <a-col>
                                <a-card
                                    height="168"
                                    outlined
                                    width="340"
                                    class="mx-auto"
                                    style="border-style:dashed"
                                    :disabled="drag"
                                >
                                    <a-row class="fill-height" align="center">
                                        <a-col class="text-center">
                                            <a-icon large class="mb-4">vertical_align_bottom</a-icon>
                                            <h6 class="text-body-1">
                                            {{ $t("DRAG_OR_SELECT_AN_ITEM") }}
                                            </h6>
                                        </a-col>
                                    </a-row>
                                </a-card>
                            </a-col>
                        </a-row>
                    </draggable>

                    <a-row
                        v-if="showSearchItemsCard"
                        align="center"
                        justify="center"
                        class="center-items-card"
                    >
                        <a-col
                            v-ripple
                            cols="8"
                            class="text-center"
                            style="cursor:pointer"
                            @click="editableModel = true"
                        >
                            <h6 class="text-body-1">
                                {{ $t("SEARCH_ITEMS_IN_CATALOG_TO_INLUDE") }}
                            </h6>
                        </a-col>
                    </a-row>
                </a-col>
            </a-row>
        </a-col>

        <a-col v-if="editableModel" cols="12" md="6" class="mono-grey-2">

            <a-row dense align="center" style="min-height:64px" class="pa-5">
                <slot name='filters-bar'>
                    <a-col cols='12' md='12' lg='5'>
                        <a-text-field
                            ref='searchField'
                            v-model="search"
                            :label="$t('SEEK_ENTITY', { entity: $t('ITEMS') })"
                            :append-icon="search ? 'close' : 'search'"
                            hide-details
                            style="max-width:300px"
                            @click:append="handleClickSearchBarIcon"
                            @change="updateSearch()"
                            @keydown.enter="refreshSearch()"
                            @keydown.shift.enter="automaticSelection()"
                            @keydown.down.prevent="focusNextItem(-1)"
                            @keydown.up.prevent="focusPreviousItem(selectedItems.length)"
                        />
                    </a-col>

                    <slot name='append-searchbar' />

                    <a-col cols='12' md='auto' class="d-flex align-center">
                        <slot name='prepend-filter-icon' />
                        <a-dynamic-command-filter
                            v-if="showCommandFilter"
                            ref='filter'
                            :key="_command.name"
                            exhibition-mode="sidebar"
                            :_fab="false"
                            :_command="_command"
                            _filter-color="transparent"
                            _refresh-color="mono-grey-40"
                            _filter-background-color="mono-grey-40"
                            @updateValues="updateValues"
                        />
                    </a-col>
                </slot>
            </a-row>

            <a-dialog
                v-if="isMobileOrTablet"
                v-model="editableModel"
                fullscreen
            >
                    <a-card tile>
                        <a-card-title class="save white--text">
                            <a-icon @click="editableModel = false" close color='white' class="mr-4"></a-icon>
                            {{$t('CATALOG')}}
                        </a-card-title>
                        <a-row dense align="center" style="min-height:64px" class="pa-5">
                            <a-col cols='12' sm='6'>
                                <a-text-field
                                    v-model="search"
                                    :label="$t('SEEK_ENTITY', { entity: $t('ITEMS') })"
                                    :append-icon="search ? 'close' : 'search'"
                                    hide-details
                                    style="max-width:300px"
                                    @click:append="handleClickSearchBarIcon"
                                />

                                <slot name='append-items-searchbar' />

                            </a-col>
                            <slot name='filters-bar'>
                                <a-col cols='12' sm='6' class="d-flex align-center">
                                    <slot name='prepend-filters-bar' />
                                    <a-dynamic-command-filter
                                        v-if="showCommandFilter"
                                        ref='filter'
                                        :key="_command.name"
                                        exhibition-mode="sidebar"
                                        :_fab="false"
                                        :_command="_command"
                                        _filter-color="transparent"
                                        _refresh-color="mono-grey-40"
                                        _filter-background-color="mono-grey-40"
                                        @updateValues="updateValues"
                                    />
                                </a-col>
                            </slot>
                    </a-row>
                    <a-simple-table class="mono-grey-50--text">
                        <tbody>
                            <template v-for="(item, index) in items">
                                <tr :key="index">
                                    <td>
                                        <a-icon
                                            add
                                            color='mono-grey-40'
                                            @click="addItem(item); editableModel = false"
                                        />
                                    </td>
                                    <slot
                                        name="item-catalog"
                                        :item="item"
                                        :removeItem="() => removeItem(item, index)"
                                        :addItem="() => addItem(item)"
                                    />
                                </tr>
                            </template>
                        </tbody>
                    </a-simple-table>
                </a-card>
            </a-dialog>

            <a-simple-table v-else class="transparent mono-grey-50--text" :height="_tableHeight">
                <!-- The add function is using the removed element from the right table, instead of
                the added element in the left table. The reason is that the Sortable constructor is needed
                for the a-datatable to work with the draggable API, therefore losing its functionality of
                sending the added element directly. Future changes in the functionality of the catalog
                may require further refactoring  -->
                <draggable
                    :sort="false"
                    :list="items"
                    group="items"
                    tag="tbody"
                    handle=".drag-handle"
                    @start="drag = true"
                    @end="drag = false"
                    @change='emitAdded($event.removed.element)'
                >
                    <template v-for="(item, index) in items">
                        <tr
                            ref="items"
                            :key="index"
                            @dblclick="addItem(item)"
                            tabindex="0"
                            @keydown.up.prevent="focusPreviousItem(index)"
                            @keydown.down.prevent="focusNextItem(index)"
                            @keydown.left.prevent="focusNextSelectedItem(-1)"
                            @keydown.enter="addItem(item)"
                        >
                            <td style="width:50px">
                                <a-icon
                                    style="cursor:pointer;"
                                    class="drag-handle"
                                >
                                    drag_indicator
                                </a-icon>
                            </td>
                            <slot
                                name="item-catalog"
                                :item="item"
                                :removeItem="() => removeItem(item, index)"
                                :addItem="() => addItem(item)"
                            />
                        </tr>
                    </template>
                </draggable>
            </a-simple-table>

        </a-col>
    </a-row>
</template>

<script>
import Sortable from 'sortablejs';

export default {
  props: {
    /**
     * Array of selected items
     * @type {Array}
     * @default {[]}
     */
    value: {
      type: Array,
      required: false,
      default: () => []
    },
    /**
     * Array representing the header of the selected items table
     * @type {Array}
     * @default {[]}
     */
    _selectedHeaders: {
      type: Array,
      required: false,
      default: () => []
    },
    /**
     * Booelan to sync and check if is in editable mode
     * @type {Array}
     * @default {[]}
     */
    _editable: {
      type: Boolean,
      required: false,
      default: null
    },
    /**
     * Array of all items
     * @type {Array}
     * @default {[]}
     */
    _items: {
      type: Array,
      required: false,
      default: () => []
    },
    /**
     * Sync searchbar
     * @type {String, Number}
     * @default {null}
     */
    _search: {
      type: [String, Number],
      required: false,
      default: null
    },
    /**
     * Title of list
     * @type {Array}
     * @default {null}
     */
    _title: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Title icon
     * @type {String}
     * @default {}
     */
    _titleIcon: {
      type: String,
      required: false,
      default: 'data_usage'
    },
    /**
     * Title img
     * @type {String}
     * @default {}
     */
    _titleImg: {
      type: String,
      required: false,
      default: undefined
    },
    /**
     * Command to use in search
     * @type {Array}
     * @default {[]}
     */
    _command: {
      type: Object,
      required: false,
      default: null
    },
    /**
     * Hide selected items
     * @type {String, Number}
     * @default {true}
     */
    _hideSelected: {
      type: Boolean,
      required: false,
      default: null
    },
    /**
     * Table height
     * @type {Array}
     * @default {[]}
     */
    _tableHeight: {
      type: String,
      required: false,
      default: "71.8vh"
    },
    /**
     * Item key prop for datatable
     * @type {[String, Array]}
     * @default {'id'}
     */
    _itemKey: {
      type: [String, Array],
      required: true,
      default: 'id'
    },
    /**
     * Group by prop for datatable
     * @type {[String, Array]}
     * @default {undefined}
     */
    _groupBy: {
      type: [String, Array],
      required: false,
      default: undefined
    },
    /**
     * Children key for a-tree-table
     * @type {[String, Array]}
     * @default {undefined}
     */
    _childrenKey: {
      type: [String, Array],
      required: false,
      default: undefined
    },
  },
    components: {
        draggable: () => import("vuedraggable")
    },
    data() {
        return {
            search: null,
            drag: false,
            editable: false,
        };
    },
    computed: {
        selectedItems: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        },
        editableModel: {
            get() {
                if (this._editable !== null) {
                    return this._editable;
                }

                return this.editable;
            },
            set(value) {
                this.editable = value;
                this.$emit("update:_editable", value);
            }
        },
        items() {
            return this._items.filter(item => {
                if (!this._hideSelected) {
                    return true;
                }

                if (this.selectedItems.includes(item)) {
                    return false;
                }
                return true;
            });
        },
        showDragItemsCard: function() {
            if (this.selectedItems.length > 0) {
                return false;
            }

            if (this.items.length === 0) {
                return false;
            }

            if (!this.editableModel) {
                return false;
            }

            return true;
        },
        showSearchItemsCard: function() {
            if (this.selectedItems.length > 0) {
                return false;
            }

            if (this.items.length > 0 && this.editableModel) {
                return false;
            }

            return true;
        },
          isMobileOrTablet: function () {
                const breakpoints = [
                    'xs',
                    'sm',
                ]

                return breakpoints.includes(this.$vuetify.breakpoint.name)
            },
        showCommandFilter: function () {
            return this._command && this._command.showFilters;
        },
    },
    watch: {
        /**search(value) {
            if(value.length >= 4){
                this.$emit("update:_search", value);
            }

        },*/
        editableModel(value) {
            this.$emit("change-expanded", value);
        }
    },
    mounted: function () {
        new Sortable(
            this.$refs.selectedTable.$el.getElementsByTagName('tbody')[0],
            {
                sort: false,
                group: 'items',
                tag: 'tbody',
                ghostClass: 'text--disabled',
                handle: '.drag-handle',
            }
        );
    },
    methods: {
        itemIndex(item) {

            return this.selectedItems.map((value) => value[this._itemKey]).indexOf(item[this._itemKey]);
        },
        itemRef(index) {
            return `itemsSelected-${index}`;
        },
        addItem(item) {
            this.emitAdded(item);
        },
        emitAdded(item) {
            this.$emit("added", item);
            this.search = undefined;
        },
        removeItem(item) {
            this.selectedItems.splice(this.itemIndex(item), 1);
            this.$emit("removed", item);
        },
        updateValues(items) {
            this.$emit("update:_items", items);
        },
        handleClickSearchBarIcon() {
            if (this.search) {
                return (this.search = "");
            }
            return this.$emit("click:search-icon");
        },
        focusPreviousSelectedItem: function(index) {
            const toIndex = index - 1;
            if (this.$refs[this.itemRef(toIndex)]) {
                this.$refs[this.itemRef(toIndex)].focus();
            } else {
                this.$refs[this.itemRef(this.selectedItems.length - 1)].focus();
            }
        },
        focusNextSelectedItem: function(index) {
            const toIndex = index + 1;
            if (this.$refs[this.itemRef(toIndex)]) {
                this.$refs[this.itemRef(toIndex)].focus();
            } else {
                this.$refs[this.itemRef(0)].focus();
            }
        },
        focusPreviousItem: function(index) {
            if (this.$refs.items[index - 1]) {
                this.$refs.items[index - 1].focus();
                return;
            }

            this.focusSearch();
        },
        focusNextItem: function(index) {
            if (this.$refs.items[index + 1]) {
                this.$refs.items[index + 1].focus();
                return;
            }

            if (this.$refs.items[0]) {
                this.focusSearch();
            }
        },
        updateSearch: function () {
            this.$emit("update:_search", this.search);
        },
        refreshSearch: function () {
            if (this.showCommandFilter) {
                this.$refs.filter.refresh(true);
            }
        },
        automaticSelection: async function () {
            if (this.showCommandFilter) {
                await this.$refs.filter.refresh(true);
                const itemCode = this.$refs.searchField.value;
                if (itemCode.length > 0){
                    const item = this.items.find(item => String(item.COD_ITEM) === String(itemCode));
                    if (item !== undefined) {
                        this.selectedItems.push(item);
                        this.emitAdded(item);
                    }
                }
            }
        },
        focusSearch: function () {
            this.$refs.searchField.focus();
        },
    }
};
</script>

<style lang="scss" scoped>
.a-catalog-draggable-list {
  .v-toolbar {
    z-index: 2;
  }
  .center-items-card {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .a-catalog-items-selected-column {
    border-right: 1px solid var(--v-mono-grey-10-base);
  }
}
</style>
