<template>
    <a-container v-resize='onResize' class='fill-height'>
        <a-spacer></a-spacer>
        <span>
            <a-row>
                <a-col cols='auto'>
                    <a-dynamic-command-filter
                        v-if='command'
                        ref='filter'
                        exhibition-mode='sidebar'
                        filter-group='all'
                        :_sidebar-props='{ _hideActions: true }'
                        :_loading.sync='loading'
                        :_command='command'
                        :_fab='false'
                        class='pivot-filter'
                        @updateValues='updateDashData'
                    />
                </a-col>
            </a-row>
        </span>
        <chart ref='chart' :_options='chartOptions' class='fill-height' />
    </a-container>
</template>

<script>
    import { GetServicesBulletinExecution } from '@/pages/maintenance/services/request';

    export default {
        name: 'MaintenanceServicesExecutionBulletin',
        props: {
            /**
             * Command filter values sent from the Dashboard
             * @type {Object}
             * @default {}
             */
            _dashdata: {
                type: Object,
                default: () => ({
                    filters: [],
                }),
            },
            /**
             * Override filters command
             * @type {Array}
             * @default {[]}
             */
            _filters: {
                type: Array,
                default: () => ([]),
            },
            /**
             * sync with parent loading
             * @type {Boolean}
             * @default {false}
             */
            _loading: {
                type: Boolean,
                default: false,
            },
            /**
             * sync with parent empty-data
             * @type {Boolean}
             * @default {false}
             */
            _emptyData: {
                type: Boolean,
                default: false,
            },
        },
        data: function () {
            return {
                dashdata: this._dashdata,
                chartOptions: {
                    legend: {
                        orient: 'vertical',
                        left: 10,
                        bottom: 50,
                    },
                    tooltip: {
                        trigger: 'item',
                    },
                },
                reportData: {},
                command: null,
            };
        },
        computed: {
            loading: {
                get: function () {
                    return this._loading;
                },
                set: function (value) {
                    this.$emit('update:_loading', value);
                },
            },
            emptyData: {
                get: function () {
                    return this._emptyData;
                },
                set: function (value) {
                    this.$emit('update:_emptyData', value);
                },
            },
        },
        watch: {
            _dashdata: {
                deep: true,
                handler: function (val, oldVal) {
                    if (!this.$lodash.isEqual(val, oldVal)) {
                        this.dashdata = val;
                    }
                },
            },
            dashdata: {
                deep: true,
                handler: function (val) {
                    this.$emit('update-dashdata', val);
                },
            },
        },
        mounted: function () {
            this.load();
        },
        methods: {
            onResize: function () {
                this.$refs.chart.resize({ height: this.chartHeight });
            },
            load: async function () {
                const command = new GetServicesBulletinExecution();

                command.node = 'TOTAIS.TOTAL';

                command.initializeLoading = !!this.dashdata.filters.length;

                this.$util.loadCommandFilter(command, this.dashdata.filters);

                command.requireRequest = fields => !!fields.worksiteId.value || this.$t('MANDATORY_FIELD_ENTITY', { entity: this.$t('WORKSITE') });

                this.command = command;

                this.$nextTick(this.onResize);
            },
            updateDashData: function (items) {
                this.emptyData = !items.length;

                this.dashdata.filters = this.$util.getFiltersByCommand(this.command);

                this.loadData(items);
            },
            loadData: async function (items) {
                this.reportData = items;
                this.loadChart();
            },
            loadChart: function () {
                if (!this.$refs.chart) {
                    return;
                }

                this.$refs.chart.reset();
                this.$refs.chart.addSerie({
                    id: 1,
                    type: 'pie',
                    radius: '55%',
                    center: ['50%', '50%'],
                    data: this.reportData.map(item => ({
                        ...item,
                        value: Number(item.QUANT),
                        name: `${item.DESC_SERVICO} - ${this.$util.numberFormat(Number(item.QUANT))} ${this.$t('OPERATION')}: ${item.OPERACAO}`,
                    })),
                });
            },
        },
    };
</script>
