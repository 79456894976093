/* eslint-disable object-curly-newline */
import { Entity } from '@inputs/entity.ts';

import assetVersion from '@autocomplete/util/customProvider/asset-version';
import itemFabricator from '@autocomplete/util/customProvider/item-fabricator';
import layersGis from '@autocomplete/util/customProvider/layers-gis';
import contract from '@autocomplete/util/customProvider/contract';
import issueType from '@autocomplete/util/customProvider/issueType';
import projectSimplify from '@autocomplete/util/customProvider/projectSimplify';
import assitanceReason from '@autocomplete/util/customProvider/assitanceReason';
import detailedCity from '@select/util/customProvider/detailedCity';
import worksites from '@autocomplete/util/customProvider/worksites';
import assitanceMotive from '@autocomplete/util/customProvider/assitanceMotive';
import stockSpecialMovement from '@autocomplete/util/customProvider/stockSpecialMovement';
import invoice from '@autocomplete/util/customProvider/invoice';

const customProvider = {
    layerGis: new Entity({ fieldNameId: 'VALUE', descriptionField: 'KEY', commandName: 'ConsultarInfGeograficasPS', providerField: 'DADOS_GIS', customProvider: layersGis }),
    assetVersion: new Entity({ fieldNameId: 'ID_VERSAO', descriptionField: 'DESC_VERSAO', commandName: 'ConsultarVersoesPontoServico', providerField: 'VERSOES_PONTO_SERVICO.VERSAO_PONTO_SERVICO', customProvider: assetVersion }),
    itemFabricator: new Entity({ fieldNameId: 'ID_ITEM_FABRICANTE', descriptionField: 'DESC_ITEM_FABRICANTE', commandName: 'ConsultarItemFabricante', providerField: 'FABRICANTES.FABRICANTE', customProvider: itemFabricator }),
    contract: new Entity({ fieldNameId: 'ID_CONTRATO', descriptionField: 'DESC_ORCAMENTO', commandName: 'ConsultarContratos', providerField: 'CONTRATOS.CONTRATO', customProvider: contract }),
    issueTypeWithAll: new Entity({ fieldNameId: 'ID_TIPO_OCORRENCIA', descriptionField: 'DESC_TIPO_OCORRENCIA', commandName: 'ConsultarTipoOcorrencia', providerField: 'TIPOS_OCORRENCIA.TIPO_OCORRENCIA', customProvider: issueType }),
    projectSimplify: new Entity({ fieldNameId: 'ID_OBRA', descriptionField: 'description', commandName: 'ConsultarObrasSimplificada', providerField: 'OBRAS.OBRA', searchField: 'PALAVRA_CHAVE', params: { CONTRATADAS: 1 }, customProvider: projectSimplify }),
    assitanceReason: new Entity({ fieldNameId: 'ID_MOTIVO_ATENDIMENTO_PS', descriptionField: 'DESC_MOTIVO_ATENDIMENTO_PS', commandName: 'ConsultarMotivosAtendimentoPS', providerField: 'MOTIVOS_ATENDIMENTO.MOTIVO_ATENDIMENTO', customProvider: assitanceReason }),
    cities: new Entity({ fieldNameId: 'ID_MUNICIPIO', descriptionField: 'description', commandName: 'ConsultarMunicipioPorPalavra', providerField: 'MUNICIPIOS.MUNICIPIO', searchField: 'PALAVRA', minToSearch: 2, customProvider: detailedCity }),
    worksiteWithAll: new Entity({ fieldNameId: 'ID_PARQUE_SERVICO', descriptionField: 'NOME_PARQUE_SERVICO', commandName: 'ConsultarParquesServico', providerField: 'PARQUES_SERVICO.PARQUE_SERVICO', params: { ATIVO: 1 }, customProvider: worksites }),
    assitanceMotiveWithNone: new Entity({ fieldNameId: 'ID_MOTIVO_ATENDIMENTO_PS', descriptionField: 'DESC_MOTIVO_ATENDIMENTO_PS', commandName: 'ConsultarMotivosAtendimentoPS', providerField: 'MOTIVOS_ATENDIMENTO.MOTIVO_ATENDIMENTO', customProvider: assitanceMotive }),
    stockSpecialMovement: new Entity({ fieldNameId: 'ID_MOV_ESPECIAL_ESTOQUE', descriptionField: 'description', commandName: 'ConsultarMovimentosEspeciaisEstoque', providerField: 'MOVIMENTOS_ESPECIAIS_ESTOQUE.MOV_ESPECIAL_ESTOQUE', searchField: 'BUSCA', customProvider: stockSpecialMovement, minToSearch: 2 }),
    invoice: new Entity({ fieldNameId: 'ID_NF_ENTRADA', descriptionField: 'description', commandName: 'ConsultarNotasEntrada', providerField: 'NOTAS_ENTRADA.NOTA_ENTRADA', searchField: 'BUSCA', customProvider: invoice, minToSearch: 2 }),
};

export default customProvider;
