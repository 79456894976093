const paths = [
    {
        path: '/rejection-reason-type-list',
        name: 'RejectionReasonTypeList',
        title: 'REJECTION_REASON_TYPES',
        icon: 'widgets',
        menu: 'ticket',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "rejectionReasonType" */ /* webpackMode: "lazy" */ '@/pages/rejectionReasonType/RejectionReasonTypeList.vue'),
    },
    {
        path: '/rejection-reason-type-general',
        name: 'RejectionReasonTypeGeneral',
        title: 'REJECTION_REASON_TYPE',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "rejectionReasonType" */ /* webpackMode: "lazy" */ '@/pages/rejectionReasonType/RejectionReasonTypeGeneral.vue'),
    },
];

export default paths;
