const paths = [
    {
        path: '/complainer',
        name: 'Complainer',
        title: 'COMPLAINER',
        favCategory: 'ticket',
        component: () => import(/* webpackChunkName: "complainer" */ /* webpackMode: "lazy" */ '@/pages/ticket/complainer/Complainer.vue'),
    },
    {
        path: '/complainer-address',
        name: 'ComplainerAddress',
        title: 'COMPLAINER_ADDRESS',
        component: () => import(/* webpackChunkName: "complainer" */ /* webpackMode: "lazy" */ '@/pages/ticket/complainer/ComplainerAddress.vue'),

    },
    {
        path: '/complainer-general',
        name: 'ComplainerGeneral',
        title: 'COMPLAINER_GENERAL',
        component: () => import(/* webpackChunkName: "complainer" */ /* webpackMode: "lazy" */ '@/pages/ticket/complainer/ComplainerGeneral.vue'),

    },
    {
        path: '/complainer-details',
        name: 'ComplainerDetails',
        title: 'COMPLAINER_DETAILS',
        component: () => import(/* webpackChunkName: "complainer" */ /* webpackMode: "lazy" */ '@/pages/ticket/complainer/ComplainerDetails.vue'),

    },
    {
        path: '/complainer-blocking',
        name: 'ComplainerBlocking',
        title: 'COMPLAINER_BLOCKING',
        component: () => import(/* webpackChunkName: "complainer" */ /* webpackMode: "lazy" */ '@/pages/ticket/complainer/ComplainerBlocking.vue'),

    },
    {
        path: '/complainer-issue',
        name: 'ComplainerIssue',
        title: 'COMPLAINER_ISSUE',
        component: () => import(/* webpackChunkName: "complainer" */ /* webpackMode: "lazy" */ '@/pages/ticket/complainer/ComplainerIssue.vue'),

    },
    {
        path: '/complainer-list',
        name: 'ComplainerList',
        title: 'COMPLAINER_LIST',
        menu: 'ticket',
        component: () => import(/* webpackChunkName: "complainer" */ /* webpackMode: "lazy" */ '@/pages/ticket/complainer/ComplainerList.vue'),

    },
    {
        path: '/complainer-list',
        name: 'ComplainerAttributes',
        title: 'COMPLAINER_ATTRIBUTES',
        component: () => import(/* webpackChunkName: "complainer" */ /* webpackMode: "lazy" */ '@/pages/ticket/complainer/ComplainerAttributes.vue'),

    },
    {
        path: '/complainer-configuration',
        name: 'ComplainerConfiguration',
        title: 'COMPLAINER_CONFIGURATION',
        menu: 'ticket',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "complainer" */ /* webpackMode: "lazy" */ '@/pages/ticket/complainer/ComplainerConfiguration.vue'),

    },
];

export default paths;
