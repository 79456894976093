const paths = [
    {
        path: '/light-spots-modality-list',
        name: 'LightSpotsModalityList',
        title: 'LIGHT_SPOTS_MODALITY',
        icon: 'fa fa-lightbulb',
        menu: 'maintenance',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "lightSpotsModality" */ /* webpackMode: "lazy" */ '@/pages/entries/lightSpotsModality/LightSpotsModalityList.vue'),
    },
    {
        path: '/light-spots-modality',
        name: 'LightSpotsModality',
        title: 'LIGHT_SPOTS_MODALITY',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "lightSpotsModality" */ /* webpackMode: "lazy" */ '@/pages/entries/lightSpotsModality/LightSpotsModality.vue'),
    },
];

export default paths;
