export default [
    {
        path: '/inconsistencies-approval-panel-list',
        title: 'INCONSISTENCIES_APPROVAL',
        name: 'InconsistenciesApprovalPanelList',
        icon: 'fas fa-exclamation-circle',
        menu: 'ticket',
        component: () => import(/* webpackChunkName: "inconsistenciesApprovalPanel" */ /* webpackMode: "lazy" */ '@/pages/issue/inconsistenciesApprovalPanel/InconsistenciesApprovalPanelList.vue'),
    },
    {
        path: '/inconsistencies-disapproval',
        title: 'INCONSISTENCIES_DISAPPROVAL',
        name: 'InconsistenciesDisapproval',
        component: () => import(/* webpackChunkName: "inconsistenciesApprovalPanel" */ /* webpackMode: "lazy" */ '@/pages/issue/inconsistenciesApprovalPanel/InconsistenciesDisapproval.vue'),
    },
];
