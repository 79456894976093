<script>
    export default {
        name: 'ATextField',
        functional: true,
        render: function (createElement, { data, children }) {
            data.attrs = data.attrs || {};
            data.attrs.outlined = Object.keys(data.attrs).includes('outlined') ? data.attrs.outlined : true;
            data.attrs.dense = Object.keys(data.attrs).includes('dense') ? data.attrs.dense : true;

            if (data.attrs.type) {
                if (data.attrs.type.toLowerCase() === 'email') {
                    return createElement('ATextFieldEmail', data, children);
                } if (data.attrs.type.toLowerCase() === 'phone') {
                    return createElement('ATextFieldPhone', data, children);
                } if (data.attrs.type.toLowerCase() === 'float') {
                    return createElement('ATextFieldFloat', data, children);
                } if (data.attrs.type.toLowerCase() === 'monetary') {
                    return createElement('AMonetaryField', data, children);
                }
            }

            return createElement('VTextField', data, children);
        },
    };
</script>
<style>
.application .v-text-field--outlined {
    border-radius: 2px;
}
</style>