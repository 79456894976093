const paths = [
    {
        path: '/attributes',
        name: 'Attributes',
        title: 'ATTRIBUTES',
        menu: 'registration',
        icon: 'apps',
        component: () => import(/* webpackChunkName: "attributes" */ /* webpackMode: "lazy" */ '@/pages/attributes/Attributes.vue'),


    },
];

export default paths;
