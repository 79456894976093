import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import { parseSuccessResponse, showErrorsResponse } from '../utils';
export class SaveCompanyDataPolicy extends CallCommand {
    constructor() {
        super({
            name: 'post_company-data-policies',
            type: 'NONE',
            fields: {
                dataPolicy: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class EditCompanyDataPolicy extends CallCommand {
    constructor() {
        super({
            name: 'patch_company-data-policies_0',
            type: 'SAVE',
            fields: {
                dataPolicy: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
                dataPolicyId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${value.toString()} }`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, debug, config) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
