<script>
    export default {
        name: 'AForm',
        functional: true,
        render: function (createElement, { data, children }) {
            
            children = [
                createElement('input', {
                    attrs: {
                        name: "avoid-form-single-input-enter-behavior-page-reloads-on-submit",
                        id: "avoid-form-single-input-enter-behavior-page-reloads-on-submit",
                        type: "text",
                        hidden: true,
                        disabled: true,
                    }
                }),
                ...children
            ]

            return createElement('VForm', data, children);
        },
    };
</script>
