export default [
    {
        path: '/ticket-alert-type-list',
        title: 'TICKET_ALERT_TYPES',
        name: 'TicketAlertTypeList',
        icon: 'widgets',
        menu: 'ticket',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "ticketAlertType" */ /* webpackMode: "lazy" */ '@/pages/issue/ticketAlertType/TicketAlertTypeList.vue'),
    },
    {
        path: '/ticket-alert-type',
        title: 'TICKET_ALERT_TYPE',
        name: 'TicketAlertType',
        component: () => import(/* webpackChunkName: "ticketAlertType" */ /* webpackMode: "lazy" */ '@/pages/issue/ticketAlertType/TicketAlertType.vue'),
    },
];
