const paths = [
    {
        path: '/category-functions-employee-list',
        name: 'CategoryFunctionEmployeeList',
        icon: 'fa fa-briefcase',
        title: 'CATEGORY_FUNCTION_EMPLOYEE',
        menu: 'humanResources',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "jobTitlesFunctions" */ /* webpackMode: "lazy" */ '@/pages/humansResources/jobTitlesFunctions/CategoryFunctionEmployeeList.vue'),
    }, {
        path: '/category-functions-employee-details',
        name: 'CategoryFunctionEmployeeDetails',
        title: 'CATEGORY_FUNCTION_EMPLOYEE',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "jobTitlesFunctions" */ /* webpackMode: "lazy" */ '@/pages/humansResources/jobTitlesFunctions/CategoryFunctionEmployeeDetails.vue'),
    },
];

export default paths;
