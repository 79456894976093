const paths = [
    {
        path: '/solid-waste-document-list',
        title: 'WASTE_TRANSPORT_MANIFEST',
        name: 'SolidWasteDocumentsList',
        icon: 'fa fa-calculator',
        menu: 'asset',
        component: () => import(/* webpackChunkName: "asset" */ /* webpackMode: "lazy" */ '@/pages/solidWaste/SolidWasteDocumentsList.vue'),
    },
    {
        path: '/solid-waste-received-itens',
        name: 'SolidWasteReceivedItens',
        component: () => import(/* webpackChunkName: "asset" */ /* webpackMode: "lazy" */ '@/pages/solidWaste/SolidWasteReceivedItens.vue'),
    },
];

export default paths;
