const paths = [
    {
        path: '/toolbar-notification-dialog',
        name: 'ToolbarNotificationDialog',
        // title: 'NOTIFICATION_DIALOG',
        component: () => import(/* webpackChunkName: "toolbarNotification" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ '@/components/toolbarNotification/ToolbarNotificationDialog.vue'),
    },
];

export default paths;
