import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import { parseSuccessResponse, showErrorsResponse } from '../utils';
export class GetWebhookClient extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_webhook-client_0',
            type: 'NONE',
            fields: {
                include: new Field({
                    name: 'BODY',
                }),
                clientAppId: new Field({
                    name: 'client_app_id',
                }),
            },
            customRequestParser: function (params) {
                const filter = {
                    include: [
                        { relation: 'webhook' },
                    ],
                };
                const pathParams = {
                    PARAM_0: params.clientAppId,
                };
                return {
                    PATH_PARAMS: JSON.stringify(pathParams),
                    BODY: `filter=${encodeURIComponent(JSON.stringify(filter))}`,
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class SaveWebhookClient extends CallCommand {
    constructor() {
        super({
            name: 'post_comp_webhook-client_0',
            type: 'NONE',
            fields: {
                clientAppId: new Field({
                    name: 'client_app_id',
                }),
                webhookId: new Field({
                    name: 'webhook_id',
                }),
                clientUriPath: new Field({
                    name: 'client_uri_path',
                }),
                companyId: new Field({
                    name: 'company_id',
                }),
            },
            customRequestParser: function (params) {
                const pathParams = {
                    PARAM_0: params.clientAppId,
                };
                const data = {
                    webhook_id: params.webhookId,
                    client_uri_path: params.clientUriPath,
                    company_id: params.companyId,
                };
                return {
                    PATH_PARAMS: JSON.stringify(pathParams),
                    BODY: JSON.stringify(data),
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class EditWebhookClient extends CallCommand {
    constructor() {
        super({
            name: 'patch_comp_webhook-client_0_1',
            type: 'NONE',
            fields: {
                clientAppId: new Field({
                    name: 'client_app_id',
                }),
                webhookClientId: new Field({
                    name: 'webhook_client_id',
                }),
                webhookId: new Field({
                    name: 'webhook_id',
                }),
                clientUriPath: new Field({
                    name: 'client_uri_path',
                }),
            },
            customRequestParser: function (params) {
                const pathParams = {
                    PARAM_0: params.clientAppId,
                    PARAM_1: params.webhookClientId,
                };
                const data = {
                    webhook_id: params.webhookId,
                    client_uri_path: params.clientUriPath,
                };
                return {
                    PATH_PARAMS: JSON.stringify(pathParams),
                    BODY: JSON.stringify(data),
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class DeleteWebhookClient extends CallCommand {
    constructor() {
        super({
            name: 'delete_comp_webhook-client_0_1',
            type: 'NONE',
            fields: {
                clientAppId: new Field({
                    name: 'client_app_id',
                }),
                webhookClientId: new Field({
                    name: 'webhook_client_id',
                }),
            },
            customRequestParser: function (params) {
                const pathParams = {
                    PARAM_0: params.clientAppId,
                    PARAM_1: params.webhookClientId,
                };
                return {
                    PATH_PARAMS: JSON.stringify(pathParams),
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
