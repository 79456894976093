<template>
    <a-container fluid class='pa-0 ma-0'>
        <a-container class='px-5'>
            <a-row align='center' dense>
                <a-col cols='auto'>
                    <span class='text-h5 dark-text'>
                        {{$t('STATUS')}}
                    </span>
                </a-col>
                <a-spacer />
                <a-col cols='auto' class='mr-3'>
                    <slot name='right-header' />
                </a-col>
            </a-row>
        </a-container>
        <a-divider />
        <a-container :style='statusBorder' class='px-6 pb-4'>
            <a-spacer />
            <a-row
                dense
                justify='start'
                align='center'
            >
                <a-col class='dark--text text-h5 font-weight-medium'>
                    {{currentStatus[_statusTitle]}}
                </a-col>
            </a-row>
            <slot name='status-body' />
        </a-container>
    </a-container>
</template>

<script>
    export default {
        name: 'AStatusCard',
        props: {
            /**
             * Status list for the desired entity
             * @type {Array}
             * @default {[]}
             */
            _statusList: {
                type: Array,
                default: [],
                required: true,
            },
            /**
             * Identifier for the current status in which the entity is in
             * @type {Number}
             * @default {undefined}
             */
            _currentStatusId: {
                type: Number,
                default: undefined,
                required: true,
            },
            /**
             * The object key for the status identifier
             * @type {String}
             * @default {''}
             */
            _statusKey: {
                type: String,
                default: '',
                required: true,
            },
            /**
             * The object key for the status title
             * @type {String}
             * @default {''}
             */
            _statusTitle: {
                type: String,
                default: '',
                required: true,
            },
            /**
             * The object key for the status color
             * @type {String}
             * @default {''}
             */
            _statusColor: {
                type: String,
                default: '',
                required: true,
            },
        },
        data: function () {
            return {
                file: null,
            };
        },
        computed: {
            statusBorder: function () {
                return `border-left: .5rem solid ${this.currentStatus ? this.currentStatus[this._statusColor] : ''};`;
            },
            currentStatus: function () {
                return this._statusList.find(status => (status[this._statusKey] === this._currentStatusId)) || {};
            },
        },
        mounted: function () {
            this.load();
        },
        methods: {
            load: function () {

            },
        },

    };
</script>
