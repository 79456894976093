import Vue from 'vue';
import i18n from '@/i18n';
import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
const { $autocomplete, $select, $util, } = Vue.prototype;
export class GetMaintanancePerDistrict extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarAtendimentosPorBairro',
            type: 'SEARCH',
            node: 'BAIRROS.BAIRRO',
            fields: {
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('WORKSITE'),
                        entity: $autocomplete.worksite,
                        'entity-params': { ATIVO: 1 },
                        clearable: false,
                    },
                }),
                regionId: new Field({
                    name: 'ID_REGIAO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('REGION'),
                        entity: $autocomplete.region,
                        'entity-params': {},
                        'require-params': ['ID_PARQUE_SERVICO'],
                        clearable: true,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.regionId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        },
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.regionId.props.disabled = !fields.worksiteId.value;
                            },
                        },
                    ],
                }),
                startDate: new Field({
                    name: 'DATA_INICIO',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('INITIAL_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                endDate: new Field({
                    name: 'DATA_CONCLUSAO',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('ENDING_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                validAssets: new Field({
                    name: 'PONTOS_VALIDOS',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('VALID_ASSETS'),
                        entity: $select.validAssets,
                    },
                }),
                assetStatusId: new Field({
                    name: 'ID_STATUS_PONTO_SERVICO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('STATUS'),
                        entity: $select.assetStatus,
                    },
                }),
                attendanceWithSoluction: new Field({
                    name: 'ATENDIMENTO_COM_SOLUCAO',
                    component: 'ACheckbox',
                    parser: $util.booleanToNumber,
                    props: {
                        label: i18n.t('ONLY_MAINTENANCE_WITH_SOLUCTION'),
                        'hide-details': true,
                    },
                }),
                allDistricts: new Field({
                    name: 'TODOS_BAIRROS',
                    component: 'ACheckbox',
                    parser: $util.booleanToNumber,
                    props: {
                        label: i18n.t('ALL_DISTRICTS'),
                        'hide-details': true,
                    },
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetAttendanceDeadlinePriority extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarPrazoAtendimentoPrioridade',
            type: 'SEARCH',
            node: 'PRIORIDADES.PRIORIDADE',
            fields: {
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('WORKSITE'),
                        entity: $autocomplete.worksite,
                        'entity-params': { ATIVO: 1 },
                        clearable: false,
                    },
                }),
                startDate: new Field({
                    name: 'DATA_INICIAL',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('INITIAL_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                endDate: new Field({
                    name: 'DATA_FINAL',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('ENDING_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                cityId: new Field({
                    name: 'ID_MUNICIPIO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('COUNTY'),
                        entity: $select.city,
                        'entity-params': { SOMENTE_ATIVOS: 1 },
                        'require-params': ['ID_PARQUE_SERVICO'],
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.cityId.props['entity-params'] = {
                                    ...fields.cityId.props['entity-params'],
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        },
                    ],
                }),
                regionId: new Field({
                    name: 'ID_REGIAO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('REGION'),
                        entity: $select.regions,
                        'entity-params': {},
                        'require-params': ['ID_MUNICIPIO'],
                        disabled: false,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_MUNICIPIO',
                            handler: (fields) => {
                                fields.regionId.props.disabled = !fields.cityId.value;
                                fields.regionId.props['entity-params'] = {
                                    ...fields.regionId.props['entity-params'],
                                    ID_MUNICIPIO: fields.cityId.value,
                                };
                            },
                        },
                    ],
                }),
                districtId: new Field({
                    name: 'ID_BAIRRO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('DISTRICT'),
                        entity: $select.districts,
                        'entity-params': {},
                        'require-params': ['ID_MUNICIPIO'],
                        disabled: false,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: ['ID_MUNICIPIO', 'ID_REGIAO'],
                            handler: (fields) => {
                                fields.districtId.props.disabled = !fields.cityId.value;
                                fields.districtId.props['entity-params'] = {
                                    ...fields.districtId.props['entity-params'],
                                    ID_MUNICIPIO: fields.cityId.value,
                                    ID_REGIAO: fields.regionId.value,
                                };
                            },
                        },
                    ],
                }),
                userCompanyId: new Field({
                    name: 'ID_EMPRESA_USUARIA',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('USER_COMPANY'),
                        entity: $autocomplete.userCompany,
                    },
                }),
                locationGroupingId: new Field({
                    name: 'ID_AGRUPAMENTO_LOCALIZACAO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('ALLOWED_LOCATIONS'),
                        entity: $autocomplete.locationGrouping,
                    },
                }),
                situation: new Field({
                    name: 'SITUATION',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
