const paths = [
    {
        path: '/model-type-list',
        title: 'MODEL_TYPE',
        name: 'ModelTypeList',
        icon: 'fas fa-file-invoice',
        menu: 'contracts',
        component: () => import(/* webpackChunkName: "modelType" */ /* webpackMode: "lazy" */ '@/pages/modelType/ModelTypeList.vue'),

    },
    {
        path: '/model-type-details',
        title: 'MODEL_TYPE_DETAILS',
        name: 'ModelTypeDetails',
        component: () => import(/* webpackChunkName: "modelType" */ /* webpackMode: "lazy" */ '@/pages/modelType/ModelTypeDetails.vue'),

    },
];
export default paths;
