import i18n from '@/i18n';
import paths from '@/api/paths';
import { v4 as uuidv4 } from 'uuid';

function getComponent (page) {
    const name = !page.name.split('-')[1] ? `${page.name}-${uuidv4()}` : page.name;
    const component = paths.filter(p => p.name === name.split('-')[0])[0];
    if (!page.title) {
        component.title = i18n.t(component.title);
    }
    return {
        ...component, ...page, name: name, tooltip: false,
    };
}

const customWindow = {
    state: {
        activeTab: null,
        pages: [],
        currentPage: null,
        dialogs: [],
        currentDialog: null,
        history: [],
        disablePageWrapperScroll: false,
        homePage: null,
    },
    getters: {
        getCurrentPage: state => state.currentPage,
        getCurrentDialog: state => state.currentDialog,
        getPages: state => state.pages,
        getDialogs: state => state.dialogs,
        getHistory: state => state.history,
        getHomePage: state => state.homePage,
    },
    mutations: {
        SET_CURRENT_PAGE: function (state, value) {
            state.currentPage = value;
        },
        SET_CURRENT_DIALOG: function (state, value) {
            state.currentDialog = value;
        },
        SET_DISABLE_PAGE_WRAPPER_SCROLL: function (state, value) {
            state.disablePageWrapperScroll = value;
        },
        ADD_PAGE_TO_HISTORY: function (state, page) {
            state.history = state.history.filter(h => h !== page.name);
            state.history.push(page.name);
        },
        REMOVE_PAGE_OF_HISTORY: function (state, page) {
            state.history = state.history.filter(h => h !== page.name);
        },
        ADD_PAGE: function (state, page) {
            state.pages.push(page);
        },
        CLOSE_PAGE: function (state, page) {
            state.pages = state.pages.filter(p => p.name !== page.name);
        },
        ADD_DIALOG: function (state, page) {
            state.dialogs.push(page);
        },
        CLOSE_DIALOG: function (state, page) {
            state.dialogs = state.dialogs.filter(d => d.name !== page.name);
        },
        SET_HOME_PAGE: function (state, page) {
            state.homePage = page;
        },
    },
    actions: {
        OPEN_PAGE: function ({ getters, dispatch }, config) {
            const {
                page, isDialog, props, events, dialog,
            } = config;
            page.props = props || {};
            page.propsDialog = { fullscreen: true, ...dialog, persistent: true };
            if (events) {
                page.events = events;
            }

            if (isDialog || (getters.getDialogs).length) {
                dispatch('ADD_DIALOG', page);
            } else {
                dispatch('ADD_PAGE', page);
            }
        },
        CHANGE_PAGE: function ({ commit, dispatch }, page) {
            const component = getComponent(page);
            commit('ADD_PAGE_TO_HISTORY', component);
            dispatch('CHANGE_CURRENT_PAGE', component.name);
        },
        CHANGE_CURRENT_PAGE: function ({ commit }, value) {
            commit('SET_CURRENT_PAGE', value);
        },
        ADD_PAGE: function ({ commit, dispatch }, page) {
            const component = getComponent(page);
            commit('ADD_PAGE', component);
            commit('ADD_PAGE_TO_HISTORY', component);
            dispatch('CHANGE_CURRENT_PAGE', component.name);
        },
        CLOSE_PAGE: function ({ getters, commit, dispatch }, page) {
            commit('CLOSE_PAGE', page);
            commit('REMOVE_PAGE_OF_HISTORY', page);
            const lastIndex = (getters.getHistory).length - 1;
            if (page.name === getters.getCurrentPage) {
                dispatch('CHANGE_CURRENT_PAGE', (getters.getHistory)[lastIndex]);
            }
        },
        // @@@@ DIALOGS @@@@
        CHANGE_CURRENT_DIALOG: function ({ commit }, value) {
            commit('SET_CURRENT_DIALOG', value);
        },
        ADD_DIALOG: function ({ commit, dispatch }, page) {
            const component = getComponent(page);
            component.value = true;
            commit('ADD_DIALOG', component);
            dispatch('CHANGE_CURRENT_DIALOG', component.name);
        },
        CLOSE_DIALOG: function ({ getters, commit, dispatch }, page) {
            commit('CLOSE_DIALOG', page);
            if (getters.getCurrentDialog === page.name && (getters.getDialogs).length) {
                const lastIndex = (getters.getDialogs).length - 1;
                const currentDialog = (getters.getDialogs)[lastIndex >= 0 ? lastIndex : null].name;
                dispatch('CHANGE_CURRENT_DIALOG', currentDialog);
            } else {
                dispatch('CHANGE_CURRENT_DIALOG', null);
            }
        },
        CLOSE_CURRENT_DIALOG: function ({ getters, dispatch }) {
            const page = { name: getters.getCurrentDialog };
            dispatch('CLOSE_DIALOG', page);
        },
        CLOSE_CURRENT_PAGE: function ({ getters, dispatch }) {
            const page = { name: getters.getCurrentPage };
            dispatch('CLOSE_PAGE', page);
        },
        HANDLER_CLOSE_PAGE: function ({ getters, dispatch }) {
            if (getters.getCurrentDialog) {
                dispatch('CLOSE_CURRENT_DIALOG');
            } else {
                dispatch('CLOSE_CURRENT_PAGE');
            }
        },
        SET_PAGE_NAME: function ({ getters }, page) {
            if (getters.getCurrentDialog) {
                getters.getDialogs.filter(d => d.name === getters.getCurrentDialog)[0].title = page.newTitle;
            } else if (page.name && getters.getPages) {
                getters.getPages.filter(p => p.name === page.name)[0].title = page.newTitle;
            }
        },
        SET_PAGE_PROPS: function ({ getters }, props) {
            if (getters.getCurrentDialog) {
                getters.getDialogs.filter(d => d.name === getters.getCurrentDialog)[0].props = props;
            } else {
                getters.getPages.filter(p => p.name === getters.getCurrentPage)[0].props = props;
            }
        },
        SET_HOME_PAGE: function ({ commit }, page) {
            const component = getComponent(page);
            component.title = i18n.t(component.title);
            commit('SET_HOME_PAGE', component);
        },
    },
};

export default customWindow;
