const paths = [
    {
        path: '/ticket-category-list',
        name: 'TicketCategoryList',
        title: 'TICKET_CATEGORIES',
        icon: 'widgets',
        menu: 'ticket',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "ticketCategory" */ /* webpackMode: "lazy" */ '@/pages/ticketCategory/TicketCategoryList'),
    },
    {
        path: '/ticket-category-general',
        name: 'TicketCategoryGeneral',
        title: 'TICKET_CATEGORY',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "ticketCategory" */ /* webpackMode: "lazy" */ '@/pages/ticketCategory/TicketCategoryGeneral'),
    },
];

export default paths;
