const paths = [
    {
        path: '/comparative-budget-report',
        name: 'ComparativeBudgetReport',
        title: 'COMPARATIVE_BUDGET_REPORT',
        icon: 'fas fa-clipboard',
        menu: 'budgets',
        categories: [
            'reports',
        ],
        component: () => import(/* webpackChunkName: "comparativeBudgetReport" */ /* webpackMode: "lazy" */ '@/pages/budget/comparativeBudgetReport/ComparativeBudgetReport.vue'),
    },
];

export default paths;
