export default function stockCenter (provider) {
    return new Promise((resolve) => {
        const resultProvider = [];

        provider.forEach((item) => {
            resultProvider.push({ ID_CENTRO_ESTOQUE: item.ID_CENTRO_ESTOQUE, DESC_CENTRO_ESTOQUE: `${item.DESC_CENTRO_ESTOQUE}` });
        });

        resolve(resultProvider);
    });
}
