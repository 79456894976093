<template lang='pug'>
    a-text-field(ref='field' v-bind='attrs' v-on='$listeners' :rules='rulesComp' :prefix='prefix' :suffix='suffix')
</template>
<script>
    import { getProps } from '../../util/helpers';

    export default {
        name: 'ATextFieldEmail',
        components: {
            ATextFieldEmail: {
                inheritAttrs: false,
            },
        },
        props: {
            enableRuleEmail: {
                type: Boolean,
                require: true,
                default: true,
            },
            value: {
                type: String,
                default: '',
            },
            rules: {
                type: Array,
                default: function () {
                    return [];
                },
            },
            type: {
                type: String,
                require: true,
                default: 'email',
                validator: function (value) {
                    return value === 'email';
                },
            },
            prefix: {
                type: String,
                default: null,
            },
            suffix: {
                type: String,
                default: null,
            },
        },
        data: function () {
            return {
                ruleEmail: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return !this.enableRuleEmail || pattern.test(this.getEmail(value)) || this.$t('INVALID_EMAIL');
                },
            };
        },
        computed: {
            attrs: function () {
                return getProps(this.$attrs, [['rules', 'prefix', 'suffix', 'type']], false);
            },
            valid: function () {
                return this.ruleEmail(this.value) === true;
            },
            rulesComp: function () {
                if (this.rules != null && Array.isArray(this.rules)) {
                    const index = this.rules.indexOf(this.ruleEmail);
                    return index < 0 ? [...this.rules, this.ruleEmail] : this.rules;
                }
                return [this.ruleEmail];
            },
            email: function () {
                return this.getEmail();
            },
        },
        methods: {
            getEmail: function (value) {
                return `${this.prefix ? this.prefix : ''}${value || this.value || ''}${(this.suffix != null && this.suffix !== '') ? `@${this.suffix}` : ''}`;
            },
        },
    };
</script>
<style lang='stylus' scoped>
    .v-text-field__suffix:before
        content '@'
</style>
