const paths = [
    {
        path: '/asset-materials-report',
        name: 'AssetMaterialsReport',
        title: 'ASSET_MATERIALS_REPORT',
        icon: 'mdi-file-document',
        menu: 'asset',
        categories: [
          'reports',
        ],
        component: () => import(/* webpackChunkName: "assetMaterialsReport" */ /* webpackMode: "lazy" */ '@/pages/assetMaterialsReport/AssetMaterialsReport.vue'),
    },
];

export default paths;
