const paths = [
    {
        path: '/financial-extract',
        name: 'FinancialExtract',
        title: 'FINANCIAL_EXTRACT',
        icon: 'fas fa-cash-register',
        menu: 'financial',
        component: () => import(/* webpackChunkName: "financialExtract" */ /* webpackMode: "lazy" */ '@/pages/financialExtract/FinancialExtract.vue'),
    },
];

export default paths;
