<template>
    <a-container ref='container' v-resize='onResize' :class='{"container":true, "fullscreen": chartMode==="pie" && _chartZoom}' style='height: 100%;'>
        <a-dynamic-command-filter
            ref='filter'
            :key='getAssetMaintenanceMonitorData.name'
            :_sidebar-props='{ _hideActions: true }'
            :_loading.sync='loading'
            filter-group='all'
            exhibition-mode='sidebar'
            :_fab='false'
            class='pivot-filter'
            :_command='getAssetMaintenanceMonitorData'
            @updateValues='updateDashData'
        />
        <chart
            v-show='chartMode === "bar"'
            ref='barChart'
            :_options='barChartOptions'
            style='height: 100%;'
        />
        <chart
            v-show='chartMode === "pie"'
            ref='chart'
            :_options='chartOptions'
            style='height: 100%;'
        />
    </a-container>
</template>

<script>
    import consts from './services/consts';
    import { GetAssetMaintenanceMonitorData } from './services/request';

    export default {
        name: 'MaintenanceByTeamMonitor',
        props: {
            /**
             * Prop to show if page is currently active
             * @type {Boolean}
             * @default {false}
            */
            _activePage: {
                type: Boolean,
                default: false,
            },
            /**
             * Command filter values sent from the Dashboard
             * @type {Object}
             * @default {}
             */
            _dashdata: {
                type: Object,
                default: () => ({
                    filters: [],
                }),
            },
            /**
             * Parameter to determine the chart's height
             * @type {[String, Number]}
             * @default {undefined}
             */
            _chartHeight: {
                type: [String, Number],
                default: undefined,
            },
            /**
             * Parameter to determine the chart's is in zoom
             * @type {Boolean}
             * @default {undefined}
             */
            _chartZoom: {
                type: Boolean,
                default: false,
            },
            /**
             * sync with parent loading
             * @type {Boolean}
             * @default {false}
             */
            _loading: {
                type: Boolean,
                default: false,
            },
            /**
             * sync with parent empty-data
             * @type {Boolean}
             * @default {false}
             */
            _emptyData: {
                type: Boolean,
                default: false,
            },
        },
        data: function () {
            return {
                dashdata: this._dashdata,
                reportData: {},
                chartMode: 'pie',
                chartOptions: {
                    maintainAspectRatio: false,
                    tooltip: {
                        trigger: 'item',
                        formatter: consts.DEFAULT_TOOLTIP,
                        confine: true,
                    },
                },
                barChartOptions: {
                    maintainAspectRatio: true,
                    grid: {
                        containLabel: true,
                        left: '25%',
                    },
                    yAxis: {
                        type: 'category',
                        name: this.$t('TEAM'),
                    },
                    xAxis: {
                        type: 'value',
                        name: this.$t('ATTENDANCE'),
                        axisLabel: {
                            formatter: '{value}',
                        },
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: `<b>${'Equipe'}:</b> {b}<br /><b>${'Atendimentos'}:</b> {c}`,
                        confine: true,
                    },
                },

                consts: consts,
                chartHeight: this._chartHeight || consts.DEFAULT_HEIGHT,
                fluidChartHeight: 750,
            };
        },
        computed: {
            getAssetMaintenanceMonitorData: function () {
                const command = new GetAssetMaintenanceMonitorData();
                command.initializeLoading = false;
                command.node = 'MONITOR.EQUIPES.EQUIPE';
                return command;
            },
            loading: {
                get: function () {
                    return this._loading;
                },
                set: function (value) {
                    this.$emit('update:_loading', value);
                },
            },
            emptyData: {
                get: function () {
                    return this._emptyData;
                },
                set: function (value) {
                    this.$emit('update:_emptyData', value);
                },
            },
        },
        watch: {
            dashdata: {
                deep: true,
                handler: function (value) {
                    this.$emit('update-dashdata', value);
                },
            },
            _chartHeight: {
                handler: function (value) {
                    this.chartHeight = value;
                    this.$nextTick(this.onResize);
                },
            },
            _chartZoom: {
                handler: function () {
                    this.$nextTick(this.onResize);
                    this.$nextTick(this.loadChart);
                },
            },
            fluidChartHeight: {
                handler: function (value) {
                    if (value < 750) {
                        this.fluidChartHeight = 750;
                    }
                },
            },
            _activePage: {
                handler: function () {
                    this.$nextTick(this.onResize);
                },
            },
        },
        mounted: function () {
            this.load();
        },
        methods: {
            onResize: function () {
                if (!this._chartZoom || this.reportData.length < 50) {
                    this.chartMode = 'pie';
                    this.$refs.chart.resize({ height: this.chartHeight });
                } else {
                    this.chartMode = 'bar';
                    this.$refs.barChart.resize({ height: this.fluidChartHeight });
                }
            },

            load: async function () {
                this.$nextTick(this.onResize);

                if (this.dashdata.filters.length === 0) {
                    return;
                }
                this.$util.loadCommandFilter(this.getAssetMaintenanceMonitorData, this.dashdata.filters);
                this.$refs.filter.refresh(true);
            },

            updateDashData: function (items) {
                this.emptyData = !items.length;
                this.dashdata.filters = this.$util.getFiltersByCommand(this.getAssetMaintenanceMonitorData);
                this.loadData(items);
            },

            loadData: async function (items) {
                this.reportData = this.formatToChart(items);
                this.loadChart();
            },

            formatToChart: function (data) {
                Object.keys(data).forEach((i) => {
                    data[i].name = data[i].DESC_EQUIPE;
                    data[i].value = data[i].TOTAL_EQUIPE;
                });
                this.fluidChartHeight = data.length * 11 + 180;
                this.onResize();
                const formattedData = data;
                return formattedData;
            },

            loadChart: function () {
                if (this.chartMode === 'pie') {
                    this.$refs.chart.reset();
                    this.$refs.chart.addSerie({
                        id: 1,
                        type: 'pie',
                        data: this.reportData,
                        animation: true,
                        left: 'center',
                        top: 'middle',
                        radius: consts.DEFAULT_RADIUS,
                        width: '100%',
                        height: '100%',
                        label: {
                            formatter: consts.DEFAULT_LABEL,
                        },
                    });
                } else {
                    this.$refs.barChart.reset();
                    this.$refs.barChart.addSerie({
                        id: 2,
                        type: 'bar',
                        data: this.reportData,

                        animation: true,
                        left: 'right',
                        top: 'middle',
                        barWidth: '100%',
                        barHeight: '100%',
                        itemStyle: {
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                            shadowBlur: 10,
                        },

                        label: {
                            show: true,
                            position: 'left',
                            formatter: '{b}',
                            color: '#000000',
                        },
                    });
                }
            },
        },
    };
</script>

<style lang='scss' scoped>
    .fullscreen {
        height: calc(100vh - 10vh) !important;
    }
</style>
