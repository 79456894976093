export const GroupingType = [
    {
        KEY: 'ISSUE_ASSET',
        VALUE: 'OCORRENCIA_PONTO_SERVICO',
    },
    {
        KEY: 'TICKET_ASSET_ALT',
        VALUE: 'SOLICITACAO_PONTO_SERVICO',
    },
    {
        KEY: 'MAINTENANCE_UNGROUPED_BY_TICKET',
        VALUE: 'ATENDIMENTO_PONTO_SERVICO',
    },
    {
        KEY: 'MAINTENANCE_UNGROUPED_BY_ISSUE',
        VALUE: 'ATENDIMENTO_PONTO_SERVICO_OCORRENCIA',
    },
    {
        KEY: 'TICKET_ENTRY_DONE_PENDING',
        VALUE: 'SOLICITACAO_PENDENTE_REALIZADA',
    },
];

export const IssueTicketStatus = [
    {
        KEY: 'ALL',
        VALUE: 'TODOS',
    },
    {
        KEY: 'PENDING',
        VALUE: 'PENDENTES',
    },
    {
        KEY: 'FULFILLED',
        VALUE: 'REALIZADOS',
    },
];

export const MaintenanceOrigin = [
    {
        KEY: 'ALL',
        VALUE: 'TODOS',
    },
    {
        KEY: 'ISSUE',
        VALUE: 'OCORRENCIA',
    },
    {
        KEY: 'PROJECT',
        VALUE: 'OBRA',
    },
];

export const TicketType = [
    {
        KEY: 'ALL',
        VALUE: 'TODOS',
    },
    {
        KEY: 'PREVENTIVE',
        VALUE: 'PREVENTIVA',
    },
    {
        KEY: 'CORRECTIVE',
        VALUE: 'CORRETIVA',
    },
];

export const RemainingTimeType = [
    {
        KEY: 'DAYS',
        VALUE: 'DIAS',
        INT_VALUE: 1,
    },
    {
        KEY: 'HOURS',
        VALUE: 'HORAS',
        INT_VALUE: 2,
    },
];
