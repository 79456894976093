const paths = [
    {
        path: '/project-measurement-list',
        name: 'ProjectMeasurementList',
        title: 'PROJECT_MEASUREMENT_TYPES',
        icon: 'widgets',
        menu: 'construction',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "projectMeasurement" */ /* webpackMode: "lazy" */ '@/pages/project/projectMeasurement/ProjectMeasurementList'),
    },
    {
        path: '/project-measurement-general',
        name: 'ProjectMeasurementGeneral',
        title: 'PROJECT_MEASUREMENT_GENERAL',
        favCategory: 'construction',
        component: () => import(/* webpackChunkName: "projectMeasurement" */ /* webpackMode: "lazy" */ '@/pages/project/projectMeasurement/ProjectMeasurementGeneral'),
    },
];

export default paths;
