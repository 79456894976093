<template lang='pug'>
    a-text-field(ref='field' v-bind='attrs' v-on='$listeners' v-model='valueLazy')
</template>
<script>
    import { getProps } from '../../util/helpers';

    export default {
        name: 'ATextFieldFloat',
        components: {
            ATextFieldFloat: {
                inheritAttrs: false,
            },
        },
        model: { prop: 'value' },
        props: {
            value: {
                type: String,
                default: '',
            },
        },
        data: function () {
            return {};
        },
        computed: {
            attrs: function () {
                return getProps(this.$attrs, [['v-model', 'value', 'type']], false);
            },
            valueLazy: {
                set: function (val) {
                    this.$emit('input', this.$util.toNumber(val));
                },
                get: function () {
                    return this.$util.numberView(this.value);
                },
            },
        },
        watch: {
            value: function (val) {
                this.$emit('input', this.$util.toNumber(val))
            }
        },
        methods: {

        },
    };
</script>
