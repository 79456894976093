const paths = [
  {
      path: '/chatbot-deployments',
      name: 'ChatbotDeployments',
      title: 'CHATBOT_DEPLOYMENTS',
      icon: 'mdi-file-document',
      menu: 'asset',
    component: () => import(/* webpackChunkName: "chatbotDeployments" */ /* webpackMode: "lazy" */ '@/pages/chatbotDeployments/ChatbotDeployments.vue'),
  },
];

export default paths;
