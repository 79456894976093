const paths = [
    {
        path: '/password-programming',
        name: 'PasswordProgramming',
        title: 'PASSWORD_PROGRAMMING',
        menu: 'registration',
        icon: 'mdi-lock',
        component: () => import(/* webpackChunkName: "passwordProgramming" */ /* webpackMode: "lazy" */ '@/pages/passwordProgramming/PasswordProgramming.vue'),
    },
    {
        path: '/password-programming-details',
        name: 'PasswordProgrammingDetails',
        component: () => import(/* webpackChunkName: "passwordProgramming" */ /* webpackMode: "lazy" */ '@/pages/passwordProgramming/PasswordProgrammingDetails.vue'),
    },
];

export default paths;
