export default [
    {
        path: '/registered-asset',
        title: 'REGISTERED_ASSETS',
        name: 'RegisteredAsset',
        icon: 'fas fa-chart-pie',
        menu: 'asset',
        categories: [
          'reports',
        ],
        component: () => import(/* webpackChunkName: "registeredAsset" */ /* webpackMode: "lazy" */ '@/pages/registeredAsset/RegisteredAsset.vue'),
    },
];
