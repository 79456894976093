const loading = {
    state: {
        isLoading: false,
    },
    getters: {
        isLoading: state => state.isLoading,
    },
    mutations: {
        SET_LOADING: function (state, value) {
            state.isLoading = value;
        },
    },
    actions: {
        SHOW_LOADING: function ({ commit, dispatch }) {
            commit('SET_LOADING', true);
            setTimeout(() => {
                dispatch('HIDE_LOADING');
            }, 5000);
        },
        HIDE_LOADING: function ({ commit }) {
            commit('SET_LOADING', false);
        },
    },
};

export default loading;
