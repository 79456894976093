export default async function detailedIssueRegion (provider) {
    provider.forEach(item => {
        item.description = '';
        item.description += item.TOTAL ? `${item.TOTAL} pto${parseInt(item.TOTAL, 10) > 1 ? 's' : ''} - ` : '';
        item.description += `${item.NOME_REGIAO}`;
        item.description += item.NOME_MUNICIPIO ? ` - ${item.NOME_MUNICIPIO}` : '';
    });

    return provider;
}
