import { getBooleanFromProp } from '../helpers';

export default {
    props: {
        /**
         * Selected items
         * @type {String}
         * @default {'breadcrumb'}
         */
        value: {
            type: null,
            default: function () {
                return undefined;
            },
        },
        /**
         * Enable select all item in menu
         * @type {String}
         * @default {'breadcrumb'}
         */
        _prependItemSelectAll: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            provider: this.$attrs.items || [],
            selected: this.value,
        };
    },
    computed: {
        valueComp: function () {
            return this.selected || [];
        },
        likesAll: function () {
            return this.valueComp.length === this.provider.length;
        },
        likesSome: function () {
            return this.valueComp.length > 0 && !this.likesAll;
        },
        iconAll: function () {
            if (this.likesAll) return '$checkboxOn';
            if (this.likesSome) return '$checkboxIndeterminate';
            return '$checkboxOff';
        },
        multipleComp: function () {
            return getBooleanFromProp(this.$attrs.multiple);
        },
    },
    watch: {
        value: {
            deep: true,
            handler: function (val, oldVal) {
                if (!this.$_aura.isEqual(val, oldVal)) {
                    this.selected = val;
                }
            },
        },
        selected: {
            deep: true,
            handler: function (val) {
                this.$emit('input', val);
            },
        },
        '$attrs.items': function (value) {
            this.provider = value || [];
        },
    },
    methods: {
        loaded: function (event) {
            this.provider = event || [];
            this.$emit('loaded', event);
        },
        toggleSelectAll: function () {
            const itemValue = this.$attrs['item-value'];
            this.$nextTick(() => {
                if (this.likesAll) {
                    this.selected = [];
                } else {
                    if (!!this.$attrs['return-object'] || this.$attrs['return-object'] === '') {
                        this.selected = this.provider;
                    } else {
                        this.selected = this.provider.map(obj => obj[itemValue]);
                    }
                }
            });
        },
    },
};
