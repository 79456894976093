import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import { showErrorsResponse, parseSuccessResponse } from '@/pages/remoteManagement/services/utils';
export class SaveMeasurementConfigItem extends CallCommand {
    constructor() {
        super({
            name: 'post_comp_measurement-config-item_0',
            type: 'SAVE',
            fields: {
                measurementConfigItem: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
                measurementConfigId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${value.toString()} }`,
                }),
                adminPath: new Field({
                    name: 'ADMIN_PATH',
                    value: true,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, debug, config) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class EditMeasurementConfigItem extends CallCommand {
    constructor() {
        super({
            name: 'put_comp_measurement-config-item_0_1',
            type: 'SAVE',
            fields: {
                measurementConfigItem: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
                measurementConfigId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${value.toString()} }`,
                }),
                measurementConfigItemId: new Field({
                    name: 'PATH_PARAMS',
                }),
                adminPath: new Field({
                    name: 'ADMIN_PATH',
                    value: true,
                }),
            },
            customRequestParser: function (params) {
                return {
                    PATH_PARAMS: `{ PARAM_0: ${params.measurementConfigId.toString()}, PARAM_1: ${params.measurementConfigItemId.toString()} }`,
                    BODY: JSON.stringify(params.measurementConfigItem),
                    ADMIN_PATH: true,
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, debug, config) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class DeleteMeasurementConfigItem extends CallCommand {
    constructor() {
        super({
            name: 'delete_comp_measurement-config-item_0_1',
            type: 'DELETE',
            fields: {
                measurementConfigId: new Field({
                    name: 'PATH_PARAMS',
                }),
                measurementConfigItemId: new Field({
                    name: 'PATH_PARAMS',
                }),
            },
            customRequestParser: function (params) {
                return {
                    PATH_PARAMS: `{ PARAM_0: ${params.measurementConfigId.toString()}, PARAM_1: ${params.measurementConfigItemId.toString()} }`,
                    ADMIN_PATH: true,
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, debug, config) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
