import i18n from '@/i18n';

export default function itemType (provider) {
    return new Promise((resolve) => {
        const resultProvider = [];
        resultProvider.push({ ID_TIPO_ITEM: -1, DESC_TIPO_ITEM: i18n.t('ALL') });

        provider.forEach((item) => {
            resultProvider.push({ ID_TIPO_ITEM: parseInt(item.ID_TIPO_ITEM, 10), DESC_TIPO_ITEM: item.DESC_TIPO_ITEM });
        });

        resolve(resultProvider);
    });
}
