const paths = [
    {
        path: '/photo-template-list',
        name: 'PhotoTemplateList',
        title: 'PHOTO_TEMPLATES',
        menu: 'maintenance',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "photoTemplate" */ /* webpackMode: "lazy" */ '@/pages/photoTemplate/PhotoTemplateList.vue'),
    },
    {
        path: '/photo-template-general',
        name: 'PhotoTemplateGeneral',
        title: 'PHOTO_TEMPLATE',
        favCategory: 'maintenance',
        component: () => import(/* webpackChunkName: "photoTemplate" */ /* webpackMode: "lazy" */ '@/pages/photoTemplate/PhotoTemplateGeneral.vue'),
    },
    {
        path: '/photo-template-photo-type',
        name: 'PhotoTemplatePhotoType',
        title: 'PHOTO_TYPE',
        component: () => import(/* webpackChunkName: "photoTemplate" */ /* webpackMode: "lazy" */ '@/pages/photoTemplate/PhotoTemplatePhotoType.vue'),
    },
    {
        path: '/photo-template-general-type-upload',
        name: 'PhotoTemplateGeneralTypeUpload',
        title: 'PHOTO_TYPE',
        component: () => import(/* webpackChunkName: "photoTemplate" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ '@/pages/photoTemplate/PhotoTemplateGeneralTypeUpload.vue'),
    },
];

export default paths;
