const path = [
    {
        path: '/passwords',
        name: 'Passwords',
        title: 'PASSWORDS',
        menu: 'registration',
        icon: 'mdi-lock',
        component: () => import(/* webpackChunkName: "passwords" */ /* webpackMode: "lazy" */ '@/pages/passwords/Passwords.vue'),

    },
    {
        path: '/password-list',
        name: 'PasswordList',
        title: 'PASSWORD_PATTERN',
        component: () => import(/* webpackChunkName: "passwords" */ /* webpackMode: "lazy" */ '@/pages/passwords/PasswordList.vue'),
    },
    {
        path: '/password-general',
        name: 'PasswordGeneral',
        component: () => import(/* webpackChunkName: "passwords" */ /* webpackMode: "lazy" */ '@/pages/passwords/PasswordGeneral.vue'),
    },
    {
        path: '/prohibit-password',
        name: 'ProhibitPassword',
        component: () => import(/* webpackChunkName: "passwords" */ /* webpackMode: "lazy" */ '@/pages/passwords/ProhibitPassword.vue'),
    },
    {
        path: '/prohibit-password-general',
        name: 'ProhibitPasswordGeneral',
        component: () => import(/* webpackChunkName: "passwords" */ /* webpackMode: "lazy" */ '@/pages/passwords/ProhibitPasswordGeneral.vue'),
    },
];
export default path;
