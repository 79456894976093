const paths = [
    {
        path: '/task-list-camunda',
        name: 'TaskListCamunda',
        title: 'TASK_LIST',
        menu: 'registration',
        icon: 'mdi-file-tree',
        component: () => import(/* webpackChunkName: "camunda" */ /* webpackMode: "lazy" */ '@/pages/camunda/taskList/TaskListCamunda.vue'),
    },
    {
        path: '/form-camunda',
        name: 'FormCamunda',
        component: () => import(/* webpackChunkName: "camunda" */ /* webpackMode: "lazy" */ '@/pages/camunda/taskList/FormCamunda.vue'),
    },
    {
        path: '/process-camunda-custom-details-header-default',
        name: 'ProcessCamundaCustomDetailsHeaderDefault',
        component: () => import(/* webpackChunkName: "camunda" */ /* webpackMode: "lazy" */ '@/pages/camunda/ProcessCamundaCustomDetailsHeaderDefault.vue'),
    },
    {
        path: '/process-camunda-custom-details-header-maintenance',
        name: 'ProcessCamundaCustomDetailsHeaderMaintenance',
        component: () => import(/* webpackChunkName: "camunda" */ /* webpackMode: "lazy" */ '@/pages/camunda/ProcessCamundaCustomDetailsHeaderMaintenance.vue'),
    },
    {
        path: '/process-camunda-custom-details-header-ticket',
        name: 'ProcessCamundaCustomDetailsHeaderTicket',
        component: () => import(/* webpackChunkName: "camunda" */ /* webpackMode: "lazy" */ '@/pages/camunda/ProcessCamundaCustomDetailsHeaderTicket.vue'),
    },
    {
        path: '/task-camunda-generic-view',
        name: 'TaskCamundaGenericView',
        component: () => import(/* webpackChunkName: "camunda" */ /* webpackMode: "lazy" */ '@/pages/camunda/taskList/TaskCamundaGenericView.vue'),
    },
];

export default paths;
