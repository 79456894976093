const paths = [
    {
        path: '/asset-change-report',
        name: 'AssetChangeReport',
        title: 'SERVICE_POINT_CHANGES_REPORT',
        icon: 'mdi-file-document',
        menu: 'asset',
        categories: [
          'reports',
        ],
        component: () => import(/* webpackChunkName: "reportMaintenance" */ /* webpackMode: "lazy" */ '@/pages/report/asset/AssetChangeReport.vue'),
    },
];

export default paths;
