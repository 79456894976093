const paths = [
    {
      path: '/reset-password',
      title: 'RESET_PASSWORD',
      name: 'ResetPassword',
      component: () => import(/* webpackChunkName: "password" */ /* webpackMode: "lazy" */ '@/components/core/password/ResetPassword.vue'),
    },
];

export default paths;
