const paths = [
    {
        path: '/project-export',
        name: 'ProjectExport',
        title: 'PROJECTS_EXPORTS',
        icon: 'fas fa-file-excel',
        menu: 'construction',
        component: () => import(/* webpackChunkName: "projectExport" */ /* webpackMode: "lazy" */ '@/pages/project/projectExport/ProjectExport.vue'),
    },
];
export default paths;
