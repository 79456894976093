import i18n from '@/i18n';

export default function areaTypes (provider) {
    return new Promise((resolve) => {
        const resultProvider = [];
        resultProvider.push({ ID_TIPO_AREA: -1, DESC_TIPO_AREA: i18n.t('ALL') });

        provider.forEach((item) => {
            resultProvider.push({ ID_TIPO_AREA: parseInt(item.ID_TIPO_AREA, 10), DESC_TIPO_AREA: item.DESC_TIPO_AREA });
        });

        resolve(resultProvider);
    });
}
