export default async function personBankAccount (provider) {
    provider.forEach(item => {
        if (item.DEPARTAMENTO) {
            item.description = `${item.NOME} - (${item.DEPARTAMENTO})`;
        } else {
            item.description = `${item.NOME}`;
        }
    });

    return provider;
}
