const paths = [
    {
        path: '/project-type-list',
        name: 'ProjectTypeList',
        title: 'PROJECT_TYPES',
        icon: 'widgets',
        menu: 'construction',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "projectType" */ /* webpackMode: "lazy" */ '@/pages/project/projectType/ProjectTypeList'),
    },
    {
        path: '/project-type',
        name: 'ProjectType',
        title: 'PROJECT_TYPE',
        favCategory: 'maintenance',
        component: () => import(/* webpackChunkName: "projectType" */ /* webpackMode: "lazy" */ '@/pages/project/projectType/ProjectType'),
    },
    {
        path: '/project-type-event-model',
        name: 'ProjectTypeEventModel',
        title: 'EVENT_MODEL_LIST',
        component: () => import(/* webpackChunkName: "projectType" */ /* webpackMode: "lazy" */ '@/pages/project/projectType/ProjectTypeEventModel'),
    },
];

export default paths;
