const paths = [
    {
        path: '/asset-mass-registration',
        name: 'AssetMassRegistration',
        title: 'ASSET_MASS_REGISTRATION',
        icon: 'mdi-map-marker-multiple',
        menu: 'maintenance',
        component: () => import(/* webpackChunkName: "assets" */ /* webpackMode: "lazy" */ '@/pages/asset/AssetMassRegistration.vue'),
    },
    {
        path: '/asset-list',
        name: 'AssetList',
        title: 'ASSETS',
        icon: 'mdi-map-marker-multiple',
        menu: 'maintenance',
        component: () => import(/* webpackChunkName: "assets" */ /* webpackMode: "lazy" */ '@/pages/asset/AssetList.vue'),
    },
    {
        path: '/asset',
        name: 'Asset',
        favCategory: 'registration',
        title: 'ASSET',
        component: () => import(/* webpackChunkName: "assets" */ /* webpackMode: "lazy" */ '@/pages/asset/Asset.vue'),
    },
    {
        path: '/asset-structure',
        name: 'AssetStructure',
        title: 'CHANGE_ASSET_STRUCTURE',
        component: () => import(/* webpackChunkName: "assets" */ /* webpackMode: "lazy" */ '@/pages/asset/AssetStructure.vue'),
    },
    {
        path: '/asset-data-attributes',
        name: 'AssetDataAttributes',
        title: 'ATTRIBUTES',
        component: () => import(/* webpackChunkName: "assets" */ /* webpackMode: "lazy" */ '@/pages/asset/AssetDataAttributes.vue'),
    },
    {
        path: '/asset-validate',
        name: 'AssetValidate',
        title: 'ASSETS_VALIDATE',
        component: () => import(/* webpackChunkName: "assets" */ /* webpackMode: "lazy" */ '@/pages/asset/AssetValidate.vue'),
    },
    {
        path: '/asset-invalidate',
        name: 'AssetInvalidate',
        title: 'ASSETS_INVALIDATE',
        component: () => import(/* webpackChunkName: "assets" */ /* webpackMode: "lazy" */ '@/pages/asset/AssetInvalidate.vue'),
    },
    {
        path: '/asset-geographic-position',
        name: 'AssetGeographicPosition',
        title: 'GEOGRAPHIC_POSITION',
        component: () => import(/* webpackChunkName: "assets" */ /* webpackMode: "lazy" */ '@/pages/asset/AssetGeographicPosition.vue'),
    },
    {
        path: '/asset-structure-new-pattern',
        name: 'AssetStructureNewPattern',
        title: 'ASSET_STRUCTURE_PATTERN',
        component: () => import(/* webpackChunkName: "assets" */ /* webpackMode: "lazy" */ '@/pages/asset/AssetStructureNewPattern.vue'),
    },
    {
        path: '/asset-item',
        name: 'AssetItem',
        title: 'ITEM_DATA',
        component: () => import(/* webpackChunkName: "assets" */ /* webpackMode: "lazy" */ '@/pages/asset/AssetItem.vue'),
    },
    {
        path: '/asset-modernized',
        name: 'AssetModernized',
        title: 'REPORT_ASSETS_MODERNIZED',
        icon: 'mdi-file-document',
        menu: 'asset',
        categories: [
          'reports',
        ],
      component: () => import(/* webpackChunkName: "assets" */ /* webpackMode: "lazy" */ '@/pages/asset/AssetModernized.vue'),
    },
    {
        path: '/asset-modernized-complete',
        name: 'AssetModernizedComplete',
        title: 'REPORT_ASSETS_MODERNIZED_COMPLETE',
        icon: 'mdi-file-document',
        menu: 'asset',
        categories: [
          'reports',
        ],
      component: () => import(/* webpackChunkName: "assets" */ /* webpackMode: "lazy" */ '@/pages/asset/AssetModernizedComplete.vue'),
    },
    {
        path: '/asset-modernized-excel-report-wizard',
        name: 'AssetModernizedExcelReportWizard',
        title: 'GENERATE_EXCEL_REPORT',
        component: () => import(/* webpackChunkName: "assets" */ /* webpackMode: "lazy" */ '@/pages/asset/AssetModernizedExcelReportWizard.vue'),
    },
    {
        path: '/asset-modernized-dinamic-report',
        name: 'AssetModernizedDinamicReport',
        title: 'GENERATE_DETAILED_EXCEL_REPORT',
        component: () => import(/* webpackChunkName: "assets" */ /* webpackMode: "lazy" */ '@/pages/asset/AssetModernizedDinamicReport.vue'),
    },
    {
        path: '/unregistered-tags-report',
        name: 'UnregisteredTagsReport',
        title: 'UNREGISTERED_TAGS_REPORT',
        icon: 'mdi-file-document',
        menu: 'asset',
        categories: [
          'reports',
        ],
      component: () => import(/* webpackChunkName: "assets" */ /* webpackMode: "lazy" */ '@/pages/asset/UnregisteredTagsReport.vue'),
    },
    {
        path: '/asset-import',
        name: 'AssetImport',
        title: 'ASSET_IMPORT',
        icon: 'mdi-file-document',
        menu: 'maintenance',
        component: () => import(/* webpackChunkName: "assets" */ /* webpackMode: "lazy" */ '@/pages/asset/AssetImport.vue'),
    },
    {
        path: '/asset-import-history',
        name: 'AssetImportHistory',
        title: 'HISTORY',
        component: () => import(/* webpackChunkName: "assets" */ /* webpackMode: "lazy" */ '@/pages/asset/AssetImportHistory.vue'),
    },
    {
        path: '/asset-create',
        name: 'AssetCreate',
        title: 'NEW_ASSET',
        component: () => import(/* webpackChunkName: "assets" */ /* webpackMode: "lazy" */ '@/pages/asset/AssetCreate.vue'),
    },
    {
        path: '/change-status-asset',
        name: 'ChangeStatusAsset',
        title: 'CHANGE_STATUS',
        component: () => import(/* webpackChunkName: "assets" */ /* webpackMode: "lazy" */ '@/pages/asset/ChangeStatusAsset.vue'),
    },
    {
        path: '/asset-data-change-history',
        name: 'AssetChangeHistory',
        title: 'CHANGE_HISTORY',
        component: () => import(/* webpackChunkName: "assets" */ /* webpackMode: "lazy" */ '@/pages/asset/AssetChangeHistory.vue'),
    },
    {
        path: '/asset-data-attribute-change-type',
        name: 'AssetDataAttributesChangeType',
        title: 'CHANGE_TYPE',
        component: () => import(/* webpackChunkName: "assets" */ /* webpackMode: "lazy" */ '@/pages/asset/AssetDataAttributesChangeType.vue'),
    },
    {
        path: '/asset-data-asset-grouping',
        name: 'AssetDataAssetGrouping',
        title: 'GROUPING',
        component: () => import(/* webpackChunkName: "assets" */ /* webpackMode: "lazy" */ '@/pages/asset/AssetDataAssetGrouping.vue'),
    },
];

export default paths;
