import { SaveUserPreferencesValue } from '@/components/core/services/request.ts';
import lodash from 'lodash';

export default {
    state: {
        user: null,
        preferences: [],
    },
    mutations: {
        SET_USER: function (state, user) {
            const { preferences, ...userData } = user;
            state.user = userData;

            if (preferences && typeof preferences === 'string') {
                state.preferences = JSON.parse(preferences);
            }
        },
        SET_USER_PREFERENCES: function (state, preferences) {
            state.preferences = lodash.uniqBy(preferences, 'key');
        },
    },
    actions: {
        setUserPreference: async function (store, { key, value }) {
            const preferences = store.state.preferences.slice();

            const oldPreferenceIndex = preferences.findIndex(i => i.key === key);

            if (oldPreferenceIndex !== -1) {
                preferences.splice(oldPreferenceIndex, 1);
            }

            preferences.push({ key: key, value: value });

            const command = new SaveUserPreferencesValue();

            await command.callCommand({
                userId: store.state.user.userId,
                userPreferencesValue: JSON.stringify(preferences),
            });

            store.commit('SET_USER_PREFERENCES', preferences);
        },
        setMultipleUserPreference: async function (store, preferences) {
            const oldPreferences = store.state.preferences.slice();

            const otherPreferences = oldPreferences.filter(
                op => !preferences.some(p => p.key === op.key),
            );

            const newPreferences = otherPreferences.concat(preferences);

            const command = new SaveUserPreferencesValue();

            await command.callCommand({
                userId: store.state.user.userId,
                userPreferencesValue: JSON.stringify(newPreferences),
            });

            store.commit('SET_USER_PREFERENCES', newPreferences);
        },
        deleteUserPreference: async function (store, key) {
            const oldPreferences = store.state.preferences.slice();

            const newPreferences = oldPreferences.filter(p => p.key !== key);

            const command = new SaveUserPreferencesValue();

            await command.callCommand({
                userId: store.state.user.userId,
                userPreferencesValue: JSON.stringify(newPreferences),
            });

            store.commit('SET_USER_PREFERENCES', newPreferences);
        },
    },
    getters: {
        getUser: state => state.user,
        getUserPreferenceByKey: state => key => {
            const preference = state.preferences.find(p => p.key === key);

            return preference ? preference.value : null;
        },
    },
};
