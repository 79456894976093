const paths = [
    {
        path: '/project-import',
        name: 'ProjectImport',
        title: 'PROJECT_IMPORT',
        icon: 'mdi-file-import',
        menu: 'construction',
        component: () => import(/* webpackChunkName: "projectImport" */ /* webpackMode: "lazy" */ '@/pages/project/projectImport/ProjectImport.vue'),
    },
];

export default paths;
