<template>
    <a-row v-if='actions.length <= _minActionsMenu' no-gutters justify='center' align='center'>
        <template v-for='(action, idx) in actions'>
            <a-hover v-slot:default='{ hover }'>
                <component :is='action.element' :key='idx' v-bind='action.props' v-on='action.events' :disabled='(_colorOnHover && !hover && !action.showColor) || action.props.disabled' />
            </a-hover>
        </template>
    </a-row>
    <a-row v-else no-gutters justify='center' align='center'>
        <a-hover v-slot:default='{ hover }'>
            <component
                :is='actions[0].element' 
                v-bind='actions[0].props' 
                v-on='actions[0].events' 
                :disabled='(_colorOnHover && !hover && !actions[0].showColor) || actions[0].props.disabled' 
            />
        </a-hover>
        <a-menu offset-y :close-on-content-click='_closeOnClick' absolute>
            <template v-slot:activator='{ on }'>
                <a-hover v-slot:default='{ hover }'>
                    <component
                            :is='_menuIcon ? "AIcon" : "ABtn"'
                            :small='!_xSmall && !_large && !_xLarge'
                            :x-small='_xSmall'
                            :large='_large'
                            :x-large='_xLarge'
                            color='undefined'
                            color-icon='undefined'
                            :tile='false'
                            ellipsish
                            :_tooltip-text='$t("MENU")'
                            :class='`${_menuIcon ? "mx-1" : ""}`'
                            :disabled='(_colorOnHover && !hover)'
                            v-on='on' />
                </a-hover>
            </template>
            <a-list dense>
                <template v-for='(action, idx) in actions.slice(1)'>
                    <a-hover v-slot:default='{ hover }'>
                        <a-list-item v-if='action.element === "AIcon" || action.element === "ABtn"' :key='idx' v-on='action.events'>
                            <component 
                                :is='"AIcon"'
                                v-bind='action.props'
                                :_tooltip='false'
                                :disabled='(_colorOnHover && !hover && !action.showColor) || action.props.disabled'
                            />
                            <span v-if='action.props && action.props.label' :disabled='(_colorOnHover && !hover && !action.showColor)' class='ml-2 text-body-2'>&nbsp;{{action.props.label}}</span>
                        </a-list-item>
                        <a-list-item v-else :key='idx' v-on='action.events'>
                            <component 
                                :is='action.element'
                                v-bind='action.props'
                                :_tooltip='false'
                                :disabled='(_colorOnHover && !hover && !action.showColor) || action.props.disabled'
                            />
                            <span v-if='action.props && action.props.label' :disabled='(_colorOnHover && !hover && !action.showColor)' class='ml-2 text-body-2'>&nbsp;{{action.props.label}}</span>
                        </a-list-item>
                    </a-hover>
                </template>
            </a-list>
        </a-menu>
    </a-row>
</template>

<script>
    export default {
        name: 'ADataTableActions',
        components: {
        },
        props: {
            _xSmall: {
                type: Boolean,
                default: false,
            },
            _large: {
                type: Boolean,
                default: false,
            },
            _xLarge: {
                type: Boolean,
                default: false,
            },
            _customActions: {
                type: Array,
                default: () => {
                    return [];
                },
            },
            _iconActions: {
                type: Array,
                default: () => {
                    return [];
                },
            },
            _menuIcon: {
                type: Boolean,
                default: false,
            },
            _colorOnHover: {
                type: Boolean,
                default: true,
            },
            _closeOnClick: {
                type: Boolean,
                required: false,
                default: false
            },
            _minActionsMenu: {
                type: Number,
                default: 2,
            },
        },
        watch: {
            _iconActions: {
                deep: true,
                handler: function () {
                    this.load();
                },
            },
            _customActions: {
                deep: true,
                handler: function () {
                    this.load();
                },
            }
        },
        data: function () {
            return {
                actions: [],
            };
        },
        mounted: function () {
            this.load();
        },
        methods: {
            load: function () {
                let iconActions = [];
                for(let i = 0; i < this._iconActions.length; i++) {
                    const action = this._iconActions[i];

                    const props = {
                            class: 'mx-1',
                            label: this.$t(action.toUpperCase()),
                    };

                    props[action] = true;

                    const icon = {
                        element: 'AIcon',
                        props: props,
                        events: {
                            click: () => this.$emit(action),
                        },
                    }

                    iconActions.push(icon);
                }
                const customActions = this._customActions.map(a => ({
                    ...a,
                    props: {
                        tile: false,
                        ...a.props,
                    }
                }))
                this.actions = iconActions.concat(customActions);
            },
        },
    };
</script>

<style lang='scss' scoped></style>
