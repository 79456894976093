const backgroundReport = {
    state: {
        isAddedReport: false,
        menuVisible: false,
    },
    getters: {
        isAddedReport: state => state.isAddedReport,
        menuVisible: state => state.menuVisible,
    },
    mutations: {
        SET_ADDED_REPORT: function (state, value) {
            state.isAddedReport = value;
        },
        SET_BACKGROUND_REPORT_VISIBLE: function (state, value) {
            state.menuVisible = value;
        },
    },
    actions: {
        ADDED_REPORT: function ({ commit }) {
            commit('SET_ADDED_REPORT', true);
        },
        REPORT_GENERATED: function ({ commit }) {
            commit('SET_ADDED_REPORT', false);
        },
        SET_BACKGROUND_REPORT_MENU: function ({ commit }, value) {
            commit('SET_BACKGROUND_REPORT_VISIBLE', value);
        },
    },
};

export default backgroundReport;
