const paths = [
    {
        path: '/translation',
        name: 'TranslationDetails',
        title: 'TRANSLATIONS',
        menu: 'customization',
        icon: 'mdi-translate',
        component: () => import(/* webpackChunkName: "pageConstructor" */ /* webpackMode: "lazy" */ '@/pages/translation/TranslationDetails.vue'),
    },
];

export default paths;
