<template>
    <a-container
        ref='container'
        v-resize='onResize'
        style='width: 100%; height: 100%;'
    >
        <a-dynamic-command-filter
            ref='filter'
            :key='getLightSpotFilters.name'
            filter-group='all'
            exhibition-mode='sidebar'
            class='pivot-filter'
            :_fab='false'
            :_sidebar-props='{ _hideActions: true }'
            :_loading.sync='loading'
            :_command='getLightSpotFilters'
            @updateValues='updateDashData'
        />
        <a-row no-gutters>
            <a-col cols='12'>
                <span class='title font-weight-light'>{{_title}}</span>
            </a-col>
        </a-row>
        <a-row no-gutters>
            <a-col v-if='total > 0' cols='12'>
                <span class='font-weight-light'>{{`${$t("TOTAL")}: ${total}`}}</span>
            </a-col>
        </a-row>
        <a-row style='height: 80%;'>
            <a-col cols='12'>
                <chart
                    v-show='!loading && !emptyData'
                    ref='chart'
                    :_options='chartOptions'
                    style='height: 100%;'
                />
            </a-col>
        </a-row>
    </a-container>
</template>

<script>
    // import { GetIssueMonitorData } from './services/request';
    import { GetLightSpots } from '@/pages/lightSpot/services/request.ts';

    export default {
        name: 'LightSpotReportChart',
        props: {
            /**
             * Prop to show if page is currently active
             * @type {Boolean}
             * @default {false}
            */
            _activePage: {
                type: Boolean,
                default: false,
            },
            /**
             * Command filter values sent from the Dashboard
             * @type {Object}
             * @default {}
             */
            _dashdata: {
                type: Object,
                default: () => ({
                    filters: [],
                }),
            },
            /**
             * Parameter to determine the chart's height
             * @type {[String, Number]}
             * @default {undefined}
             */
            _chartHeight: {
                type: [String, Number],
                default: undefined,
            },
            /**
             * Parameter to determine the chart's title
             * @type {[String, Number]}
             * @default {undefined}
             */
            _title: {
                type: String,
                default: undefined,
            },
            /**
             * sync with parent loading
             * @type {Boolean}
             * @default {false}
             */
            _loading: {
                type: Boolean,
                default: false,
            },
            /**
             * sync with parent empty-data
             * @type {Boolean}
             * @default {false}
             */
            _emptyData: {
                type: Boolean,
                default: false,
            },
        },
        data: function () {
            return {
                dashdata: this._dashdata,
                reportData: {},
                total: 0,

                chartOptions: {
                    maintainAspectRatio: false,
                    tooltip: {
                        trigger: 'item',
                        formatter: '<b>{b}: {d}%</b><br />({c})',
                        confine: true,
                    },
                },

                chartHeight: this._chartHeight || 'auto',
            };
        },
        computed: {
            getLightSpotFilters: function () {
                const command = new GetLightSpots();
                command.node = 'PONTOS_LUMINOSOS.PONTO_LUMINOSO';
                command.initializeLoading = false;
                return command;
            },
            loading: {
                get: function () {
                    return this._loading;
                },
                set: function (value) {
                    this.$emit('update:_loading', value);
                },
            },
            emptyData: {
                get: function () {
                    return this._emptyData;
                },
                set: function (value) {
                    this.$emit('update:_emptyData', value);
                },
            },
        },
        watch: {
            dashdata: {
                deep: true,
                handler: function (value) {
                    this.$emit('update-dashdata', value);
                },
            },
            _chartHeight: {
                handler: function (value) {
                    this.chartHeight = value;
                    this.$nextTick(this.onResize);
                },
            },
            _activePage: {
                handler: function () {
                    this.$nextTick(this.onResize);
                },
            },
        },
        mounted: function () {
            this.load();
        },
        methods: {
            onResize: function () {
                this.$refs.chart.resize({ height: this.chartHeight });
            },

            load: async function () {
                this.$nextTick(this.onResize);

                if (this.dashdata.filters.length === 0) {
                    return;
                }

                this.$util.loadCommandFilter(this.getLightSpotFilters, this.dashdata.filters);

                this.$refs.filter.refresh(true);
            },

            updateDashData: function (items) {
                this.emptyData = !items.length;
                this.dashdata.filters = this.$util.getFiltersByCommand(this.getLightSpotFilters);
                this.loadData(items);
            },

            loadData: function (items) {
                this.processTotal(items);
                this.reportData = this.formatToChart(items);
                this.loadChart();
            },

            formatToChart: function (data) {
                Object.keys(data).forEach((i) => {
                    data[i].name = data[i].DESCRICAO;
                    data[i].value = data[i].NUM_PONTOS_LUMINOSOS;
                });
                const formattedData = data;
                return formattedData;
            },

            attendanceLabelFormatter: function (params) {
                const { data } = params;
                const description = data.DESCRICAO;
                const total = data.NUM_PONTOS_LUMINOSOS;
                const percentage = (Number(data.NUM_PONTOS_LUMINOSOS) / this.total) * 100;
                return `${description}\n${total} (${this.$util.numberFormat(percentage)} %)`;
            },

            loadChart: function () {
                this.$refs.chart.reset();
                this.$refs.chart.addSerie({
                    id: 1,
                    type: 'pie',
                    data: this.reportData,
                    animation: true,
                    left: 'center',
                    top: 'middle',
                    radius: '50%',
                    width: '100%',
                    height: '100%',
                    label: {
                        show: true,
                        formatter: this.attendanceLabelFormatter,
                        color: '#000000',
                    },
                });
            },

            processTotal: function (lightSpots) {
                this.total = 0;
                lightSpots.forEach(elem => {
                    this.total += Number(elem.NUM_PONTOS_LUMINOSOS);
                });
            },
        },
    };
</script>
