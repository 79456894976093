const paths = [
    {
        path: '/maintenance-reason-list',
        name: 'MaintenanceReasonList',
        title: 'MAINTENANCE_REASON',
        menu: 'maintenance',
        categories: [
          'configurations',
        ],
        icon: 'fa fa-briefcase',
        component: () => import(/* webpackChunkName: "maintenanceReason" */ /* webpackMode: "lazy" */ '@/pages/maintenanceReason/MaintenanceReasonList.vue'),
    },
    {
        path: '/maintenance-reason-general',
        name: 'MaintenanceReasonGeneral',
        title: 'MAINTENANCE_REASON',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "maintenanceReason" */ /* webpackMode: "lazy" */ '@/pages/maintenanceReason/MaintenanceReasonGeneral.vue'),
    },
];
export default paths;
