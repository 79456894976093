import Vue from 'vue';
import SweetAlert2 from 'vue-sweetalert2';
import i18n from '@/i18n';
import Alert from './Alert.vue';

Vue.use(SweetAlert2);

const alert = {
    options: {},
    vue: null,
    initializeOptions: function (options, vue) {
        const aux = {
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: i18n.t('CONFIRM'),
            confirmButtonColor: '#242358',
            cancelButtonText: i18n.t('CANCEL'),
            cancelButtonColor: '#f03049',
            reverseButtons: true,
        };
        this.options = Object.assign({}, aux, options);
        SweetAlert2.options = this.options;
        this.vue = vue;
    },
    alert: function (options) {
        return Vue.swal(options).then(
            (resolve) => resolve,
            (reject) => reject,
        );
    },
    showAlert: function (title, text, type) {
        this.options.title = title;
        this.options.text = text;
        this.options.type = type || this.options.type;

        return this.alert(this.options);
    },
    showAlertWithOptions: function (options, mergeDefaultOptions) {
        const incomingOptions = options;
        if (!(mergeDefaultOptions === false)) {
            options = Vue.prototype.$lodash.merge({ ...this.options }, options);
            if(!incomingOptions.text) {
                options.text = '';
            }
        }
        return this.alert(options);
    },
    showCustomAlert: function (props) {
        // const baseMixins = mixins(
        //     // Overlayable, // TODO: Fix Sass Loader - webpack
        //     Themeable,
        // );

        const node = document.createElement('div');
        document.querySelector('.v-application--wrap').appendChild(node);
        const ComponentClass = Vue.extend(Alert);
        const instance = new ComponentClass();
        instance.$props.title = props.title;
        instance.$mount(node);
        return instance.open();
    },
};

export default alert;
