<template>
    <v-btn
        v-bind="$attrs"
        v-on="$listeners"
        :loading='loading || $attrs.loading'
        :disabled='disabled || $attrs.disabled'
        @[clickEvent]='checkForRequest'
    >
        <slot />
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
            <slot :name="slot" v-bind="scope" />
        </template>
    </v-btn>
</template>
<script>
export default {
    name: 'ABtnRaw',
    data: function (){
        return {
            loading: false,
            disabled: false,
            timeoutId: null,
            clickEvent: this.$attrs.type === 'submit' ? 'dummy' : 'click'
        }
    },
    methods: {
        checkForRequest: function () {
            this.disabled = true;
            this.$_aura.events.global.$on('updateRequests', this.updateRequests);

            this.timeoutId = setTimeout(() => {
                this.updateRequests(0);
            }, 500);
        },
        updateRequests: function (runningRequests) {
            clearTimeout(this.timeoutId);
            if(runningRequests > 0) {
                this.loading = true;
            } else if(runningRequests === 0) {
                this.loading = false;
                this.disabled = false;
                this.$_aura.events.global.$off('updateRequests', this.updateRequests)
            }

        }
    }
};
</script>
