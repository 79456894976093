const paths = [
    {
        path: '/dynamic-opening-report',
        name: 'DynamicOpeningReport',
        title: 'DYNAMIC_REOPENING_REPORT',
        menu: 'maintenance',
        categories: [
            'reports',
        ],
        component: () => import(/* webpackChunkName: "dynamicOpeningReport" */ /* webpackMode: "lazy" */ '@/pages/report/dynamicOpeningReport/DynamicOpeningReport.vue'),
    },
];

export default paths;
