const paths = [
    {
        path: '/script-Programming',
        name: 'ScriptProgramming',
        title: 'SCRIPT_PROGRAMMING',
        menu: 'registration',
        icon: 'mdi-language-javascript',
        component: () => import(/* webpackChunkName: "scriptProgramming" */ /* webpackMode: "lazy" */ '@/pages/scriptProgramming/ScriptProgramming.vue'),
    },
    {
        path: '/script-general',
        name: 'ScriptGeneral',
        component: () => import(/* webpackChunkName: "scriptProgramming" */ /* webpackMode: "lazy" */ '@/pages/scriptProgramming/ScriptGeneral.vue'),
    },
];

export default paths;
