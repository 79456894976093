const paths = [
    {
        path: '/financial-schedule-duplicate-alert-list',
        name: 'FinancialScheduleDuplicateAlertList',
        title: 'FINANCIAL_SCHEDULE_DUPLICATE_ALERTS',
        icon: 'widgets',
        menu: 'financial',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "financialScheduleDuplicateAlert" */ /* webpackMode: "lazy" */ '@/pages/financialScheduleDuplicateAlert/FinancialScheduleDuplicateAlertList.vue'),
    },
    {
        path: '/financial-schedule-duplicate-alert',
        name: 'FinancialScheduleDuplicateAlert',
        title: 'FINANCIAL_SCHEDULE_DUPLICATE_ALERT',
        favCategory: 'financial',
        component: () => import(/* webpackChunkName: "financialScheduleDuplicateAlert" */ /* webpackMode: "lazy" */ '@/pages/financialScheduleDuplicateAlert/FinancialScheduleDuplicateAlert.vue'),
    },
    {
        path: '/financial-schedule-duplicate-alert-general',
        name: 'FinancialScheduleDuplicateAlertGeneral',
        title: 'FINANCIAL_SCHEDULE_DUPLICATE_ALERT',
        component: () => import(/* webpackChunkName: "financialScheduleDuplicateAlert" */ /* webpackMode: "lazy" */ '@/pages/financialScheduleDuplicateAlert/FinancialScheduleDuplicateAlertGeneral.vue'),
    },
    {
        path: '/financial-schedule-duplicate-alert-email-list',
        name: 'FinancialScheduleDuplicateAlertEmailList',
        title: 'EMAIL_LIST',
        component: () => import(/* webpackChunkName: "financialScheduleDuplicateAlert" */ /* webpackMode: "lazy" */ '@/pages/financialScheduleDuplicateAlert/FinancialScheduleDuplicateAlertEmailList.vue'),
    },
    {
        path: '/financial-schedule-duplicate-alert-email',
        name: 'FinancialScheduleDuplicateAlertEmail',
        title: 'EDIT',
        component: () => import(/* webpackChunkName: "financialScheduleDuplicateAlert" */ /* webpackMode: "lazy" */ '@/pages/financialScheduleDuplicateAlert/FinancialScheduleDuplicateAlertEmail.vue'),
    },
];

export default paths;
