export default {
    light: {
        bar: '#ffffff',
        primary: '#1978FF',
        secondary: '#211e54',
        tertiary: '#F2F2F2',
        inactive: '#ababab',
        // active: '#4497d8',
        success: '#4eb8b0',
        warning: '#f3bc47',
        danger: '#EF5549',
        cancel: '#000000',
        dark: '#1A1A1A',

        // buttons
        save: '#1978FF',
        delete: '#EF5549',

        yellow: '#F9A726',
        red: '#EF5549',
        green: '#52BE7F',
        orange: '#FF8000',
        pink: '#FF00FF',
        cian: '#00BBCC',
        'dark-blue': '#000090',
        'dark-green': '#009000',
        purple: '#6600CC',

        mono: '#000',
        'mono-grey-95': '#0D0D0D',
        'mono-grey-90': '#1A1A1A',
        'mono-grey-85': '#262626',
        'mono-grey-80': '#333333',
        'mono-grey-75': '#404040',
        'mono-grey-70': '#4D4D4D',
        'mono-grey-60': '#666666',
        'mono-grey-50': '#808080',
        'mono-grey-40': '#999999',
        'mono-grey-15': '#D9D9D9',

        // new
        'mono-grey-2': '#FAFAFA',
        'mono-grey-5': '#F2F2F2',
        'mono-grey-10': '#E6E6E6',
        'mono-grey-20': '#CCCCCC',
        'mono-grey-30': '#B3B3B3',
        'mono-grey-45': '#8C8C8C',

        'exati-blue-dark': '#004AA3',
        'exati-blue-main': '#0A64FF',
        'exati-blue-medium': '#8CBCFF',
        'exati-blue-light': '#E1EFFF',
    },
    dark: {
        bar: '#1A1A1A',
        primary: '#10ccb0',
        secondary: '#878585',
        tertiary: '#1A1A1A',
        black: '#1A1A1A',
        inactive: '#ababab',
        success: '#4eb8b0',
        warning: '#f3bc47',
        danger: '#eb524c',
        cancel: '#000000',
        dark: '#ffffff',
        green: '#52BE7F',
        orange: '#FF8000',
        pink: '#FF00FF',
        cian: '#00BBCC',
        'dark-blue': '#000090',
        'dark-green': '#009000',
        purple: '#6600CC',

        // mono
        mono: '#1A1A1A',
        'mono-grey-2': '#1e1e1e',
        'mono-grey-5': '#262626',
        'mono-grey-10': '#303030',
        'mono-grey-20': '#484848',
        'mono-grey-30': '#5e5e5e',
        'mono-grey-45': '#818181',
        'mono-grey-50': '#808080',

        'exati-blue-main': '#10ccb0',

        'exati-blue-dark': '#004AA3',
        'exati-blue-medium': '#8CBCFF',
        'exati-blue-light': '#E1EFFF',
    },
};
