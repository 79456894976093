const paths = [
    {
        path: '/financial-allocation-movement-type-list',
        name: 'FinancialAllocationMovementTypeList',
        title: 'FINANC_ALLOC_MOV_TYPES',
        icon: 'fa fa-briefcase',
        menu: 'financial',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "financialAllocationMovementType" */ /* webpackMode: "lazy" */ '@/pages/financialAllocationMovementType/FinancialAllocationMovementTypeList.vue'),
    },
    {
        path: '/financial-allocation-movement-type-general',
        name: 'FinancialAllocationMovementTypeGeneral',
        title: 'FINANC_ALLOC_MOV_TYPE_GENERAL',
        favCategory: 'financial',
        component: () => import(/* webpackChunkName: "financialAllocationMovementType" */ /* webpackMode: "lazy" */ '@/pages/financialAllocationMovementType/FinancialAllocationMovementTypeGeneral.vue'),
    },
];

export default paths;
