export default {
    namespaced: true,
    state: {
        panels: [],
        sessions: [],
    },
    actions: {
        showMarkerInOperationPanel: function (store, { sessionName, activateExtraSessions, marker }) {
            const panel = store.state.panels.find(i => i.sessionId);
            const session = store.getters.getSessionByName(sessionName);

            // open available operation panel
            store.commit('SET_CURRENT_PAGE', panel.sessionId, { root: true });

            store.dispatch('setActiveStateSession', {
                sessionId: session.sessionId,
                session: session,
                active: true,
            });


            // activate session or sessions
            if (activateExtraSessions) {
                activateExtraSessions.forEach(name => {
                    const activeSession = store.getters.getSessionByName(name);
                    store.dispatch('setActiveStateSession', {
                        sessionId: activeSession.sessionId,
                        session: activeSession,
                        active: true,
                    });
                });
            }

            // add highlight marker
            store.commit('setSessionTemporaryMarkers', {
                sessionId: session.sessionId,
                componentId: session.componentId,
                markers: [marker],
            });

            const removeMarker = () => {
                store.commit('setSessionTemporaryMarkers', {
                    sessionId: session.sessionId,
                    componentId: session.componentId,
                    markers: [],
                });

                panel.map.unsubscribe('click', removeMarker);
            };

            panel.map.subscribe('click', { handler: removeMarker });

            if (marker) {
                panel.map.fitBoundsByMultipleCoordinates([[marker.lat, marker.lng]]);
                panel.map.notifyAll(`${sessionName}:open:item`, marker);
            }
        },
        setActiveStateSession: function ({ state, commit }, { session, active }) {
            if (session.active === active) {
                return;
            }

            const { sessionId } = session;

            state.sessions
                .filter(s => s.sessionId === sessionId)
                .forEach(s => {
                if (session.mapOptions.onlyActiveMap && s.componentId !== session.componentId) {
                    commit('removeSessionMenuByComponentId', {
                        componentId: s.componentId,
                        sessionId: sessionId,
                    });
                    s.active = false;
                    s.items = [];
                    s.selectedItems = [];
                    s.mapMarkers = [];
                    s.temporaryMarkers = [];
                    return;
                }

                if (s.mapOptions.onlyActiveMap && s.componentId !== session.componentId) {
                    commit('removeSessionMenuByComponentId', {
                        componentId: s.componentId,
                        sessionId: sessionId,
                    });
                    s.active = false;
                    s.items = [];
                    s.selectedItems = [];
                    s.mapMarkers = [];
                    s.temporaryMarkers = [];
                    return;
                }

                if (s.componentId !== session.componentId) {
                    return;
                }

                if (!active) {
                    commit('removeSessionMenuByComponentId', {
                        componentId: s.componentId,
                        sessionId: sessionId,
                    });
                    s.items = [];
                    s.selectedItems = [];
                    s.mapMarkers = [];
                    s.temporaryMarkers = [];
                }

                s.active = active;
            });

            state.sessions = state.sessions.slice();
        },
    },
    mutations: {
        addSessionMapInstance: function (state, mapInstance) {
            state.panels.push(mapInstance);
        },
        removeSessionMapInstance: function (state, sessionId) {
            state.panels = state.panels.filter(s => s.sessionId !== sessionId);
        },
        addSession: function (state, session) {
            state.sessions.push(session);
        },
        removeSession: function (state, { sessionId, componentId }) {
            const index = state.sessions.findIndex(s => s.sessionId === sessionId && s.componentId === componentId);

            if (index === -1) {
                throw new Error(`session not found\n sessionId: ${sessionId}, componentId: ${componentId}`);
            }

            state.sessions.splice(index, 1);
        },
        setSessions: function (state, { sessionId, sessions }) {
            const otherSessions = state.sessions.filter(s => s.sessionId !== sessionId);
            state.sessions = otherSessions.concat(sessions);
        },
        setSessionItems: function (state, { sessionId, componentId, items }) {
            const component = state.sessions.find(
                session => session.sessionId === sessionId && session.componentId === componentId,
            );
            if (component) {
                component.items = Object.freeze(items);
            }
        },
        setSessionItemByName: function (state, { sessionName, items }) {
            const component = state.sessions.find(
                session => session.name === sessionName,
            );

            if (!component) {
                throw new Error(`operation-panel: session ${sessionName} not found`);
            }

            component.items = Object.freeze(items);
        },
        setSessionSelectedItems: function (state, { sessionId, componentId, items }) {
            const component = state.sessions.find(
                session => session.sessionId === sessionId && session.componentId === componentId,
            );

            if (component) {
                component.selectedItems = items;
            }
        },
        setSessionMapMarkers: function (state, { sessionId, componentId, items }) {
            const component = state.sessions.find(
                session => session.sessionId === sessionId && session.componentId === componentId,
            );
            if (component) {
                component.mapMarkers = items;
            }
        },
        setSessionMapMarkersByName: function (state, { sessionName, items }) {
            const component = state.sessions.find(
                session => session.name === sessionName,
            );

            if (!component) {
                throw new Error(`operation-panel: session ${sessionName} not found`);
            }

            component.mapMarkers = Object.freeze(items);
        },
        setSessionTemporaryMarkers: function (state, { sessionId, componentId, markers }) {
            const component = state.sessions.find(
                session => session.sessionId === sessionId && session.componentId === componentId,
            );
            if (component) {
                component.temporaryMarkers = markers || [];
            }
        },
        setSessionWmsLayers: function (state, { sessionId, componentId, layers }) {
            const component = state.sessions.find(
                session => session.sessionId === sessionId && session.componentId === componentId,
            );
            if (component) {
                component.wmsLayers = layers;
            }
        },
        setSessionMapOptions: function (state, { sessionId, componentId, options }) {
            const session = state.sessions.find(s => s.sessionId === sessionId && s.componentId === componentId);
            if (session) {
                session.mapOptions = {
                    ...session.mapOptions,
                    ...options,
                };
            }
        },
        setSessionMetadata: function (state, { sessionId, componentId, value }) {
            const session = state.sessions.find(s => s.sessionId === sessionId && s.componentId === componentId);
            if (session) {
                session.metadata = {
                    ...session.metadata,
                    ...value,
                };
            }
        },
        removeSessions: function (state, sessionId) {
            state.sessions = state.sessions.filter(
                s => s.sessionId !== sessionId,
            );
        },
        setSessionMenu: function (state, { menu, sessionId }) {
            const panel = state.sessions.find(
                p => p.sessionId === sessionId,
            );

            if (panel) {
                panel.menu = menu;
            }
        },
        setSessionCurrentMenuId: function (state, { sessionId, menuId }) {
            const panel = state.panels.find(
                p => p.sessionId === sessionId,
            );

            if (!panel) {
                throw new Error('operation-panel: session panel not found');
            }

            panel.currentMenuId = menuId;
        },
        addSingleSessionMenu: function (state, { sessionId, componentId, item }) {
            const panel = state.panels.find(
                p => p.sessionId === sessionId,
            );

            if (!panel) {
                throw new Error('operation-panel: session panel not found');
            }

            if (typeof item.component !== 'function') {
                throw new Error('operation-panel: component format invalid');
            }

            if (!item.key) {
                throw new Error('operation-panel: single items need to have a unique key');
            }

            if (panel.menu.some(i => i.item.key === item.key)) {
                return;
            }

            panel.menu.push({
                sessionId: sessionId,
                componentId: componentId,
                item: item,
            });
        },
        removeSessionMenuByComponentId: function (state, { sessionId, componentId }) {
            const panel = state.panels.find(
                p => p.sessionId === sessionId,
            );

            if (!panel) {
                throw new Error('operation-panel: session panel not found');
            }

            panel.menu = panel.menu.filter(m => m.componentId !== componentId);
        },
        openSessionMenuItemByKey: function (state, { sessionId, key }) {
            const panel = state.panels.find(
                p => p.sessionId === sessionId,
            );

            if (!panel) {
                throw new Error('operation-panel: session panel not found');
            }

            const index = panel.menu.findIndex(i => i.item.key === key);

            if (index === -1) {
                throw new Error('operation-panel: menu item not found');
            }

            panel.currentMenuId = index + 1;
        },
    },
    getters: {
        getSessions: (state) => (sessionId) => state.sessions.filter(s => s.sessionId === sessionId),
        getSessionByName: (state) => (name) => state.sessions.find(s => s.name === name),
        getSessionMapInstance: (state) => (sessionId) => {
            const mapInstance = state.panels.find(m => m.sessionId === sessionId);

            return mapInstance ? mapInstance.map : null;
        },
        getSessionMapOptions: (state) => (sessionId, componentId) => {
            const session = state.sessions.find(s => s.sessionId === sessionId && s.componentId === componentId);

            return session ? session.mapOptions : null;
        },
        getSessionItems: (state) => (sessionId, componentId) => {
            const component = state.sessions.find(
                session => session.sessionId === sessionId && session.componentId === componentId,
            );

            return component ? component.items : [];
        },
        getSessionSelectedItems: (state) => (sessionId, componentId) => {
            const component = state.sessions.find(
                session => session.sessionId === sessionId && session.componentId === componentId,
            );

            return component ? component.selectedItems : [];
        },
        getSessionMapMarkers: (state) => (sessionId, componentId) => {
            const component = state.sessions.find(
                session => session.sessionId === sessionId && session.componentId === componentId,
            );

            return component ? component.mapMarkers : [];
        },
        getSessionTemporaryMarkers: (state) => (sessionId, componentId) => {
            const component = state.sessions.find(
                session => session.sessionId === sessionId && session.componentId === componentId,
            );

            return component ? component.temporaryMarkers : [];
        },
        getSessionWmsLayers: (state) => (sessionId, componentId) => {
            const component = state.sessions.find(
                session => session.sessionId === sessionId && session.componentId === componentId,
            );

            return component ? component.wmsLayers : [];
        },
        getSessionMetadata: (state) => (sessionId, componentId) => {
            const component = state.sessions.find(
                session => session.sessionId === sessionId && session.componentId === componentId,
            );

            return component ? component.metadata : null;
        },
        getSessionPanel: (state) => (sessionId) => {
            const panel = state.panels.find(
                p => p.sessionId === sessionId,
            );

            return panel || null;
        },
        getSessionMenu: (state) => (sessionId) => {
            const panel = state.panels.find(
                p => p.sessionId === sessionId,
            );

            return panel ? panel.menu : [];
        },
    },

};
