<template>
    <a-container>
        <a-row align='baseline' style="margin-left: 0; margin-right: 0">
            <span class='title' style='padding-right: 10px'>{{_title}}</span>
            <slot name='append-title' />
            <a-spacer />
            <template v-if='!_filterDisabled && !$_aura.isEmpty(_command) && !isMobile'>
                <a-dynamic-command-filter
                     exhibition-mode='inline'
                     filter-group='main'
                    :_command='_command'
                     @updateValues='updateValues'
                    :_inline-fields-size='_inlineFieldsSize'
                />
            </template>

            <template v-if='!_filterDisabled && !$_aura.isEmpty(_command)'>
                <a-dynamic-command-filter 
                    ref='filter'
                    :key='_command.name'
                    :filter-group='isMobile ? "all" : "secondary"'
                    exhibition-mode='sidebar'
                    :_fab='false'
                    class='pivot-filter'
                    :_command='_command'
                    @updateValues='updateValues'
                    :_inline-fields-size='_inlineFieldsSize'

                />
            </template>
            
            <a-data-table-menu
                v-model="showMenu"
                :_items='itemsProvider'
                :_export-options='_exportOptions'
                :_headers='headers'
                :_visible-headers.sync='visibleHeaders'
                :_command.sync='command'
                :_advanced-filters.sync='advancedFilters'
                :_disable-options='[..._disableOptions, "headers-selector"]'
                @click:toggle-table-reports='toggleTableReports'
                @reload='refreshData'
                @ready='menuReady = true'
            >
            
                <template #append-list>
                    
                    <slot name='menu-options' />

                    <a-text-field
                        v-if="isMobile && !searchInFooter"
                        v-model='searchLazy'
                        class='ml-1 my-1'
                        flat
                        hide-no-data
                        hide-details
                        single-line
                        outlined
                        dense
                        :label='$t("SEARCH")'
                        style='max-width: 300px;'>
                    <template v-slot:append>
                        <a-icon small search class='pa-1' />
                    </template>
                </a-text-field>
                </template>
            </a-data-table-menu>

        </a-row>
        <slot name='info-header'>
        </slot>
        <v-card flat class='pa-0'>
            <v-card-text class='overflow-hidden pt-0 px-0' style='position: relative;'>
                <v-data-iterator
                    v-show='tableMode'
                    :search='searchComp'
                    :items='itemsProvider'
                    :hide-default-footer='_hideDefaultFooter'
                    v-bind='attrsComp'
                    :options.sync='optionsSync'
                    class='table-striped'
                    :server-items-length='serverItemsLength'
                    :loading='loading'
                    :disable-sort='_paginate ? true : $attrs["disable-sort"]'
                    v-on='$listeners'
                    @pagination='pagination'>
                    <slot v-for='slot in Object.keys($slots)' :slot='slot' :name='slot' />
                    <template v-for='slot in Object.keys($scopedSlots)' :slot='slot' slot-scope='scope'>
                        <slot :name='slot' v-bind='scope' />
                    </template>
                    <template v-if='Object.keys($slots).indexOf("header") < 0 && !_hideHeader' v-slot:header>
                        <a-banner single-line sticky class='mb-5'>
                            <slot name='prepend-search' />
                            <template v-slot:actions>
                                <a-row align='center' justify='end' dense>
                                    <a-text-field
                                            v-if='!isMobile && !_hideSearchBar && !_paginate && !_searchInFooter'
                                            v-model='searchLazy'
                                            class='ml-1 my-1 mr-2'
                                            flat
                                            hide-no-data
                                            hide-details
                                            single-line
                                            outlined
                                            dense
                                            :label='$t("SEARCH")'
                                            style='max-width: 300px; margin-right: 4px !important;'>
                                        <template v-slot:append>
                                            <a-icon small search class='pa-1' />
                                        </template>
                                    </a-text-field>
                                </a-row>
                            </template>
                        </a-banner>
                    </template>
                </v-data-iterator>
                <a-card v-if='_command && !tableMode' flat style='width: 100%'>
                    <component :is='$_aura.pivot.component' :_title='_title' :_command-name='_command.name' :_items='itemsProvider' @toggleTableMode='toggleTableReports' />
                </a-card>
                <a-row justify='end' align='center'>
                    <a-col class='grow'>
                        <a-text-field
                                v-if='_searchInFooter'
                                v-model='searchLazy'
                                class='ml-1 my-1'
                                flat
                                hide-no-data
                                hide-details
                                single-line
                                outlined
                                dense
                                :label='$t("SEARCH")'
                                style='max-width: 300px; margin: 0 !important;'>
                            <template v-slot:append>
                                <a-icon small search class='pa-1' />
                            </template>
                        </a-text-field>
                        <slot name='prepend-pagination' />
                    </a-col>
                    <template v-if='tableMode && _hideDefaultFooter && !_hideFooter'>
                        <a-col cols='12' sm='auto'>
                            <a-combobox
                                    ref='itemsPerPageCombobox'
                                    v-model='optionsSync.itemsPerPage'
                                    :items='rowsPerPageOptions'
                                    :filter='rowsPerPageFilter'
                                    hide-selected
                                    hide-details
                                    dense
                                    :label='$t("ROWS_PER_PAGE")'
                                    :disabled='loading'
                                    class='items-per-page-width'>
                                <template v-slot:selection='{ item }'>
                                    <span class='pr-2'>{{Number(item) === -1 ? $t('ALL') : item}}</span>
                                </template>
                                <template v-slot:item='{ item }'>
                                    <a-list-item-title>
                                        {{Number(item) === -1 ? $t('ALL') : item}}
                                    </a-list-item-title>
                                </template>
                            </a-combobox>
                        </a-col>
                        <a-col v-if='paginationInfo && paginationInfo.itemsLength' cols='12' sm='auto'>
                            <a-row justify='end' no-gutters>
                                <a-col cols='auto'>
                                    <span v-if='!_paginate'>{{$t('PAGE_OF_PAGE_COUNT', { page: paginationInfo.pageStart + 1, num_items: paginationInfo.pageStop, page_count: paginationInfo.itemsLength })}}</span>
                                    <span v-else>{{$t('PAGE')}}: {{paginationInfo.page}}</span>
                                </a-col>
                            </a-row>
                        </a-col>
                        <a-col cols='12' sm='auto'>
                            <a-row no-gutters justify='end'>
                                <a-col cols='auto'>
                                    <a-btn
                                        previous
                                        icon
                                        :_x-small='true'
                                        color-icon='undefined'
                                        color='undefined'
                                        :disabled='disablePrevious'
                                        @click='optionsSync.page--' />
                                </a-col>
                                <a-col cols='auto'>
                                    <a-btn
                                        next
                                        icon
                                        :_x-small='true'
                                        color-icon='undefined'
                                        color='undefined'
                                        :disabled='disableNext'
                                        @click='optionsSync.page++' />
                                </a-col>
                            </a-row>
                        </a-col>
                    </template>
                </a-row>
            </v-card-text>
        </v-card>
    </a-container>
</template>

<script>
    import tableFunctions from '../../util/mixins/table-functions';

    export default {
        name: 'ADataIterator',
        mixins: [tableFunctions],
        methods: {
            setHeaders: function () {

            }
        },
    };
</script>

<style scoped lang='stylus'>
    >>>.v-toolbar--dense
        .v-input__control
            min-height 38px !important

    .v-banner >>>.v-banner__wrapper
        padding 0
        padding-left 0px !important

    .v-banner >>>.v-banner__actions
        margin-left 0

    .v-banner >>>.v-banner__content
        overflow inherit

    .v-banner >>>.v-banner__text
        width 100%

    .items-per-page-width
        max-width 180px
</style>
