const paths = [
    {
        path: '/bidding-modality-list',
        name: 'BiddingModalityList',
        title: 'BIDDING_MODALITIES',
        icon: 'fa fa-briefcase',
        menu: 'budgets',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "biddingModality" */ /* webpackMode: "lazy" */ '@/pages/biddingModality/BiddingModalityList.vue'),
    },
    {
        path: '/bidding-modality-details',
        name: 'BiddingModalityDetails',
        title: 'BIDDING_MODALITY',
        icon: 'fa fa-briefcase',
        component: () => import(/* webpackChunkName: "biddingModality" */ /* webpackMode: "lazy" */ '@/pages/biddingModality/BiddingModalityDetails.vue'),
    },
];

export default paths;
