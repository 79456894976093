import i18n from '@/i18n';

export default function contractStatus (provider) {
    return new Promise((resolve) => {
        const resultProvider = [];
        resultProvider.push({ ID_STATUS_CONTRATO: -1, DESC_STATUS_CONTRATO: i18n.t('ALL') });
        resultProvider.push({ ID_STATUS_CONTRATO: -2, DESC_STATUS_CONTRATO: i18n.t('ALL_ACTIVE') });

        provider.forEach((item) => {
            resultProvider.push({ ID_STATUS_CONTRATO: parseInt(item.ID_STATUS_CONTRATO, 10), DESC_STATUS_CONTRATO: item.DESC_STATUS_CONTRATO });
        });

        resolve(resultProvider);
    });
}
