const paths = [
    {
        path: '/bills-to-pay-list',
        name: 'BillsToPayList',
        title: 'BILLS_TO_PAY',
        icon: 'fas fa-cash-register',
        menu: 'financial',
        component: () => import(/* webpackChunkName: "financialEvent" */ /* webpackMode: "lazy" */ '@/pages/financialEvent/BillsToPayList.vue'),
    },
    {
        path: '/bills-to-receive-list',
        name: 'BillsToReceiveList',
        title: 'BILLS_TO_RECEIVE',
        icon: 'fas fa-cash-register',
        menu: 'financial',
        component: () => import(/* webpackChunkName: "financialEvent" */ /* webpackMode: "lazy" */ '@/pages/financialEvent/BillsToReceiveList.vue'),
    },
    {
        path: '/financial-event',
        name: 'FinancialEvent',
        title: 'FINANCIAL_EVENT',
        component: () => import(/* webpackChunkName: "financialEvent" */ /* webpackMode: "lazy" */ '@/pages/financialEvent/FinancialEvent.vue'),
    },
    {
        path: '/financial-event-categorization-extra-data',
        name: 'FinancialEventCategorizationExtraData',
        title: 'EXTRA_DATA',
        component: () => import(/* webpackChunkName: "financialEvent" */ /* webpackMode: "lazy" */ '@/pages/financialEvent/FinancialEventCategorizationExtraData.vue'),
    },
    {
        path: '/financial-event-request-action',
        name: 'FinancialEventRequestAction',
        title: 'AUTHENTICATION_REQUEST',
        component: () => import(/* webpackChunkName: "financialEvent" */ /* webpackMode: "lazy" */ '@/pages/financialEvent/FinancialEventRequestAction.vue'),
    },
    {
        path: '/financial-event-wizard',
        name: 'FinancialEventWizard',
        title: 'FINANCIAL_EVENT',
        component: () => import(/* webpackChunkName: "financialEvent" */ /* webpackMode: "lazy" */ '@/pages/financialEvent/FinancialEventWizard.vue'),
    },
    {
        path: '/financial-event-schedule-wizard',
        name: 'FinancialEventScheduleWizard',
        title: 'FINANCIAL_SCHEDULE',
        component: () => import(/* webpackChunkName: "financialEvent" */ /* webpackMode: "lazy" */ '@/pages/financialEvent/FinancialEventScheduleWizard.vue'),
    },
    {
        path: '/financial-event-schedule-linked-categorization-list',
        name: 'FinancialEventScheduleLinkedCategorizationList',
        title: 'LINKED_CATEGORIZATIONS',
        component: () => import(/* webpackChunkName: "financialEvent" */ /* webpackMode: "lazy" */ '@/pages/financialEvent/FinancialEventScheduleLinkedCategorizationList.vue'),
    },
    {
        path: '/financial-event-authentication-confirmation',
        name: 'FinancialEventAuthenticationConfirmation',
        title: 'SELECT_A_OPTION',
        component: () => import(/* webpackChunkName: "financialEvent" */ /* webpackMode: "lazy" */ '@/pages/financialEvent/FinancialEventAuthenticationConfirmation.vue'),
    },
    {
        path: '/financial-event-approval-confirmation',
        name: 'FinancialEventApprovalConfirmation',
        title: 'SELECT_A_OPTION',
        component: () => import(/* webpackChunkName: "financialEvent" */ /* webpackMode: "lazy" */ '@/pages/financialEvent/FinancialEventApprovalConfirmation.vue'),
    },
    {
        path: '/financial-event-schedules-list',
        name: 'FinancialEventSchedulesList',
        title: 'SCHEDULES',
        component: () => import(/* webpackChunkName: "financialEvent" */ /* webpackMode: "lazy" */ '@/pages/financialEvent/FinancialEventSchedulesList.vue'),
    },
];

export default paths;
