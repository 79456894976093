import i18n from '@/i18n';
import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import { showErrorsResponse, parseSuccessResponse } from '@/pages/remoteManagement/services/utils';
import { pickBy } from 'lodash';
export class GetTelemanagementSystemDataAvailabilityIndicator extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_device-system-data-availability-indicator',
            type: 'SEARCH',
            node: 'RESPONSE',
            fields: {
                connectionInterval: new Field({
                    name: 'CONNECTION_INTERVAL',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('CONNECTION_INTERVAL'),
                    },
                }),
                initialDate: new Field({
                    name: 'INITIAL_DATE',
                    component: 'ADatePicker',
                    props: {
                        label: i18n.t('INITIAL_DATE'),
                    },
                }),
                finalDate: new Field({
                    name: 'FINAL_DATE',
                    component: 'ADatePicker',
                    props: {
                        label: i18n.t('END_DATE'),
                    },
                }),
            },
            customRequestParser: function (params) {
                const filters = pickBy(params, (v) => (v !== undefined && v !== null));
                const query = new URLSearchParams(filters);
                return {
                    BODY: query.toString(),
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetLightOnIndicatorAtNight extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_light-on-at-night-indicator',
            type: 'NONE',
            node: 'RESPONSE',
            fields: {
                connectionInterval: new Field({
                    name: 'MINIMUM_POWER',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('MINIMUM_POWER'),
                    },
                }),
                initialDate: new Field({
                    name: 'INITIAL_DATE',
                    component: 'ADatePicker',
                    props: {
                        label: i18n.t('INITIAL_DATE'),
                    },
                }),
                finalDate: new Field({
                    name: 'FINAL_DATE',
                    component: 'ADatePicker',
                    props: {
                        label: i18n.t('END_DATE'),
                    },
                }),
            },
            customRequestParser: function (params) {
                const filters = pickBy(params, (v) => v !== undefined);
                const query = new URLSearchParams(filters);
                return {
                    BODY: query.toString(),
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetLightOffIndicatorDuringTheDay extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_light-off-during-the-day-indicator',
            type: 'NONE',
            node: 'RESPONSE',
            fields: {
                connectionInterval: new Field({
                    name: 'MINIMUM_POWER',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('MINIMUM_POWER'),
                    },
                }),
                initialDate: new Field({
                    name: 'INITIAL_DATE',
                    component: 'ADatePicker',
                    props: {
                        label: i18n.t('INITIAL_DATE'),
                    },
                }),
                finalDate: new Field({
                    name: 'FINAL_DATE',
                    component: 'ADatePicker',
                    props: {
                        label: i18n.t('END_DATE'),
                    },
                }),
            },
            customRequestParser: function (params) {
                const filters = pickBy(params, (v) => v !== undefined);
                const query = new URLSearchParams(filters);
                return {
                    BODY: query.toString(),
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
