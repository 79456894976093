const paths = [
    {
        path: '/transfer-list',
        title: 'TRANSFERS',
        name: 'TransferList',
        icon: 'fa fa-briefcase',
        menu: 'financial',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "financy" */ /* webpackMode: "lazy" */ '@/pages/financy/transfer/TransferList.vue'),
    },
    {
        path: '/financial-event-complete-wizard',
        title: 'REGISTER_TRANSFER',
        name: 'FinancialEventCompleteWizard',
        component: () => import(/* webpackChunkName: "financy" */ /* webpackMode: "lazy" */ '@/pages/financy/transfer/FinancialEventCompleteWizard.vue'),
    },
    {
        path: '/financial-event-complete-wizard-data',
        name: 'FinancialEventCompleteWizardData',
        component: () => import(/* webpackChunkName: "financy" */ /* webpackMode: "lazy" */ '@/pages/financy/transfer/FinancialEventCompleteWizardData.vue'),
    },
    {
        path: '/financial-event-complete-wizard-selection',
        name: 'FinancialEventCompleteWizardFavoredSelection',
        component: () => import(/* webpackChunkName: "financy" */ /* webpackMode: "lazy" */ '@/pages/financy/transfer/FinancialEventCompleteWizardFavoredSelection.vue'),
    },
];

export default paths;
