const paths = [
    {
        path: '/cashFlos-scheduling',
        title: 'CASH_FLOW_SCHEDULING',
        name: 'CashFlowScheduling',
        icon: 'fa fa-briefcase',
        component: () => import(/* webpackChunkName: "financy" */ /* webpackMode: "lazy" */ '@/pages/financy/cashFlow/CashFlowScheduling.vue'),
    },
];

export default paths;
