const paths = [
    {
        path: '/allocated-employee-items',
        name: 'Allocadted Employee Items',
        title: 'ALLOCATED_EMPLOYEES_ITEMS',
        icon: 'fas fa-clipboard',
        menu: 'stock',
        component: () => import(/* webpackChunkName: "allocatedEmployeeItems" */ /* webpackMode: "lazy" */ '@/pages/allocatedEmployeeItems/AllocatedEmployeeItems.vue'),
    },
    {
        path: '/movement-allocated-employee-item-quick-way',
        name: 'MovementAllocatedEmployeeItemsQuickWay',
        title: 'MOVEMENT_ALLOCATED_EMPLOYEE_ITEMS_QUICK_WAY',
        component: () => import(/* webpackChunkName: "allocatedEmployeeItems" */ /* webpackMode: "lazy" */ '@/pages/allocatedEmployeeItems/MovementAllocatedEmployeeItemsQuickWay.vue'),
    },
    {
        path: '/movement-allocated-employee-items-multiple-items',
        name: 'MovementAllocatedEmployeeItemsMultipleItems',
        title: 'MOVEMENT_ALLOCATED_EMPLOYEE_ITEMS_MULTIPLE_ITEMS',
        component: () => import(/* webpackChunkName: "allocatedEmployeeItems" */ /* webpackMode: "lazy" */ '@/pages/allocatedEmployeeItems/MovementAllocatedEmployeeItemsMultipleItems.vue'),
    },
    {
        path: '/movement-allocated-employee-report',
        name: 'MovementAllocatedEmployeeReport',
        title: 'MovementAllocatedEmployeeReport',
        component: () => import(/* webpackChunkName: "allocatedEmployeeItems" */ /* webpackMode: "lazy" */ '@/pages/allocatedEmployeeItems/MovementAllocatedEmployeeReport.vue'),
    },
];

export default paths;
