<template>
    <a-container ref='container' v-resize='onResize' class='container' style='width: 100%; height: 100%;'>
        <a-dynamic-command-filter
            ref='filter'
            :key='getEnergyConsumptionPeriods.name'
            filter-group='all'
            exhibition-mode='sidebar'
            class='pivot-filter'
            :_fab='false'
            :_command='getEnergyConsumptionPeriods'
            :_sidebar-props='{ _hideActions: true }'
            :_loading.sync='loading'
            @updateValues='updateDashData'
        />
        <a-row no-gutters>
            <a-col>
                <a-radio-group
                    v-model='frequencySelected'
                    row
                    dense
                    class='mt-0'
                    hide-details
                >
                    <a-radio :label='$t("MONTHLY")' :value='frequency.MONTHLY' />
                    <a-radio :label='$t("YEARLY")' :value='frequency.YEARLY' />
                </a-radio-group>
            </a-col>
        </a-row>
        <chart
            ref='chart'
            :_options='chartOptions'
            style='height: 80%;'
        />
    </a-container>
</template>

<script>
    import { GetEnergyConsumptionPerPeriod } from '@/pages/report/energyConsumption/services/request.ts';
    import consts from '@/pages/report/energyConsumption/services/consts';

    export default {
        name: 'EnergyConsumptionPerPeriodChart',
        props: {
            /**
             * Prop to show if page is currently active
             * @type {Boolean}
             * @default {false}
            */
            _activePage: {
                type: Boolean,
                default: false,
            },
            /**
             * Command filter values sent from the Dashboard
             * @type {Object}
             * @default {{ filters: [], }}
             */
            _dashdata: {
                type: Object,
                default: () => ({
                    filters: [],
                }),
            },
            /**
             * Prop to determine if the chart is being used from the dashboard
             * Set to false if the component is being used outside of it
             * @type {Boolean}
             * @default {true}
             */
            _dashboardMode: {
                type: Boolean,
                default: true,
            },

            /**
             * Command filter values sent from the EnergyConsumptionPerPeriodReportList page
             * to fill the getEnergyConsumptionPeriods fields
             * @type {Array}
             * @default {[]}
             */
            _filters: {
                type: Array,
                default: () => ([]),
            },

            /**
             * Command items values sent from the EnergyConsumptionPerPeriodReportList page
             * to fill reportData and categoryData
             * @type {Array}
             * @default {[]}
             */
            _items: {
                type: Array,
                default: () => ([]),
            },
            /**
             * sync with parent loading
             * @type {Boolean}
             * @default {false}
             */
            _loading: {
                type: Boolean,
                default: false,
            },
            /**
             * sync with parent empty-data
             * @type {Boolean}
             * @default {false}
             */
            _emptyData: {
                type: Boolean,
                default: false,
            },
        },
        data: function () {
            return {
                dashdata: this._dashdata,
                categoryData: [],
                reportData: [],
                filters: null,
                items: null,
                frequency: consts.FREQUENCY,
                frequencySelected: 0,

                // Chart instance, used for the on() event
                chart: undefined,

                // Default chart before it's loaded with data
                chartOptions: {
                    maintainAspectRatio: false,
                    grid: {
                        containLabel: true,
                        bottom: '20',
                    },
                    xAxis: {
                        type: 'category',
                        name: this.$t('PERIOD'),
                    },
                    yAxis: {
                        type: 'value',
                        name: this.$t('CONSUME_ENERGY'),
                        axisLabel: {
                            formatter: '{value}',
                        },
                    },
                },
            };
        },
        computed: {
            getEnergyConsumptionPeriods: function () {
                const command = new GetEnergyConsumptionPerPeriod();
                command.initializeLoading = false;

                const { startDate, endDate } = command.fields;
                if (this.frequencySelected === this.frequency.YEARLY) {
                    startDate.component = 'ATextField';
                    endDate.component = 'ATextField';
                    startDate.props.label = this.$t('STARTING_YEAR');
                    endDate.props.label = this.$t('ENDING_YEAR');

                    startDate.parser = value => `01/01/${value}`;
                    endDate.parser = value => `31/12/${value}`;
                } else {
                    startDate.component = 'ADatePicker';
                    endDate.component = 'ADatePicker';
                    startDate.props.label = this.$t('INITIAL_DATE');
                    endDate.props.label = this.$t('ENDING_DATE');
                }

                const values = {};
                values.yearFilter = this.frequencySelected;
                command.values = values;
                command.onSuccess = this.processLoad;
                return command;
            },
            loading: {
                get: function () {
                    return this._loading;
                },
                set: function (value) {
                    this.$emit('update:_loading', value);
                },
            },
            emptyData: {
                get: function () {
                    return this._emptyData;
                },
                set: function (value) {
                    this.$emit('update:_emptyData', value);
                },
            },
        },
        watch: {
            dashdata: {
                deep: true,
                handler: function (value) {
                    this.$emit('update-dashdata', value);
                },
            },
            _activePage: {
                handler: function () {
                    this.onResize();
                },
            },
            _filters: {
                immediate: true,
                handler: function (value) {
                    if (value) {
                        this.$util.loadCommandFilter(this.getEnergyConsumptionPeriods, value);
                        this.frequencySelected = value.find(filter => filter.key === 'FILTRO_ANO').value;
                    }
                },
            },
            filters: {
                deep: true,
                handler: function (val) {
                    this.$emit('update:_filters', val);
                },
            },
            _items: {
                immediate: true,
                handler: function (value) {
                    if (value !== this.items) {
                        this.items = value;
                        this.loadData(this.items);
                    }
                },
            },
            items: {
                deep: true,
                handler: function (val) {
                    this.$emit('update:_items', val);
                },
            },
        },
        mounted: function () {
            this.load();
        },
        methods: {
            onResize: function () {
                this.$nextTick(this.$refs.chart.resize);
            },
            load: function () {
                this.onResize();
                this.chart = this.$refs.chart.getChart();

                if (this.dashdata.filters.length !== 0) {
                    this.$util.loadCommandFilter(this.getEnergyConsumptionPeriods, this.dashdata.filters);
                    this.$refs.filter.refresh(true);
                }
            },
            updateDashData: function (items) {
                this.emptyData = !items.length;

                // if the page is being used by the dashboard, update the filters
                if (this._dashboardMode) {
                    this.dashdata.filters = this.$util.getFiltersByCommand(this.getEnergyConsumptionPeriods);
                }
                this.loadData(items);
            },
            loadData: function (items) {
                this.formatToChart(items);
                this.loadChart();
            },
            formatToChart: function (data) {
                this.reportData = [];
                this.categoryData = [];
                Object.keys(data).forEach((i) => {
                    const item = data[i];
                    item.name = item.DATA_MES;
                    item.value = item.CONSUMO_MES;

                    this.reportData.push(item);
                    this.categoryData.push(item.name);
                });
            },
            loadChart: function () {
                if (!this.$refs.chart) {
                    return;
                }
                this.$refs.chart.reset();
                this.setChartOption();
                this.addSerie();
                this.addTooltip();
                this.onResize();
            },
            setChartOption: function () {
                this.$refs.chart.setOption({
                    xAxis: {
                        data: this.categoryData,
                    },
                });
            },
            addSerie: function () {
                this.$refs.chart.addSerie({
                    id: 1,
                    type: 'bar',
                    data: this.reportData,
                    animation: true,
                    left: 'right',
                    top: 'middle',
                    barWidth: '50%',
                });
            },
            addTooltip: function () {
                this.$refs.chart.setTooltip({
                    trigger: 'axis',
                    formatter: this.tooltipFormatter,
                    confine: true,
                });
            },
            tooltipFormatter: function (params) {
                params = params[0];
                const period = params.data.DATA_MES;
                const consume = Number(params.data.CONSUMO_MES);

                let label = `${this.$t('PERIOD')}: ${period}<br />`;
                label += `${this.$t('CONSUME')}: ${this.$util.numberFormat(consume)} KW<br />`;
                return label;
            },
            processLoad: function (resolve) {
                this.filters = this.$util.getFiltersByCommand(this.getEnergyConsumptionPeriods);

                const items = this.$util.getArrayByNode(resolve, this.getEnergyConsumptionPeriods.node);
                this.items = items;
                if (this.frequencySelected === this.frequency.MONTHLY) {
                    items.forEach(item => {
                        item.DATA_MES = item.DATA_MES.replace(/,/g, '');
                    });
                }
                return resolve;
            },
        },
    };
</script>
