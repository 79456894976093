const paths = [
    {
        path: '/external-origin-issue',
        name: 'ExternalOriginIssueList',
        title: 'EXTERNAL_ORIGINS_ISSUE',
        menu: 'ticket',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "externalOriginIssue" */ /* webpackMode: "lazy" */ '@/pages/externalOriginIssue/ExternalOriginIssueList.vue'),
    },
    {
        path: '/new-external-origin-issue',
        title: 'NEW_EXTERNAL_ORIGIN_ISSUE',
        name: 'NewExternalOriginIssue',
        component: () => import(/* webpackChunkName: "externalOriginIssue" */ /* webpackMode: "lazy" */ '@/pages/externalOriginIssue/NewExternalOriginIssue.vue'),
    },
    {
        path: '/external-origin-issue',
        title: 'EXTERNAL_ORIGIN_ISSUE',
        name: 'ExternalOriginIssue',
        component: () => import(/* webpackChunkName: "externalOriginIssue" */ /* webpackMode: "lazy" */ '@/pages/externalOriginIssue/ExternalOriginIssue.vue'),
    },
];

export default paths;
