export default [
    {
        path: '/maintenance-monitor',
        title: 'MAINTENANCES_MONITOR',
        name: 'MaintenanceMonitor',
        icon: 'fas fa-chart-pie',
        menu: 'maintenance',
        component: () => import(/* webpackChunkName: "maintenanceMonitor" */ /* webpackMode: "lazy" */ '@/pages/maintenance/maintenanceMonitor/MaintenanceMonitor.vue'),
    },
];
