const paths = [
    {
        path: '/people-list',
        title: 'PEOPLE',
        name: 'PeopleList',
        icon: 'fa fa-user-circle',
        menu: 'humanResources',
        component: () => import(/* webpackChunkName: "people" */ /* webpackMode: "lazy" */ '@/pages/people/PeopleList.vue'),
    },
    {
        path: '/people-list-excel-wizard',
        title: 'PEOPLE_REPORT',
        name: 'PeopleListExcelWizard',
        component: () => import(/* webpackChunkName: "people" */ /* webpackMode: "lazy" */ '@/pages/people/PeopleListExcelWizard.vue'),
    },
    {
        path: '/person',
        title: 'PERSON',
        name: 'Person',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "people" */ /* webpackMode: "lazy" */ '@/pages/people/Person.vue'),
    },
    {
        path: '/person-general',
        title: 'PEOPLE_GENERAL_DATA',
        name: 'PersonGeneral',
        component: () => import(/* webpackChunkName: "people" */ /* webpackMode: "lazy" */ '@/pages/people/PersonGeneral.vue'),
    },
    {
        path: '/person-contact-register',
        title: 'PERSON_CONTACT_REGISTER',
        name: 'PersonContactRegister',
        component: () => import(/* webpackChunkName: "people" */ /* webpackMode: "lazy" */ '@/pages/people/PersonContactRegister.vue'),
    },
    {
        path: '/person-contributor-email',
        title: 'PERSON_CONTRIBUTOR_EMAIL',
        name: 'PersonContributorEmail',
        component: () => import(/* webpackChunkName: "people" */ /* webpackMode: "lazy" */ '@/pages/people/PersonContributorEmail.vue'),
    },
    {
        path: '/person-employment-contract-register',
        title: 'PERSON_EMPLOYMENT_CONTRACT_REGISTER',
        name: 'PersonEmploymentContractRegister',
        component: () => import(/* webpackChunkName: "people" */ /* webpackMode: "lazy" */ '@/pages/people/PersonEmploymentContractRegister.vue'),
    },
    {
        path: '/person-employment-contract-cooperative-allocation',
        title: 'PERSON_EMPLOYMENT_CONTRACT_COOPERATIVE_ALLOCATION',
        name: 'PersonEmploymentContractCooperativeAllocation',
        component: () => import(/* webpackChunkName: "people" */ /* webpackMode: "lazy" */ '@/pages/people/PersonEmploymentContractCooperativeAllocation.vue'),
    },
    {
        path: '/person-position-salary-register',
        title: 'PERSON_POSITION_SALARY_REGISTER',
        name: 'PersonPositionSalaryRegister',
        component: () => import(/* webpackChunkName: "people" */ /* webpackMode: "lazy" */ '@/pages/people/PersonPositionSalaryRegister.vue'),
    },
    {
        path: '/person-certificate-register',
        title: 'PERSON_CERTIFICATE_REGISTER',
        name: 'PersonCertificateRegister',
        component: () => import(/* webpackChunkName: "people" */ /* webpackMode: "lazy" */ '@/pages/people/PersonCertificateRegister.vue'),
    },
    {
        path: '/person-bank-account-register',
        title: 'PERSON_BANK_ACCOUNT_REGISTER',
        name: 'PersonBankAccountRegister',
        component: () => import(/* webpackChunkName: "people" */ /* webpackMode: "lazy" */ '@/pages/people/PersonBankAccountRegister.vue'),
    },
    {
        path: '/person-traffic-violations-register',
        title: 'PERSON_TRAFFIC_VIOLATIONS_REGISTER',
        name: 'PersonTrafficViolationsRegister',
        component: () => import(/* webpackChunkName: "people" */ /* webpackMode: "lazy" */ '@/pages/people/PersonTrafficViolationsRegister.vue'),
    },
    {
        path: '/person-permission-login-mfa',
        title: 'MFA',
        name: 'PersonPermissionLoginMFA',
        component: () => import(/* webpackChunkName: "password" */ /* webpackMode: "lazy" */ '@/pages/people/PersonPermissionLoginMFA.vue'),
    },
    {
        path: '/person-permission-login-password-pattern',
        title: 'Dados do padrão de senha',
        name: 'PersonPermissionLoginPasswordPattern',
        component: () => import(/* webpackChunkName: "password" */ /* webpackMode: "lazy" */ '@/pages/people/PersonPermissionLoginPasswordPattern.vue'),
    },
];

export default paths;
