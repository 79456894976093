const paths = [
    {
        path: '/linked-public-place',
        title: 'LINKED_PUBLIC_PLACE',
        name: 'AreaLocationLinkedPublicPlace',
        component: () => import(/* webpackChunkName: "location" */ /* webpackMode: "lazy" */ '@/pages/location/AreaLocationLinkedPublicPlace.vue'),
    },
    {
        path: '/public-place',
        title: 'PUBLIC_PLACE',
        name: 'PublicPlace',
        component: () => import(/* webpackChunkName: "location" */ /* webpackMode: "lazy" */ '@/pages/location/PublicPlace.vue'),
    },
    {
        path: '/city',
        title: 'CITY',
        name: 'City',
        component: () => import(/* webpackChunkName: "location" */ /* webpackMode: "lazy" */ '@/pages/location/City.vue'),
    },
    {
        path: '/area',
        title: 'AREA',
        name: 'AreaLocation',
        component: () => import(/* webpackChunkName: "location" */ /* webpackMode: "lazy" */ '@/pages/location/AreaLocation.vue'),
    },
    {
        path: '/address-change',
        title: 'ADDRESS',
        name: 'AddressChange',
        component: () => import(/* webpackChunkName: "location" */ /* webpackMode: "lazy" */ '@/pages/location/AddressChange.vue'),
    },
    {
        path: '/location',
        title: 'LOCATIONS',
        name: 'Location',
        menu: 'registration',
        component: () => import(/* webpackChunkName: "location" */ /* webpackMode: "lazy" */ '@/pages/location/Location.vue'),
    },
    {
        path: '/import-location',
        title: 'IMPORT_LOCATIONS',
        name: 'ImportLocation',
        menu: 'registration',
        component: () => import(/* webpackChunkName: "location" */ /* webpackMode: "lazy" */ '@/pages/location/ImportLocation.vue'),
    },
    {
        path: '/district-deletion',
        title: 'DISTRICT_DELETION',
        name: 'DistrictDeletion',
        component: () => import(/* webpackChunkName: "location" */ /* webpackMode: "lazy" */ '@/pages/location/DistrictDeletion.vue'),
    },
    {
        path: '/link-districts-region',
        title: 'LINK_DISTRICTS_REGION',
        name: 'LinkDistrictsRegion',
        component: () => import(/* webpackChunkName: "location" */ /* webpackMode: "lazy" */ '@/pages/location/LinkDistrictsRegion.vue'),
    },
    {
        path: '/state-list',
        title: 'STATES',
        name: 'StateList',
        component: () => import(/* webpackChunkName: "location" */ /* webpackMode: "lazy" */ '@/pages/location/StateList.vue'),
    },
    {
        path: '/state',
        title: 'STATE',
        name: 'State',
        component: () => import(/* webpackChunkName: "location" */ /* webpackMode: "lazy" */ '@/pages/location/State.vue'),
    },
    {
        path: '/city-list',
        title: 'CITIES',
        name: 'CityList',
        component: () => import(/* webpackChunkName: "location" */ /* webpackMode: "lazy" */ '@/pages/location/CityList.vue'),
    },
    {
        path: '/city-data',
        title: 'CITY',
        name: 'CityData',
        component: () => import(/* webpackChunkName: "location" */ /* webpackMode: "lazy" */ '@/pages/location/CityData.vue'),
    },
];

export default paths;
