import _ from 'lodash';

const chatLog = {
    state: {
        chatMenuVisible: false,
        userChats: {},
    },
    getters: {
        chatMenuVisible: state => state.chatMenuVisible,
        userChats: state => state.userChats,
        nUserChats: state => Object.keys(state.userChats).length,
    },
    mutations: {
        SET_CHAT_LOG_VISIBLE: function (state, value) {
            state.chatMenuVisible = value;
        },
        ADD_USER_CHAT_MESSAGES: function (state, value) {
            state.userChats = value;
        },
        CLOSE_USER_CHAT: function (state, value) {
            state.userChats = value;
        },
    },
    actions: {
        SET_CHAT_LOG_MENU: function ({ commit }, value) {
            commit('SET_CHAT_LOG_VISIBLE', value);
        },
        addUserChatMessages: function ({ commit, state }, value) {
            const groups = _.groupBy(value, message => message.FROM.ID);
            const chats = _.cloneDeep(state.userChats);
            Object.keys(groups).forEach(gp => {
                const items = groups[gp];
                items.forEach(item => {
                    if (!chats[gp]) {
                        chats[gp] = {
                            FROM_NAME: item.FROM.NAME,
                            FROM_ID: item.FROM.ID,
                            MESSAGES: [],
                            OPEN: false,
                        };
                    }

                    chats[gp].MESSAGES.push({ MESSAGE: item.VALUE.MESSAGE, TIMESTAMP: item.CREATED_AT, ORIGIN: item.ORIGIN || 'other' });
                    chats[gp].MESSAGES = chats[gp].MESSAGES.sort((a, b) => a.TIMESTAMP - b.TIMESTAMP);
                });
            });

            commit('ADD_USER_CHAT_MESSAGES', chats);
        },
        closeUserChat: function ({ commit, state }, chatKey) {
          const chats = _.cloneDeep(state.userChats);
          const filtered = Object.entries(chats).filter((item) => item[0] !== chatKey);
            commit('CLOSE_USER_CHAT', Object.fromEntries(filtered));
        },
    },
};

export default chatLog;
