import i18n from '@/i18n';

export default function issueStatus (provider) {
    return new Promise((resolve) => {
        resolve([
            {
                ID_STATUS_OCORRENCIA: '-1',
                DESC_STATUS_OCORRENCIA: i18n.t('ALL'),
            },
            {
                ID_STATUS_OCORRENCIA: '-2',
                DESC_STATUS_OCORRENCIA: i18n.t('ALL_ACTIVE'),
            },
            ...provider,
        ]);
    });
}
