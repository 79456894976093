<template>
  <div>
    <a-menu
        v-model='picker'
        :close-on-content-click='false'
        :position-x='pickerPosition.x'
        :position-y='pickerPosition.y'
        offset-y
    >
        <a-tabs
            v-model='pickerTab'
            grow
            background-color='primary'
            dark
            hide-slider
        >
            <a-tab>{{$t('DATE')}}</a-tab>
            <a-tab>{{$t('TIME')}}</a-tab>
            <a-tabs-items v-model='pickerTab'>
                <a-tab-item>
                    <a-date-picker
                        v-model='pickerDate'
                        no-title
                        color='primary'
                        :max='maxDatePicker'
                        :min='minDatePicker'
                        :readonly='readonly'
                        :disabled='disabled'
                        :locale='$t("LANGUAGE_INITIALS")'
                    />
                </a-tab-item>
                <a-tab-item>
                    <a-time-picker
                        v-model='pickerTime'
                        style='border-radius:0;box-shadow:none'
                        color='primary'
                        :format='timeFormat'
                        :max='maxTimePicker'
                        :min='minTimePicker'
                        :readonly='readonly'
                        :disabled='disabled'
                        :use-seconds='useSeconds'
                    />
                </a-tab-item>
            </a-tabs-items>
        </a-tabs>
    </a-menu>

    <a-text-field
        v-bind="$attrs"
        v-model='textFieldDate'
        v-mask='textFieldMask'
        :rules='textFieldRules'
        append-icon='mdi-calendar'
        @click:append='handleClickAppend'
        @click='handleTextFieldClick'
        :readonly='readonly'
        :disabled='disabled'
        :outlined='outlined'
        ref="textField"
    >
    </a-text-field>
  </div>
</template>

<script>
import moment from 'moment';

export default {
    props: {
        value: {
            type: [Date, String, Object],
            required: false,
            default: null,
        },
        returnServe: {
            type: Boolean,
            required: false,
            default: false
        },
        returnDate: {
            type: Boolean,
            required: false,
        },
        returnMoment: {
            type: Boolean,
            required: false,
        },
        format: {
            type: String,
            required: false,
            default: 'YYYY-MM-DD HH:mm',
            validator: function (value) {
                return ['DD', 'YYYY', 'MM', 'HH', 'mm'].every(v => value.includes(v));
            }
        },
        useSeconds: {
            type: Boolean,
            required: false,
            default: false,
        },
        formatTextField: {
            type: String,
            required: false,
            default: function () {
                let format = this.$util.getDateFormatOfUserLocale();

                // make 1-digit month and day formats into 2-digit
                if (!format.includes('DD')){
                    format = format.replace('D', 'DD');
                }
                if (!format.includes('MM')){
                    format = format.replace('M', 'MM');
                }

                // remove special character of ar-ly and ar moment locales
                format = format.replace(/\u200F/g, '');

                // validate
                if (['DD', 'YYYY', 'MM'].every(v => format.includes(v))) {
                    format += this.useSeconds ? ' HH:mm:ss' : ' HH:mm';
                    return format;
                }

                return this.useSeconds ? 'DD/MM/YYYY HH:mm:ss' : 'DD/MM/YYYY HH:mm';
            },
            validator: function (value) {
                return ['DD', 'YYYY', 'MM', 'HH', 'mm'].every(v => value.includes(v));
            }
        },
        timeFormat: {
            type: String,
            required: false,
            default: function () {
                let timeFormat = this.$util.getTimeFormatOfUserLocale();

                // remove escape sequences which are enclosed by '[]' like '[Auer]'
                timeFormat = timeFormat.replace(/\[[^\]]*\]/g, '');

                if (['a', 'A'].some(v => timeFormat.includes(v))) {
                    return 'ampm';
                }
                else {
                    return '24hr';
                }
            },
        },
        rules: {
            type: Array,
            required: false,
            default: () => [],
        },
        maxDate: {
            type: [Date, String],
            required: false,
            default: null,
            validator: (value) => {
                const date = moment(value, 'YYYY-MM-DD HH:mm', true).isValid();
                const dateWithSeconds = moment(value, 'YYYY-MM-DD HH:mm:ss', true).isValid()
                return date || dateWithSeconds;
            },
        },
        minDate: {
            type: [Date, String],
            required: false,
            default: null,
            validator: (value) => {
                const date = moment(value, 'YYYY-MM-DD HH:mm', true).isValid();
                const dateWithSeconds = moment(value, 'YYYY-MM-DD HH:mm:ss', true).isValid()
                return date || dateWithSeconds;
            },
        },
        disableDefaultRules: {
            type: Boolean,
            required: false,
            default: false,
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        defaultTime: {
            type: String,
            required: false,
            default: null,
        },
        outlined: {
            type: Boolean,
            required: false,
            default: true,
        },
        openTimeTab: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * Instead of normal datepicker flow, will show a SweetAlert to confirm
         * if the user wants to set the value to the current datetime
         * @type {Boolean}
         * @default {false}
         */
        fillCurrentDatetime: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    data: function () {
        return {
            lazyTextFieldDate: '',
            picker: false,
            pickerTab: 0,
            pickerPosition: {
                x: 0,
                y: 0,
            },
            textFieldDateRule: value => {
                if (!value) {
                    return true;
                }
                const date = this.$moment(value, this.formatTextField, true);
                let isValid = date.isValid();

                if (isValid === true && this.maxDate) {
                    const maxDate = this.$moment(this.maxDate);
                    const maxDateMessage = this.$t('MAX_DATE_ENTITY', { max: maxDate.format(this.formatTextField) });
                    isValid = date.isSameOrBefore(this.maxDate) || maxDateMessage;
                }

                if (isValid === true && this.minDate) {
                    const minDate = this.$moment(this.minDate);
                    const minDateMessage = this.$t('MIN_DATE_ENTITY', { min: minDate.format(this.formatTextField) });
                    isValid = date.isSameOrAfter(this.minDate) || minDateMessage;
                }

                return isValid || this.$t('INVALID_DATE');
            }
        };
    },
    computed: {
        modelFormat: function () {
            if (this.returnServe) {
                if (this.useSeconds) {
                    return 'DD/MM/YYYY HH:mm:ss';
                }
                return 'DD/MM/YYYY HH:mm';
            }
            return this.format;
        },
        dateModel: {
            get: function () {
                const date = this.$moment(this.value, this.modelFormat);

                if (!date.isValid()) {
                    return null;
                }

                return date.format('YYYY-MM-DD HH:mm:ss');
            },
            set: function (value) {
                const date = this.$moment(value, 'YYYY-MM-DD HH:mm:ss', true);

                if (!date.isValid()) {
                    return this.$emit('input', null);
                }

                if (this.returnDate) {
                    return this.$emit('input', date.toDate());
                }

                if (this.returnServe) {
                    if (this.useSeconds) {
                        return this.$emit('input', date.format('DD/MM/YYYY HH:mm:ss'));
                    }
                    return this.$emit('input', date.format('DD/MM/YYYY HH:mm'));
                }

                if (this.returnMoment) {
                    return this.$emit('input', date);
                }

                return this.$emit('input', date.format(this.modelFormat));

            },
        },
        textFieldDate: {
            get: function () {
                return this.lazyTextFieldDate;
            },
            set: function (value) {
                const date = this.$moment(value, this.formatTextField, true);

                if (!date.isValid()) {
                    return this.dateModel = null;
                }

                this.dateModel = date.format('YYYY-MM-DD HH:mm:ss');
            },
        },
        pickerDate: {
            get: function () {
                const date = this.$moment(this.value, this.modelFormat);

                if (!date.isValid()) {
                    return null;
                }

                return date.format('YYYY-MM-DD');

            },
            set: function (value) {
                const defaultTime = this.defaultTime;
                if (defaultTime !== null) {
                    const timeArray = defaultTime.split(':');
                    const hour = Number(timeArray[0]);
                    const minute = Number(timeArray[1])
                    const date = this.$moment(value).set({'hour': hour, 'minute': minute}).format('YYYY-MM-DD HH:mm:ss');
                    this.dateModel = date;
                } else {
                    const date = this.$moment(value).format('YYYY-MM-DD HH:mm:ss');
                    this.dateModel = date;
                }
            },
        },
        pickerTime: {
            get: function () {
                const date = this.$moment(this.value, this.modelFormat);

                if (!date.isValid()) {
                    return null;
                }
                if (this.useSeconds){
                    return date.format('HH:mm:ss');
                }
                return date.format('HH:mm');

            },
            set: function (value) {
                const model = this.$moment(this.dateModel).format('YYYY-MM-DD');
                const date = this.$moment(`${model} ${value}`);
                if (date.isValid()) {
                    this.dateModel = date;
                }
            },
        },
        textFieldMask: function () {
            return this.formatTextField
                    .replace(/D/g, '#')
                    .replace(/Y/g, '#')
                    .replace(/M/g, '#')
                    .replace(/H/g, '#')
                    .replace(/m/g, '#')
                    .replace(/s/g, '#')
        },
        textFieldRules: function () {
            const rules = [];

            if (!this.disableDefaultRules) {
                rules.push(this.textFieldDateRule);
            }

            return rules.concat(this.rules);
        },
        maxDatePicker: function () {
            if (!this.maxDate) {
                return null;
            }
            if(this.useSeconds){
                return this.$moment(this.maxDate).format('YYYY-MM-DD HH:mm:ss');
            }
            return this.$moment(this.maxDate).format('YYYY-MM-DD HH:mm');
        },
        minDatePicker: function () {
            if (!this.minDate) {
                return null;
            }
            if(this.useSeconds){
                return this.$moment(this.minDate).format('YYYY-MM-DD HH:mm:ss');
            }
            return this.$moment(this.minDate).format('YYYY-MM-DD HH:mm');
        },
        maxTimePicker: function () {
            if (!this.maxDate || !this.pickerDate) {
                return null;
            }
            const date = this.$moment(this.pickerDate);

            if (date.isSameOrAfter(this.maxDate)) {
                return this.$moment(this.maxDate).format('HH:mm:ss');
            }

            return null
        },
        minTimePicker: function () {
            if (!this.minDate || !this.pickerDate) {
                return null;
            }
            
            const date = this.$moment(this.pickerDate);

            if (date.isSameOrBefore(this.minDate)) {
                return this.$moment(this.minDate).format('HH:mm:ss');
            }

            return null
        },
    },
    watch: {
        dateModel: {
            immediate: true,
            handler: function (value) {
                const date = this.$moment(value);

                if (!date.isValid()) {
                    this.lazyTextFieldDate = '';
                    return;
                }

                if (date.format(this.formatTextField) !== this.lazyTextFieldDate) {
                    this.lazyTextFieldDate = date.format(this.formatTextField);
                }
            }
        },
        openTimeTab: {
            immediate: true,
            handler: function (value) {
                this.pickerTab = value ? 1 : 0;
            }
        },
    },
    methods: {
        showPicker: function (e) {
            e.preventDefault();
            this.picker = false;
            this.pickerPosition = {
                x: e.clientX,
                y: e.clientY
            };
            this.$nextTick(() => {
                this.picker = true;
            })
        },
        handleClickAppend(e) {
            if (this.fillCurrentDatetime) {
                if (this.$refs.textField) {
                    this.$refs.textField.focus();
                }
                this.autoFillCurrentDatetime();
            } else {
                this.showPicker(e);
            }
        },
        handleTextFieldClick() {
            if (this.fillCurrentDatetime) {
                this.autoFillCurrentDatetime();
            }
        },
        async autoFillCurrentDatetime() {
            const result = await this.$_aura.swalO({
                text: this.$t('ATTRIBUTE_WILL_BE_FILLED_WITH_CURRENT_DATETIME_CONFIRM'),
                confirmButtonText: this.$t('YES'),
                cancelButtonText: this.$t('NO'),
            }, true);
            if (result.value) {
                const date = this.$moment(new Date(), 'DD/MM/YYYY hh:mm', true).format('L HH:mm');
                this.textFieldDate = date;
            }
        }
    }
}
</script>
