export default function itemFabricator (provider) {
    return new Promise((resolve) => {
        provider.forEach((item) => {
            if (item.COD_ITEM_FABRICANTE && item.DESC_ITEM_FABRICANTE) {
                item.DESC_ITEM_FABRICANTE = `${item.NOME_FABRICANTE} - ${item.COD_ITEM_FABRICANTE} - ${item.DESC_ITEM_FABRICANTE}`;
            } else if (item.COD_ITEM_FABRICANTE && !item.DESC_ITEM_FABRICANTE) {
                item.DESC_ITEM_FABRICANTE = `${item.NOME_FABRICANTE} - ${item.COD_ITEM_FABRICANTE}`;
            } else {
                item.DESC_ITEM_FABRICANTE = item.NOME_FABRICANTE;
            }
        });
        resolve(provider);
    });
}
