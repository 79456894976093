const paths = [
    {
        path: '/item-type',
        name: 'ItemTypeList',
        title: 'ITEM_TYPE',
        icon: 'widgets',
        menu: 'stock',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "itemtype" */ /* webpackMode: "lazy" */ '@/pages/entries/itemType/ItemTypeList.vue'),
    },
    {
        path: '/item-type-general',
        name: 'ItemTypeGeneral',
        title: 'ITEM_TYPE_GENERAL',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "itemtype" */ /* webpackMode: "lazy" */ '@/pages/entries/itemType/ItemTypeGeneral.vue'),
    },
];

export default paths;
