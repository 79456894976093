import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
export class GetWebServices extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarWebServices',
            type: 'SEARCH',
            node: 'WEBSERVICES.WEBSERVICE',
            fields: {
                webserviceId: new Field({
                    name: 'ID_WEBSERVICE',
                }),
                search: new Field({
                    name: 'BUSCA',
                }),
                details: new Field({
                    name: 'DETALHES',
                }),
            },
        });
    }
    async callCommand(param, debug, config) {
        return super.callCommand(param, debug, config);
    }
}
export class GetWebService extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarWebServiceConfig',
            type: 'SEARCH',
            node: 'CONFIG_WEBSERVICE',
            fields: {
                webserviceId: new Field({
                    name: 'ID_WEBSERVICE',
                }),
                search: new Field({
                    name: 'BUSCA',
                }),
                details: new Field({
                    name: 'DETALHES',
                }),
            },
        });
    }
    async callCommand(param, debug, config) {
        return super.callCommand(param, debug, config);
    }
}
export class DeleteWebService extends CallCommand {
    constructor() {
        super({
            name: 'ExcluirWebService',
            type: 'DELETE',
            fields: {
                webserviceId: new Field({
                    name: 'ID_WEBSERVICE',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, debug, config);
    }
}
export class SaveWebService extends CallCommand {
    constructor() {
        super({
            name: 'SalvarWebService',
            type: 'SAVE',
            fields: {
                webserviceId: new Field({
                    name: 'ID_WEBSERVICE',
                }),
                description: new Field({
                    name: 'DESC_WEBSERVICE',
                }),
                commandWeb: new Field({
                    name: 'COMANDO_WEBSERVICE',
                }),
                path: new Field({
                    name: 'PATH_WEBSERVICE',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, debug, config);
    }
}
export class SaveConfigWebservice extends CallCommand {
    constructor() {
        super({
            name: 'SalvarConfigWebService',
            type: 'SAVE',
            fields: {
                webserviceId: new Field({
                    name: 'ID_WEBSERVICE',
                }),
                configWeb: new Field({
                    name: 'CONFIG_WEBSERVICE',
                }),
            },
        });
    }
    async callCommand(param, debug, config) {
        return super.callCommand(param, debug, config);
    }
}
