import libPhoneNumber from 'google-libphonenumber';

const phoneRegion = {
    options: {},
    initializeOptions: function (options) {
        const list = [];
        const phoneUtil = libPhoneNumber.PhoneNumberUtil.getInstance();
        const phoneNumberType = libPhoneNumber.PhoneNumberType;
        const phoneNumberFormat = libPhoneNumber.PhoneNumberFormat;
        phoneUtil.getSupportedRegions().forEach(value => {
            const exampleNumberFixedLine = phoneUtil.getExampleNumberForType(value, phoneNumberType.FIXED_LINE);
            const exampleNumberMobile = phoneUtil.getExampleNumberForType(value, phoneNumberType.MOBILE);
            list.push({
                iso3: value,
                codCountry: phoneUtil.getCountryCodeForRegion(value),
                maskFixedLine: exampleNumberFixedLine ? phoneUtil.format(exampleNumberFixedLine, phoneNumberFormat.NATIONAL).replace(/[0-9]/g, '#') : undefined,
                maskMobile: exampleNumberMobile ? phoneUtil.format(exampleNumberMobile, phoneNumberFormat.NATIONAL).replace(/[0-9]/g, '#') : undefined,
            });
        });
        const aux = { phoneRegion: list };
        this.options = Object.assign({}, aux, options);
    },
    getPhoneRegion: function () {
        return this.options.phoneRegion;
    },
};

export default phoneRegion;
