const paths = [
    {
        path: '/type-obligation-vehicle-list',
        name: 'TypeObligationVehicleList',
        title: 'TYPES_OBLIGATION_VEHICLE',
        menu: 'fleet',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "typeObligationVehicle" */ /* webpackMode: "lazy" */ '@/pages/fleet/typeObligationVehicle/TypeObligationVehicleList.vue'),
    },
    {
        path: '/type-obligation-vehicle-details',
        name: 'TypeObligationVehicleDetails',
        title: 'TYPE_OBLIGATION_VEHICLE',
        favCategory: 'fleet',
        component: () => import(/* webpackChunkName: "typeObligationVehicle" */ /* webpackMode: "lazy" */ '@/pages/fleet/typeObligationVehicle/TypeObligationVehicleDetails.vue'),
    },
];

export default paths;
