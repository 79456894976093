import i18n from '@/i18n';

export default function issueType (provider) {
    return new Promise((resolve) => {
        const resultProvider = [];
        resultProvider.push({ ID_MOTIVO_ATENDIMENTO_PS: -2, DESC_MOTIVO_ATENDIMENTO_PS: i18n.t('NONE') });
        provider.forEach((item) => {
            resultProvider.push({
                ID_MOTIVO_ATENDIMENTO_PS: parseInt(item.ID_MOTIVO_ATENDIMENTO_PS, 10),
                DESC_MOTIVO_ATENDIMENTO_PS: item.DESC_MOTIVO_ATENDIMENTO_PS,
            });
        });

        resolve(resultProvider);
    });
}
