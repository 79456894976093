const paths = [
    {
        path: '/complementary-registration-list',
        name: 'ComplementaryRegistrationList',
        title: 'COMPLEMENTARY_REGISTRATIONS',
        icon: 'widgets',
        menu: 'asset',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "complementaryRegistration" */ /* webpackMode: "lazy" */ '@/pages/complementaryRegistration/ComplementaryRegistrationList.vue'),
    },
    {
        path: '/complementary-registration',
        name: 'ComplementaryRegistration',
        title: 'COMPLEMENTARY_REGISTRATION',
        favCategory: 'asset',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "complementaryRegistration" */ /* webpackMode: "lazy" */ '@/pages/complementaryRegistration/ComplementaryRegistration.vue'),
    },
    {
        path: '/complementary-registration-general',
        name: 'ComplementaryRegistrationGeneral',
        title: 'COMPLEMENTARY_REGISTRATION_GENERAL',
        component: () => import(/* webpackChunkName: "complementaryRegistration" */ /* webpackMode: "lazy" */ '@/pages/complementaryRegistration/ComplementaryRegistrationGeneralData.vue'),
    },
    {
        path: '/complementary-registration-attributes',
        name: 'ComplementaryRegistrationAttributes',
        title: 'COMPLEMENTARY_REGISTRATION_ATTRIBUTES',
        component: () => import(/* webpackChunkName: "complementaryRegistration" */ /* webpackMode: "lazy" */ '@/pages/complementaryRegistration/ComplementaryRegistrationAttributes.vue'),
    },
];

export default paths;
