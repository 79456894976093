const paths = [
    {
        path: '/scripts',
        name: 'Scripts',
        title: 'SCRIPTS',
        menu: 'customization',
        icon: 'mdi-language-javascript',
        component: () => import(/* webpackChunkName: "script" */ /* webpackMode: "lazy" */ '@/pages/script/ScriptList.vue'),
    },
    {
        path: '/script_details',
        title: 'SCRIPT',
        name: 'ScriptDetails',
        favCategory: 'customization',
        component: () => import(/* webpackChunkName: "script" */ /* webpackMode: "lazy" */ '@/pages/script/ScriptDetails.vue'),
    },
    {
        path: '/script-edit-history',
        name: 'ScriptEditHistory',
        title: '/SCRIPT_EDIT_HISTORY',
        component: () => import(/* webpackChunkName: "script" */ /* webpackMode: "lazy" */ '@/pages/script/ScriptEditHistory.vue'),
    },
];

export default paths;
