const paths = [
    {
        path: '/asset-attribute-evolution',
        name: 'AssetAttributeEvolution',
        title: 'ATTRIBUTES_EVOLUTION_ASSETS',
        icon: 'fas fa-clipboard',
        menu: 'asset',
        categories: [
            'reports',
        ],
        component: () => import(/* webpackChunkName: "assetAttributeEvolution" */ /* webpackMode: "lazy" */ '@/pages/assetAttributeEvolution/AssetAttributeEvolution.vue'),
    },
];

export default paths;
