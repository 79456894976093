const paths = [
    {
        path: '/equipment-problem-list',
        name: 'EquipmentProblemList',
        title: 'EQUIPMENT_PROBLEM_SEARCH',
        menu: 'fleet',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "equipmentProblemLog" */ /* webpackMode: "lazy" */ '@/pages/equipmentProblemLog/EquipmentProblemList.vue'),
    },
    {
        path: '/equipment-problem-details',
        name: 'EquipmentProblemDetails',
        component: () => import(/* webpackChunkName: "equipmentProblemLog" */ /* webpackMode: "lazy" */ '@/pages/equipmentProblemLog/EquipmentProblemDetails.vue'),
    },
];

export default paths;
