const paths = [
    {
        path: '/asset-geo-coding',
        name: 'Asset Geo Coding',
        title: 'GEOCODIFICATION',
        icon: 'fas fa-globe',
        menu: 'asset',
        component: () => import(/* webpackChunkName: "assetGeoCoding" */ /* webpackMode: "lazy" */ '@/pages/assetGeoCoding/AssetGeoCoding.vue'),
    },
];

export default paths;
