import i18n from '@/i18n';

export default async function requestMaterialShowProject (provider) {
    provider.forEach(item => {
        if (item.ID_SOLIC_MATERIAL) {
            const reserveDate = item.DATA_RESERVA || i18n.t('WITHOUT_DATE');
            if (item.NUM_OBRA) {
                item.description = `${item.NUM_OBRA}(${item.ID_OBRA}) - ${item.DESC_OBRA} - ${reserveDate}`;
            } else {
                item.description = `${item.ID_OBRA} - ${item.DESC_OBRA} - ${reserveDate}`;
            }
        } else {
            item.description = '';
        }
    });

    return provider;
}
