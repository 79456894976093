<template>
    <div>
        <a-menu
            v-model='picker'
            :close-on-content-click='false'
            :position-x='pickerPosition.x'
            :position-y='pickerPosition.y'
            offset-y
        >
            <div>
                <a-date-picker
                    v-model='pickerDate'
                    no-title
                    :max='max'
                    :min='min'
                    :readonly='readonly'
                    :disabled='disabled'
                    type='month'
                    :locale='$t("LANGUAGE_INITIALS")'
                />
            </div>
        </a-menu>
        <a-text-field
            v-model='textFieldDate'
            v-mask='textFieldMask'
            v-bind='$attrs'
            :rules='textFieldRules'
            :readonly='readonly'
            :disabled='disabled'
            append-icon='mdi-calendar'
            @click:append='showPicker'
        >
            <template v-slot:append-outer>
                <slot name='append-outer' />
            </template>
        </a-text-field>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'AMonthPicker',
        props: {
            value: {
                type: [Date, String, Object],
                required: false,
                default: null,
            },
            returnServe: {
                type: Boolean,
                required: false,
                default: false,
            },
            returnDate: {
                type: Boolean,
                required: false,
            },
            returnMoment: {
                type: Boolean,
                required: false,
            },
            format: {
                type: String,
                required: false,
                default: 'YYYY-MM',
                validator: function (value) {
                    return ['YYYY', 'MM'].every(v => value.includes(v));
                },
            },
            formatTextField: {
                type: String,
                required: false,
                default: 'MM/YYYY',
                validator: function (value) {
                    return ['YYYY', 'MM'].every(v => value.includes(v));
                },
            },
            rules: {
                type: Array,
                required: false,
                default: () => [],
            },
            maxDate: {
                type: [Date, String],
                required: false,
                default: null,
                validator: (value) => moment(value, 'YYYY-MM-DD', true).isValid(),
            },
            minDate: {
                type: [Date, String],
                required: false,
                default: null,
                validator: (value) => moment(value, 'YYYY-MM-DD', true).isValid(),
            },
            disableDefaultRules: {
                type: Boolean,
                required: false,
                default: false,
            },
            readonly: {
                type: Boolean,
                required: false,
                default: false,
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            },
            month: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        data: function () {
            return {
                lazyTextFieldDate: '',
                picker: false,
                pickerPosition: {
                    x: 0,
                    y: 0,
                },
                relativeExtraDays: [],
                textFieldDateRule: value => {
                    if (!value) {
                        return true;
                    }
                    const date = this.$moment(value, this.formatTextField, true);
                    let isValid = date.isValid();

                    if (isValid === true && this.maxDate) {
                        const maxDate = this.$moment(this.maxDate);
                        const maxDateMessage = this.$t('MAX_DATE_ENTITY', { max: maxDate.format(this.formatTextField) });
                        isValid = date.isSameOrBefore(this.maxDate) || maxDateMessage;
                    }

                    if (isValid === true && this.minDate) {
                        const minDate = this.$moment(this.minDate);
                        const minDateMessage = this.$t('MIN_DATE_ENTITY', { min: minDate.format(this.formatTextField) });
                        isValid = date.isSameOrAfter(this.minDate) || minDateMessage;
                    }

                    return isValid || this.$t('INVALID_DATE');
                },
            };
        },
        computed: {
            modelFormat: function () {
                if (this.returnServe) {
                    return 'MM/YYYY';
                }
                return this.format;
            },
            dateModel: {
                get: function () {
                    const date = this.$moment(this.value, this.modelFormat);

                    if (!date.isValid()) {
                        return null;
                    }

                    return date.format('YYYY-MM');
                },
                set: function (value) {
                    const date = this.$moment(value, 'YYYY-MM', true);

                    if (!date.isValid()) {
                        return this.$emit('input', null);
                    }

                    if (this.returnDate) {
                        return this.$emit('input', date.toDate());
                    }

                    if (this.returnServe) {
                        return this.$emit('input', date.format('MM/YYYY'));
                    }

                    if (this.returnMoment) {
                        return this.$emit('input', date);
                    }

                    return this.$emit('input', date.format(this.modelFormat));
                },
            },
            textFieldDate: {
                get: function () {
                    return this.lazyTextFieldDate;
                },
                set: function (value) {
                    const date = this.$moment(value, this.formatTextField, true);

                    if (!date.isValid()) {
                        this.dateModel = null;
                    } else {
                        this.dateModel = date.format('YYYY-MM');
                    }

                    return '';
                },
            },
            pickerDate: {
                get: function () {
                    const date = this.$moment(this.value, this.modelFormat);

                    if (!date.isValid()) {
                        return null;
                    }

                    return date.format('YYYY-MM');
                },
                set: function (value) {
                    this.dateModel = value;
                },
            },
            textFieldMask: function () {
                return this.formatTextField
                    .replace(/D/g, '#')
                    .replace(/Y/g, '#')
                    .replace(/M/g, '#');
            },
            textFieldRules: function () {
                const rules = [];

                if (!this.disableDefaultRules && !this.returnRelative) {
                    rules.push(this.textFieldDateRule);
                }

                return rules.concat(this.rules);
            },
            max: function () {
                if (this.returnRelative) {
                    return this.$moment().format('YYYY-MM');
                }
                return this.maxDate;
            },
            min: function () {
                return this.minDate;
            },
        },
        watch: {
            dateModel: {
                immediate: true,
                handler: function (value) {
                    const date = this.$moment(value);

                    if (!date.isValid()) {
                        this.lazyTextFieldDate = '';
                        return;
                    }

                    if (date.format(this.formatTextField) !== this.lazyTextFieldDate) {
                        this.lazyTextFieldDate = date.format(this.formatTextField);
                    }
                },
            },
        },
        methods: {
            showPicker: function (e) {
                e.preventDefault();
                this.picker = false;
                this.pickerPosition = {
                    x: e.clientX,
                    y: e.clientY,
                };
                this.$nextTick(() => {
                    this.picker = true;
                });
            },
        },
    };
</script>
