import Vue from 'vue';
import i18n from '@/i18n';
import { GetDeviceGroups, GetCommandTypes, GetCompanies, GetSchedules, GetMeasurementTypes, GetScheduleActionModes, GetScheduleActionTypes, GetScheduleActionOperations, GetScheduleStatus, GetMeasurementConfig, GetDeviceStatusLampModes, GetAuthInformation, GetFixtures, GetDeviceTypes, GetDefects, GetBoardTypes, GetBoardModels, GetNumberOfUuidsAvailableToPrint, } from '@/pages/remoteManagement/services/request';
import state from './state';
import mutations from './mutations';
import { GetAlertTypesTg, GetHardwareAlertTypes } from '../request/alert-type';
import { GetDeviceConfigs } from '../request/device-config';
import { GetDeviceStates } from '../request/device-state';
const actions = {
    getAuthInformation: async function () {
        const request = await new GetAuthInformation().callCommand();
        return request;
    },
    setIsTgAdmin: async function () {
        if (state.isTgAdmin === null) {
            const request = await this.getAuthInformation();
            const company = Vue.prototype.$util.getObjectByNode(request, 'RESPONSE.company');
            mutations.SET_IS_TG_ADMIN(company.company.includes('admin'));
        }
    },
    setBoardModels: async function () {
        const response = await new GetBoardModels().callCommand({});
        mutations.SET_BOARD_MODELS(response);
    },
    setBoardTypes: async function () {
        const response = await new GetBoardTypes().callCommand({});
        mutations.SET_BOARD_TYPES(response);
    },
    setCompanies: async function () {
        const { response } = await new GetCompanies().callCommand({});
        mutations.SET_COMPANIES(response);
    },
    setDefects: async function (filter = { where: { deleted_at: { eq: null } } }) {
        const { response } = await new GetDefects().callCommand({ filter: filter });
        const defects = response.map((defect) => {
            defect.description = i18n.t(defect.description);
            return defect;
        });
        mutations.SET_DEFECTS(defects);
    },
    setDeviceGroups: async function (companyId) {
        const { RAIZ } = await new GetDeviceGroups().callCommand({ companyId: companyId });
        mutations.SET_DEVICE_GROUPS(RAIZ.RESPONSE);
    },
    setDeviceConfigs: async function () {
        const { response } = await new GetDeviceConfigs().callCommand();
        mutations.SET_DEVICE_CONFIGS(response);
    },
    setDeviceTypes: async function () {
        const { response } = await new GetDeviceTypes().callCommand();
        mutations.SET_DEVICE_TYPES(response);
    },
    setDeviceStates: async function () {
        const { response } = await new GetDeviceStates().callCommand();
        mutations.SET_DEVICE_STATES(response);
    },
    setCommandTypes: async function () {
        const { response } = await new GetCommandTypes().callCommand({});
        mutations.SET_COMMAND_TYPES(response);
    },
    setFixtures: async function (params) {
        const { response } = await new GetFixtures().callCommand({
            include: [
                { relation: 'status' },
                { relation: 'tests' },
                { relation: 'board' },
            ],
            ...params,
        });
        mutations.SET_FIXTURES(response);
    },
    setLampModes: async function () {
        const { response } = await new GetDeviceStatusLampModes().callCommand();
        mutations.SET_LAMP_MODES(response);
    },
    setSchedules: async function () {
        const { RAIZ } = await new GetSchedules().callCommand({ include: [] });
        mutations.SET_SCHEDULES(RAIZ.RESPONSE);
    },
    setScheduleStatus: async function () {
        const { response } = await new GetScheduleStatus().callCommand();
        mutations.SET_SCHEDULE_STATUS(response);
    },
    setScheduleActionOptions: async function () {
        const modes = await new GetScheduleActionModes().callCommand();
        const types = await new GetScheduleActionTypes().callCommand();
        const operations = await new GetScheduleActionOperations().callCommand();
        mutations.SET_SCHEDULE_ACTION_OPTIONS({
            modes: modes.response,
            modeTypes: types.response,
            operations: operations.response,
        });
    },
    setMeasurementConfigs: async function (deviceTypeId) {
        const { RAIZ } = await new GetMeasurementConfig().callCommand({
            include: [],
            deviceTypeId: deviceTypeId,
        });
        mutations.SET_MEASUREMENT_CONFIGS(RAIZ.RESPONSE);
    },
    setMeasurementTypes: async function () {
        const { response } = await new GetMeasurementTypes().callCommand();
        mutations.SET_MEASUREMENT_TYPES(response);
    },
    setAlertTypes: async function (filter = { where: { deleted_at: { eq: null } } }) {
        const request = await new GetAlertTypesTg().callCommand({ filter: filter });
        mutations.SET_ALERT_TYPES(request.RAIZ.RESPONSE);
        return request;
    },
    setHardwareAlertTypes: async function () {
        const { response } = await new GetHardwareAlertTypes().callCommand();
        mutations.SET_HARDWARE_ALERT_TYPES(response);
    },
    setNumberOfUuidsAvailableToPrint: async function () {
        const response = await new GetNumberOfUuidsAvailableToPrint().callCommand({});
        mutations.SET_NUMBER_OF_UUIDS_AVAILABLE_TO_PRINT(response);
    },
    showResponseInformation: function (information) {
        if (!information) {
            return;
        }
        if (Array.isArray(information)) {
            if (information && information.length > 0) {
                information.forEach((message) => {
                    Vue.prototype.$_aura.showSuccessMessage(i18n.t(message));
                });
            }
        }
        else {
            Vue.prototype.$_aura.showSuccessMessage(i18n.t(information));
        }
    },
};
export default actions;
