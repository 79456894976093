import i18n from '@/i18n';

export default function workOrderStatusCustom (provider) {
    return new Promise((resolve) => {
        resolve([
            {
                ID_STATUS_ORDEM_SERVICO: '-1',
                DESC_STATUS_ORDEM_SERVICO: i18n.t('ALL'),
            },
            {
                ID_STATUS_ORDEM_SERVICO: '-2',
                DESC_STATUS_ORDEM_SERVICO: i18n.t('ALL_ACTIVE'),
            },
            ...provider,
        ]);
    });
}
