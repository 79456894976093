export default async function personBankAccount (provider) {
    provider.forEach(item => {
        if (item.DESC_BANCO_PESSOA) {
            item.description = `${item.DESC_BANCO} - ${item.DESC_BANCO_PESSOA}`;
        } else {
            item.description = `${item.DESC_BANCO}`;
        }
    });

    return provider;
}
