import state from './state';
const mutations = {
    SET_IS_TG_ADMIN: function (isTgAdmin) {
        state.isTgAdmin = isTgAdmin;
    },
    SET_BOARD_MODELS: function (boardModels) {
        state.boardModels = boardModels;
    },
    SET_BOARD_TYPES: function (boardTypes) {
        state.boardTypes = boardTypes;
    },
    SET_MAP_OPTIONS: function (options) {
        state.mapOptions = options;
    },
    SET_DEFECTS: function (defects) {
        state.defects = defects;
    },
    SET_DEVICE_GROUPS: function (deviceGroups) {
        state.deviceGroups = deviceGroups;
    },
    SET_DEVICE_TYPES: function (deviceTypes) {
        state.deviceTypes = deviceTypes;
    },
    SET_DEVICE_STATES: function (deviceStates) {
        state.deviceStates = deviceStates;
    },
    SET_DEVICE_CONFIGS: function (deviceConfigs) {
        state.deviceConfigs = deviceConfigs;
    },
    SET_COMPANIES: function (companies) {
        state.companies = companies;
    },
    SET_COMMAND_TYPES: function (commandTypes) {
        state.commandTypes = commandTypes;
    },
    SET_FIXTURES: function (fixtures) {
        state.fixtures = fixtures;
    },
    SET_LAMP_MODES: function (lampModes) {
        state.lampModes = lampModes;
    },
    SET_SCHEDULES: function (schedules) {
        state.schedules = schedules;
    },
    SET_SCHEDULE_STATUS: function (scheduleStatus) {
        state.scheduleStatus = scheduleStatus;
    },
    SET_SCHEDULE_ACTION_OPTIONS: function (options) {
        state.scheduleAction = options;
    },
    SET_MEASUREMENT_CONFIGS: function (measurementConfigs) {
        state.measurementConfigs = measurementConfigs;
    },
    SET_MEASUREMENT_TYPES: function (measurementTypes) {
        state.measurementTypes = measurementTypes;
    },
    SET_ALERT_TYPES: function (alertTypes) {
        state.alertTypes = alertTypes;
    },
    SET_HARDWARE_ALERT_TYPES: function (hardwareAlertTypes) {
        state.hardwareAlertTypes = hardwareAlertTypes;
    },
    SET_NUMBER_OF_UUIDS_AVAILABLE_TO_PRINT: function (numberOfUuidsAvailableToPrint) {
        state.numberOfUuidsAvailableToPrint = numberOfUuidsAvailableToPrint;
    },
    SET_SELECTED_COMPANY_DEVICES_ID: function (companyDevice) {
        const companyDeviceIds = companyDevice.map((cp) => cp.company_device_id);
        const deviceIds = companyDevice.map((cp) => cp.device_id);
        // set company-device ids
        state.companyDevice.selectedIds = companyDeviceIds;
        // set device-ids
        state.companyDevice.selectedDeviceIds = deviceIds;
    },
    SET_ENTITIES_THAT_STARTED_TESTS: function (entitiesThatStartedTests) {
        state.entitiesThatStartedTests = entitiesThatStartedTests;
    },
};
export default mutations;
