export default [
    {
        path: '/maintenance-log-list',
        title: 'MAINTENANCES_LOG_LIST',
        name: 'MaintenanceLogList',
        menu: 'maintenance',
        component: () => import(/* webpackChunkName: "maintenanceLog" */ /* webpackMode: "lazy" */ '@/pages/maintenance/maintenanceLog/MaintenanceLogList.vue'),
    },
    {
        path: '/maintenance-log-details',
        title: 'MAINTENANCE_LOG_DETAILS',
        name: 'MaintenanceLogDetails',
        menu: 'maintenance',
        component: () => import(/* webpackChunkName: "maintenanceLog" */ /* webpackMode: "lazy" */ '@/pages/maintenance/maintenanceLog/MaintenanceLogDetails.vue'),

    },
    {
        path: '/maintenance-log-general',
        title: 'MAINTENANCE_LOG_GENERAL',
        name: 'MaintenanceLogGeneral',
        menu: 'maintenance',
        component: () => import(/* webpackChunkName: "maintenanceLog" */ /* webpackMode: "lazy" */ '@/pages/maintenance/maintenanceLog/MaintenanceLogGeneral.vue'),

    },
    {
        path: '/maintenance-log-equipment',
        title: 'MAINTENANCE_LOG_EQUIPMENT',
        name: 'MaintenanceLogEquipment',
        menu: 'maintenance',
        component: () => import(/* webpackChunkName: "maintenanceLog" */ /* webpackMode: "lazy" */ '@/pages/maintenance/maintenanceLog/MaintenanceLogEquipment.vue'),

    },
    {
        path: '/maintenance-log-equipment-details',
        title: 'MAINTENANCE_LOG_EQUIPMENT_DETAILS',
        name: 'MaintenanceLogEquipmentDetails',
        menu: 'maintenance',
        component: () => import(/* webpackChunkName: "maintenanceLog" */ /* webpackMode: "lazy" */ '@/pages/maintenance/maintenanceLog/MaintenanceLogEquipmentDetails.vue'),

    },
    {
        path: '/maintenance-log-item-details',
        title: 'MAINTENANCE_LOG_ITEM_DETAILS',
        name: 'MaintenanceLogItemDetails',
        menu: 'maintenance',
        component: () => import(/* webpackChunkName: "maintenanceLog" */ /* webpackMode: "lazy" */ '@/pages/maintenance/maintenanceLog/MaintenanceLogItemDetails.vue'),

    },
    {
        path: '/maintenance-log-empty-items',
        title: 'MAINTENANCE_LOG_EMPTY_ITEMS',
        name: 'MaintenanceLogEmptyItems',
        menu: 'maintenance',
        component: () => import(/* webpackChunkName: "maintenanceLog" */ /* webpackMode: "lazy" */ '@/pages/maintenance/maintenanceLog/MaintenanceLogEmptyItems.vue'),

    },
];
