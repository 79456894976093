<template>
    <div>
        <a-tooltip v-bind='attrsTooltip' :disabled='!_tooltip'>
            <template v-slot:activator='{ on }'>
                <a-btn-raw
                    v-bind='attrs'
                    :class='buttonClass'
                    v-on='{ ...listener, ...on }'
                >
                    <slot v-if='_rightIcon' />
                    <a-icon
                        v-bind='attrsIcon'
                        :_tooltip='false'
                        :_class-icon='iconClass'>
                        <slot name='icon-body' />
                    </a-icon>
                    <slot v-if='!_rightIcon' />
                </a-btn-raw>
            </template>
            <span v-if='_tooltipText'>{{_tooltipText}}</span>
            <span v-else-if='!hasTooltipBody'>{{$t('DEFAULT')}}</span>
            <slot v-else name='tooltip-body' />
        </a-tooltip>
    </div>
</template>
<script>
    import { getBooleanFromProp } from '../../../util/helpers';

    export default {
        inheritAttrs: false,
        name: 'ABtnDefault',
        props: {
            _tooltip: {
                type: Boolean,
                default: true,
            },
            _tooltipText: {
                type: String,
                default: '',
            },
            _iconType: {
                type: String,
                default: 'default',
            },
            _pulse: {
                type: Boolean,
                default: false,
            },
            _rightIcon: {
                type: Boolean,
                default: false,
            },
            _classButton: {
                type: [String, Object],
                default: '',
            },
            _classIcon: {
                type: [String, Object],
                default: '',
            },
        },
        computed: {
            attrs: function () {
                const attrs = { ...this.$attrs };

                delete attrs.default;

                return  {
                    fab: !this.$slots.default || !this.$slots.default.length,
                    small: !this.$slots.default || !this.$slots.default.length,
                    depressed: this._pulse ? false : true,
                    bottom: getBooleanFromProp(this.$attrs.bottom) || (!getBooleanFromProp(this.$attrs.top) && !getBooleanFromProp(this.$attrs.left) && !getBooleanFromProp(this.$attrs.right)),
                    ...attrs
                }
            },
            attrsIcon: function () {
                const attrs = {};
                Object
                    .entries(this.$attrs)
                    .filter(value => value[0].length > '-icon'.length && value[0].slice(-1 * '-icon'.length) === '-icon')
                    .forEach(value => {
                        const name = value[0].slice(0, -1 * '-icon'.length);
                        attrs[name] = value[1];
                    });
                attrs[this._iconType] = true;
                return attrs;
            },
            attrsTooltip: function () {
                const attrs = {};
                Object
                    .entries(this.$attrs).filter(value => value[0].length > '-tooltip'.length && value[0].slice(-1 * '-tooltip'.length) === '-tooltip')
                    .forEach(value => {
                        const name = value[0].slice(0, -1 * '-tooltip'.length);
                        attrs[name] = value[1];
                    });
                attrs[this._iconType] = true;
                attrs.bottom = getBooleanFromProp(attrs.bottom) || (!getBooleanFromProp(attrs.top) && !getBooleanFromProp(attrs.left) && !getBooleanFromProp(attrs.right));
                return attrs;
            },
            listener: function () {
                return this.$listeners || {};
            },
            hasTooltipBody: function () {
                return !!this.$slots['tooltip-body'] || !!this.$scopedSlots['tooltip-body'];
            },
            buttonClass: function () {
                let attrClass;
                if (!this._classButton) {
                    attrClass = {};
                } else if (typeof this._classButton === 'string') {
                    attrClass = {};
                    this._classButton.split(' ').forEach(value => { attrClass[value] = true; });
                } else {
                    attrClass = this._classButton;
                }
                if (this.attrsIcon.color) {
                    attrClass[`${this.attrsIcon.color}--text`] = true;
                }
                if (this._pulse) {
                    attrClass['pulse-button'] = true;
                }
                return attrClass;
            },
            iconClass: function () {
                let attrClass;
                if (!this._classIcon) {
                    attrClass = {};
                } else if (typeof this._classIcon === 'string') {
                    attrClass = {};
                    this._classIcon.split(' ').forEach(value => { attrClass[value] = true; });
                } else {
                    attrClass = this._classIcon;
                }
                if (this.$slots.default && (!this.attrsIcon.default || this.$slots['icon-body'])) {
                    attrClass[this._rightIcon ? 'ml-2' : 'mr-2'] = true;
                }
                return attrClass;
            },
        },
    };
</script>
<style lang='stylus' scoped>
    @-webkit-keyframes pulse
        0%
            box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.3);
        50%
            box-shadow: 0 0 3px 10px rgba(255, 0, 0, 0.05);
        100%
            box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.3);
    @-moz-keyframes pulse
        0%
            box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.3);
        50%
            box-shadow: 0 0 3px 10px rgba(255, 0, 0, 0.05);
        100%
            box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.3);
    @-ms-keyframes pulse
        0%
            box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.3);
        50%
            box-shadow: 0 0 3px 10px rgba(255, 0, 0, 0.05);
        100%
            box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.3);
    @keyframes pulse
        0%
            box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.3);
        50%
            box-shadow: 0 0 3px 10px rgba(255, 0, 0, 0.05);
        100%
            box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.3);

    .pulse-button
        -webkit-animation pulse 2s infinite
        -moz-animation pulse 2s infinite
        -ms-animation pulse 2s infinite
        animation pulse 2s infinite
</style>
