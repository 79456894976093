<template>
    <a-row dense>
        <a-col class='grow' :cols='colsBreakpoint'>
            <a-card-list-item
                v-model='leftSelected'
                :_items='items'
                :_item-key='_itemKey'
                :_title='_leftTitle'
                :_height-list-item='_heightListItem'
                :_multiple='_multiple'
                :_color='_color'
                :_title-background-color='_leftTitleBackgroundColor || _titleBackgroundColor'
                :_title-color='_leftTitleColor || _titleColor'
                @all-selected-to='allSelectedTo(true, $event)'
                @all-selected-up-down='allSelectedUpDown(true, $event)'>
                <template v-slot:item='value'>
                    <slot name='item' v-bind='value' />
                    <slot name='left-item' v-bind='value' />
                </template>
            </a-card-list-item>
        </a-col>
        <div :class='midControlClassBreakpoint'>
            <a-btn
                default
                color='primary'
                class='ma-2'
                :_tooltip='false'
                @click='allToRight'>
                <a-icon color='white'>
                    fas fa-angle-double-{{viewportBreakpointAndUp ? 'right' : 'down'}}
                </a-icon>
            </a-btn>
            <a-btn
                default
                color='primary'
                class='ma-2'
                :_tooltip='false'
                @click='selectedToRight'>
                <a-icon color='white'>
                    fas fa-angle-{{viewportBreakpointAndUp ? 'right' : 'down'}}
                </a-icon>
            </a-btn>
            <a-btn
                default
                color='primary'
                class='ma-2'
                :_tooltip='false'
                @click='selectedToLeft'>
                <a-icon color='white'>
                    fas fa-angle-{{viewportBreakpointAndUp ? 'left' : 'up'}}
                </a-icon>
            </a-btn>
            <a-btn
                default
                color='primary'
                class='ma-2'
                :_tooltip='false'
                @click='allToLeft'>
                <a-icon color='white'>
                    fas fa-angle-double-{{viewportBreakpointAndUp ? 'left' : 'up'}}
                </a-icon>
            </a-btn>
        </div>
        <a-col class='grow' :cols='colsBreakpoint'>
            <a-card-list-item
                v-model='rightSelected'
                :_items='value'
                :_item-key='_itemKey'
                :_title='_rightTitle'
                :_height-list-item='_heightListItem'
                :_multiple='_multiple'
                :_color='_color'
                :_title-background-color='_rightTitleBackgroundColor || _titleBackgroundColor'
                :_title-color='_rightTitleColor || _titleColor'
                :_order-bottom='!viewportBreakpointAndUp'
                @all-selected-to='allSelectedTo(false, $event)'
                @all-selected-up-down='allSelectedUpDown(false, $event)'
            >
                <template v-slot:item='value'>
                    <slot name='item' v-bind='value' />
                    <slot name='right-item' v-bind='value' />
                </template>
            </a-card-list-item>
        </a-col>
    </a-row>
</template>

<script>
    export default {
        props: {
            /**
             * Card title from the left
             * @type {String}
             * @default {''}
             */
            _leftTitle: {
                type: String,
                require: true,
                default: '',
            },
            /**
             * Card title from the right
             * @type {String}
             * @default {''}
             */
            _rightTitle: {
                type: String,
                require: true,
                default: '',
            },
            /**
             * Card title background color
             * @type {String}
             * @default {null}
             */
            _titleBackgroundColor: {
                type: String,
                require: true,
                default: null,
            },
            /**
             * Card title background color from the left
             * @type {String}
             * @default {null}
             */
            _leftTitleBackgroundColor: {
                type: String,
                require: true,
                default: null,
            },
            /**
             * Card title background color from the right
             * @type {String}
             * @default {null}
             */
            _rightTitleBackgroundColor: {
                type: String,
                require: true,
                default: null,
            },
            /**
             * Card title color
             * @type {String}
             * @default {null}
             */
            _titleColor: {
                type: String,
                default: null,
            },
            /**
             * Card title color from the left
             * @type {String}
             * @default {null}
             */
            _leftTitleColor: {
                type: String,
                default: null,
            },
            /**
             * Card title color from the right
             * @type {String}
             * @default {null}
             */
            _rightTitleColor: {
                type: String,
                default: null,
            },
            /**
             * List height
             * @type {String | Number}
             * @default {'auto'}
             */
            _heightListItem: {
                type: [String, Number],
                require: true,
                default: 'auto',
            },
            /**
             * List provider from the left
             * @type {Array}
             * @default {[]}
             */
            _items: {
                type: Array,
                require: true,
                default: () => [],
            },
            /**
             * List provider from the right (v-model)
             * @type {Array}
             * @default {[]}
             */
            _value: {
                type: Array,
                require: true,
                default: () => [],
            },
            /**
             * Key of item object
             * @type {String}
             * @default {'id'}
             */
            _itemKey: {
                type: String,
                require: true,
                default: 'id',
            },
            /**
             * Multiple selection
             * @type {Boolean}
             * @default {false}
             */
            _multiple: {
                type: Boolean,
                require: true,
                default: false,
            },
            /**
             * Selection color
             * @type {String}
             * @default {'primary'}
             */
            _color: {
                type: String,
                default: 'primary',
            },
            /**
             * Enable order control
             * @type {Boolean}
             * @default {false}
             */
            _orderMode: {
                type: Boolean,
                require: true,
                default: false,
            },
            /**
             * Disable order control from the left
             * @type {Boolean}
             * @default {false}
             */
            _disableLeftOrder: {
                type: Boolean,
                require: true,
                default: false,
            },
            /**
             * Disable order control from the right
             * @type {Boolean}
             * @default {false}
             */
            _disableRightOrder: {
                type: Boolean,
                require: true,
                default: false,
            },
            /**
             * Enable if the items will move to another provider
             * @type {Boolean}
             * @default {false}
             */
            _spliceMode: {
                type: Boolean,
                require: true,
                default: false,
            },
            /**
             * Set viewport breakpoint code no-wrap
             * @type {Boolean}
             * @default {false}
             */
            _viewportBreakpoint: {
                type: String,
                require: true,
                default: 'md',
            },
        },
        model: {
            prop: '_value',
        },
        name: 'APickList',
        data: function () {
            return {
                items: [...this._items],
                value: [],
                leftSelected: [],
                rightSelected: [],
            };
        },
        computed: {
            viewportBreakpointAndUp: function () {
                return this.$vuetify.breakpoint[`${this._viewportBreakpoint}AndUp`];
            },
            colsBreakpoint: function () {
                return this.viewportBreakpointAndUp ? null : 12;
            },
            midControlClassBreakpoint: function () {
                const classObj = {};
                if (this.viewportBreakpointAndUp) {
                    classObj.column = true;
                    classObj['column--dense'] = true;
                    classObj.shrink = true;
                } else {
                    classObj.row = true;
                    classObj['row--dense'] = true;
                }
                classObj['align-self-center'] = true;
                classObj['align-center'] = true;
                classObj['justify-center'] = true;
                return classObj;
            },
        },
        watch: {
            _items: {
                deep: true,
                handler: function (value, oldValue) {
                    if (!this.$_aura.isEqual(value, oldValue)) {
                        this.items = this.getProcessItems(value, this.value);
                    }
                },
            },
            _value: {
                deep: true,
                handler: function (value) {
                    this.value = value;
                },
            },
            items: {
                deep: true,
                handler: function (value) {
                    if (this._spliceMode) {
                        this.$emit('update:_items', value);
                    }
                },
            },
            value: {
                deep: true,
                handler: function (value) {
                    this.$emit('input', value);
                },
            },
        },
        created: function () {
            this.value = [...this._value];
            this.items = this.getProcessItems(this._items, this.value);
        },
        methods: {
            getProcessItems: function (items, value) {
                return items.filter(item => !value.find(el => el[this._itemKey] === item[this._itemKey]));
            },
            allToRight: function () {
                this.leftSelected = [];
                this.value.splice(this.value.length, 0, ...this.items);
                this.items.splice(0);
            },
            allToLeft: function () {
                this.rightSelected = [];
                this.items.splice(this.items.length, 0, ...this.value);
                this.value.splice(0);
            },
            selectedToRight: function () {
                this.value.splice(this.value.length, 0, ...this.items.filter((value, index) => this.leftSelected.includes(index)));
                this.items = this.items.filter((value, index) => !this.leftSelected.includes(index));
                this.leftSelected = [];
            },
            selectedToLeft: function () {
                this.items.splice(this.items.length, 0, ...this.value.filter((value, index) => this.rightSelected.includes(index)));
                this.value = this.value.filter((value, index) => !this.rightSelected.includes(index));
                this.rightSelected = [];
            },
            allSelectedTo: function (left, top) {
                const keyProvider = left ? 'items' : 'value';
                const keySelected = left ? 'leftSelected' : 'rightSelected';
                const indexInsert = top ? 0 : this[keyProvider].length;
                const selectedIndex = this[keySelected].map((value, index) => (top ? index : this[keyProvider].length - 1 - index));
                const selected = this[keyProvider].filter((value, index) => this[keySelected].includes(index));
                this[keyProvider] = this[keyProvider].filter((value, index) => !this[keySelected].includes(index));
                this[keyProvider].splice(indexInsert, 0, ...selected);
                this[keySelected] = selectedIndex;
            },
            allSelectedUpDown: function (left, up) {
                const keyProvider = left ? 'items' : 'value';
                const keySelected = left ? 'leftSelected' : 'rightSelected';
                let provider = [...this[keyProvider]];
                let selected = this[keySelected].sort();
                if (!up) {
                    provider = provider.reverse();
                    selected = selected.map(value => provider.length - 1 - value);
                    selected = selected.sort();
                }
                let edge = 0;
                for (let i = 0; i < selected.length; i++) {
                    const index = selected[i];
                    if (index === edge) {
                        edge++;
                    } else {
                        provider.splice(index - 1, 2, provider[index], provider[index - 1]);
                        selected[i]--;
                    }
                }
                if (!up) {
                    provider = provider.reverse();
                    selected = selected.map(value => provider.length - 1 - value);
                }
                this[keyProvider] = provider;
                this[keySelected] = selected;
            },
        },
    };
</script>
