<template>
    <a-layout
        no-gutters
        align-content-center
        align-center
        :style='"margin-bottom: " + (!hideDetails || hideDetails === "auto" ? "20" : "0")'
    >
        <a-col :cols='!disableRelative ? 11 : 12' class='ma-0 pa-0'>
            <div style='max-height: 40px;'>
                <transition name='flip'>
                    <div v-if='showDatePicker' key='date'>
                        <a-menu
                            v-model='picker'
                            :close-on-content-click='false'
                            :position-x='pickerPosition.x'
                            :position-y='pickerPosition.y'
                            offset-y
                        >
                            <div>
                                <a-date-picker
                                    v-model='pickerDate'
                                    no-title
                                    range
                                    :readonly='readonly'
                                    :disabled='disabled'
                                    type='month'
                                />
                            </div>
                        </a-menu>
                        <a-text-field
                            v-model='textFieldDate'
                            v-mask='textFieldMask'
                            v-bind='$attrs'
                            :rules='textFieldRules'
                            :readonly='readonly'
                            :disabled='disabled'
                            :hide-details='hideDetails'
                            append-icon='mdi-calendar'
                            @click:append='showPicker'
                        >
                            <template v-slot:append-outer>
                                <slot name='append-outer' />
                            </template>
                            <template v-slot:append>
                                <v-badge v-if='dayOffset !== 0' :content='textDayOffset' inline>
                                    <v-icon @click.native='showPicker'>
                                        mdi-calendar
                                    </v-icon>
                                </v-badge>
                            </template>
                        </a-text-field>
                    </div>
                    <div v-else key='relative' style='width: 100%'>
                        <a-row class='ma-0 pa-0'>
                            <a-col :cols='(relativeSelected != null && relativeSelected.value === "X_QUARTERS_AGO") ? 8 : 12' class='ma-0 pa-0'>
                                <a-select
                                    v-model='relativeSelected'
                                    :items='attributes'
                                    :label='relativeLabel + " - " + $t("RELATIVE")'
                                    :readonly='readonly'
                                    :disabled='disabled'
                                    :clearable='true && !disabled && !readonly'
                                    :rules='rules'
                                    :hide-details='hideDetails'
                                    persistent-label
                                    persistent-hint
                                    full-width
                                    return-object
                                />
                            </a-col>
                            <a-col :cols='(relativeSelected != null && relativeSelected.value === "X_QUARTERS_AGO") ? 4 : 0' class='ma-0 pa-0'>
                                <template v-if='relativeSelected != null && relativeSelected.value === "X_QUARTERS_AGO" '>
                                    <a-text-field
                                        v-model.number='xQuartersAgo'
                                        :label='$t("QUARTER")'
                                        :clearable='true && !disabled && !readonly'
                                        :rules='rules'
                                        :readonly='readonly'
                                        :disabled='disabled'
                                        full-width
                                        type='number'
                                        style='margin-left: 10px;'
                                        hide-details
                                    />
                                </template>
                            </a-col>
                        </a-row>
                    </div>
                </transition>
            </div>
        </a-col>
        <a-col v-if='!disableRelative' cols='1' class='ma-0 pa-0'>
            <a-btn
                ref='btn'
                x-small
                variant='plain'
                :ripple='false'
                style='background-color:transparent;'
                @click='toggleDatePicker'
            >
                <a-icon>
                    mdi-swap-vertical
                </a-icon>
            </a-btn>
        </a-col>
    </a-layout>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'AQPicker',
        props: {
            value: {
                type: [Array, String, Object],
                required: false,
                default: null,
            },
            returnServe: {
                type: Boolean,
                required: false,
                default: false,
            },
            returnDate: {
                type: Boolean,
                required: false,
            },
            returnMoment: {
                type: Boolean,
                required: false,
            },
            formatTextField: {
                type: String,
                required: false,
                default: function () {
                    return this.$util.getQuarterDateFormatOfUserLocale();
                },
                validator: function (value) {
                    return ['YYYY', 'QQ'].every(v => value.includes(v));
                },
            },
            rules: {
                type: Array,
                required: false,
                default: () => [],
            },
            disableDefaultRules: {
                type: Boolean,
                required: false,
                default: false,
            },
            readonly: {
                type: Boolean,
                required: false,
                default: false,
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            },
            monthOffset: {
                type: [Number, String],
                required: false,
                default: 0,
            },
            dayOffset: {
                type: [Number, String],
                required: false,
                default: 0,
            },
            defaultQuarter: {
                type: String,
                required: false,
                default: '',
            },
            disableRelative: {
                type: Boolean,
                required: false,
                default: false,
            },
            relativeLabel: {
                type: String,
                required: false,
                default: function () {
                    return this.$t('QUARTER');
                },
            },
            required: {
                type: Boolean,
                required: false,
                default: false,
            },
            hideDetails: {
                type: [Boolean, String],
                required: false,
                default: false,
            },
        },
        data: function () {
            return {
                lazyTextFieldDate: '',
                picker: false,
                badge: this.dayOffset !== 0,
                pickerPosition: {
                    x: 0,
                    y: 0,
                },
                quarterRegex: /^.*(\d.*\d{4}|\d{4}.*\d).*$/,
                showDatePicker: true,
                relativeSelected: null,
                xQuartersAgo: null,
                textFieldDateRule: value => {
                    if (!value) {
                        return true;
                    }
                    if (!this.isValidQuarter(value)) {
                        return this.$t('INVALID_DATE');
                    }
                    const inputDate = this.quarterStringToMoment(value);
                    const year = inputDate.year();
                    const quarter = inputDate.quarter();
                    const startDate = this.$moment()
                        .year(year)
                        .quarter(quarter)
                        .startOf('quarter')
                        .add(Math.abs(this.dayOffset), 'days')
                        .add(Math.abs(this.monthOffset) % 3, 'months');
                    const endDate = this.$moment()
                        .year(year)
                        .quarter(quarter)
                        .endOf('quarter')
                        .add(Math.abs(this.dayOffset), 'days')
                        .add(Math.abs(this.monthOffset) % 3, 'months');
                    const isValid = startDate.isValid() && endDate.isValid();

                    return isValid || this.$t('INVALID_DATE');
                },
                attributes: [
                    {
                        text: this.$t('CURRENT_QUARTER'),
                        value: 'CURRENT_QUARTER',
                    },
                    {
                        text: this.$t('X_QUARTERS_AGO'),
                        value: 'X_QUARTERS_AGO',
                    },
                ],
            };
        },
        computed: {
            dateModel: {
                get: function () {
                    if (!!this.value && typeof this.value !== 'string') {
                        if (Object.hasOwn(this.value, 'quartersAgo')) {
                            const { quartersAgo } = this.value;
                            if (quartersAgo == null) {
                                this.relativeSelected = this.attributes[0];
                                this.xQuartersAgo = null;
                            } else {
                                this.relativeSelected = this.attributes[1];
                                this.xQuartersAgo = quartersAgo;
                            }
                            this.showDatePicker = false;
                        }

                        let year;
                        if (this.value.startDate instanceof Date) {
                            year = this.value.startDate.getFullYear();
                        } else if (this.$moment.isMoment(this.value.startDate)) {
                            year = this.value.startDate.year();
                        } else if (typeof this.value.startDate === 'string') {
                            const startDateString = new Date(this.value.startDate);
                            if(startDateString instanceof Date && !isNaN(startDateString)){
                                year = startDateString.getFullYear();
                            } else {
                                return null;
                            }
                        } else {
                            return null;
                        }
                        const { quarter } = this.value;
                        const quarterMoment = this.$moment().year(year).quarter(quarter);
                        return this.momentToQuarterString(quarterMoment);
                    }
                    try {
                        const dateObj = JSON.parse(this.value.replace(/'/g, '"'));

                        if (Object.hasOwn(dateObj, 'quartersAgo')) {
                            const { quartersAgo } = dateObj;
                            if (quartersAgo == null) {
                                this.relativeSelected = this.attributes[0];
                                this.xQuartersAgo = null;
                            } else {
                                this.relativeSelected = this.attributes[1];
                                this.xQuartersAgo = quartersAgo;
                            }
                            this.showDatePicker = false;
                        }
                        const { quarter, year } = dateObj;
                        const dateMoment = this.$moment().year(year).quarter(quarter).startOf('quarter')
                            .add(Math.abs(this.monthOffset) % 3, 'months')
                            .add(Math.abs(this.dayOffset), 'days');
                        return this.momentToQuarterString(dateMoment);
                    } catch (e) {
                        if (!this.isValidQuarter(this.value)) {
                            return null;
                        }
                        return this.value;
                    }
                },
                set: function (value) {
                    if (!this.isValidQuarter(value)) {
                        return this.$emit('input', null);
                    }
                    let inputDate = '';
                    if (value.search(/Q/) === 0) {
                        inputDate = this.quarterStringToMoment(value, 'QQ/YYYY');
                    } else {
                        inputDate = this.quarterStringToMoment(value);
                    }
                    const year = inputDate.year();
                    const quarter = inputDate.quarter();
                    const firstDate = this.$moment()
                        .year(year)
                        .quarter(quarter)
                        .startOf('quarter')
                        .add(Math.abs(this.monthOffset) % 3, 'months')
                        .startOf('month')
                        .add(Math.abs(this.dayOffset), 'days');
                    const endDate = this.$moment()
                        .year(year)
                        .quarter(quarter)
                        .endOf('quarter')
                        .add(Math.abs(this.monthOffset) % 3, 'months')
                        .endOf('month')
                        .add(Math.abs(this.dayOffset), 'days');

                    const obj = {
                        quarter: quarter,
                        year: year,
                    };
                    if (!this.showDatePicker) {
                        obj.quartersAgo = this.relativeSelected.value === 'CURRENT_QUARTER' ? null : this.xQuartersAgo;
                    }
                    if (this.returnDate) {
                        obj.startDate = firstDate.toDate();
                        obj.endDate = endDate.toDate();
                        return this.$emit('input', obj);
                    }

                    if (this.returnServe) {
                        obj.startDate = firstDate.format('DD/MM/YYYY');
                        obj.endDate = endDate.format('DD/MM/YYYY');
                        return this.$emit('input', JSON.stringify(obj).replace(/"/g, '\''));
                    }

                    if (this.returnMoment) {
                        obj.startDate = firstDate;
                        obj.endDate = endDate;
                        return this.$emit('input', obj);
                    }

                    if (value.search(/Q/) === 0) {
                        return this.$emit('input', value);
                    }

                    return this.$emit('input', this.quarterChangeFormatting(value, this.formatTextField, 'QQ/YYYY'));
                },
            },
            textFieldDate: {
                get: function () {
                    return this.lazyTextFieldDate;
                },
                set: function (value) {
                    if (!this.isValidQuarter(value)) {
                        this.dateModel = null;
                    } else {
                        this.dateModel = value;
                    }

                    return '';
                },
            },
            pickerDate: {
                get: function () {
                    if (!this.value) {
                        return null;
                    }
                    if (typeof this.value === 'string') {
                        try {
                            const dateObj = JSON.parse(this.value.replace(/'/g, '"'));

                            const firstMonth = this.$moment(dateObj.startDate, 'DD/MM/YYYY').format('YYYY-MM');
                            const lastMonth = this.$moment(dateObj.endDate, 'DD/MM/YYYY').format('YYYY-MM');

                            return [firstMonth, lastMonth];
                        } catch (e) {
                            if (!this.isValidQuarter(this.value)) {
                                return null;
                            }
                            const inputDate = this.quarterStringToMoment(this.value, 'QQ/YYYY');
                            const year = inputDate.year();
                            const quarter = inputDate.quarter();
                            const firstMonth = this.$moment()
                                .year(year)
                                .quarter(quarter)
                                .startOf('quarter')
                                .add(Math.abs(this.monthOffset) % 3, 'months')
                                .add(Math.abs(this.dayOffset), 'days')
                                .format('YYYY-MM');
                            const lastMonth = this.$moment()
                                .year(year)
                                .quarter(quarter)
                                .endOf('quarter')
                                .add(Math.abs(this.monthOffset) % 3, 'months')
                                .add(Math.abs(this.dayOffset), 'days')
                                .format('YYYY-MM');

                            return [firstMonth, lastMonth];
                        }
                    }
                    const firstMonth = this.value.startDate;
                    const lastMonth = this.value.endDate;
                    if (this.$moment.isMoment(firstMonth)) {
                        return [firstMonth.format('YYYY-MM'), lastMonth.format('YYYY-MM')];
                    }

                    return [
                        this.$moment(firstMonth).format('YYYY-MM'),
                        this.$moment(lastMonth).format('YYYY-MM'),
                    ];
                },
                set: function (value) {
                    if (Array.isArray(value)) {
                        value = value[0];
                    } else if (typeof value !== 'string') {
                        value = value.startDate;
                    }
                    this.dateModel = this.momentToQuarterString(this.$moment(value), 'QQ/YYYY');
                },
            },
            textFieldMask: function () {
                return this.formatTextField
                    .replace(/QQ/g, 'Q#')
                    .replace(/D/g, '#')
                    .replace(/Y/g, '#')
                    .replace(/M/g, '#');
            },
            textFieldRules: function () {
                const rules = [];

                if (!this.disableDefaultRules) {
                    rules.push(this.textFieldDateRule);
                }

                return rules.concat(this.rules);
            },
            textDayOffset: function () {
                if (this.dayOffset === 0) {
                    return 0;
                }

                return `+${Math.abs(this.dayOffset)}D`;
            },
        },
        watch: {
            dateModel: {
                immediate: true,
                handler: function (value) {
                    if (!value || !this.isValidQuarter(value)) {
                        this.lazyTextFieldDate = '';
                        return;
                    }

                    if (value !== this.lazyTextFieldDate) {
                        if (this.returnDate || this.returnMoment || this.returnServe) {
                            this.lazyTextFieldDate = value;
                        } else {
                            this.lazyTextFieldDate = this.quarterChangeFormatting(value, 'QQ/YYYY', this.formatTextField);
                        }
                    }
                },
            },
            relativeSelected: {
                immediate: false,
                handler: function (value) {
                    if (!value) {
                        this.dateModel = null;
                        return;
                    }
                    if (value.value === 'CURRENT_QUARTER') {
                        this.dateModel = this.momentToQuarterString(this.$moment());
                        this.xQuartersAgo = null;
                    } else if (value.value === 'X_QUARTERS_AGO' && this.xQuartersAgo != null) {
                        this.dateModel = this.momentToQuarterString(this.$moment().subtract(this.xQuartersAgo, 'quarters'));
                    }
                },
            },
            xQuartersAgo: {
                immediate: true,
                handler: function (value) {
                    if (this.relativeSelected != null && this.relativeSelected.value === 'X_QUARTERS_AGO') {
                        if (value != null) {
                            this.dateModel = this.momentToQuarterString(this.$moment().subtract(this.xQuartersAgo, 'quarters'));
                        } else {
                            this.dateModel = null;
                        }
                    }
                },
            },
        },
        created: function () {
            if (this.required) {
                this.rules.push(value => (!!value && value.toString().trim() !== '') || this.$t('MANDATORY'));
            }
            if (this.defaultQuarter !== null && this.defaultQuarter.search(/^(q|Q)\d\/\d{4}$/) !== -1) {
                this.dateModel = this.defaultQuarter;
            }
        },
        methods: {
            toggleDatePicker: function () {
                if (this.showDatePicker) {
                    const relative = this.quarterToRelative();
                    if (relative !== null) {
                        if (relative === 0) {
                            this.relativeSelected = JSON.parse(JSON.stringify(this.attributes[0]));
                            this.xQuartersAgo = null;
                        } else {
                            this.relativeSelected = JSON.parse(JSON.stringify(this.attributes[1]));
                            this.xQuartersAgo = relative;
                        }
                    }
                }
                this.showDatePicker = !this.showDatePicker;
                if (this.showDatePicker) {
                    this.dateModel = this.dateModel;
                }
            },
            showPicker: function (e) {
                e.preventDefault();
                this.picker = false;
                this.pickerPosition = {
                    x: e.clientX,
                    y: e.clientY,
                };
                this.$nextTick(() => {
                    this.picker = true;
                });
            },
            momentToQuarterString: function (date, format = null) {
                const newDate = date.clone().subtract(Math.abs(this.monthOffset) % 3, 'months')
                    .subtract(Math.abs(this.dayOffset), 'days')
                    .endOf('month');
                if (format) {
                    return newDate.format(format.replace('QQ', '%Q')).replace('%', 'Q');
                }

                return newDate.format(this.formatTextField.replace('QQ', '%Q')).replace('%', 'Q');
            },
            quarterStringToMoment: function (value, format = null) {
                if (format) {
                    const date = this.$moment(value.replace('Q', ''), format.replace('QQ', '%Q'));
                    return date;
                }

                const date = this.$moment(value.replace('Q', ''), this.formatTextField.replace('QQ', '%Q'));
                return date;
            },
            quarterChangeFormatting: function (quarter, oldFormat, newFormat) {
                const date = this.$moment(quarter.replace('Q', ''), oldFormat.replace('QQ', '%Q'));
                return date.format(newFormat.replace('QQ', '%Q')).replace('%', 'Q');
            },
            isValidQuarter: function (value) {
                if (!value) {
                    return false;
                }
                if (typeof value !== 'string') {
                    return false;
                }

                const date = this.quarterStringToMoment(value);
                if (!date.isValid() || value.search(this.quarterRegex) === -1) {
                    return false;
                }

                return true;
            },
            quarterToRelative: function () {
                if (!this.value) {
                    return null;
                }
                let selectedQuarter = null;
                let selectedYear = null;
                if (typeof this.value === 'string') {
                    try {
                        const dateObj = JSON.parse(this.value.replace(/'/g, '"'));

                        selectedQuarter = dateObj.quarter;
                        selectedYear = dateObj.year;
                    } catch (e) {
                        if (!this.isValidQuarter(this.value)) {
                            return null;
                        }
                        const inputDate = this.quarterStringToMoment(this.value, 'QQ/YYYY');
                        selectedQuarter = inputDate.quarter();
                        selectedYear = inputDate.year();
                    }
                } else {
                    selectedQuarter = this.value.quarter;
                    selectedYear = this.value.year;
                }
                const currentMoment = this.$moment()
                    .subtract(Math.abs(this.monthOffset) % 3, 'months')
                    .subtract(Math.abs(this.dayOffset));
                return currentMoment.quarter() - selectedQuarter + (currentMoment.year() - selectedYear) * 4;
            },
        },
    };
</script>
