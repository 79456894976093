import Vue from 'vue';

const request = {
    specialConfig: false,
    debug: {
        url: '',
        old: '',
    },
    production: {
        url: '',
        old: '',
    },
    manager: {
        url: 'https://manager.exati.com.br/guia/exati/command/exati',
    },
    reset: function () {
        this.debug.url = _.clone(this.debug.old);
        this.production.url = _.clone(this.production.old);
        Vue.prototype.$_aura.setHTTPUrl(null);
    },
    toggleSpecialConfig: function () {
        const value = !this.specialConfig;
        this.specialConfig = value;

        return value;
    },
    saveURL: function (managerUrl) {
        this.debug.old = _.clone(this.debug.url);
        this.production.old = _.clone(this.production.url);

        if (managerUrl) {
            this.setURL(_.clone(this.manager.url));
        }
    },
    setURL: function (url) {
        this.debug.url = _.clone(url);
        this.production.url = _.clone(url);
        Vue.prototype.$_aura.setHTTPUrl(this.production.url);
    },
};

export default request;
