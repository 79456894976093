export default function assetVersion (provider) {
    return new Promise((resolve) => {
        provider.forEach((item) => {
            if (item.ID_VERSAO) {
                let description = '';
                if (item.DATA_INICIO_CONSISTENCIA) {
                    description = item.DATA_INICIO_CONSISTENCIA;
                } else {
                    description = '...';
                }

                if (item.DATA_CONCLUSAO_CONSISTENCIA) {
                    description += ` a ${item.DATA_CONCLUSAO_CONSISTENCIA}`;
                } else {
                    description += ' a ...';
                }

                item.DESC_VERSAO = `${item.ID_VERSAO} - ${item.DESC_VERSAO} (${description}) ${(item.ATUAL === '1') ? ' (Atual)' : ''}`;
            }
        });
        resolve(provider);
    });
}
