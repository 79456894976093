const paths = [
    {
        path: '/pending-fulfilled-issues-tickets-list',
        title: 'PENDING_FULFILLED_ISSUES_TICKETS',
        name: 'PendingFulfilledIssuesTicketsList',
        icon: 'mdi mdi-file',
        menu: 'analysis',
        favCategory: 'analysis',
        component: () => import(/* webpackChunkName: "pendingFulfilledIssuesTickets" */ /* webpackMode: "lazy" */ '@/pages/pendingFulfilledIssuesTickets/PendingFulfilledIssuesTicketsList.vue'),
    },
];

export default paths;
