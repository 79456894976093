const paths = [
    {
        path: '/ncall-call-list',
        title: 'NCALL_CALLS',
        name: 'NcallCallList',
        icon: 'fa fa-headset',
        menu: 'analysis',
        component: () => import(/* webpackChunkName: "ncall" */ /* webpackMode: "lazy" */ '@/pages/ncall/NcallCallList.vue'),
    },
    {
        path: '/ncall-break-list',
        title: 'NCALL_BREAKS',
        name: 'NcallBreakList',
        icon: 'fa fa-coffee',
        menu: 'analysis',
        component: () => import(/* webpackChunkName: "ncall" */ /* webpackMode: "lazy" */ '@/pages/ncall/NcallBreakList.vue'),
    },
    {
        path: '/ncall-login-logout-list',
        title: 'NCALL_LOGINS_LOGOUTS',
        name: 'NcallLoginLogoutList',
        icon: 'fa fa-sign-in-alt',
        menu: 'analysis',
        component: () => import(/* webpackChunkName: "ncall" */ /* webpackMode: "lazy" */ '@/pages/ncall/NcallLoginLogoutList.vue'),
    },
    {
        path: '/ncall-satisfaction-survey-list',
        title: 'NCALL_SATISFACTION_SURVEYS',
        name: 'NcallSatisfactionSurveyList',
        icon: 'fas fa-file-alt',
        menu: 'analysis',
        component: () => import(/* webpackChunkName: "ncall" */ /* webpackMode: "lazy" */ '@/pages/ncall/NcallSatisfactionSurveyList.vue'),
    },
    {
        path: '/ncall-configuration',
        title: 'NCALL_CONFIGURATION',
        name: 'NcallConfiguration',
        icon: 'fa fa-cog',
        menu: 'analysis',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "ncall" */ /* webpackMode: "lazy" */ '@/pages/ncall/NcallConfiguration.vue'),
    },
];

export default paths;
