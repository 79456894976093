const paths = [
    {
        path: '/forecast-end-stock',
        name: 'ForecastEndStockList',
        title: 'FORECAST_END_STOCK',
        icon: 'fas fa-clipboard',
        menu: 'stock',
        component: () => import(/* webpackChunkName: "forecastEndStock" */ /* webpackMode: "lazy" */ '@/pages/stock/forecastEndStock/ForecastEndStockList.vue'),
    },
];

export default paths;
