const paths = [
    {
        path: '/project-material-reserves-applications',
        name: 'ProjectMaterialReservesApplications',
        title: 'MATERIAL_RESERVES_APPLICATIONS',
        component: () => import(/* webpackChunkName: "projectMaterialReservesApplications" */ /* webpackMode: "lazy" */ '@/pages/project/projectMaterialReservesApplications/ProjectMaterialReservesApplications'),
    },
    {
        path: '/project-material-reserves-applications',
        name: 'ProjectMaterialReservesApplicationApply',
        title: 'MATERIAL_APPLICATION',
        component: () => import(/* webpackChunkName: "projectMaterialReservesApplications" */ /* webpackMode: "lazy" */ '@/pages/project/projectMaterialReservesApplications/ProjectMaterialReservesApplicationApply'),
    },
];

export default paths;
