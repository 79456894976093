import i18n from '@/i18n';

export default function assitanceReason (provider) {
    return new Promise((resolve) => {
        resolve([
            {
                ID_MOTIVO_ATENDIMENTO_PS: -2,
                DESC_MOTIVO_ATENDIMENTO_PS: i18n.t('ALL_ACTIVE'),
            },
            ...provider,
        ]);
    });
}
