const { context } = localStorage && localStorage.preferences ? JSON.parse(localStorage.preferences) : { context: '' };
const paths = context === 'gestaosmartluz' ? [
    {
        path: '/smartluz-subdivision-list',
        name: 'SmartluzSubdivisionList',
        title: 'SUBDIVISIONS',
        icon: 'fa fa-map-marked',
        menu: 'analysis',
        component: () => import(/* webpackChunkName: "smartluzSubdivision" */ /* webpackMode: "lazy" */ '@/pages/subdivision/smartluzSubdivision/SubdivisionList.vue'),
    },
    {
        path: '/smartluz-subdivision-evaluations',
        name: 'SmartluzSubdivisionTechnicalEvaluationHistory',
        title: 'HISTORY',
        icon: 'fa fa-history',
        component: () => import(/* webpackChunkName: "smartluzSubdivision" */ /* webpackMode: "lazy" */ '@/pages/subdivision/smartluzSubdivision/SubdivisionTechnicalEvaluationHistory.vue'),
    },
    {
        path: '/smartluz-subdivision-status-history',
        name: 'SmartluzSubdivisionStatusHistory',
        title: 'HISTORY',
        icon: 'fa fa-history',
        component: () => import(/* webpackChunkName: "smartluzSubdivision" */ /* webpackMode: "lazy" */ '@/pages/subdivision/smartluzSubdivision/SubdivisionStatusHistory.vue'),
    },
] : [];

export default paths;
