const paths = [
    {
        path: '/reschedule-issue',
        title: 'RESCHEDULE_ISSUE',
        name: 'RescheduleIssue',
        component: () => import(/* webpackChunkName: "issueReschedule" */ /* webpackMode: "lazy" */ '@/pages/issue/issueReschedule/RescheduleIssue.vue'),
    },
    {
        path: '/issue-reschedule-list',
        title: 'ISSUE_RESCHEDULES',
        name: 'IssueRescheduleList',
        component: () => import(/* webpackChunkName: "issueReschedule" */ /* webpackMode: "lazy" */ '@/pages/issue/issueReschedule/IssueRescheduleList.vue'),
    },
    {
        path: '/rescheduled-issues-report',
        title: 'RESCHEDULED_ISSUES_REPORT',
        name: 'RescheduledIssuesReport',
        menu: 'maintenance',
        categories: [
            'reports',
        ],
        component: () => import(/* webpackChunkName: "issueReschedule" */ /* webpackMode: "lazy" */ '@/pages/issue/issueReschedule/RescheduledIssuesReport.vue'),
    },
    {
        path: '/rescheduled-issue-reasons-list',
        name: 'IssueRescheduleReasonList',
        title: 'ISSUE_RESCHEDULE_REASON',
        menu: 'maintenance',
        component: () => import(/* webpackChunkName: "assets" */ /* webpackMode: "lazy" */ '@/pages/issue/issueReschedule/IssueRescheduleReasonList.vue'),
    },
    {
        path: '/rescheduled-issue-reason',
        name: 'IssueRescheduleReason',
        title: 'ISSUE_RESCHEDULE_REASON',
        component: () => import(/* webpackChunkName: "assets" */ /* webpackMode: "lazy" */ '@/pages/issue/issueReschedule/IssueRescheduleReason.vue'),
    },
];

export default paths;
