<script>
    export default {
        name: 'AIconPin',
        functional: true,
        render: function (createElement, { data, parent }) {
            delete data.attrs.pin;
            data.attrs.color = data.attrs.color || 'primary';
            const children = ['mdi-pin'];
            if ((!data.scopedSlots || !data.scopedSlots['tooltip-body']) && !data.attrs['_tooltip-text']) {
                data.attrs['_tooltip-text'] = parent.$t('PIN');
            }
            return createElement('AIconDefault', data, children);
        },
    };
</script>
