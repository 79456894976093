const paths = [
    {
        path: '/database-consult',
        name: 'DatabaseConsult',
        title: 'DATABASE_CONSULT',
        menu: 'registration',
      component: () => import(/* webpackChunkName: "database" */ /* webpackMode: "lazy" */ '@/pages/database/databaseConsult.vue'),
    },
    {
      path: '/database-details',
      name: 'DatabaseDetails',
      title: 'DATABASE_DETAILS',
    component: () => import(/* webpackChunkName: "database" */ /* webpackMode: "lazy" */ '@/pages/database/databaseDetails.vue'),
  },
];

export default paths;
