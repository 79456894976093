export const ModGrouping = [
    {
        KEY: 'BY_ASSET',
        VALUE: 2,
    },
    {
        KEY: 'BY_LIGHT_SPOT',
        VALUE: 1,
    },
];

export const Calculation = [
    {
        KEY: 'DEFAULT',
        VALUE: 1,
    },
    {
        KEY: 'MAXIMUM',
        VALUE: 2,
    },
    {
        KEY: 'DIFFERENCE',
        VALUE: 3,
    },
];

export const Modernization = [
    {
        KEY: 'FIRST_MODERNIZATION',
        VALUE: 1,
    },
    {
        KEY: 'LAST_MODERNIZATION',
        VALUE: 2,
    },
    {
        KEY: 'BOTH',
        VALUE: 3,
    },
];
