const paths = [
    {
        path: '/contract-balance-list',
        name: 'ContractBalanceList',
        title: 'CONTRACTS_BALANCES',
        icon: 'fas fa-file-invoice',
        menu: 'contracts',
        component: () => import(/* webpackChunkName: "contract" */ /* webpackMode: "lazy" */ '@/pages/contract/balance/ContractBalanceList.vue'),
    },
];

export default paths;
