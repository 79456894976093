const paths = [
    {
        path: '/operation-panel',
        title: 'OPERATION_PANEL',
        name: 'OperationPanel',
        icon: 'fa fa-hard-hat',
        menu: 'maintenance',
        component: () => import(/* webpackChunkName: "operationPanel" */ /* webpackMode: "lazy" */ '@/pages/operationPanel/OperationPanel.vue'),
    },
];

export default paths;
