const urlProvider = {
    getHTTPUrl: function (url, context) {
        if (!url) {
            const end = (document.location.host + document.location.pathname).split('/');
            const clearEnd = [];
            const endLength = end.length;
            for (let i = 0; i < endLength; i++) {
                if (end[i].indexOf('.html') >= 0) {
                    break;
                }
                if (end[i].length > 0) {
                    clearEnd.push(end[i]);
                }
            }

            let output = '';
            const clearEndLength = clearEnd.length;
            for (let i = 0; i < clearEndLength; i++) {
                if (i === 0) {
                    context = end[i].split('.')[0];
                }
                output += `${end[i]}/`;
            }
            return `${document.location.protocol}//${output}command/${context}`;
        }
        return url;
    },
};

export default urlProvider;
