<template>
    <div>
        <a-row align='baseline' no-gutters>
            <a-col :cols='enableRelativePicker ? 11 : 12'>
                <transition name='flip' mode='out-in' @after-enter='afterEnter'>
                    <div v-if='showRelativePicker && enableRelativePicker' key='relative'>
                        <a-relative-date-picker
                            v-model='relativeDate'
                            v-bind='$attrs'
                            ref='relativePicker'
                            :required='required'
                            :max-date='maxDate'
                            :min-date='minDate'
                            :disable-dafault-rules='disableDefaultRules'
                            :readonly='readonly'
                            :disabled='disabled'
                        >
                        </a-relative-date-picker>
                    </div>
                    <div v-else key='date'>
                        <a-menu
                            v-model='picker'
                            :close-on-content-click='false'
                            :position-x='pickerPosition.x'
                            :position-y='pickerPosition.y'
                            offset-y
                        >
                            <div>
                                <a-date-picker
                                    v-model='pickerDate'
                                    no-title
                                    :max='max'
                                    :min='min'
                                    :readonly='readonly'
                                    :disabled='disabled'
                                    :locale='$t("LANGUAGE_INITIALS")'
                                />
                            </div>
                        </a-menu>
                        <a-text-field
                            v-model='textFieldDate'
                            v-mask='textFieldMask'
                            v-bind='$attrs'
                            ref='textField'
                            :rules='textFieldRules'
                            :readonly='readonly'
                            :disabled='disabled'
                            :clearable='$attrs.clearable && !readonly'
                            append-icon='mdi-calendar'
                            @click:append='showPicker'
                        >
                            <template v-slot:append-outer>
                                <slot name='append-outer' />
                            </template>
                        </a-text-field>
                    </div>
                </transition>
            </a-col>
            <template v-if='enableRelativePicker'>
                <a-col cols='1' class='pl-1'>
                    <a-btn
                        ref='btn'
                        small
                        icon
                        :disabled='disabled || readonly'
                        @click='toggleDatePicker'
                    >
                        <a-icon>
                            mdi-swap-vertical
                        </a-icon>
                    </a-btn>
                </a-col>
            </template>
        </a-row>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        props: {
            value: {
                type: [Date, String, Object],
                required: false,
                default: null,
            },
            returnServe: {
                type: Boolean,
                required: false,
                default: false,
            },
            returnDate: {
                type: Boolean,
                required: false,
            },
            returnMoment: {
                type: Boolean,
                required: false,
            },
            format: {
                type: String,
                required: false,
                default: 'YYYY-MM-DD',
                validator: function (value) {
                    return ['DD', 'YYYY', 'MM'].every(v => value.includes(v));
                },
            },
            formatTextField: {
                type: String,
                required: false,
                default: function () {
                    let format = this.$util.getDateFormatOfUserLocale();

                    // make 1-digit month and day formats into 2-digit
                    if (!format.includes('DD')) {
                        format = format.replace('D', 'DD');
                    }
                    if (!format.includes('MM')) {
                        format = format.replace('M', 'MM');
                    }

                    // remove special character of ar-ly and ar moment locales
                    format = format.replace(/\u200F/g, '');

                    // validate
                    if (['DD', 'YYYY', 'MM'].every(v => format.includes(v))) {
                        return format;
                    }
                    return 'DD/MM/YYYY';
                },
                validator: function (value) {
                    return ['DD', 'YYYY', 'MM'].every(v => value.includes(v));
                },
            },
            rules: {
                type: Array,
                required: false,
                default: () => [],
            },
            required: {
                type: Boolean,
                required: false,
                default: false,
            },
            maxDate: {
                type: [Date, String],
                required: false,
                default: null,
                validator: (value) => moment(value, 'YYYY-MM-DD', true).isValid(),
            },
            minDate: {
                type: [Date, String],
                required: false,
                default: null,
                validator: (value) => moment(value, 'YYYY-MM-DD', true).isValid(),
            },
            disableDefaultRules: {
                type: Boolean,
                required: false,
                default: false,
            },
            readonly: {
                type: Boolean,
                required: false,
                default: false,
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            },
            enableRelativePicker: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        data: function () {
            return {
                lazyPickerDate: null,
                lazyTextFieldDate: '',
                lazyRelativeDate: { 
                    type: 'RELATIVE',
                    value: null,
                },
                showRelativePicker: false,
                picker: false,
                pickerPosition: {
                    x: 0,
                    y: 0,
                },
                textFieldDateRule: value => {
                    if (!value) {
                        return true;
                    }
                    const date = this.$moment(value, this.formatTextField, true);
                    let isValid = date.isValid();

                    if (isValid === true && this.maxDate) {
                        const maxDate = this.$moment(this.maxDate);
                        const maxDateMessage = this.$t('MAX_DATE_ENTITY', { max: maxDate.format(this.formatTextField) });
                        isValid = date.isSameOrBefore(this.maxDate) || maxDateMessage;
                    }

                    if (isValid === true && this.minDate) {
                        const minDate = this.$moment(this.minDate);
                        const minDateMessage = this.$t('MIN_DATE_ENTITY', { min: minDate.format(this.formatTextField) });
                        isValid = date.isSameOrAfter(this.minDate) || minDateMessage;
                    }

                    return isValid || this.$t('INVALID_DATE');
                },
                textFieldRequiredRule: value => (!!value && !!value.trim()) || this.$t('MANDATORY'),
            };
        },
        computed: {
            modelFormat: function () {
                if (this.returnServe) {
                    return 'DD/MM/YYYY';
                }
                return this.format;
            },
            dateModel: {
                get: function () {
                    if (this.enableRelativePicker) {
                        // v-model expected to be an object with type date or relative
                        if (this.value != null && (this.value.type === 'DATE' || this.value.type === 'RELATIVE')) {
                            if (this.value.type === 'DATE') {
                                if (this.value.date === null) {
                                    return { type: 'DATE', date: null};
                                }

                                const date = this.$moment(this.value.date, this.modelFormat, true);

                                if (!date.isValid()) {
                                    // v-model date is invalid, update dateModel
                                    return this.dateModel = { type: 'DATE', date: null};
                                }

                                // transform date value to 'YYYY-MM-DD'
                                return { type: 'DATE', date: date.format('YYYY-MM-DD')};
                            }
                            else { // type 'RELATIVE'
                                return this.value;
                            }
                        }

                        // v-model format invalid for relative mode, update dateModel with the right format
                        let date = this.$moment(this.value, this.modelFormat, true);
                        date = date.isValid() ? date.format('YYYY-MM-DD') : null;
                        return this.dateModel = { type: 'DATE', date: date };
                    }
                    else {
                        // v-model expected to be null, a valid date string, or a moment or date object
                        if (this.value === null) {
                            return null;
                        }

                        const date = this.$moment(this.value, this.modelFormat, true);

                        if (!date.isValid()) {
                            // v-model is invalid, update dateModel
                            return this.dateModel = null;
                        }

                        return date.format('YYYY-MM-DD');
                    }
                },
                set: function (value) {
                    if (this.enableRelativePicker) {
                        if (value.type === 'DATE') {
                            const date = this.$moment(value.date, 'YYYY-MM-DD', true);
                            
                            if (!date.isValid()) {
                                value.date = null;
                            }
                            else if (this.returnDate) {
                                value.date = date.toDate();
                            }
                            else if (this.returnServe) {
                                value.date = date.format('DD/MM/YYYY');
                            }
                            else if (this.returnMoment) {
                                value.date = date;
                            }
                            else {
                                value.date = date.format(this.modelFormat);
                            }

                            return this.$emit('input', value);
                        }
                        
                        if (value.type === 'RELATIVE'){
                            if (this.returnDate) {
                                value.format = 'date';
                            }
                            else if (this.returnServe) {
                                value.format = 'DD/MM/YYYY';
                            }
                            else if (this.returnMoment) {
                                value.format = 'moment';
                            }
                            else {
                                value.format = this.modelFormat;
                            }
                            
                            return this.$emit('input', value);
                        }
                    }

                    const date = this.$moment(value, 'YYYY-MM-DD', true);

                    if (!date.isValid()) {
                        return this.$emit('input', null);
                    }

                    if (this.returnDate) {
                        return this.$emit('input', date.toDate());
                    }

                    if (this.returnServe) {
                        return this.$emit('input', date.format('DD/MM/YYYY'));
                    }

                    if (this.returnMoment) {
                        return this.$emit('input', date);
                    }

                    return this.$emit('input', date.format(this.modelFormat));
                },
            },
            textFieldDate: {
                get: function () {
                    // updated in dateModel watcher
                    return this.lazyTextFieldDate;
                },
                set: function (value) {
                    let date = this.$moment(value, this.formatTextField, true);
                    date = date.isValid() ? date.format('YYYY-MM-DD') : null;
                    
                    // prevent updating until date is valid again
                    if (date === null && this.lazyTextFieldDate === '') {
                        return;
                    }

                    if (this.enableRelativePicker) {
                        this.dateModel = { type: 'DATE', date: date};
                    }
                    else {
                        this.dateModel = date;
                    }
                },
            },
            pickerDate: {
                get: function () {
                    // updated in dateModel watcher
                    return this.lazyPickerDate;
                },
                set: function (value) {
                    if (this.enableRelativePicker) {
                        this.dateModel = { type: 'DATE', date: value};
                    }
                    else {
                        this.dateModel = value;
                    }
                },
            },
            relativeDate: {
                get: function () {
                    // updated in dateModel watcher
                    return this.lazyRelativeDate;
                },
                set: function (value) {
                    this.dateModel = value;
                },
            },
            textFieldMask: function () {
                return this.formatTextField
                    .replace(/D/g, '#')
                    .replace(/Y/g, '#')
                    .replace(/M/g, '#');
            },
            textFieldRules: function () {
                const rules = [];

                if (!this.disableDefaultRules) {
                    rules.push(this.textFieldDateRule);
                }
                if (this.required) {
                    rules.push(this.textFieldRequiredRule);
                }

                return rules.concat(this.rules);
            },
            max: function () {
                return this.maxDate;
            },
            min: function () {
                return this.minDate;
            },
        },
        watch: {
            dateModel: {
                immediate: true,
                handler: function (value) {
                    // value comes from dateModel getter already validated

                    if (this.enableRelativePicker) {
                        // value is an object with relative date data
                        if (value.type === 'RELATIVE') {
                            // if not on relative mode, switch
                            if (!this.showRelativePicker) {
                                this.showRelativePicker = !this.showRelativePicker;
                            }

                            this.lazyRelativeDate = value;
                        }
                        else if (value.type === 'DATE') {
                            // if not on date picker mode, switch
                            if (this.showRelativePicker) {
                                this.showRelativePicker = !this.showRelativePicker;
                            }

                            // value.date is either null or a date in 'YYYY-MM-DD' format
                            this.updatePickerAndTextField(value.date);
                        }
                    }
                    else {
                        // value is either null or a date in 'YYYY-MM-DD' format
                        this.updatePickerAndTextField(value);
                    }
                },
            },
        },
        methods: {
            showPicker: function (e) {
                e.preventDefault();
                this.picker = false;
                this.pickerPosition = {
                    x: e.clientX,
                    y: e.clientY,
                };
                this.$nextTick(() => {
                    this.picker = true;
                });
            },
            toggleDatePicker: function () {
                if (!this.enableRelativePicker) {
                    return;
                }
                this.showRelativePicker = !this.showRelativePicker;

                // when changing back to date picker mode, update dateModel according to selected relative option
                if (!this.showRelativePicker) {
                    let date = this.$util.relativeDateParser(this.relativeDate);
                    date = this.$moment(date, this.modelFormat, true);
                    date = date.isValid() ? date.format('YYYY-MM-DD') : null;
                    // value must be either null or a date in 'YYYY-MM-DD' format
                    this.dateModel = { type: 'DATE', date: date};
                }
                else {
                    // update dateModel to last selected relative option
                    this.dateModel = this.relativeDate;
                }
            },
            updatePickerAndTextField: function (value) {
                // value is either null or a date in 'YYYY-MM-DD' format
                    
                // updates pickerDate
                this.lazyPickerDate = value;
                
                // updates textFieldDate
                const date = this.$moment(value);
                if (!date.isValid()) {
                    this.lazyTextFieldDate = '';
                }
                else if (date.format(this.formatTextField) !== this.lazyTextFieldDate) {
                    this.lazyTextFieldDate = date.format(this.formatTextField);
                }
            },
            afterEnter: function () {
                // validation occurs after transition ends, i.e., after component is re-rendered
                if (this.$refs.textField) {
                    this.$refs.textField.validate(true);
                }
                if (this.$refs.relativePicker) {
                    this.$refs.relativePicker.validate(true);
                }
            },
        },
    };
</script>

<style>
.flip-enter-active {
  animation: flip-in 0.3s ease-out;
  backface-visibility: visible !important;
  transform-style: preserve-3d;
}

.flip-leave-active {
  animation: flip-out 0.01s ease-out;
  backface-visibility: visible !important;
  transform-style: preserve-3d;
}

.flip-enter,
.flip-leave-to {
  transform: rotateX(90deg);
}

@keyframes flip-in {
  0% {
    transform: rotateX(90deg);
  }
  60% {
    transform: rotateX(0deg);
  }
  100% {
    display: none;
  }
}

@keyframes flip-out {
  0% {
    transform: rotateX(0deg);
  }
  60% {
    transform: rotateX(-90deg);
  }
  100% {
    display: none;
  }
}
</style>
