const paths = [
    {
        path: '/pending-bills',
        name: 'PendingBills',
        title: 'PENDING_BILLS',
        menu: 'financial',
        component: () => import(/* webpackChunkName: "pendingBilss" */ /* webpackMode: "lazy" */ '@/pages/pendingBills/PendingBills.vue'),
    },
];
export default paths;
