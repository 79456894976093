import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import { showErrorsResponse, parseSuccessResponse } from '@/pages/remoteManagement/services/utils';
import i18n from '@/i18n';
import localStore from '@/pages/remoteManagement/services/store';
export class GetDevicesMeasurements extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_measurements',
            type: 'NONE',
            node: 'RESPONSE',
            fields: {
                serialNumber: new Field({
                    name: 'serial_number',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('SERIAL_NUMBER'),
                    },
                }),
                companyId: new Field({
                    name: 'companyId',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('COMPANY'),
                        items: localStore.state.companies || [],
                        itemText: 'company_name',
                        itemValue: 'company_id',
                        clearable: true,
                    },
                }),
                minDate: new Field({
                    name: 'min_date',
                    component: 'ADatePicker',
                    props: {
                        'text-field': true,
                        label: i18n.t('MIN_DATE'),
                    },
                }),
                maxDate: new Field({
                    name: 'max_date',
                    component: 'ADatePicker',
                    props: {
                        'text-field': true,
                        label: i18n.t('MAX_DATE'),
                    },
                }),
                measurementTypeId: new Field({
                    name: 'measurement_type_id',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('MEASUREMENT_TYPE'),
                        items: localStore.state.measurementTypes || [],
                        itemText: (el) => i18n.t(el.description),
                        itemValue: 'measurement_type_id',
                        clearable: true,
                        multiple: true,
                    },
                }),
                minValue: new Field({
                    name: 'min_value',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('MINIMUM_VALUE'),
                    },
                }),
                maxValue: new Field({
                    name: 'max_value',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('MAXIMUM_VALUE'),
                    },
                }),
                limit: new Field({
                    name: 'limit',
                }),
            },
            customRequestParser: function (params) {
                const filter = {
                    serial_number: params.serialNumber || null,
                    company_id: params.companyId || null,
                    min_date: params.minDate || null,
                    max_date: params.maxDate || null,
                    measurement_type_id: params.measurementTypeId || null,
                    min_value: params.minValue || null,
                    max_value: params.maxValue || null,
                    limit: params.limit || null,
                };
                return {
                    BODY: `searchFilter=${encodeURIComponent(JSON.stringify(filter))}`,
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
