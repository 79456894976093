const paths = [
  {
      path: '/service-composition-list',
      title: 'SERVICES_COMPOSITIONS_LIST',
      name: 'ServiceCompositionList',
      icon: 'fa fa-hard-hat',
      menu: 'maintenance',
      categories: [
        'configurations',
      ],
      component: () => import(/* webpackChunkName: "serviceComposition" */ /* webpackMode: "lazy" */ '@/pages/serviceComposition/ServiceCompositionList.vue'),
  },
  {
    path: '/service-composition',
    title: 'SERVICES_COMPOSITION',
    name: 'ServiceComposition',
    favCategory: 'registration',
    component: () => import(/* webpackChunkName: "serviceComposition" */ /* webpackMode: "lazy" */ '@/pages/serviceComposition/ServiceComposition.vue'),
},
  {
    path: '/service-composition-wizard',
    title: 'SERVICE_COMPOSITION',
    name: 'ServiceCompositionWizard',
    component: () => import(/* webpackChunkName: "serviceComposition" */ /* webpackMode: "lazy" */ '@/pages/serviceComposition/ServiceCompositionWizard.vue'),
  },
  {
    path: '/service-composition-cost-spreadsheet-wizard',
    title: 'SERVICE_COMPOSITION',
    name: 'ServiceCompositionCostSpreadsheetWizard',
    component: () => import(/* webpackChunkName: "serviceComposition" */ /* webpackMode: "lazy" */ '@/pages/serviceComposition/ServiceCompositionCostSpreadsheetWizard.vue'),
  },
];

export default paths;
