<template>
    <div>
        <a-menu
            v-model='picker'
            :close-on-content-click='false'
            :position-x='pickerPosition.x'
            :position-y='pickerPosition.y'
            offset-y
        >
            <a-time-picker
                ref='timePicker'
                v-model='timeModel'
                :format='format'
                :min='minTime'
                :max='maxTime'
                :use-seconds='useSeconds'
                :readonly='readonly'
                :disabled='disabled'
            />
        </a-menu>
        <a-text-field
            v-mask='textFieldMask'
            v-model='timeTextField'
            v-bind='$attrs'
            :rules='textFieldRules'
            append-icon='far fa-clock'
            :readonly='readonly'
            :disabled='disabled'
            @click:append='showPicker'
        />
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'ATimePickerCustom',
        props: {
            value: {
                type: String,
                required: false,
                default: null,
            },
            useSeconds: {
                type: Boolean,
                required: false,
                default: false,
            },
            format: {
                type: String,
                required: false,
                default: function () {
                    let timeFormat = this.$util.getTimeFormatOfUserLocale();

                    // remove escape sequences which are enclosed by '[]' like '[Auer]'
                    timeFormat = timeFormat.replace(/\[[^\]]*\]/g, '');

                    if (['a', 'A'].some(v => timeFormat.includes(v))) {
                        return 'ampm';
                    }
                    else {
                        return '24hr';
                    }
                },
            },
            rules: {
                type: Array,
                required: false,
                default: () => [],
            },
            minTime: {
                type: String,
                required: false,
                default: null,
                validator: value => {
                    const isValidTime = moment(value, 'HH:mm', true).isValid();
                    const isValidTimeWithSecons = moment(value, 'HH:mm:ss', true).isValid();
                    return isValidTime || isValidTimeWithSecons;
                },
            },
            maxTime: {
                type: String,
                required: false,
                default: null,
                validator: value => {
                    const isValidTime = moment(value, 'HH:mm', true).isValid();
                    const isValidTimeWithSecons = moment(value, 'HH:mm:ss', true).isValid();
                    return isValidTime || isValidTimeWithSecons;
                },
            },
            disableDefaultRules: {
                type: Boolean,
                required: false,
                default: false,
            },
            readonly: {
                type: Boolean,
                required: false,
                default: false,
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        data: function () {
            return {
                picker: false,
                pickerPosition: {
                    x: 0,
                    y: 0
                },
                lazyTimeTextField: "",
                textFieldTimeRule: value => {
                    if (!value) {
                        return true;
                    }
                    return this.isTimeValid(value) || this.$t('INVALID_TIME');
                },
                textFieldMaxTimeRule: (value) => {
                    if (!value || !this.maxTime) {
                        return true;
                    }
                    const max = this.getTimeMilisecs(this.maxTime);

                    const time = this.getTimeMilisecs(value);

                    return time <= max || this.$t('MAX_TIME', { max: this.maxTime });
                },
                textFieldMinTimeRule: (value) => {
                    if (!value || !this.minTime) {
                        return true;
                    }

                    const min = this.getTimeMilisecs(this.minTime);
                    const time = this.getTimeMilisecs(value);

                    return time >= min || this.$t('MIN_TIME', { min: this.minTime });
                },

            };
        },
        computed: {
            timeModel: {
                get: function () {
                    return this.value;
                },
                set: function (value) {
                    this.$emit('input', value);
                },
            },
            timeTextField: {
                get: function () {
                    return this.lazyTimeTextField;
                },
                set: function (value) {
                    if (!this.isTimeValid(value)) {
                        return this.timeModel = null;
                    }

                    return this.timeModel = value;
                },
            },
            textFieldRules: function () {
                const rules = [...this.rules];
                if (!this.disableDefaultRules) {
                    rules.push(
                        this.textFieldTimeRule,
                        this.textFieldMaxTimeRule,
                        this.textFieldMinTimeRule,
                    );
                }
                return rules;
            },
            textFieldMask: function () {
                return this.useSeconds ? '##:##:##' : '##:##';
            },
        },
        watch: {
            timeModel:{
                immediate: true,
                handler:  function (value) {
                    if (value !== this.lazyTimeTextField) {
                        this.lazyTimeTextField = value;
                    }
                },
            }
        },
        methods: {
            showPicker: function (e) {
                e.preventDefault();
                this.picker = false;
                this.pickerPosition = {
                    x: e.clientX,
                    y: e.clientY
                };
                this.$nextTick(() => {
                    this.picker = true;
                    if (this.$refs.timePicker) {
                        this.$refs.timePicker.selecting = 1;
                    }
                })
            },
            isTimeValid: function (value) {
                if (this.useSeconds) {
                    const isValidTimeWithSecons = moment(value, 'HH:mm:ss', true).isValid();

                    return isValidTimeWithSecons;
                }

                const isValidTime = moment(value, 'HH:mm', true).isValid();
                
                return isValidTime;
            },
            getTimeMilisecs(time) {
                const [hour, minutes, seconds] = time.split(':');

                let milisecs = 0;

                if (hour) {
                    milisecs += hour * (60 * 60000);
                }

                if (minutes) {
                    milisecs += minutes * 60000;
                }

                if (seconds) {
                    milisecs += seconds * 1000;
                }

                return milisecs;
            },
        },
    };
</script>
