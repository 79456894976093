import i18n from '@/i18n';

export default function listings (provider) {
    return new Promise((resolve) => {
        const resultProvider = [];
        resultProvider.push({ ID_LISTAGEM: -1, DESC_LISTAGEM: i18n.t('ALL') });

        provider.forEach((item) => {
            resultProvider.push({ ID_LISTAGEM: parseInt(item.ID_LISTAGEM, 10), DESC_LISTAGEM: item.DESC_LISTAGEM });
        });

        resolve(resultProvider);
    });
}
