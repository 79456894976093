<template>
    <tr>
        <template v-for='(operation, idx) in operations'>
            <td :key='idx' class='footer'>
                <component
                    :is='operation.element'
                    v-if='operation.element'
                    :key='idx'
                    class='my-2'
                    v-bind='operation.props'
                    v-on='operation.events' />
            </td>
        </template>
    </tr>
</template>

<script>
    export default {
        name: 'ADatatableColumnOperation',
        props: {
            /**
             * Array of Headers datatable
             * @type {Array}
             * @default {undefined}
             */
            _headers: {
                type: Array,
                default: undefined,
            },
            /**
             * Array of Operations
             * @type {Array}
             * @default {undefined}
             */
            _operations: {
                type: Array,
                default: undefined,
            },
        },
        computed: {
            operations: function () {
                return this._headers.map(header => {
                    let element = {};
                    this._operations.forEach(operation => {
                        if (Object.keys(operation).includes('column') && operation.column === header.value) {
                            element = operation;
                        }
                    });
                    return element;
                });
            },
        },
    };
</script>
<style scoped>
    .footer {
        border-left: 0;
    }
</style>
