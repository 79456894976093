const inputs = {
    options: {},
    initializeOptions: function (options) {
        this.options = options.editDialogs || {};
    },
    getComponentAutocomplete: function (name) {
        let component = {};
        if (this.options.autocomplete) {
            component = this.options.autocomplete[name];
        }
        return component;
    },
    getComponentSelect: function (name) {
        let component = {};
        if (this.options.select) {
            component = this.options.select[name];
        }
        return component;
    },
    getComponentCombobox: function (name) {
        let component = {};
        if (this.options.combobox) {
            component = this.options.combobox[name];
        }
        return component;
    },
};

export default inputs;
