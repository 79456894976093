<template>
    <div>
        <a-tooltip v-bind='attrsTooltip' :disabled='!_tooltip'>
            <template v-slot:activator='{ on }'>
                <a-icon v-bind='attrs' :class='_classIcon' v-on='{ ...listener, ...on }'>
                    <slot />
                </a-icon>
            </template>
            <span v-if='_tooltipText'>{{_tooltipText}}</span>
            <span v-else-if='!hasTooltipBody'>{{$t('DEFAULT')}}</span>
            <slot v-else name='tooltip-body' />
        </a-tooltip>
    </div>
</template>
<script>
    import { getBooleanFromProp } from '../../../util/helpers';

    export default {
        inheritAttrs: false,
        name: 'AIconDefault',
        props: {
            _tooltip: {
                type: Boolean,
                default: true,
            },
            _tooltipText: {
                type: String,
                default: '',
            },
            _classIcon: {
                type: [String, Object],
                default: '',
            },
        },
        computed: {
            attrs: function () {
                const attrs = { ...this.$attrs };
                delete attrs.default;
                attrs.small = Object.keys(this.$attrs).includes('small') ? getBooleanFromProp(this.$attrs.small) : (!getBooleanFromProp(this.$attrs['x-small']) && !getBooleanFromProp(this.$attrs.large) && !getBooleanFromProp(this.$attrs['x-large']));
                return attrs;
            },
            attrsTooltip: function () {
                const attrs = {};
                Object
                    .entries(this.$attrs).filter(value => value[0].length > '-tooltip'.length && value[0].slice(-1 * '-tooltip'.length) === '-tooltip')
                    .forEach(value => {
                        const name = value[0].slice(0, -1 * '-tooltip'.length);
                        attrs[name] = value[1];
                    });
                attrs[this._iconType] = true;
                attrs.bottom = getBooleanFromProp(attrs.bottom) || (!getBooleanFromProp(attrs.top) && !getBooleanFromProp(attrs.left) && !getBooleanFromProp(attrs.right));
                return attrs;
            },
            listener: function () {
                return this.$listeners || {};
            },
            hasTooltipBody: function () {
                return !!this.$slots['tooltip-body'] || !!this.$scopedSlots['tooltip-body'];
            },
        },
    };
</script>
