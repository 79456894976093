export default [
    {
        path: '/activity-allocation-list',
        title: 'ACTIVITY_ALLOCATION',
        name: 'ActivityAllocationList',
        icon: 'fa fa-hard-hat',
        menu: 'construction',
        component: () => import(/* webpackChunkName: "activityRouteType" */ /* webpackMode: "lazy" */ '@/pages/activityAllocation/ActivityAllocationList.vue'),
    },
];
