<template>
    <a-navigation-drawer
      v-model="model"
      v-bind="$attrs"
      v-on="$listeners"
      right
      :temporary='_temporary'
      :absolute='!isMobileOrTablet'
      :fixed='isMobileOrTablet'
      hide-overlay
    >

    <a-toolbar v-if="isMobileOrTablet" color='save' class="white--text">
        <a-btn icon>
            <a-icon color='white' @click="model = false">
                close
            </a-icon>
        </a-btn>
        <a-toolbar-title>{{$t('CATALOG')}}</a-toolbar-title>
    </a-toolbar>
      
      <template v-if="!_hideTabs">
        
        <a-tabs v-model="tabModel">
            <slot name='tabs-headers' />            
        </a-tabs>
        <a-divider></a-divider>
        
        <a-tabs-items v-model='tabModel' style='height:calc(100% - 49px)'>
          <slot name='tabs-items' />
        </a-tabs-items>

      </template>

      <slot />
    </a-navigation-drawer>
</template>

<script>
export default {
  props: {
    /**
     * V model value
     * @type {Boolean}
     * @default {false}
     */
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Tab index model
     * @type {Number}
     * @default {false}
     */
    _tab: {
      type: [Number, String],
      required: false,
      default: null
    },
    /**
     * set mode o be without tabs
     * @type {Boolean}
     * @default {false}
     */
    _hideTabs: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Temporary prop from a-navigation-drawer
     * @type {Boolean}
     * @default {true}
     */
    _temporary: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data(){
    return {
      tab: null
    }
  },
  computed: {
    model: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.$emit('input', value)
      }
    },
    tabModel: {
      get: function () {
        if (this._tab !== null) {
          return this._tab;
        }
        return this.tab;
      },
      set: function (value) {
        this.tab = value;
        this.$emit('update:_tab', value);
      }
    },
    isMobileOrTablet: function () {
        const breakpoints = [
            'xs',
            'sm',
        ];

        return breakpoints.includes(this.$vuetify.breakpoint.name);
    },
  },
  watch: {
    model: function (value) {
        if (this.isMobileOrTablet) {
            return;
        }
        if (value) {
            this.$store.commit('SET_DISABLE_PAGE_WRAPPER_SCROLL', true)
        } else {
            this.$store.commit('SET_DISABLE_PAGE_WRAPPER_SCROLL', false)

        }
    }
  }
}
</script>

<style>

</style>