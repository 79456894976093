const paths = [
    {
        path: '/applied-materials',
        name: 'AppliedMaterials',
        title: 'APPLIED_MATERIALS',
        icon: 'widgets',
        menu: 'maintenance',
        help: {
            type: 'loom',
            url: 'https://www.loom.com/embed/4006f4af8d294cec89f706e58f4ec8f8',
        },
        categories: [
            'reports',
        ],
        component: () => import(/* webpackChunkName: "appliedMaterials" */ /* webpackMode: "lazy" */ '@/pages/appliedMaterials/AppliedMaterials'),
    },
    {
        path: '/applied-materials-general',
        name: 'AppliedMaterialsItemInfo',
        title: 'ITEM_ADDITIONAL_INFORMATION',
        favCategory: 'maintenance',
        component: () => import(/* webpackChunkName: "appliedMaterials" */ /* webpackMode: "lazy" */ '@/pages/appliedMaterials/AppliedMaterialsItemInfo'),
    },
    {
        path: '/applied-materials-scrap',
        name: 'AppliedMaterialsScrap',
        title: 'APPLIED_MATERIALS_SCRAP',
        icon: 'mdi-file-document',
        menu: 'maintenance',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "appliedMaterials" */ /* webpackMode: "lazy" */ '@/pages/appliedMaterials/AppliedMaterialsScrap.vue'),
    },
    {
        path: '/applied-materials-detail-list',
        name: 'AppliedMaterialsDetailList',
        title: 'APPLIED_MATERIALS',
        component: () => import(/* webpackChunkName: "appliedMaterials" */ /* webpackMode: "lazy" */ '@/pages/appliedMaterials/AppliedMaterialsDetailList.vue'),
    },
];

export default paths;
