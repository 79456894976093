const paths = [
    {
        path: '/city-registration-report',
        name: 'CityRegistrationReport',
        title: 'CITY_REGISTRATION_REPORT',
        icon: 'mdi-file-document',
        menu: 'asset',
        categories: [
          'reports',
        ],
      component: () => import(/* webpackChunkName: "cityRegistrationReport" */ /* webpackMode: "lazy" */ '@/pages/cityRegistrationReport/CityRegistrationReport.vue'),
    },
];

export default paths;
