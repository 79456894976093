<template>
    <v-text-field
        ref='field'
        v-model.lazy='displayValue'
        v-bind='attrs'
        :type='"text"'
        :prefix='prefixSymbol'
        @focus='useNumberValue'
        @blur='useFormattedValue'
        @keyup='$emit("keyup", $event)'
        @keypress='onKeypress'
        @keydown='onKeydown'
    >
        <template v-for="(index, name) in $scopedSlots" v-slot:[name]>
            <slot :name="name" />
        </template>
    </v-text-field>
</template>

<script>
    export default {
        name: 'AMonetaryField',
        model: { prop: '_value' },
        components: {
            AMonetaryField: {
                inheritAttrs: false,
            },
        },
        props: {
            _value: {
                type: Number,
                default: undefined,
            },
            precision: {
                type: Number,
                default: 2,
            },
            hideGroupingSymbol: {
                type: Boolean,
                default: false,
            },
            hideCurrencySymbol: {
                type: Boolean,
                default: false,
            },
        },
        data: function () {
            return {
                numberValue: null,
                isFocused: false,
                preserveFormat: false,
                fixPrecision: false,
            };
        },
        computed: {
            formattedValue: {
                set: function (val) {
                    this.numberValue = val;
                },
                get: function () {
                    if (!this.hasValue(this._value) || this.isNaN(this._value)) {
                        return '';
                    }
                    return this.$util.asMonetary(this._value, this.precision, this.hideGroupingSymbol);
                },
            },
            displayValue: {
                get: function () {
                    if (!this.isFocused) {
                        return this.formattedValue;
                    }

                    if (!this.hasValue(this.numberValue) || this.isNaN(this.numberValue)) {
                        return '';
                    }

                    let val = this.numberValue.toString();
                    if (val.includes(",") && val.split(',')[1].length > 0) {
                        // If format was preserved, it's possible the comma is still there
                        val = val.replace(",", ".");
                    }

                    if (this.fixPrecision && val.includes(".") && val.split('.')[1].length > 0) {
                        val = Number(val).toFixed(this.precision);
                        this.fixPrecision = false;
                    }

                    return val;
                },
                set: function (val) {
                    if (this.hasValue(val) && this.containsInvalidKey(val)) {
                        // Some edge cases may cause the value to still have invalid characters. Remove them
                        val = val.replace(/[^0-9.,-]+/g, '');
                    }
                    this.formattedValue = val;
                },
            },
            prefixSymbol: function () {
                return !this.isFocused && !this.hideCurrencySymbol ? this.$store.getters.getCurrencySymbol : '';
            },
            attrs: function() {
                // Apply the rules to the numeric value. Pass it as string, as the rules assume a string value
                const attrs = { ...this.$attrs };
                if (attrs.rules && Array.isArray(attrs.rules) && attrs.rules.length > 0) {
                    attrs.rules = attrs.rules.map(rule => {
                        return (...args) => rule(this.numberValue?.toString(), ...args);
                    });
                }
                return attrs;
            },
        },
        watch: {
            _value: {
                immediate: true,
                handler: function (val) {
                    if (!this.preserveFormat) {
                        this.numberValue = val;
                    }
                },
            },
            numberValue: {
                immediate: true,
                handler: function (val, oldVal) {
                    
                    let numeric = null;
                    if (this.hasValue(val)) {
                        numeric = this.$util.formatToNumber(val);
                        if (!this.hasValue(numeric) || this.isNaN(numeric)) {
                            numeric = null;
                        }
                    }

                    this.$emit('input', numeric);
                    
                    if (!this.preserveFormat) {
                        val = numeric;
                        return;
                    }
                    
                    val = oldVal;
                },
            },
        },
        mounted: function () {
            this.numberValue = this._value;
        },
        methods: {
            useNumberValue: function () {
                this.isFocused = true;
                this.fixPrecision = false;
                if (this.hasValue(this.numberValue)) {
                    // When entering focus, fix precision for display
                    this.displayValue = Number(this.$util.formatToNumber(this.numberValue)).toFixed(this.precision);
                }
                this.$emit('focus');
            },
            useFormattedValue: function () {
                this.isFocused = false;
                this.fixPrecision = false;
                this.$emit('blur');
                this.$emit('change', this.numberValue);
            },
            onKeydown: function (event) {
                const key = event.key;
                const pos = event.target.selectionStart;
                const currValue = event.target.value.toString();
                
                this.preserveFormat = key === 'Backspace' && currValue.length > 0 && currValue[pos] !== '.' && currValue[pos] !== ','
                    && /^[\d]*[.,]0*$/.test(currValue.substring(0, pos - 1) + currValue.substring(pos));

                if (key !== 'Dead' && key !== 'Unidentified') {
                    this.$emit('keydown', event);
                    return;
                }

                event.preventDefault();
                event.stopPropagation();
                event.target.blur();
                this.$nextTick(() => {
                    event.target.focus();
                    event.target.setSelectionRange(pos, pos);
                });
            },
            onKeypress: function (event) {
                const key = event.key;
                const pos = event.target.selectionStart;
                const currValue = event.target.value.toString();

                const hasDot = currValue.includes('.');
                const hasComma = currValue.includes(',');
                const dotIndex = currValue.indexOf('.');
                const commaIndex = currValue.indexOf(',');
                const isNegative = currValue[0] === '-';
                const isInt = pos <= Math.max(dotIndex, commaIndex);
                const isSeparator = key === '.' || key === ',';

                if (this.containsInvalidKey(key)
                    || (isSeparator && (hasDot || hasComma || this.precision === 0))
                    || (key === '-' && (isNegative || pos > 0))
                    || (key === '0' && (currValue[0] === '0' || (isNegative && currValue[1] === '0')) && (isInt || (!hasDot && !hasComma)))
                    || (!isInt && ((hasDot && currValue.split('.')[1].length >= this.precision) 
                                  || (hasComma && currValue.split(',')[1].length >= this.precision)))) {
                    event.preventDefault();
                    event.stopPropagation();
                } else if (isSeparator && pos > 0 && pos < currValue.length) {
                    this.preserveFormat = true;
                    this.fixPrecision = true;
                }

                this.$emit('keypress', event);
            },
            hasValue: function (val) {
                // Only using truthy could be a problem if the value is 0
                return val !== null && val !== undefined && val !== '';
            },
            isNaN: function (val) {
                return typeof val === 'number' && isNaN(val);
            },
            containsInvalidKey: function (key) {
                return /[^0-9.,-]+/.test(key);
            },
        },
    };
</script>