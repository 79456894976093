import Vue from 'vue';
import moment from 'moment';
import i18n from '@/i18n';
import { MARKERS_MODE } from './consts';
export function parseSuccessResponse(request) {
    if (!Vue.prototype.$util.isJsonString(request.RAIZ.RESPONSE)) {
        throw new Error('TG: error in server');
    }
    const tgRequest = JSON.parse(request.RAIZ.RESPONSE);
    if (tgRequest.errors?.length) {
        tgRequest.errors.forEach((err) => {
            if (err !== null) {
                Vue.prototype.$_aura.showErrorMessage(err);
            }
        });
    }
    if (tgRequest.informations?.length) {
        tgRequest.informations.forEach((info) => {
            Vue.prototype.$_aura.showSuccessMessage(info);
        });
    }
    const response = {
        ...request,
        response: tgRequest.response,
    };
    response.RAIZ.RESPONSE = tgRequest.response;
    return response;
}
export function showErrorsResponse(reject) {
    if (!reject) {
        return reject;
    }
    const data = Vue.prototype.$_aura.getArray(() => reject.RAIZ.MESSAGES.ERRORS);
    if (!Vue.prototype.$util.isJsonString(data[0])) {
        throw new Error('TG: error in server');
    }
    const errors = JSON.parse(data[0]);
    if (Array.isArray(errors)) {
        if (errors && errors.length > 0) {
            errors.forEach((message) => {
                if (!message) {
                    return;
                }
                if (typeof message === 'string') {
                    Vue.prototype.$_aura.showErrorMessage(i18n.t(message));
                }
                else if (message) {
                    Vue.prototype.$_aura.showErrorMessage(JSON.stringify(message));
                }
            });
        }
    }
    else {
        Vue.prototype.$_aura.showErrorMessage(i18n.t(errors));
    }
    return reject;
}
export function asyncLoopChunks(items, callback, callbackFinishChunk, options = {}) {
    return new Promise((resolve) => {
        let counter = 0;
        const chunksNumber = options.chunksNumber || 1000;
        const renderChunkPerSecond = options.renderChunkPerSecond || 100;
        const iterator = () => {
            for (let i = 0; i < chunksNumber; i++) {
                if (items[counter] === undefined) {
                    resolve();
                    return;
                }
                callback(items[counter], counter);
                counter++;
            }
            if (callbackFinishChunk) {
                callbackFinishChunk(counter, items.length);
            }
            if (counter < items.length) {
                setTimeout(iterator, renderChunkPerSecond);
            }
            else {
                resolve();
            }
        };
        iterator();
    });
}
export function getDimmerLabel(dimmer) {
    if (dimmer === 0) {
        return i18n.t('OFF');
    }
    if (dimmer === 100) {
        return i18n.t('ON');
    }
    if (dimmer > 100) {
        return i18n.t('FAIL');
    }
    return `${String(dimmer)}%`;
}
export function deviceIsConnected(device) {
    if (device.last_contact) {
        const minimumHours = 24;
        const lastContact = moment(device.last_contact);
        const timeDifference = moment().diff(lastContact, 'hours');
        if (timeDifference < minimumHours) {
            return true;
        }
    }
    return false;
}
export function displayDisconnectedIcon(device, markersMode) {
    if (!deviceIsConnected(device) && markersMode !== MARKERS_MODE.CONNECTION) {
        return true;
    }
    return false;
}
export function getMarkerDefaultColor(device, markersMode) {
    if (displayDisconnectedIcon(device, markersMode)) {
        return 0x1978FF;
    }
    return undefined;
}
export function getColorForMarkersMode(device, markerHasIcon, markersMode) {
    if (markersMode === MARKERS_MODE.ALERT && markerHasIcon) {
        return undefined;
    }
    let color;
    if (device.device_type_id === 2) {
        return 0x9309FD;
    }
    if (markersMode === MARKERS_MODE.ALERT) {
        color = device.alert_count ? 0xff3333 : getMarkerDefaultColor(device, markersMode);
    }
    if (markersMode === MARKERS_MODE.CONNECTION) {
        const currentDate = moment();
        const dateToTest = moment(device.last_contact);
        const result = currentDate.diff(dateToTest, 'hours');
        if (!result) {
            color = 0x212121;
        }
        if (result >= 0) {
            color = 0x4CAF50;
        }
        if (result >= 6) {
            color = 0x3F51B5;
        }
        if (result >= 12) {
            color = 0x303F9F;
        }
        if (result >= 24) {
            color = 0x1A237E;
        }
        if (result >= 48) {
            color = 0x212121;
        }
    }
    if (markersMode === MARKERS_MODE.LAMP_STATE) {
        if (!device.lamp_state || device.lamp_state < 1) {
            color = 0x212121;
        }
        if (device.lamp_state >= 1) {
            color = 0x827717;
        }
        if (device.lamp_state >= 30) {
            color = 0xAFB42B;
        }
        if (device.lamp_state >= 50) {
            color = 0xFDD835;
        }
        if (device.lamp_state >= 80) {
            color = 0xFFEA00;
        }
    }
    return color;
}
export function getMarkerIcon(device, markerHasIcon, markersMode) {
    if (markersMode === MARKERS_MODE.ALERT && markerHasIcon) {
        return device.hardware_alert_type_id ?? device.alert_type_id;
    }
    if (displayDisconnectedIcon(device, markersMode)) {
        return 'disconnected-icon';
    }
    return undefined;
}
export function getMarkerShadow(device, markerHasIcon, markersMode) {
    if (markersMode === MARKERS_MODE.ALERT && markerHasIcon) {
        return undefined;
    }
    if (displayDisconnectedIcon(device, markersMode)) {
        return 'disconnected-icon-shadow';
    }
    return undefined;
}
export function getShadowAnchor(device, markersMode) {
    if (displayDisconnectedIcon(device, markersMode)) {
        return { x: 0.5, y: 0.51 };
    }
    return { x: 0, y: 0 };
}
