const paths = [
    {
        path: '/default-permission-list',
        title: 'GROUPS_AND_PERMISSIONS',
        name: 'DefaultPermissionList',
        component: () => import(/* webpackChunkName: "defaultPermission" */ /* webpackMode: "lazy" */ '@/pages/defaultPermission/DefaultPermissionList.vue'),
    },
    {
        path: '/default-permission-general',
        title: 'PERMISSIONS_GROUP',
        name: 'DefaultPermissionGeneral',
        component: () => import(/* webpackChunkName: "defaultPermission" */ /* webpackMode: "lazy" */ '@/pages/defaultPermission/DefaultPermissionGeneral.vue'),
    },
];

export default paths;
