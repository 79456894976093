const paths = [
    // {
    //     path: '*',
    //     meta: { public: true },
    //     redirect: {
    //         path: '/404',
    //     },
    // },
    // {
    //     path: '/404',
    //     meta: { public: true },
    //     name: 'NotFound',
    //     component: () => import( /* webpackChunkName: "not_found" */ /* webpackMode: "lazy" */   '@/pages/NotFound.vue'),
    // },
    // {
    //     path: '/403',
    //     meta: {
    //         public: true,
    //     },
    //     name: 'AccessDenied',
    //     component: () => import( /* webpackChunkName: "access_denied" */ /* webpackMode: "lazy" */  '@/pages/Deny.vue',
    //     ),
    // },
    // {
    //     path: '/500',
    //     meta: {
    //         public: true,
    //     },
    //     name: 'ServerError',
    //     component: () => import( /* webpackChunkName: "500" */ /* webpackMode: "lazy" */   '@/pages/Error.vue',
    //     ),
    // },
    {
        path: '/login',
        meta: { public: true },
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ /* webpackMode: "lazy" */ '@/components/core/access/Login.vue'),
    },
    {
        path: '/',
        name: 'Root',
        redirect: {
            name: 'Home',
        },
    },
    {
        path: '/',
        name: 'Home',
        title: 'HOME',
        icon: 'home',
        component: () => import(/* webpackChunkName: "home" */ /* webpackMode: "lazy" */ '@/pages/home/Home.vue'),
    },
];

let requireComponent = require.context('@/pages', true, /paths.js$/);

requireComponent.keys().forEach((fileName) => {
    const componentConfig = requireComponent(fileName);

    const innerPaths = componentConfig.default;
    paths.push(...innerPaths);
});


requireComponent = require.context('@/components', true, /paths.js$/);

requireComponent.keys().forEach((fileName) => {
    const componentConfig = requireComponent(fileName);

    const innerPaths = componentConfig.default;
    paths.push(...innerPaths);
});

export default paths;
