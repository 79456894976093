const paths = [
    {
        path: '/asset-materials-dynamic-search',
        name: 'AssetMaterialsDynamicSearch',
        title: 'ASSET_MATERIALS_DYNAMIC_SEARCH',
        icon: 'mdi-file-document',
        menu: 'asset',
        component: () => import(/* webpackChunkName: "assetMaterialsDynamicSearch" */ /* webpackMode: "lazy" */ '@/pages/report/assetMaterialsDynamicSearch/AssetMaterialsDynamicSearch.vue'),
    },
    {
        path: '/asset-report-wizard',
        title: 'PHOTOGRAPHIC_ASSETS_REPORT',
        name: 'AssetReportWizard',
        component: () => import(/* webpackChunkName: "assetMaterialsDynamicSearch" */ /* webpackMode: "lazy" */ '@/pages/report/assetMaterialsDynamicSearch/AssetReportWizard.vue'),
    },
];

export default paths;
