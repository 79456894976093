import Vue from 'vue';
import Router from 'vue-router';
import paths from '@/api/paths';

Vue.use(Router);

 const router = new Router({
    mode: 'hash',
    base: '/#/',
    linkActiveClass: 'active',
    routes: paths,
});

export default router;
