import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import { showErrorsResponse, parseSuccessResponse } from '@/pages/remoteManagement/services/utils';
export class PrintTag extends CallCommand {
    constructor() {
        super({
            name: 'post_print-tag_0',
            type: 'NONE',
            fields: {
                serialNumber: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${encodeURIComponent(value)} }`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        return response;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class SaveDevice extends CallCommand {
    constructor() {
        super({
            name: 'post_devices',
            type: 'NONE',
            fields: {
                device: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        return response;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class GenerateSerialsToPrint extends CallCommand {
    constructor() {
        super({
            name: 'post_generate-test-serials_0',
            type: 'NONE',
            fields: {
                numberOfSerials: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        return response;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class PackDevice extends CallCommand {
    constructor() {
        super({
            name: 'post_device-packaging_0',
            type: 'NONE',
            fields: {
                serialNumber: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${encodeURIComponent(value)} }`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        return response;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class ResetPackagedSerials extends CallCommand {
    constructor() {
        super({
            name: 'post_clear-packaged-serials',
            type: 'NONE',
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        return response;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetClosingMetrics extends CallCommand {
    constructor() {
        super({
            name: 'get_closing-metrics',
            type: 'NONE',
            node: 'RESPONSE',
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        return response;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetPackagingMetrics extends CallCommand {
    constructor() {
        super({
            name: 'get_packaging-metrics',
            type: 'NONE',
            node: 'RESPONSE',
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        return response;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
