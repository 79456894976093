const paths = [
    {
        path: '/financial-schedule-details',
        title: 'FINANCIAL_SCHEDULE_DETAIS',
        name: 'FinancialScheduleDetails',
        component: () => import(/* webpackChunkName: "financialSchedule" */ /* webpackMode: "lazy" */ '@/pages/financialSchedule/FinancialScheduleDetails.vue'),
    },
    {
        path: '/financial-schedule-list',
        title: 'FINANCIAL_SCHEDULES',
        name: 'FinancialScheduleList',
        icon: '',
        menu: 'financial',
        component: () => import(/* webpackChunkName: "financialSchedule" */ /* webpackMode: "lazy" */ '@/pages/financialSchedule/FinancialScheduleList.vue'),
    },
    {
        path: '/financial-schedule-register-payment-wizard',
        title: 'TRANSACTION_DATA',
        name: 'FinancialScheduleRegisterPaymentWizard',
        component: () => import(/* webpackChunkName: "financialSchedule" */ /* webpackMode: "lazy" */ '@/pages/financialSchedule/FinancialScheduleRegisterPaymentWizard.vue'),
    },
    {
        path: '/financial-schedule-generate-payment-wizard',
        title: 'TRANSACTION_DATA',
        name: 'FinancialScheduleGeneratePaymentWizard',
        component: () => import(/* webpackChunkName: "financialSchedule" */ /* webpackMode: "lazy" */ '@/pages/financialSchedule/FinancialScheduleGeneratePaymentWizard.vue'),
    },
];

export default paths;
