function getDescription (item) {
    let label = item.ID_ESTRUTURA;

    const unformattedStructureNum = item.NUM_ESTRUTURA;

    let structureNum = null;
    if (unformattedStructureNum) {
        structureNum = (unformattedStructureNum < 100 ? '0' : '') + (unformattedStructureNum < 10 ? '0' : '') + unformattedStructureNum;
    }

    label = `( ${item.TIPO_ESTRUTURA} )  ${(structureNum || '')}`;

    if (item.DESC_ESTRUTURA) {
        label += (structureNum ? ' - ' : '') + item.DESC_ESTRUTURA;
    }

    if (item.TIPO_ESTRUTURA === 'T' && item.NUM_ESTRUTURA_INICIAL && item.NUM_ESTRUTURA_FINAL) {
        const initialStructureNum = (item.NUM_ESTRUTURA_INICIAL < 100 ? '0' : '') + (item.NUM_ESTRUTURA_INICIAL < 10 ? '0' : '') + item.NUM_ESTRUTURA_INICIAL;
        const finalStructureNum = (item.NUM_ESTRUTURA_FINAL < 100 ? '0' : '') + (item.NUM_ESTRUTURA_FINAL < 10 ? '0' : '') + item.NUM_ESTRUTURA_FINAL;

        label += ` (${initialStructureNum} - ${finalStructureNum})`;
    }

    if (item.ID_ESTRUTURA == null || item.ID_ESTRUTURA.length === 0) {
        return '';
    }
    return label;
}

export default async function detailedIssueRegion (provider) {
    provider.forEach(item => {
        item.description = getDescription(item);
    });

    return provider;
}
