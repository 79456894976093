const paths = [
    {
        path: '/iot-attributes-link',
        name: 'IoTAttributesLink',
        title: 'IOT_ATTRIBUTES_LINK',
        menu: 'iot',
        icon: 'mdi-link',
        component: () => import(/* webpackChunkName: "iotAttributesLink" */ /* webpackMode: "lazy" */ '@/pages/iotAttributesLink/IoTAttributesLink.vue'),
    },
];

export default paths;
