import axios from 'axios';

export default async function layersGis (provider) {
    let resultProvider = [];
    let enderecoGeo = `${provider[0].GEOSERVER_URL}/wms`;
    enderecoGeo += '?service=wms&version=1.1.1&request=GetCapabilities';

    const request = {
        method: 'GET',
        url: enderecoGeo,
        headers: {
            'Content-Type': 'application/json',
        },
    };
    try {
        const response = await axios(request);
        let index = 0;
        const data = [];
        while (response.data.indexOf('sgi:', index) > 0) {
            index = response.data.indexOf('sgi:', index);
            const layer = response.data.substr(index, response.data.indexOf('</', index) - index);
            data.push({
                VALUE: layer,
                KEY: layer,
            });
            index += layer.length;
        }
        resultProvider = data;
        return resultProvider;
    } catch (reject) {
       throw resultProvider;
    }
}
