import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import { parseSuccessResponse } from '../utils';
export class SaveAlertTypeNotifiers extends CallCommand {
    constructor() {
        super({
            name: 'post_comp_alert-type-notify_replace-all_0_1',
            type: 'NONE',
            fields: {
                alertTypeId: new Field({
                    name: 'ALERT_TYPE_ID',
                }),
                isHardwareType: new Field({
                    name: 'IS_HARDWARE_TYPE',
                }),
                alertTypeNotifiers: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
            },
            customRequestParser: ({ alertTypeId, isHardwareType, alertTypeNotifiers }) => {
                const pathParams = `{ PARAM_0: ${alertTypeId.toString()}, PARAM_1: ${isHardwareType.toString()} }`;
                return {
                    BODY: JSON.stringify(alertTypeNotifiers),
                    PATH_PARAMS: pathParams,
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
