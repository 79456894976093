const paths = [
    {
        path: '/pdf-viewer',
        title: 'DOCUMENT',
        name: 'PdfViewer',
        component: () => import(/* webpackChunkName: "document" */ /* webpackMode: "lazy" */ '@/pages/document/pdfViewer/PdfViewer.vue'),
    },
];

export default paths;
