const paths = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        title: 'DASHBOARD',
        icon: 'fas fa-chart-pie',
        menu: 'customization',
        component: () => import(/* webpackChunkName: "dashboard" */ /* webpackMode: "lazy" */ '@/pages/dashboard/Dashboard.vue'),
    },
    {
        path: '/dashboard-details',
        name: 'DashboardDetails',
        title: 'DASHBOARD',
        favCategory: 'report',
        component: () => import(/* webpackChunkName: "dashboard" */ /* webpackMode: "lazy" */ '@/pages/dashboard/DashboardDetails.vue'),
    },
    {
        path: '/dashboard-report-form',
        name: 'DashboardReportForm',
        title: 'REPORTS',
        component: () => import(/* webpackChunkName: "dashboard" */ /* webpackMode: "lazy" */ '@/pages/dashboard/DashboardReportForm.vue'),
    },
    {
        path: '/dashboard-schedule-report',
        name: 'DashboardScheduleReport',
        title: 'SCHEDULE_EMAIL_SENDING',
        component: () => import(/* webpackChunkName: "dashboard" */ /* webpackMode: "lazy" */ '@/pages/dashboard/DashboardScheduleReport.vue'),
    },
    {
        path: '/dashboard-permissions',
        name: 'DashboardPermissions',
        title: 'SHARED_WITH',
        component: () => import(/* webpackChunkName: "dashboard" */ /* webpackMode: "lazy" */ '@/pages/dashboard/DashboardPermissions.vue'),
    },
    {
        path: '/dashboard-entity-table',
        name: 'DashboardEntityTable',
        component: () => import(/* webpackChunkName: "dashboard" */ /* webpackMode: "lazy" */ '@/pages/dashboard/DashboardEntityTable.vue'),
    },
];

export default paths;
