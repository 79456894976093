const paths = [
    {
        path: '/export-popup',
        name: 'ExportPopup',
        title: 'EXPORT_TO',
        component: () => import(/* webpackChunkName: "exportPopup" */ /* webpackMode: "lazy" */ '@/components/exportPopup/ExportPopup.vue'),
    },
];

export default paths;
