const paths = [
  {
      path: '/allocated-movements',
      title: 'ALLOCATED_MOVEMENTS',
      name: 'AllocatedMovements',
      icon: 'mdi-file-document',
      menu: 'financial',
      categories: [
        'reports',
      ],
      component: () => import(/* webpackChunkName: "allocatedMovements" */ /* webpackMode: "lazy" */ '@/pages/allocatedMovements/AllocatedMovements.vue'),
  },
];

export default paths;
