export default async function projectContract (provider) {
    provider.forEach(item => {
        if (item.ID_CONTRATO) {
            if (item.NUM_CONTRATO) {
                item.description = `${item.ID_CONTRATO} - ${item.NUM_CONTRATO} - ${item.DESC_ORCAMENTO} - ${item.DESC_STATUS_CONTRATO}`;
            } else {
                item.description = `${item.ID_CONTRATO} - ${item.DESC_ORCAMENTO} - ${item.DESC_STATUS_CONTRATO}`;
            }
        } else {
            item.description = `${item.ID_ORCAMENTO} - ${item.DESC_ORCAMENTO} - ${item.DESC_STATUS_ORCAMENTO}`;
        }
    });
    return provider;
}
