const paths = [
    {
        path: '/instance-config',
        name: 'InstanceConfig',
        title: 'INSTANCE_CONFIG',
        icon: 'fa fa-cog',
        menu: 'registration',
        component: () => import(/* webpackChunkName: "config" */ /* webpackMode: "lazy" */ '@/pages/config/InstanceConfig.vue'),
    },
    {
        path: '/instance-config-general-notice-general',
        name: 'InstanceConfigGeneralNoticeGeneral',
        component: () => import(/* webpackChunkName: "config" */ /* webpackMode: "lazy" */ '@/pages/config/InstanceConfigGeneralNoticeGeneral.vue'),
    },
    {
        path: '/instace-config-domain',
        name: 'InstanceConfigDomain',
        component: () => import(/* webpackChunkName: "config" */ /* webpackMode: "lazy" */ '@/pages/config/InstanceConfigDomain.vue'),
    },
    {
        path: '/belling-change-history',
        name: 'BellingChangeHistory',
        component: () => import(/* webpackChunkName: "config" */ /* webpackMode: "lazy" */ '@/pages/config/BellingChangeHistory.vue'),
    },
    {
        path: '/instance-config-worksite-issue-origin-type',
        name: 'InstanceConfigWorksiteIssueOriginType',
        component: () => import(/* webpackChunkName: "config" */ /* webpackMode: "lazy" */ '@/pages/config/InstanceConfigWorksiteIssueOriginType.vue'),
    },
];

export default paths;
