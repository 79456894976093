import pivottable from 'pivottable';
import plotlyRenderers from 'pivottable/dist/plotly_renderers';
import exportRenderers from 'pivottable/dist/export_renderers';
import tableexport from 'tableexport';
import gchartRenderers from 'pivottable/dist/gchart_renderers';
import pt from 'pivottable/dist/pivot.pt';

export default {
    install: function (Vue) {
        // Object.defineProperty(Vue.prototype, '$pivot', { value: require('pivottable') });
        // Object.defineProperty(Vue.prototype, '$plotly', { value: require('pivottable/dist/plotly_renderers') });
        // Object.defineProperty(Vue.prototype, '$exportRenderers', { value: require('pivottable/dist/export_renderers') });
        // Object.defineProperty(Vue.prototype, '$tableexport', { value: require('tableexport') });
        // Object.defineProperty(Vue.prototype, '$pt', { value: require('pivottable/dist/pivot.pt') });
        Object.defineProperty(Vue.prototype, '$pivot', { value: pivottable });
        Object.defineProperty(Vue.prototype, '$plotly', { value: plotlyRenderers });
        Object.defineProperty(Vue.prototype, '$gchartRenderers', { value: gchartRenderers });
        Object.defineProperty(Vue.prototype, '$exportRenderers', { value: exportRenderers });
        Object.defineProperty(Vue.prototype, '$tableexport', { value: tableexport });
        Object.defineProperty(Vue.prototype, '$pt', { value: pt });
    },
};
