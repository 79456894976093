const paths = [
    {
        path: '/material-identification-data',
        name: 'MaterialIdentificationData',
        title: 'MATERIAL_IDENTIFICATION',
        component: () => import(/* webpackChunkName: "material" */ /* webpackMode: "lazy" */ '@/pages/material/MaterialIdentificationData.vue'),
    },
    {
        path: '/material-identification',
        name: 'MaterialIdentification',
        title: 'MATERIAL_IDENTIFICATION',
        component: () => import(/* webpackChunkName: "material" */ /* webpackMode: "lazy" */ '@/pages/material/MaterialIdentification.vue'),
    },
    {
        path: '/material-identification-attribute',
        name: 'MaterialIdentificationAttribute',
        title: 'MATERIAL_IDENTIFICATION',
        component: () => import(/* webpackChunkName: "material" */ /* webpackMode: "lazy" */ '@/pages/material/MaterialIdentificationAttribute.vue'),
    },
    {
        path: '/material-identification-movements',
        name: 'MaterialIdentificationMovements',
        title: 'MATERIAL_IDENTIFICATION',
        component: () => import(/* webpackChunkName: "material" */ /* webpackMode: "lazy" */ '@/pages/material/MaterialIdentificationMovements.vue'),
    },
    {
        path: '/confirm-exclusion-material-identification',
        name: 'ConfirmExclusionMaterialIdentification',
        title: 'MATERIAL_IDENTIFICATION',
        component: () => import(/* webpackChunkName: "material" */ /* webpackMode: "lazy" */ '@/pages/material/ConfirmExclusionMaterialIdentification.vue'),
    },
];

export default paths;
