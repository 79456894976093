const paths = [
    {
        path: '/purchased-material-list',
        title: 'MATERIALS_PURCHASED_REPORT',
        name: 'PurchasedMaterialList',
        menu: 'shopping',
        categories: [
          'reports',
        ],
        component: () => import(/* webpackChunkName: "purchasedMaterial" */ /* webpackMode: "lazy" */ '@/pages/purchasedMaterial/PurchasedMaterialList.vue'),
    },
];

export default paths;
