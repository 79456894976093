import enUS from './locale/en/all.json';
import ptBr from './locale/pt/all.json';
import es from './locale/es/all.json';

export default {
  // eslint-disable-next-line camelcase
  en_US: enUS,
  // eslint-disable-next-line camelcase
  pt_BR: ptBr,
  es: es,
};
