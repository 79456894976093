import i18n from '@/i18n';
import Vue from 'vue';
import moment from 'moment';
import 'moment/locale/pt-br';
import momentDurationFormatSetup from 'moment-duration-format';

const locale = {
  state: { locale: 'pt_BR' },
  getters: {
    locale: (state) => state.locale,
  },
  mutations: {
    SET_LOCALE: function (state, value) {
      state.locale = value;
    },
  },
  actions: {
    // eslint-disable-next-line no-shadow
    changeLocaleAction: function ({ commit }, { locale }) {
      const lang = locale || 'pt_BR';

      i18n.locale = lang;

      let momentLocale = i18n.locale.replace('_', '-');
      if (momentLocale === 'en-US') {
        momentLocale = 'en';
      }
      moment.locale(momentLocale);
      momentDurationFormatSetup(moment);
      localStorage.setItem('locale', lang);

      Vue.prototype.$moment.locale(momentLocale);

      commit('SET_LOCALE', lang);
    },
  },
};

export default locale;
