const paths = [
    {
        path: '/bank-list',
        title: 'BANKS',
        name: 'BankList',
        icon: 'fa fa-briefcase',
        menu: 'financial',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "financy" */ /* webpackMode: "lazy" */ '@/pages/financy/BankList.vue'),
    },
    {
        path: '/bank',
        title: 'BANK',
        name: 'Bank',
        favCategory: 'financial',
        component: () => import(/* webpackChunkName: "financy" */ /* webpackMode: "lazy" */ '@/pages/financy/Bank.vue'),
    },
    {
        path: '/bank-account-list',
        title: 'BANK_ACCOUNTS',
        name: 'BankAccountList',
        icon: 'fa fa-briefcase',
        menu: 'financial',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "financy" */ /* webpackMode: "lazy" */ '@/pages/financy/BankAccountList.vue'),
    },
    {
        path: '/bank-account-general',
        title: 'BANK_ACCOUNT',
        name: 'BankAccountGeneral',
        favCategory: 'financial',
        component: () => import(/* webpackChunkName: "financy" */ /* webpackMode: "lazy" */ '@/pages/financy/BankAccountGeneral.vue'),
    }, {
        path: '/financial-account-list',
        title: 'FINANCIAL_ACCOUNTS',
        name: 'FinancialAccountList',
        icon: 'fa fa-briefcase',
        menu: 'financial',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "financy" */ /* webpackMode: "lazy" */ '@/pages/financy/FinancialAccountList.vue'),
    }, {
        path: '/financial-account-general',
        title: 'FINANCIAL_ACCOUNT',
        name: 'FinancialAccountGeneral',
        favCategory: 'financial',
        component: () => import(/* webpackChunkName: "financy" */ /* webpackMode: "lazy" */ '@/pages/financy/FinancialAccountGeneral.vue'),
    }, {
        path: '/financial-center-list',
        title: 'FINANCIAL_CENTERS',
        name: 'FinancialCenterList',
        icon: 'fa fa-briefcase',
        menu: 'financial',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "financy" */ /* webpackMode: "lazy" */ '@/pages/financy/FinancialCenterList.vue'),
    }, {
        path: '/financial-center-detail',
        title: 'FINANCIAL_CENTER',
        name: 'FinancialCenterDetail',
        component: () => import(/* webpackChunkName: "financy" */ /* webpackMode: "lazy" */ '@/pages/financy/FinancialCenterDetail.vue'),
    },
    {
        path: '/financial-category-list',
        title: 'FINANCIAL_CATEGORIES',
        name: 'FinancialCategoryList',
        icon: 'fa fa-briefcase',
        menu: 'financial',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "financy" */ /* webpackMode: "lazy" */ '@/pages/financy/FinancialCategoryList.vue'),
    },
    {
        path: '/financial-category',
        title: 'FINANCIAL_CATEGORY',
        name: 'FinancialCategory',
        favCategory: 'financial',
        component: () => import(/* webpackChunkName: "financy" */ /* webpackMode: "lazy" */ '@/pages/financy/FinancialCategory.vue'),
    },
    {
        path: '/cash-flow',
        title: 'CASH_FLOW',
        name: 'CashFlow',
        icon: 'fas fa-cash-register',
        menu: 'financial',
        component: () => import(/* webpackChunkName: "financy" */ /* webpackMode: "lazy" */ '@/pages/financy/cashFlow/CashFlow.vue'),
    },
];

export default paths;
