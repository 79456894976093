const paths = [
    {
        path: '/asset-importer-list',
        name: 'AssetImporterList',
        title: 'ASSET_IMPORT_NEW',
        icon: 'mdi-file-document',
        menu: 'asset',
        component: () => import(/* webpackChunkName: "assetImporter" */ /* webpackMode: "lazy" */ '@/pages/assetImporter/AssetImporterList.vue'),
    },
    {
        path: '/asset-importer-details',
        name: 'AssetImporterDetails',
        title: 'ASSET_IMPORT_NEW',
        component: () => import(/* webpackChunkName: "assetImporter" */ /* webpackMode: "lazy" */ '@/pages/assetImporter/AssetImporterDetails.vue'),
    },
    {
        path: '/asset-importer-asset-details',
        name: 'AssetImporterAssetDetails',
        title: 'ASSET_DETAILS',
        component: () => import(/* webpackChunkName: "assetImporter" */ /* webpackMode: "lazy" */ '@/pages/assetImporter/AssetImporterAssetDetails.vue'),
    },
];

export default paths;
