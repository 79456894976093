import Vue from 'vue';
import i18n from '@/i18n';
import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
const { $autocomplete, $util, } = Vue.prototype;
export class GetRevenuesReport extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarFaturamentoNFServico',
            type: 'SEARCH',
            node: 'FATURAMENTOS.FATURAMENTO',
            fields: {
                startDate: new Field({
                    name: 'DATA_INICIO',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('INITIAL_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                endDate: new Field({
                    name: 'DATA_FIM',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('ENDING_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                clientId: new Field({
                    name: 'ID_CLIENTE',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('CLIENT'),
                        entity: $autocomplete.clients,
                        clearable: false,
                    },
                }),
                contractId: new Field({
                    name: 'ID_CONTRATO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('CONTRACT'),
                        entity: $autocomplete.contract,
                        'entity-params': { ATIVO: 1 },
                    },
                    dependsOn: [
                        {
                            fieldName: ['ID_CLIENTE'],
                            handler: (fields) => {
                                fields.contractId.props.disabled = !fields.clientId.value;
                                fields.contractId.props['entity-params'] = {
                                    ATIVO: 1,
                                    ID_CLIENTE: fields.clientId.value,
                                };
                            },
                        },
                    ],
                }),
                reportType: new Field({
                    name: 'TIPO_RELATORIO',
                    value: 0,
                }),
                grouper: new Field({
                    name: 'TIPO_GRAFICO',
                }),
                byClients: new Field({
                    name: 'POR_CLIENTE',
                }),
                relevantClients: new Field({
                    name: 'CLIENTES_RELEVANTES',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
