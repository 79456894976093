const paths = [
    {
        path: '/resolved-issues-report',
        name: 'ResolvedIssuesReport',
        title: 'RESOLVED_ISSUES_BY_TEAM_AND_ORIGIN_TYPE',
        icon: 'fa fa-file-excel',
        menu: 'maintenance',
        categories: [
            'reports',
        ],
        component: () => import(/* webpackChunkName: "resolvedIssuesReport" */ /* webpackMode: "lazy" */ '@/pages/resolvedIssuesReport/ResolvedIssuesReport.vue'),
    },
];

export default paths;
