import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
export class CheckMfaNeed extends CallCommand {
    constructor() {
        super({
            name: 'VerificarNecessidadeMFA',
            type: 'SEARCH',
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetAndroidApkVersion extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarVersaoAplicativoAndroid',
            type: 'SEARCH',
            node: 'CONTEXTOS.CONTEXTO',
            fields: {},
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveUserFavoriteInterfaceMenu extends CallCommand {
    constructor() {
        super({
            name: 'SalvarMenuInterfaceFavoritoUsuario',
            type: 'SAVE',
            fields: {
                menuCode: new Field({
                    name: 'COD_MENU',
                }),
                interfaceCode: new Field({
                    name: 'INTERFACE',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class DeleteUserFavoriteInterfaceMenu extends CallCommand {
    constructor() {
        super({
            name: 'ExcluirMenuInterfaceFavoritoUsuario',
            type: 'DELETE',
            fields: {
                menuCode: new Field({
                    name: 'COD_MENU',
                }),
                interfaceCode: new Field({
                    name: 'INTERFACE',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class Logout extends CallCommand {
    constructor() {
        super({
            name: 'Logout',
            type: 'NONE',
            fields: {},
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class ConfirmFlowUpUser extends CallCommand {
    constructor() {
        super({
            name: 'ConfirmarUsuarioFlowUP',
            type: 'SAVE',
            fields: {
                confirmCode: new Field({
                    name: 'CODIGO_CONFIRMACAO',
                }),
                password: new Field({
                    name: 'SENHA',
                }),
                confirmPassword: new Field({
                    name: 'SENHA_CONFIRMACAO',
                }),
                teamSupervisor: new Field({
                    name: 'SUPERVISOR_EQUIPES',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetUserFavoriteInterfaceMenu extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarMenuInterfaceFavoritoUsuario',
            type: 'NONE',
            fields: {
                interfaceCode: new Field({
                    name: 'INTERFACE',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetTranslationKeys extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarChavesTraducao',
            type: 'NONE',
            fields: {
                interfaceCode: new Field({
                    name: 'INTERFACE',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetUserLogged extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarUsuarioLogado',
            type: 'NONE',
            fields: {
                interfaceCode: new Field({
                    name: 'INTERFACE',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class Login extends CallCommand {
    constructor() {
        super({
            name: 'Login',
            type: 'NONE',
            fields: {
                username: new Field({
                    name: 'USERNAME',
                }),
                password: new Field({
                    name: 'PASSWORD',
                }),
                version: new Field({
                    name: 'VERSION',
                }),
                tokenMFA: new Field({
                    name: 'MFA_TOKEN',
                }),
                rememberMe: new Field({
                    name: 'REMEMBER_ME',
                }),
                platform: new Field({
                    name: 'PLATAFORM',
                }),
                tokenCaptcha: new Field({
                    name: 'TOKEN_CAPTCHA',
                }),
            },
        });
    }
    async callCommand(param, config, debug, authorization) {
        return super.callCommand(param, config, debug, authorization);
    }
}
export class GetValidClient extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarClienteValido',
            type: 'NONE',
            fields: {
                clientCode: new Field({
                    name: 'COD_CLIENTE',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class AskForPasswordRecoveryCode extends CallCommand {
    constructor() {
        super({
            name: 'AskForPasswordRecoveryCode',
            type: 'NONE',
            fields: {
                username: new Field({
                    name: 'USERNAME',
                }),
                email: new Field({
                    name: 'EMAIL',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveUserLocale extends CallCommand {
    constructor() {
        super({
            name: 'SalvarLocaleUsuario',
            type: 'SAVE',
            fields: {
                locale: new Field({
                    name: 'LOCALE',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetLoginScreenInfo extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarInformacoesTelaLogin',
            type: 'SEARCH',
            fields: {
                personId: new Field({
                    name: 'ID_PESSOA',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetUserHasLateralMenu extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarUsuarioPossuiMenuLateral',
            type: 'SEARCH',
            fields: {
                userId: new Field({
                    name: 'ID_USUARIO',
                }),
            },
        });
    }
    async callCommand(config, debug) {
        return super.callCommand(config, debug);
    }
}
export class RecoverPassword extends CallCommand {
    constructor() {
        super({
            name: 'RecoverPassword',
            type: 'NONE',
            fields: {
                recoveryCode: new Field({
                    name: 'RECOVERY_CODE',
                }),
                newPassword: new Field({
                    name: 'NEW_PASSWORD',
                }),
                newPasswordConfirmation: new Field({
                    name: 'NEW_PASSWORD_CONFIRMATION',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SendChatMessage extends CallCommand {
    constructor() {
        super({
            name: 'EnviarMensagemChat',
            type: 'NONE',
            fields: {
                message: new Field({
                    name: 'MENSAGEM',
                }),
                userId: new Field({
                    name: 'ID_USUARIO_DESTINO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class VerifyMFATokenCreation extends CallCommand {
    constructor() {
        super({
            name: 'VerificarCriacaoTokenMFA',
            type: 'SEARCH',
        });
    }
    async callCommand(config, debug) {
        return super.callCommand(config, debug);
    }
}
export class GenerateQRCodeForMFA extends CallCommand {
    constructor() {
        super({
            name: 'GerarQRCodeParaMFA',
            type: 'FILE',
            config: { type: 'file' },
            fields: {
                userEmail: new Field({
                    name: 'EMAIL_USUARIO',
                }),
            },
        });
    }
    async callCommand(config, debug) {
        return super.callCommand(config, debug);
    }
}
export class VerifyMFAToken extends CallCommand {
    constructor() {
        super({
            name: 'VerificarTokenMFA',
            type: 'SEARCH',
            fields: {
                token: new Field({
                    name: 'TOKEN',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class ActivateMFAToken extends CallCommand {
    constructor() {
        super({
            name: 'AtivarTokenMFA',
            type: 'SAVE',
            fields: {
                token: new Field({
                    name: 'TOKEN',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetMFAActivation extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarAtivacaoMFA',
            type: 'SAVE',
        });
    }
    async callCommand(config, debug) {
        return super.callCommand(config, debug);
    }
}
export class SendMFADisableRequest extends CallCommand {
    constructor() {
        super({
            name: 'EnviarPedidoRevogacaoMFA',
            type: 'SAVE',
            fields: {
                authorizerId: new Field({
                    name: 'ID_USUARIO_PEDIDO',
                }),
                directDisablePermission: new Field({
                    name: 'REVOG_PERM',
                }),
            },
        });
    }
    async callCommand(params, config, debug) {
        return super.callCommand(params, config, debug);
    }
}
export class GetMFADisableRequests extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarPedidosRevogacao',
            type: 'SEARCH',
            fields: {
                userId: new Field({
                    name: 'ID_USUARIO',
                }),
                startDate: new Field({
                    name: 'PERIODO_INICIAL',
                }),
                endDate: new Field({
                    name: 'PERIODO_FINAL',
                }),
                isDisabled: new Field({
                    name: 'REVOGADO',
                }),
            },
        });
    }
    async callCommand(params, config, debug) {
        return super.callCommand(params, config, debug);
    }
}
export class DisableMFA extends CallCommand {
    constructor() {
        super({
            name: 'RevogarMFA',
            type: 'SAVE',
            fields: {
                userId: new Field({
                    name: 'ID_USUARIO',
                }),
                disableRequestId: new Field({
                    name: 'REVOGADO',
                }),
                directDisablePermission: new Field({
                    name: 'REVOG_PERM',
                }),
            },
        });
    }
    async callCommand(params, config, debug) {
        return super.callCommand(params, config, debug);
    }
}
export class GetDisableMFAPermission extends CallCommand {
    constructor() {
        super({
            name: 'VerificaPermissaoRevogacao',
            type: 'SEARCH',
        });
    }
    async callCommand(config, debug) {
        return super.callCommand(config, debug);
    }
}
