const paths = [
    {
        path: '/referral-destiny-list',
        name: 'ReferralDestinyList',
        title: 'REFERRAL_DESTINY',
        menu: 'maintenance',
        component: () => import(/* webpackChunkName: "referralDestiny" */ /* webpackMode: "lazy" */ '@/pages/referralDestiny/ReferralDestinyList.vue'),
    },
    {
        path: '/referral-destiny-general',
        name: 'ReferralDestinyGeneral',
        title: 'REFERRAL_GENERAL',
        component: () => import(/* webpackChunkName: "referralDestiny" */ /* webpackMode: "lazy" */ '@/pages/referralDestiny/ReferralDestinyGeneral.vue'),
    },
];

export default paths;
