const paths = [
    {
        path: '/videnci-model-event',
        title: 'EVENT_MODEL',
        name: 'VidenciModelEvent',
        menu: 'registration',
        component: () => import(/* webpackChunkName: "videnci" */ /* webpackMode: "lazy" */ '@/pages/videnci/VidenciModelEvent.vue'),
    },
];

export default paths;
