const paths = [
    {
        path: '/sld',
        name: 'SLD',
        title: 'SLD',
        icon: 'fa fa-briefcase',
        menu: 'registration',
        component: () => import(/* webpackChunkName: "sld" */ /* webpackMode: "lazy" */ '@/pages/sld/SldMap.vue'),
    },
    {
      path: '/street-view-dialog',
      name: 'StreetView',
      title: 'StreetView',
      component: () => import(/* webpackChunkName: "sld" */ /* webpackMode: "lazy" */ '@/pages/sld/StreetView.vue'),
  },
];

export default paths;
