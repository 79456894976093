const paths = [
    {
        path: '/gadget-list',
        name: 'GadgetList',
        title: 'GADGETS',
        icon: 'widgets',
        menu: 'registration',
        component: () => import(/* webpackChunkName: "gadget" */ /* webpackMode: "lazy" */ '@/pages/gadget/GadgetList.vue'),
    },
    {
        path: '/gadget',
        name: 'Gadget',
        title: 'GADGET',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "gadget" */ /* webpackMode: "lazy" */ '@/pages/gadget/Gadget.vue'),
    },
];

export default paths;
