export default [
    {
        path: '/reopen-confirmation-panel-list',
        title: 'REOPEN_AUTHORIZARION_PANEL',
        name: 'ReopenAuthorizationPanel',
        icon: 'fa fa-hard-hat',
        menu: 'ticket',
        component: () => import(/* webpackChunkName: "ReopenAuthorizationPanel" */ /* webpackMode: "lazy" */ '@/pages/issue/reopenAuthorizationPanel/ReopenAuthorizationPanel.vue'),
    },
    {
        path: '/new-issue-change-order',
        name: 'NewIssueChangeOrder',
        title: 'DEFINITION',
        component: () => import(/* webpackChunkName: "ReopenAuthorizationPanel" */ /* webpackMode: "lazy" */ '@/pages/issue/reopenAuthorizationPanel/NewIssueChangeOrder.vue'),
    },
    {
        path: '/issue-reopen-history',
        name: 'IssueReopenHistory',
        title: 'HISTORY',
        component: () => import(/* webpackChunkName: "ReopenAuthorizationPanel" */ /* webpackMode: "lazy" */ '@/pages/issue/reopenAuthorizationPanel/IssueReopenHistory.vue'),
    },
];
