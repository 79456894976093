import i18n from '@/i18n';
import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import localStore from '@/pages/remoteManagement/services/store';
import { parseSuccessResponse, showErrorsResponse } from '../utils';
export class GetAllDeviceAllocation extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_device-allocations_search_0',
            type: 'SEARCH',
            node: 'RESPONSE',
            fields: {
                deviceId: new Field({
                    name: 'device_id',
                }),
                initialDate: new Field({
                    name: 'created_at',
                    component: 'ADateTimePicker',
                    props: {
                        'text-field': true,
                        label: i18n.t('INITIAL_DATE'),
                        'use-seconds': true,
                    },
                }),
                finalDate: new Field({
                    name: 'deleted_at',
                    component: 'ADateTimePicker',
                    props: {
                        'text-field': true,
                        label: i18n.t('ENDING_DATE'),
                        'use-seconds': true,
                    },
                }),
                reference: new Field({
                    name: 'reference',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('REFERENCE'),
                    },
                }),
                deviceStateId: new Field({
                    name: 'deviceStateId',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('DEVICE_STATE'),
                        items: localStore.state.deviceStates || [],
                        itemText: (obj) => i18n.t(obj.description),
                        itemValue: 'device_state_id',
                        clearable: true,
                    },
                }),
            },
            customRequestParser: function (params) {
                const filter = {
                    created_at: params.initialDate || null,
                    deleted_at: params.finalDate || null,
                    reference: params.reference || null,
                    device_state_id: params.deviceStateId || null,
                };
                const pathParams = {
                    PARAM_0: params.deviceId,
                };
                return {
                    PATH_PARAMS: JSON.stringify(pathParams),
                    BODY: `searchFilter=${encodeURIComponent(JSON.stringify(filter))}`,
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetDeviceAllocation extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_device-allocations_0',
            type: 'NONE',
            fields: {
                deviceId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${value} }`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveDeviceAllocation extends CallCommand {
    constructor() {
        super({
            name: 'post_comp_device-allocations_0',
            type: 'SAVE',
            fields: {
                deviceAllocation: new Field({
                    name: 'BODY',
                    parser: (value) => {
                        Object.keys(value).forEach((key) => {
                            value[key] = value[key] ?? '';
                        });
                        return JSON.stringify(value);
                    },
                }),
                deviceId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${value} }`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveMultipleDeviceAllocations extends CallCommand {
    constructor() {
        super({
            name: 'post_comp_device-allocations_multiple',
            type: 'SAVE',
            fields: {
                deviceAllocation: new Field({
                    name: 'BODY',
                    parser: (value) => {
                        Object.keys(value).forEach((key) => {
                            value[key] = value[key] ?? '';
                        });
                        return JSON.stringify(value);
                    },
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
