const paths = [
    {
        path: '/fuel-type-list',
        name: 'FuelTypeList',
        title: 'FUEL_TYPES',
        icon: 'widgets',
        menu: 'fleet',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "fuelType" */ /* webpackMode: "lazy" */ '@/pages/fuelType/FuelTypeList.vue'),
    },
    {
        path: '/fuel-type',
        name: 'FuelType',
        title: 'FUEL_TYPE',
        icon: 'widgets',
        component: () => import(/* webpackChunkName: "fuelType" */ /* webpackMode: "lazy" */ '@/pages/fuelType/FuelType.vue'),
    },
    {
        path: '/fuel-type-details-item-selection',
        name: 'FuelTypeDetailsItemSelection',
        title: 'SELECT_ITEM',
        icon: 'widgets',
        component: () => import(/* webpackChunkName: "fuelType" */ /* webpackMode: "lazy" */ '@/pages/fuelType/FuelTypeDetailsItemSelection.vue'),
    },
    {
        path: '/fuel-type-equivalence-details',
        name: 'FuelTypeEquivalenceDetails',
        title: 'FUEL_EQUIVALENCE',
        icon: 'widgets',
        component: () => import(/* webpackChunkName: "fuelType" */ /* webpackMode: "lazy" */ '@/pages/fuelType/FuelTypeEquivalenceDetails.vue'),
    },
];

export default paths;
