const paths = [
    {
        path: '/assets-by-region',
        name: 'AssetsByRegion',
        title: 'ASSETS_BY_REGION',
        menu: 'asset',
        categories: [
          'reports',
        ],
        component: () => import(/* webpackChunkName: "assetsByRegion" */ /* webpackMode: "lazy" */ '@/pages/assetsByRegion/AssetsByRegion.vue'),
    },
];
export default paths;
