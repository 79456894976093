const paths = [
    {
        path: '/document-group-status-list',
        title: 'GROUPS_STEPS',
        name: 'DocumentGroupStatusList',
        icon: 'fa fa-briefcase',
        menu: 'maintenance',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "document" */ /* webpackMode: "lazy" */ '@/pages/document/DocumentGroupStatusList.vue'),
    },
    {
        path: '/document-group-status',
        name: 'DocumentGroupStatus',
        title: 'GROUP_STEPS',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "document" */ /* webpackMode: "lazy" */ '@/pages/document/DocumentGroupStatus.vue'),
    },
    {
        path: '/document-group-status-linked-status-general',
        name: 'DocumentGroupStatusLinkedStatusGeneral',
        title: 'STEP_2',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "document" */ /* webpackMode: "lazy" */ '@/pages/document/DocumentGroupStatusLinkedStatusGeneral.vue'),
    },
    {
        path: '/document-type-list',
        title: 'DOCUMENT_TYPES',
        name: 'DocumentTypeList',
        icon: 'widgets',
        menu: 'maintenance',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "document" */ /* webpackMode: "lazy" */ '@/pages/document/DocumentTypeList.vue'),
    },
    {
        path: '/document-type-general',
        name: 'DocumentTypeGeneral',
        title: 'DOCUMENT_TYPE',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "document" */ /* webpackMode: "lazy" */ '@/pages/document/DocumentTypeGeneral.vue'),
    },
];

export default paths;
