import Vue from 'vue';
import i18n from '@/i18n';
import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
const { $autocomplete, $util, } = Vue.prototype;
export class GetFinantialAllocationsHistoricMovement extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarHistoricoMovAlocFinanceiras',
            type: 'SEARCH',
            node: 'CENTROS_FINANCEIROS.CENTRO_FINANCEIRO',
            fields: {
                startingDate: new Field({
                    name: 'DATA_INICIO',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('INITIAL_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                endingDate: new Field({
                    name: 'DATA_FIM',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('ENDING_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                finantialAccountId: new Field({
                    name: 'ID_CONTA_FINANC',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('ACCOUNT'),
                        entity: $autocomplete.financialCenterId,
                        'entity-params': {
                            AGRUPADOR: 0,
                            PERMITE_MOVIMENTOS: 1,
                        },
                    },
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetPeriodicFinantialAllocationsHistoricMovement extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarHistoricoMovAlocFinanceirasPeriodico',
            type: 'SEARCH',
            node: 'CENTROS_FINANCEIROS.CENTRO_FINANCEIRO',
            fields: {
                startingDate: new Field({
                    name: 'DATA_INICIO',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('INITIAL_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                endingDate: new Field({
                    name: 'DATA_FIM',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('ENDING_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                finantialAccountId: new Field({
                    name: 'ID_CONTA_FINANC',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('ACCOUNT'),
                        entity: $autocomplete.financialCenterId,
                        'entity-params': {
                            AGRUPADOR: 0,
                            PERMITE_MOVIMENTOS: 1,
                        },
                    },
                }),
                finantialAccounts: new Field({
                    name: 'CONTAS_FINANC',
                }),
                category: new Field({
                    name: 'CATEGORIAS',
                }),
                categoryId: new Field({
                    name: 'ID_CATEGORIA',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetCandleBalance extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarSaldoCandle',
            type: 'SEARCH',
            node: 'SALDOS.SALDO',
            fields: {
                startingDate: new Field({
                    name: 'DATA_INICIO',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('INITIAL_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                endingDate: new Field({
                    name: 'DATA_FIM',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('ENDING_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                finantialAccountId: new Field({
                    name: 'ID_CONTA_FINANC',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('ACCOUNT'),
                        entity: $autocomplete.financialCenterId,
                        'entity-params': {
                            AGRUPADOR: 0,
                            PERMITE_MOVIMENTOS: 1,
                        },
                    },
                }),
                requestDate: new Field({
                    name: 'DATA_PESQUISA',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
