import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import { parseSuccessResponse, showErrorsResponse } from '../utils';
export class GetTemplates extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_template',
            type: 'NONE',
            fields: {
                filter: new Field({
                    name: 'BODY',
                    parser: (value) => `filter=${encodeURIComponent(JSON.stringify(value))}`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class GetTemplateById extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_template_0',
            type: 'NONE',
            fields: {
                include: new Field({
                    name: 'BODY',
                    value: [],
                }),
                templateId: new Field({
                    name: 'PATH_PARAMS',
                }),
            },
            customRequestParser: function (params) {
                const filter = {};
                filter.include = params.include || [];
                return {
                    BODY: `filter=${encodeURIComponent(JSON.stringify(filter))}`,
                    PATH_PARAMS: `{ PARAM_0: ${String(params.templateId)} }`,
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class GetCurrentTemplates extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_template_current',
            type: 'NONE',
            fields: {
                include: new Field({
                    name: 'BODY',
                    value: [],
                }),
            },
            customRequestParser: function (params) {
                const filter = {};
                filter.include = params.include || [];
                return {
                    BODY: `filter=${encodeURIComponent(JSON.stringify(filter))}`,
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class GetCurrentTemplateByDeviceId extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_template_current_0',
            type: 'NONE',
            node: 'RESPONSE',
            fields: {
                deviceId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
                showValues: new Field({
                    name: 'showValues',
                    value: true,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class SaveTemplate extends CallCommand {
    constructor() {
        super({
            name: 'post_comp_template',
            type: 'SAVE',
            fields: {
                template: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class EditTemplate extends CallCommand {
    constructor() {
        super({
            name: 'put_comp_template_0',
            type: 'SAVE',
            fields: {
                templateId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
                template: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class DeleteTemplate extends CallCommand {
    constructor() {
        super({
            name: 'delete_comp_template_0',
            type: 'DELETE',
            fields: {
                templateId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class MakeTemplateDefault extends CallCommand {
    constructor() {
        super({
            name: 'post_default-template',
            type: 'SAVE',
            fields: {
                template: {
                    name: 'template',
                },
            },
            customRequestParser: function (params) {
                const data = {
                    template: params.template,
                };
                return {
                    BODY: JSON.stringify(data.template),
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
