const paths = [
    {
        path: '/stock-position-report',
        name: 'StockPositionReportList',
        title: 'STOCK_POSITION_REPORT',
        icon: 'fas fa-clipboard',
        menu: 'stock',
        component: () => import(/* webpackChunkName: "stockPositionReport" */ /* webpackMode: "lazy" */ '@/pages/stock/stockPositionReport/StockPositionReportList.vue'),
    },
];

export default paths;
