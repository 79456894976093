<template>
    <a-row>
        <a-col v-bind='colAttrs'>
            <a-date-picker
                v-if='type === "date"'
                v-model='date.from'
                v-bind='fromAttrs'
                text-field
                return-serve
            />
            <a-date-time-picker
                v-if='type === "datetime"'
                v-model='date.from'
                v-bind='fromAttrs'
                text-field
                return-serve
            />
            <a-time-picker
                v-if='type === "time"'
                v-model='date.from'
                v-bind='fromAttrs'
                text-field
            />
        </a-col>
        <a-col v-bind='colAttrs'>
            <a-date-picker
                v-if='type === "date"'
                v-model='date.to'
                :min-date='minDate'
                v-bind='toAttrs'
                text-field
                return-serve
            />
            <a-date-time-picker
                v-if='type === "datetime"'
                v-model='date.to'
                :min-date='minDate'
                v-bind='toAttrs'
                text-field
                return-serve
            />
            <a-time-picker
                v-if='type === "time"'
                v-model='date.to'
                :min-date='minDate'
                v-bind='toAttrs'
                text-field
            />
        </a-col>
    </a-row>
</template>

<script>
    import isEmpty from '../../aura/util/helper/isEmpty';
    import { getProps } from '../../util/helpers';

    export default {
        name: 'APeriodPicker',
        props: {
            value: {
                type: Object,
                default: function () {
                    return { from: null, to: null };
                },
            },
            type: {
                type: String,
                default: 'date',
            },
        },
        computed: {
            date: {
                get: function () {
                    return this.value;
                },
                set: function () {
                    this.$emit('input', value);
                },
            },
            fromAttrs: function () {
                return getProps(this.$attrs, ['from-']);
            },
            toAttrs: function () {
                return getProps(this.$attrs, ['to-']);
            },
            minDate: function () {
                if (this.fromAttrs.minDate) {
                    return this.fromAttrs.minDate;
                }

                if (this.date.from && this.type === 'date') {
                    return this.$moment(this.date.from, 'DD/MM/YYYY').format('YYYY-MM-DD')
                }
                
                if (this.date.from && this.type === 'datetime') {
                    return this.$moment(this.date.from, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm')
                }
            },
            colAttrs: function () {
                const props = getProps(this.$attrs, ['col-']);
                return (!isEmpty(props) && props) || { cols: 12, md: 6 };
            },
        },
    };
</script>
