const paths = [
  {
      path: '/offsetting-of-balances-report',
      name: 'OffsettingOfBalancesReport',
      title: 'OFFSETTING_OF_BALANCES_REPORT',
      icon: 'mdi-file-document',
      menu: 'financial',
      categories: [
        'reports',
      ],
    favCategory: 'report',
      component: () => import(/* webpackChunkName: "reportEnergy" */ /* webpackMode: "lazy" */ '@/pages/report/energy/OffsettingOfBalancesReport.vue'),
  },
];

export default paths;
