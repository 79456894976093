import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import { parseSuccessResponse, showErrorsResponse } from '../utils';
export class GetRoles extends CallCommand {
    constructor() {
        super({
            name: 'get_roles',
            type: 'NONE',
        });
    }
    async callCommand(config, debug) {
        return super.callCommand(config, debug);
    }
}
export class GetRolesPermissions extends CallCommand {
    constructor() {
        super({
            name: 'get_role-permissions',
            type: 'NONE',
        });
    }
    async callCommand(config, debug) {
        return super.callCommand(config, debug);
    }
}
export class SaveRolesPermissions extends CallCommand {
    constructor() {
        super({
            name: 'post_role-permissions',
            type: 'NONE',
            fields: {
                rolePermission: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class EditRolePermission extends CallCommand {
    constructor() {
        super({
            name: 'patch_role-permissions',
            type: 'NONE',
            fields: {
                rolePermission: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
