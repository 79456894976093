<script>
    export default {
        name: 'ABtnNext',
        functional: true,
        render: function (createElement, { data, parent, children }) {
            delete data.attrs.next;
            data.attrs.color = data.attrs.color || 'primary';
            data.attrs._iconType = 'next';
            data.attrs._rightIcon = true;
            const defaultColorIcon = data.attrs['outlined'] !== undefined && data.attrs['outlined'] !== false ? 'primary' : 'white'
            data.attrs['color-icon'] = data.attrs['color-icon'] || defaultColorIcon;
            children = children || [];
            if ((!data.scopedSlots || !data.scopedSlots['tooltip-body']) && !data.attrs['_tooltip-text']) {
                data.attrs['_tooltip-text'] = parent.$t('NEXT');
            }
            return createElement('ABtnDefault', data, children);
        },
    };
</script>
