<script>
    export default {
        name: 'ABtn',
        functional: true,
        props: {
            depressed: {
                type: Boolean,
                required: false,
                default: true
            },
            _pulse: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        render: function (createElement, { data, props, children }) {
            data.attrs = data.attrs || {};
            data.attrs.depressed = props._pulse ? false : props.depressed;
            data.attrs._pulse = props._pulse;

            if (Object.keys(data.attrs).includes('label') && data.attrs.label !== false && data.attrs.label !== 'false') {
                children = children || [];
                children.push(createElement('div', data.attrs.label));
            }

            if (Object.keys(data.attrs).includes('default') && data.attrs.default !== false && data.attrs.default !== 'false') {
                return createElement('ABtnDefault', data, children);
            } if (Object.keys(data.attrs).includes('add') && data.attrs.add !== false && data.attrs.add !== 'false') {
                return createElement('ABtnAdd', data, children);
            } if (Object.keys(data.attrs).includes('delete') && data.attrs.delete !== false && data.attrs.delete !== 'false') {
                return createElement('ABtnDelete', data, children);
            } if (Object.keys(data.attrs).includes('edit') && data.attrs.edit !== false && data.attrs.edit !== 'false') {
                return createElement('ABtnEdit', data, children);
            } if (Object.keys(data.attrs).includes('export') && data.attrs.export !== false && data.attrs.export !== 'false') {
                return createElement('ABtnExport', data, children);
            } if (Object.keys(data.attrs).includes('save') && data.attrs.save !== false && data.attrs.save !== 'false') {
                return createElement('ABtnSave', data, children);
            } if (Object.keys(data.attrs).includes('search') && data.attrs.search !== false && data.attrs.search !== 'false') {
                return createElement('ABtnSearch', data, children);
            } if (Object.keys(data.attrs).includes('next') && data.attrs.next !== false && data.attrs.next !== 'false') {
                return createElement('ABtnNext', data, children);
            } if (Object.keys(data.attrs).includes('previous') && data.attrs.previous !== false && data.attrs.previous !== 'false') {
                return createElement('ABtnPrevious', data, children);
            } if (Object.keys(data.attrs).includes('check') && data.attrs.check !== false && data.attrs.check !== 'false') {
                return createElement('ABtnCheck', data, children);
            } if (Object.keys(data.attrs).includes('usercheck') && data.attrs.usercheck !== false && data.attrs.usercheck !== 'false') {
                return createElement('ABtnUserCheck', data, children);
            } if (Object.keys(data.attrs).includes('useruncheck') && data.attrs.useruncheck !== false && data.attrs.useruncheck !== 'false') {
                return createElement('ABtnUserUncheck', data, children);
            } if (Object.keys(data.attrs).includes('excel') && data.attrs.excel !== false && data.attrs.excel !== 'false') {
                return createElement('ABtnExcel', data, children);
            } if (Object.keys(data.attrs).includes('pdf') && data.attrs.pdf !== false && data.attrs.pdf !== 'false') {
                return createElement('ABtnPdf', data, children);
            } if (Object.keys(data.attrs).includes('print') && data.attrs.print !== false && data.attrs.print !== 'false') {
                return createElement('ABtnPrint', data, children);
            } if (Object.keys(data.attrs).includes('uncheck') && data.attrs.uncheck !== false && data.attrs.uncheck !== 'false') {
                return createElement('ABtnUncheck', data, children);
            } if (Object.keys(data.attrs).includes('view') && data.attrs.view !== false && data.attrs.view !== 'false') {
                return createElement('ABtnView', data, children);
            } if (Object.keys(data.attrs).includes('filter') && data.attrs.filter !== false && data.attrs.filter !== 'false') {
                return createElement('ABtnFilter', data, children);
            } if (Object.keys(data.attrs).includes('refresh') && data.attrs.refresh !== false && data.attrs.refresh !== 'false') {
                return createElement('ABtnRefresh', data, children);
            } if (Object.keys(data.attrs).includes('copy') && data.attrs.copy !== false && data.attrs.copy !== 'false') {
                return createElement('ABtnCopy', data, children);
            } if (Object.keys(data.attrs).includes('clone') && data.attrs.clone !== false && data.attrs.clone !== 'false') {
                return createElement('ABtnClone', data, children);
            } if (Object.keys(data.attrs).includes('ellipsisv') && data.attrs.ellipsisv !== false && data.attrs.ellipsisv !== 'false') {
                return createElement('ABtnEllipsisv', data, children);
            } if (Object.keys(data.attrs).includes('ellipsish') && data.attrs.ellipsish !== false && data.attrs.ellipsish !== 'false') {
                return createElement('ABtnEllipsish', data, children);
            } if (Object.keys(data.attrs).includes('menu') && data.attrs.menu !== false && data.attrs.menu !== 'false') {
                return createElement('ABtnMenu', data, children);
            } if (Object.keys(data.attrs).includes('settings') && data.attrs.settings !== false && data.attrs.settings !== 'false') {
                return createElement('ABtnSettings', data, children);
            } if (Object.keys(data.attrs).includes('expandbottom') && data.attrs.expandbottom !== false && data.attrs.expandbottom !== 'false') {
                return createElement('ABtnExpandbottom', data, children);
            } if (Object.keys(data.attrs).includes('expandtop') && data.attrs.expandtop !== false && data.attrs.expandtop !== 'false') {
                return createElement('ABtnExpandtop', data, children);
            } if (Object.keys(data.attrs).includes('ban') && data.attrs.ban !== false && data.attrs.ban !== 'false') {
                return createElement('ABtnBan', data, children);
            } if (Object.keys(data.attrs).includes('calendar') && data.attrs.calendar !== false && data.attrs.calendar !== 'false') {
                return createElement('ABtnCalendar', data, children);
            } if (Object.keys(data.attrs).includes('cellphone') && data.attrs.cellphone !== false && data.attrs.cellphone !== 'false') {
                return createElement('ABtnCellphone', data, children);
            } if (Object.keys(data.attrs).includes('chat') && data.attrs.chat !== false && data.attrs.chat !== 'false') {
                return createElement('ABtnChat', data, children);
            } if (Object.keys(data.attrs).includes('warning') && data.attrs.warning !== false && data.attrs.warning !== 'false') {
                return createElement('ABtnWarning', data, children);
            } if (Object.keys(data.attrs).includes('clock') && data.attrs.clock !== false && data.attrs.clock !== 'false') {
                return createElement('ABtnClock', data, children);
            } if (Object.keys(data.attrs).includes('close') && data.attrs.close !== false && data.attrs.close !== 'false') {
                return createElement('ABtnClose', data, children);
            } if (Object.keys(data.attrs).includes('download') && data.attrs.download !== false && data.attrs.download !== 'false') {
                return createElement('ABtnDownload', data, children);
            } if (Object.keys(data.attrs).includes('externallink') && data.attrs.externallink !== false && data.attrs.externallink !== 'false') {
                return createElement('ABtnExternallink', data, children);
            } if (Object.keys(data.attrs).includes('fax') && data.attrs.fax !== false && data.attrs.fax !== 'false') {
                return createElement('ABtnFax', data, children);
            } if (Object.keys(data.attrs).includes('file') && data.attrs.file !== false && data.attrs.file !== 'false') {
                return createElement('ABtnFile', data, children);
            } if (Object.keys(data.attrs).includes('flag') && data.attrs.flag !== false && data.attrs.flag !== 'false') {
                return createElement('ABtnFlag', data, children);
            } if (Object.keys(data.attrs).includes('folder') && data.attrs.folder !== false && data.attrs.folder !== 'false') {
                return createElement('ABtnFolder', data, children);
            } if (Object.keys(data.attrs).includes('graph') && data.attrs.graph !== false && data.attrs.graph !== 'false') {
                return createElement('ABtnGraph', data, children);
            } if (Object.keys(data.attrs).includes('historic') && data.attrs.historic !== false && data.attrs.historic !== 'false') {
                return createElement('ABtnHistoric', data, children);
            } if (Object.keys(data.attrs).includes('info') && data.attrs.info !== false && data.attrs.info !== 'false') {
                return createElement('ABtnInfo', data, children);
            } if (Object.keys(data.attrs).includes('map') && data.attrs.map !== false && data.attrs.map !== 'false') {
                return createElement('ABtnMap', data, children);
            } if (Object.keys(data.attrs).includes('marker') && data.attrs.marker !== false && data.attrs.marker !== 'false') {
                return createElement('ABtnMarker', data, children);
            } if (Object.keys(data.attrs).includes('notification') && data.attrs.notification !== false && data.attrs.notification !== 'false') {
                return createElement('ABtnNotification', data, children);
            } if (Object.keys(data.attrs).includes('phone') && data.attrs.phone !== false && data.attrs.phone !== 'false') {
                return createElement('ABtnPhone', data, children);
            } if (Object.keys(data.attrs).includes('photo') && data.attrs.photo !== false && data.attrs.photo !== 'false') {
                return createElement('ABtnPhoto', data, children);
            } if (Object.keys(data.attrs).includes('pin') && data.attrs.pin !== false && data.attrs.pin !== 'false') {
                return createElement('ABtnPin', data, children);
            } if (Object.keys(data.attrs).includes('privacy') && data.attrs.privacy !== false && data.attrs.privacy !== 'false') {
                return createElement('ABtnPrivacy', data, children);
            } if (Object.keys(data.attrs).includes('star') && data.attrs.star !== false && data.attrs.star !== 'false') {
                return createElement('ABtnStar', data, children);
            } if (Object.keys(data.attrs).includes('upload') && data.attrs.upload !== false && data.attrs.upload !== 'false') {
                return createElement('ABtnUpload', data, children);
            } if (Object.keys(data.attrs).includes('user') && data.attrs.user !== false && data.attrs.user !== 'false') {
                return createElement('ABtnUser', data, children);
            } if (Object.keys(data.attrs).includes('video') && data.attrs.video !== false && data.attrs.video !== 'false') {
                return createElement('ABtnVideo', data, children);
            } if (Object.keys(data.attrs).includes('barcode') && data.attrs.barcode !== false && data.attrs.barcode !== 'false') {
                return createElement('ABtnBarcode', data, children);
            } if (Object.keys(data.attrs).includes('qrcode') && data.attrs.qrcode !== false && data.attrs.qrcode !== 'false') {
                return createElement('ABtnQRCode', data, children);
            } if (Object.keys(data.attrs).includes('keyboard') && data.attrs.keyboard !== false && data.attrs.keyboard !== 'false') {
                return createElement('ABtnKeyboard', data, children);
            } if (Object.keys(data.attrs).includes('globe') && data.attrs.globe !== false && data.attrs.globe !== 'false') {
                return createElement('ABtnGlobe', data, children);
            } if (Object.keys(data.attrs).includes('tablesearch') && data.attrs.tablesearch !== false && data.attrs.tablesearch !== 'false') {
                return createElement('ABtnTablesearch', data, children);
            } if (Object.keys(data.attrs).includes('play') && data.attrs.play !== false && data.attrs.play !== 'false') {
                return createElement('ABtnPlay', data, children);
            } if (Object.keys(data.attrs).includes('lock') && data.attrs.lock !== false && data.attrs.lock !== 'false') {
                return createElement('ABtnLock', data, children);
            } if (Object.keys(data.attrs).includes('usergroup') && data.attrs.usergroup !== false && data.attrs.usergroup !== 'false') {
                return createElement('ABtnUserGroup', data, children);
            } if (Object.keys(data.attrs).includes('migrate') && data.attrs.migrate !== false && data.attrs.migrate !== 'false') {
                return createElement('ABtnMigrate', data, children);
            } if (Object.keys(data.attrs).includes('service') && data.attrs.service !== false && data.attrs.service !== 'false') {
                return createElement('ABtnService', data, children);
            } if (Object.keys(data.attrs).includes('alarm') && data.attrs.alarm !== false && data.attrs.alarm !== 'false') {
                return createElement('ABtnAlarm', data, children);
            } if (Object.keys(data.attrs).includes('unlink') && data.attrs.unlink !== false && data.attrs.unlink !== 'false') {
                return createElement('ABtnUnlink', data, children);
            } if (Object.keys(data.attrs).includes('arrowup') && data.attrs.arrowup !== false && data.attrs.arrowup !== 'false') {
                return createElement('ABtnArrowUp', data, children);
            } if (Object.keys(data.attrs).includes('arrowdown') && data.attrs.arrowdown !== false && data.attrs.arrowdown !== 'false') {
                return createElement('ABtnArrowDown', data, children);
            } if (Object.keys(data.attrs).includes('send') && data.attrs.send !== false && data.attrs.send !== 'false') {
                return createElement('ABtnSend', data, children);
            } if (Object.keys(data.attrs).includes('emailreceive') && data.attrs.emailreceive !== false && data.attrs.emailreceive !== 'false') {
                return createElement('ABtnEmailreceive', data, children);
            } if (Object.keys(data.attrs).includes('splitarrow') && data.attrs.splitarrow !== false && data.attrs.splitarrow !== 'false') {
                return createElement('ABtnSplitarrow', data, children);
            } if (Object.keys(data.attrs).includes('currency') && data.attrs.currency !== false && data.attrs.currency !== 'false') {
                return createElement('ABtnCurrency', data, children);
            } if (Object.keys(data.attrs).includes('link') && data.attrs.link !== false && data.attrs.link !== 'false') {
                return createElement('ABtnLink', data, children);
            } if (Object.keys(data.attrs).includes('refund') && data.attrs.refund !== false && data.attrs.refund !== 'false') {
                return createElement('ABtnRefund', data, children);
            } if (Object.keys(data.attrs).includes('scalebalance') && data.attrs.scalebalance !== false && data.attrs.scalebalance !== 'false') {
                return createElement('ABtnScalebalance', data, children);
            } if (Object.keys(data.attrs).includes('assign-user') && data.attrs['assign-user'] !== false && data.attrs['assign-user'] !== 'false') {
                return createElement('ABtnAssignUser', data, children);
            } if (Object.keys(data.attrs).includes('dollar-sign') && data.attrs['dollar-sign'] !== false && data.attrs['dollar-sign'] !== 'false') {
                return createElement('ABtnDollarSign', data, children);
            } if (Object.keys(data.attrs).includes('link') && data.attrs.link !== false && data.attrs.link !== 'false') {
                return createElement('ABtnLink', data, children);
            } if (Object.keys(data.attrs).includes('invoice') && data.attrs.invoice !== false && data.attrs.invoice !== 'false') {
                return createElement('ABtnInvoice', data, children);
            } if (Object.keys(data.attrs).includes('open') && data.attrs.open !== false && data.attrs.open !== 'false') {
                return createElement('ABtnOpen', data, children);
            } if (Object.keys(data.attrs).includes('lightbulb') && data.attrs.lightbulb !== false && data.attrs.lightbulb !== 'false') {
                return createElement('ABtnLightbulb', data, children);
            }

            return createElement('ABtnRaw', data, children);
        },
    };
</script>
<style lang="scss" >
.theme--light {
    .v-btn.save, .v-btn.delete{
        color: #fff;
    }
    .v-btn:not(.v-btn--outlined).mono-grey-2 {
        border-width: thin;
        border-style: solid;
        border-color: var(--v-mono-grey-10-base) !important;
    }
}
.application {
    .v-btn {
        text-transform: inherit;
    }
    .v-btn:not(.v-btn--rounded):not(.v-btn--round).v-size--default {
        border-radius: 2px;
        padding: 10px 25px;
        font-weight: 400;
    }
}
</style>
