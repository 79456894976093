const paths = [
    {
        path: '/team-type-list',
        title: 'TEAM_TYPES',
        name: 'TeamTypeList',
        icon: 'widgets',
        menu: 'humanResources',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "teamType" */ /* webpackMode: "lazy" */ '@/pages/teamType/TeamTypeList.vue'),
    },
    {
        path: '/team-type',
        title: 'TEAM_TYPE',
        name: 'TeamType',
        favCategory: 'humanResources',
        component: () => import(/* webpackChunkName: "teamType" */ /* webpackMode: "lazy" */ '@/pages/teamType/TeamType.vue'),
    },
    {
        path: '/team-type-general',
        title: 'TEAM_TYPE_GENERAL',
        name: 'TeamTypeGeneral',
        component: () => import(/* webpackChunkName: "teamType" */ /* webpackMode: "lazy" */ '@/pages/teamType/TeamTypeGeneral.vue'),
    },
    {
        path: '/team-type-issue-type',
        title: 'ISSUE_TYPE',
        name: 'TeamTypeIssueType',
        component: () => import(/* webpackChunkName: "teamType" */ /* webpackMode: "lazy" */ '@/pages/teamType/TeamTypeIssueType.vue'),
    },
    {
        path: '/team-type-technical-scope',
        title: 'TECHNICAL_SCOPE',
        name: 'TeamTypeTechnicalScope',
        component: () => import(/* webpackChunkName: "teamType" */ /* webpackMode: "lazy" */ '@/pages/teamType/TeamTypeTechnicalScope.vue'),
    },
    {
        path: '/team-type-vehicle-types',
        title: 'VEHICLE_TYPES',
        name: 'TeamTypeVehicleTypes',
        component: () => import(/* webpackChunkName: "teamType" */ /* webpackMode: "lazy" */ '@/pages/teamType/TeamTypeVehicleTypes.vue'),
    },
    {
        path: '/team-type-vehicle-type-general',
        title: 'VEHICLE_TYPE_GENERAL',
        name: 'TeamTypeVehicleTypeGeneral',
        component: () => import(/* webpackChunkName: "teamType" */ /* webpackMode: "lazy" */ '@/pages/teamType/TeamTypeVehicleTypeGeneral.vue'),
    },
    {
        path: '/team-type-role-categories',
        title: 'ROLE_CATEGORIES',
        name: 'TeamTypeRoleCategories',
        component: () => import(/* webpackChunkName: "teamType" */ /* webpackMode: "lazy" */ '@/pages/teamType/TeamTypeRoleCategories.vue'),
    },
    {
        path: '/team-type-role-category-general',
        title: 'ROLE_CATEGORY_GENERAL',
        name: 'TeamTypeRoleCategoryGeneral',
        component: () => import(/* webpackChunkName: "teamType" */ /* webpackMode: "lazy" */ '@/pages/teamType/TeamTypeRoleCategoryGeneral.vue'),
    },
];

export default paths;
