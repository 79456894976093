const paths = [
    {
        path: '/basic-records',
        name: 'BasicRecords',
        title: 'BASIC_RECORDS',
        icon: 'fa fa-briefcase',
        menu: 'budgets',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "basicRecords" */ /* webpackMode: "lazy" */ '@/pages/basicRecords/BasicRecords.vue'),
    },
    {
        path: '/server',
        name: 'Server',
        title: 'SERVER',
        component: () => import(/* webpackChunkName: "basicRecords" */ /* webpackMode: "lazy" */ '@/pages/basicRecords/Server.vue'),
    },
    {
        path: '/server-wizard',
        name: 'ServerWizard',
        title: 'SERVER_WIZARD',
        component: () => import(/* webpackChunkName: "basicRecords" */ /* webpackMode: "lazy" */ '@/pages/basicRecords/ServerWizard.vue'),
    },
    {
        path: '/products-basic-records',
        name: 'ProductsBasicRecords',
        title: 'PRODUCTS',
        component: () => import(/* webpackChunkName: "basicRecords" */ /* webpackMode: "lazy" */ '@/pages/basicRecords/ProductsBasicRecords.vue'),
    },
    {
        path: '/product-version',
        name: 'ProductVersion',
        title: 'ProductVersion',
        component: () => import(/* webpackChunkName: "basicRecords" */ /* webpackMode: "lazy" */ '@/pages/basicRecords/ProductVersion.vue'),
    },
    {
        path: '/product-version-wizard',
        name: 'ProductVersionWizard',
        title: 'ProductVersionWizard',
        component: () => import(/* webpackChunkName: "basicRecords" */ /* webpackMode: "lazy" */ '@/pages/basicRecords/ProductVersionWizard.vue'),
    },
    {
        path: '/instance-product',
        name: 'InstanceProduct',
        title: 'InstanceProduct',
        component: () => import(/* webpackChunkName: "basicRecords" */ /* webpackMode: "lazy" */ '@/pages/basicRecords/InstanceProduct.vue'),
    },
    {
        path: '/instance-product-details',
        name: 'InstanceProductDetails',
        title: 'InstanceProductDetails',
        component: () => import(/* webpackChunkName: "basicRecords" */ /* webpackMode: "lazy" */ '@/pages/basicRecords/InstanceProductDetails.vue'),
    },
    {
        path: '/product-client',
        name: 'ProductClient',
        title: 'ProductClient',
        component: () => import(/* webpackChunkName: "basicRecords" */ /* webpackMode: "lazy" */ '@/pages/basicRecords/ProductClient.vue'),
    },
    {
        path: '/product-client-details',
        name: 'ProductClientDetails',
        title: 'ProductClientDetails',
        component: () => import(/* webpackChunkName: "basicRecords" */ /* webpackMode: "lazy" */ '@/pages/basicRecords/ProductClientDetails.vue'),
    },
    {
        path: '/user-attribute-schema',
        name: 'UserAttributeSchema',
        title: 'UserAttributeSchema',
        component: () => import(/* webpackChunkName: "basicRecords" */ /* webpackMode: "lazy" */ '@/pages/basicRecords/UserAttributeSchema.vue'),
    },
];

export default paths;
