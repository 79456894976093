/* eslint-disable object-curly-newline */
import { Entity } from '@inputs/entity.ts';

import layersGis from '@select/util/customProvider/layers-gis';
import workOrderStatusCustom from '@select/util/customProvider/workOrderStatusCustom';
import address from '@select/util/customProvider/address';
import ticketStatus from '@select/util/customProvider/ticketStatus';
import itemManufacturer from '@select/util/customProvider/itemManufacturer';
import stockCenter from '@select/util/customProvider/stockCenter';
import materialRequestStatusCustom from '@select/util/customProvider/materialRequestStatusCustom';
import issueTypeOrigin from '@select/util/customProvider/issueTypeOrigin';
import requestMaterialShowProject from '@select/util/customProvider/requestMaterialShowProject';
import detailedIssueRegion from '@select/util/customProvider/detailedIssueRegion';
import detailedIssueDistrict from '@select/util/customProvider/detailedIssueDistrict';
import detailedCity from '@select/util/customProvider/detailedCity';
import issueStatus from '@select/util/customProvider/issueStatus';
import subProject from '@select/util/customProvider/subProject';
import itemFabricator from '@autocomplete/util/customProvider/item-fabricator';
import personBankAccount from '@select/util/customProvider/personBankAccount';
import personBankAccountWithInfo from '@select/util/customProvider/personBankAccountWithInfo';
import projectStructure from '@select/util/customProvider/projectStructure';
import projectTeam from '@autocomplete/util/customProvider/project-team';
import measurementProject from '@select/util/customProvider/measurement-project';
import billSaleItemsToIdentify from '@select/util/customProvider/bill-sale-items-to-identify.js';
import contacts from '@select/util/customProvider/contacts';
import statusListSearch from '@select/util/customProvider/statusListSearch';
import itemType from '@select/util/customProvider/itemType';
import listings from '@select/util/customProvider/listings';
import contractStatus from '@select/util/customProvider/contractStatus';
import projectContract from '@select/util/customProvider/project-contract';
import budgetStatusWithAll from '@select/util/customProvider/budgetStatusWithAll';
import projectStatusWithAll from '@select/util/customProvider/projectStatusWithAll.js';
import scheduleStatus from '@select/util/customProvider/scheduleStatus.js';
import citiesInstances from '@select/util/customProvider/citiesInstancesCi.js';
import configIlluminatedCity from '@select/util/customProvider/configIlluminatedCity.js';
import detailedStockMovementReason from '@select/util/customProvider/detailedStockMovementReason.js';
import issueType from '@select/util/customProvider/issueType.js';
import ombudsmanTypeOrigin from '@select/util/customProvider/ombudsmanTypeOrigin';
import ombudsmanReason from '@select/util/customProvider/ombudsmanReason';
import areaTypes from '@select/util/customProvider/areaTypes';

const customProvider = {
    layerGis: new Entity({ fieldNameId: 'VALUE', descriptionField: 'KEY', commandName: 'ConsultarInfGeograficasPS', providerField: 'DADOS_GIS', customProvider: layersGis }),
    workOrderStatusCustom: new Entity({ fieldNameId: 'ID_STATUS_ORDEM_SERVICO', descriptionField: 'DESC_STATUS_ORDEM_SERVICO', commandName: 'ConsultarStatusOrdemServico', providerField: 'ORDENS_SERVICO.ORDEM_SERVICO.STATUS_ORDEM_SERVICO', customProvider: workOrderStatusCustom }),
    address: new Entity({ fieldNameId: 'KEY', descriptionField: 'VALUE', commandName: 'ConsultarLogradouroPorPalavra', providerField: 'LOGRADOUROS.LOGRADOURO', customProvider: address }),
    ticketStatus: new Entity({ fieldNameId: 'KEY', descriptionField: 'VALUE', commandName: 'ConsultarTodosStatusSolicitacao', providerField: 'STATUSES_SOLICITACOES.STATUS_SOLICITACOES', customProvider: ticketStatus }),
    itemManufacturer: new Entity({ fieldNameId: 'KEY', descriptionField: 'VALUE', commandName: 'ConsultarFabricantesItem', providerField: 'ITEM.FABRICANTES.FABRICANTE', customProvider: itemManufacturer }),
    stockCenter: new Entity({ fieldNameId: 'ID_CENTRO_ESTOQUE', descriptionField: 'DESC_CENTRO_ESTOQUE', commandName: 'ConsultarCentrosEstoque', providerField: 'CENTROS_ESTOQUE.CENTRO_ESTOQUE', customProvider: stockCenter }),
    materialRequestStatusCustom: new Entity({ fieldNameId: 'ID_STATUS_SOLIC_MATERIAL', descriptionField: 'DESC_STATUS_SOLIC_MATERIAL', commandName: 'ConsultarStatusSolicMaterial', providerField: 'STATUS_SOLIC_MATERIAL.STATUS', customProvider: materialRequestStatusCustom }),
    issueTypeOriginWithAll: new Entity({ fieldNameId: 'ID_TIPO_ORIGEM_OCORRENCIA', descriptionField: 'DESC_TIPO_ORIGEM_OCORRENCIA', commandName: 'ConsultarTipoOrigemOcorrencia', providerField: 'TIPOS_ORIGEM_OCORRENCIA.TIPO_ORIGEM_OCORRENCIA', customProvider: issueTypeOrigin }),
    requestMaterialShowProject: new Entity({ fieldNameId: 'ID_SOLIC_MATERIAL', descriptionField: 'description', commandName: 'ConsultarSolicitacoesMaterial', providerField: 'SOLICITACOES_MATERIAL.SOLICITACAO_MATERIAL', customProvider: requestMaterialShowProject }),
    detailedIssueRegion: new Entity({ fieldNameId: 'ID_REGIAO', descriptionField: 'description', commandName: 'ConsultarRegioesOcorrencia', providerField: 'REGIOES.REGIAO', customProvider: detailedIssueRegion }),
    detailedIssueDistrict: new Entity({ fieldNameId: 'ID_BAIRRO', descriptionField: 'description', commandName: 'ConsultarBairrosOcorrencia', providerField: 'BAIRROS.BAIRRO', customProvider: detailedIssueDistrict }),
    detailedCity: new Entity({ fieldNameId: 'ID_MUNICIPIO', descriptionField: 'description', commandName: 'ConsultarMunicipiosParqueServico', providerField: 'MUNICIPIOS.MUNICIPIO', customProvider: detailedCity }),
    issueStatus: new Entity({ fieldNameId: 'ID_STATUS_OCORRENCIA', descriptionField: 'DESC_STATUS_OCORRENCIA', commandName: 'ConsultarStatusOcorrenciaComboBox', providerField: 'STATUS_OCORRENCIA.STATU_OCORRENCIA', customProvider: issueStatus }),
    subProject: new Entity({ fieldNameId: 'ID_SUB_OBRA', descriptionField: 'DESC_SUB_OBRA', commandName: 'ConsultarSubObras', providerField: 'SUB_OBRAS.SUB_OBRA', customProvier: subProject }),
    manufacturerItem: new Entity({ fieldNameId: 'ID_ITEM_FABRICANTE', descriptionField: 'DESC_ITEM_FABRICANTE', commandName: 'ConsultarItemFabricante', providerField: 'FABRICANTES.FABRICANTE', customProvider: itemFabricator }),
    projectStructure: new Entity({ fieldNameId: 'ID_ESTRUTURA', descriptionField: 'description', commandName: 'ConsultarEstruturasObra', providerField: 'ESTRUTURAS.ESTRUTURA', customProvider: projectStructure }),
    projectTeam: new Entity({ fieldNameId: 'INDEX_EQUIPE_OBRA', descriptionField: 'description', commandName: 'ConsultarEquipesObra', providerField: 'EQUIPES.EQUIPE', customProvider: projectTeam }),
    measurementProject: new Entity({ fieldNameId: 'ID_OBRA', descriptionField: 'description', commandName: 'ConsultarObraMedicao', providerField: 'OBRAS_MEDICOES.OBRA_MEDICAO', customProvider: measurementProject }),
    personBankAccount: new Entity({ fieldNameId: 'ID_CONTA_BANCARIA_PESSOA', descriptionField: 'description', commandName: 'ConsultarContasBancariasPessoa', providerField: 'CONTAS_BANCARIAS_PESSOA.CONTA_BANCARIA_PESSOA', customProvider: personBankAccount }),
    personBankAccountWithInfo: new Entity({ fieldNameId: 'ID_CONTA_BANCARIA_PESSOA', descriptionField: 'description', commandName: 'ConsultarContasBancariasPessoa', providerField: 'CONTAS_BANCARIAS_PESSOA.CONTA_BANCARIA_PESSOA', customProvider: personBankAccountWithInfo }),
    billSaleItemsToIdentify: new Entity({ fieldNameId: 'ID_NF_ENTRADA', descriptionField: 'NUM_NOTA', commandName: 'ConsultarNFEntradaItensAIdentificar', providerField: 'NOTAS_FISCAIS_ITENS_A_IDENTIFICAR.NOTA_FISCAL_ITENS_A_IDENTIFICAR', customProvider: billSaleItemsToIdentify }),
    statusListSearch: new Entity({ fieldNameId: 'ID_STATUS', descriptionField: 'DESC_STATUS', commandName: 'ConsultarListaStatus', providerField: 'LISTA_STATUS_OC.STATUS_OC', customProvider: statusListSearch }),
    contacts: new Entity({ fieldNameId: 'ID_CONTATO', descriptionField: 'description', commandName: 'ConsultarContatosPessoa', providerField: 'CONTATOS.CONTATO', customProvider: contacts }),
    itemTypeWithAll: new Entity({ fieldNameId: 'ID_TIPO_ITEM', descriptionField: 'DESC_TIPO_ITEM', commandName: 'ConsultarTelaDadosTiposItens', providerField: 'TIPOS_ITENS.TIPO_ITEM', customProvider: itemType }),
    listingsWithAll: new Entity({ fieldNameId: 'ID_LISTAGEM', descriptionField: 'DESC_LISTAGEM', commandName: 'ConsultarTelaDadosListagens', providerField: 'LISTAGENS.LISTAGEM', customProvider: listings }),
    contractStatusWithAll: new Entity({ fieldNameId: 'ID_STATUS_CONTRATO', descriptionField: 'DESC_STATUS_CONTRATO', commandName: 'ConsultarStatusContratos', providerField: 'LISTA_STATUS_CONTRATO.STATUS_CONTRATO', customProvider: contractStatus }),
    projectContract: new Entity({ fieldNameId: 'ID_ORCAMENTO', descriptionField: 'description', commandName: 'ContratosOrcamentosObras', providerField: 'CONTRATOS_OBRA.CONTRATO_OBRA', customProvider: projectContract }),
    billSaleItemsToIdentifyCompleteDescription: new Entity({ fieldNameId: 'ID_NF_ENTRADA', descriptionField: 'description', commandName: 'ConsultarNFEntradaItensAIdentificar', providerField: 'NOTAS_FISCAIS_ITENS_A_IDENTIFICAR.NOTA_FISCAL_ITENS_A_IDENTIFICAR', customProvider: billSaleItemsToIdentify }),
    budgetStatusWithAll: new Entity({ fieldNameId: 'ID_STATUS_ORCAMENTO', descriptionField: 'DESC_STATUS_ORCAMENTO', commandName: 'ConsultarStatusOrcamentos', providerField: 'LISTA_STATUS_ORCAMENTO.STATUS_ORCAMENTO', customProvider: budgetStatusWithAll }),
    projectStatusWithAll: new Entity({ fieldNameId: 'ID_STATUS', descriptionField: 'DESC_STATUS', commandName: 'ConsultarStatusObra', providerField: 'STATUS_OBRA.STATUS', customProvider: projectStatusWithAll }),
    scheduleStatus: new Entity({ fieldNameId: 'ID_STATUS_AGENDAMENTO', descriptionField: 'DESC_STATUS_AGENDAMENTO', commandName: 'ConsultarStatusAgendamento', providerField: 'LISTA_STATUS.STATUS_AGENDAMENTO', customProvider: scheduleStatus }),
    citiesInstancesCi: new Entity({ fieldNameId: 'KEY', descriptionField: 'VALUE', commandName: 'ConsultarCidadesInstanciasCidadeIluminada', providerField: 'CIDADES_INSTANCIAS.CIDADE_INSTANCIA', customProvider: citiesInstances }),
    configIlluminatedCity: new Entity({ fieldNameId: 'KEY', descriptionField: 'VALUE', commandName: 'ConsultarConfiguracoesVisuais', providerField: 'CONFIGURACOES.CONFIGURACAO', customProvider: configIlluminatedCity }),
    customStockMovementReason: new Entity({ fieldNameId: 'COD_MOTIVO_MOV_ESTOQUE', descriptionField: 'description', commandName: 'ConsultarMotivosMovEstoque', providerField: 'MOTIVOS_MOV_ESTOQUE.MOTIVO_MOV_ESTOQUE', customProvider: detailedStockMovementReason, params: { ENTIDADE: '_entity', TRAZER_NOMES_CENTROS_ESTOQUES_ORIGEM_DESTINO: 0 } }),
    issueTypeSameValueAsPrevius: new Entity({ fieldNameId: 'ID_TIPO_OCORRENCIA', descriptionField: 'DESC_TIPO_OCORRENCIA', commandName: 'ConsultarTipoOcorrencia', providerField: 'TIPOS_OCORRENCIA.TIPO_OCORRENCIA', params: { SOMENTE_PREVENTIVAS: 0, HABILITADO_PARQUE: 1, SOMENTE_ATRASADAS: 0, SOMENTE_NAO_PREVENTIVAS: 0, HABILITADO_CALLCENTER: 0 }, customProvider: issueType }),
    ombudsmanOriginTypeWithAll: new Entity({ fieldNameId: 'ID_TIPO_ORIGEM_OUVIDORIA', descriptionField: 'DESC_TIPO_ORIGEM_OUVIDORIA', commandName: 'ConsultarTiposOrigemRequisicaoOuvidoria', providerField: 'TIPOS_ORIGEM_OUVIDORIA.TIPO_ORIGEM_OUVIDORIA', customProvider: ombudsmanTypeOrigin }),
    ombudsmanRequestReasonWithAll: new Entity({ fieldNameId: 'ID_MOTIVO_OUVIDORIA', descriptionField: 'DESC_MOTIVO_OUVIDORIA', commandName: 'ConsultarMotivosRequisicaoOuvidoria', providerField: 'MOTIVOS_OUVIDORIA.MOTIVO_OUVIDORIA', customProvider: ombudsmanReason }),
    areaTypesWithAll: new Entity({ fieldNameId: 'ID_TIPO_AREA', descriptionField: 'DESC_TIPO_AREA', commandName: 'ConsultarTiposArea', providerField: 'TIPOS_AREA.TIPO_AREA', customProvider: areaTypes }),
};

export default customProvider;
