export default [
    {
        path: '/workflow',
        title: 'WORKFLOW',
        name: 'Workflow',
        icon: 'fa fa-hard-hat',
        menu: 'ticket',
        component: () => import(/* webpackChunkName: "Workflow" */ /* webpackMode: "lazy" */ '@/pages/workflow/Workflow.vue'),
    },
];
