<template>
    <a-dialog id='alertDialog' :value='dialog' :persistent='true'>
        <a-overlay>
            <a-card max-width='400' :dark='dark' :light='!dark'>
                <a-card-title class='text-h5'>
                    {{title}}
                </a-card-title>
                <a-card-text class='pb-1'>
                    <a-text-field v-model='username' :placeholder='i18n.t("USERNAME")' hide-details class='my-2' />
                    <a-text-field v-model='password' :placeholder='i18n.t("PASSWORD")' type='password' hide-details class='my-2' @keyup.enter='resolve' />
                    <a-row justify='center' class='ma-0'>
                        <a-btn color='danger' style='color: #fff' class='ma-2' @click='close'>
                            {{i18n.t('CANCEL')}}
                        </a-btn>
                        <a-btn color='primary' class='ma-2' @click='resolve'>
                            {{i18n.t('CONFIRM')}}
                        </a-btn>
                    </a-row>
                </a-card-text>
            </a-card>
        </a-overlay>
    </a-dialog>
</template>

<script>
    import i18n from '@/i18n';

    export default {
        props: {
            title: {
                type: String,
                default: undefined,
            },
            payload: {
                type: Array,
                default: function () {
                    return [];
                },
            },
            dark: {
                type: Boolean,
                default: function () {
                    return false;
                },
            },
        },
        data: function () {
            return {
                resolver: null,
                rejector: null,
                dialog: false,
                username: undefined,
                password: undefined,
                i18n: i18n,
            };
        },
        created: function () {
            const { login } = this.$_aura.getUser();
            this.username = login;
        },
        watch: {
            dialog: function () {
                if (!this.dialog) {
                    this.$nextTick(() => {
                        const element = document.getElementById('alertDialog');
                        element.remove();
                    });
                }
            },
        },
        methods: {
            open: function () {
                return new Promise(
                    (resolve, reject) => {
                        this.resolver = resolve;
                        this.rejector = reject;
                        this.dialog = true;
                    },
                );
            },
            close: function () {
                if (this.rejector) {
                    this.rejector();
                    this.dialog = false;
                }
            },
            resolve: function () {
                if (this.resolver) {
                    this.resolver({
                        value: {
                            username: this.username,
                            password: this.password,
                        },
                    });
                    this.dialog = false;
                }
            },
        },
    };
</script>
