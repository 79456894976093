<template lang='pug'>
    a-flex
        a-layout
            a-tabs(v-bind='tabAttrs' :value='activeTab' centered grow)
                a-tab(href='#calendar')
                    a-icon event
                a-tab(href='#timer' :disabled='date == null')
                    a-icon access_time
                a-tab-item(value='calendar')
                    a-date-picker(v-bind='dateAttrs' v-model='date' :min='minDateComp' :max='maxDateComp' @input='$emit("input", inputDate($event))', :locale='$t("LANGUAGE_INITIALS")')
                a-tab-item(value='timer')
                    a-time-picker(v-bind='timeAttrs' v-model='time' :min='minTimeComp' :max='maxTimeComp' @input='$emit("input", [date, $event])')
</template>

<script>
    import moment from 'moment';
    import { getProps } from '../../util/helpers';

    export default {
        name: 'ADateTimePickerExtend',
        components: {
            ADateTimePickerExtend: {
                inheritAttrs: false,
            },
        },
        props: {
            value: {
                type: Array,
                require: false,
                default: function () {
                    return [undefined, undefined];
                },
            },
            min: {
                type: Array,
                require: false,
                default: function () {
                    return [undefined, undefined];
                },
            },
            max: {
                type: Array,
                require: false,
                default: function () {
                    return [undefined, undefined];
                },
            },
        },
        data: function () {
            return {
                activeTab: 'calendar',
                date: null,
                time: null,
            };
        },
        computed: {
            tabAttrs: function () {
                const key = getProps(getProps(this.$attrs, [['v-model', 'a-value', 'a-centered', 'a-grow']], false), [['a-']]);
                const keyNoTag = getProps(this.$attrs, [['d-', 'h-', 'v-model', 'value', 'centered', 'grow', ...Object.keys(key)]], false);
                return this.fixAttr({ ...key, ...keyNoTag });
            },
            dateAttrs: function () {
                const key = getProps(getProps(this.$attrs, [['v-model', 'd-value', 'd-text-field', 'd-min', 'd-max']], false), [['d-']]);
                const keyNoTag = getProps(this.$attrs, [['a-', 'h-', 'v-model', 'value', 'min', 'max', ...Object.keys(key)]], false);
                return this.fixAttr({ ...key, ...keyNoTag });
            },
            timeAttrs: function () {
                const key = getProps(getProps(this.$attrs, [['v-model', 'h-value', 'h-text-field', 'h-min', 'h-max']], false), [['h-']]);
                const keyNoTag = getProps(this.$attrs, [['a-', 'd-', 'v-model', 'value', 'min', 'max', ...Object.keys(key)]], false);
                return this.fixAttr({ ...key, ...keyNoTag });
            },
            minDateComp: function () {
                if (this.min != null && this.min[0] != null) {
                    return this.min[0];
                }
                return undefined;
            },
            maxDateComp: function () {
                if (this.max != null && this.max[0] != null) {
                    return this.max[0];
                }
                return undefined;
            },
            minTimeComp: function () {
                if (this.min != null && this.min[0] != null && this.min[1] != null && this.date === this.min[0]) {
                    return this.min[1];
                }
                return undefined;
            },
            maxTimeComp: function () {
                if (this.max != null && this.max[0] != null && this.max[1] != null && this.date === this.max[0]) {
                    return this.max[1];
                }
                return undefined;
            },
        },
        watch: {
            value: function (e) {
                this.validateValue(e);
            },
        },
        created: function () {
            this.validateValue(this.value);
        },
        methods: {
            validateValue: function (e) {
                let invalid = false;
                if (e != null) {
                    if (e[0] != null && !moment(e[0], 'YYYY-MM-DD', true).isValid()) {
                        e[0] = undefined;
                        invalid = true;
                    }
                    if ((e[0] == null && e[1] != null) || (e[1] != null && !moment(e[1], this.useSeconds ? 'HH:mm:ss' : 'HH:mm', true).isValid())) {
                        e[1] = undefined;
                        invalid = true;
                    }
                }
                if (invalid) {
                    this.$emit('input', [...this.value]);
                }
                this.date = e != null ? e[0] : undefined;
                this.time = e != null ? e[1] : undefined;
            },
            fixAttr: function (value) {
                const obj = { ...value };
                if (Object.keys(obj).indexOf('text-field') !== -1 && obj['text-field'] != null) {
                    obj['text-field'] = '';
                }
                return obj;
            },
            inputDate: function (value) {
                this.activeTab = 'timer';
                return [value, this.time];
            },
        },
    };
</script>
<style lang='scss' scoped>
    .v-picker__title {
        min-height: 102px;
    }

    .v-picker__body {
        min-height: 290px;
    }

</style>
