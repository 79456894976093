const paths = [
    {
        path: '/work-diary-personal-allocation-details',
        title: 'ALLOCATION_DETAILS',
        name: 'PersonalAllocationDetails',
        component: () => import(/* webpackChunkName: "workDiaryErrorFix" */ /* webpackMode: "lazy" */ '@/pages/workDiary/workDiaryErrorFix/PersonalAllocationDetails.vue'),
    },
    {
        path: '/work-diary-personal-allocation-edit',
        title: 'ALLOCATION_EDIT',
        name: 'PersonalAllocationEdit',
        component: () => import(/* webpackChunkName: "workDiaryErrorFix" */ /* webpackMode: "lazy" */ '@/pages/workDiary/workDiaryErrorFix/PersonalAllocationEdit.vue'),
    },
    {
        path: '/work-diary-personal-allocation-conflict',
        title: 'ALLOCATION_CONFLICT',
        name: 'PersonalAllocationConflicts',
        component: () => import(/* webpackChunkName: "workDiaryErrorFix" */ /* webpackMode: "lazy" */ '@/pages/workDiary/workDiaryErrorFix/PersonalAllocationConflicts.vue'),
    },
    {
        path: '/work-diary-collaborator-list',
        title: 'CONTRIBUTOR_SELECTION',
        name: 'CollaboratorList',
        component: () => import(/* webpackChunkName: "workDiaryErrorFix" */ /* webpackMode: "lazy" */ '@/pages/workDiary/workDiaryErrorFix/CollaboratorList.vue'),
    },
    {
        path: '/work-diary-fix-history',
        title: 'CHANGE_HISTORY',
        name: 'PersonalAllocationEditHistory',
        component: () => import(/* webpackChunkName: "workDiaryErrorFix" */ /* webpackMode: "lazy" */ '@/pages/workDiary/workDiaryErrorFix/PersonalAllocationEditHistory.vue'),
    },
];

export default paths;
