const paths = [
    {
        path: '/maintenance-status-list',
        name: 'MaintenanceStatusList',
        title: 'MAINTENANCE_STATUS',
        menu: 'maintenance',
        categories: [
          'configurations',
        ],
        icon: 'fa fa-briefcase',
        component: () => import(/* webpackChunkName: "maintenanceStatus" */ /* webpackMode: "lazy" */ '@/pages/maintenanceStatus/MaintenanceStatusList.vue'),
    },
    {
        path: '/maintenance-status-general',
        name: 'MaintenanceStatusGeneral',
        title: 'MAINTENANCE_STATUS_GENERAL',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "maintenanceStatus" */ /* webpackMode: "lazy" */ '@/pages/maintenanceStatus/MaintenanceStatusGeneral.vue'),

    },
    {
        path: '/maintenance-status',
        name: 'MaintenanceStatus',
        title: 'MAINTENANCE_STATUS',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "maintenanceStatus" */ /* webpackMode: "lazy" */ '@/pages/maintenanceStatus/MaintenanceStatus.vue'),

    },
    {
        path: '/maintenance-status-event-model-general',
        name: 'MaintenanceStatusEventModelGeneral',
        title: 'EVENT_MODEL',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "maintenanceStatus" */ /* webpackMode: "lazy" */ '@/pages/maintenanceStatus/MaintenanceStatusEventModelGeneral.vue'),
    },
    {
        path: '/maintenance-status-event-model-list',
        name: 'MaintenanceStatusEventModelList',
        title: 'EVENT_MODEL_LIST',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "maintenanceStatus" */ /* webpackMode: "lazy" */ '@/pages/maintenanceStatus/MaintenanceStatusEventModelList.vue'),
    },
];

export default paths;
