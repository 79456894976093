const paths = [
    {
        path: '/asset-type-list',
        name: 'AssetTypeList',
        title: 'ASSET_TYPE',
        menu: 'maintenance',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "assetType" */ /* webpackMode: "lazy" */ '@/pages/assetType/AssetTypeList.vue'),
    },
    {
        path: '/asset-type',
        name: 'AssetTypeTabs',
        title: 'ASSET_TYPE',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "assetType" */ /* webpackMode: "lazy" */ '@/pages/assetType/AssetTypeTabs.vue'),
    },
    {
        path: '/asset-type-related-services',
        name: 'AssetTypeRelatedServiceList',
        title: 'RELATED_SERVICES',
        component: () => import(/* webpackChunkName: "assetType" */ /* webpackMode: "lazy" */ '@/pages/assetType/AssetTypeRelatedServiceList.vue'),
    },
    {
        path: '/asset-type-attribute-rule-general',
        name: 'AssetTypeAttributeRuleGeneral',
        title: 'ATTRIBUTE_RULES',
        component: () => import(/* webpackChunkName: "assetType" */ /* webpackMode: "lazy" */ '@/pages/assetType/AssetTypeAttributeRuleGeneral.vue'),
    },
    {
        path: '/asset-type-allowed-attribute-value-general',
        name: 'AssetTypeAllowedAttributeValueGeneral',
        title: 'ALLOWED_ATTRIBUTE_VALUES',
        component: () => import(/* webpackChunkName: "assetType" */ /* webpackMode: "lazy" */ '@/pages/assetType/AssetTypeAllowedAttributeValueGeneral.vue'),
    },
    {
        path: '/asset-type-structure-rule-family-dialog',
        name: 'AssetTypeStructureRuleFamilyDialog',
        title: 'FAMILY',
        component: () => import(/* webpackChunkName: "assetType" */ /* webpackMode: "lazy" */ '@/pages/assetType/AssetTypeStructureRuleFamilyDialog.vue'),
    },
    {
        path: '/asset-type-structure-rule-attribute-dialog',
        name: 'AssetTypeStructureRuleAttributeDialog',
        title: 'ATTRIBUTE',
        component: () => import(/* webpackChunkName: "assetType" */ /* webpackMode: "lazy" */ '@/pages/assetType/AssetTypeStructureRuleAttributeDialog.vue'),
    },
    {
        path: '/asset-type-related-service-dialog',
        name: 'AssetTypeRelatedServiceDialog',
        title: 'RELATED_SERVICES',
        component: () => import(/* webpackChunkName: "assetType" */ /* webpackMode: "lazy" */ '@/pages/assetType/AssetTypeRelatedServiceDialog.vue'),
    },
    {
        path: '/asset-type-generic-item-list',
        name: 'AssetTypeGenericItemList',
        title: 'GENERIC_ITEM_LIST',
        component: () => import(/* webpackChunkName: "assetType" */ /* webpackMode: "lazy" */ '@/pages/assetType/AssetTypeGenericItemList.vue'),
    },
    {
        path: '/asset-type-generic-item',
        name: 'AssetTypeGenericItem',
        title: 'GENERIC_ITEM',
        component: () => import(/* webpackChunkName: "assetType" */ /* webpackMode: "lazy" */ '@/pages/assetType/AssetTypeGenericItem.vue'),
    },
];

export default paths;
