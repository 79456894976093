
<template>
    <v-img v-bind="$attrs" :src='srcImage' v-on="$listeners">
        <slot />
    </v-img>
</template>

<script>
import axios from 'axios';

export default {
    name: 'AImg',
    props: {
        src: {
            type: String,
            required: false,
            default: function () {
                return '';
            },
        },
        params: {
            type: [Object, Array],
            required: false,
            default: function () {
                return {};
            },
        },
    },
    data: function () {
        return {
            srcImage: '',
        };
    },
    watch: {
        src: {
            handler: async function (value) {
                await this.loadImage(value);
            },
        },
    },
    created: async function () {
        await this.loadImage(this.src);
    },
    methods: {
        loadImage: async function (value) {

            if (value.indexOf('auth_token') >= 0) {
                value = value.replace(this.$_aura.getHTTPUrl(), '');

                const urlParams = new URLSearchParams(value);
                urlParams.delete('auth_token');
                const config = {
                    method: 'GET',
                    url: `${this.$_aura.getHTTPUrl()}?${urlParams.toString()}`,
                    headers: {
                        Authorization: this.$_aura.getToken(),
                    },
                    responseType: 'blob',
                };

                const result = await axios(config);
                this.srcImage = URL.createObjectURL(new Blob([result.data]));
            } else if (!value){
                const attributes = Object.keys(this.$attrs);
                attributes.some(attribute => {
                    const commandName = this.$_aura.image.get(attribute);
                    if (commandName) {
                        this.$_aura.callCommand(commandName, this.params, { type: 'file' }).then((resolve) => {
                            this.srcImage = resolve || '';
                        });
                        return true;
                    }                    
                    return false;
                });
            } else {
                this.srcImage = value;
            }
        },
    },
};

</script>
