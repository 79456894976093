const paths = [
    {
        path: '/security-permissions',
        name: 'Permissions',
        title: 'PERMISSIONS',
        menu: 'security',
        component: () => import(/* webpackChunkName: "security" */ /* webpackMode: "lazy" */ '@/pages/security/Permissions.vue'),
    },
    {
        path: '/permissions-commands-add-functionality',
        name: 'PermissionsCommandsAddFunctionality',
        title: 'FUNCTIONALITIES_COMMAND',
        component: () => import(/* webpackChunkName: "security" */ /* webpackMode: "lazy" */ '@/pages/security/permissions/PermissionsCommandsAddFunctionality.vue'),
    },
    {
        path: '/permissions-default-details',
        name: 'PermissionsDefaultDetails',
        component: () => import(/* webpackChunkName: "security" */ /* webpackMode: "lazy" */ '@/pages/security/permissions/PermissionsDefaultDetails.vue'),
    },
    {
        path: '/permissions-system-packages',
        name: 'PermissionsSystemPackages',
        component: () => import(/* webpackChunkName: "security" */ /* webpackMode: "lazy" */ '@/pages/security/permissions/PermissionsSystemPackages.vue'),
    },
    {
        path: '/copy-permissions',
        name: 'CopyPermissions',
        title: 'COPY_PERMISSIONS',
        component: () => import(/* webpackChunkName: "security" */ /* webpackMode: "lazy" */ '@/pages/security/permissions/CopyPermissions.vue'),
    },
    {
        path: '/security',
        name: 'Security',
        title: 'SECURITY',
        menu: 'security',
        component: () => import(/* webpackChunkName: "security" */ /* webpackMode: "lazy" */ '@/pages/security/Security.vue'),
    },
    {
        path: '/permissions-user-password-pattern',
        name: 'PermissionsUserPasswordPattern',
        component: () => import(/* webpackChunkName: "security" */ /* webpackMode: "lazy" */ '@/pages/security/permissions/PermissionsUserPasswordPattern.vue'),
    },
    {
        path: '/permission-consolidated-report-wizard',
        title: 'PERMISSION_CONSOLIDATED_REPORT',
        name: 'PermissionConsolidatedReportWizard',
        component: () => import(/* webpackChunkName: "security" */ /* webpackMode: "lazy" */ '@/pages/security/permissions/PermissionConsolidatedReportWizard.vue'),
    },
];

export default paths;
