const paths = [
    {
        path: '/total-applied-materials',
        name: 'TotalAppliedMaterials',
        title: 'TOTAL_APPLIED_MATERIALS',
        icon: 'mdi-file-document',
        menu: 'maintenance',
        categories: [
          'reports',
        ],
      component: () => import(/* webpackChunkName: "totalAppliedMaterials" */ /* webpackMode: "lazy" */ '@/pages/maintenance/totalAppliedMaterials/TotalAppliedMaterials.vue'),
    },
];

export default paths;
