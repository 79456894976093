export default async function measurementProject (provider) {
    provider.forEach(item => {
        if (item.NUM_OBRA != null) {
            item.description = `${item.NUM_OBRA} - ${item.ID_OBRA} - ${item.DESC_OBRA} - ${item.DESC_STATUS_OBRA}`;
        } else {
            item.description = `${item.ID_OBRA} - ${item.DESC_OBRA} - ${item.DESC_STATUS_OBRA}`;
        }
    });

    return provider;
}
