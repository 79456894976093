import i18n from '@/i18n';

export default function projectStatusWithAll (provider) {
    return new Promise((resolve) => {
        const resultProvider = [];
        resultProvider.push({ ID_STATUS: -1, DESC_STATUS: i18n.t('ALL') });
        resultProvider.push({ ID_STATUS: -2, DESC_STATUS: i18n.t('ALL_ACTIVE') });

        provider.forEach((item) => {
            resultProvider.push({ ID_STATUS: parseInt(item.ID_STATUS, 10), DESC_STATUS: item.DESC_STATUS });
        });
        resolve(resultProvider);
    });
}
