const paths = [
  {
      path: '/contract-list',
      name: 'ContractList',
      icon: 'fa fa-briefcase',
      title: 'CONTRACTS',
      menu: 'contracts',
      component: () => import(/* webpackChunkName: "contract" */ /* webpackMode: "lazy" */ '@/pages/contract/ContractList.vue'),
  },
  {
      path: '/contract',
      name: 'Contract',
      title: 'CONTRACT',
      favCategory: 'contracts',
      component: () => import(/* webpackChunkName: "contract" */ /* webpackMode: "lazy" */ '@/pages/contract/Contract.vue'),
  },
  {
      path: '/contract-data-client-material-supply-by-project',
      name: 'ContractDataClientMaterialSupplyByProject',
      title: 'MATERIAL_SUPPLY_BY_PROJECT',
      favCategory: 'contracts',
      component: () => import(/* webpackChunkName: "contract" */ /* webpackMode: "lazy" */ '@/pages/contract/ContractDataClientMaterialSupplyByProject.vue'),
  },
  {
      path: '/contract-values-billing-items-wizard',
      name: 'ContractValuesBillingItemsWizard',
      title: 'ADD_NEW',
      favCategory: 'contracts',
      component: () => import(/* webpackChunkName: "contract" */ /* webpackMode: "lazy" */ '@/pages/contract/ContractValuesBillingItemsWizard.vue'),
  },
  {
    path: '/contract-data-details-wizard',
    name: 'ContractDataDetailsWizard',
    title: 'GENERATE_CONTRACT',
    favCategory: 'contracts',
    component: () => import(/* webpackChunkName: "contract" */ /* webpackMode: "lazy" */ '@/pages/contract/ContractDataDetailsWizard.vue'),
},
    {
    path: '/contract-data-project-map',
    name: 'ContractDataProjectMap',
    title: 'PROJECTS_MAP',
    favCategory: 'contracts',
    component: () => import(/* webpackChunkName: "contract" */ /* webpackMode: "lazy" */ '@/pages/contract/ContractDataProjectMap.vue'),
    },
];

export default paths;
