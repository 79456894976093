import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import i18n from '@/i18n';
import localStore from '@/pages/remoteManagement/services/store';
import { parseSuccessResponse, showErrorsResponse } from '../utils';
export class GetDeviceStatus extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_device-status',
            type: 'NONE',
            node: 'RESPONSE',
            fields: {
                serialNumber: new Field({
                    name: 'serial_number',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('SERIAL_NUMBER'),
                    },
                }),
                companyId: new Field({
                    name: 'companyId',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('COMPANY'),
                        items: localStore.state.companies || [],
                        itemText: 'company_name',
                        itemValue: 'company_id',
                        clearable: true,
                    },
                }),
                minDate: new Field({
                    name: 'min_date',
                    component: 'ADatePicker',
                    props: {
                        'text-field': true,
                        label: i18n.t('MIN_DATE'),
                    },
                }),
                maxDate: new Field({
                    name: 'max_date',
                    component: 'ADatePicker',
                    props: {
                        'text-field': true,
                        label: i18n.t('MAX_DATE'),
                    },
                }),
                visualizationType: new Field({
                    name: 'visualization_type',
                    component: 'ASelect',
                    value: 'last-status-by-device',
                    props: {
                        label: i18n.t('VISUALIZATION_TYPE'),
                        items: [
                            { name: i18n.t('LAST_STATUS_BY_DEVICE'), value: 'last-status-by-device' },
                            { name: i18n.t('ALL_DEVICES_STATUS'), value: 'all-devices-status' },
                        ],
                        itemText: 'name',
                        itemValue: 'value',
                        clearable: false,
                    },
                }),
                limit: new Field({
                    name: 'limit',
                }),
            },
            customRequestParser: function (params) {
                const filter = {
                    serial_number: params.serialNumber || null,
                    visualization_type: params.visualizationType || null,
                    company_id: params.companyId || null,
                    min_date: params.minDate || null,
                    max_date: params.maxDate || null,
                    limit: params.limit || null,
                };
                return {
                    BODY: `searchFilter=${encodeURIComponent(JSON.stringify(filter))}`,
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetLampStateAverageTime extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarTempoMedioLuminarias',
            type: 'SEARCH',
            node: 'ITEMS.ITEM',
            fields: {
                initialDate: new Field({
                    name: 'initial_date',
                    component: 'ADatePicker',
                    props: {
                        'text-field': true,
                        label: i18n.t('INITIAL_DATE'),
                    },
                }),
                finalDate: new Field({
                    name: 'final_date',
                    component: 'ADatePicker',
                    props: {
                        'text-field': true,
                        label: i18n.t('ENDING_DATE'),
                    },
                }),
                reference: new Field({
                    name: 'reference',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('REFERENCE'),
                    },
                }),
                lampDesc: new Field({
                    name: 'LAMP_DESC',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('LAMP_DESCRIPTION'),
                    },
                }),
                identification: new Field({
                    name: 'IDENTIFICACAO',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('IDENTIFICATION'),
                    },
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
