const paths = [
    {
        path: '/light-spot-report',
        name: 'LightSpotReport',
        title: 'LIGHT_SPOTS',
        icon: 'fas fa-chart-pie',
        menu: 'asset',
        categories: [
          'reports',
        ],
        component: () => import(/* webpackChunkName: "lightSpot" */ /* webpackMode: "lazy" */ '@/pages/lightSpot/LightSpotReport.vue'),
    },
];

export default paths;
