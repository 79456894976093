const paths = [
    {
        path: '/menu-template-list',
        name: 'MenuTemplateList',
        icon: 'fas fa-upload',
        title: 'MENU_TEMPLATE_PLURAL',
        menu: 'customization',
        component: () => import(/* webpackChunkName: "menu" */ /* webpackMode: "lazy" */ '@/pages/menu/MenuTemplateList.vue'),
    },
    {
        path: '/menu-template',
        name: 'MenuTemplate',
        title: 'NEW_MENU_TEMPLATE',
        component: () => import(/* webpackChunkName: "menu" */ /* webpackMode: "lazy" */ '@/pages/menu/MenuTemplate.vue'),
    },
    {
        path: '/menu-template-bulk-apply',
        name: 'MenuTemplateBulkApply',
        title: 'APPLY_MENU_TEMPLATE_IN_BULK',
        component: () => import(/* webpackChunkName: "menu" */ /* webpackMode: "lazy" */ '@/pages/menu/MenuTemplateBulkApply.vue'),
    },
];

export default paths;
