import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import { showErrorsResponse, parseSuccessResponse } from '@/pages/remoteManagement/services/utils';
export class GetScheduleActionModes extends CallCommand {
    constructor() {
        super({
            name: 'get_schedule-action_action-modes',
            type: 'NONE',
            fields: {},
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class GetScheduleActionTypes extends CallCommand {
    constructor() {
        super({
            name: 'get_schedule-action_action-types',
            type: 'NONE',
            fields: {},
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class GetScheduleActionOperations extends CallCommand {
    constructor() {
        super({
            name: 'get_schedule-action_action-operations',
            type: 'NONE',
            fields: {},
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class SaveScheduleActions extends CallCommand {
    constructor() {
        super({
            name: 'put_schedule-action_replace-all_0',
            type: 'SAVE',
            fields: {
                scheduleWindowId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${value.toString()} }`,
                }),
                scheduleActions: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
