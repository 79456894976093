<script>
    export default {
        name: 'ABtnSettings',
        functional: true,
        render: function (createElement, { data, parent, children }) {
            delete data.attrs.tablesearch;
            data.attrs.color = data.attrs.color || 'tertiary';
            data.attrs._iconType = 'tablesearch';
            children = children || [];
            if ((!data.scopedSlots || !data.scopedSlots['tooltip-body']) && !data.attrs['_tooltip-text']) {
                data.attrs['_tooltip-text'] = parent.$t('TABLE_SEARCH');
            }
            return createElement('ABtnDefault', data, children);
        },
    };
</script>
