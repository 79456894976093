import Vue from 'vue';
import i18n from '@/i18n';
import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
const { $select, $util, } = Vue.prototype;
export class GetCashFlow extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarFluxoCaixa',
            type: 'SEARCH',
            node: 'AGENDAMENTOS.AGENDAMENTO',
            fields: {
                accountFinanceId: new Field({
                    name: 'ID_CONTA_FINANC',
                }),
                accountFinance: new Field({
                    name: 'CONTAS_FINANCEIRAS',
                    component: () => import('@components/financialAccount/FinancialAccountSelect.vue'),
                    parser: (value) => {
                        if (value) {
                            const mapped = value.map((elem) => elem.ID_CENTRO_FINANC);
                            return mapped.length > 0 ? mapped.join(',') : null;
                        }
                        return null;
                    },
                    props: {},
                }),
                initialDate: new Field({
                    name: 'DATA_INICIO',
                }),
                endDate: new Field({
                    name: 'DATA_FIM',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('DATE_TO'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                withoutAccount: new Field({
                    name: 'SEM_CONTA',
                }),
                singleAccont: new Field({
                    name: 'CONTA_UNICA',
                }),
                searchType: new Field({
                    name: 'TIPO_CONSULTA',
                    component: 'ASelect',
                    value: 0,
                    props: {
                        label: i18n.t('PERIODICITY'),
                        entity: $select.cashFlowPeriodicity,
                        clearable: false,
                        'clear-provider': true,
                    },
                }),
                selectionMonth: new Field({
                    name: 'MES_SELECAO',
                }),
                performed: new Field({
                    name: 'REALIZADAS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
