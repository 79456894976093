<template>
    <a-app-bar class='overflow-hidden mb-2' flat dense>
        <a-toolbar-title>{{title}}</a-toolbar-title>
        <a-spacer />
        <slot />
        <template v-if='$slots.options'>
            <a-menu v-model='menu' class='ml-1' :close-on-content-click='false' offset-y>
                <template v-slot:activator='{ on }'>
                    <a-btn icon small depressed='depressed' v-on='on'>
                        <a-icon small>
                            fa fa-ellipsis-v
                        </a-icon>
                    </a-btn>
                </template>
                <a-list class='pa-1' dense>
                    <slot name='options' />
                </a-list>
            </a-menu>
        </template>
    </a-app-bar>
</template>

<script>
    export default {
        name: 'APageToolbar',
        props: {
            title: {
                type: String,
                default: 'PAGE_TITLE',
            },
        },
        data: function () {
            return {
                menu: false,
            };
        },
    };
</script>
