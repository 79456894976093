<template>
    <a-container v-resize='onResize' class='container' style='width: 100%; height: 100%;'>
        <a-dynamic-command-filter
            ref='filter'
            :key='getAttendanceDeadlinePriority.name'
            filter-group='all'
            exhibition-mode='sidebar'
            :_fab='false'
            class='pivot-filter'
            :_command='getAttendanceDeadlinePriority'
            :_sidebar-props='{ _hideActions: true }'
            :_loading.sync='loading'
            @updateValues='updateDashData'
        />
        <a-row no-gutters>
            <a-btn
                v-if='!showSelectionChart && tabMode'
                fab
                icon
                @click='changeTab(true)'
            >
                <a-icon>mdi-arrow-right</a-icon>
            </a-btn>
            <a-btn
                v-if='showSelectionChart && tabMode'
                fab
                icon
                @click='changeTab(false)'
            >
                <a-icon>mdi-arrow-left</a-icon>
            </a-btn>
        </a-row>
        <a-row style='width: 100%; height: 80%;'>
            <a-col
                v-show='!showSelectionChart || !tabMode'
                :xl='!tabMode ? "6" : "12"'
                cols='12'
            >
                <chart
                    ref='attendanceChart'
                    :_options='attendanceChartOptions'
                />
            </a-col>
            <a-col
                v-show='showSelectionChart || !tabMode'
                :xl='!tabMode ? "6" : "12"'
                cols='12'
            >
                <chart
                    ref='issueChart'
                    :_options='issueChartOptions'
                />
            </a-col>
        </a-row>
    </a-container>
</template>

<script>
    import { GetAttendanceDeadlinePriority } from './services/request';

    export default {
        name: 'AttendanceDeadlinePerPriorityChart',
        props: {
            /**
             * Prop to show if page is currently active
             * @type {Boolean}
             * @default {false}
            */
            _activePage: {
                type: Boolean,
                default: false,
            },
            /**
             * Command filter values sent from the Dashboard
             * @type {Object}
             * @default {{ filters: [], }}
             */
            _dashdata: {
                type: Object,
                default: () => ({
                    filters: [],
                }),
            },
            /**
             * Prop to determine if the chart is being used from the dashboard
             * Set to false if the component is being used outside of it
             * @type {Boolean}
             * @default {true}
             */
            _dashboardMode: {
                type: Boolean,
                default: true,
            },
            /**
             * Prop to sync command filters
             * @type {Array}
             * @default {[]}
             */
            _filters: {
                type: Array,
                default: () => ([]),
            },
            /**
             * sync with parent loading
             * @type {Boolean}
             * @default {false}
             */
            _loading: {
                type: Boolean,
                default: false,
            },
            /**
             * sync with parent empty-data
             * @type {Boolean}
             * @default {false}
             */
            _emptyData: {
                type: Boolean,
                default: false,
            },
        },
        data: function () {
            return {
                dashdata: this._dashdata,
                reportData: [],

                issueData: [],
                issueChartData: [],
                showSelectionChart: false,
                selected: null,
                filters: [],

                // Chart instance, used for the on() event
                attendanceChart: undefined,

                // Default chart before it's loaded with data
                attendanceChartOptions: {
                    maintainAspectRatio: true,
                    tooltipchartOptions: {
                        trigger: 'item',
                        confine: true,
                    },
                },
                issueChartOptions: {
                    maintainAspectRatio: true,
                    tooltipchartOptions: {
                        trigger: 'item',
                        confine: true,
                    },
                },

                total: 0,
            };
        },
        computed: {
            getAttendanceDeadlinePriority: function () {
                const command = new GetAttendanceDeadlinePriority();
                command.initializeLoading = false;
                command.onSuccess = this.processRequest;
                return command;
            },
            tabMode: function () {
                return this._dashboardMode || this.$vuetify.breakpoint.lgAndDown;
            },
            loading: {
                get: function () {
                    return this._loading;
                },
                set: function (value) {
                    this.$emit('update:_loading', value);
                },
            },
            emptyData: {
                get: function () {
                    return this._emptyData;
                },
                set: function (value) {
                    this.$emit('update:_emptyData', value);
                },
            },
        },
        watch: {
            dashdata: {
                deep: true,
                handler: function (value) {
                    this.$emit('update-dashdata', value);
                },
            },
            _activePage: {
                handler: function () {
                    this.onResize();
                },
            },
            _filters: {
                immediate: true,
                handler: function (value) {
                    if (value) {
                        this.$util.loadCommandFilter(this.getAttendanceDeadlinePriority, value);
                    }
                },
            },
            filters: {
                deep: true,
                handler: function (val) {
                    this.$emit('update:_filters', val);
                },
            },
        },
        mounted: function () {
            this.load();
        },
        methods: {
            onResize: function () {
                if (this.tabMode) {
                    if (this.showSelectionChart) {
                        this.$nextTick(this.$refs.issueChart.resize);
                    } else {
                        this.$nextTick(this.$refs.attendanceChart.resize);
                    }
                } else {
                    this.$nextTick(this.$refs.issueChart.resize);
                    this.$nextTick(this.$refs.attendanceChart.resize);
                }
            },
            changeTab: function (value) {
                this.showSelectionChart = value;
                this.$nextTick(this.onResize);
            },
            load: async function () {
                this.onResize();
                this.attendanceChart = this.$refs.attendanceChart.getChart();

                if (this.dashdata.filters.length !== 0) {
                    this.$util.loadCommandFilter(this.getAttendanceDeadlinePriority, this.dashdata.filters);
                    this.$refs.filter.refresh(true);
                }
            },
            updateDashData: function (items) {
                this.emptyData = !items.length;
                // if the page is being used by the dashboard, update the filters
                if (this._dashboardMode) {
                    this.dashdata.filters = this.$util.getFiltersByCommand(this.getAttendanceDeadlinePriority);
                }
                this.loadData(items);
            },
            loadData: async function (items) {
                this.formatToChart(items);
                this.loadAttendanceChart();
                this.attendanceChart.on('click', { seriesIndex: 0 }, this.loadIssueChart);
            },
            formatToChart: function (data) {
                this.reportData = [];
                Object.keys(data).forEach((i) => {
                    const item = data[i];
                    item.name = item.DESC_PRIORIDADE_PONTO_OCORR;
                    item.value = item.TOTAL_ABSOLUTO;

                    this.reportData.push(item);
                });
            },
            loadAttendanceChart: function () {
                this.$refs.attendanceChart.reset();
                this.$refs.issueChart.reset();
                this.addAttendanceSerie();
                this.onResize();
            },
            addAttendanceSerie: function () {
                this.$refs.attendanceChart.addSerie({
                    id: 1,
                    type: 'pie',
                    data: this.reportData,

                    animation: true,
                    left: 'left',
                    top: 'middle',
                    radius: '70%',
                    width: '100%',
                    height: '100%',

                    label: {
                        show: true,
                        formatter: this.attendanceLabelFormatter,
                        color: '#000000',
                    },

                    selectedMode: 'single',
                    selectedOffset: 25,
                });
            },
            attendanceLabelFormatter: function (params) {
                const { data } = params;
                const description = data.DESC_PRIORIDADE_PONTO_OCORR;
                const total = data.TOTAL_ABSOLUTO;
                const percentage = (Number(data.TOTAL_ABSOLUTO) / this.total) * 100;
                return `${description}\n${total} (${this.$util.numberFormat(percentage)} %)`;
            },

            formatToIssueChart: function (code) {
                const item = this.issueData.find((elem) => elem.SIGLA_PRIORIDADE_PONTO_OCORR.trim() === code);
                const issueChartData = [];
                if (Number(item.SEM_ATENDIMENTO) > 0) {
                    issueChartData.push({
                        name: this.$t('WITHOUT_ENTITY', { entity: this.$t('ATTENDANCE') }),
                        value: item.SEM_ATENDIMENTO,
                        percentage: item.SEM_ATENDIMENTO_PERCENTUAL,
                    });
                }
                if (Number(item.NO_PRAZO) > 0) {
                    issueChartData.push({
                        name: this.$t('IN_TIME'),
                        value: item.NO_PRAZO,
                        percentage: item.NO_PRAZO_PERCENTUAL,
                    });
                }
                if (Number(item.FORA_PRAZO) > 0) {
                    issueChartData.push({
                        name: this.$t('OUT_OF_TIME'),
                        value: item.FORA_PRAZO,
                        percentage: item.FORA_PRAZO_PERCENTUAL,
                    });
                }
                this.issueChartData = issueChartData;
            },
            loadIssueChart: function (params) {
                this.$refs.issueChart.reset();

                const selected = params.data.SIGLA_PRIORIDADE_PONTO_OCORR.trim();
                if (selected !== this.selected) {
                    this.selected = selected;
                    this.formatToIssueChart(selected);
                    this.addIssueSerie();
                    this.changeTab(true);
                } else {
                    this.selected = null;
                }
            },
            addIssueSerie: function () {
                this.$refs.issueChart.addSerie({
                    id: 1,
                    type: 'pie',
                    data: this.issueChartData,

                    animation: true,
                    left: 'left',
                    top: 'middle',
                    radius: '70%',
                    width: '100%',
                    height: '100%',

                    label: {
                        show: true,
                        formatter: this.issueLabelFormatter,
                        color: '#000000',
                    },
                });
            },
            issueLabelFormatter: function (params) {
                const { name, value, percentage } = params.data;
                return `${name}\n${value} (${percentage}%)`;
            },
            processRequest: function (resolve) {
                this.filters = this.$util.getFiltersByCommand(this.getAttendanceDeadlinePriority);

                this.issueData = this.$util.getArrayByNode(resolve, 'OCORRENCIAS.OCORRENCIA');
                const attendances = this.$util.getArrayByNode(resolve, this.getAttendanceDeadlinePriority.node);
                this.total = 0;
                attendances.forEach(elem => {
                    this.total += Number(elem.TOTAL_ABSOLUTO);
                });
                return resolve;
            },
        },
    };
</script>
