const paths = [
    {
        path: '/identified-material-list',
        name: 'IdentifiedMaterialList',
        title: 'IDENTIFIED_LISTING',
        icon: 'widgets',
        menu: 'stock',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "identifiedMaterial" */ /* webpackMode: "lazy" */ '@/pages/identifiedMaterial/IdentifiedMaterialList.vue'),
    },
];

export default paths;
