const paths = [
    {
        path: '/families-list',
        name: 'FamiliesList',
        title: 'FAMILIES',
        icon: 'widgets',
        menu: 'stock',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "family" */ /* webpackMode: "lazy" */ '@/pages/family/FamiliesList.vue'),
    },
    {
        path: '/family',
        name: 'Family',
        title: 'FAMILY',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "family" */ /* webpackMode: "lazy" */ '@/pages/family/Family.vue'),
    },
    {
        path: '/family-configuration',
        name: 'FamilyConfiguration',
        title: 'FAMILY',
        component: () => import(/* webpackChunkName: "family" */ /* webpackMode: "lazy" */ '@/pages/family/FamilyConfiguration.vue'),
    },
];

export default paths;
