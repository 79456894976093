<script>
    export default {
        name: 'ABtnFax',
        functional: true,
        render: function (createElement, { data, parent, children }) {
            delete data.attrs.fax;
            data.attrs.color = data.attrs.color || 'primary';
            data.attrs._iconType = 'fax';
            data.attrs['color-icon'] = data.attrs['color-icon'] || 'white';
            children = children || [];
            if ((!data.scopedSlots || !data.scopedSlots['tooltip-body']) && !data.attrs['_tooltip-text']) {
                data.attrs['_tooltip-text'] = parent.$t('FAX');
            }
            return createElement('ABtnDefault', data, children);
        },
    };
</script>
