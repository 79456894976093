const paths = [
    {
        path: '/energy-tariff-plan-list',
        name: 'EnergyTariffPlanList',
        title: 'ENERGY_TARIFF_PLAN',
        component: () => import(/* webpackChunkName: "energyTariffPlan" */ /* webpackMode: "lazy" */ '@/pages/energy/energyTariffPlan/EnergyTariffPlanList.vue'),
    },
    {
        path: '/energy-tariff-plan-details',
        name: 'EnergyTariffPlanDetails',
        title: 'ENERGY_TARIFF_PLAN',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "energyTariffPlan" */ /* webpackMode: "lazy" */ '@/pages/energy/energyTariffPlan/EnergyTariffPlanDetails.vue'),
    },
];

export default paths;
