const paths = [
    {
        path: '/generate-asset-ticket',
        name: 'GenerateAssetTicket',
        title: 'GENERATE',
        component: () => import(/* webpackChunkName: "generateAssetTicket" */ /* webpackMode: "lazy" */ '@/pages/asset/generateAssetTicket/GenerateAssetTicket.vue'),
    },
    {
        path: '/asset-type-select',
        name: 'AssetTypeSelect',
        title: 'SELECT_ASSET_TYPES',
        component: () => import(/* webpackChunkName: "generateAssetTicket" */ /* webpackMode: "lazy" */ '@/pages/asset/generateAssetTicket/AssetTypeSelect.vue'),
    },
];

export default paths;
