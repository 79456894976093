import getLocale from '@/lang/leafletDraw/leafletLocale';

export default {
    install: function (vue) {
        require('leaflet').default;
        require('leaflet-draw').default;

        vue.mixin({
            watch: {
                '$i18n.locale': {
                    immediate: true,
                    handler: function () {
                        L.drawLocal = getLocale();
                    },
                },
            },
        });
    },
};
