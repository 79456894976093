export default {
    CHART_COLORS: [
        '#C1C1C1',
        '#52BE7F',
        '#F9A726',
        '#1D62FF',
        '#1DCAD4',
    ],
    CHART_LINE_WIDTH: 2.5,
};
