const paths = [
    {
        path: '/subdivision-config',
        name: 'SubdivisionConfig',
        title: 'SUBDIVISION_CONFIG',
        icon: 'fa fa-map-marked',
        menu: 'analysis',
        component: () => import(/* webpackChunkName: "subdivisionConfig" */ /* webpackMode: "lazy" */ '@/pages/subdivision/subdivisionConfig/SubdivisionConfig.vue'),
    },
    {
        path: '/subdivision-config-status',
        name: 'SubdivisionConfigStatus',
        title: 'SUBDIVISION_CONFIG_STATUS',
        component: () => import(/* webpackChunkName: "subdivisionConfig" */ /* webpackMode: "lazy" */ '@/pages/subdivision/subdivisionConfig/SubdivisionConfigStatus.vue'),
    },
    {
        path: '/subdivision-status-color',
        name: 'SubdivisionStatusColor',
        title: 'SUBDIVISION_STATUS_COLOR',
        component: () => import(/* webpackChunkName: "subdivisionConfig" */ /* webpackMode: "lazy" */ '@/pages/subdivision/subdivisionConfig/SubdivisionStatusColor.vue'),
    },
    {
        path: '/subdivision-config-entity-list',
        name: 'SubdivisionConfigEntityList',
        title: 'SUBDIVISION_CONFIG_ENTITY_LIST',
        component: () => import(/* webpackChunkName: "subdivisionConfig" */ /* webpackMode: "lazy" */ '@/pages/subdivision/subdivisionConfig/SubdivisionConfigEntityList.vue'),
    },
    {
        path: '/subdivision-config-entity-general',
        name: 'SubdivisionConfigEntityGeneral',
        title: 'SUBDIVISION_CONFIG_ENTITY_GENERAL',
        component: () => import(/* webpackChunkName: "subdivisionConfig" */ /* webpackMode: "lazy" */ '@/pages/subdivision/subdivisionConfig/SubdivisionConfigEntityGeneral.vue'),
    },
    {
        path: '/subdivision-type-sample-config',
        name: 'SubdivisionTypeSampleConfig',
        title: 'SUBDIVISION_TYPE_SAMPLE_CONFIG',
        component: () => import(/* webpackChunkName: "subdivisionConfig" */ /* webpackMode: "lazy" */ '@/pages/subdivision/subdivisionConfig/SubdivisionTypeSampleConfig.vue'),
    },
    {
        path: '/subdivision-type-sample-config-general',
        name: 'SubdivisionTypeSampleConfigGeneral',
        title: 'SUBDIVISION_TYPE_SAMPLE_CONFIG_GENERAL',
        component: () => import(/* webpackChunkName: "subdivisionConfig" */ /* webpackMode: "lazy" */ '@/pages/subdivision/subdivisionConfig/SubdivisionTypeSampleConfigGeneral.vue'),
    },
    {
        path: '/subdivision-config-entity-status-link',
        name: 'SubdivisionConfigEntityStatusLink',
        title: 'SUBDIVISION_TYPE_CONFIG_ENTITY_STATUS_LINK',
        component: () => import(/* webpackChunkName: "subdivisionConfig" */ /* webpackMode: "lazy" */ '@/pages/subdivision/subdivisionConfig/SubdivisionConfigEntityStatusLink.vue'),
    },
    {
        path: '/subdivision-config-entity-status-link-general',
        name: 'SubdivisionConfigEntityStatusLinkGeneral',
        title: 'SUBDIVISION_TYPE_CONFIG_ENTITY_STATUS_LINK_GENERAL',
        component: () => import(/* webpackChunkName: "subdivisionConfig" */ /* webpackMode: "lazy" */ '@/pages/subdivision/subdivisionConfig/SubdivisionConfigEntityStatusLinkGeneral.vue'),
    },
];
export default paths;
