const paths = [
  {
    path: '/energy-consumption-per-period-report',
    name: 'EnergyConsumptionPerPeriodReport',
    title: 'ENERGY_CONSUMPTION_PER_PERIOD_REPORT',
    icon: 'mdi-file-document',
    menu: 'report',
    component: () => import(/* webpackChunkName: "reportEnergyConsumption" */ /* webpackMode: "lazy" */ '@/pages/report/energyConsumption/EnergyConsumptionPerPeriodReport.vue'),
  },
];

export default paths;
