import theme from '@style/theme';

export default {
    loadedDomain: function (domains, dataProvider) {
        const newDomains = [];
        domains.forEach(el => {
            const key = el.userId;
            const domain = el.domainId;
            if (domain && key && domain.length && domain.some(value => value && (typeof value === 'string' || typeof value === 'number'))) {
                const domainList = [];
                domain.forEach(value => {
                    const obj = dataProvider.find(element => String(element.ID_DOMINIO) === String(value));
                    if (obj) {
                        domainList.push(obj);
                    }
                });
                newDomains.push({
                    userId: key,
                    domainId: domainList,
                });
            }
        });
        return newDomains;
    },
    setThemeDomain: function (themes, user, domain) {
        if (user && user.personId) {
            if (domain) {
                themes.light.primary = domain.COR_DOMINIO || theme.light.primary;
                return;
            }
        }
        themes.light.primary = theme.light.primary;
    },
};
