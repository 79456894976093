import i18n from '@/i18n';

export default function budgetStatusWIthAll (provider) {
    return new Promise((resolve) => {
        const resultProvider = [];
        resultProvider.push({ ID_STATUS_ORCAMENTO: -1, DESC_STATUS_ORCAMENTO: i18n.t('ALL') });
        resultProvider.push({ ID_STATUS_ORCAMENTO: -2, DESC_STATUS_ORCAMENTO: i18n.t('ALL_ACTIVE') });

        provider.forEach((item) => {
            resultProvider.push({ ID_STATUS_ORCAMENTO: parseInt(item.ID_STATUS_ORCAMENTO, 10), DESC_STATUS_ORCAMENTO: item.DESC_STATUS_ORCAMENTO });
        });

        resolve(resultProvider);
    });
}
