const paths = [
    {
        path: '/identification-process-list',
        name: 'IdentificationProcessList',
        title: 'IDENTIFICATION_PROCESS',
        icon: 'fa fa-shopping-basket',
        menu: 'stock',
        component: () => import(/* webpackChunkName: "purchaseOrder" */ /* webpackMode: "lazy" */ '@/pages/identificationProcess/IdentificationProcessList.vue'),
    },
    {
        path: '/identification-process-1',
        name: 'IdentificationProcess',
        title: 'IDENTIFICATION_PROCESS',
        favCategory: 'stock',
        component: () => import(/* webpackChunkName: "purchaseOrder" */ /* webpackMode: "lazy" */ '@/pages/identificationProcess/IdentificationProcess.vue'),
    },
    {
        path: '/mass-identification',
        name: 'MassIdentification',
        title: 'MASS_IDENTIFICATION',
        component: () => import(/* webpackChunkName: "assets" */ /* webpackMode: "lazy" */ '@/pages/identificationProcess/MassIdentificationWizard.vue'),
    },
];

export default paths;
