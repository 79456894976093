import Vue from 'vue';
import i18n from '@/i18n';
import state from './state';
const getters = {
    // get device-group name
    getDeviceGroup: function (device_group_id) {
        if (!device_group_id) {
            return i18n.t('UNDEFINED');
        }
        if (!state.deviceGroups) {
            return device_group_id;
        }
        const deviceGroup = state.deviceGroups
            .find((dg) => dg.device_group_id === device_group_id);
        if (deviceGroup?.group_desc) {
            return deviceGroup.group_desc;
        }
        return i18n.t('DEVICE_GROUP_IS_DELETED');
    },
    // get schedule name
    getSchedule: function (schedule_id) {
        if (!schedule_id) {
            return i18n.t('UNDEFINED');
        }
        if (!state.schedules) {
            return schedule_id;
        }
        const schedule = state.schedules
            .find((s) => s.schedule_id === schedule_id);
        if (schedule) {
            return schedule.schedule_desc;
        }
        return i18n.t('DEVICE_SCHEDULE_NOT_FOUND');
    },
    // get measurement_config description
    getMeasurementConfig: function (measurement_config_id) {
        if (!measurement_config_id) {
            return i18n.t('UNDEFINED');
        }
        if (!state.measurementConfigs) {
            return measurement_config_id;
        }
        const measurementConfig = state.measurementConfigs
            .find((m) => m.measurement_config_id === measurement_config_id);
        if (measurementConfig) {
            return measurementConfig.measurement_config_desc;
        }
        return i18n.t('DEVICE_MEASUREMENT_CONFIG_NOT_FOUND');
    },
    // get schedule name
    getCommandTypeName: function (commandTypeNumber) {
        if (!commandTypeNumber) {
            return i18n.t('UNDEFINED');
        }
        if (!state.commandTypes) {
            return commandTypeNumber;
        }
        const name = Vue.prototype.$lodash.invert(state.commandTypes)[commandTypeNumber];
        return name;
    },
    getAlertTypeDescription: function ({ alert_type_id, hardware_alert_type_id }) {
        if (state.alertTypes && alert_type_id !== null) {
            const type = state.alertTypes
                .find((a) => a.alert_type_id === alert_type_id);
            return type ? type.description : i18n.t('UNDEFINED');
        }
        if (state.hardwareAlertTypes && hardware_alert_type_id !== null) {
            const type = state.hardwareAlertTypes
                .find((a) => a.hardware_alert_type_id === hardware_alert_type_id);
            return type ? type.description : i18n.t('UNDEFINED');
        }
        return i18n.t('UNDEFINED');
    },
};
export default getters;
