// eslint-disable-next-line max-classes-per-file
export class Entity {
    ID_FIELD_NAME;
    DESCRIPTION_FIELD_NAME;
    COMMAND_NAME;
    DATA_PROVIDER_FIELD;
    SEARCH_FIELD;
    PARAMETERS;
    JSON_PROVIDER;
    CUSTOM_FUNCTION;
    SAVE_USER_SELECTION_KEY;
    EDIT_DIALOG;
    MIN_TO_SEARCH;
    constructor(entity) {
        this.ID_FIELD_NAME = entity.fieldNameId;
        this.DESCRIPTION_FIELD_NAME = entity.descriptionField;
        this.COMMAND_NAME = entity.commandName;
        this.DATA_PROVIDER_FIELD = entity.providerField;
        this.SEARCH_FIELD = entity.searchField;
        this.PARAMETERS = entity.params || {};
        this.JSON_PROVIDER = entity.staticProvider;
        this.CUSTOM_FUNCTION = entity.customProvider;
        this.SAVE_USER_SELECTION_KEY = entity.saveUserSelectionKey;
        this.EDIT_DIALOG = entity.editDialog || {};
        this.MIN_TO_SEARCH = entity.minToSearch || 0;
    }
}
export class StaticEntity {
    ID_FIELD_NAME;
    DESCRIPTION_FIELD_NAME;
    JSON_PROVIDER;
    COMMAND_NAME;
    DATA_PROVIDER_FIELD;
    SEARCH_FIELD;
    PARAMETERS;
    CUSTOM_FUNCTION;
    constructor(entity) {
        this.ID_FIELD_NAME = entity.fieldNameId;
        this.DESCRIPTION_FIELD_NAME = entity.descriptionField;
        this.JSON_PROVIDER = entity.staticProvider;
        this.COMMAND_NAME = undefined;
        this.DATA_PROVIDER_FIELD = undefined;
        this.SEARCH_FIELD = undefined;
        this.PARAMETERS = undefined;
        this.CUSTOM_FUNCTION = undefined;
    }
}
