const paths = [
    {
        path: '/file-path',
        name: 'ImageCard',
        title: 'FILE_PATH',
        component: () => import(/* webpackChunkName: "file" */ /* webpackMode: "lazy" */ '@/components/image/ImageCard.vue'),
    },
];

export default paths;
