const paths = [
    {
        path: '/applied-materials-per-contract',
        name: 'AppliedMaterialsPerContract',
        title: 'APPLIED_MATERIALS_CONTRACT_COST',
        icon: 'mdi-file-document',
        menu: 'contracts',
        categories: [
          'reports',
        ],
        component: () => import(/* webpackChunkName: "reportAppliedMaterials" */ /* webpackMode: "lazy" */ '@/pages/report/appliedMaterials/AppliedMaterialsPerContract.vue'),
    },
    {
        path: '/applied-materials-cost',
        name: 'AppliedMaterialsCost',
        title: 'APPLIED_MATERIALS_COST',
        icon: 'mdi-file-document',
        menu: 'maintenance',
        categories: [
          'reports',
        ],
        component: () => import(/* webpackChunkName: "reportAppliedMaterials" */ /* webpackMode: "lazy" */ '@/pages/report/appliedMaterials/AppliedMaterialsCost.vue'),
    },
];

export default paths;
