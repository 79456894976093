const paths = [
    {
        path: '/charge-modality-list',
        name: 'ChargeModalityList',
        title: 'CHARGE_MODALITY',
        icon: 'fa fa-briefcase',
        menu: 'financial',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "chargeModality" */ /* webpackMode: "lazy" */ '@/pages/chargeModality/ChargeModalityList.vue'),
    },
    {
        path: '/charge-modality-general',
        name: 'ChargeModalityGeneral',
        title: 'CHARGE_MODALITY_GENERAL',
        favCategory: 'financial',
        component: () => import(/* webpackChunkName: "chargeModality" */ /* webpackMode: "lazy" */ '@/pages/chargeModality/ChargeModalityGeneral.vue'),
    },
];

export default paths;
