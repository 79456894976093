import Vue from 'vue';
import i18n from '@/i18n';
import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import { parseSuccessResponse, showErrorsResponse } from '../utils';
const { $moment, } = Vue.prototype;
export class GetBoard extends CallCommand {
    constructor() {
        super({
            name: 'get_boards',
            type: 'NONE',
            node: 'RESPONSE',
            fields: {
                include: new Field({
                    name: 'include',
                    value: [
                        { relation: 'model' },
                        {
                            relation: 'tests',
                            scope: {
                                fields: {
                                    board_id: true,
                                },
                            },
                        },
                    ],
                }),
                serialNumber: new Field({
                    name: 'serial_number',
                    component: 'a-text-field',
                    props: {
                        label: i18n.t('SERIAL_NUMBER'),
                    },
                }),
                boardType: new Field({
                    name: 'board_type',
                    component: 'a-select',
                    props: {
                        label: i18n.t('TYPE'),
                        clearable: true,
                    },
                }),
                where: new Field({
                    name: 'where',
                }),
            },
            customRequestParser: function (params) {
                const filter = {
                    include: params.include,
                    where: params.where ?? {},
                };
                if (params.serialNumber) {
                    filter.where.board_serial_number = {
                        like: `${String(params.serialNumber)}%`,
                    };
                }
                if (params.boardType) {
                    filter.where.board_type = params.boardType;
                }
                return {
                    BODY: `filter=${encodeURIComponent(JSON.stringify(filter))}`,
                };
            },
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        resolve.RAIZ.RESPONSE = response;
        return resolve;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetBoardById extends CallCommand {
    constructor() {
        super({
            name: 'get_boards_0',
            type: 'NONE',
            node: 'RESPONSE',
            fields: {
                include: new Field({
                    name: 'include',
                    value: [
                        { relation: 'model' },
                        { relation: 'tests' },
                    ],
                }),
                boardId: new Field({
                    name: 'board_id',
                }),
            },
            customRequestParser: function (params) {
                const filter = {
                    include: params.include,
                };
                return {
                    PATH_PARAMS: JSON.stringify({ PARAM_0: params.boardId }),
                    BODY: `filter=${encodeURIComponent(JSON.stringify(filter))}`,
                };
            },
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        return response;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetBoardTypes extends CallCommand {
    constructor() {
        super({
            name: 'get_boards_board-types',
            type: 'NONE',
            node: 'RESPONSE',
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        return response;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetBoardModels extends CallCommand {
    constructor() {
        super({
            name: 'get_board-model',
            type: 'NONE',
            node: 'RESPONSE',
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        return response;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetNumberOfUuidsAvailableToPrint extends CallCommand {
    constructor() {
        super({
            name: 'get_number-of-uuids-available-to-print',
            type: 'NONE',
            node: 'RESPONSE',
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        return response;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetBoardsTypesAndDevices extends CallCommand {
    constructor() {
        super({
            name: 'get_boards-types-and-devices_0',
            type: 'NONE',
            node: 'RESPONSE',
            fields: {
                boardsIds: new Field({
                    name: 'boards_ids',
                }),
            },
            customRequestParser: function (params) {
                return {
                    PATH_PARAMS: JSON.stringify({ PARAM_0: params.boardsIds }),
                };
            },
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        return response;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveBoards extends CallCommand {
    constructor() {
        super({
            name: 'post_boards',
            type: 'SAVE',
            fields: {
                boards: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class CreateTestUuids extends CallCommand {
    constructor() {
        super({
            name: 'post_test-uuids_0',
            type: 'NONE',
            fields: {
                numberOfUuids: new Field({
                    name: 'number_of_uuids',
                }),
            },
            customRequestParser: function (params) {
                return {
                    PATH_PARAMS: JSON.stringify({ PARAM_0: params.numberOfUuids }),
                };
            },
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        return response;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class GetBoardTests extends CallCommand {
    constructor() {
        super({
            name: 'get_board-tests-by-serial-number',
            type: 'NONE',
            node: 'RESPONSE',
            fields: {
                serialNumber: new Field({
                    name: 'serialNumber',
                    component: 'a-text-field',
                    props: {
                        label: i18n.t('SERIAL_NUMBER'),
                    },
                }),
                limitDate: new Field({
                    name: 'limitDate',
                    component: 'ADateTimePicker',
                    props: {
                        label: i18n.t('LIMIT_DATE'),
                        'text-field': true,
                    },
                    value: $moment().subtract(1, 'day'),
                }),
            },
            customRequestParser: function (params) {
                return {
                    BODY: `limitDate=${encodeURIComponent(new Date(params.limitDate).toISOString())}&serialNumber=${encodeURIComponent(params.serialNumber)}`,
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class UpdateUuidsToPrint extends CallCommand {
    constructor() {
        super({
            name: 'patch_uuids-to-print_0_1',
            type: 'NONE',
            fields: {
                pageQuantity: new Field({
                    name: 'page_quantity',
                }),
                isReprint: new Field({
                    name: 'is_reprint',
                }),
            },
            customRequestParser: function (params) {
                const pathParams = {
                    PARAM_0: params.pageQuantity,
                    PARAM_1: params.isReprint,
                };
                return {
                    PATH_PARAMS: JSON.stringify(pathParams),
                };
            },
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        return response;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class GetFinalStatus extends CallCommand {
    constructor() {
        super({
            name: 'get_0_final-status',
            type: 'NONE',
            node: 'RESPONSE',
            fields: {
                entity: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        return response;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class ResetRunin extends CallCommand {
    constructor() {
        super({
            name: 'post_reset-runin',
            type: 'NONE',
            node: 'RESPONSE',
            fields: {
                serialNumbers: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class RequestBoardTest extends CallCommand {
    constructor() {
        super({
            name: 'post_start-test_0',
            type: 'NONE',
            fields: {
                entity: new Field({
                    name: 'entity',
                }),
            },
            customRequestParser: function (params) {
                return {
                    PATH_PARAMS: JSON.stringify({
                        PARAM_0: params.entity,
                    }),
                };
            },
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        return JSON.parse(response);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class SaveEntityUuid extends CallCommand {
    constructor() {
        super({
            name: 'post_save-entity-uuid_0_1',
            type: 'NONE',
            fields: {
                entity: new Field({
                    name: 'entity',
                }),
                uuid: new Field({
                    name: 'uuid',
                }),
            },
            customRequestParser: function (params) {
                return {
                    PATH_PARAMS: JSON.stringify({
                        PARAM_0: params.entity,
                        PARAM_1: params.uuid ?? 'undefined',
                    }),
                };
            },
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        return response;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class GetEntitiesThatStartedTests extends CallCommand {
    constructor() {
        super({
            name: 'get_entities-that-started-tests',
            type: 'NONE',
            node: 'RESPONSE',
            fields: {
                numberOfEntities: new Field({
                    name: 'numberOfEntities',
                }),
                entityType: new Field({
                    name: 'entityType',
                }),
            },
            customRequestParser: function (params) {
                return {
                    BODY: `numberOfEntities=${encodeURIComponent(params.numberOfEntities)}&entityType=${encodeURIComponent(params.entityType)}`,
                };
            },
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        return response;
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetEntitiesThatClearedInfo extends CallCommand {
    constructor() {
        super({
            name: 'get_entities-that-cleared-info',
            type: 'NONE',
            node: 'RESPONSE',
            fields: {
                numberOfEntities: new Field({
                    name: 'numberOfEntities',
                }),
                entityType: new Field({
                    name: 'entityType',
                }),
            },
            customRequestParser: function (params) {
                return {
                    BODY: `numberOfEntities=${encodeURIComponent(params.numberOfEntities)}&entityType=${encodeURIComponent(params.entityType)}`,
                };
            },
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        return response;
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetEntitiesThatTestsProgressed extends CallCommand {
    constructor() {
        super({
            name: 'get_entities-that-tests-progressed',
            type: 'NONE',
            node: 'RESPONSE',
            fields: {
                numberOfEntities: new Field({
                    name: 'numberOfEntities',
                }),
                entityType: new Field({
                    name: 'entityType',
                }),
            },
            customRequestParser: function (params) {
                return {
                    BODY: `numberOfEntities=${encodeURIComponent(params.numberOfEntities)}&entityType=${encodeURIComponent(params.entityType)}`,
                };
            },
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        return response;
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetEntitiesThatFinishedTests extends CallCommand {
    constructor() {
        super({
            name: 'get_entities-that-finished-tests',
            type: 'NONE',
            node: 'RESPONSE',
            fields: {
                numberOfEntities: new Field({
                    name: 'numberOfEntities',
                }),
                entityType: new Field({
                    name: 'entityType',
                }),
            },
            customRequestParser: function (params) {
                return {
                    BODY: `numberOfEntities=${encodeURIComponent(params.numberOfEntities)}&entityType=${encodeURIComponent(params.entityType)}`,
                };
            },
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        return response;
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetTestStatus extends CallCommand {
    constructor() {
        super({
            name: 'get_0_test-status',
            type: 'NONE',
            node: 'RESPONSE',
            fields: {
                entity: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        return response;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class RuninStatus extends CallCommand {
    constructor() {
        super({
            name: 'get_runin-state',
            type: 'NONE',
            node: 'RESPONSE',
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        return response;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class RequestStop extends CallCommand {
    constructor() {
        super({
            name: 'post_0_request-stop',
            type: 'NONE',
            node: 'RESPONSE',
            fields: {
                entity: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        return response;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class UpdateFixtureStatus extends CallCommand {
    constructor() {
        super({
            name: 'post_0_update-fixture-status',
            type: 'NONE',
            node: 'RESPONSE',
            fields: {
                entity: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        return response;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetRuninMetrics extends CallCommand {
    constructor() {
        super({
            name: 'get_runin-metrics',
            type: 'NONE',
            node: 'RESPONSE',
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        return response;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetBoardsTestsMetrics extends CallCommand {
    constructor() {
        super({
            name: 'get_boards-tests-metrics',
            type: 'NONE',
            fields: {
                boardType: new Field({
                    name: 'board_type',
                }),
            },
            customRequestParser: function (params) {
                return {
                    BODY: `board_type=${encodeURIComponent(params.boardType)}`,
                };
            },
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        return response;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class UpdateMetricsExpectationsDefaults extends CallCommand {
    constructor() {
        super({
            name: 'post_metrics-expectations-defaults',
            type: 'SAVE',
            fields: {
                metricsExpectations: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        return response;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class UpdateFccDefaults extends CallCommand {
    constructor() {
        super({
            name: 'post_fcc-defaults',
            type: 'NONE',
            fields: {
                secret: new Field({
                    name: 'secret',
                }),
                configFile: new Field({
                    name: 'configFile',
                }),
                version: new Field({
                    name: 'version',
                }),
            },
            customRequestParser: function (params) {
                return {
                    BODY: JSON.stringify({
                        secret: params.secret,
                        configFile: params.configFile,
                        version: params.version,
                    }),
                };
            },
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        return response;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class UpdateOtpDefaults extends CallCommand {
    constructor() {
        super({
            name: 'post_otp-defaults',
            type: 'NONE',
            fields: {
                secret_code: new Field({
                    name: 'secret_code',
                }),
                image_version: new Field({
                    name: 'image_version',
                }),
            },
            customRequestParser: function (params) {
                return {
                    BODY: JSON.stringify({
                        secret_code: params.secret_code,
                        image_version: params.image_version,
                    }),
                };
            },
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        return response;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class GetManufactureDefaults extends CallCommand {
    constructor() {
        super({
            name: 'get_manufacture-defaults',
            type: 'NONE',
            node: 'RESPONSE',
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        return response;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetOnlineBoards extends CallCommand {
    constructor() {
        super({
            name: 'get_online-boards_0',
            type: 'NONE',
            node: 'RESPONSE',
            fields: {
                boardType: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        return response;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetBoardLog extends CallCommand {
    constructor() {
        super({
            name: 'get_board-log',
            type: 'NONE',
            node: 'RESPONSE',
            fields: {
                uuidOrSerial: new Field({
                    name: 'uuid_or_serial',
                }),
            },
            customRequestParser: function (params) {
                return {
                    BODY: `uuid_or_serial=${encodeURIComponent(params.uuidOrSerial)}`,
                };
            },
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        return response;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetManufactureProgress extends CallCommand {
    constructor() {
        super({
            name: 'get_manufacture-progress',
            type: 'NONE',
            node: 'RESPONSE',
            fields: {
                timestampInMilliseconds: new Field({
                    name: 'timestampInMilliseconds',
                }),
            },
            customRequestParser: function (params) {
                return {
                    BODY: `timestamp_in_milliseconds=${encodeURIComponent(params.timestampInMilliseconds)}`,
                };
            },
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        return response;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
