const paths = [
    {
        path: '/holidayCalendar',
        name: 'HolidayCalendar',
        title: 'HOLIDAY_CALENDAR',
        icon: 'fa fa-briefcase',
        menu: 'humanResources',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "holidaysCalendar" */ /* webpackMode: "lazy" */ '@/pages/holidaysCalendar/HolidaysCalendar.vue'),
    },
    {
      path: '/holidayCalendarDetais',
      name: 'HolidaysCalendarDetails',
      title: 'HOLIDAY_CALENDAR_DETAILS',
      icon: 'fa fa-briefcase',
      component: () => import(/* webpackChunkName: "holidaysCalendar" */ /* webpackMode: "lazy" */ '@/pages/holidaysCalendar/HolidaysCalendarDetails.vue'),
    },
    {
        path: '/holidayCalendarModel',
        name: 'HolidayCalendarModel',
        title: 'HOLIDAY_CALENDAR_MODEL',
        icon: 'fa fa-briefcase',
        menu: 'humanResources',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "holidaysCalendar" */ /* webpackMode: "lazy" */ '@/pages/holidaysCalendar/HolidaysCalendarModel.vue'),
    },
    {
        path: '/holidayCalendarModelDetais',
        name: 'HolidaysCalendarModelDetails',
        title: 'HOLIDAY_CALENDAR_MODEL_DETAILS',
        icon: 'fa fa-briefcase',
        component: () => import(/* webpackChunkName: "holidaysCalendar" */ /* webpackMode: "lazy" */ '@/pages/holidaysCalendar/HolidaysCalendarModelDetails.vue'),
    },
];

export default paths;
