export default [
    {
        path: '/delivery-place-activity-route-list',
        title: 'DELIVERY_PLACE_ACTIVITY_ROUTE',
        name: 'DeliveryPlaceActivityRouteList',
        icon: 'fa fa-truck-loading',
        menu: 'analysis',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "deliveryPlaceActivityRoute" */ /* webpackMode: "lazy" */ '@/pages/activityRoute/deliveryPlaceActivityRoute/DeliveryPlaceActivityRouteList.vue'),
    },
    {
        path: '/delivery-place-activity-route',
        title: 'DELIVERY_PLACE_ACTIVITY_ROUTE',
        name: 'DeliveryPlaceActivityRoute',
        icon: 'fa fa-truck-loading',
        component: () => import(/* webpackChunkName: "deliveryPlaceActivityRoute" */ /* webpackMode: "lazy" */ '@/pages/activityRoute/deliveryPlaceActivityRoute/DeliveryPlaceActivityRoute.vue'),
    },
];
