const paths = [
    {
        path: '/registration-change-import',
        title: 'REGISTRATION_CHANGE_IMPORT',
        name: 'RegistrationChangeImportTabs',
        icon: 'fa fa-file-upload',
        menu: 'asset',
        component: () => import(/* webpackChunkName: "registrationChangeImport" */ /* webpackMode: "lazy" */ '@/pages/registrationChangeImport/RegistrationChangeImportTabs.vue'),
    },
    {
        path: '/importExecutionHistory',
        title: 'IMPORT_EXECUTION_HISTORY',
        name: 'ImportExecutionHistory',
        component: () => import(/* webpackChunkName: "registrationChangeImport" */ /* webpackMode: "lazy" */ '@/pages/registrationChangeImport/ImportExecutionHistory.vue'),
    },
];

export default paths;
