<template>
    <a-card v-if='!!_items.length' color='primary' style='color: white; border-radius: 0; margin-top: 5px;'>
        <a-row no-gutters align='center' class='flex-nowrap'>
            <a-col>
                <a-row class='mx-2'>
                    <template v-for='item in _items'>
                        <a-col
                            v-if='!isMobile && showHighlighted && !!item.highlighted'
                            class="pa-sm-2"
                            :key='item.text+item.value'
                            v-bind='colSpan'
                        >
                            <a-row no-gutters>
                                <a-col cols='12'>
                                    {{`${item.text}:`}}
                                </a-col>
                                <a-col cols='12' style='font-size: xx-large;' :class='processClassItem(item)'>
                                    {{`${processValue(item)}`}}
                                </a-col>
                            </a-row>
                        </a-col>
                        <a-col
                            v-else-if='!showHighlighted || (isMobile && !!item.highlighted)'
                            :key='item.text+item.value'
                            v-bind='colSpan'
                        >
                            <a-row no-gutters>
                                <span style='white-space: pre'><b>{{`${item.text}: `}}</b></span>
                                <span :class='processClassItem(item)'>{{processValue(item)}}</span>
                            </a-row>
                        </a-col>
                    </template>
                </a-row>
            </a-col>
            <a-col cols='auto'>
                <template v-if='hiddenItemsCount > 0'>
                    <a-badge left overlap color='red' :value='showHighlighted'>
                        <template v-if='showHighlighted' v-slot:badge>
                            <span>{{hiddenItemsCount}}</span>
                        </template>
                        <a-btn
                                @click='toggleShowHighlighted'
                                small
                                depressed
                                :expandbottom='showHighlighted'
                                :expandtop='!showHighlighted'
                                small-icon>
                        </a-btn>
                    </a-badge>
                </template>
            </a-col>
        </a-row>
    </a-card>
</template>

<script>
    export default {
        name: 'ADataTableInfoCard',
        props: {
            /**
             * Prop that contains the items to be displayed
             * @type {Array}
             * @default {[]}
            */
            _items: {
                type: Array,
                default: function () {
                    return [];
                },
            },
            /**
             * Prop to show only highlighted items
             * @type {Boolean}
             * @default {false}
            */
            _showHighlighted: {
                type: Boolean,
                default: function () {
                    return false;
                },
            },
            /**
             * Prop to assign the number of columns
             * @type {Object}
             * @default {{cols:12,sm:6,md:5}}
            */
            _colspanProps: {
                type: Object,
                default: function () {
                    return {
                        cols: 12,
                        sm: 6,
                        md: 5,
                        lg: 4,
                        xl: 3
                    };
                },
            },
        },
        data: function () {
            return {
                showHighlighted: this._showHighlighted,
                colSpan: this._colspanProps,
            };
        },
        computed: {
            isMobile: function () {
                return this.$vuetify.breakpoint.name === 'xs';
            },
            hiddenItemsCount: function () {
                return this._items.filter(item => !item.highlighted).length;
            },
        },
        watch: {
            _showHighlighted: {
                handler: function (value) {
                    this.showHighlighted = value;
                },
                immediate: true,
            },
        },
        mounted: function () {
            this.load();
        },
        methods: {
            load: function () {
            },

            toggleShowHighlighted: function () {
                this.showHighlighted = !this.showHighlighted;
            },
            processClassItem: function (item) {
                if (item.classValue) {
                    if (typeof item.classValue === 'function') {
                        return item.classValue(item);
                    } else {
                        return item.classValue;
                    }
                }
                return '';
            },
            processValue: function (item) {
                if (item.monetary) {
                    const precision = item.precision !== null && item.precision !== undefined ? item.precision : 2; // Zero is a valid value, but the default is 2
                    const hideGroupingSymbol = item.hideGroupingSymbol || false;
                    const showCurrencySymbol = item.showCurrencySymbol || false;
                    if (showCurrencySymbol) {
                        return `${this.$store.getters.getCurrencySymbol} ${this.$util.asMonetary(item.value, precision, hideGroupingSymbol)}`;
                    }
                    return this.$util.asMonetary(item.value, precision, hideGroupingSymbol);
                }
                return item.value;
            },
        },
    };
</script>

<style lang='scss' scoped></style>
