<script>
    export default {
        name: 'AIconPhoto',
        functional: true,
        render: function (createElement, { data, parent }) {
            delete data.attrs.photo;
            const children = ['fas fa-camera'];
            if ((!data.scopedSlots || !data.scopedSlots['tooltip-body']) && !data.attrs['_tooltip-text']) {
                data.attrs['_tooltip-text'] = parent.$t('PHOTO');
            }
            return createElement('AIconDefault', data, children);
        },
    };
</script>
