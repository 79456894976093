const paths = [
    {
        path: '/revenues-report',
        name: 'RevenuesReport',
        title: 'REVENUES_REPORT',
        icon: 'mdi-file-chart',
        menu: 'contracts',
        categories: [
          'reports',
        ],
        component: () => import(/* webpackChunkName: "revenuesReport" */ /* webpackMode: "lazy" */ '@/pages/report/revenuesReport/RevenuesReport.vue'),
    },
];

export default paths;
