const paths = [
    {
        path: '/fuel-report',
        name: 'FullReport',
        title: 'FULL_REPORT',
        icon: 'mdi-file-excel',
        menu: 'analysis',
        component: () => import(/* webpackChunkName: "fullReport" */ /* webpackMode: "lazy" */ '@/pages/fullReport/FullReport.vue'),
    },
];

export default paths;
