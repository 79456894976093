export default {
    TABS: {
        DATA_TABLE: 0,
        CHART: 1,
    },
    FREQUENCY: {
        MONTHLY: 0,
        YEARLY: 1,
    },
};
