const paths = [
    {
        path: '/financial-history',
        name: 'FinancialHistory',
        title: 'FINANCIAL_HISTORY',
        icon: 'mdi-cash',
        menu: 'financial',
        categories: [
          'reports',
        ],
        component: () => import(/* webpackChunkName: "financialHistory" */ /* webpackMode: "lazy" */ '@/pages/financialHistory/financialHistory.vue'),
    },
];

export default paths;
