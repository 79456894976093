const paths = [
    {
        path: '/inventory-report-by-company',
        name: 'InventoryReportByCompanyList',
        title: 'INVENTORY_REPORT_BY_COMPANY',
        icon: 'fas fa-clipboard',
        menu: 'stock',
        component: () => import(/* webpackChunkName: "inventoryReportByCompany" */ /* webpackMode: "lazy" */ '@/pages/stock/inventoryReportByCompany/InventoryReportByCompanyList.vue'),
    },
    {
        path: '/inventory-report-by-company-detail',
        name: 'InventoryReportByCompanyDetail',
        title: 'MATERIAL_STOCK_DETAILS',
        favCategory: 'stock',
        component: () => import(/* webpackChunkName: "inventoryReportByCompany" */ /* webpackMode: "lazy" */ '@/pages/stock/inventoryReportByCompany/InventoryReportByCompanyDetail.vue'),
    },
];

export default paths;
