const paths = [
    {
        path: '/activity-panel',
        name: 'ActivityPanel',
        title: 'ACTIVITIES',
        menu: 'processes',
        icon: 'fa fa-hard-hat',
        component: () => import(/* webpackChunkName: "camunda" */ /* webpackMode: "lazy" */ '@/pages/camunda/activityPanel/ActivityPanel.vue'),
    },
];

export default paths;
