const paths = [
    {
        path: '/item-lifetime',
        name: 'ItemLifetime',
        title: 'ITEM_LIFETIME',
        icon: 'fa fa-hard-hat',
        menu: 'maintenance',
        categories: [
            'reports',
        ],
        component: () => import(/* webpackChunkName: "itemLifetime" */ /* webpackMode: "lazy" */ '@/pages/itemLifetime/itemLifetime.vue'),
    },
];

export default paths;
