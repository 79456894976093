export default function projectTeam (provider) {
    return new Promise((resolve) => {
        provider.forEach((item) => {
            if (item.ID_EQUIPE_ESCOLHIDA) {
                item.description = `${item.INDEX_EQUIPE_OBRA} - ${item.DESC_TIPO_EQUIPE} - ${item.DESC_EQUIPE_ESCOLHIDA}`;
            }
            item.description = `${item.INDEX_EQUIPE_OBRA} - ${item.DESC_TIPO_EQUIPE}`;
        });
        resolve(provider);
    });
}
