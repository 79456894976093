<template lang="pug">
    a-stepper(alt-labels v-model='STATE' vertical)
        a-stepper-header
            template(v-for='s in states')
                a-stepper-step(:key='s.id' :complete='s.status !== INCOMPLETE' :step='s.id'
                    editable :edit-icon='icon(s, 1)' :complete-icon='icon(s, 1)' :error-icon='icon(s, 0)'
                    :color='colorValue(s)' @click='onClick(s)')
                    div(class="center")
                        span(v-if='!titleHtml') {{ s.title }}
                        span(v-else v-html='s.title')
                        br
                        small(v-if='subtitle(s)') {{ s.subtitle }}
                a-divider
</template>

<script>
    import alert from '../../aura/alert/alert';
    import isEmpty from '../../aura/util/helper/isEmpty';

    export default {
        name: 'AStatusBar',
        props: {
            states: {
                type: Array,
                required: true,
                default: function () {
                    return [];
                },
                validator: function (states) {
                    const sizeStates = states.length;
                    let obj;
                    for (let i = 0; i < sizeStates; i += 1) {
                        obj = states[i];
                        if (isEmpty(obj)) {
                            return false;
                        }
                        if (typeof (obj.id) === 'undefined') {
                            return false;
                        }
                        if (typeof (obj.title) === 'undefined') {
                            return false;
                        }
                        if (typeof (obj.status) === 'undefined') {
                            return false;
                        }
                    }
                    return true;
                },
            },
            color: {
                type: String,
                required: false,
                default: function () {
                    return null;
                },
            },
            noConfirmationDialog: {
                type: Boolean,
                required: false,
                default: function () {
                    return false;
                },
            },
            titleHtml: {
                type: Boolean,
                default: false,
            },
        },
        data: function () {
            return {
                INCOMPLETE: 0,
                COMPLETED: 1,
                STATE: 0,
            };
        },
        methods: {
            verifyClick: function (s) {
                const options = {
                    title: this.$t('ATTENTION'),
                    text: this.$t('DO_YOU_REALLY_WANT_TO_CHANGE_CONFIRMATION_STATE', {
                        0: s.title,
                        1: s.status === this.INCOMPLETE ? this.$t('CONFIRMED') : this.$t('UNCONFIRMED'),
                    }),
                    showCancelButton: true,
                    confirmButtonText: this.$t('CONFIRM'),
                    cancelButtonText: this.$t('CANCEL'),
                    type: 'question',
                    confirmButtonColor: '#51A0C9',
                    cancelButtonColor: '#ABABAB',
                    reverseButtons: true,
                };
                return alert.alert(options).then(
                    (resolve) => !resolve.dismiss,
                );
            },
            onClick: function (s) {
                if (this.noConfirmationDialog) {
                    this.changeState(s);
                } else {
                    this.verifyClick(s).then(
                        (resolve) => {
                            if (resolve) {
                                this.changeState(s);
                            }
                        },
                    );
                }
            },
            changeState: function (s) {
                if (s.status === this.INCOMPLETE) {
                    // before completion callback hook
                    if (typeof (s.beforeConfirm) !== 'undefined' && s.beforeConfirm !== null) {
                        if (!s.beforeConfirm(this.STATE, s)) {
                            return;
                        }
                    }

                    // dependency analysis
                    const errors = [];
                    const sizeConfirmsWhen = s.confirmsWhen.length;
                    const sizeStates = this.states.length;
                    for (let i = 0; i < sizeConfirmsWhen; i++) {
                        for (let j = 0; j < sizeStates; j++) {
                            // if conditions aren't met
                            if (this.states[j].id === s.confirmsWhen[i].id && this.states[j].status !== s.confirmsWhen[i].status) {
                                errors.push({ state: s, depends: this.states[j], expected: s.confirmsWhen[i].status });
                            }
                        }
                    }
                    if (errors.length > 0) {
                        this.raiseErrors(errors, this.INCOMPLETE);
                        return;
                    }

                    // completion callback hook
                    if (typeof (s.onConfirm) !== 'undefined' && s.onConfirm !== null) {
                        if (!s.onConfirm(this.STATE, s)) {
                            return;
                        }
                    } else {
                        s.status = typeof (s.nextStatus) !== 'undefined' ? s.nextStatus : this.COMPLETED;
                        // last state emits onEnd event
                        if (this.states[this.states.length - 1] === s && typeof (s.nextState) === 'undefined') {
                            this.$emit('onEnd', s);
                        }
                    }

                    // after completion callback hook
                    if (typeof (s.afterConfirm) !== 'undefined' && s.afterConfirm !== null) {
                        if (!s.afterConfirm(this.STATE, s)) {
                            return;
                        }
                    }

                    // effects after state completion
                    if (typeof (s.causes) !== 'undefined' && s.causes !== null && s.causes.length > 0) {
                        const sizeCauses = s.causes.length;
                        for (let i = 0; i < sizeCauses; i++) {
                            this.setStatus(s.causes[i].id, s.causes[i].status);
                        }
                    }
                    this.raiseSuccess(s);
                } else {
                    const sizeStates = this.states.length;
                    // before incompletion callback hook
                    if (typeof (s.beforeDisconfirm) !== 'undefined' && s.beforeDisconfirm !== null) {
                        if (!s.beforeDisconfirm(this.STATE, s)) {
                            return;
                        }
                    }
                    // dependency analysis
                    const errors = [];
                    if (typeof (s.disconfirmsWhen) !== 'undefined' && s.disconfirmsWhen !== null && s.disconfirmsWhen.length > 0) {
                        const sizeDisconfirmsWhen = s.disconfirmsWhen.length;
                        // custom rule to disconfirm state
                        for (let i = 0; i < sizeDisconfirmsWhen; i += 1) {
                            for (let j = 0; j < sizeStates; j += 1) {
                                // if conditions aren't met
                                if (this.states[j].id === s.disconfirmsWhen[i].id && this.states[j].status !== s.disconfirmsWhen[i].status) {
                                    errors.push({ state: s, depends: this.states[j], expected: s.disconfirmsWhen[i].status });
                                }
                            }
                        }
                    }

                    if (errors.length > 0) {
                        this.raiseErrors(errors, this.COMPLETED);
                        return;
                    }

                    // incompletion callback hook
                    if (typeof (s.onDisconfirm) !== 'undefined' && s.onDisconfirm !== null) {
                        if (!s.onDisconfirm(this.STATE, s)) {
                            return;
                        }
                    } else {
                        this.STATE = this.STATE > 1 ? this.STATE - 1 : this.STATE;
                        s.status = this.INCOMPLETE;
                    }

                    // after incompletion callback hook
                    if (typeof (s.afterDisconfirm) !== 'undefined' && s.afterDisconfirm !== null) {
                        if (!s.afterDisconfirm(this.STATE, s)) {
                            return;
                        }
                    }
                    // effects after state completion
                    if (typeof (s.causesOnDisconfirm) !== 'undefined' && s.causesOnDisconfirm !== null && s.causesOnDisconfirm.length > 0) {
                        const sizeCauses = s.causesOnDisconfirm.length;
                        for (let i = 0; i < sizeCauses; i++) {
                            this.setStatus(s.causesOnDisconfirm[i].id, s.causesOnDisconfirm[i].status);
                        }
                    }
                    this.raiseSuccess(s);
                }
            },
            raiseErrors: function (errors, status) {
                const sizeErros = errors.length;
                if (status === this.INCOMPLETE) {
                    for (let i = 0; i < sizeErros; i += 1) {
                        errors[i].message = `${errors[i].state.title} ${this.$t('CANNOT_BE_CONFIRMED_BECAUSE')} ${errors[i].depends.title} ${this.$t('NEEDS_TO_BE')} ${this.getStatusDescription(errors[i].expected)}.`;
                    }
                } else {
                    for (let i = 0; i < sizeErros; i += 1) {
                        errors[i].message = `${errors[i].state.title} ${this.$t('CANNOT_BE_DISCONFIRMED_BECAUSE')} ${errors[i].depends.title} ${this.$t('NEEDS_TO_BE')} ${this.getStatusDescription(errors[i].expected)}.`;
                    }
                }
                this.$emit('onError', { errors: errors, status: status });
            },
            raiseSuccess: function (state) {
                const success = {};
                success.message = `${state.title} ${state.status === this.INCOMPLETE ? this.$t('WAS_SUCCESSFULLY_UNCONFIRMED') : this.$t('WAS_SUCCESSFULLY_CONFIRMED')}.`;
                success.state = state;
                this.$emit('onSuccess', success);
            },
            setStatus: function (stateId, status) {
                const sizeStates = this.states.length;
                for (let i = 0; i < sizeStates; i += 1) {
                    if (this.states[i].id === stateId) {
                        this.states[i].status = status;
                        return;
                    }
                }
            },
            getStatusDescription: function (status) {
                switch (status) {
                    case 0:
                        return this.$t('UNCONFIRMED');
                    case 1:
                        return this.$t('CONFIRMED');
                    default:
                        return '';
                }
            },
            colorValue: function (state) {
                // incomplete state is always grey
                if (state.status === this.INCOMPLETE) {
                    return 'grey';
                }
                // defined color for object
                if (typeof (state.color) !== 'undefined' && state.color !== null) {
                    return state.color;
                }
                // defined color for all objects
                if (this.color !== undefined && this.color !== null) {
                    return this.color;
                }
                // default completed color
                return 'success';
            },
            icon: function (state, type) {
                let selectedIcon;
                switch (type) {
                    // error icon
                    case 0:
                        selectedIcon = typeof (state.errorIcon) !== 'undefined' ? state.errorIcon : '$vuetify.icons.error';
                        break;
                    // complete icon
                    case 1:
                        selectedIcon = typeof (state.confirmedIcon) !== 'undefined' ? state.confirmedIcon : '$vuetify.icons.complete';
                        break;
                    // edit icon has to be equal to complete icon
                    default:
                        selectedIcon = typeof (state.confirmedIcon) !== 'undefined' ? state.confirmedIcon : '$vuetify.icons.complete';
                }
                return selectedIcon;
            },
            subtitle: function (state) {
                // there is a subtitle and the state is already confirmed
                return typeof (state.subtitle) !== 'undefined' && state.status !== this.INCOMPLETE;
            },
        },
    };
</script>

<style scoped>
.center {
    text-align: center;
}
</style>
