const paths = [
    {
        path: '/events-list',
        name: 'EventsList',
        title: 'EVENTS',
        icon: 'widgets',
        menu: 'maintenance',
        component: () => import(/* webpackChunkName: "events" */ /* webpackMode: "lazy" */ '@/pages/events/EventsList.vue'),
    },
    {
        path: '/event-standard',
        name: 'EventStandard',
        title: 'EVENT',
        component: () => import(/* webpackChunkName: "events" */ /* webpackMode: "lazy" */ '@/pages/events/EventStandard.vue'),
    },
];

export default paths;
