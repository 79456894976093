import { CallCommand } from '@/util/dynamicForm/dynamic-form';
export class GetUserConfigs extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarUsuarioConfiguracoes',
            node: 'USUARIO_CONFIGURACOES.USUARIO_CONFIGURACOES',
            type: 'NONE',
            fields: {
                name: {
                    name: 'NOME',
                },
                noFilterUser: {
                    name: "NAO_FILTRAR_USUARIO",
                }
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveUserConfig extends CallCommand {
    constructor() {
        super({
            name: 'SalvarUsuarioConfiguracao',
            node: 'USUARIO_CONFIGURACOES.USUARIO_CONFIGURACOES',
            type: 'NONE',
            fields: {
                name: {
                    name: 'NOME',
                },
                value: {
                    name: 'VALOR',
                },
                noFilterUser: {
                    name: "NAO_FILTRAR_USUARIO",
                },
            },
        });
    }
    async callCommand(param, config, debug) {
        if (Array.isArray(param.value)) {
            param.value = JSON.stringify(param.value);
        }
        if (typeof param.value === "object") {
            param.value = JSON.stringify(param.value);
        }
        return super.callCommand(param, config, debug);
    }
}
export class DeleteUserConfig extends CallCommand {
    constructor() {
        super({
            name: 'DeletarUsuarioConfiguracao',
            type: 'NONE',
            fields: {
                name: {
                    name: 'NOME',
                },
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
