const paths = [
    {
        path: '/asset-grouping-edit',
        name: 'AssetGroupingEdit',
        component: () => import(/* webpackChunkName: "assetGrouping" */ /* webpackMode: "lazy" */ '@/pages/assetGrouping/AssetGroupingEdit.vue'),
    },
    {
        path: '/asset-grouping-status-history',
        name: 'AssetGroupingStatusHistory',
        component: () => import(/* webpackChunkName: "assetGrouping" */ /* webpackMode: "lazy" */ '@/pages/assetGrouping/AssetGroupingStatusHistory.vue'),
    },
    {
        path: '/asset-grouping-status-attributes-history',
        name: 'AssetGroupingStatusHistoryAttributes',
        component: () => import(/* webpackChunkName: "assetGrouping" */ /* webpackMode: "lazy" */ '@/pages/assetGrouping/AssetGroupingStatusHistoryAttributes.vue'),
    },
    {
        path: '/asset-grouping-status-steps',
        name: 'AssetGroupingStatusSteps',
        component: () => import(/* webpackChunkName: "assetGrouping" */ /* webpackMode: "lazy" */ '@/pages/assetGrouping/AssetGroupingStatusSteps.vue'),
    },
    {
        path: '/asset-grouping-history',
        name: 'AssetGroupingHistory',
        component: () => import(/* webpackChunkName: "assetGrouping" */ /* webpackMode: "lazy" */ '@/pages/assetGrouping/AssetGroupingHistory.vue'),
    },
];

export default paths;
