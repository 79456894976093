const paths = [
    {
        path: '/quotation-list',
        title: 'QUOTATIONS',
        name: 'QuotationList',
        icon: 'fa fa-calculator',
        menu: 'shopping',
        component: () => import(/* webpackChunkName: "shopping" */ /* webpackMode: "lazy" */ '@/pages/shopping/QuotationList.vue'),
    },
    {
        path: '/quotation',
        title: 'QUOTATION',
        name: 'Quotation',
        component: () => import(/* webpackChunkName: "shopping" */ /* webpackMode: "lazy" */ '@/pages/shopping/Quotation.vue'),
    },
    {
        path: '/quotation-items-change',
        title: 'CHANGE_BUDGET_PROVIDER_ITEM',
        name: 'QuotationItemsChange',
        component: () => import(/* webpackChunkName: "shopping" */ /* webpackMode: "lazy" */ '@/pages/shopping/QuotationItemsChange.vue'),
    },
    {
        path: '/quotation-items-send-budget-request',
        title: 'SENDING_BUDGET_REQUEST',
        name: 'QuotationItemsSendBudgetRequest',
        component: () => import(/* webpackChunkName: "shopping" */ /* webpackMode: "lazy" */ '@/pages/shopping/QuotationItemsSendBudgetRequest.vue'),
    },
    {
        path: '/quotation-items-include-wizard',
        title: 'INCLUDE_QUOTATION_ITEM',
        name: 'QuotationItemsIncludeWizard',
        component: () => import(/* webpackChunkName: "shopping" */ /* webpackMode: "lazy" */ '@/pages/shopping/QuotationItemsIncludeWizard.vue'),
    },
    {
        path: '/budget-provider',
        title: 'BUDGET_PROVIDER',
        name: 'BudgetProvider',
        component: () => import(/* webpackChunkName: "shopping" */ /* webpackMode: "lazy" */ '@/pages/shopping/BudgetProvider.vue'),
    },
    {
        path: '/purchase-order-to-quotation',
        title: 'NEW_PURCHASE_ORDER',
        name: 'PurchaseOrderToQuotation',
        component: () => import(/* webpackChunkName: "shopping" */ /* webpackMode: "lazy" */ '@/pages/shopping/PurchaseOrderToQuotation.vue'),
    },
    {
        path: '/budget-additional-information',
        title: 'BUDGET_ITEM_ADDITIONAL_INFORMATION',
        name: 'BudgetAdditionalInformation',
        component: () => import(/* webpackChunkName: "shopping" */ /* webpackMode: "lazy" */ '@/pages/shopping/BudgetAdditionalInformation.vue'),
    },
    {
        path: '/change-provider',
        title: 'CHANGE_PROVIDER',
        name: 'ChangeProvider',
        component: () => import(/* webpackChunkName: "shopping" */ /* webpackMode: "lazy" */ '@/pages/shopping/ChangeProvider.vue'),
    },
    {
        path: '/budget-code-page',
        title: 'BUDGET_CODE',
        name: 'BudgetCodePage',
        component: () => import(/* webpackChunkName: "shopping" */ /* webpackMode: "lazy" */ '@/pages/shopping/BudgetCodePage.vue'),
    },
];

export default paths;
