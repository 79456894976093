export default function citiesInstances (provider) {
    return new Promise((resolve) => {
        const resultProvider = [];

        provider.forEach((item) => {
            resultProvider.push({ KEY: Number(item.ID_CIDADE_INSTANCIA_CI), VALUE: `${item.EMPRESA_RESPONSAVEL} - ${item.NOME_MUNICIPIO}` });
        });
        resolve(resultProvider);
    });
}
