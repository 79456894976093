const paths = [
    {
        path: '/ombudsman',
        name: 'Ombudsman',
        title: 'OMBUDSMAN',
        menu: 'maintenance',
        component: () => import(/* webpackChunkName: "ombudsman" */ /* webpackMode: "lazy" */ '@/pages/ombudsman/OmbudsmanRequestList.vue'),
    },
    {
        path: '/ombudsman_request_details',
        name: 'OmbudsmanRequestDetails',
        title: 'OMBUDSMAN_REQUEST_DETAILS',
        favCategory: 'maintenance',
        component: () => import(/* webpackChunkName: "ombudsman" */ /* webpackMode: "lazy" */ '@/pages/ombudsman/OmbudsmanRequestDetails.vue'),
    },
    {
        path: '/ombudsman_request_wizard',
        name: 'OmbudsmanRequestWizard',
        title: 'OMBUDSMAN_REQUEST_WIZARD',
        favCategory: 'maintenance',
        component: () => import(/* webpackChunkName: "ombudsman" */ /* webpackMode: "lazy" */ '@/pages/ombudsman/OmbudsmanRequestWizard.vue'),
    },
    {
        path: '/ombudsman_origin_type_list',
        name: 'OmbudsmanOriginTypeList',
        title: 'OMBUDSMAN_ORIGIN_TYPES',
        menu: 'ticket',
        icon: 'widgets',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "ombudsmanOriginType" */ /* webpackMode: "lazy" */ '@/pages/ombudsman/OmbudsmanOriginTypeList.vue'),
    },
    {
        path: '/ombudsman-origin-type-general',
        name: 'OmbudsmanOriginTypeGeneral',
        title: 'OMBUDSMAN_ORIGIN_TYPE',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "ombudsmanOriginType" */ /* webpackMode: "lazy" */ '@/pages/ombudsman/OmbudsmanOriginTypeGeneral.vue'),
    },
    {
        path: '/ombudsman-reason-list',
        name: 'OmbudsmanReasonList',
        title: 'OMBUDSMAN_REASON',
        menu: 'maintenance',
        categories: [
            'configurations',
        ],
        icon: 'fa fa-briefcase',
        component: () => import(/* webpackChunkName: "ombudsmanReason" */ /* webpackMode: "lazy" */ '@/pages/ombudsman/OmbudsmanReasonList.vue'),
    },
    {
        path: '/ombudsman-reason-general',
        name: 'OmbudsmanReasonGeneral',
        title: 'OMBUDSMAN_REASON',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "ombudsmanReason" */ /* webpackMode: "lazy" */ '@/pages/ombudsman/OmbudsmanReasonGeneral.vue'),
    },
    {
        path: '/ombudsman-resolution-type-list',
        name: 'OmbudsmanResolutionTypeList',
        title: 'OMBUDSMAN_RESOLUTION_TYPE',
        menu: 'maintenance',
        categories: [
            'configurations',
        ],
        icon: 'fa fa-briefcase',
        component: () => import(/* webpackChunkName: "ombudsmanResolutionType" */ /* webpackMode: "lazy" */ '@/pages/ombudsman/OmbudsmanResolutionTypeList.vue'),
    },
    {
        path: '/ombudsman-resolution-type-general',
        name: 'OmbudsmanResolutionTypeGeneral',
        title: 'OMBUDSMAN_RESOLUTION_TYPE',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "ombudsmanResolutionType" */ /* webpackMode: "lazy" */ '@/pages/ombudsman/OmbudsmanResolutionTypeGeneral.vue'),
    },
    {
        path: '/ombudsman-priority-list',
        name: 'OmbudsmanPriorityList',
        title: 'OMBUDSMAN_REQUEST_PRIORITY',
        icon: 'widgets',
        menu: 'ticket',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "OmbudsmanPriority" */ /* webpackMode: "lazy" */ '@/pages/ombudsman/OmbudsmanPriorityList.vue'),
    },
    {
        path: '/ombudsman-priority-general',
        name: 'OmbudsmanPriorityGeneral',
        title: 'OMBUDSMAN_REQUEST_PRIORITY',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "OmbudsmanPriority" */ /* webpackMode: "lazy" */ '@/pages/ombudsman/OmbudsmanPriorityGeneral.vue'),
    },
    {
        path: '/ombudsman-service-type-list',
        name: 'OmbudsmanServiceTypeList',
        title: 'OMBUDSMAN_SERVICE_TYPE',
        icon: 'widgets',
        menu: 'ticket',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "ombudsmanServiceType" */ /* webpackMode: "lazy" */ '@/pages/ombudsman/OmbudsmanServiceTypeList.vue'),
    },
    {
        path: '/ombudsman-service-type',
        title: 'OMBUDSMAN_SERVICE_TYPE',
        name: 'OmbudsmanServiceTypeGeneral',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "ombudsmanServiceType" */ /* webpackMode: "lazy" */ '@/pages/ombudsman/OmbudsmanServiceTypeGeneral.vue'),
    },
    {
        path: '/ombudsman-deadline-history',
        name: 'OmbudsmanDeadlineHistory',
        title: 'DEADLINE_CALCULATION',
        component: () => import(/* webpackChunkName: "ticket" */ /* webpackMode: "lazy" */ '@/pages/ombudsman/OmbudsmanDeadlineHistory.vue'),
    },
    {
        path: '/ombudsman-reason-priority-general',
        name: 'OmbudsmanReasonPriorityGeneral',
        title: 'OMBUDSMAN_REASON_PRIORITY',
        component: () => import(/* webpackChunkName: "ticket" */ /* webpackMode: "lazy" */ '@/pages/ombudsman/OmbudsmanReasonPriorityGeneral.vue'),
    },
];

export default paths;
