const paths = [
    {
        path: '/issue-referral-list',
        title: 'ISSUES_REFERRALS',
        name: 'IssueReferralList',
        icon: 'fa fa-hard-hat',
        menu: 'maintenance',
        component: () => import(/* webpackChunkName: "issueReferral" */ /* webpackMode: "lazy" */ '@/pages/issue/issueReferral/IssueReferralList.vue'),
    },
    {
        path: '/issue-referral',
        title: 'ISSUE_REFERRAL',
        name: 'IssueReferral',
        favCategory: 'maintenance',
        component: () => import(/* webpackChunkName: "issueReferral" */ /* webpackMode: "lazy" */ '@/pages/issue/issueReferral/IssueReferral.vue'),
    },
    {
        path: '/issue-referral-wizard',
        title: 'NEW',
        name: 'IssueReferralWizard',
        component: () => import(/* webpackChunkName: "issueReferral" */ /* webpackMode: "lazy" */ '@/pages/issue/issueReferral/IssueReferralWizard.vue'),
    },
];

export default paths;
