function removePrefixes (item, prefixes) {
    prefixes.forEach((p) => {
        if (item.search(p) === 0) {
            item = item.substring(p.length);
        }
    });
    return item;
}

function findPrefixe (attr) {
    return function (element) {
        return attr.search(element) === 0;
    };
}

function includesArrayString (attr, prefix, includes) {
    if (prefix instanceof Array) {
        return (includes !== false ? prefix.some(findPrefixe(attr[0])) : !prefix.some(findPrefixe(attr[0])));
    }
    return (includes !== false ? attr[0].search(prefix) === 0 : attr[0].search(prefix) !== 0);
}

export function getProps (attrs, [prefix], includes, removePrefix = true) {
    const props = {};
    Object.entries(attrs).filter((attr) => includesArrayString(attr, prefix, includes)).forEach((item) => {
        const propName = (includes !== false && removePrefix) ? removePrefixes(item[0], prefix instanceof Array ? prefix : [prefix]) : item[0];
        props[propName] = item[1];
    });
    return props;
}

export function isAuraTheme (attrs) {
    return !(attrs['aura-theme'] === false || attrs['aura-theme'] === 'false');
}

export function getBooleanFromProp (value) {
    return value === true || value === 1 || (typeof value === 'string' && value !== 'false');
}
