const paths = [
    {
        path: '/report_pivot',
        title: 'REPORT',
        name: 'ReportPivot',
        component: () => import(/* webpackChunkName: "reportPivot" */ /* webpackMode: "lazy" */ '@/components/core/dataTableReport/DataTableReportPivot.vue'),
    },
];

export default paths;
