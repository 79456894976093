const paths = [
    {
        path: '/referral-reason-list',
        name: 'ReferralReasonList',
        title: 'REFERRAL_REASONS',
        icon: 'widgets',
        menu: 'ticket',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "referralReason" */ /* webpackMode: "lazy" */ '@/pages/referralReason/ReferralReasonList.vue'),
    },
    {
        path: '/referral-reason-general',
        name: 'ReferralReasonGeneral',
        title: 'REFERRAL_REASON_GENERAL',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "referralReason" */ /* webpackMode: "lazy" */ '@/pages/referralReason/ReferralReasonGeneral.vue'),
    },
];

export default paths;
