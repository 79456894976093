const paths = [
    {
        path: '/maintenance-services-performed',
        name: 'MaintenanceServicesPerformed',
        title: 'SERVICES_PERFORMED_IN_MAINTENANCE',
        icon: 'mdi-file-document',
        menu: 'maintenance',
        categories: [
          'reports',
        ],
        component: () => import(/* webpackChunkName: "reportMaintenance" */ /* webpackMode: "lazy" */ '@/pages/report/maintenance/MaintenanceServicesPerformed.vue'),
    },
    {
        path: '/maintenance-services-performed',
        name: 'MaintenanceServicesPerfomedPieChart',
        title: 'EXECUTION_BULLETIN_SERVICES',
        component: () => import(/* webpackChunkName: "reportMaintenance" */ /* webpackMode: "lazy" */ '@/pages/report/maintenance/MaintenanceServicesPerfomedPieChart.vue'),
    },
    {
        path: '/maintenance-per-district',
        name: 'MaintenancePerDistrict',
        title: 'MAINTENANCES_BY_DISTRICT',
        icon: 'mdi-file-document',
        menu: 'maintenance',
        categories: [
          'reports',
        ],
        component: () => import(/* webpackChunkName: "reportMaintenance" */ /* webpackMode: "lazy" */ '@/pages/report/maintenance/MaintenancePerDistrict.vue'),
    },
];

export default paths;
