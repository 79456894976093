<template>
    <div>
        <input type='file' id='file' ref='file' :multiple='multiple && !mini' :accept='accept' @change='handleFiles($event)' style='display: none' />
        <a-card
            v-if='!mini'
            ref='dropbox'
            @click='handlerClick'
            @dragenter.native='dragEnter($event)'
            @dragover.native='dragOver($event)'
            @dragleave.native='dragLeave($event)'
            @drop.native='drop($event)'>
            <a-card-text class='wd-100'>
                <a-layout row>
                    <a-flex>
                        <a-layout class='wd-100 dropbox' column :class='{ "is-drag-over": isDragOver || isMouseOver }' :style='{"height": height, "max-height": height}'>
                            <a-layout
                                v-if='$_aura.isEmpty(getValues)'
                                class='wd-100 ma-0'
                                column
                                justify-center
                                align-center
                                @mouseover='isMouseOver = true'
                                @mouseleave='isMouseOver = false'
                            >
                                <a-icon class='ma-2' large>fas fa-upload</a-icon>
                                <span>{{$t('CLICK_OR_DRAG_AND_DROP_THE_FILE_HERE_TO_UPLOAD')}}</span>
                            </a-layout>

                            <!-- MULTIPLE-->
                            <div v-else-if='multiple'>
                                <a-toolbar class='pa-1 mb-1' dense flat v-for='(item, idx) in getValues' @click.native.stop='' style='cursor: default' :key='idx'>
                                    <a-layout row align-center class='flex-nowrap'>
                                        <a-avatar size='32' class='mr-2 flex-grow-0'>
                                            <a-img class='image' v-if='item.src' :src='item.src' aspect-ratio='1' :contain='contain' @error='imageLoadError(item)'>
                                                <a-layout v-slot:placeholder v-if='item.imageError' column fill-height align-center justify-center ma-0>
                                                    <a-icon>{{$util.getFileIcon('')}}</a-icon>
                                                </a-layout>
                                            </a-img>
                                            <a-icon v-else>{{$util.getFileIcon(item.type)}}</a-icon>
                                        </a-avatar>
                                        <div class='flex-grow-1 ellipsis-content'>
                                            <div class='ellipsis-content'>
                                                <span class='text-xs-left'>&nbsp;{{item.name}}</span>
                                            </div>
                                            <div class='ellipsis-content'>
                                                <span v-if='item.dateTime' class='text-xs-left text--disabled'>&nbsp;{{$moment(item.dateTime, 'DD/MM/YYYY HH:mm').format('L LT')}}</span>
                                            </div>
                                        </div>
                                        <slot name='beforeOptions' v-bind='{ item: item, handlerClick: handlerClick, deleteFile: deleteFile }'></slot>
                                        <v-menu offset-y>
                                            <template v-slot:activator='{ on }'>
                                                <a-btn x-small fab depressed outlined color='primary' class='flex-grow-0' v-on='on'>
                                                    <a-icon small>fas fa-caret-down</a-icon>
                                                </a-btn>
                                            </template>
                                            <a-list class='pa-0' dense>
                                                <slot name='options' v-bind='{ item: item, handlerClick: handlerClick, deleteFile: deleteFile }'></slot>
                                                <a-list-item v-if='!readonly' ripple @click='deleteFile(item)'>
                                                    <a-list-item-icon class='mr-3'>
                                                        <a-icon delete color='error' class='mr-2' />
                                                    </a-list-item-icon>
                                                    <a-list-item-content>
                                                        <span>{{$t('DELETE')}}</span>
                                                    </a-list-item-content>
                                                </a-list-item>
                                            </a-list>
                                        </v-menu>
                                    </a-layout>
                                </a-toolbar>
                                <a-toolbar v-if='!readonly' class='pa-1 mb-1' dense flat style='cursor: default; background-color: transparent; cursor: pointer'>
                                    <a-layout justify-center align-center>{{$t('CLICK_OR_DRAG_AND_DROP_THE_FILE_HERE_TO_UPLOAD')}}</a-layout>
                                </a-toolbar>
                            </div>
                            <!-- SINGLE-->
                            <a-flex class='pa-0 wd-100' v-else style='flex: 0 0 auto'>
                                <v-menu class='wd-100 change-picture-container' offset-y absolute full-width @click.native.stop=''>
                                    <template v-slot:activator='on'>
                                            <a-img
                                                v-on='on'
                                                v-if='preview || src'
                                                class='image'
                                                :src='preview || src'
                                                aspect-ratio='1'
                                                :contain='contain'
                                                :height='height'
                                                @error='imageLoadError()'
                                            >
                                                <a-layout
                                                    v-if='imageError'
                                                    slot='placeholder'
                                                    column
                                                    fill-height
                                                    align-center
                                                    justify-center
                                                    ma-0
                                                >
                                                    <a-layout
                                                        v-for='(item, key) in value'
                                                        class='ma-0'
                                                        align-center
                                                        :key='key'
                                                    >
                                                        <a-icon small>{{$util.getFileIcon(item.type)}}</a-icon>
                                                        <span>&nbsp; {{item.name}}</span>
                                                    </a-layout>
                                                </a-layout>
                                            </a-img>
                                            <a-layout
                                                v-else
                                                v-for='(item, key) in value' :key='key'
                                                column
                                                class='ma-0 wd-100'
                                                fill-height
                                                justify-center
                                                align-center
                                            >
                                                <a-icon>{{$util.getFileIcon(item.type)}}</a-icon><span>&nbsp; {{item.name}}</span>
                                            </a-layout>
                                            <a-icon class='change-picture'>fa fa-pencil-alt</a-icon>
                                    </template>
                                    <a-list class='pa-0' dense>
                                        <slot name='options'></slot>
                                        <a-list-item ripple @click='handlerClick'>
                                            <a-icon small color='success'>fas fa-pencil-alt</a-icon><span>&nbsp; {{$t('EDIT')}}</span>
                                        </a-list-item>
                                    </a-list>
                                </v-menu>
                            </a-flex>
                        </a-layout>
                    </a-flex>
                    <a-flex :class='{ sidebar: true, "with-footer": footerOptions, "without-footer": !footerOptions }' shrink v-if='sidebar && $scopedSlots.sidebar'>
                        <a-layout class='pa-2' column>
                            <slot name='sidebar' v-bind='{ item: getValues, handlerClick: handlerClick, clear: clear }'></slot>
                        </a-layout>
                    </a-flex>
                </a-layout>
            </a-card-text>
            <!-- Footer-->
            <a-card-actions class='dropbox-footer' v-if='footerOptions'>
                <a-layout justify-center align-center>
                    <a-btn fab text depressed small>
                        <a-icon small>{{$util.getFileIcon(accept)}}</a-icon>
                    </a-btn>
                    <a-spacer></a-spacer>
                    <slot name='footer'/>
                    <a-btn
                        v-if='!hideClear && !readonly'
                        class="mr-0 mr-md-3"
                        @click.stop='clear'>
                        {{$t('CLEAR')}}
                    </a-btn>
                    <a-btn
                        v-if='!hideSend && !readonly'
                        color='save'
                        @click.stop='$emit("send", value)'>
                        {{$t('SEND')}}
                    </a-btn>                        
                </a-layout>

                
            </a-card-actions>
        </a-card>

        <!-- Mini-->
        <div v-else>
            <v-menu class='wd-100 change-picture-container' offset-y absolute full-width @click.native.stop=''>
                <template v-slot:activator>
                    <a-avatar :size='size' :tile='tile' :class='{ "is-drag-over": isDragOver }' style='background-color: #f3f3f3' ref='dropbox'>
                        <a-img class='image' v-if='preview || src' :src='preview || src' aspect-ratio='1' @error='imageLoadError()'>
                            <a-layout v-slot:placeholder fill-height align-center justify-center ma-0>
                                <a-icon v-if='imageError'>fas fa-unlink</a-icon>
                            </a-layout>
                        </a-img>
                        <a-icon v-else>{{miniEmptyIcon}}</a-icon>
                        <a-icon class='change-picture'>fa fa-pencil-alt</a-icon>
                    </a-avatar>
                </template>
                <a-list class='pa-0' dense>
                    <slot name='options'></slot>
                    <a-list-item v-if='!readonly' ripple @click='handlerClick'>
                        <a-icon small color='success'>fas fa-pencil-alt</a-icon><span>&nbsp; {{$t('EDIT')}}</span>
                    </a-list-item>
                </a-list>
            </v-menu>
        </div>
    </div>
</template>

<script>
import { verify } from 'crypto';

    // TODO: Rever código (mt código repetido no template) + dragDrop de arquivos do tipo definido (atualmente com o dragAndDrop qualquer arquivo pode ser adicionado)
    export default {
        name: 'AFileUpload',
        props: {
            footerOptions: {
                type: Boolean,
                default: true,
            },
            sidebar: {
                type: Boolean,
                default: false,
            },
            hideClear: {
                type: Boolean,
                default: false,
            },
            hideSend: {
                type: Boolean,
                default: false,
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            accept: {
                type: String,
                default: 'image/*',
            },
            height: {
                type: String,
                default: '200px',
            },
            contain: {
                type: Boolean,
                default: false,
            },
            src: {
                type: [String, Array],
                default: null,
            },
            mini: {
                type: Boolean,
                default: false,
            },
            size: {
                type: [Number, String],
                default: 200,
            },
            tile: {
                type: Boolean,
                default: false,
            },
            miniEmptyIcon: {
                type: String,
                default: 'fa-image',
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            verifyExtension: {
                type: Boolean, 
                default: false,
            }
        },
        data: function () {
            return {
                value: [],
                isDragOver: false,
                isMouseOver: false,
                preview: null,
                imageError: false,
                menu: false,
            };
        },
        computed: {
            getValues: function () {
                let values = this.value;
                if (this.src) {
                 if (typeof this.src === 'object' && this.src.length) {
                  values = [...this.src, ...values];
                 } else {
                      values = [this.src, ...values];
                     }
            }
                return values;
            },
            
        },
        watch: {
            value: async function () {
                this.$emit('input', this.value);
                if (!this.multiple && this.value.length && this.value[0].type.startsWith('image/')) {
                    if (this.value[0].type === "image/svg+xml") {
                    
                        this.preview = this.value[0].size ? URL.createObjectURL(new Blob([this.value[0]], {type: 'image/svg+xml'})) : null;
                    
                    
                    } else {
                        this.preview = this.value[0].size ? URL.createObjectURL(new Blob([this.value[0]])) : null;
                    }
                } else {
                    this.preview = null;
                    this.$_aura.getArray(() => this.value).forEach((v) => {
                        if (v.type && v.type.startsWith('image/')) {
                            v.src = v.size ? URL.createObjectURL(new Blob([v])) : null;
                        }
                    });
                }
                this.$emit('create-preview', this.preview);
            },
        },
        methods: {
            handleFiles: function () {
                this.imageError = false;
                if (this.multiple) {
                    this.value.push(...this.$refs.file.files);
                } else {
                    this.value = (this.$refs.file.files.length && Object.values(this.$refs.file.files)) || this.value;

                    if(this.value?.length > 1){
                        this.$_aura.showInfoMessage(this.$t('ONLY_ONE_FILE_AT_A_TIME'));
                        this.value = this.value.slice(0, 1);
                    }
                }
                if (this.verifyExtension) {
                    this.validateExtensionFile();
                }                
            },
            validateExtensionFile: function () {
                if (!this.accept) {
                    return;
                }

                if (this.accept === '*') {
                    return;
                }
                
                const errorExtensions = [];
                const finalValidExtensions = [];
                const initialValidExtensions = this.accept.split(',');

                if (!this.value || this.value.length == 0) {
                    return;
                }

                initialValidExtensions.forEach(ext => {
                    ext = ext.trim();
                    if (ext.includes('\/')) {
                        if (ext.split('/')[1] === '*') {
                            if (ext.split('/')[0] === 'image') {
                                finalValidExtensions.push('.png');
                                finalValidExtensions.push('.svg');
                                finalValidExtensions.push('.jpeg');
                                finalValidExtensions.push('.gif');
                            }
                        } else {
                            finalValidExtensions.push(`.${ext.split('/')[1]}`);
                        }
                    } else {
                        finalValidExtensions.push(ext);
                    }
                })

                this.value.forEach(file => {
                    let fileType = file.type;
                    let fileExtension = `.${file.name.slice((Math.max(0, file.name.lastIndexOf(".")) || Infinity) + 1)}`;

                    if (fileType.includes('\/')) {
                        fileType = `.${fileType.split('/')[1]}`;
                    }                    
                    
                    if (!finalValidExtensions.includes(fileType)) {
                        const validExtension = finalValidExtensions.filter(ext => ext === fileExtension);
                        if (validExtension && validExtension.length > 0) {
                            return;
                        }
                        this.deleteFile(file);
                        errorExtensions.push(fileExtension);
                    }
                })

                if (errorExtensions && errorExtensions.length > 0) {
                    if (finalValidExtensions.length > 0) {
                        this.$_aura.showInfoMessage(this.$t('FILE_FORMATS_ALLOWED', {
                            formats: finalValidExtensions.join(', '),
                        }));
                    }
                    this.$_aura.showErrorMessage(this.$t('EXTENSION_ARE_NOT_VALID_FOR_THIS_FIELD', {
                            formats: errorExtensions.join(', '),
                    }));
                }
            },
            dragEnter: function (event) {
                event.stopPropagation();
                event.preventDefault();
                this.isDragOver = true;
            },
            dragOver: function (event) {
                event.stopPropagation();
                event.preventDefault();
                this.isDragOver = true;
            },
            dragLeave: function (event) {
                event.stopPropagation();
                event.preventDefault();
                this.isDragOver = false;
            },
            drop: function (event) {
                event.stopPropagation();
                event.preventDefault();
                this.isDragOver = false;
                if (!this.readonly) {
                    this.$refs.file.files = event.dataTransfer.files;
                    this.handleFiles();
                }
            },
            handlerClick: function () {
                if (!this.readonly) {
                    this.$el.querySelector('#file').click();
                }
            },
            clear: function () {
                this.$el.querySelector('#file').value = '';
                this.value = [];
                this.imageError = false;
                this.$emit('clear');
            },
            deleteFile: function (item) {
                this.value = this.value.filter(i => i.name !== item.name);
                this.$emit('deleteFile', item);
            },
            imageLoadError: function (item) {
                if (item) {
                    item.imageError = true;
                } else {
                    this.imageError = true;
                    this.$emit('imageLoadFailed');
                }
            },
        },
    };
</script>

<style lang='stylus' scoped>
    >>>.wd-100
        width 100%
        height 100%
    >>>.dropbox
        cursor pointer
        min-height 90px
        //border dashed 2px #747474
        overflow-y auto
        &::-webkit-scrollbar
            width 5px
            background-color #f8f8f8
        &::-webkit-scrollbar-thumb
            background-color #c6c6c6
        >>>span
            width 100%
            overflow hidden
            display inline-block
            text-align center
            text-overflow ellipsis
            white-space nowrap
    >>>.dropbox-footer
        background-color #f3f3f3

    >>>.sidebar
        opacity: 1.5;
        margin-top -12px
        margin-right -4px
        margin-bottom -12px
        border-radius 0 8px 0 0

    >>>.with-footer
        border-radius 0 8px 0 0

    >>>.without-footer
        border-radius 0 8px 8px 0

    >>>.sidebar i
        cursor pointer

    >>>.is-drag-over
        background-color #e1e1e1

    >>>.image
        opacity 1
        display block
        width 100%
        height auto
        transition .5s ease
        backface-visibility hidden

    >>>.image:hover .change-picture
        opacity 1

    >>>.change-picture-container
        cursor pointer

    >>>.change-picture
        transition .5s ease
        opacity 0
        position absolute
        top 50%
        left 50%
        transform translate(-50%, -50%)
        -ms-transform translate(-50%, -50%)
        text-align center

    >>>.change-picture-container
        position relative

    >>>.change-picture-container:hover .image
        opacity 0.3

    >>>.change-picture-container:hover .change-picture
        opacity 1

    >>>.v-menu__activator
        height 100%

    .ellipsis-content {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
