const paths = [
    {
        path: '/active-items-cost',
        name: 'ActiveItemsCostList',
        icon: 'fa fa-briefcase',
        menu: 'maintenance',
        categories: [
            'reports',
          ],
        title: 'ACTIVE_ITEMS_COST_LIST',
        component: () => import(/* webpackChunkName: "ActiveItemsCostList" */ /* webpackMode: "lazy" */ '@/pages/maintenance/activeItemsCost/ActiveItemsCostList.vue'),
    },
];
export default paths;
