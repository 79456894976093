const paths = [
    {
        path: '/request-service-type-list',
        name: 'RequestServiceTypeList',
        title: 'REQUEST_SERVICE_TYPE',
        icon: 'widgets',
        menu: 'ticket',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "requestServiceType" */ /* webpackMode: "lazy" */ '@/pages/requestServiceType/RequestServiceTypeList.vue'),
    },
    {
        path: '/request-service-type',
        title: 'REQUEST_SERVICE_TYPE',
        name: 'RequestServiceType',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "requestServiceType" */ /* webpackMode: "lazy" */ '@/pages/requestServiceType/RequestServiceType.vue'),
    },
];

export default paths;
