const paths = [
    {
        path: '/ticket-asset-priority-list',
        name: 'TicketAssetPriorityList',
        title: 'TICKET_ASSET_PRIORITY',
        icon: 'widgets',
        menu: 'ticket',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "ticketAssetPriority" */ /* webpackMode: "lazy" */ '@/pages/ticketAssetPriority/TicketAssetPriorityList.vue'),
    },
    {
        path: '/ticket-asset-priority-general',
        name: 'TicketAssetPriorityGeneral',
        title: 'TICKET_ASSET_PRIORITY',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "ticketAssetPriority" */ /* webpackMode: "lazy" */ '@/pages/ticketAssetPriority/TicketAssetPriorityGeneral.vue'),
    },
];

export default paths;
