<script>
    export default {
        name: 'ASelect',
        functional: true,
        render: function (createElement, { data, children }) {
            data.attrs = data.attrs || {};
            data.attrs.outlined = Object.keys(data.attrs).includes('outlined') ? data.attrs.outlined : true;
            data.attrs.dense = Object.keys(data.attrs).includes('dense') ? data.attrs.dense : true;

            if (data.attrs.entity) {
                data.attrs.clearable = Object.keys(data.attrs).includes('clearable') ? data.attrs.clearable : true;
                return createElement('ASelectCustom', data, children);
            }

            return createElement('ASelectDefault', data, children);
        },
    };
</script>
