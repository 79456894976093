const paths = [
    {
        path: '/help-details',
        name: 'HelpDetails',
        title: 'HELP_VIDEO_DETAILS',
        component: () => import(/* webpackChunkName: "help" */ /* webpackMode: "lazy" */ '@/pages/help/HelpDetails.vue'),
    },
    {
        path: '/help',
        name: 'Help',
        title: 'HELP',
        component: () => import(/* webpackChunkName: "help" */ /* webpackMode: "lazy" */ '@/pages/help/Help.vue'),
    },
    {
        path: '/help-menu',
        name: 'HelpMenu',
        title: 'HELP_MENU',
        component: () => import(/* webpackChunkName: "help" */ /* webpackMode: "lazy" */ '@/pages/help/HelpMenu.vue'),
    },
];

export default paths;
