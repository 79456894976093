import i18n from '@/i18n';

export default function issueTypeOrigin (provider) {
    return new Promise((resolve) => {
        const resultProvider = [];
        resultProvider.push({ ID_TIPO_ORIGEM_OCORRENCIA: -1, DESC_TIPO_ORIGEM_OCORRENCIA: i18n.t('ALL') });

        provider.forEach((item) => {
            resultProvider.push({ ID_TIPO_ORIGEM_OCORRENCIA: parseInt(item.ID_TIPO_ORIGEM_OCORRENCIA, 10), DESC_TIPO_ORIGEM_OCORRENCIA: item.DESC_TIPO_ORIGEM_OCORRENCIA });
        });

        resolve(resultProvider);
    });
}
