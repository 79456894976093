const paths = [
    {
        path: '/service-bill-sale-list',
        name: 'ServiceBillSaleList',
        title: 'SERVICES_BILL_SALES',
        icon: 'fas fa-file-invoice',
        menu: 'contracts',
        component: () => import(/* webpackChunkName: "contract" */ /* webpackMode: "lazy" */ '@/pages/contract/billSale/ServiceBillSaleList.vue'),
    },
    {
        path: '/service-bill-sale',
        name: 'ServiceBillSale',
        title: 'SERVICE_BILL_SALE',
        favCategory: 'contracts',
        component: () => import(/* webpackChunkName: "contract" */ /* webpackMode: "lazy" */ '@/pages/contract/billSale/ServiceBillSale.vue'),
    },
    {
        path: '/service-bill-sale-change-category',
        name: 'ServiceBillSaleChangeCategory',
        title: 'FINANCIAL_CATEGORY',
        component: () => import(/* webpackChunkName: "contract" */ /* webpackMode: "lazy" */ '@/pages/contract/billSale/ServiceBillSaleChangeCategory.vue'),
    },
    {
        path: '/service-bill-sale-wizard',
        name: 'ServiceBillSaleWizard',
        title: 'SERVICE_BILL_SALE',
        favCategory: 'contracts',
        component: () => import(/* webpackChunkName: "contract" */ /* webpackMode: "lazy" */ '@/pages/contract/billSale/ServiceBillSaleWizard.vue'),
    },
    {
        path: '/third-party-service-bill-sale-list',
        name: 'ThirdPartyServiceBillSaleList',
        title: 'THIRD_PARTY_SERVICE_BILLS_SALE',
        icon: 'fas fa-file-invoice',
        menu: 'contracts',
        component: () => import(/* webpackChunkName: "contract" */ /* webpackMode: "lazy" */ '@/pages/contract/billSale/ThirdPartyServiceBillSaleList.vue'),
    },
];

export default paths;
