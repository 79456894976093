const paths = [
    {
        path: '/stock-total-with-cost-list',
        name: 'Stock Total With Cost',
        title: 'STOCK_TOTAL_WITH_COST_LIST',
        icon: 'fas fa-clipboard',
        menu: 'stock',
        component: () => import(/* webpackChunkName: "stock" */ /* webpackMode: "lazy" */ '@/pages/stock/stockTotalWithCost/StockTotalWithCostList.vue'),
    },
];

export default paths;
