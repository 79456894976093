const paths = [
    {
        path: '/material-request-search',
        title: 'DELIVERY_REQUEST_SEARCH',
        name: 'MaterialRequestSearch',
        icon: 'fas fa-clipboard',
        menu: 'stock',
        component: () => import(/* webpackChunkName: "materialRequest" */ /* webpackMode: "lazy" */ '@/pages/materialRequest/MaterialRequestSearch.vue'),
    },
    {
        path: '/material-request-wizard',
        title: 'NEW_DELIVERY_REQUEST',
        name: 'MaterialRequestWizard',
        component: () => import(/* webpackChunkName: "materialRequest" */ /* webpackMode: "lazy" */ '@/pages/materialRequest/MaterialRequestWizard.vue'),
    },
    {
        path: '/material-request-general',
        title: 'DELIVERY_REQUEST_GENERAL',
        favCategory: 'stock',
        name: 'MaterialRequestGeneral',
        component: () => import(/* webpackChunkName: "materialRequest" */ /* webpackMode: "lazy" */ '@/pages/materialRequest/MaterialRequestGeneral.vue'),
    },
    {
        path: '/material-request-approval-wizard',
        title: 'APPROVAL_REQUEST',
        name: 'MaterialRequestApprovalWizard',
        component: () => import(/* webpackChunkName: "materialRequest" */ /* webpackMode: "lazy" */ '@/pages/materialRequest/MaterialRequestApprovalWizard.vue'),
    },
    {
        path: '/material-request-items-select',
        title: 'ITEM_SELECTION',
        name: 'MaterialRequestItemsSelect',
        component: () => import(/* webpackChunkName: "materialRequest" */ /* webpackMode: "lazy" */ '@/pages/materialRequest/MaterialRequestItemsSelect.vue'),
    },
    {
        path: '/material-request-kits-select',
        title: 'KIT_SELECTION',
        name: 'MaterialRequestKitsSelect',
        component: () => import(/* webpackChunkName: "materialRequest" */ /* webpackMode: "lazy" */ '@/pages/materialRequest/MaterialRequestKitsSelect.vue'),
    },
    {
        path: '/material-request-division',
        title: 'DIVIDE_MATERIAL_REQUEST',
        name: 'MaterialRequestDivision',
        component: () => import(/* webpackChunkName: "materialRequest" */ /* webpackMode: "lazy" */ '@/pages/materialRequest/MaterialRequestDivision.vue'),
    },
    {
        path: '/material-request-project-items',
        title: 'ADD_PROJECT_ITEMS',
        name: 'MaterialRequestProjectItems',
        component: () => import(/* webpackChunkName: "materialRequest" */ /* webpackMode: "lazy" */ '@/pages/materialRequest/MaterialRequestProjectItems.vue'),
    },
    {
        path: '/material-request-book-list',
        name: 'MaterialRequestReservationSearch',
        title: 'MATERIAL_REQUEST_RESERVATION_SEARCH',
        icon: 'fas fa-clipboard',
        menu: 'stock',
        component: () => import(/* webpackChunkName: "materialRequest" */ /* webpackMode: "lazy" */ '@/pages/materialRequest/MaterialRequestReservationSearch.vue'),
    },
];

export default paths;
