const paths = [
    {
        path: '/maintenance-by-team',
        name: 'MaintenanceByTeam',
        menu: 'maintenance',
        categories: [
            'reports',
          ],
        title: 'MAINTENANCES_BY_TEAM',
        component: () => import(/* webpackChunkName: "maintenanceByTeam" */ /* webpackMode: "lazy" */ '@/pages/maintenance/maintenanceByTeam/MaintenanceByTeam.vue'),
    },
];
export default paths;
