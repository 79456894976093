const paths = [
    {
        path: '/activity-type-list',
        name: 'ActivityTypeList',
        title: 'ACTIVITY_TYPE',
        menu: 'humanResources',
        icon: 'widgets',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "camunda" */ /* webpackMode: "lazy" */ '@/pages/camunda/activityType/ActivityTypeList.vue'),
    },
    {
        path: '/activity-type',
        name: 'ActivityType',
        title: 'ACTIVITY_TYPE',
        component: () => import(/* webpackChunkName: "camunda" */ /* webpackMode: "lazy" */ '@/pages/camunda/activityType/ActivityType.vue'),
    },
];

export default paths;
