<template>
    <a-container ref='container'>
        <a-row>
            <a-col cols='12'>
                <a-dynamic-command-filter
                    v-show='false'
                    ref='filter'
                    :key='getIssueMonitorData.name'
                    filter-group='all'
                    exhibition-mode='sidebar'
                    :_fab='false'
                    class='pivot-filter'
                    :_command='getIssueMonitorData'
                    @updateValues='updateDashData'
                />
            </a-col>
            <a-col cols='12' align='center' justify='center'>
                <span style='font-size:50px' v-text='data' />
            </a-col>
        </a-row>
    </a-container>
</template>

<script>
    import consts from './services/consts';
    import { GetIssueMonitorData } from './services/request';

    export default {
        name: 'IssueTotalMonitor',
        props: {
            /**
             * Command filter values sent from the Dashboard
             * @type {Object}
             * @default {}
             */
            _dashdata: {
                type: Object,
                default: () => ({
                    filters: [],
                }),
            },
        },
        data: function () {
            return {
                dashdata: this._dashdata,
                data: '',

                consts: consts,
            };
        },
        computed: {
            getIssueMonitorData: function () {
                const command = new GetIssueMonitorData();
                command.initializeLoading = false;
                command.node = 'MONITOR.TOTAL_OCORRENCIAS';
                return command;
            },
        },
        watch: {
            dashdata: {
                deep: true,
                handler: function (value) {
                    this.$emit('update-dashdata', value);
                },
            },
        },
        mounted: function () {
            this.load();
        },
        methods: {
            load: async function () {
                if (this.dashdata.filters.length === 0) {
                    return;
                }
                this.$util.loadCommandFilter(this.getIssueMonitorData, this.dashdata.filters);
                this.$refs.filter.refresh(true);
            },

            updateDashData: function (items) {
                this.dashdata.filters = this.$util.getFiltersByCommand(this.getIssueMonitorData);
                this.loadData(items);
            },

            loadData: async function (items) {
                try {
                    this.data = items[0].TOTAL;
                } catch (reject) {
                }
            },
        },
    };
</script>
