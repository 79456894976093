const paths = [
    {
        path: '/allocation-movement-reason-person-allocation',
        title: 'MOVEMENT_REASON_PERSON_ALLOCATION',
        name: 'MOVEMENT_REASON_PERSON_ALLOCATION',
        icon: 'fa fa-briefcase',
        menu: 'registration',
        component: () => import(/* webpackChunkName: "workDiary" */ /* webpackMode: "lazy" */ '@/pages/workDiary/allocationMovementReasonPersonAllocation/AllocationMovementReasonPersonAllocation.vue'),
    },
];

export default paths;
