const paths = [
    {
        path: '/transaction-list',
        title: 'TRANSACTIONS',
        name: 'TransactionList',
        icon: '',
        menu: 'financial',
        component: () => import(/* webpackChunkName: "transaction" */ /* webpackMode: "lazy" */ '@/pages/transaction/TransactionList.vue'),
    },
    {
        path: '/transaction-details',
        name: 'TransactionDetails',
        title: 'TRANSACTION',
        component: () => import(/* webpackChunkName: "transaction" */ /* webpackMode: "lazy" */ '@/pages/transaction/TransactionDetails.vue'),
    },
    {
        path: '/bank-statement-details',
        name: 'BankStatementDetails',
        title: 'BANK_STATEMENT',
        component: () => import(/* webpackChunkName: "transaction" */ /* webpackMode: "lazy" */ '@/pages/transaction/BankStatementDetails.vue'),
    },
];

export default paths;
