const paths = [
    {
        path: '/defective-asset-report',
        title: 'DEFECTIVE_ASSET_REPORT',
        name: 'DefectiveAssetReport',
        menu: 'ticket',
        component: () => import(/* webpackChunkName: "defectiveAssetReport" */ /* webpackMode: "lazy" */ '@/pages/defectiveAssetReport/DefectiveAssetReport.vue'),
    },
];

export default paths;
