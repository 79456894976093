const paths = [
    {
        path: '/cancellation-reasons-list',
        name: 'CancellationReasonsList',
        title: 'CANCELLATION_REASONS',
        icon: 'widgets',
        menu: 'maintenance',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "cancellationReason" */ /* webpackMode: "lazy" */ '@/pages/cancellationReason/CancellationReasonsList'),
    },
    {
        path: '/cancellation-reason-general',
        name: 'CancellationReasonGeneral',
        title: 'CANCELLATION_REASON',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "cancellationReason" */ /* webpackMode: "lazy" */ '@/pages/cancellationReason/CancellationReasonGeneral'),
    },
];

export default paths;
