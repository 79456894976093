import Vue from 'vue';
import i18n from '@/i18n';
import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
const { $select, $autocomplete, $moment, $util, } = Vue.prototype;
export class GetMaintenanceByTeam extends CallCommand {
    constructor() {
        super({
            name: 'AtendimentosPorEquipe',
            type: 'SEARCH',
            node: 'EQUIPES.EQUIPE',
            fields: {
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('WORKSITE'),
                        entity: $select.worksites,
                        multiple: true,
                    },
                }),
                initialDate: new Field({
                    name: 'DATA_INICIO',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('INITIAL_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                    value: $moment().subtract(1, 'months').format('DD/MM/YYYY'),
                }),
                conclusionDate: new Field({
                    name: 'DATA_CONCLUSAO',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('ENDING_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                serviceWithSolution: new Field({
                    name: 'ATENDIMENTO_COM_SOLUCAO',
                    component: 'ACheckbox',
                    parser: $util.booleanToNumber,
                    value: false,
                    props: {
                        label: i18n.t('SERVICE_WITH_SOLUTION'),
                    },
                }),
                order: new Field({
                    name: 'ORDEM',
                }),
                initialHour: new Field({
                    name: 'HORA_INICIO',
                    component: 'ATimePicker',
                    props: {
                        label: i18n.t('INITIAL_HOUR'),
                        'text-field': true,
                        'return-serve': true,
                    },
                }),
                conclusionHour: new Field({
                    name: 'HORA_CONCLUSAO',
                    component: 'ATimePicker',
                    props: {
                        label: i18n.t('ENDING_HOUR'),
                        'text-field': true,
                        'return-serve': true,
                    },
                }),
                teamType: new Field({
                    name: 'TIPO_EQUIPE',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('TEAM_TYPE'),
                        entity: $select.teamType,
                    },
                }),
                dayToDay: new Field({
                    name: 'DIA_A_DIA',
                }),
                productivity: new Field({
                    name: 'PRODUTIVIDADE',
                }),
                companyUserId: new Field({
                    name: 'ID_EMPRESA_USUARIA',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('COMPANY'),
                        entity: $autocomplete.userCompany,
                    },
                }),
                locationGroupingId: new Field({
                    name: 'ID_AGRUPAMENTO_LOCALIZACAO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('ALLOWED_LOCATIONS'),
                        entity: $autocomplete.locationGrouping,
                    },
                }),
                maintenanceStatus: new Field({
                    name: 'ID_STATUS_ATENDIMENTO_PS',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('MAINTENANCE_STATUS'),
                        entity: $select.maintenanceStatus,
                        multiple: false,
                    },
                }),
            },
        });
    }
    async callCommand(param, debug, config) {
        return super.callCommand(param, debug, config);
    }
}
export class GetMonthlyMaintenanceByTeam extends CallCommand {
    constructor() {
        super({
            name: 'AtendimentosPorEquipeMensal',
            type: 'SEARCH',
            node: 'MESES.MES',
            fields: {
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                }),
                teamId: new Field({
                    name: 'ID_EQUIPE',
                }),
                initialDate: new Field({
                    name: 'DATA_INICIO',
                }),
                conclusionDate: new Field({
                    name: 'DATA_CONCLUSAO',
                }),
                month: new Field({
                    name: 'MES',
                }),
                year: new Field({
                    name: 'ANO',
                }),
                teamType: new Field({
                    name: 'TIPO_EQUIPE',
                }),
                serviceWithSolution: new Field({
                    name: 'ATENDIMENTO_COM_SOLUCAO',
                }),
                domainId: new Field({
                    name: 'ID_DOMINIO',
                }),
            },
        });
    }
    async callCommand(param, debug, config) {
        return super.callCommand(param, debug, config);
    }
}
