const theme = {
    color: {},
    image: {},
    initializeOptions: function (options) {
        this.color = options.color || {};
        this.image = options.image || {};
    },
    getImage: function (value) {
        return this.image[value] || null;
    },
    getColor: function (value) {
        return this.color[value] || null;
    },
};

export default theme;
