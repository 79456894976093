const paths = [
    {
        path: '/measurement-list',
        name: 'MeasurementList',
        icon: 'fa fa-briefcase',
        title: 'MEASUREMENT_LISTING',
        menu: 'contracts',
        component: () => import(/* webpackChunkName: "contract" */ /* webpackMode: "lazy" */ '@/pages/contract/measurement/MeasurementList.vue'),
    },
    {
        path: '/measurement',
        name: 'Measurement',
        title: 'MEASUREMENTS',
        favCategory: 'contracts',
        component: () => import(/* webpackChunkName: "contract" */ /* webpackMode: "lazy" */ '@/pages/contract/measurement/Measurement.vue'),
    },
    {
        path: '/measurement-general',
        name: 'MeasurementGeneral',
        title: 'MEASUREMENTS',
        favCategory: 'contracts',
        component: () => import(/* webpackChunkName: "contract" */ /* webpackMode: "lazy" */ '@/pages/contract/measurement/MeasurementGeneral.vue'),
    },
    {
        path: '/measurement-project',
        name: 'MeasurementProject',
        title: 'MEASUREMENTS',
        favCategory: 'contracts',
        component: () => import(/* webpackChunkName: "contract" */ /* webpackMode: "lazy" */ '@/pages/contract/measurement/MeasurementProject.vue'),
    },
    {
        path: '/measurement-item',
        name: 'MeasurementItem',
        title: 'MEASUREMENTS',
        favCategory: 'contracts',
        component: () => import(/* webpackChunkName: "contract" */ /* webpackMode: "lazy" */ '@/pages/contract/measurement/MeasurementItem.vue'),
    },
    {
        path: '/measurement-asset',
        name: 'MeasurementAsset',
        title: 'MEASUREMENTS',
        favCategory: 'contracts',
        component: () => import(/* webpackChunkName: "contract" */ /* webpackMode: "lazy" */ '@/pages/contract/measurement/MeasurementAsset.vue'),
    },
    {
        path: '/measurement-project-data',
        name: 'MeasurementProjectData',
        title: 'PROJECT',
        favCategory: 'contracts',
        component: () => import(/* webpackChunkName: "contract" */ /* webpackMode: "lazy" */ '@/pages/contract/measurement/MeasurementProjectData.vue'),
    },
    {
        path: '/measurement-project-item',
        name: 'MeasurementProjectItem',
        title: 'PROJECT',
        favCategory: 'contracts',
        component: () => import(/* webpackChunkName: "contract" */ /* webpackMode: "lazy" */ '@/pages/contract/measurement/MeasurementProjectDataItem.vue'),
    },
    {
        path: '/measurement-monitoring',
        name: 'MeasurementMonitoring',
        title: 'MEASUREMENTS_MONITORING',
        menu: 'contracts',
        categories: [
            'reports',
          ],
        component: () => import(/* webpackChunkName: "contract" */ /* webpackMode: "lazy" */ '@/pages/contract/measurement/MeasurementMonitoring.vue'),
    },
];

export default paths;
