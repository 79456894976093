const paths = [
    {
        path: '/stock-center-list',
        name: 'StockCenterList',
        title: 'STOCK_CENTER',
        icon: 'widgets',
        menu: 'stock',
        component: () => import(/* webpackChunkName: "stockcenter" */ /* webpackMode: "lazy" */ '@/pages/stockCenter/StockCenterList.vue'),
    },
    {
        path: '/stock-center-permissions-group',
        name: 'StockCenterPermissionsGroup',
        title: 'STOCK_CENTER_PERMISSIONS_GROUP',
        component: () => import(/* webpackChunkName: "stockcenter" */ /* webpackMode: "lazy" */ '@/pages/stockCenter/StockCenterPermissionsGroup.vue'),
    },
    {
        path: '/stock-center',
        name: 'StockCenter',
        title: 'STOCK_CENTER',
        favCategory: 'stock',
        component: () => import(/* webpackChunkName: "stockcenter" */ /* webpackMode: "lazy" */ '@/pages/stockCenter/StockCenter.vue'),
    },
];

export default paths;
