export default [
    {
        path: '/email-registration-activity-route-list',
        title: 'EMAIL_REGISTRATION_ACTIVITY_ROUTE',
        name: 'EmailRegistrationActivityRouteList',
        icon: 'fa fa-envelope',
        menu: 'analysis',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "emailRegistration" */ /* webpackMode: "lazy" */ '@/pages/activityRoute/emailRegistration/EmailRegistrationActivityRouteList.vue'),
    },
    {
        path: '/email-registration-activity-route',
        title: 'EMAIL_REGISTRATION_ACTIVITY_ROUTE',
        name: 'EmailRegistrationActivityRoute',
        icon: 'fa fa-envelope',
        component: () => import(/* webpackChunkName: "emailRegistration" */ /* webpackMode: "lazy" */ '@/pages/activityRoute/emailRegistration/EmailRegistrationActivityRoute.vue'),
    },
];
