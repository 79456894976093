const paths = [
    {
        path: '/units',
        title: 'UNITS',
        name: 'UnitList',
        icon: 'widgets',
        menu: 'stock',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "unit" */ /* webpackMode: "lazy" */ '@/pages/entries/unit/UnitList.vue'),
    },
    {
        path: '/unit',
        title: 'UNIT',
        name: 'Unit',
        favCategory: 'stock',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "unit" */ /* webpackMode: "lazy" */ '@/pages/entries/unit/Unit.vue'),
    },
];

export default paths;
