const paths = [
    {
        path: '/budget-composition-type-list',
        title: 'BUDGET_COMPOSITION',
        name: 'BudgetCompositionTypeList',
        icon: 'fa fa-briefcase',
        menu: 'budgets',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "budget" */ /* webpackMode: "lazy" */ '@/pages/budget/BudgetCompositionTypeList.vue'),
    },
    {
        path: '/budget-list',
        title: 'BUDGETS',
        name: 'BudgetList',
        icon: 'fa fa-briefcase',
        menu: 'budgets',
        component: () => import(/* webpackChunkName: "budget" */ /* webpackMode: "lazy" */ '@/pages/budget/BudgetList.vue'),
    },
    {
        path: '/budget-wizard',
        title: 'BUDGET',
        name: 'BudgetWizard',
        component: () => import(/* webpackChunkName: "budget" */ /* webpackMode: "lazy" */ '@/pages/budget/BudgetWizard.vue'),
    },
    {
        path: '/budget',
        title: 'BUDGET',
        name: 'Budget',
        favCategory: 'budgets',
        component: () => import(/* webpackChunkName: "budget" */ /* webpackMode: "lazy" */ '@/pages/budget/Budget.vue'),
    },
    {
        path: '/budget-general',
        title: 'BUDGET',
        name: 'BudgetGeneral',
        component: () => import(/* webpackChunkName: "budget" */ /* webpackMode: "lazy" */ '@/pages/budget/BudgetGeneral.vue'),
    },
    {
        path: '/budget-values-details',
        title: 'BUDGET',
        name: 'BudgetValuesDetails',
        component: () => import(/* webpackChunkName: "budget" */ /* webpackMode: "lazy" */ '@/pages/budget/BudgetValuesDetails.vue'),
    },
    {
        path: '/budget-values-items-wizard',
        title: 'BUDGET',
        name: 'BudgetValuesItemsWizard',
        component: () => import(/* webpackChunkName: "budget" */ /* webpackMode: "lazy" */ '@/pages/budget/BudgetValuesItemsWizard.vue'),
    },
    {
        path: '/budget-invite-to-event-by-email',
        title: 'INVITE_TO_EVENT_BY_EMAIL',
        name: 'BudgetInviteToEventByEmail',
        component: () => import(/* webpackChunkName: "budget" */ /* webpackMode: "lazy" */ '@/pages/budget/BudgetInviteToEventByEmail.vue'),
    },
    {
        path: '/budget-values-quotation-wizard',
        title: 'QUOTATION',
        name: 'BudgetValuesQuotationWizard',
        component: () => import(/* webpackChunkName: "budget" */ /* webpackMode: "lazy" */ '@/pages/budget/BudgetValuesQuotationWizard.vue'),
    },
];

export default paths;
