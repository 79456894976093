const paths = [
  {
      path: '/late-issue-alert-type-list',
      name: 'LateIssueAlertTypeList',
      title: 'LATE_ISSUE_ALERT_TYPE',
      icon: 'widgets',
      menu: 'ticket',
      categories: [
          'configurations',
      ],
      component: () => import(/* webpackChunkName: "lateIssueAlertType" */ /* webpackMode: "lazy" */ '@/pages/issue/lateIssueAlertType/LateIssueAlertTypeList'),
  },
  {
    path: '/late-issue-alert-type',
    name: 'LateIssueAlertType',
    title: 'LATE_ISSUE_ALERT_TYPE',
    favCategory: 'ticket',
    component: () => import(/* webpackChunkName: "lateIssueAlertType" */ /* webpackMode: "lazy" */ '@/pages/issue/lateIssueAlertType/LateIssueAlertType'),
  },
  {
    path: '/late-issue-alert-type-wizard',
    name: 'LateIssueAlertTypeWizard',
    title: 'LATE_ISSUE_ALERT_TYPE',
    component: () => import(/* webpackChunkName: "lateIssueAlertType" */ /* webpackMode: "lazy" */ '@/pages/issue/lateIssueAlertType/LateIssueAlertTypeWizard'),
  },
];

export default paths;
