const paths = [
    {
        path: '/light-spot-style-list',
        title: 'LIGHT_SPOT_STYLE',
        name: 'LightSpotStyleList',
        menu: 'maintenance',
        categories: [
            'configurations',
        ],
        icon: 'fa fa-lightbulb',
        component: () => import(/* webpackChunkName: "lightSpotStyle" */ /* webpackMode: "lazy" */ '@/pages/lightSpotStyle/LightSpotStyleList.vue'),
    },
    {
        path: '/light-spot-style-general',
        title: 'LIGHT_SPOT_STYLE',
        name: 'LightSpotStyleGeneral',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "lightSpotStyle" */ /* webpackMode: "lazy" */ '@/pages/lightSpotStyle/LightSpotStyleGeneral.vue'),
    },
];
export default paths;
