const paths = [
    {
        path: '/user-company-list',
        title: 'USER_COMPANIES',
        name: 'UserCompanyList',
        icon: 'fa fa-user-circle',
        menu: 'registration',
        component: () => import(/* webpackChunkName: "userCompany" */ /* webpackMode: "lazy" */ '@/pages/userCompany/UserCompanyList.vue'),
    },
    {
        path: '/user-company',
        title: 'USER_COMPANY',
        name: 'UserCompany',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "userCompany" */ /* webpackMode: "lazy" */ '@/pages/userCompany/UserCompany.vue'),
    },
];

export default paths;
