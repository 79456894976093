const paths = [
    {
        path: '/cockpit-iframe',
        name: 'CockpitIframe',
        component: () => import(/* webpackChunkName: "camunda" */ /* webpackMode: "lazy" */ '@/pages/camunda/CockpitIframe.vue'),
    },
    {
        path: '/tasklist-iframe',
        name: 'TaskListIframe',
        component: () => import(/* webpackChunkName: "camunda" */ /* webpackMode: "lazy" */ '@/pages/camunda/TaskListIframe.vue'),
    },
    {
        path: '/camunda-iframe-general',
        name: 'CamundaIframeGeneral',
        component: () => import(/* webpackChunkName: "camunda" */ /* webpackMode: "lazy" */ '@/pages/camunda/CamundaIframeGeneral.vue'),
    },
    {
        path: '/process-camunda-custom',
        name: 'ProcessCamundaCustom',
        title: 'PROCESS',
        component: () => import(/* webpackChunkName: "camunda" */ /* webpackMode: "lazy" */ '@/pages/camunda/ProcessCamundaCustom.vue'),
    },
];

export default paths;
