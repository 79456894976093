const paths = [
    {
        path: '/ticket',
        name: 'Ticket',
        favCategory: 'registration',
        title: 'TICKET',
        component: () => import(/* webpackChunkName: "ticket" */ /* webpackMode: "lazy" */ '@/pages/ticket/Ticket.vue'),
    },
    {
        path: '/ticket-asset-wizard',
        name: 'TicketAssetWizard',
        title: 'TICKET_ASSET',
        component: () => import(/* webpackChunkName: "ticket" */ /* webpackMode: "lazy" */ '@/pages/ticket/TicketAssetWizard.vue'),
    },
    {
        path: '/ticket-asset-history',
        name: 'TicketAssetHistory',
        title: 'TICKET_ASSET_HISTORY',
        component: () => import(/* webpackChunkName: "ticket" */ /* webpackMode: "lazy" */ '@/pages/ticket/TicketAssetHistory.vue'),
    },
    {
        path: '/ticket-asset-list-photos',
        name: 'TicketAssetListPhotos',
        title: 'TICKET_ASSET_PHOTOS',
        component: () => import(/* webpackChunkName: "ticket" */ /* webpackMode: "lazy" */ '@/pages/ticket/TicketAssetListPhotos.vue'),
    },
    {
        path: '/ticket-opened-issues-by-complainer',
        name: 'TicketOpenedIssuesByComplainer',
        title: 'OPENED_ISSUES',
        component: () => import(/* webpackChunkName: "ticket" */ /* webpackMode: "lazy" */ '@/pages/ticket/TicketOpenedIssuesByComplainer.vue'),
    },
    {
        path: '/ticket-alert',
        name: 'TicketAlert',
        title: 'ALERT',
        component: () => import(/* webpackChunkName: "ticket" */ /* webpackMode: "lazy" */ '@/pages/ticket/TicketAlert.vue'),
    },
    {
        path: '/ticket-list',
        name: 'TicketList',
        title: 'TICKETS',
        menu: 'ticket',
        icon: 'fa fa-hard-hat',
        component: () => import(/* webpackChunkName: "ticket" */ /* webpackMode: "lazy" */ '@/pages/ticket/TicketList.vue'),
    },
    {
        path: '/ticket-special-consultations',
        name: 'TicketSpecialConsultations',
        title: 'SPECIAL_CONSULTATIONS',
        menu: 'ticket',
        icon: 'fa fa-hard-hat',
        component: () => import(/* webpackChunkName: "ticket" */ /* webpackMode: "lazy" */ '@/pages/ticket/TicketSpecialConsultations.vue'),
    },
    {
        path: '/ticket-details-cancel-ticket',
        name: 'TicketDetailsCancelTicket',
        title: 'TICKETS',
        component: () => import(/* webpackChunkName: "ticket" */ /* webpackMode: "lazy" */ '@/pages/ticket/TicketDetailsCancelTicket.vue'),
    },
    {
        path: '/historical-ticket',
        name: 'HistoricalTicket',
        title: 'HISTORICAL_TICKET',
        component: () => import(/* webpackChunkName: "ticket" */ /* webpackMode: "lazy" */ '@/pages/ticket/HistoricalTicket.vue'),
    },
    {
        path: '/ticket-linked-issues',
        name: 'TicketLinkedIssues',
        title: 'LINKED_ISSUES',
        component: () => import(/* webpackChunkName: "ticket" */ /* webpackMode: "lazy" */ '@/pages/ticket/TicketLinkedIssues.vue'),
    },
    {
        path: '/ticket-pending-purchase',
        name: 'TicketPendingPurchase',
        menu: 'shopping',
        icon: 'mdi-playlist-edit',
        title: 'PENDING_PURCHASE_TICKET',
        categories: [
          'reports',
        ],
      component: () => import(/* webpackChunkName: "ticket" */ /* webpackMode: "lazy" */ '@/pages/ticket/TicketPendingPurchase.vue'),
    },
    {
        path: '/ticket-pending-purchase-item-flow',
        name: 'TicketPendingPurchaseItemFlow',
        title: 'MATERIAL_PHYSICAL_FLOW',
        component: () => import(/* webpackChunkName: "ticket" */ /* webpackMode: "lazy" */ '@/pages/ticket/TicketPendingPurchaseItemFlow.vue'),
    },
    {
        path: '/ticket-pending-purchase-historical-handling',
        name: 'TicketPendingPurchaseHistoricalHandling',
        title: 'HISTORICAL_HANDLING',
        component: () => import(/* webpackChunkName: "ticket" */ /* webpackMode: "lazy" */ '@/pages/ticket/TicketPendingPurchaseHistoricalHandling.vue'),
    },
    {
        path: '/alert-detail-chat',
        name: 'AlertDetailChat',
        title: 'ALERT_CHAT',
        component: () => import(/* webpackChunkName: "ticket" */ /* webpackMode: "lazy" */ '@/pages/ticket/AlertDetailChat.vue'),
    },
    {
        path: '/ticket-specificAssets-wizard',
        name: 'TicketSpecificAssetsWizard',
        title: 'TICKETS',
        component: () => import(/* webpackChunkName: "ticket" */ /* webpackMode: "lazy" */ '@/pages/ticket/TicketSpecificAssetsWizard.vue'),
    },
    {
        path: '/ticket-asset-attributes',
        name: 'TicketAssetAttributes',
        title: 'ISSUE_ATTRIBUTES',
        component: () => import(/* webpackChunkName: "ticket" */ /* webpackMode: "lazy" */ '@/pages/ticket/TicketAssetAttributes.vue'),
    },
    {
        path: '/ticket-alerts-panel',
        name: 'TicketAlertsPanel',
        menu: 'ticket',
        icon: 'fas fa-clipboard',
        title: 'TICKET_ALERTS_PANEL',
        categories: [
          'reports',
        ],
      component: () => import(/* webpackChunkName: "ticket" */ /* webpackMode: "lazy" */ '@/pages/ticket/TicketAlertsPanel.vue'),
    },
    {
        path: '/ticket-asset-deadline-history',
        name: 'TicketAssetDeadlineHistory',
        title: 'DEADLINE_CALCULATION',
        component: () => import(/* webpackChunkName: "ticket" */ /* webpackMode: "lazy" */ '@/pages/ticket/TicketAssetDeadlineHistory.vue'),
    },
    {
        path: '/ticket-preview',
        name: 'TicketPreview',
        component: () => import(/* webpackChunkName: "ticket" */ /* webpackMode: "lazy" */ '@/pages/ticket/TicketPreview.vue'),
    },
    {
        path: '/ticket-fields-complainer-attributes',
        name: 'TicketFieldsComplainerAttributes',
        component: () => import(/* webpackChunkName: "ticket" */ /* webpackMode: "lazy" */ '@/pages/ticket/TicketFieldsComplainerAttributes.vue'),
    },
    {
        path: '/ticket-complainer-list',
        name: 'TicketComplainerList',
        component: () => import(/* webpackChunkName: "ticket" */ /* webpackMode: "lazy" */ '@/pages/ticket/TicketComplainerList.vue'),
    },
];

export default paths;
