<script>
    export default {
        name: 'ABtnAdd',
        functional: true,
        render: function (createElement, { data, parent, children }) {
            data.attrs  = data.attrs || {}
            delete data.attrs['assign-user'];
            data.attrs._iconType = 'assign-user';
            children = children || [];
            if ((!data.scopedSlots || !data.scopedSlots['tooltip-body']) && !data.attrs['_tooltip-text']) {
                data.attrs['_tooltip-text'] = parent.$t('ASSIGN');
            }
            return createElement('ABtnDefault', data, children);
        },
    };
</script>
