const paths = [
    {
        path: '/competence-group',
        name: 'CompetenceGroup',
        title: 'COMPETENCE_GROUP',
        component: () => import(/* webpackChunkName: "technicalCompetenceGroup" */ /* webpackMode: "lazy" */ '@/pages/technicalCompetenceGroup/CompetenceGroup'),
    },
    {
        path: '/competence-group-list',
        name: 'CompetenceGroupList',
        title: 'COMPETENCE_GROUP_LIST',
        menu: 'humanResources',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "technicalCompetenceGroup" */ /* webpackMode: "lazy" */ '@/pages/technicalCompetenceGroup/CompetenceGroupList'),
    },
];

export default paths;
