<script>
export default {
    name: 'AProgressLinearSegmented',
    functional: true,
    props: {
        /**
         * Progress Bar Segments
         * @type {Array}
         * @require {true}
         */
        _segments: {
            type: Array,
            required: true,
        },
    },
    render: function (createElement, data) {
        const getSegmentStyle = (segment) => ({
            width: segment.value + '%',
            backgroundColor: segment.color,
        });
        const progressSegments = data.props._segments.map((segment, index) =>
            createElement('div', {
                class: 'progress',
                style: getSegmentStyle(segment),
                key: index,
            })
        );
        data.class = 'progress-bar';
        return createElement('div', data, progressSegments);
    },
};
</script>

<style>
.progress-bar {
    width: 100%;
    height: 6px;
    border-radius: 3px;
    background-color: #ccc;
    display: flex;
    overflow: hidden;
}
.progress {
    height: 100%;
}
</style>
