const paths = [
    {
        path: '/production-center-report',
        name: 'ProductionCenterReport',
        title: 'PRODUCTION_CENTER_REPORT',
        icon: 'fas fa-clipboard',
        menu: 'budgets',
        categories: [
            'reports',
          ],
        component: () => import(/* webpackChunkName: "productionCenter" */ /* webpackMode: "lazy" */ '@/pages/productionCenter/ProductionCenterReport.vue'),

    },
];

export default paths;
