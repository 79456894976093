export default function contract (provider) {
    return new Promise((resolve) => {
        const resultProvider = [];

        provider.forEach((item) => {
            resultProvider.push({ ID_CONTRATO: item.ID_ITEM_FABRICANTE, DESC_ORCAMENTO: `${item.ID_CONTRATO} - ${item.NOME_CLIENTE} - ${item.DESC_ORCAMENTO}` });
        });

        resolve(resultProvider);
    });
}
