const paths = [
    {
        path: '/completed-form-list',
        name: 'CompletedFormsList',
        title: 'COMPLETED_FORMS',
        icon: 'mdi-form-select',
        menu: 'ticket',
        component: () => import(/* webpackChunkName: "completedForm" */ /* webpackMode: "lazy" */ '@/pages/completedForm/CompletedFormsList.vue'),
    },
    {
        path: '/completed-form-general',
        name: 'CompletedFormGeneral',
        title: 'COMPLETED_REPORT_GENERAL',
        favCategory: 'form',
        component: () => import(/* webpackChunkName: "completedForm" */ /* webpackMode: "lazy" */ '@/pages/completedForm/CompletedFormGeneral.vue'),
    },
    {
        path: '/completed-form-new',
        name: 'CompletedFormNew',
        title: 'NEW_COMPLETED_REPORT',
        component: () => import(/* webpackChunkName: "completedForm" */ /* webpackMode: "lazy" */ '@/pages/completedForm/CompletedFormNew.vue'),
    },
    {
        path: '/completed-form-issues-list',
        name: 'CompletedFormIssuesList',
        title: 'COMPLETED_FORMS_ISSUES_LIST',
        component: () => import(/* webpackChunkName: "completedForm" */ /* webpackMode: "lazy" */ '@/pages/completedForm/CompletedFormIssuesList.vue'),
    },
];

export default paths;
