import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import { parseSuccessResponse, showErrorsResponse } from '../utils';
export class GetClientApp extends CallCommand {
    constructor() {
        super({
            node: 'RESPONSE',
            name: 'get_comp_client-app_0',
            type: 'NONE',
            fields: {
                include: new Field({
                    name: 'include',
                    value: [
                        { relation: 'config' },
                    ],
                }),
                clientId: new Field({
                    name: 'client_id',
                }),
            },
            customRequestParser: function (params) {
                const filter = {
                    include: params.include || [],
                };
                const pathParams = {
                    PARAM_0: params.clientId,
                };
                return {
                    PATH_PARAMS: JSON.stringify(pathParams),
                    BODY: `filter=${encodeURIComponent(JSON.stringify(filter))}`,
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveClientApp extends CallCommand {
    constructor() {
        super({
            node: 'RESPONSE',
            name: 'post_comp_client-app_0',
            type: 'SAVE',
            fields: {
                name: new Field({
                    name: 'name',
                }),
                companyId: new Field({
                    name: 'company_id',
                }),
            },
            customRequestParser: function (params) {
                const data = {
                    name: params.name,
                    company_id: params.companyId,
                };
                const pathParams = {
                    PARAM_0: params.clientId,
                };
                return {
                    PATH_PARAMS: JSON.stringify(pathParams),
                    BODY: JSON.stringify(data),
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class EditClientApp extends CallCommand {
    constructor() {
        super({
            node: 'RESPONSE',
            name: 'patch_comp_client-app_0_1',
            type: 'SAVE',
            fields: {
                clientId: new Field({
                    name: 'client_id',
                }),
                clientAppId: new Field({
                    name: 'client_app_id',
                }),
                name: new Field({
                    name: 'name',
                }),
            },
            customRequestParser: function (params) {
                const data = {
                    name: params.name,
                };
                const pathParams = {
                    PARAM_0: params.clientId,
                    PARAM_1: params.clientAppId,
                };
                return {
                    PATH_PARAMS: JSON.stringify(pathParams),
                    BODY: JSON.stringify(data),
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class RefreshTokenClientApp extends CallCommand {
    constructor() {
        super({
            node: 'RESPONSE',
            name: 'post_comp_client-app_0_1_refresh-token',
            type: 'SAVE',
            fields: {
                clientId: new Field({
                    name: 'client_id',
                }),
                clientAppId: new Field({
                    name: 'client_app_id',
                }),
            },
            customRequestParser: function (params) {
                const pathParams = {
                    PARAM_0: params.clientId,
                    PARAM_1: params.clientAppId,
                };
                return {
                    PATH_PARAMS: JSON.stringify(pathParams),
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class DeleteClientApp extends CallCommand {
    constructor() {
        super({
            node: 'RESPONSE',
            name: 'delete_comp_client-app_0_1',
            type: 'DELETE',
            fields: {
                clientId: new Field({
                    name: 'client_id',
                }),
                clientAppId: new Field({
                    name: 'client_app_id',
                }),
            },
            customRequestParser: function (params) {
                const pathParams = {
                    PARAM_0: params.clientId,
                    PARAM_1: params.clientAppId,
                };
                return {
                    PATH_PARAMS: JSON.stringify(pathParams),
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
