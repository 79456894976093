<template>
    <a-card outlined :color='getStateColor(currentStatus)' class='white--text'>
        <a-container>
            <a-row>
                <a-col class='pt-0 pb-0'>
                    <span class='text-subtitle-1 font-weight-medium'>
                        {{$t('STATUS').toUpperCase()}}
                    </span>
                </a-col>
            </a-row>
            <a-row align='center'>
                <a-col cols='auto'>
                    <span class='text-h5 font-weight-medium' v-html='currentStatus ? currentStatus[_descriptionField] : "-"' />
                </a-col>
                <a-col v-if='$slots["status-function-button"]' cols='auto'>
                    <slot name='status-function-button' />
                </a-col>
                <a-col v-if='_editEnabled' cols='auto'>
                    <a-icon edit color='white' :small='false' @click='editStatus' />
                    <a-dialog v-model='statusDialog' content-class='no-box-shadow-dialog' overlay-opacity='0.8'>
                        <a-container>
                            <a-row justify='center'>
                                <a-col v-for='(state, idx) in availableStates' :key='idx' :cols='isMobile ? "12" : "auto"'>
                                    <a-tooltip top :disabled='!_tooltipField || !state[_tooltipField]'>
                                        <template v-slot:activator='{ on }'>
                                            <a-card
                                                class='fill-height'
                                                :width='isMobile ? "100%" : 250'
                                                :color='getStateColor(state)'
                                                v-on='on'
                                                @click='clickStatus(state)'
                                            >
                                                <a-card-text :class='`fill-height white--text${isMobile ? " pa-5" : " px-8 py-10"}`'>
                                                    <a-row dense class='fill-height'>
                                                        <a-col cols='12' class='title font-weight-bold'>
                                                            <span v-html='state[_descriptionField]' />
                                                        </a-col>
                                                    </a-row>
                                                </a-card-text>
                                            </a-card>
                                        </template>
                                        {{_tooltipField ? state[_tooltipField] : null}}
                                    </a-tooltip>
                                </a-col>
                                <slot name='state-dialog' :status='currentStatus' />
                                <a-col v-if='isMobile' cols='auto'>
                                    <a-btn text color='white' @click='statusDialog = false'>
                                        {{$t('CANCEL')}}
                                    </a-btn>
                                </a-col>
                            </a-row>
                        </a-container>
                    </a-dialog>
                </a-col>
            </a-row>
        </a-container>
    </a-card>
</template>

<script>
    export default {
        name: 'AStatus',
        props: {
            /**
             * Available states array
             * @type {Array}
             * @default {[]}
             */
            _availableStates: {
                type: Array,
                default: () => [],
            },
            /**
             * Id Field Name
             * @type {String}
             * @default {undefined}
             * @required {true}
             */
            _idField: {
                type: String,
                default: undefined,
                required: true,
            },
            /**
             * Description Field Name
             * @type {String}
             * @default {undefined}
             * @required {true}
             */
            _descriptionField: {
                type: String,
                default: undefined,
                required: true,
            },
            /**
             * Current Status ID
             * @type {[Number, String]}
             * @default {undefined}
             */
            _currentStatusId: {
                type: [Number, String],
                default: undefined,
            },
            /**
             * Edit button enabled
             * @type {Boolean}
             * @default {true}
             */
            _editEnabled: {
                type: Boolean,
                default: true,
            },
            /**
             * Tooltip field name
             * @type {String}
             * @default {undefined}
             * @required {false}
             */
            _tooltipField: {
                type: String,
                default: undefined,
                required: false,
            },
        },
        data: function () {
            return {
                availableStates: [],
                statusDialog: false,
            };
        },
        computed: {
            isMobile: function () {
                return this.$vuetify.breakpoint.smAndDown;
            },
            currentStatus: function () {
                const id = this._currentStatusId;
                return this.availableStates.find(s => s[this._idField] == id);
            },
        },
        watch: {
            _availableStates: {
                handler: function (value) {
                    this.availableStates = value;
                    this.$forceUpdate();
                },
                immediate: true,
                deep: true,
            },
        },
        mounted: function () {
            this.load();
        },
        methods: {
            load: function () {
            },
            getStateColor: function (state) {
                if (!state || !state.COR) return 'primary';
                return state.COR;
            },
            clickStatus: function (state) {
                this.$emit('click-status', state);
            },
            editStatus: function () {
                this.statusDialog = true;
                this.$emit('click');
            },
        },
    };
</script>

<style lang='scss' scoped>
    ::v-deep .no-box-shadow-dialog {
        box-shadow: none;
    }
</style>
