<script>
    export default {
        name: 'AContainer',
        functional: true,
        render: function (createElement, { data, children }) {
            data.attrs = data.attrs || {};
            if (!Object.keys(data.attrs).includes('fluid')) {
                data.attrs.fluid = true;
            }
            return createElement('VContainer', data, children);
        },
    };
</script>
