const paths = [
    {
        path: '/courses-and-certificates-types',
        name: 'CoursesAndCertificatesTypes',
        title: 'COURSES_AND_CERTIFICATES_TYPES',
        icon: 'fa fa-briefcase',
        menu: 'humanResources',
        component: () => import(/* webpackChunkName: "coursesAndCertificatesTypes" */ /* webpackMode: "lazy" */ '@/pages/coursesAndCertificatesTypes/CoursesAndCertificatesTypes.vue'),
    },
    {
        path: '/courses-and-certificate-types-register',
        name: 'CoursesAndCertificatesTypesRegister',
        title: 'CERTIFICATE',
        component: () => import(/* webpackChunkName: "coursesAndCertificatesTypes" */ /* webpackMode: "lazy" */ '@/pages/coursesAndCertificatesTypes/CoursesAndCertificatesTypesRegister.vue'),

    },
];

export default paths;
