const paths = [
    {
        path: '/maintenance-status-asset-type-link',
        name: 'MaintenanceStatusAssetTypeLink',
        title: 'MAINTENANCE_STATUS_ASSET_TYPE_LINK',
        icon: 'fa fa-link',
        menu: 'maintenance',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "maintenanceStatusAssetTypeLink" */ /* webpackMode: "lazy" */ '@/pages/maintenanceStatusAssetTypeLink/MaintenanceStatusAssetTypeLink.vue'),
    },
];

export default paths;
