const paths = [
    {
        path: '/financial-allocation-list',
        name: 'FinancialAllocationList',
        title: 'FINANCIAL_ALLOCATIONS',
        icon: 'fa fa-briefcase',
        menu: 'financial',
        component: () => import(/* webpackChunkName: "financialAllocation" */ /* webpackMode: "lazy" */ '@/pages/financialAllocation/FinancialAllocationList.vue'),
    },
];

export default paths;
