const paths = [
    {
        path: '/done-pending-dynamic-maintenance-search',
        title: 'DONE_PENDING_DYNAMIC_MAINTENANCE_SEARCH',
        name: 'DonePendingDynamicMaintenanceSearch',
        icon: 'fa fa-briefcase',
        menu: 'maintenance',
        component: () => import(/* webpackChunkName: "donePendingDynamicMaintenanceSearch" */ /* webpackMode: "lazy" */ '@/pages/donePendingDynamicMaintenanceSearch/DonePendingDynamicMaintenanceSearch.vue'),
    },
    {
        path: '/done-pending-dynamic-maintenance-map-subtitle',
        title: 'SUBTITLE',
        name: 'DonePendingDynamicMaintenanceSearchMapSubtitle',
        component: () => import(/* webpackChunkName: "donePendingDynamicMaintenanceSearch" */ /* webpackMode: "lazy" */ '@/pages/donePendingDynamicMaintenanceSearch/DonePendingDynamicMaintenanceSearchMapSubtitle.vue'),
    },
];

export default paths;
