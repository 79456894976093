<template>
    <!-- VDataTable - Não alterar -->
    <a-data-table
        :headers='headers'
        :items='filterItems'
        dense
        :_disable-options='["advanced-filters"]'
    >
        <template v-slot:header>
            <tr class='hidden-sm-and-down'>
                <th 
                    v-for='header in headers'
                    :key='header.text'
                    class="py-4 px-3 font-weight-regular"
                >
                    <a-text-field
                        v-model='filters[header.value]'
                        dense
                        hide-details
                        :label='header.text'
                    />
                </th>
            </tr>
        </template>
    </a-data-table>
</template>

<script>
    import { toLower } from 'lodash';

    export default {
        name: 'ADatatableAdvancedFilters',
        props: {
            _items: {
                type: Array,
                default: () => [],
            },
            _filters: {
                type: Object,
                default: null
            }
        },
        data: function () {
            return {
                headers: [],
                innerFilters: {}
            };
        },
        created: function () {
            this.headers = this.getHeaders();
        },
        computed: {
            filters: {
                get: function () {
                    if (this._filters) {
                        return this._filters
                    }
                    
                    return this.innerFilters;
                },
                set: function (value) {
                    if (this._filters) {
                        this.$emit('update:_filters')
                        return
                    }
                    this.innerFilters = value;                    
                }
            },
            filterItems: function () {
                const filters = Object.entries(this.filters || {})
                        .filter(([_, value]) => value && value !== '')
                        .map(([key, value]) => (item) => toLower(item[key]).includes(toLower(value)))

                return this._items.filter(item => filters.every(filter => filter(item)));
            },
        },
        methods: {
            getHeaders: function () {
                const headers = [];
                const aux = [];

                this._items.forEach((i) => {
                    Object.keys(i).forEach((k) => {
                        if (!aux.includes(k)) {
                            aux.push(k);
                            headers.push({
                                text: k,
                                value: k,
                                width: k.length * 20
                            });
                        }
                    });
                });

                return headers;
            },
        },
    };
</script>
