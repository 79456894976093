const paths = [
    {
        path: '/project-study-stage-list',
        name: 'ProjectStudyStageList',
        title: 'PROJECT_STUDY_STAGES',
        icon: 'fa fa-briefcase',
        favCategory: 'construction',
        component: () => import(/* webpackChunkName: "projectStudyStage" */ /* webpackMode: "lazy" */ '@/pages/project/projectStudyStage/ProjectStudyStageList.vue'),
    },
    {
        path: '/project-study-stage-details',
        name: 'ProjectStudyStageDetails',
        title: 'PROJECT_STUDY_STAGE',
        component: () => import(/* webpackChunkName: "projectStudyStage" */ /* webpackMode: "lazy" */ '@/pages/project/projectStudyStage/ProjectStudyStageDetails.vue'),
    },
];

export default paths;
