<template>
    <a-container ref='container' v-resize='onResize' class='container' style='width: 100%; height: 100%;'>
        <a-dynamic-command-filter
            ref='filter'
            :key='getExtract.name'
            filter-group='all'
            exhibition-mode='sidebar'
            class='pivot-filter'
            :_fab='false'
            :_command='getExtract'
            :_sidebar-props='{ _hideActions: true }'
            :_loading.sync='loading'
            @updateValues='updateData'
        />
        <chart
            ref='chart'
            :_options='chartOptions'
            style='height: 100%;'
        />
    </a-container>
</template>

<script>
    import { GetCashFlow } from '@/components/charts/cashFlow/services/request.ts';

    export default {
        name: 'ExtractChart',
        props: {
            /**
             * Prop for show if page is currently active
             * @type {Boolean}
             * @default {false}
             */
            _activePage: {
                type: Boolean,
                default: false,
            },
            /**
             * Command filter values
             * @type {Array}
             * @default {[]}
             */
            _filters: {
                type: Array,
                default: () => ([]),
            },
            /**
             * sync with parent loading
             * @type {Boolean}
             * @default {false}
             */
            _loading: {
                type: Boolean,
                default: false,
            },
            /**
             * sync with parent empty-data
             * @type {Boolean}
             * @default {false}
             */
            _emptyData: {
                type: Boolean,
                default: false,
            },
        },
        data: function () {
            return {
                categoryData: [],
                reportData: [],
                schedules: {},
                // Chart instance, used for the on() event
                chart: undefined,

                // Default chart before it's loaded with data
                chartOptions: {
                    xAxis: {
                        type: 'category',
                        name: this.$t('DATE'),
                        nameTextStyle: {
                            color: this.$vuetify.theme.currentTheme.dark,
                        },
                    },
                    yAxis: {
                        type: 'value',
                        name: this.$t('COSTS'),
                        nameTextStyle: {
                            color: this.$vuetify.theme.currentTheme.dark,
                        },
                    },
                },

                currentPeriodicity: undefined,
                filters: null,
            };
        },
        computed: {
            getExtract: function () {
                const command = new GetCashFlow();

                command.fields.initialDate = {
                    ...command.fields.initialDate,
                    component: 'ADatePicker',
                    parser: this.$util.relativeDateParser,
                    props: {
                        label: this.$t('DATE_FROM'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                };

                command.values = {
                    searchType: 3,
                    performed: 1,
                    withoutAccount: 1,
                    selectionMonth: undefined,
                    initialDate: this.$moment().startOf('month').format('DD/MM/YYYY'),
                    endDate: this.$moment().add('45', 'days').format('DD/MM/YYYY'),
                };
                return command;
            },
            loading: {
                get: function () {
                    return this._loading;
                },
                set: function (value) {
                    this.$emit('update:_loading', value);
                },
            },
            emptyData: {
                get: function () {
                    return this._emptyData;
                },
                set: function (value) {
                    this.$emit('update:_emptyData', value);
                },
            },
        },
        watch: {
            _filters: {
                immediate: true,
                handler: function (value) {
                    if (value) {
                        this.$util.loadCommandFilter(this.getExtract, value);
                    }
                },
            },
            filters: {
                deep: true,
                handler: function (val) {
                    this.$emit('update:_filters', val);
                },
            },
            _activePage: {
                handler: function (val) {
                    if (val) {
                        this.onResize();
                    }
                },
            },
        },
        mounted: function () {
            this.load();
        },
        methods: {
            onResize: function () {
                this.$nextTick(this.$refs.chart.resize);
            },
            load: async function () {
                this.chart = this.$refs.chart.getChart();
                await this.loadSchedules();
                this.formatToChart(this.schedules);
                this.loadChart();
                this.onResize();
            },
            loadSchedules: async function () {
                const result = await this.getExtract.callCommand();
                const schedules = this.$util.getArrayByNode(result, this.getExtract.node);
                this.schedules = schedules;
            },
            loadChart: function () {
                this.$refs.chart.reset();
                this.currentPeriodicity = this.getExtract.fields.searchType.value;
                this.setChartOption();
                this.addTooltip();
                this.addSerie();
                this.chart.on('click', { seriesIndex: 0 }, this.openFinancialSchedule);
            },
            formatToChart: function (data) {
                this.reportData = [];
                this.categoryData = [];
                Object.keys(data).forEach((i) => {
                    const item = data[i];
                    item.name = item.DATA_PAGA;
                    item.value = [item.OPEN, item.CLOSE, item.LOW, item.HIGH];

                    this.reportData[i] = item;
                    this.categoryData[i] = item.name;
                });
            },
            setChartOption: function () {
                const chartOptions = {
                    maintainAspectRatio: false,
                    grid: {
                        containLabel: true,
                        bottom: '20',
                    },
                    xAxis: {
                        type: 'category',
                        name: this.$t('DATE'),
                        data: this.categoryData,
                        axisLabel: {
                            show: true,
                            position: 'bottom',
                            formatter: '{value}',
                            color: this.$vuetify.theme.currentTheme.dark,
                            padding: [30, 31, 32, 33],
                        },
                    },
                    yAxis: {
                        type: 'value',
                        name: this.$t('OPEN_ISSUES'),
                        axisLabel: {
                            formatter: '{value}',
                            color: this.$vuetify.theme.currentTheme.dark,
                        },
                    },
                };

                this.$refs.chart.setOption(chartOptions);
            },
            addSerie: function () {
                this.$refs.chart.addSerie({
                    id: 1,
                    type: 'candlestick',
                    data: this.reportData,

                    itemStyle: {
                        color: this.$vuetify.theme.currentTheme.green,
                        color0: this.$vuetify.theme.currentTheme.red,
                        borderColor: this.$vuetify.theme.currentTheme.orange,
                        borderColor0: this.$vuetify.theme.currentTheme.orange,
                    },

                    animation: true,
                    left: 'right',
                    top: 'middle',
                });
            },
            addTooltip: function () {
                const tooltip = {
                    trigger: 'axis',
                    formatter: this.tooltipFormatter,
                    confine: true,
                    axisPointer: {
                        type: 'line',
                    },
                };
                this.$refs.chart.setTooltip(tooltip);
            },
            tooltipFormatter: function (params) {
                params = params[0];
                const date = params.data.DATA_PAGA;
                const opening = Number(params.data.OPEN || 0);
                const closing = Number(params.data.CLOSE || 0);
                const high = Number(params.data.HIGH || 0);
                const low = Number(params.data.LOW || 0);

                let label = `${(this.currentPeriodicity === 3 ? this.$t('DAY') : this.$t('MONTH'))}: ${date}<br />`;
                label += `${this.$t('OPENING')}: ${this.$util.asMonetary(opening)}<br />`;
                label += `${this.$t('CLOSING')}: ${this.$util.asMonetary(closing)}<br />`;
                label += `${this.$t('HIGH')}: ${this.$util.asMonetary(high)}<br />`;
                label += `${this.$t('LOW')}: ${this.$util.asMonetary(low)}<br />`;
                return label;
            },
            updateData: function (items) {
                this.emptyData = !items.length;
                this.loadData(items);
            },
            loadData: async function (items) {
                this.formatToChart(items);
                this.loadChart();
            },
            openFinancialSchedule: async function (params) {
                if (params.data.DATA_MES) {
                    const command = new GetCashFlow();
                    command.values = {
                        searchType: 3,
                        performed: 1,
                        withoutAccount: 1,
                        selectionMonth: params.data.DATA_MES,
                    };

                    const result = await command.callCommand();
                    const schedules = this.$util.getArrayByNode(result, this.getExtract.node);
                    this.schedules = [];
                    this.schedules = schedules;
                    this.loadData(this.schedules);
                    return;
                }

                const filters = this.$util.getFiltersByCommand(this.getExtract);
                filters.find(f => f.key === 'DATA_FIM').value = params.data.DATA_PAGA;
                filters.find(f => f.key === 'SEM_CONTA').value = 1;
                filters.find(f => f.key === 'REALIZADAS').value = 0;
                filters.find(f => f.key === 'TIPO_CONSULTA').value = 2;
                filters.find(f => f.key === 'CONTAS_FINANCEIRAS').value = undefined;
                this.$util.openPage({ name: 'CashFlowScheduling' }, {
                    isDialog: true,
                    props: {
                        _filters: filters,
                    },
                });
            },
            getFilters: function () {
                const filters = this.$util.getFiltersByCommand(this.getExtract);
                this.filters = filters.filter((elem) => elem.key !== 'TIPO_CONSULTA');
            },
        },
    };
</script>
