<script>
    import { getBooleanFromProp } from '../../util/helpers';

    export default {
        name: 'ADateTimePicker',
        functional: true,
        render: function (createElement, { data, children }) {
            if (Object.keys(data).length !== 0 && data.attrs) {
                if (Object.keys(data.attrs).indexOf('text-field') !== -1 && getBooleanFromProp(data.attrs['text-field'])) {
                    return createElement('ADateTimePickerCustom', data, children);
                }
            }
            return createElement('ADateTimePickerExtend', data, children);
        },
    };
</script>
