import i18n from '@/i18n';

export default function issueTypeOrigin (provider) {
    return new Promise((resolve) => {
        const resultProvider = [];
        resultProvider.push({ ID_TIPO_OCORRENCIA: -1, DESC_TIPO_OCORRENCIA: i18n.t('SAME_VALUE_AS_THE_PREVIOUS') });

        provider.forEach((item) => {
            resultProvider.push({ ID_TIPO_OCORRENCIA: parseInt(item.ID_TIPO_OCORRENCIA, 10), DESC_TIPO_OCORRENCIA: item.DESC_TIPO_OCORRENCIA });
        });

        resolve(resultProvider);
    });
}
