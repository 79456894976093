import Vue from 'vue';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';

const requireComponent = require.context('./charts', true, /\.vue$/);

const exclude = [];

requireComponent.keys().forEach((path) => {
    const { length } = path.split('/');
    const fileName = path.split('/')[length - 1];

    if (!exclude.includes(fileName.replace('.vue', ''))) {
        const componentConfig = requireComponent(path);

        const componentName = upperFirst(
            camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')),
        );

        Vue.component(componentName, componentConfig.default || componentConfig);
    }
});

Vue.component('UploadFilesCard', () => import('./UploadFilesCard/UploadFilesCard.vue'));
Vue.component('OpCard', () => import('./OpCard/OpCard.vue'));
Vue.component('OpCardItem', () => import('./OpCard/OpCardItem.vue'));
Vue.component('AddIdentifiedMaterial', () => import('./identifiedMaterial/AddIdentifiedMaterial.vue'));
