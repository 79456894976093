const paths = [
    {
        path: '/iot/device-details',
        name: 'IotDeviceDetails',
        title: 'DEVICE_DETAILS',
        component: () => import(/* webpackChunkName: "iot" */ /* webpackMode: "lazy" */ '@/pages/iot/device/IotDeviceDetails.vue'),
    },
    {
        path: '/iot/device-list',
        name: 'IotDeviceList',
        title: 'DEVICE_LIST',
        component: () => import(/* webpackChunkName: "iot" */ /* webpackMode: "lazy" */ '@/pages/iot/device/IotDeviceList.vue'),
    },
    {
        path: '/iot/device-class-details',
        name: 'IotDeviceClassDetails',
        title: 'DEVICE_CLASS',
        component: () => import(/* webpackChunkName: "iot" */ /* webpackMode: "lazy" */ '@/pages/iot/IotDeviceClassDetails.vue'),
    },
    {
        path: '/iot/device-class-list',
        name: 'IotDeviceClassList',
        title: 'DEVICE_CLASSES',
        component: () => import(/* webpackChunkName: "iot" */ /* webpackMode: "lazy" */ '@/pages/iot/IotDeviceClassList.vue'),
    },
    {
        path: '/iot/vendor-details',
        name: 'IotVendorDetails',
        title: 'IOT_HUB_VENDOR',
        component: () => import(/* webpackChunkName: "iot" */ /* webpackMode: "lazy" */ '@/pages/iot/IotVendorDetails.vue'),
    },
    {
        path: '/iot/vendor-list',
        name: 'IotVendorList',
        title: 'IOT_HUB_VENDORS',
        component: () => import(/* webpackChunkName: "iot" */ /* webpackMode: "lazy" */ '@/pages/iot/IotVendorList.vue'),
    },
    {
        path: '/iot/vendor-list',
        name: 'Iot',
        title: 'IOT_HUB',
        menu: 'iot',
        component: () => import(/* webpackChunkName: "iot" */ /* webpackMode: "lazy" */ '@/pages/iot/Iot.vue'),
    },
    {
        path: '/iot/vendor-save-form',
        name: 'IotVendorSaveForm',
        title: 'NEW_IOT_VENDOR',
        component: () => import(/* webpackChunkName: "iot" */ /* webpackMode: "lazy" */ '@/pages/iot/IotVendorSaveForm.vue'),
    },
    {
        path: '/iot/ticket-list',
        name: 'IotTicketList',
        title: 'IOT_TICKET_LIST',
        menu: 'iot',
        component: () => import(/* webpackChunkName: "iot" */ /* webpackMode: "lazy" */ '@/pages/iot/IotTicketList.vue'),
    },
    {
        path: '/iot/unlinked-devices-and-materials-list',
        name: 'UnlinkedDevicesAndMaterialsList',
        title: 'UNLINKED_DEVICES_AND_MATERIALS',
        menu: 'iot',
        component: () => import(/* webpackChunkName: "iot" */ /* webpackMode: "lazy" */ '@/pages/iot/UnlinkedDevicesAndMaterialsList.vue'),
    },
    {
        path: '/iot/unlinked-identified-materials',
        name: 'IotUnlinkedIdentifiedMaterials',
        title: 'IDENTIFIED_MATERIAL',
        component: () => import(/* webpackChunkName: "iot" */ /* webpackMode: "lazy" */ '@/pages/iot/device/IotUnlinkedIdentifiedMaterials.vue'),
    },
    {
        path: '/iot/unlinked-devices',
        name: 'IotUnlinkedDevices',
        title: 'DEVICES',
        component: () => import(/* webpackChunkName: "iot" */ /* webpackMode: "lazy" */ '@/pages/iot/device/IotUnlinkedDevices.vue'),
    },
];

export default paths;
