<template>
    <a-container ref='container' v-resize='onResize' class='container' style='width: 100%; height: 100%;'>
        <a-dynamic-command-filter
            v-show='false'
            ref='filter'
            :key='getRegisteredAssetData.name'
            filter-group='all'
            exhibition-mode='sidebar'
            class='pivot-filter'
            :_fab='false'
            :_command='getRegisteredAssetData'
            :_sidebar-props='{ _hideActions: true }'
            :_loading.sync='loading'
            @updateValues='updateDashData'
        />

        <chart
            ref='assetsChart'
            :_options='assetsChartOptions'
        />
    </a-container>
</template>

<script>
    import { GetRegisteredAssets } from './services/request';

    export default {
        name: 'AssetsMonthlyChart',
        props: {
            /**
             * Prop to show if page is currently active
             * @type {Boolean}
             * @default {false}
            */
            _activePage: {
                type: Boolean,
                default: false,
            },
            /**
             * Command filter values sent from the Dashboard
             * @type {Object}
             * @default {{filters:[]}}
             */
            _dashdata: {
                type: Object,
                default: () => ({
                    filters: [],
                }),
            },
            /**
             * Parameter to determine the chart's height
             * @type {[String, Number]}
             * @default {undefined}
             */
            _chartHeight: {
                type: [String, Number],
                default: undefined,
            },
            /**
             * Command filter values sent from the RegisteredAsset page to fill the GetRegisteredAssets fields
             * @type {Array}
             * @default {[]}
             */
            _registeredAssetFilters: {
                type: Array,
                default: () => ([]),
            },
            /**
             * sync with parent loading
             * @type {Boolean}
             * @default {false}
             */
            _loading: {
                type: Boolean,
                default: false,
            },
            /**
             * sync with parent empty-data
             * @type {Boolean}
             * @default {false}
             */
            _emptyData: {
                type: Boolean,
                default: false,
            },
        },
        data: function () {
            return {
                dashdata: this._dashdata,

                assetsData: {},
                barColor: undefined,
                assetsChartOptions: {
                    maintainAspectRatio: true,
                    grid: {
                        containLabel: true,
                        left: '5%',
                    },
                    xAxis: {
                        type: 'category',
                        name: this.$t('MONTH'),
                    },
                    yAxis: {
                        type: 'value',
                        name: this.$t('TOTAL_ASSETS'),
                        axisLabel: {
                            formatter: '{value}',
                        },
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: `<b>${this.$t('MONTH')}:</b> {b}<br /><b>${this.$t('TOTAL_ASSETS')}:</b> {c}`,
                        confine: true,
                    },
                },

                chartHeight: this._chartHeight || 'auto',
            };
        },
        computed: {
            getRegisteredAssetData: function () {
                const command = new GetRegisteredAssets();
                command.initializeLoading = false;
                return command;
            },
            loading: {
                get: function () {
                    return this._loading;
                },
                set: function (value) {
                    this.$emit('update:_loading', value);
                },
            },
            emptyData: {
                get: function () {
                    return this._emptyData;
                },
                set: function (value) {
                    this.$emit('update:_emptyData', value);
                },
            },
        },
        watch: {
            dashdata: {
                deep: true,
                handler: function (value) {
                    this.$emit('update-dashdata', value);
                },
            },
            _chartHeight: {
                handler: function (value) {
                    this.chartHeight = value;
                    this.$nextTick(this.onResize);
                },
            },
            _registeredAssetFilters: {
                immediate: true,
                handler: function (value) {
                    if (value) {
                        this.$util.loadCommandFilter(this.getRegisteredAssetData, value);
                    }
                },
            },
            _activePage: {
                handler: function (value) {
                    if (value) {
                        this.$nextTick(this.onResize);
                    }
                },
            },
        },
        mounted: function () {
            this.load();
        },
        methods: {
            onResize: function () {
                this.$refs.assetsChart.resize({ height: this.chartHeight });
            },

            load: async function () {
                this.$nextTick(this.onResize);

                if (this.dashdata.filters.length === 0) {
                    return;
                }
                this.$util.loadCommandFilter(this.getRegisteredAssetData, this.dashdata.filters);
                this.$refs.filter.refresh(true);
            },

            updateDashData: function (items) {
                this.dashdata.filters = this.$util.getFiltersByCommand(this.getRegisteredAssetData);

                const generalItems = this.$lodash.get(items, '[0].DATAS_MES.DATA_MES', []);

                this.emptyData = !generalItems.length;

                this.loadData(generalItems);
            },

            loadData: async function (items) {
                this.assetsData = this.formatToChart(items);
                this.resetCharts();
                this.loadAssetsChart();
            },
            formatToChart: function (data) {
                Object.keys(data).forEach((i) => {
                    data[i].name = data[i].DATA_FIM.substring(3);
                    data[i].value = data[i].TOTAL;
                });
                const formattedData = data;
                return formattedData;
            },
            resetCharts: function () {
                this.$refs.assetsChart.reset();
            },

            loadAssetsChart: async function () {
                this.addAssetsSerie();
            },

            addAssetsSerie: function () {
                this.$refs.assetsChart.reset();
                this.$refs.assetsChart.addSerie({
                    id: 1,
                    type: 'bar',
                    data: this.assetsData,

                    animation: true,
                    left: 'right',
                    top: 'middle',
                    barWidth: '50%',
                    barHeight: '100%',
                    itemStyle: {
                        shadowColor: 'rgba(0, 0, 0, 0.5)',
                        shadowBlur: 10,
                    },

                    label: {
                        show: true,
                        position: 'bottom',
                        formatter: '{b}',
                        color: '#000000',
                        padding: [5, 5, 5, 50],
                        rotate: -45,
                    },
                });
            },
        },
    };
</script>

<style lang='scss' scoped></style>
