const paths = [
    {
        path: '/import-list',
        name: 'ImportList',
        icon: 'fas fa-upload',
        title: 'IMPORTS',
        menu: 'customization',
        component: () => import(/* webpackChunkName: "import" */ /* webpackMode: "lazy" */ '@/pages/import/ImportList.vue'),
    },
    {
        path: '/import',
        name: 'Import',
        title: 'NEW_IMPORT',
        component: () => import(/* webpackChunkName: "import" */ /* webpackMode: "lazy" */ '@/pages/import/Import.vue'),
    },
    {
        path: '/importer-list',
        name: 'ImporterList',
        icon: 'fas fa-upload',
        title: 'IMPORTERS',
        menu: 'customization',
        component: () => import(/* webpackChunkName: "import" */ /* webpackMode: "lazy" */ '@/pages/import/ImporterList.vue'),
    },
    {
        path: '/importer',
        name: 'Importer',
        title: 'NEW_IMPORTER',
        component: () => import(/* webpackChunkName: "import" */ /* webpackMode: "lazy" */ '@/pages/import/Importer.vue'),
    },
];

export default paths;
