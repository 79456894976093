const paths = [
    {
        path: '/asset-import-photo-list',
        name: 'AssetImportPhotoList',
        title: 'ASSET_PHOTO_IMPORT',
        icon: 'mdi-file-document',
        menu: 'maintenance',
        component: () => import(/* webpackChunkName: "AssetImportPhoto" */ /* webpackMode: "lazy" */ '@/pages/assetPhotoImport/AssetPhotoImportList.vue'),
    },
    {
        path: '/asset-import-photo',
        name: 'AssetImportPhoto',
        title: 'IMPORT_ASSET_PHOTOS',
        component: () => import(/* webpackChunkName: "AssetImportPhoto" */ /* webpackMode: "lazy" */ '@/pages/assetPhotoImport/AssetPhotoImport.vue'),
    },
];

export default paths;
