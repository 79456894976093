<template>
    <a-container ref='container' v-resize='onResize' class='container' style='height: 100%;'>
        <a-dynamic-command-filter
            v-show='false'
            ref='filter'
            :key='getCandleBalance.name'
            filter-group='all'
            exhibition-mode='sidebar'
            class='pivot-filter'
            :_command='getCandleBalance'
            :_fab='false'
            :_sidebar-props='{ _hideActions: true }'
            :_loading.sync='loading'
            @updateValues='updateDashData'
        />
        <chart ref='chart' :_options='chartOptions' style='height: 100%;' />
    </a-container>
</template>

<script>
    import { GetCandleBalance } from '@/pages/financialHistory/services/request.ts';

    export default {
        name: 'FinancialHistoryGraphChart',
        props: {
            /**
             * Prop to show if page is currently active
             * @type {Boolean}
             * @default {false}
            */
            _activePage: {
                type: Boolean,
                default: false,
            },
            /**
             * Command filter values sent from the Dashboard
             * @type {Object}
             * @default {{ filters: [], }}
             */
            _dashdata: {
                type: Object,
                default: () => ({
                    filters: [],
                }),
            },
            /**
             * Override filters command
             * @type {Array}
             * @default {[]}
             */
            _filters: {
                type: Array,
                default: () => ([]),
            },
            /**
             * Prop to determine if the chart is being used from the dashboard
             * Set to false if the component is being used outside of it
             * @type {Boolean}
             * @default {true}
             */
            _dashboardMode: {
                type: Boolean,
                default: true,
            },
            /**
             * sync with parent loading
             * @type {Boolean}
             * @default {false}
             */
            _loading: {
                type: Boolean,
                default: false,
            },
            /**
             * sync with parent empty-data
             * @type {Boolean}
             * @default {false}
             */
            _emptyData: {
                type: Boolean,
                default: false,
            },
        },
        data: function () {
            return {
                dashdata: this._dashdata,
                chartOptions: {
                    xAxis: {
                        type: 'category',
                        name: this.$t('DATE'),
                    },
                    yAxis: {
                        type: 'value',
                        name: `${this.$t('COST')} (${this.$store.getters.getCurrencySymbol})`,
                    },
                },
                reportData: [],
                categoryData: [],
            };
        },
        computed: {
            getCandleBalance: function () {
                const command = new GetCandleBalance();
                command.initializeLoading = false;
                return command;
            },
            loading: {
                get: function () {
                    return this._loading;
                },
                set: function (value) {
                    this.$emit('update:_loading', value);
                },
            },
            emptyData: {
                get: function () {
                    return this._emptyData;
                },
                set: function (value) {
                    this.$emit('update:_emptyData', value);
                },
            },
        },
        watch: {
            _dashdata: {
                deep: true,
                handler: function (val, oldVal) {
                    if (!this.$lodash.isEqual(val, oldVal)) {
                        this.dashdata = val;
                    }
                },
            },
            dashdata: {
                deep: true,
                handler: function (val) {
                    this.$emit('update-dashdata', val);
                },
            },
            _activePage: {
                handler: function () {
                    this.onResize();
                },
            },
        },
        mounted: function () {
            this.load();
        },
        methods: {
            onResize: function () {
                this.$refs.chart.resize({ height: this.chartHeight });
            },
            load: async function () {
                this.$nextTick(this.onResize);
                this.chart = this.$refs.chart.getChart();

                if (this.dashdata.filters.length === 0) {
                    return;
                }
                this.$util.loadCommandFilter(this.getCandleBalance, this.dashdata.filters);
                this.$refs.filter.refresh(true);
            },
            updateDashData: function (items) {
                this.emptyData = !items.length;
                if (this._dashboardMode) {
                    this.dashdata.filters = this.$util.getFiltersByCommand(this.getCandleBalance);
                }
                this.loadData(items);
            },
            loadData: async function (items) {
                this.reportData = items;
                this.loadChart();
            },
            loadChart: function () {
                this.$refs.chart.reset();
                this.formatToChart();
                this.setChartOption();
                this.addTooltip();
                this.addSerie();
                this.chart.on('click', { seriesIndex: 0 }, this.searchType);
            },
            formatToChart: function () {
                const data = this.reportData;
                this.reportData = [];
                this.categoryData = [];
                Object.keys(data).forEach((i) => {
                    const item = data[i];
                    item.name = item.DATA_SALDO;
                    item.itemStyle = {
                        color: 'green',
                        color0: 'red',
                        borderColor: 'green',
                        borderColor0: 'red',
                    };
                    item.value = [item.OPEN, item.CLOSE, item.LOW, item.HIGH];
                    this.reportData[i] = item;
                    this.categoryData[i] = item.name;
                });
            },
            setChartOption: function () {
                const chartOptions = {
                    maintainAspectRatio: false,
                    grid: {
                        containLabel: true,
                        bottom: '20',
                    },
                    xAxis: {
                        type: 'category',
                        name: this.$t('DATE'),
                        data: this.categoryData,
                        axisLabel: {
                            show: true,
                            position: 'bottom',
                            formatter: '{value}',
                            color: '#000000',
                            padding: [3, 4, 5, 6],
                        },
                    },
                    yAxis: {
                        type: 'value',
                        name: this.$t('COSTS'),
                        axisLabel: {
                            formatter: '{value}',
                        },
                    },
                };

                this.$refs.chart.setOption(chartOptions);
            },
            addTooltip: function () {
                const tooltip = {
                    trigger: 'axis',
                    formatter: this.tooltipFormatter,
                    confine: true,
                };
                this.$refs.chart.setTooltip(tooltip);
            },
            tooltipFormatter: function (params) {
                params = params[0];
                const { data } = params;
                let dateType;
                if (this.getCandleBalance.fields.requestDate.value === null) {
                    dateType = this.$t('MONTH');
                } else {
                    dateType = this.$t('DATE');
                }

                let label = `<b>${dateType}: ${data.DATA_SALDO} </b><br />`;
                label += `${this.$t('OPENING')}: ${this.$store.getters.getCurrencySymbol} ${this.$util.asMonetary(Number(data.OPEN || 0))}<br />`;
                label += `${this.$t('CLOSING')}: ${this.$store.getters.getCurrencySymbol} ${this.$util.asMonetary(Number(data.CLOSE || 0))}<br />`;
                label += `${this.$t('HIGH')}: ${this.$store.getters.getCurrencySymbol} ${this.$util.asMonetary(Number(data.HIGH || 0))}<br />`;
                label += `${this.$t('LOW')}: ${this.$store.getters.getCurrencySymbol} ${this.$util.asMonetary(Number(data.LOW || 0))}<br />`;
                return label;
            },
            addSerie: function () {
                this.$refs.chart.addSerie({
                    id: 1,
                    type: 'candlestick',
                    data: this.reportData,

                    animation: true,
                    left: 'right',
                    top: 'middle',
                });
            },
            loadDataFromFilters: function (filters) {
                this.$util.loadCommandFilter(this.getCandleBalance, filters);
                this.$refs.filter.refresh(true);
            },
            searchType: function (params) {
                if (this.getCandleBalance.fields.requestDate.value === null) {
                    this.searchByDate(params);
                } else {
                    this.searchByMonth();
                }
            },
            searchByDate: function (params) {
                const date = `01/${params.data.DATA_SALDO}`;
                this.getCandleBalance.fields.requestDate.value = date;
                this.$nextTick(() => this.getCandleBalance.refreshData(true));
            },
            searchByMonth: function () {
                this.getCandleBalance.fields.requestDate.value = null;
                this.$nextTick(() => this.getCandleBalance.refreshData(true));
            },
        },
    };
</script>
