<template>
    <a-container ref='container' v-resize='onResize' class='container' style='height: 100%;'>
        <a-dynamic-command-filter
            v-show='false'
            ref='filter'
            :key='getMaintenanceMonitorData.name'
            filter-group='all'
            exhibition-mode='sidebar'
            class='pivot-filter'
            :_fab='false'
            :_sidebar-props='{ _hideActions: true }'
            :_loading.sync='loading'
            :_command='getMaintenanceMonitorData'
            @updateValues='updateDashData'
        />
        <a-row v-if='!_dashboardMode'>
            <a-col cols='auto'>
                <a-btn excel :_tooltip-text='$t("EXPORT_TO_EXCEL")' @click='getReport("excel1")' />
            </a-col>
            <a-col cols='auto'>
                <a-btn pdf :_tooltip-text='$t("EXPORT_TO_PDF")' @click='getReport("jr1")' />
            </a-col>
        </a-row>
        <chart
            ref='chart'
            :_options='chartOptions'
            style='height: 90%;'
        />
    </a-container>
</template>

<script>
    import { GetMaintenanceMonitorData, GetTicketsExpiration } from './services/request';

    export default {
        name: 'AttendedTicketsByPeriodicityMonitor',
        props: {
            /**
             * Prop to show if page is currently active
             * @type {Boolean}
             * @default {false}
            */
            _activePage: {
                type: Boolean,
                default: false,
            },
            /**
             * Command filter values sent from the Dashboard
             * @type {Object}
             * @default {{ filters: [], }}
             */
            _dashdata: {
                type: Object,
                default: () => ({
                    filters: [],
                }),
            },
            /**
             * Prop to determine if the chart is being used from the dashboard
             * Set to false if the component is being used outside of it
             * @type {Boolean}
             * @default {true}
             */
            _dashboardMode: {
                type: Boolean,
                default: true,
            },
            /**
             * sync with parent loading
             * @type {Boolean}
             * @default {false}
             */
            _loading: {
                type: Boolean,
                default: false,
            },
            /**
             * sync with parent empty-data
             * @type {Boolean}
             * @default {false}
             */
            _emptyData: {
                type: Boolean,
                default: false,
            },
        },
        data: function () {
            return {
                dashdata: this._dashdata,
                categoryData: [],
                reportData: [],
                ticketsExpiration: [],

                // Chart instance, used for the on() event
                chart: undefined,

                // Default chart before it's loaded with data
                chartOptions: {
                    xAxis: {
                        type: 'category',
                        name: this.periodName,
                    },
                    yAxis: {
                        type: 'value',
                        name: this.$t('OPEN_ISSUES'),
                    },
                },

                currentPeriodicity: undefined,
            };
        },
        computed: {
            getMaintenanceMonitorData: function () {
                const command = new GetMaintenanceMonitorData();
                command.initializeLoading = false;
                return command;
            },
            getTicketsExpiration: function () {
                const command = new GetTicketsExpiration();
                command.initializeLoading = false;
                return command;
            },
            periodName: function () {
                switch (this.currentPeriodicity) {
                    case 0:
                        return this.$t('MONTH');
                    case 1:
                        return this.$t('WEEK');
                    case 2:
                        return this.$t('DAY');
                    default:
                        return this.$t('PERIOD');
                }
            },
            loading: {
                get: function () {
                    return this._loading;
                },
                set: function (value) {
                    this.$emit('update:_loading', value);
                },
            },
            emptyData: {
                get: function () {
                    return this._emptyData;
                },
                set: function (value) {
                    this.$emit('update:_emptyData', value);
                },
            },
        },
        watch: {
            dashdata: {
                deep: true,
                handler: function (value) {
                    this.$emit('update-dashdata', value);
                },
            },
            _activePage: {
                handler: function () {
                    this.onResize();
                },
            },
        },
        mounted: function () {
            this.load();
        },
        methods: {
            onResize: function () {
                this.$nextTick(this.$refs.chart.resize);
            },

            load: async function () {
                this.onResize();
                this.chart = this.$refs.chart.getChart();

                if (this.dashdata.filters.length === 0) {
                    return;
                }
                this.$util.loadCommandFilter(this.getMaintenanceMonitorData, this.dashdata.filters);
                this.$refs.filter.refresh(true);
            },

            updateDashData: function (items) {
                this.emptyData = !items.length;
                // if the page is being used by the dashboard, update the filters
                if (this._dashboardMode) {
                    this.dashdata.filters = this.$util.getFiltersByCommand(this.getMaintenanceMonitorData);
                }
                this.loadData(items);
            },

            loadDataFromFilters: async function (filters) {
                /* if (filtersExpiration) {
                    this.$util.loadCommandFilter(this.getTicketsExpiration, filtersExpiration);
                    const resolve = await this.getTicketsExpiration.callCommand();
                    this.ticketsExpiration = resolve.RAIZ.VENCIMENTOS.VENCIMENTO;
                } */

                this.$util.loadCommandFilter(this.getMaintenanceMonitorData, filters);
                this.$refs.filter.refresh(true);
            },

            loadData: function (items) {
                this.formatToChart(items);
                this.loadChart();
            },
            formatToChart: function (data) {
                this.reportData = [];
                this.categoryData = [];
                Object.keys(data).forEach((i) => {
                    const item = data[i];
                    item.name = item.DATA;
                    item.value = [item.OPEN, item.CLOSE, item.LOW, item.HIGH];
                    /* item.dateStart = this.$moment(item.DATA_INICIO, 'DD/MM/YYYY').valueOf();
                    item.dateEnd = this.$moment(item.DATA_FINAL, 'DD/MM/YYYY').valueOf();

                    if (this.ticketsExpiration.length) {
                        item.totalAttended = 0;
                        item.totalDispatched = 0;
                        this.ticketsExpiration.map((ticket) => {
                            const ticketExpireDate = this.$moment(ticket.DATA_VENCIMENTO, 'DD/MM/YYYY').valueOf();
                            if (ticketExpireDate >= item.dateStart && ticketExpireDate <= item.dateEnd) {
                                item.totalAttended += ticket.TOTAL_ATENDIDA;
                                item.totalDispatched += ticket.TOTAL_DESPACHADA;
                            }
                            return 0;
                        });
                    } */

                    this.reportData[i] = item;
                    this.categoryData[i] = item.name;
                });
            },
            loadChart: function () {
                this.$refs.chart.reset();
                this.currentPeriodicity = this.getMaintenanceMonitorData.fields.periodicity.value;
                this.setChartOption();
                this.addTooltip();
                this.addSerie();
                this.chart.on('click', { seriesIndex: 0 }, this.openPendingTicketsPage);
            },

            setChartOption: function () {
                const chartOptions = {
                    maintainAspectRatio: false,
                    grid: {
                        containLabel: true,
                        bottom: '20',
                    },
                    xAxis: {
                        type: 'category',
                        name: this.periodName,
                        data: this.categoryData,
                        axisLabel: {
                            show: true,
                            position: 'bottom',
                            formatter: '{value}',
                            color: '#000000',
                            padding: [3, 4, 5, 6],
                        },
                    },
                    yAxis: {
                        type: 'value',
                        name: this.$t('OPEN_ISSUES'),
                        axisLabel: {
                            formatter: '{value}',
                        },
                    },
                };

                this.$refs.chart.setOption(chartOptions);
            },

            addTooltip: function () {
                const tooltip = {
                    trigger: 'axis',
                    formatter: this.tooltipFormatter,
                    confine: true,
                    axisPointer: {
                        type: 'line',
                    },
                };
                this.$refs.chart.setTooltip(tooltip);
            },
            tooltipFormatter: function (params) {
                params = params[0];
                const date = params.data.DATA;
                const startDate = params.data.DATA_INICIO;
                const finalDate = params.data.DATA_FINAL;
                const open = params.data.OPEN;
                const close = params.data.CLOSE;
                const high = params.data.HIGH;
                const low = params.data.LOW;
                const highDate = params.data.DATA_HIGH;
                const lowDate = params.data.DATA_LOW;
                // const { totalAttended, totalDispatched } = params.data;

                let label = `<b>${this.$t('TICKETS_HISTORY')}</b><br /><br />`;
                if (this.currentPeriodicity !== 2) {
                    label += `${this.$t('PERIOD')}: ${this.$t('VALUE_TO_VALUE', { value1: startDate, value2: finalDate })}<br />`;
                } else {
                    label += `${this.$t('PERIOD')}: ${date}<br />`;
                }
                label += `${this.$t('TICKETS_BEFORE_PERIOD')}: ${open}<br />`;
                label += `${this.$t('TICKETS_AT_END_OF_PERIOD')}: ${close}<br />`;
                label += `${this.$t('TICKETS_ATTENDED_IN_PERIOD')}: ${params.data.ATENDIMENTO_PERIODO} <br />`;
                // label += `${this.$t('TICKETS_DISPATCHED_IN_PERIOD')}: ${totalDispatched}<br />`;
                label += `${this.$t('TICKETS_HIGH')}: ${high} ${highDate ? `(${highDate})` : ''}<br />`;
                label += `${this.$t('LOWEST_NUMBER_OF_TICKETS')}: ${low} ${lowDate ? `(${lowDate})` : ''}`;
                return label;
            },

            addSerie: function () {
                this.$refs.chart.addSerie({
                    id: 1,
                    type: 'candlestick',
                    data: this.reportData,

                    animation: true,
                    left: 'right',
                    top: 'middle',
                });
            },

            getReport: async function (situation) {
                this.getMaintenanceMonitorData.type = 'FILE';
                this.getMaintenanceMonitorData.config = { type: 'file' };
                this.getMaintenanceMonitorData.fields.situation.value = situation;
                this.getMaintenanceMonitorData.fields.report.value = 1;
                try {
                    const resolve = await this.getMaintenanceMonitorData.callCommand();
                    this.downloadFile(resolve, situation);
                } catch (reject) {

                } finally {
                    this.returnCommandToDefault();
                }
            },
            downloadFile: function (resolve, situation) {
                if (resolve) {
                    const link = document.createElement('a');

                    link.href = resolve;
                    link.setAttribute('download', `${this.$t('ATTENDED_TICKETS_BY_PERIODICITY')}.${situation === 'jr1' ? 'pdf' : 'xls'}`);

                    document.body.appendChild(link);
                    link.click();

                    this.$_aura.showSuccessMessage(this.$t('SUCCESSFULLY_GENERATED_FILE'));
                } else {
                    this.$_aura.showErrorMessage(this.$t('UNABLE_TO_GENERATE_FILE'));
                }
            },
            returnCommandToDefault: function () {
                this.getMaintenanceMonitorData.type = 'SEARCH';
                this.getMaintenanceMonitorData.config = null;
                this.getMaintenanceMonitorData.fields.situation.value = null;
                this.getMaintenanceMonitorData.fields.report.value = 0;
            },

            openPendingTicketsPage: async function (params) {
                const filters = this.$util.getFiltersByCommand(this.getMaintenanceMonitorData);
                filters.find(f => f.key === 'DATA_INICIO').value = params.data.DATA_INICIO;
                filters.find(f => f.key === 'DATA_FINAL').value = params.data.DATA_FINAL;
                this.$util.openPage({ name: 'PendingTicketsList', title: this.$t('PENDING_TICKETS_DETAILS') }, {
                    props: {
                        _filters: filters,
                    },
                });
            },
        },
    };
</script>
