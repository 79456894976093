const paths = [
    {
        path: '/project',
        name: 'Project',
        title: 'PROJECT',
        icon: 'fa fa-briefcase',
        favCategory: 'construction',
        component: () => import(/* webpackChunkName: "project" */ /* webpackMode: "lazy" */ '@/pages/project/Project.vue'),
    },
    {
        path: '/project-scheduling-wizard',
        name: 'ProjectSchedulingWizard',
        title: 'NEW_SCHEDULE',
        component: () => import(/* webpackChunkName: "project" */ /* webpackMode: "lazy" */ '@/pages/project/ProjectSchedulingWizard.vue'),
    },
    {
        path: '/project-scheduling-detail',
        name: 'ProjectSchedulingDetail',
        title: 'DEVICE_SCHEDULE',
        component: () => import(/* webpackChunkName: "project" */ /* webpackMode: "lazy" */ '@/pages/project/ProjectSchedulingDetail.vue'),
    },
    {
        path: '/project-documents-details',
        name: 'ProjectDocumentsDetails',
        title: 'PROJECT_DOCUMENT',
        component: () => import(/* webpackChunkName: "project" */ /* webpackMode: "lazy" */ '@/pages/project/ProjectDocumentsDetails.vue'),
    },
    {
        path: '/project-documents-email',
        name: 'ProjectDocumentsEmail',
        title: 'EMAIL',
        component: () => import(/* webpackChunkName: "project" */ /* webpackMode: "lazy" */ '@/pages/project/ProjectDocumentsEmail.vue'),
    },
    {
        path: '/project-list',
        name: 'ProjectList',
        icon: 'fa fa-briefcase',
        title: 'PROJECTS',
        menu: 'construction',
        component: () => import(/* webpackChunkName: "project" */ /* webpackMode: "lazy" */ '@/pages/project/ProjectList.vue'),
    },
    {
        path: '/project-materials-list',
        name: 'ProjectMaterialsList',
        title: 'MATERIALS_REPORT_BY_PROJECT',
        icon: 'mdi-file-document',
        menu: 'construction',
        categories: [
          'reports',
        ],
        component: () => import(/* webpackChunkName: "project" */ /* webpackMode: "lazy" */ '@/pages/project/ProjectMaterialsList.vue'),
    },
    {
        path: '/new-project-wizard',
        title: 'NEW_PROJECT_WIZARD',
        name: 'NewProjectWizard',
        component: () => import(/* webpackChunkName: "project" */ /* webpackMode: "lazy" */ '@/pages/project/NewProjectWizard.vue'),
    },
    {
        path: '/project-general-data-change-status',
        title: 'CHANGE_PROJECT_STATUS',
        name: 'ProjectGeneralDataChangeStatus',
        component: () => import(/* webpackChunkName: "project" */ /* webpackMode: "lazy" */ '@/pages/project/ProjectGeneralDataChangeStatus.vue'),
    },
    {
        path: '/project-link-contract',
        title: 'LINK_CONTRACT',
        name: 'ProjectLinkContract',
        component: () => import(/* webpackChunkName: "project" */ /* webpackMode: "lazy" */ '@/pages/project/ProjectLinkContract.vue'),
    },
    {
        path: '/project-export-copel',
        title: 'EXPORT_COPEL',
        name: 'ProjectExportCopel',
        component: () => import(/* webpackChunkName: "project" */ /* webpackMode: "lazy" */ '@/pages/project/ProjectExportCopel.vue'),
    },
];

export default paths;
