import i18n from '@/i18n';

export default function statusListSearch (provider) {
    return new Promise((resolve) => {
        const resultProvider = [
            {
                DESC_STATUS: i18n.t('ALL'),
                ID_STATUS: -1,
            },
            {
                DESC_STATUS: i18n.t('ALL_ACTIVE'),
                ID_STATUS: -2,
            },
            ...provider,
        ];

        const addItem = {
            DESC_STATUS: `${i18n.t('APPROVED')} / ${i18n.t('CONFIRMED')}`,
            ID_STATUS: -3,
        };
        resultProvider.splice(7, 0, addItem);

        resolve(resultProvider);
    });
}
