import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import { showErrorsResponse, parseSuccessResponse } from '@/pages/remoteManagement/services/utils';
// command-uri
export * from './request/command-uri';
// company
export * from './request/company';
// company-data-policy
export * from './request/company-data-policy';
// company-connection-interval
export * from './request/company-connection-interval';
// auth-information
export * from './request/auth-information';
// alert-type
export * from './request/alert-type';
export * from './request/alert-type-rule';
export * from './request/alert-type-notify';
export * from './request/hardware-alert-type-action';
// board
export * from './request/board';
export * from './request/client';
export * from './request/client-app';
// billing-estimation
export * from './request/billing-estimation';
// billing-report
export * from './request/billing-report';
// company-device
export * from './request/company-device';
// defect
export * from './request/defect';
// device
export * from './request/device';
export * from './request/device-config';
export * from './request/nodered-actions';
// device-action-history
export * from './request/device-action-history';
// device-allocation
export * from './request/device-allocation';
// device-config
export * from './request/device-config-schedule';
// device-connection
export * from './request/device-connection';
// device-group
export * from './request/device-group';
export * from './request/device-template-value';
export * from './request/device-type';
// device-information
export * from './request/device-information';
// device-state
export * from './request/device-state';
// device-status
export * from './request/device-status';
// fixures
export * from './request/fixture';
// general-configs
export * from './request/general-configs';
// measurement
export * from './request/measurement-config';
export * from './request/measurement-config-item';
export * from './request/measurement';
// role
export * from './request/role';
// schedule
export * from './request/schedule';
export * from './request/schedule-action';
// template
export * from './request/template';
export * from './request/webhook';
export * from './request/webhook-client';
// user
export * from './request/user';
/**
 * Device Status
 * @param param
 * =============================================================================
 */
export class GetDevicesStatus extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_device-status_0_1',
            type: 'NONE',
            fields: {
                filter: new Field({
                    name: 'BODY',
                }),
                pathParams: new Field({
                    name: 'PATH_PARAMS',
                    value: {},
                    parser: (value) => `{ PARAM_0: ${value.deviceId}, PARAM_1: ${value.deviceStatusId} }`,
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetDeviceStatusLampModes extends CallCommand {
    constructor() {
        super({
            name: 'get_device-status_lamp-modes',
            type: 'NONE',
            fields: {},
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
/**
 * Schedule window
 * Requests of schedule-window entity
 * =======================================================================
*/
export class GetScheduleWindowById extends CallCommand {
    constructor() {
        super({
            name: 'get_schedule-window_0_1',
            type: 'NONE',
            fields: {
                pathParams: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${value.scheduleId.toString()}, PARAM_1: ${value.scheduleWindowId.toString()} }`,
                }),
                filter: new Field({
                    name: 'BODY',
                    parser: (value) => `filter=${encodeURIComponent(JSON.stringify(value))}`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class SaveScheduleWindow extends CallCommand {
    constructor() {
        super({
            name: 'post_schedule-window_0',
            type: 'SAVE',
            fields: {
                scheduleId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
                scheduleWindow: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class EditScheduleWindow extends CallCommand {
    constructor() {
        super({
            name: 'put_schedule-window_0_1',
            type: 'SAVE',
            fields: {
                scheduleId: new Field({
                    name: 'SCHEDULE_ID',
                }),
                scheduleWindowId: new Field({
                    name: 'SCHEDULE_WINDOW_ID',
                }),
                scheduleWindow: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
            },
            customRequestParser: ({ scheduleId, scheduleWindowId, scheduleWindow }) => {
                const pathParams = `{ PARAM_0: ${scheduleId.toString()}, PARAM_1: ${scheduleWindowId.toString()} }`;
                return {
                    BODY: JSON.stringify(scheduleWindow),
                    PATH_PARAMS: pathParams,
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class DeleteScheduleWindow extends CallCommand {
    constructor() {
        super({
            name: 'delete_schedule-window_0_1',
            type: 'DELETE',
            fields: {
                scheduleId: new Field({
                    name: 'PATH_PARAMS',
                }),
                scheduleWindowId: new Field({
                    name: 'PATH_PARAMS',
                }),
            },
            customRequestParser: ({ scheduleId, scheduleWindowId }) => {
                const pathParams = `{ PARAM_0: ${scheduleId.toString()}, PARAM_1: ${scheduleWindowId.toString()} }`;
                return {
                    PATH_PARAMS: pathParams,
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
/**
 * Measurement-type
 * Requests of meausurement-type entity
 * =======================================================================
*/
export class GetMeasurementTypes extends CallCommand {
    constructor() {
        super({
            name: 'get_measurement-type',
            type: 'NONE',
            fields: {},
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    async callCommand() {
        return super.callCommand();
    }
}
/**
 * Alert-type-device
 * Requests of alert-type-device entity
 * =======================================================================
 */
export class SaveAlertTypeDevices extends CallCommand {
    constructor() {
        super({
            name: 'post_comp_alert-type-device_create-all_0',
            type: 'SAVE',
            fields: {
                alertTypeId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
                alertTypeDevices: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class EditAlertTypeDevices extends CallCommand {
    constructor() {
        super({
            name: 'put_comp_alert-type-device_replace-all_0',
            type: 'SAVE',
            fields: {
                alertTypeId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
                alertTypeDevices: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
/**
 * Alert-type-device-group
 * Requests of alert-type-device entity
 * =======================================================================
 */
export class SaveAlertTypeDeviceGroups extends CallCommand {
    constructor() {
        super({
            name: 'post_comp_alert-type-device-group_create-all_0',
            type: 'SAVE',
            fields: {
                alertTypeId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
                alertTypeDeviceGroups: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class EditAlertTypeDeviceGroups extends CallCommand {
    constructor() {
        super({
            name: 'put_comp_alert-type-device-group_replace-all_0',
            type: 'SAVE',
            fields: {
                alertTypeId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
                alertTypeDeviceGroups: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
/**
 * Alerts
 * Requests of alerts type entity
 * =======================================================================
 */
export class GetAlertTypes extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarTiposAlerta',
            type: 'SEARCH',
            node: 'TIPOS_ALERTA.TIPO_ALERTA',
            fields: {
                showAll: new Field({
                    name: 'SHOW_ALL',
                }),
            },
        });
    }
    async callCommand(config, debug) {
        return super.callCommand(config, debug);
    }
}
export class SaveAlertTypeTg extends CallCommand {
    constructor() {
        super({
            name: 'SalvarTipoAlertaTg',
            type: 'SAVE',
            fields: {
                alertTypeId: new Field({
                    name: 'ID_TIPO_ALERTA_TG',
                }),
                alertTypeSystemId: new Field({
                    name: 'ID_TIPO_ALERTA_SISTEMA',
                }),
                alertTypeHardwareId: new Field({
                    name: 'ID_TIPO_ALERTA_HARDWARE',
                }),
                issueTypeId: new Field({
                    name: 'ID_TIPO_OCORRENCIA',
                }),
                description: new Field({
                    name: 'DESCRICAO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetActiveAlerts extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarAlertasAtivos',
            type: 'SEARCH',
            node: 'ITEMS.ITEM',
            fields: {
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class CreateDevice extends CallCommand {
    constructor() {
        super({
            name: 'post_devices_0',
            type: 'NONE',
            fields: {
                company_id: new Field({
                    name: 'PATH_PARAMS',
                }),
                device_uuid: new Field({
                    name: 'PATH_PARAMS',
                }),
                device_type: new Field({
                    name: 'PATH_PARAMS',
                }),
                serial_number: new Field({
                    name: 'PATH_PARAMS',
                }),
                private_key: new Field({
                    name: 'PATH_PARAMS',
                }),
                public_key: new Field({
                    name: 'PATH_PARAMS',
                }),
                certificate: new Field({
                    name: 'PATH_PARAMS',
                }),
                passcode: new Field({
                    name: 'PATH_PARAMS',
                }),
                manufacturer: new Field({
                    name: 'PATH_PARAMS',
                }),
                model: new Field({
                    name: 'PATH_PARAMS',
                }),
                hardware_version: new Field({
                    name: 'PATH_PARAMS',
                }),
                boards: new Field({
                    name: 'PATH_PARAMS',
                }),
                latency: new Field({
                    name: 'PATH_PARAMS',
                }),
            },
            customRequestParser: ({ company_id, device_uuid, device_type, serial_number, private_key, public_key, certificate, passcode, manufacturer, model, hardware_version, boards, latency, }) => {
                const pathParams = `{"device_type_id": ${device_type.toString()}, "device_uuid": "${device_uuid.toString()}", "serial_number": "${serial_number.toString()}", "latency": ${latency.toString()}, 
                "private_key": "${private_key.toString()}", "public_key": "${public_key.toString()}", "certificate": "${certificate.toString()}", "passcode": "${passcode.toString()}", 
                "manufacturer": "${manufacturer.toString()}", "model": "${model.toString()}", "hardware_version": "${hardware_version.toString()}", "boards": ${boards.toString()}}`;
                return {
                    BODY: pathParams,
                    PATH_PARAMS: `{PARAM_0: ${company_id.toString()}}`,
                };
            },
        });
    }
}
export class GetLampsWithDefect extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarLampadasComDefeito',
            type: 'SEARCH',
            node: 'ITEMS.ITEM',
            fields: {
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class GetConnectedDevices extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarDispositivosConectados',
            type: 'SEARCH',
            node: 'ITEMS.ITEM',
            fields: {
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class SaveHardwareAlertTypeHide extends CallCommand {
    constructor() {
        super({
            name: 'post_comp_hardware-alert-type-hide_0',
            type: 'NONE',
            fields: {
                hardwareAlertTypeId: new Field({
                    name: 'HARDWARE_ALERT_TYPE_ID',
                }),
                hardwareAlertTypeHide: new Field({
                    name: 'BODY',
                }),
            },
            customRequestParser: ({ hardwareAlertTypeId, hardwareAlertTypeHide }) => {
                const pathParams = `{ PARAM_0: ${hardwareAlertTypeId.toString()}}`;
                return {
                    BODY: hardwareAlertTypeHide ? JSON.stringify(hardwareAlertTypeHide) : undefined,
                    PATH_PARAMS: pathParams,
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetAlertTypeHide extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_hardware-alert-type-hide_0',
            type: 'NONE',
            fields: {
                hardwareAlertTypeId: new Field({
                    name: 'HARDWARE_ALERT_TYPE_ID',
                }),
            },
            customRequestParser: ({ hardwareAlertTypeId }) => {
                const pathParams = `{ PARAM_0: ${hardwareAlertTypeId.toString()}}`;
                return {
                    PATH_PARAMS: pathParams,
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
