export default [
    {
        path: '/issue-monitor',
        title: 'ISSUES_MONITOR',
        name: 'IssueMonitor',
        icon: 'fas fa-chart-pie',
        menu: 'maintenance',
        component: () => import(/* webpackChunkName: "issueMonitor" */ /* webpackMode: "lazy" */ '@/pages/issue/issueMonitor/IssueMonitor.vue'),
    },
];
