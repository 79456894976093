export default function itemManufacturer (provider) {
    return new Promise((resolve) => {
        const resultProvider = [];

        provider.forEach((item) => {
            resultProvider.push({ KEY: item.ID_ITEM_FABRICANTE, VALUE: `${item.NOME_FABRICANTE} ${item.COD_ITEM_FABRICANTE ? ` - ${item.COD_ITEM_FABRICANTE}` : ''} ${item.DESC_ITEM_FABRICANTE ? ` - ${item.DESC_ITEM_FABRICANTE}` : ''} ` });
        });

        resolve(resultProvider);
    });
}
