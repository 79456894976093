import i18n from '@/i18n';
import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import localStore from '@/pages/remoteManagement/services/store';
import staticProvider from '@/util/inputs/select/staticProvider/static-provider.js';
import { parseSuccessResponse, showErrorsResponse } from '../utils';
export class GetDeviceActionHistorySearch extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_device-action-history_search',
            type: 'SEARCH',
            node: 'RESPONSE',
            fields: {
                limit: new Field({
                    name: 'limit',
                }),
                serialNumber: new Field({
                    name: 'serial_number',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('SERIAL_NUMBER'),
                    },
                }),
                reference: new Field({
                    name: 'reference',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('REFERENCE'),
                    },
                }),
                companyId: new Field({
                    name: 'companyId',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('COMPANY'),
                        items: localStore.state.companies || [],
                        itemText: 'company_name',
                        itemValue: 'company_id',
                        clearable: true,
                    },
                }),
                minDate: new Field({
                    name: 'min_date',
                    component: 'ADateTimePicker',
                    props: {
                        'text-field': true,
                        label: i18n.t('MIN_DATE'),
                    },
                }),
                maxDate: new Field({
                    name: 'max_date',
                    component: 'ADateTimePicker',
                    props: {
                        'text-field': true,
                        label: i18n.t('MAX_DATE'),
                    },
                }),
                minLatency: new Field({
                    name: 'min_latency',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('MINIMUM_LATENCY'),
                    },
                }),
                maxLatency: new Field({
                    name: 'max_latency',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('MAXIMUM_LATENCY'),
                    },
                }),
                action: new Field({
                    name: 'action',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('ACTION'),
                    },
                }),
                executed: new Field({
                    name: 'executed',
                    component: 'ASelect',
                    value: -1,
                    props: {
                        label: i18n.t('EXECUTED'),
                        entity: staticProvider.yesNoAll,
                        clearable: false,
                    },
                }),
                userRequisition: new Field({
                    name: 'user_requisition',
                    component: 'ASelect',
                    value: -1,
                    props: {
                        label: i18n.t('USER_REQUISITION'),
                        entity: staticProvider.yesNoAll,
                        clearable: false,
                    },
                }),
            },
            customRequestParser: function (params) {
                const filter = {
                    serial_number: params.serialNumber || null,
                    min_date: params.minDate || null,
                    max_date: params.maxDate || null,
                    action: params.action || null,
                    executed: params.executed || null,
                    user_requisition: params.userRequisition || null,
                    min_latency: params.minLatency || null,
                    max_latency: params.maxLatency || null,
                    limit: params.limit || null,
                    reference: params.reference || null,
                    company_id: params.companyId || null,
                };
                return {
                    BODY: `searchFilter=${encodeURIComponent(JSON.stringify(filter))}`,
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetDeviceActionHistory extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_device-action-history',
            type: 'NONE',
            node: 'RESPONSE',
            fields: {
                serialNumber: new Field({
                    name: 'serial_number',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('SERIAL_NUMBER'),
                    },
                }),
                companyId: new Field({
                    name: 'companyId',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('COMPANY'),
                        items: localStore.state.companies || [],
                        itemText: 'company_name',
                        itemValue: 'company_id',
                        clearable: true,
                    },
                }),
                minDate: new Field({
                    name: 'min_date',
                    component: 'ADateTimePicker',
                    props: {
                        'text-field': true,
                        label: i18n.t('MIN_DATE'),
                    },
                }),
                maxDate: new Field({
                    name: 'max_date',
                    component: 'ADateTimePicker',
                    props: {
                        'text-field': true,
                        label: i18n.t('MAX_DATE'),
                    },
                }),
                minLatency: new Field({
                    name: 'min_latency',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('MINIMUM_LATENCY'),
                    },
                }),
                maxLatency: new Field({
                    name: 'max_latency',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('MAXIMUM_LATENCY'),
                    },
                }),
                action: new Field({
                    name: 'action',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('ACTION'),
                    },
                }),
                executed: new Field({
                    name: 'executed',
                    component: 'ASelect',
                    value: -1,
                    props: {
                        label: i18n.t('EXECUTED'),
                        entity: staticProvider.yesNoAll,
                        clearable: false,
                    },
                }),
                userRequisition: new Field({
                    name: 'user_requisition',
                    component: 'ASelect',
                    value: -1,
                    props: {
                        label: i18n.t('USER_REQUISITION'),
                        entity: staticProvider.yesNoAll,
                        clearable: false,
                    },
                }),
                limit: new Field({
                    name: 'limit',
                }),
            },
            customRequestParser: function (params) {
                const filter = {
                    serial_number: params.serialNumber || null,
                    min_date: params.minDate || null,
                    max_date: params.maxDate || null,
                    action: params.action || null,
                    executed: params.executed || null,
                    user_requisition: params.userRequisition || null,
                    min_latency: params.minLatency || null,
                    max_latency: params.maxLatency || null,
                    company_id: params.companyId || null,
                    limit: params.limit || null,
                };
                return {
                    BODY: `searchFilter=${encodeURIComponent(JSON.stringify(filter))}`,
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
