const paths = [
  {
      path: '/service-list',
      title: 'SERVICES',
      name: 'ServiceList',
      icon: 'fa fa-hard-hat',
      menu: 'maintenance',
      categories: [
        'configurations',
      ],
      component: () => import(/* webpackChunkName: "service" */ /* webpackMode: "lazy" */ '@/pages/service/ServiceList.vue'),
  },
  {
    path: '/service-tab-service-composition-wizard',
    title: 'SERVICE_COMPOSITION',
    name: 'ServiceTabServiceCompositionWizard',
    component: () => import(/* webpackChunkName: "service" */ /* webpackMode: "lazy" */ '@/pages/service/ServiceTabServiceCompositionWizard.vue'),
  },
  {
    path: '/service-tabs',
    title: 'SERVICE',
    name: 'ServiceTabs',
    component: () => import(/* webpackChunkName: "service" */ /* webpackMode: "lazy" */ '@/pages/service/ServiceTabs.vue'),
  },
  {
    path: '/service-linked-material-wizard',
    title: 'LINKED_MATERIALS',
    name: 'ServiceLinkedMaterialWizard',
    component: () => import(/* webpackChunkName: "service" */ /* webpackMode: "lazy" */ '@/pages/service/ServiceLinkedMaterialWizard.vue'),
  },
  {
    path: '/service-linked-issues-types-wizard',
    title: 'LINKED_ISSUES_TYPES',
    name: 'ServiceLinkedIssueTypeWizard',
    component: () => import(/* webpackChunkName: "service" */ /* webpackMode: "lazy" */ '@/pages/service/ServiceLinkedIssueTypeWizard.vue'),
  },
  {
    path: '/service-clone',
    title: 'CLONE_SERVICE',
    name: 'ServiceClone',
    component: () => import(/* webpackChunkName: "service" */ /* webpackMode: "lazy" */ '@/pages/service/ServiceClone.vue'),
  },
  {
    path: '/service-import',
    title: 'SERVICE_IMPORT',
    name: 'ServiceImport',
    icon: 'fas fa-upload',
    menu: 'maintenance',
    categories: [
      'configurations',
    ],
    component: () => import(/* webpackChunkName: "service" */ /* webpackMode: "lazy" */ '@/pages/service/ServiceImport.vue'),
  },
];

export default paths;
