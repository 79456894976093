const paths = [
    {
        path: '/notify-complainer',
        name: 'NotifyComplainer',
        title: 'NOTIFY_COMPLAINER',
        menu: 'ticket',
        component: () => import(/* webpackChunkName: "notifyComplainer" */ /* webpackMode: "lazy" */ '@/pages/notifyComplainer/NotifyComplainer.vue'),

    },
];

export default paths;
