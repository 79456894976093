const paths = [
    {
        path: '/conciliation-list',
        title: 'CONCILIATIONS',
        name: 'ConciliationList',
        icon: 'fa fa-briefcase',
        menu: 'financial',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "financy" */ /* webpackMode: "lazy" */ '@/pages/financy/conciliation/ConciliationList.vue'),
    },
    {
        path: '/conciliation',
        title: 'CONCILIATION',
        name: 'Conciliation',
        favCategory: 'financial',
        component: () => import(/* webpackChunkName: "financy" */ /* webpackMode: "lazy" */ '@/pages/financy/conciliation/Conciliation.vue'),
    },
    {
        path: '/finish-conciliation',
        title: 'CLOSURE_CONFIRMATION',
        name: 'FinishConciliation',
        component: () => import(/* webpackChunkName: "financy" */ /* webpackMode: "lazy" */ '@/pages/financy/conciliation/FinishConciliation.vue'),
    },
    {
        path: '/conciliation-import-bank-statement-wizard',
        title: 'IMPORT_BANK_STATEMENTS',
        name: 'ConciliationImportBankStatementWizard',
        component: () => import(/* webpackChunkName: "financy" */ /* webpackMode: "lazy" */ '@/pages/financy/conciliation/ConciliationImportBankStatementWizard.vue'),
    },
    {
        path: '/conciliation-general',
        title: 'CONCILIATION',
        name: 'ConciliationGeneral',
        component: () => import(/* webpackChunkName: "financy" */ /* webpackMode: "lazy" */ '@/pages/financy/conciliation/ConciliationGeneral.vue'),
    },
    {
        path: '/conciliation-register-payment-wizard',
        title: 'CONCILIATION',
        name: 'ConciliationRegisterPaymentWizard',
        component: () => import(/* webpackChunkName: "financy" */ /* webpackMode: "lazy" */ '@/pages/financy/conciliation/ConciliationRegisterPaymentWizard.vue'),
    },
];

export default paths;
