import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import { parseSuccessResponse, showErrorsResponse } from '../utils';
export class GetCommandUris extends CallCommand {
    constructor() {
        super({
            name: 'get_company-command-uri_0',
            type: 'SEARCH',
            fields: {
                company_id: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetDeviceCommands extends CallCommand {
    constructor() {
        super({
            name: 'get_device-commands',
            type: 'SEARCH',
        });
    }
    async callCommand(config, debug) {
        return super.callCommand(config, debug);
    }
}
export class SaveCompanyCommandUri extends CallCommand {
    constructor() {
        super({
            name: 'post_company-command-uri',
            type: 'NONE',
            fields: {
                companyCommandUri: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class EditCompanyCommandUri extends CallCommand {
    constructor() {
        super({
            name: 'patch_company-command-uri_0',
            type: 'NONE',
            fields: {
                companyCommandUriId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
                companyCommandUri: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class DeleteCompanyCommandUri extends CallCommand {
    constructor() {
        super({
            name: 'delete_company-command-uri_0',
            type: 'DELETE',
            fields: {
                commandUriId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
