<template>
    <div>
        <a-row no-gutters align='center'>
            <a-col v-for='(status, index) in _statusList' :key='status[_statusKey]' cols='auto'>
                <a-row no-gutters align='center'>
                    <a-col cols='auto' class='ma-0'>
                        <a-tooltip bottom>
                            <template v-slot:activator='{ on }'>
                                <a-hover v-slot:default='{ hover }'>
                                    <a-icon
                                        :size='hover ? _stepIconSize + 5 : _stepIconSize'
                                        :color='iconColor(status, index === _statusList.length - 1)'
                                        style='margin-left: -1.5px; margin-right: -1.5px; z-index: 2;'
                                        v-on='on'
                                    >
                                        fas fa-circle
                                    </a-icon>
                                </a-hover>
                            </template>
                            {{status[_statusTitle]}}
                        </a-tooltip>
                    </a-col>
                    <a-col v-if='index < _statusList.length - 1' cols='auto' class='ma-0'>
                        <a-progress-linear
                            :style='`width: ${_lineWidth}px`'
                            :value='_currentStatusId > status[_statusKey] ? 100 : 0'
                            :indeterminate='_currentStatusId === status[_statusKey] && _nextInProgress'
                            color='primary'
                            background-color='mono-grey-10'
                            :height='_lineHeight'
                        />
                    </a-col>
                </a-row>
            </a-col>
        </a-row>
    </div>
</template>

<script>
    export default {
        name: 'AProgressStepper',
        props: {
            /**
             * Status list for the desired entity
             * @type {Array}
             * @default {[]}
             */
            _statusList: {
                type: Array,
                default: [],
                required: true,
            },
            /**
             * Identifier for the current status in which the entity is in
             * @type {Number}
             * @default {undefined}
             */
            _currentStatusId: {
                type: Number,
                default: undefined,
                required: true,
            },
            /**
             * The object key for the status identifier
             * @type {String}
             * @default {''}
             */
            _statusKey: {
                type: String,
                default: '',
                required: true,
            },
            /**
             * The object key for the status title
             * @type {String}
             * @default {''}
             */
            _statusTitle: {
                type: String,
                default: '',
                required: true,
            },
            /**
             * Prop to determine if the next bar is in progress (inderteminate)
             * @type {Boolean}
             * @default {false}
             */
            _nextInProgress: {
                type: Boolean,
                default: false,
            },
            /**
             * Status line width
             * @type {Number | String}
             * @default {50}
             */
            _lineWidth: {
                type: Number | String,
                default: 50,
            },
            /**
             * Status line height
             * @type {Number | String}
             * @default {6}
             */
            _lineHeight: {
                type: Number | String,
                default: 6,
            },
            /**
             * Status step icon size
             * @type {Number | String}
             * @default {18}
             */
            _stepIconSize: {
                type: Number | String,
                default: 18,
            },
        },
        data: function () {
            return {
                file: null,
            };
        },
        computed: {
        },
        mounted: function () {
            this.load();
        },
        methods: {
            load: function () {

            },
            iconColor: function (status, isLast) {
                if (this._currentStatusId < status[this._statusKey]) {
                    return "mono-grey-10"
                }
                return isLast ? "success" : "primary";
            }
        },

    };
</script>
