import Vue from 'vue';
import i18n from '@/i18n';
import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
const { $autocomplete, $util, $select, $moment, } = Vue.prototype;
export class GetMaintenanceMonitorData extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarDadosAtendimentoMonitor',
            type: 'SEARCH',
            node: 'PERIODOS.PERIODO',
            fields: {
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('WORKSITE'),
                        entity: $autocomplete.worksite,
                        'entity-params': { ATIVO: 1 },
                    },
                }),
                startDate: new Field({
                    name: 'DATA_INICIO',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    value: $moment().subtract(2, 'months').format('DD/MM/YYYY'),
                    props: {
                        label: i18n.t('INITIAL_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                endDate: new Field({
                    name: 'DATA_FINAL',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    value: $moment().format('DD/MM/YYYY'),
                    props: {
                        label: i18n.t('ENDING_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                periodicity: new Field({
                    name: 'PERIODICIDADE',
                    component: 'ASelect',
                    value: 0,
                    props: {
                        label: i18n.t('PERIODICITY'),
                        entity: $select.monitorPeriodicity,
                        clearable: true,
                        'clear-provider': true,
                    },
                }),
                ticketType: new Field({
                    name: 'TIPO_SOLICITACAO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('TICKET_TYPE'),
                        entity: $select.monitorTicketType,
                        clearable: true,
                        'clear-provider': true,
                    },
                }),
                ticketOriginTypeId: new Field({
                    name: 'ID_TIPO_ORIGEM_OCORRENCIA',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('TICKET_ORIGIN_TYPE'),
                        parser: $util.arrayToString,
                        entity: $select.issueOriginType,
                        'entity-params': { SEM_RECLAMANTE: 0 },
                        clearable: true,
                        outlined: true,
                        multiple: true,
                    },
                }),
                cityId: new Field({
                    name: 'ID_MUNICIPIO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('CITY'),
                        entity: $select.city,
                        'entity-params': { SOMENTE_ATIVOS: 1 },
                        'require-params': ['ID_PARQUE_SERVICO'],
                        clearable: true,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.cityId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        },
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.cityId.props.disabled = !fields.worksiteId.value;
                            },
                        },
                    ],
                }),
                regionId: new Field({
                    name: 'ID_REGIAO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('REGION'),
                        entity: $select.detailedIssueRegion,
                        'entity-params': {},
                        'require-params': ['ID_PARQUE_SERVICO'],
                        clearable: true,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: ['ID_PARQUE_SERVICO', 'ID_MUNICIPIO'],
                            handler: (fields) => {
                                fields.regionId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                    ID_MUNICIPIO: fields.cityId.value,
                                };
                            },
                        },
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.regionId.props.disabled = !fields.worksiteId.value;
                            },
                        },
                    ],
                }),
                userCompanyId: new Field({
                    name: 'ID_EMPRESA_USUARIA',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('USER_COMPANY'),
                        entity: $autocomplete.userCompany,
                    },
                }),
                issueTypes: new Field({
                    name: 'TIPOS_OCORRENCIA',
                    component: 'AAutocomplete',
                    parser: $util.arrayToString,
                    props: {
                        label: i18n.t('ISSUE_TYPES'),
                        entity: $autocomplete.issueType,
                        'entity-params': {
                            HABILITADO_PARQUE: null,
                            SOMENTE_NAO_PREVENTIVAS: null,
                            HABILITADO_CALLCENTER: null,
                        },
                        clearable: true,
                        'clear-provider': true,
                        multiple: true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.issueTypes.props.disabled = !fields.worksiteId.value;
                            },
                        },
                    ],
                }),
                authorizedIssues: new Field({
                    name: 'OCORRENCIAS_AUTORIZADAS',
                    component: 'ACheckbox',
                    parser: $util.booleanToNumber,
                    props: {
                        label: i18n.t('AUTHORIZED_ISSUES'),
                    },
                }),
                attributeFilter: new Field({
                    name: 'FILTRO_ATRIBUTOS',
                    component: () => import('@components/attribute/AttributeFilter.vue'),
                    parser: $util.attributesToString,
                    props: {
                        _sgi: 1,
                        _filter: false,
                    },
                }),
                report: new Field({
                    name: 'RELATORIO',
                    value: 0,
                }),
                detail: new Field({
                    name: 'DETALHE',
                    value: 0,
                }),
                situation: new Field({
                    name: 'SITUATION',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetTicketsExpiration extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarSolicitacoesVencimento',
            type: 'SEARCH',
            node: 'VENCIMENTOS.VENCIMENTO',
            fields: {
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('WORKSITE'),
                        entity: $autocomplete.worksite,
                        'entity-params': { ATIVO: 1 },
                    },
                }),
                startDate: new Field({
                    name: 'DATA_INICIO',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    value: $moment().subtract(2, 'months').format('DD/MM/YYYY'),
                    props: {
                        label: i18n.t('INITIAL_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                endDate: new Field({
                    name: 'DATA_FINAL',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    value: $moment().format('DD/MM/YYYY'),
                    props: {
                        label: i18n.t('ENDING_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                cityId: new Field({
                    name: 'ID_MUNICIPIO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('CITY'),
                        entity: $select.city,
                        'entity-params': { SOMENTE_ATIVOS: 1 },
                        'require-params': ['ID_PARQUE_SERVICO'],
                        clearable: true,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.cityId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        },
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.cityId.props.disabled = !fields.worksiteId.value;
                            },
                        },
                    ],
                }),
                regionId: new Field({
                    name: 'ID_REGIAO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('REGION'),
                        entity: $select.detailedIssueRegion,
                        'entity-params': {},
                        'require-params': ['ID_PARQUE_SERVICO'],
                        clearable: true,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: ['ID_PARQUE_SERVICO', 'ID_MUNICIPIO'],
                            handler: (fields) => {
                                fields.regionId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                    ID_MUNICIPIO: fields.cityId.value,
                                };
                            },
                        },
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.regionId.props.disabled = !fields.worksiteId.value;
                            },
                        },
                    ],
                }),
                userCompanyId: new Field({
                    name: 'ID_EMPRESA_USUARIA',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('USER_COMPANY'),
                        entity: $autocomplete.userCompany,
                    },
                }),
                issueTypes: new Field({
                    name: 'TIPOS_OCORRENCIA',
                    component: 'AAutocomplete',
                    parser: $util.arrayToString,
                    props: {
                        label: i18n.t('ISSUE_TYPES'),
                        entity: $autocomplete.issueType,
                        'entity-params': {
                            HABILITADO_PARQUE: null,
                            SOMENTE_NAO_PREVENTIVAS: null,
                            HABILITADO_CALLCENTER: null,
                        },
                        clearable: true,
                        'clear-provider': true,
                        multiple: true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.issueTypes.props.disabled = !fields.worksiteId.value;
                            },
                        },
                    ],
                }),
                authorizedIssues: new Field({
                    name: 'OCORRENCIAS_AUTORIZADAS',
                    component: 'ACheckbox',
                    parser: $util.booleanToNumber,
                    props: {
                        label: i18n.t('AUTHORIZED_ISSUES'),
                    },
                }),
                attributeFilter: new Field({
                    name: 'FILTRO_ATRIBUTOS',
                    component: () => import('@components/attribute/AttributeFilter.vue'),
                    parser: $util.attributesToString,
                    props: {
                        _sgi: 1,
                        _filter: false,
                    },
                }),
                report: new Field({
                    name: 'RELATORIO',
                    value: 0,
                }),
                situation: new Field({
                    name: 'SITUATION',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
