const paths = [
    {
        path: '/worksites',
        name: 'Worksites',
        title: 'WORKSITES',
        icon: 'fa fa-briefcase',
        menu: 'registration',
        component: () => import(/* webpackChunkName: "worksite" */ /* webpackMode: "lazy" */ '@/pages/worksite/WorksiteList.vue'),
    },
    {
        path: '/worksite-details',
        name: 'WorksiteDetails',
        title: 'WORKSITE_DETAILS',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "worksite" */ /* webpackMode: "lazy" */ '@/pages/worksite/WorksiteDetails.vue'),
    },
    {
        path: '/region-registration',
        name: 'RegionRegistration',
        title: 'REGION_REGISTRATION',
        component: () => import(/* webpackChunkName: "worksite" */ /* webpackMode: "lazy" */ '@/pages/worksite/WorksiteDetailsRegionRegistration.vue'),
    },
    {
        path: '/district-registration',
        name: 'DistrictRegistration',
        title: 'DISTRICT_REGISTRATION',
        component: () => import(/* webpackChunkName: "worksite" */ /* webpackMode: "lazy" */ '@/pages/worksite/WorksiteDetailsDistrictRegistration.vue'),
    },
    {
        path: '/tax-plan',
        name: 'TaxPlan',
        title: 'TAX_PLAN',
        component: () => import(/* webpackChunkName: "worksite" */ /* webpackMode: "lazy" */ '@/pages/worksite/WorksiteDetailsTaxPlan.vue'),
    },
    {
        path: '/company-location',
        name: 'CompanyLocation',
        title: 'COMPANY_LOCATION',
        component: () => import(/* webpackChunkName: "worksite" */ /* webpackMode: "lazy" */ '@/components/companyLocation/CompanyLocation.vue'),
    },
    {
        path: '/workiste-details-region-link',
        name: 'WorksiteDetailsRegionLink',
        title: 'REGION_LINK',
        component: () => import(/* webpackChunkName: "worksite" */ /* webpackMode: "lazy" */ '@/pages/worksite/WorksiteDetailsRegionLink.vue'),
    },
    {
        path: '/worksite-tags',
        name: 'WorksiteDetailsTagsGeneration',
        title: 'TAGS_GENERATION',
        component: () => import(/* webpackChunkName: "tags" */ /* webpackMode: "lazy" */ '@/pages/worksite/WorksiteDetailsTagsGeneration.vue'),
    },
    {
        path: '/workiste-details-region-registration-district-link',
        name: 'WorkisteDetailsRegionRegistrationDistrictLink',
        title: 'DISTRICT_LINK',
        component: () => import(/* webpackChunkName: "worksite" */ /* webpackMode: "lazy" */ '@/pages/worksite/WorkisteDetailsRegionRegistrationDistrictLink.vue'),
    },
    {
        path: '/worksite-layers',
        name: 'WorksiteDetailsLayerConfigLayerRegistration',
        title: 'WORKSITE_LAYERS',
        component: () => import(/* webpackChunkName: "worksite" */ /* webpackMode: "lazy" */ '@/pages/worksite/WorksiteDetailsLayerConfigLayerRegistration.vue'),
    },
    {
        path: '/materials-families-registration',
        name: 'WorksiteDetailsMaterialsFamiliesRegistration',
        title: 'FAMILIES_REGISTRATION',
        component: () => import(/* webpackChunkName: "worksite" */ /* webpackMode: "lazy" */ '@/pages/worksite/WorksiteDetailsMaterialsFamiliesRegistration.vue'),
    },
    {
        path: '/stock-center',
        name: 'WorksiteDetailsMaterialsListingRegistration',
        title: 'LISTINGS_REGISTRATION',
        component: () => import(/* webpackChunkName: "worksite" */ /* webpackMode: "lazy" */ '@/pages/worksite/WorksiteDetailsMaterialsListingsRegistration.vue'),
    },
    {
        path: '/workiste-details-company-location-registration',
        name: 'WorksiteDetailsCompanyLocationRegistration',
        title: 'COMPANY_LOCATION',
        component: () => import(/* webpackChunkName: "worksite" */ /* webpackMode: "lazy" */ '@/pages/worksite/WorksiteDetailsCompanyLocationRegistration.vue'),
    },
    {
        path: '/worksite-location-type',
        name: 'WorksiteLocationType',
        title: 'COMPANY_LOCATION_TYPE',
        menu: 'humanResources',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "worksite" */ /* webpackMode: "lazy" */ '@/pages/worksite/WorksiteLocationType.vue'),
    },
    {
        path: '/worksite-details-layer-config-upload-shapefile',
        name: 'WorksiteDetailsLayerConfigUploadShapefile',
        title: 'UPLOAD_SHAPEFILE',
        component: () => import(/* webpackChunkName: "worksite" */ /* webpackMode: "lazy" */ '@/pages/worksite/WorksiteDetailsLayerConfigUploadShapefile.vue'),
    },
    {
        path: '/worksite-asset-type',
        name: 'WorksiteAssetType',
        title: 'WORKSITE_ASSET_TYPE',
        component: () => import(/* webpackChunkName: "worksite" */ /* webpackMode: "lazy" */ '@/pages/worksite/WorksiteAssetType.vue'),
    },
    {
        path: '/company-location-list',
        title: 'COMPANY_LOCATION',
        name: 'CompanyLocationList',
        menu: 'registration',
        component: () => import(/* webpackChunkName: "worksite" */ /* webpackMode: "lazy" */ '@/pages/worksite/CompanyLocationList.vue'),
    },
    {
        path: '/warranty_recalculation_execution_history',
        title: 'WARRANTY_RECALCULATION_EXECUTION_HISTORY',
        name: 'WarrantyRecalculationExecutionHistory',
        component: () => import(/* webpackChunkName: "worksite" */ /* webpackMode: "lazy" */ '@/pages/worksite/WarrantyRecalculationExecutionHistory.vue'),
    },
];

export default paths;
