<script>
    export default {
        name: 'ACard',
        functional: true,
        render: function (createElement, { data, children }) {
            return createElement('VCard', data, children);
        },
    };
</script>

<style lang='scss'>
.application {
    .v-dialog {
        .v-card {
            .v-card__title {
                font-weight: 400;
            }
        }
    } 
}
</style>