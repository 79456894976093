const paths = [
    {
        path: '/material-application-list',
        title: 'MATERIAL_APPLICATION_LIST',
        name: 'MaterialApplicationList',
        icon: 'mdi-tools',
        menu: 'construction',
        component: () => import(/* webpackChunkName: "materialApplication" */ /* webpackMode: "lazy" */ '@/pages/materialApplication/MaterialApplicationList.vue'),
    },
    {
        path: '/material-application-general',
        title: 'MATERIAL_APPLICATION',
        name: 'MaterialApplicationGeneral',
        favCategory: 'construction',
        component: () => import(/* webpackChunkName: "materialApplication" */ /* webpackMode: "lazy" */ '@/pages/materialApplication/MaterialApplicationGeneral.vue'),
    },
    {
        path: '/material-application-wizard',
        title: 'MATERIAL_APPLICATION_WIZARD',
        name: 'MaterialApplicationWizard',
        component: () => import(/* webpackChunkName: "materialApplication" */ /* webpackMode: "lazy" */ '@/pages/materialApplication/MaterialApplicationWizard.vue'),
    },
    {
        path: '/material-application-project-items',
        title: 'MATERIAL_APPLICATION_PROJECT_ITEMS',
        name: 'MaterialApplicationProjectItems',
        component: () => import(/* webpackChunkName: "materialApplication" */ /* webpackMode: "lazy" */ '@/pages/materialApplication/MaterialApplicationProjectItems.vue'),
    },
];

export default paths;
