<template>
    <div>
        <a-row no-gutters>
            <a-col :cols='(selected != null && selected.value === "LAST_X_DAYS") ? 9 : 12'>
                <v-select
                    v-model='selected'
                    v-bind='$attrs'
                    ref='selectPicker'
                    :rules='selectRules'
                    :items='attributes'
                    :label='label + " - " + $t("RELATIVE")'
                    :readonly='readonly'
                    :clearable='clearable && !readonly'
                    :disabled='disabled'
                    return-object
                    outlined
                    dense
                />
            </a-col>
            <template v-if='selected != null && selected.value === "LAST_X_DAYS" '>
                <a-col cols='3'>
                    <a-text-field
                        v-model.number='lastXDays'
                        v-bind='$attrs'
                        :label='$t("DAY")'
                        :readonly='readonly'
                        :disabled='disabled'
                        :clearable='false'
                        type='number'
                        class='pl-1'
                    />
                </a-col>
            </template>
        </a-row>
    </div>
</template>
<script>
    import moment from 'moment';

    export default {
        name: 'ARelativeDatePicker',
        props: {
            /**
             * v-model
             * @type {Object}
             * @default {null}
             */
            value: {
                type: Object,
                default: null,
            },
            readonly: {
                type: Boolean,
                required: false,
                default: false,
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            },
            label: {
                type: String,
                required: false,
                default: '',
            },
            clearable: {
                type: Boolean,
                required: false,
                default: true,
            },
            required: {
                type: Boolean,
                required: false,
                default: false,
            },
            maxDate: {
                type: [Date, String],
                required: false,
                default: null,
                validator: (value) => moment(value, 'YYYY-MM-DD', true).isValid(),
            },
            minDate: {
                type: [Date, String],
                required: false,
                default: null,
                validator: (value) => moment(value, 'YYYY-MM-DD', true).isValid(),
            },
            disableDefaultRules: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        data: function () {
            return {
                attributes: [
                    {
                        text: this.$t('TODAY'),
                        value: 'TODAY',
                    },
                    {
                        text: this.$t('YESTERDAY'),
                        value: 'YESTERDAY',
                    },
                    {
                        text: this.$t('FIRST_CURRENT_MONTH'),
                        value: 'FIRST_CURRENT_MONTH',
                    },
                    {
                        text: this.$t('LAST_CURRENT_MONTH'),
                        value: 'LAST_CURRENT_MONTH',
                    },
                    {
                        text: this.$t('FIRST_PREVIOUS_MONTH'),
                        value: 'FIRST_PREVIOUS_MONTH',
                    },
                    {
                        text: this.$t('LAST_PREVIOUS_MONTH'),
                        value: 'LAST_PREVIOUS_MONTH',
                    },
                    {
                        text: this.$t('FIRST_CURRENT_TRIMESTER'),
                        value: 'FIRST_CURRENT_TRIMESTER',
                    },
                    {
                        text: this.$t('LAST_CURRENT_TRIMESTER'),
                        value: 'LAST_CURRENT_TRIMESTER',
                    },
                    {
                        text: this.$t('FIRST_PREVIOUS_TRIMESTER'),
                        value: 'FIRST_PREVIOUS_TRIMESTER',
                    },
                    {
                        text: this.$t('LAST_PREVIOUS_TRIMESTER'),
                        value: 'LAST_PREVIOUS_TRIMESTER',
                    },
                    {
                        text: this.$t('LAST_X_DAYS', { days: 'X' }),
                        value: 'LAST_X_DAYS',
                    },
                ],
                selectDateRule: value => {
                    if (!value || !value.value) {
                        return true;
                    }

                    let date = this.$util.relativeDateParser({
                        type: 'RELATIVE',
                        value: value.value,
                        lastXDays: this.lastXDays,
                    });

                    // by not passing the format option to the parser, it defualts to 'DD/MM/YYYY'
                    date = this.$moment(date, 'DD/MM/YYYY', true);
                    let isValid = date.isValid();

                    if (isValid === true && this.maxDate) {
                        const maxDate = this.$moment(this.maxDate);
                        const format = this.$util.getDateFormatOfUserLocale();
                        const maxDateMessage = this.$t('MAX_DATE_ENTITY', { max: maxDate.format(format) });
                        isValid = date.isSameOrBefore(this.maxDate) || maxDateMessage;
                    }

                    if (isValid === true && this.minDate) {
                        const minDate = this.$moment(this.minDate);
                        const format = this.$util.getDateFormatOfUserLocale();
                        const minDateMessage = this.$t('MIN_DATE_ENTITY', { min: minDate.format(format) });
                        isValid = date.isSameOrAfter(this.minDate) || minDateMessage;
                    }

                    return isValid || this.$t('INVALID_DATE');
                },
                selectRequiredRule: value => !!value || this.$t('MANDATORY'),
            };
        },
        computed: {
            selected: {
                get: function () {
                    if (this.value != null && this.value.value != null) {
                        const valIndex = this.attributes.findIndex((item) => item.value === this.value.value);
                        return this.attributes[valIndex] || null;
                    }
                    return null;
                },
                set: function (val) {
                    const returnValue = val != null ? val.value : null;
                    this.$emit('input', { type: 'RELATIVE', value: returnValue });
                },
            },
            lastXDays: {
                get: function () {
                    if (this.value != null && this.value.lastXDays != null) {
                        return this.value.lastXDays;
                    }
                    return null;
                },
                set: function (val) {
                    const returnValue = val != null ? val : null;
                    this.$emit('input', { type: 'RELATIVE', value: 'LAST_X_DAYS', lastXDays: returnValue });
                },
            },
            selectRules: function () {
                const rules = [];

                if (!this.disableDefaultRules) {
                    rules.push(this.selectDateRule);
                }
                if (this.required) {
                    rules.push(this.selectRequiredRule);
                }

                return rules;
            },
        },
        watch: {
            lastXDays: function() {
                this.validate(true);
            }
        },
        methods: {
            validate: function (silent) {
                if (this.$refs.selectPicker) {
                    this.$refs.selectPicker.validate(silent);
                }
            },
        }
    };
</script>
