export default {
    ENTITY_MATERIAL: 'MATERIAL',
    ENTITY_SERVICE: 'SERVICO',
    ENTITY_MODULE: 'MODULO',
    ENTITY_SERVICE_COMPOSITION: 'COMPOSICAO_SERVICO',
    ENTITY_GROUP: 'AGRUPADOR',
    ENTITY_STOCK_CENTER: 'CENTRO_ESTOQUE',
    SELECTION_BULLETIN_EXECUTION_STEP: 1,
    DATA_BULLETIN_EXECUTION_STEP: 2,
    ITEM_BULLETIN_EXECUTION_MODE: 0,
    SERVICE_BULLETIN_EXECUTION_MODE: 1,
    CLIENT_SERVICE_MATERIAL_MATERIAL: 'M',
    COD_DESCRIPTION_ITEM_REGISTER_STEP: 1,
    RELATED_ITEM_REGISTER_STEP: 2,
    DATA_ITEM_REGISTER_STEP: 3,
    ADDITIONAL_DATA_ITEM_REGISTER_STEP: 4,
    ADDITIONAL_DATA_SERVICE_REGISTER_STEP: 4,
    FINANC_CENTER_TYPE_STOCK_CENTER: 'CEN_EST',
    QRCODE_FILLS_ATTRIBUTES: {
        UNINFORMED: 0,
        NO: 1,
        YES: 2,
    },
};
