const paths = [
    {
        path: '/quality-model',
        name: 'QualityModel',
        title: 'QUALITY_MODEL',
        icon: 'mdi-file-document',
        menu: 'asset',
        categories: [
          'reports',
        ],
        component: () => import(/* webpackChunkName: "qualityModel" */ /* webpackMode: "lazy" */ '@/pages/qualityModel/QualityModel.vue'),
    },
];

export default paths;
