import Vue from 'vue';
import i18n from '@/i18n';
import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
const { $autocomplete, $util, } = Vue.prototype;
export class GetEnergyConsumptionPerPeriod extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarConsumoEnergeticoPeriodo',
            type: 'SEARCH',
            node: 'PERIODOS.PERIODO',
            fields: {
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('WORKSITE'),
                        entity: $autocomplete.worksite,
                        'entity-params': { ATIVO: 1 },
                    },
                }),
                startDate: new Field({
                    name: 'PERIODO_INICIAL',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('INITIAL_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        clearable: true,
                        'enable-relative-picker': true,
                    },
                }),
                endDate: new Field({
                    name: 'PERIODO_FINAL',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('ENDING_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        clearable: true,
                        'enable-relative-picker': true,
                    },
                }),
                serviceTypeId: new Field({
                    name: 'ID_TIPO_PONTO_SERVICO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('ASSET_TYPE'),
                        entity: $autocomplete.assetType,
                        clearable: true,
                    },
                }),
                yearFilter: new Field({
                    name: 'FILTRO_ANO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
