import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import { parseSuccessResponse, showErrorsResponse } from '../utils';
export class GetCompanyLogo extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarFotoEmpresaUsuaria',
            type: 'FILE',
            config: { type: 'file' },
            fields: {
                parser: new Field({
                    name: 'PARSER',
                }),
                systemLogoDisplay: new Field({
                    name: 'EXIBICAO_LOGO_SISTEMA',
                }),
                userCompanyId: new Field({
                    name: 'ID_EMPRESA_USUARIA',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
/**
 *  Tg Companies
 *  =======================================================================
*/
export class GetCompanies extends CallCommand {
    constructor() {
        super({
            name: 'get_companies',
            type: 'NONE',
            node: 'RESPONSE',
            customRequestParser: function () {
                const filter = {
                    where: {
                        deleted_at: null,
                    },
                };
                return {
                    BODY: `filter=${encodeURIComponent(JSON.stringify(filter))}`,
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveCompany extends CallCommand {
    constructor() {
        super({
            name: 'post_companies',
            type: 'NONE',
            fields: {
                company: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class DeleteCompany extends CallCommand {
    constructor() {
        super({
            name: 'delete_companies_0',
            type: 'NONE',
            fields: {
                companyId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    async callCommand(config, debug) {
        return super.callCommand(config, debug);
    }
}
