import Vue from 'vue';
import i18n from '@/i18n';
import { pickBy } from 'lodash';
import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import { showErrorsResponse, parseSuccessResponse } from '@/pages/remoteManagement/services/utils';
import localStore from '@/pages/remoteManagement/services/store';
import moment from 'moment';
import deviceStates from '@/pages/remoteManagement/deviceState/device-states';
export class GetCompanyDevices extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_company-devices',
            type: 'NONE',
            fields: {
                filter: new Field({
                    name: 'BODY',
                    parser: (value) => `filter=${encodeURIComponent(JSON.stringify(value))}`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class GetCompanyDevicesSearch extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_company-devices_search',
            type: 'SEARCH',
            node: 'RESPONSE',
            fields: {
                serialNumber: new Field({
                    name: 'serial_number',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('SERIAL_NUMBER'),
                    },
                }),
                reference: new Field({
                    name: 'reference',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('REFERENCE'),
                    },
                }),
                deviceStateId: new Field({
                    name: 'deviceStateId',
                    component: 'ASelect',
                    value: deviceStates.installed,
                    props: {
                        label: i18n.t('DEVICE_STATE'),
                        items: localStore.state.deviceStates || [],
                        itemText: (obj) => i18n.t(obj.description),
                        itemValue: 'device_state_id',
                        clearable: true,
                    },
                }),
                withAlerts: new Field({
                    name: 'withAlerts',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('SHOW_ONLY_DEVICE_WITH_ALERTS'),
                        entity: Vue.prototype.$select?.yesNo,
                    },
                }),
                alertType: new Field({
                    name: 'alertType',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('ALERT_TYPE'),
                        itemText: (el) => i18n.t(el.description),
                        itemValue: 'alert_type_id',
                        clearable: true,
                        multiple: true,
                    },
                }),
                isHistoryMode: new Field({
                    name: 'isHistoryMode',
                    value: false,
                }),
                scheduleId: new Field({
                    name: 'scheduleId',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('DEVICE_SCHEDULE'),
                        items: localStore.state.schedules || [],
                        itemText: 'schedule_desc',
                        itemValue: 'schedule_id',
                        clearable: true,
                        multiple: true,
                    },
                }),
                groupId: new Field({
                    name: 'groupId',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('GROUP'),
                        items: localStore.state.deviceGroups || [],
                        itemText: 'group_desc',
                        itemValue: 'device_group_id',
                        clearable: true,
                        multiple: true,
                    },
                }),
                deviceTypeId: new Field({
                    name: 'deviceTypeId',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('DEVICE_TYPE'),
                        items: localStore.state.deviceTypes || [],
                        itemText: (obj) => i18n.t(obj.description),
                        itemValue: 'device_type_id',
                        clearable: true,
                    },
                }),
                companyId: new Field({
                    name: 'companyId',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('COMPANY'),
                        items: localStore.state.companies || [],
                        itemText: 'company_name',
                        itemValue: 'company_id',
                        clearable: true,
                    },
                }),
                initialDate: new Field({
                    name: 'initial_date',
                    component: 'ADatePicker',
                    props: {
                        'text-field': true,
                        label: i18n.t('INITIAL_DATE'),
                    },
                }),
                finalDate: new Field({
                    name: 'final_date',
                    component: 'ADatePicker',
                    props: {
                        'text-field': true,
                        label: i18n.t('ENDING_DATE'),
                    },
                }),
            },
            customRequestParser: function (params) {
                const filter = {
                    last_contact: {
                        initial_date: params.initial_date || null,
                        final_date: params.final_date || null,
                    },
                    serial_number: params.serialNumber || null,
                    reference: params.reference || null,
                    device_state_id: params.deviceStateId || null,
                    company_id: params.companyId || null,
                    schedule_id: params.scheduleId || null,
                    device_group_id: params.groupId || null,
                    alert_type_id: params.alertType || null,
                    device_type_id: params.deviceTypeId || null,
                    order: 'last_contact ASC',
                    withAlerts: params.withAlerts ? params.withAlerts === 1 : null,
                    isHistoryMode: params.isHistoryMode || null,
                };
                return {
                    BODY: `searchFilter=${encodeURIComponent(JSON.stringify(filter))}`,
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetCompanyDeviceById extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_company-devices_0',
            type: 'NONE',
            fields: {
                filter: new Field({
                    name: 'BODY',
                    parser: (value) => `filter=${encodeURIComponent(JSON.stringify(value))}`,
                }),
                companyDeviceId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{PARAM_0: ${String(value)}}`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class SaveCompanyDevice extends CallCommand {
    constructor() {
        super({
            name: 'post_company-devices',
            type: 'SAVE',
            fields: {
                companyDevice: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class GetCompanyDeviceOperations extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_company-device-details_operations',
            type: 'SEARCH',
            node: 'RESPONSE',
            fields: {
                companyDeviceIds: new Field({
                    name: 'companyDeviceIds',
                }),
            },
            customRequestParser: function (params) {
                const query = new URLSearchParams(params);
                return {
                    BODY: query.toString(),
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class GetCompanyDeviceOperationsAlerts extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_company-device-details_operations_alerts',
            type: 'SEARCH',
            node: 'RESPONSE',
            fields: {
                companyDeviceIds: new Field({
                    name: 'companyDeviceIds',
                }),
                limit: new Field({
                    name: 'limit',
                    value: 10,
                }),
                page: new Field({
                    name: 'page',
                    value: 1,
                }),
                alertTypeId: new Field({
                    name: 'alertTypeId',
                }),
                hardwareAlertTypeId: new Field({
                    name: 'hardwareAlertTypeId',
                }),
            },
            customRequestParser: function (params) {
                const filters = pickBy(params, (v) => v !== undefined);
                const query = new URLSearchParams(filters);
                return {
                    BODY: query.toString(),
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetCompanyDeviceOperationsMeasurements extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_company-device-details_operations_measurements',
            type: 'SEARCH',
            node: 'RESPONSE',
            fields: {
                companyDeviceIds: new Field({
                    name: 'companyDeviceIds',
                }),
                limit: new Field({
                    name: 'limit',
                    value: 10,
                }),
                page: new Field({
                    name: 'page',
                    value: 1,
                }),
                measurementTypeId: new Field({
                    name: 'measurementTypeId',
                }),
            },
            customRequestParser: function (params) {
                const filters = pickBy(params, (v) => !!v);
                const query = new URLSearchParams(filters);
                return {
                    BODY: query.toString(),
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetCompanyDeviceMeasurements extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_company-device-details_measurements',
            type: 'SEARCH',
            node: 'RESPONSE',
            fields: {
                companyDeviceIds: new Field({
                    name: 'companyDeviceIds',
                }),
                initialDate: new Field({
                    name: 'initialDate',
                    component: 'ADateTimePicker',
                    props: {
                        'text-field': true,
                        label: i18n.t('INITIAL_DATE'),
                    },
                }),
                finalDate: new Field({
                    name: 'finalDate',
                    component: 'ADateTimePicker',
                    props: {
                        'text-field': true,
                        label: i18n.t('ENDING_DATE'),
                    },
                }),
                measurementTypeIds: new Field({
                    name: 'measurementTypeId',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('MEASUREMENT_TYPE'),
                        items: localStore.state.measurementTypes,
                        itemText: (obj) => i18n.t(obj.description),
                        itemValue: 'measurement_type_id',
                        clearable: true,
                        multiple: true,
                        rules: [
                            (value) => {
                                if (!value) {
                                    return true;
                                }
                                if (value.length === 0) {
                                    return i18n.t('MIN_ITEMS_SELECTED', { value: 1 });
                                }
                                if (value.length > 4) {
                                    return i18n.t('MAX_ITEMS_SELECTED', { value: 4 });
                                }
                                return true;
                            },
                        ],
                    },
                }),
            },
            customRequestParser: function (params) {
                const filters = pickBy(params, (v) => !!v);
                const query = new URLSearchParams({
                    ...filters,
                    initialDate: moment(params.initialDate).local().utc().format(),
                    finalDate: moment(params.initialDate).local().utc().format(),
                });
                return {
                    BODY: query.toString(),
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class GetCompanyDeviceProperties extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_company-device-details_properties',
            type: 'SEARCH',
            node: 'RESPONSE',
            fields: {
                companyDeviceIds: new Field({
                    name: 'companyDeviceIds',
                }),
                specificDate: new Field({
                    name: 'specificDate',
                }),
            },
            customRequestParser: function (params) {
                const filters = pickBy(params, (v) => !!v);
                const query = new URLSearchParams(filters);
                return {
                    BODY: query.toString(),
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class GetCompanyDevicePropertiesNextConfigs extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_company-device-details_properties_next-configs',
            type: 'SEARCH',
            node: 'RESPONSE',
            fields: {
                companyDeviceIds: new Field({
                    name: 'companyDeviceIds',
                }),
                limit: new Field({
                    name: 'limit',
                    value: 10,
                }),
                page: new Field({
                    name: 'page',
                    value: 1,
                }),
            },
            customRequestParser: function (params) {
                const filters = pickBy(params, (v) => !!v);
                const query = new URLSearchParams(filters);
                return {
                    BODY: query.toString(),
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class GetCompanyDeviceAlerts extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_company-device-details_alerts',
            type: 'SEARCH',
            node: 'RESPONSE',
            fields: {
                companyDeviceIds: new Field({
                    name: 'companyDeviceIds',
                }),
                page: new Field({
                    name: 'PAGE',
                }),
                limit: new Field({
                    name: 'PAGE_SIZE',
                }),
                alertTypeId: new Field({
                    name: 'alertTypeId',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('ALERT_TYPE'),
                        itemText: (obj) => i18n.t(obj.description),
                        returnObject: true,
                        clearable: true,
                    },
                }),
                initialDate: new Field({
                    name: 'initialDate',
                    component: 'ADateTimePicker',
                    props: {
                        'text-field': true,
                        label: i18n.t('INITIAL_DATE'),
                        clearable: true,
                    },
                }),
                finalDate: new Field({
                    name: 'finalDate',
                    component: 'ADateTimePicker',
                    props: {
                        'text-field': true,
                        label: i18n.t('ENDING_DATE'),
                        clearable: true,
                    },
                }),
                processed: new Field({
                    name: 'processed',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('PROCESSED'),
                        entity: Vue.prototype.$select?.yesNo,
                    },
                }),
            },
            customRequestParser: function (params) {
                const { alertTypeId, ...filters } = params;
                if (alertTypeId?.alert_type_id) {
                    filters.alertTypeId = alertTypeId.alert_type_id;
                }
                if (alertTypeId?.hardware_alert_type_id) {
                    filters.hardwareAlertTypeId = alertTypeId.hardware_alert_type_id;
                }
                if (typeof filters.processed === 'number') {
                    filters.processed = filters.processed === 1;
                }
                if (filters.initialDate) {
                    filters.initialDate = moment(filters.initialDate).local().utc().format();
                }
                if (filters.finalDate) {
                    filters.finalDate = moment(filters.finalDate).local().utc().format();
                }
                filters.page += 1;
                const query = new URLSearchParams(pickBy(filters, (v) => v !== null && v !== undefined));
                return {
                    BODY: query.toString(),
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class GetCompanyDeviceAlertsMeasurements extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_company-device-details_alerts_measurements',
            type: 'SEARCH',
            node: 'RESPONSE',
            fields: {
                deviceId: new Field({
                    name: 'deviceId',
                }),
                alertId: new Field({
                    name: 'alertId',
                }),
                interval: new Field({
                    name: 'interval',
                    component: 'ASelect',
                    value: 2,
                    props: {
                        label: `${i18n.t('INTERVAL')} ( ${i18n.t('HOURS')} )`,
                        items: [
                            { text: '2', value: 2 },
                            { text: '4', value: 4 },
                            { text: '6', value: 6 },
                            { text: '12', value: 12 },
                        ],
                    },
                }),
            },
            customRequestParser: function (params) {
                const query = new URLSearchParams(params);
                return {
                    BODY: query.toString(),
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class GetCompanyDevicePropertiesAttributes extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_company-device-details_properties_attributes',
            type: 'SEARCH',
            node: 'RESPONSE',
            fields: {
                companyDeviceIds: new Field({
                    name: 'companyDeviceIds',
                }),
                templateId: new Field({
                    name: 'templateId',
                }),
                page: new Field({
                    name: 'page',
                }),
                limit: new Field({
                    name: 'limit',
                }),
                specificDate: new Field({
                    name: 'specificDate',
                }),
            },
            customRequestParser: function (params) {
                const filters = pickBy(params, (v) => !!v);
                const query = new URLSearchParams(filters);
                return {
                    BODY: query.toString(),
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
