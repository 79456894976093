const paths = [
    {
        path: '/highway-type-list',
        name: 'HighwayTypeList',
        title: 'HIGHWAY_TYPES',
        icon: 'widgets',
        menu: 'maintenance',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "highwayType" */ /* webpackMode: "lazy" */ '@/pages/highwayType/HighwayTypeList.vue'),
    },
    {
        path: '/highway-type-general',
        name: 'HighwayTypeGeneral',
        title: 'HIGHWAY_TYPE_GENERAL',
        favCategory: 'maintenance',
        component: () => import(/* webpackChunkName: "highwayType" */ /* webpackMode: "lazy" */ '@/pages/highwayType/HighwayTypeGeneral.vue'),
    },
];

export default paths;
