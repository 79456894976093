const paths = [
  {
      path: '/entry-invoices-list',
      title: 'ENTRY_INVOICES',
      name: 'EntryInvoicesList',
      icon: 'fas fa-file-invoice',
      menu: 'shopping',
      component: () => import(/* webpackChunkName: "entryInvoices" */ /* webpackMode: "lazy" */ '@/pages/entryInvoices/EntryInvoicesList.vue'),
  },
  {
      path: '/entry-invoice',
      title: 'ENTRY_INVOICE',
      name: 'EntryInvoice',
      favCategory: 'shopping',
      component: () => import(/* webpackChunkName: "entryInvoices" */ /* webpackMode: "lazy" */ '@/pages/entryInvoices/EntryInvoice.vue'),
  },
  {
    path: '/entry-invoice-details-change-stock-center',
    title: 'ENTRY_INVOICE_CHANGE_STOCK_CENTER',
    name: 'EntryInvoiceDetailsChangeStockCenter',
    component: () => import(/* webpackChunkName: "entryInvoices" */ /* webpackMode: "lazy" */ '@/pages/entryInvoices/EntryInvoiceDetailsChangeStockCenter.vue'),
  },
  {
    path: '/entry-invoice-items-inclusion',
    title: 'ENTRY_INVOICE_ITEMS_INCLUSION',
    name: 'EntryInvoiceItemsInclusion',
    component: () => import(/* webpackChunkName: "entryInvoices" */ /* webpackMode: "lazy" */ '@/pages/entryInvoices/EntryInvoiceItemsInclusion.vue'),
  },
  {
    path: '/entry-invoice-wizard',
    title: 'ENTRY_INVOICE',
    name: 'EntryInvoiceWizard',
    component: () => import(/* webpackChunkName: "entryInvoices" */ /* webpackMode: "lazy" */ '@/pages/entryInvoices/EntryInvoiceWizard.vue'),
  },
  {
    path: '/entry-invoice-details-adjustment',
    title: 'ENTRY_INVOICE',
    name: 'EntryInvoiceDetailsAdjustment',
    component: () => import(/* webpackChunkName: "entryInvoices" */ /* webpackMode: "lazy" */ '@/pages/entryInvoices/EntryInvoiceDetailsAdjustment.vue'),
  },
  {
    path: '/entry-invoice-details-schedules-selection',
    title: 'ENTRY_INVOICE',
    name: 'EntryInvoiceDetailsSchedulesSelection',
    component: () => import(/* webpackChunkName: "entryInvoices" */ /* webpackMode: "lazy" */ '@/pages/entryInvoices/EntryInvoiceDetailsSchedulesSelection.vue'),
  },
  {
    path: '/entry-invoice-details-schedule',
    title: 'ENTRY_INVOICE',
    name: 'EntryInvoiceDetailsSchedule',
    component: () => import(/* webpackChunkName: "entryInvoices" */ /* webpackMode: "lazy" */ '@/pages/entryInvoices/EntryInvoiceDetailsSchedule.vue'),
  },
  {
    path: '/entry-invoice-consumption',
    title: 'ENTRY_INVOICE_CONSUMPTION',
    name: 'EntryInvoiceConsumption',
    component: () => import(/* webpackChunkName: "entryInvoices" */ /* webpackMode: "lazy" */ '@/pages/entryInvoices/EntryInvoiceConsumption.vue'),
  },

];

export default paths;
