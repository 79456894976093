export default async function personBankAccountWithInfo (provider) {
    provider.forEach(item => {
        if (item.DESC_BANCO_PESSOA) {
            item.description = `${item.STRING_CONTA}`;
        } else {
            item.description = `${item.DESC_BANCO}`;
        }
    });

    return provider;
}
