const paths = [
    {
        path: '/grouping',
        name: 'Grouping',
        title: 'GROUPING',
        icon: 'widgets',
        menu: 'registration',
        component: () => import(/* webpackChunkName: "grouping" */ /* webpackMode: "lazy" */ '@/pages/grouping/Grouping.vue'),
    },
];

export default paths;
