const paths = [
    {
        path: '/vehicle-alert-type-list',
        title: 'VEHICLE_ALERT_TYPES',
        name: 'VehicleAlertTypeList',
        icon: 'widgets',
        menu: 'fleet',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "vehicleAlertType" */ /* webpackMode: "lazy" */ '@/pages/vehicleAlertType/VehicleAlertTypeList.vue'),
    },
    {
        path: '/vehicle-alert-type',
        name: 'VehicleAlertType',
        title: 'VEHICLE_ALERT_TYPE',
        favCategory: 'fleet',
        component: () => import(/* webpackChunkName: "vehicleAlertType" */ /* webpackMode: "lazy" */ '@/pages/vehicleAlertType/VehicleAlertType.vue'),
    },
];

export default paths;
