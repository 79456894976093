const paths = [
    {
        path: '/delimit-team-registration-area',
        title: 'DELIMIT_TEAM_REGISTRATION_AREA',
        name: 'DelimitTeamRegistrationArea',
        icon: 'fa fa-briefcase',
        menu: 'asset',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "document" */ /* webpackMode: "lazy" */ '@/pages/delimitTeamRegistrationArea/DelimitTeamRegistrationArea.vue'),
    },
];

export default paths;
