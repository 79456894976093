const paths = [
    {
        path: '/illuminated-city-settings-general',
        name: 'IlluminatedCitySettingsGeneral',
        component: () => import(/* webpackChunkName: "illuminatedCitySettings" */ /* webpackMode: "lazy" */ '@/pages/illuminatedCitySettings/IlluminatedCitySettingsGeneral.vue'),
    },
    {
        path: '/illuminated-city-settings-list',
        name: 'IlluminatedCitySettingsList',
        title: 'ILLUMINATED_CITY_SETTINGS_LIST',
        icon: 'widgets',
        menu: 'registration',
        component: () => import(/* webpackChunkName: "illuminatedCitySettings" */ /* webpackMode: "lazy" */ '@/pages/illuminatedCitySettings/IlluminatedCitySettingsList.vue'),
    },
    {
        path: '/illuminated-city-settings-default-policies',
        name: 'IlluminatedCitySettingsDefaultPolicies',
        component: () => import(/* webpackChunkName: "illuminatedCitySettings" */ /* webpackMode: "lazy" */ '@/pages/illuminatedCitySettings/IlluminatedCitySettingsDefaultPolicies.vue'),
    },
    {
        path: '/illuminated-city-settings-default-policies-locales',
        name: 'IlluminatedCitySettingsDefaultPoliciesLocales',
        component: () => import(/* webpackChunkName: "illuminatedCitySettings" */ /* webpackMode: "lazy" */ '@/pages/illuminatedCitySettings/IlluminatedCitySettingsDefaultPoliciesLocales.vue'),
    },
    {
        path: '/illuminated-city-settings-locales-lgpd',
        name: 'IlluminatedCitySettingsLocalesLgpd',
        component: () => import(/* webpackChunkName: "illuminatedCitySettings" */ /* webpackMode: "lazy" */ '@/pages/illuminatedCitySettings/IlluminatedCitySettingsLocalesLgpd.vue'),
    },
    {
        path: '/illuminated-city-settings-email',
        name: 'IlluminatedCitySettingsEmail',
        component: () => import(/* webpackChunkName: "equipmentProblemLog" */ /* webpackMode: "lazy" */ '@/pages/illuminatedCitySettings/IlluminatedCitySettingsEmail.vue'),
    },
    {
        path: '/illuminated-city-settings-custom-email-locales',
        name: 'IlluminatedCitySettingsCustomEmailLocales',
        component: () => import(/* webpackChunkName: "equipmentProblemLog" */ /* webpackMode: "lazy" */ '@/pages/illuminatedCitySettings/IlluminatedCitySettingsCustomEmailLocales.vue'),
    },
    {
        path: '/illuminated-city-settings-custom-email',
        name: 'IlluminatedCitySettingsCustomEmail',
        component: () => import(/* webpackChunkName: "equipmentProblemLog" */ /* webpackMode: "lazy" */ '@/pages/illuminatedCitySettings/IlluminatedCitySettingsCustomEmail.vue'),
    },
    {
        path: '/illuminated-city-settings-sms',
        name: 'IlluminatedCitySettingsSms',
        component: () => import(/* webpackChunkName: "equipmentProblemLog" */ /* webpackMode: "lazy" */ '@/pages/illuminatedCitySettings/IlluminatedCitySettingsSms.vue'),
    },
    {
        path: '/illuminated-city-settings-custom-sms-locales',
        name: 'IlluminatedCitySettingsCustomSmsLocales',
        component: () => import(/* webpackChunkName: "equipmentProblemLog" */ /* webpackMode: "lazy" */ '@/pages/illuminatedCitySettings/IlluminatedCitySettingsCustomSmsLocales.vue'),
    },
    {
        path: '/illuminated-city-settings-custom-sms',
        name: 'IlluminatedCitySettingsCustomSms',
        component: () => import(/* webpackChunkName: "equipmentProblemLog" */ /* webpackMode: "lazy" */ '@/pages/illuminatedCitySettings/IlluminatedCitySettingsCustomSms.vue'),
    },
];

export default paths;
