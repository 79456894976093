const getObject = function (value) {
    try {
        if (!value) {
            return {};
        }
        if (value.constructor === Array) {
            return value[0];
        }
        return value;
    } catch (e) {
        return {};
    }
};

export default getObject;
