const paths = [
    {
        path: '/camunda-process-definition-list',
        name: 'CamundaProcessDefinitionList',
        title: 'PROCESS_DEFINITIONS',
        menu: 'processes',
        icon: 'fas fa-project-diagram',
        component: () => import(/* webpackChunkName: "camunda" */ /* webpackMode: "lazy" */ '@/pages/camunda/processDefinition/CamundaProcessDefinitionList.vue'),
    },
    {
        path: '/camunda-process-definition',
        name: 'CamundaProcessDefinition',
        title: 'PROCESS_DEFINITION',
        component: () => import(/* webpackChunkName: "camunda" */ /* webpackMode: "lazy" */ '@/pages/camunda/processDefinition/CamundaProcessDefinition.vue'),
    },
    {
        path: '/camunda-process-definition-deploy-dialog',
        name: 'CamundaProcessDefinitionDeployDialog',
        title: 'PROCESS_DEFINITION',
        component: () => import(/* webpackChunkName: "camunda" */ /* webpackMode: "lazy" */ '@/pages/camunda/processDefinition/CamundaProcessDefinitionDeployDialog.vue'),
    },
    {
        path: '/camunda-process-definition-correction-dialog',
        name: 'CamundaProcessDefinitionMigrateCorrectionDialog',
        title: 'PROCESS_DEFINITION',
        component: () => import(/* webpackChunkName: "camunda" */ /* webpackMode: "lazy" */ '@/pages/camunda/processDefinition/CamundaProcessDefinitionMigrateCorrectionDialog.vue'),
    },
    {
        path: '/camunda-process-definition-dialog',
        name: 'CamundaProcessDefinitionDialog',
        title: 'PROCESS_DEFINITION',
        component: () => import(/* webpackChunkName: "camunda" */ /* webpackMode: "lazy" */ '@/pages/camunda/processDefinition/CamundaProcessDefinitionDialog.vue'),
    },
    {
        path: '/camunda-process-import-bpmn',
        name: 'CamundaProcessImportBpmn',
        title: 'FLOW_IMPORT',
        component: () => import(/* webpackChunkName: "camunda" */ /* webpackMode: "lazy" */ '@/pages/camunda/processDefinition/CamundaProcessImportBpmn.vue'),
    },
    {
        path: '/camunda-process-import-process-definition',
        name: 'CamundaProcessImportProcessDefinition',
        title: 'IMPORT_PROCESS_DEFINITION',
        component: () => import(/* webpackChunkName: "camunda" */ /* webpackMode: "lazy" */ '@/pages/camunda/processDefinition/CamundaProcessImportProcessDefinition.vue'),
    },
];

export default paths;
