const paths = [
    {
        path: '/report-template-list',
        name: 'ReportTemplateList',
        title: 'REPORT_TEMPLATES',
        icon: 'widgets',
        menu: 'customization',
        component: () => import(/* webpackChunkName: "reportTemplate" */ /* webpackMode: "lazy" */ '@/pages/reportTemplate/ReportTemplateList.vue'),
    },
    {
        path: '/report-template',
        name: 'ReportTemplate',
        title: 'TEMPLATE',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "reportTemplate" */ /* webpackMode: "lazy" */ '@/pages/reportTemplate/ReportTemplate.vue'),
    },
    {
        path: '/report-template-file-details',
        name: 'ReportTemplateFileDetails',
        title: 'TEMPLATE_FILE',
        component: () => import(/* webpackChunkName: "reportTemplate" */ /* webpackMode: "lazy" */ '@/pages/reportTemplate/ReportTemplateFileDetails.vue'),
    },
];

export default paths;
