<template>
    <div>
        <v-img
            v-if='!noImage'
            v-bind='$attrs'
            :src='src'
            class='a-img-custom'
            :lazy-src='src'
            @load='loaded = true'
            v-on='listeners'
        />
        <v-icon
            v-else-if='$slots.default == null && noImage'
        >
            fa-file-image
        </v-icon>
        <slot v-else />
    </div>
</template>

<script>
    export default {
        name: 'AImgCustom',
        components: {
            AImgCustom: {
                inheritAttrs: false,
            },
        },
        props: {
            commandName: {
                type: String,
                required: true,
                default: function () {
                    return '';
                },
            },
            params: {
                type: [Object, Array],
                required: true,
                default: function () {
                    return {};
                },
            },
        },
        data: function () {
            return {
                src: '',
                noImage: false,
            };
        },
        computed: {
            attrs: function () {
                return this.$attrs;
            },
            listeners: function () {
                return this.$listeners;
            },
        },
        watch: {
            params: {
                handler: function (val, oldVal) {
                    if (!this.$_aura.isMatch(val, oldVal)) {
                        this.loadImage();
                    }
                },
            },
        },
        created: function () {
            this.loadImage();
        },
        methods: {
            loadImage: function () {
                this.$_aura.callCommand(this.commandName, this.params, { type: 'file' }).then((resolve) => {
                    this.src = resolve || '';
                    this.noImage = !this.src;
                });
            },
        },
    };
</script>
