const paths = [
    {
        path: '/project-study-list',
        name: 'ProjectStudyList',
        title: 'PROJECT_STUDIES',
        icon: 'fa fa-briefcase',
        menu: 'construction',
        component: () => import(/* webpackChunkName: "projectStudy" */ /* webpackMode: "lazy" */ '@/pages/project/projectStudy/ProjectStudyList.vue'),
    },
    {
        path: '/project-study-fields',
        name: 'ProjectStudyFields',
        title: 'PROJECT_STUDY_NEW',
        component: () => import(/* webpackChunkName: "projectStudy" */ /* webpackMode: "lazy" */ '@/pages/project/projectStudy/ProjectStudyFields.vue'),
    },
    {
        path: '/project-study',
        name: 'ProjectStudy',
        title: 'PROJECT_STUDY',
        favCategory: 'construction',
        component: () => import(/* webpackChunkName: "projectStudy" */ /* webpackMode: "lazy" */ '@/pages/project/projectStudy/ProjectStudy.vue'),
    },
    {
        path: '/project-study-generation',
        name: 'ProjectStudyGeneration',
        title: 'GENERATE_PROJECT',
        component: () => import(/* webpackChunkName: "projectStudy" */ /* webpackMode: "lazy" */ '@/pages/project/projectStudy/ProjectStudyGeneration.vue'),
    },
    {
        path: '/project-study-caption',
        name: 'ProjectStudyCaption',
        title: 'CAPTION',
        component: () => import(/* webpackChunkName: "projectStudy" */ /* webpackMode: "lazy" */ '@/pages/project/projectStudy/ProjectStudyCaption.vue'),
    },
];

export default paths;
