const paths = [
    {
        path: '/item-catalogue-list',
        name: 'ItemCatalogueList',
        title: 'ITEM_CATALOGUE',
        icon: 'widgets',
        menu: 'stock',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "itemCatalogue" */ /* webpackMode: "lazy" */ '@/pages/itemCatalogue/ItemCatalogueList.vue'),
    },
    {
        path: '/item-catalogue-general',
        name: 'ItemCatalogueGeneral',
        title: 'ITEM_CATALOGUE_GENERAL',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "itemCatalogue" */ /* webpackMode: "lazy" */ '@/pages/itemCatalogue/ItemCatalogueGeneral.vue'),
    },
];

export default paths;
