export default [
    {
        path: '/attended-tickets-monitor',
        title: 'ATTENDED_TICKETS_MONITOR',
        name: 'AttendedTicketsMonitor',
        icon: 'fas fa-chart-pie',
        menu: 'maintenance',
        component: () => import(/* webpackChunkName: "attendedTicketsMonitor" */ /* webpackMode: "lazy" */ '@/pages/ticket/attendedTicketsMonitor/AttendedTicketsMonitor.vue'),
    },
    {
        path: '/pending-tickets-list',
        title: 'PENDING_TICKETS_DETAILS',
        name: 'PendingTicketsList',
        component: () => import(/* webpackChunkName: "attendedTicketsMonitor" */ /* webpackMode: "lazy" */ '@/pages/ticket/attendedTicketsMonitor/PendingTicketsList.vue'),
    },
];
