import i18n from '@/i18n';
import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import { showErrorsResponse, parseSuccessResponse } from '@/pages/remoteManagement/services/utils';
import store from '../store';
export class GetMeasurementConfig extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_measurement-config',
            type: 'NONE',
            node: 'RESPONSE',
            fields: {
                include: new Field({
                    name: 'include',
                    value: [
                        { relation: 'company' },
                        { relation: 'deviceType' },
                    ],
                }),
                companyId: new Field({
                    name: 'company_id',
                    value: null,
                    component: 'a-select',
                    props: {
                        items: store.state.companies,
                        label: i18n.t('COMPANY'),
                        itemText: 'company_name',
                        itemValue: 'company_id',
                        clearable: true,
                    },
                }),
                deviceTypeId: new Field({
                    name: 'device_type_id',
                    value: null,
                    component: 'a-select',
                    props: {
                        label: i18n.t('DEVICE_TYPE'),
                        itemText: 'description',
                        itemValue: 'device_type_id',
                        items: store.state.deviceTypes,
                        clearable: true,
                    },
                }),
                hideDeleted: new Field({
                    name: 'BODY',
                    value: true,
                }),
            },
            customRequestParser: function (params) {
                const filter = {
                    where: {},
                };
                if (params.hideDeleted) {
                    filter.where.deleted_at = { eq: null };
                }
                if (params.companyId) {
                    filter.where.company_id = params.companyId;
                }
                if (params.deviceTypeId) {
                    filter.where.device_type_id = params.deviceTypeId;
                }
                if (params.include) {
                    filter.include = params.include;
                }
                return {
                    BODY: `filter=${encodeURIComponent(JSON.stringify(filter))}`,
                };
            },
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        resolve.RAIZ.RESPONSE = response;
        return resolve;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class GetMeasurementById extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_measurement-config_0',
            type: 'NONE',
            fields: {
                include: new Field({
                    name: 'BODY',
                    value: [],
                }),
                measurementConfigId: new Field({
                    name: 'BODY',
                }),
            },
            customRequestParser: function (params) {
                const filter = {
                    include: params.include,
                };
                return {
                    BODY: `filter=${encodeURIComponent(JSON.stringify(filter))}`,
                    PATH_PARAMS: `{ PARAM_0: ${params.measurementConfigId.toString()} }`,
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, debug, config) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class SaveMeasurementConfig extends CallCommand {
    constructor() {
        super({
            name: 'post_comp_measurement-config',
            type: 'SAVE',
            fields: {
                measurementConfig: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, debug, config) {
        return super.callCommand(param, config, debug);
    }
}
export class EditMeasurementConfig extends CallCommand {
    constructor() {
        super({
            name: 'patch_comp_measurement-config_0',
            type: 'SAVE',
            fields: {
                measurementConfig: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
                measurementConfigId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${value.toString()} }`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, debug, config) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class DeleteMeasurementConfig extends CallCommand {
    constructor() {
        super({
            name: 'delete_comp_measurement-config_0',
            type: 'DELETE',
            fields: {
                measurementConfigId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${value.toString()} }`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, debug, config) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
