<script>
    export default {
        name: 'ATextarea',
        functional: true,
        render: function (createElement, { data, children }) {
            data.attrs = data.attrs || {};
            data.attrs.outlined = Object.keys(data.attrs).includes('outlined') ? data.attrs.outlined : true;

            return createElement('VTextarea', data, children);
        },
    };
</script>
