<template>
    <a-container ref='container'>
        <a-row>
            <a-col cols='12'>
                <a-dynamic-command-filter
                    v-show='false'
                    ref='filter'
                    :key='getTelemanagementSystemDataAvailabilityIndicator.name'
                    filter-group='all'
                    exhibition-mode='sidebar'
                    :_fab='false'
                    class='pivot-filter'
                    :_command='getTelemanagementSystemDataAvailabilityIndicator'
                    @updateValues='updateDashData'
                />
            </a-col>
            <a-col cols='12' align='center' justify='center'>
                <span style='font-size:50px' v-text='data + "%"' />
            </a-col>
        </a-row>
    </a-container>
</template>

<script>
    import consts from './services/consts';
    import { GetTelemanagementSystemDataAvailabilityIndicator } from './services/request';

    export default {
        name: 'TelemanagementSystemDataAvailabilityIndicator',
        props: {
            /**
             * Command filter values sent from the Dashboard
             * @type {Object}
             * @default {}
             */
            _dashdata: {
                type: Object,
                default: () => ({
                    filters: [],
                }),
            },
        },
        data: function () {
            return {
                dashdata: this._dashdata,
                data: '0',

                consts: consts,
            };
        },
        computed: {
            getTelemanagementSystemDataAvailabilityIndicator: function () {
                const command = new GetTelemanagementSystemDataAvailabilityIndicator();
                command.initializeLoading = false;
                return command;
            },
        },
        watch: {
            dashdata: {
                deep: true,
                handler: function (value) {
                    this.$emit('update-dashdata', value);
                },
            },
        },
        mounted: function () {
            this.load();
        },
        methods: {
            load: async function () {
                if (this.dashdata.filters.length === 0) {
                    return;
                }
                this.$util.loadCommandFilter(this.getTelemanagementSystemDataAvailabilityIndicator, this.dashdata.filters);
                this.$refs.filter.refresh(true);
            },

            updateDashData: function (items) {
                this.dashdata.filters = this.$util.getFiltersByCommand(this.getTelemanagementSystemDataAvailabilityIndicator);
                this.loadData(items);
            },

            loadData: async function (items) {
                try {
                    this.data = parseFloat(items[0]).toFixed(2);
                } catch (reject) {
                }
            },
        },
    };
</script>
