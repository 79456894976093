const paths = [
    {
        path: '/equipments-vehicles-listing',
        name: 'EquipmentsVehiclesListing',
        title: 'EQUIPMENT_VEHICLES_LISTING',
        icon: 'mdi-map-marker-multiple',
        menu: 'fleet',
        favCategory: 'fleet',
        component: () => import(/* webpackChunkName: "fleet" */ /* webpackMode: "lazy" */ '@/pages/fleet/EquipmentsVehiclesListing.vue'),
    },
    {
        path: '/equipment-vehicle-wizard',
        name: 'EquipmentVehicleWizard',
        title: 'NEW_VEHICLE_EQUIPMENT',
        component: () => import(/* webpackChunkName: "fleet" */ /* webpackMode: "lazy" */ '@/pages/fleet/EquipmentVehicleWizard.vue'),
    },
    {
        path: '/equipments-vehicles-menu',
        name: 'EquipmentsVehiclesMenu',
        title: 'MENU',
        component: () => import(/* webpackChunkName: "fleet" */ /* webpackMode: "lazy" */ '@/pages/fleet/EquipmentsVehiclesMenu.vue'),
    },
    {
        path: '/equipments-vehicles-operator',
        name: 'EquipmentsVehiclesOperator',
        title: 'OPERATOR',
        component: () => import(/* webpackChunkName: "fleet" */ /* webpackMode: "lazy" */ '@/pages/fleet/EquipmentsVehiclesOperator.vue'),
    },
    {
        path: '/equipments-vehicles-refuelling',
        name: 'EquipmentsVehiclesRefuelling',
        title: 'REFUELLING_REGISTRY',
        icon: 'mdi-gas-station',
        menu: 'fleet',
        favCategory: 'fleet',
        component: () => import(/* webpackChunkName: "fleet" */ /* webpackMode: "lazy" */ '@/pages/fleet/EquipmentsVehiclesRefuelling.vue'),
    },
    {
        path: '/equipments-vehicles-obligations',
        name: 'EquipmentsVehiclesObligations',
        title: 'OBLIGATIONS',
        component: () => import(/* webpackChunkName: "fleet" */ /* webpackMode: "lazy" */ '@/pages/fleet/EquipmentsVehiclesObligations.vue'),
    },
    {
        path: '/equipments-vehicles-attributes',
        name: 'EquipmentsVehiclesAttributes',
        title: 'ATTRIBUTES',
        component: () => import(/* webpackChunkName: "fleet" */ /* webpackMode: "lazy" */ '@/pages/fleet/EquipmentsVehiclesAttributes.vue'),
    },
    {
        path: '/equipment-vehicles-refuelling-wizard',
        name: 'EquipmentsVehiclesRefuellingWizard',
        title: 'NEW_REFUELLING',
        component: () => import(/* webpackChunkName: "fleet" */ /* webpackMode: "lazy" */ '@/pages/fleet/EquipmentsVehiclesRefuellingWizard.vue'),
    },
    {
        path: '/equipment-vehicles-operator-change',
        name: 'EquipmentsVehiclesOperatorChange',
        title: 'CHANGE_OPERATOR',
        component: () => import(/* webpackChunkName: "fleet" */ /* webpackMode: "lazy" */ '@/pages/fleet/EquipmentsVehiclesOperatorChange.vue'),
    },
    {
        path: '/equipment-vehicles-usage-report',
        name: 'EquipmentVehiclesUsageReport',
        title: 'VEHICLES_USAGE_REPORT',
        icon: 'mdi-car-estate',
        menu: 'fleet',
        favCategory: 'fleet',
        component: () => import(/* webpackChunkName: "fleet" */ /* webpackMode: "lazy" */ '@/pages/fleet/EquipmentVehiclesUsageReport.vue'),
    },
    {
        path: '/equipments-vehicles-teams',
        name: 'EquipmentsVehiclesTeams',
        title: 'TEAMS',
        component: () => import(/* webpackChunkName: "fleet" */ /* webpackMode: "lazy" */ '@/pages/fleet/EquipmentsVehiclesTeams.vue'),
    },
    {
        path: '/equipments-vehicles-edit-odometers',
        name: 'EquipmentsVehiclesEditOdometers',
        title: 'EDIT_ODOMETERS',
        component: () => import(/* webpackChunkName: "fleet" */ /* webpackMode: "lazy" */ '@/pages/fleet/EquipmentsVehiclesEditOdometers.vue'),
    },
    {
        path: '/equipments-vehicles-tracker',
        name: 'EquipmentsVehiclesTracker',
        title: 'TRACKER',
        component: () => import(/* webpackChunkName: "fleet" */ /* webpackMode: "lazy" */ '@/pages/fleet/EquipmentsVehiclesTracker.vue'),
    },
    {
        path: '/equipments-vehicles-tracker-link',
        name: 'EquipmentsVehiclesTrackerLink',
        title: 'TRACKER',
        component: () => import(/* webpackChunkName: "fleet" */ /* webpackMode: "lazy" */ '@/pages/fleet/EquipmentsVehiclesTrackerLink.vue'),
    },
];

export default paths;
