export const OP_SESSION_KEYS = {
    ALL_ISSUES_SESSION_NAME: 'ISSUES',
    ISSUE_PENDING_SESSION_NAME: 'PENDING_ISSUES',
    ISSUE_TO_AUTHORIZE_SESSION_NAME: 'ISSUE_TO_AUTHORIZE',
    ISSUES_PROJECT: 'PROJECT_ISSUES',
    ISSUES_MAINTENANCE_PREVENTIVE: 'MAINTENANCE_PREVENTIVE',
    ISSUE_PENDING_LIST_COMPONENT_KEY: 'ISSUE_PENDING_LIST_COMPONENT_KEY',
    ISSUE_TO_AUTHORIZE_LIST_COMPONENT_KEY: 'ISSUE_TO_AUTHORIZE_LIST_COMPONENT_KEY',
    ISSUES_PROJECT_LIST_COMPONENT_KEY: 'ISSUES_PROJECT_LIST_COMPONENT_KEY',
    ISSUES_MAINTENANCE_PREVENTIVE_COMPONENT_KEY: 'ISSUES_MAINTENANCE_PREVENTIVE_COMPONENT_KEY',
};
export const ISSUE_PANEL_MODES = {
    AUTHORIZE_ISSUE: 'AUTHORIZE_ISSUE',
    SELECT_ISSUES: 'SELECT_ISSUES',
    GENERATE_ORDER: 'GENERATE_ORDER',
    MAINTENANCE_PREVENTIVE: 'MAINTENANCE_PREVENTIVE',
};
export default {
    ISSUE_PANEL_MODES: ISSUE_PANEL_MODES,
    OP_SESSION_KEYS: OP_SESSION_KEYS,
    URL_ICON_MARKER_RED: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png',
    URL_ICON_MARKER_BLUE: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png',
    ASSET_COLOR: {
        RED: '#EF5549',
        BLUE: '#0033FF',
    },
};
export const PREVENTIVE_STATUS = {
    LATER_WITH_ISSUE: 'Atrasado, Ocorrencia Pendente',
    PENDING_WITH_ISSUE: 'Pendente, Ocorrencia Pendente',
};
export const VIEW_ISSUE_MAP_COLOR_BY = {
    ORIGIN: 0,
    PRIORITY: 1,
    DEADLINE: 2,
};
export const VIEW_ISSUE_MAP_COLOR_PROP = {
    0: 'COR_ORIGEM_OCORRENCIA',
    1: 'priorityColor',
    2: 'COR_PRAZO',
};
