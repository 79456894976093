<template>
    <a-card>
        <a-card-title :class='`justify-center py-2 ${_titleBackgroundColor} ${_titleColor ? `${_titleColor}--text` : ""}`'>
            {{_title}}
        </a-card-title>
        <a-divider />
        <a-row no-gutters>
            <a-col class='pa-0' cols='12' :order='_orderBottom ? 2 : 1'>
                <a-card-actions>
                    <a-row class='flex-nowrap' justify='center'>
                        <a-btn
                            default
                            class='ma-1'
                            color='primary'
                            :_tooltip='false'
                            @click='$emit("all-selected-to", true)'>
                            <a-icon color='white'>
                                fas fa-angle-double-up
                            </a-icon>
                        </a-btn>
                        <a-btn
                            default
                            class='ma-1'
                            color='primary'
                            :_tooltip='false'
                            @click='$emit("all-selected-up-down", true)'>
                            <a-icon color='white'>
                                fas fa-angle-up
                            </a-icon>
                        </a-btn>
                        <a-btn
                            default
                            class='ma-1'
                            color='primary'
                            :_tooltip='false'
                            @click='$emit("all-selected-up-down", false)'>
                            <a-icon color='white'>
                                fas fa-angle-down
                            </a-icon>
                        </a-btn>
                        <a-btn
                            default
                            class='ma-1'
                            color='primary'
                            :_tooltip='false'
                            @click='$emit("all-selected-to", false)'>
                            <a-icon color='white'>
                                fas fa-angle-double-down
                            </a-icon>
                        </a-btn>
                    </a-row>
                </a-card-actions>
            </a-col>
            <a-col class='pa-0' cols='12' :order='_orderBottom ? 1 : 2'>
                <a-card-text class='pa-0'>
                    <a-list dense class='py-0 overflow-y-auto' :height='_heightListItem'>
                        <a-list-item-group v-model='value' :multiple='_multiple' :color='_color'>
                            <a-list-item v-for='(item, index) in _items' :key='index'>
                                <slot name='item' :item='item' />
                            </a-list-item>
                        </a-list-item-group>
                    </a-list>
                </a-card-text>
            </a-col>
        </a-row>
    </a-card>
</template>

<script>
    export default {
        name: 'ACardListItem',
        props: {
            /**
             * Card title
             * @type {String}
             * @default {''}
             */
            _title: {
                type: String,
                require: true,
                default: '',
            },
            /**
             * Card title background color
             * @type {String}
             * @default {'tertiary'}
             */
            _titleBackgroundColor: {
                type: String,
                require: true,
                default: 'tertiary',
            },
            /**
             * Card title color
             * @type {String}
             * @default {null}
             */
            _titleColor: {
                type: String,
                default: null,
            },
            /**
             * List height
             * @type {String | Number}
             * @default {'auto'}
             */
            _heightListItem: {
                type: [String, Number],
                require: true,
                default: 'auto',
            },
            /**
             * List provider
             * @type {Array}
             * @default {[]}
             */
            _items: {
                type: Array,
                require: true,
                default: () => [],
            },
            /**
             * Items selected
             * @type {Array}
             * @default {[]}
             */
            _value: {
                type: Array,
                require: true,
                default: () => [],
            },
            /**
             * Key of item object
             * @type {String}
             * @default {'id'}
             */
            _itemKey: {
                type: String,
                require: true,
                default: 'id',
            },
            /**
             * Multiple selection
             * @type {Boolean}
             * @default {false}
             */
            _multiple: {
                type: Boolean,
                require: true,
                default: false,
            },
            /**
             * Selection color
             * @type {String}
             * @default {'primary'}
             */
            _color: {
                type: String,
                default: 'primary',
            },
            /**
             * Position of order control
             * @type {Boolean}
             * @default {false}
             */
            _orderBottom: {
                type: Boolean,
                require: true,
                default: false,
            },
        },
        model: {
            prop: '_value',
        },
        data: function () {
            return {
                value: this._value,
            };
        },
        watch: {
            _value: function (value) {
                this.value = value;
            },
            value: function (value) {
                this.$emit('input', value);
            },
        },
    };
</script>
