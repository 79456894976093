import i18n from '@/i18n';

export default function projectStatusWithAll (provider) {
    return new Promise((resolve) => {
        const resultProvider = [];
        resultProvider.push({ ID_STATUS_AGENDAMENTO: -1, DESC_STATUS_AGENDAMENTO: i18n.t('ALL') });

        provider.forEach((item) => {
            resultProvider.push({ ID_STATUS_AGENDAMENTO: parseInt(item.ID_STATUS_AGENDAMENTO, 10), DESC_STATUS_AGENDAMENTO: item.DESC_STATUS_AGENDAMENTO });
        });
        resolve(resultProvider);
    });
}
