export default [
    {
        path: '/activity-route-type-list',
        title: 'ACTIVITY_ROUTE_TYPE',
        name: 'ActivityRouteTypeList',
        icon: 'fa fa-route',
        menu: 'analysis',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "activityRouteType" */ /* webpackMode: "lazy" */ '@/pages/activityRoute/activityRouteType/ActivityRouteTypeList.vue'),
    },
    {
        path: '/activity-route-type',
        title: 'ACTIVITY_ROUTE_TYPE',
        name: 'ActivityRouteType',
        icon: 'fa fa-route',
        component: () => import(/* webpackChunkName: "activityRouteType" */ /* webpackMode: "lazy" */ '@/pages/activityRoute/activityRouteType/ActivityRouteType.vue'),
    },
    {
        path: '/activity-route-type-entity-dialog',
        name: 'ActivityRouteTypeEntityDialog',
        component: () => import(/* webpackChunkName: "activityRouteType" */ /* webpackMode: "lazy" */ '@/pages/activityRoute/activityRouteType/ActivityRouteTypeEntityDialog.vue'),
    },
    {
        path: '/activity-route-type-sample-config-dialog',
        name: 'ActivityRouteTypeSampleConfigDialog',
        component: () => import(/* webpackChunkName: "activityRouteType" */ /* webpackMode: "lazy" */ '@/pages/activityRoute/activityRouteType/ActivityRouteTypeSampleConfigDialog.vue'),
    },
];
