const paths = [
    {
        path: '/material-request-list',
        name: 'MaterialRequestList',
        icon: 'fas fa-clipboard',
        menu: 'stock',
        title: 'MATERIAL_REQUEST_LIST',
        component: () => import(/* webpackChunkName: "MaterialRequestList" */ /* webpackMode: "lazy" */ '@/pages/stock/materialRequestList/MaterialRequestList.vue'),
    },
];
export default paths;
