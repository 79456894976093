const paths = [
    {
        path: '/complainant-reregistration-list',
        title: 'COMPLAINANT_REREGISTRATION',
        name: 'ComplainantReregistrationList',
        icon: 'fa fa-briefcase',
        menu: 'ticket',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "complaint" */ /* webpackMode: "lazy" */ '@/pages/complaint/ComplainantReregistrationList.vue'),
    },
];

export default paths;
