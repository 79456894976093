const paths = [
    {
        path: '/withdraw-worksite-materials',
        name: 'WithdrawWorksiteMaterials',
        title: 'WITHDRAW_WORKSITE_MATERIALS',
        icon: 'mdi-file-document',
        menu: 'asset',
        categories: [
          'reports',
        ],
        component: () => import(/* webpackChunkName: "withdrawWorksiteMaterials" */ /* webpackMode: "lazy" */ '@/pages/withdrawWorksiteMaterials/withdrawMaterialsWorksite.vue'),
    },
    {
        path: '/withdraw-materials-destine',
        name: 'WithdrawMaterialsDestine',
        title: 'WITHDRAW_MATERIALS_DESTINE',
        icon: 'mdi-file-document',
        menu: 'asset',
        component: () => import(/* webpackChunkName: "withdrawWorksiteMaterials" */ /* webpackMode: "lazy" */ '@/pages/withdrawWorksiteMaterials/withdrawMaterialsDestine.vue'),
    },
    {
        path: '/withdraw-materials-transfer-stock-center',
        name: 'WithdrawMaterialsTransferStockCenter',
        title: 'WITHDRAW_MATERIALS_TRANSFER',
        component: () => import(/* webpackChunkName: "withdrawWorksiteMaterials" */ /* webpackMode: "lazy" */ '@/pages/withdrawWorksiteMaterials/withdrawMaterialsTransferStockCenter.vue'),
    },
];

export default paths;
