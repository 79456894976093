const paths = [
    {
        path: '/tariff-plan-flag-list',
        name: 'TariffPlanFlagList',
        title: 'TARIFF_PLAN_FLAG',
        component: () => import(/* webpackChunkName: "TariffPlanFlag" */ /* webpackMode: "lazy" */ '@/pages/energy/tariffPlanFlag/TariffPlanFlagList.vue'),
    },
    {
        path: '/tariff-plan-flag-details',
        name: 'TariffPlanFlagDetails',
        title: 'TARIFF_PLAN_FLAG',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "TariffPlanFlag" */ /* webpackMode: "lazy" */ '@/pages/energy/tariffPlanFlag/TariffPlanFlagDetails.vue'),
    },
];

export default paths;
