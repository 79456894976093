const paths = [
    {
        path: '/worksite-type-list',
        name: 'WorksiteTypeList',
        title: 'WORKSITE_TYPES',
        icon: 'fa fa-briefcase',
        menu: 'maintenance',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "worksiteType" */ /* webpackMode: "lazy" */ '@/pages/worksiteType/WorksiteTypeList.vue'),
    },
    {
        path: '/worksite-type-general',
        name: 'WorksiteTypeGeneral',
        title: 'WORKSITE_TYPE_GENERAL',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "worksiteType" */ /* webpackMode: "lazy" */ '@/pages/worksiteType/WorksiteTypeGeneral.vue'),
    },
];

export default paths;
