const date = {
    getToday: function () {
        const today = new Date();
        return today;
    },
    getYesterday: function () {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);

        return yesterday;
    },
    getFirstDayOfCurrentMonth: function () {
        const today = new Date();
        const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);

        return firstDayOfCurrentMonth;
    },
    getLastDayOfCurrentMonth: function () {
        const today = new Date();
        const lastDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

        return lastDayOfCurrentMonth;
    },
    getFirstDayOfPreviousMonth: function () {
        const today = new Date();
        const firstDayOfPreviousMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);

        return firstDayOfPreviousMonth;
    },
    getLastDayOfPreviousMonth: function () {
        const today = new Date();
        const lastDayOfPreviousMonth = new Date(today.getFullYear(), today.getMonth(), 0);

        return lastDayOfPreviousMonth;
    },
    getFirstDayOfCurrentTrimester: function () {
        const today = new Date();
        const currentMonth = today.getMonth() + 1; // Add 1 because getMonth() returns 0-indexed values
        let firstDay;

        // Determine the first date of the current trimester
        if (currentMonth <= 3) {
            firstDay = new Date(today.getFullYear(), 0, 1); // January 1st of the current year
        } else if (currentMonth <= 6) {
            firstDay = new Date(today.getFullYear(), 3, 1); // April 1st of the current year
        } else if (currentMonth <= 9) {
            firstDay = new Date(today.getFullYear(), 6, 1); // July 1st of the current year
        } else {
            firstDay = new Date(today.getFullYear(), 9, 1); // October 1st of the current year
        }

        return firstDay;
    },
    getLastDayOfCurrentTrimester: function () {
        const today = new Date();
        const currentMonth = today.getMonth() + 1; // Add 1 because getMonth() returns 0-indexed values
        let lastDay;

        // Determine the last date of the current trimester
        if (currentMonth <= 3) {
            lastDay = new Date(today.getFullYear(), 2, 31); // March 31th of the current year
        } else if (currentMonth <= 6) {
            lastDay = new Date(today.getFullYear(), 5, 30); // June 30st of the current year
        } else if (currentMonth <= 9) {
            lastDay = new Date(today.getFullYear(), 8, 30); // September 30st of the current year
        } else {
            lastDay = new Date(today.getFullYear(), 11, 31);// December 31st of the current year
        }

        return lastDay;
    },
    getFirstDayOfPreviousTrimester: function () {
        const today = new Date();

        const currentMonth = today.getMonth() + 1; // Add 1 because getMonth() returns 0-indexed values
        const currentYear = today.getFullYear();
        let firstDay;

        // Determine the first date of the previous trimester
        if (currentMonth <= 3) {
            firstDay = new Date(currentYear - 1, 9, 1); // October 1st of the previous year
        } else if (currentMonth <= 6) {
            firstDay = new Date(currentYear, 0, 1);// January 1st of the current year
        } else if (currentMonth <= 9) {
            firstDay = new Date(currentYear, 3, 1);// April 1st of the current year
        } else {
            firstDay = new Date(currentYear, 6, 1);// July 1st of the current year
        }

        return firstDay;
    },
    getLastDayOfPreviousTrimester: function () {
        const today = new Date();

        const currentMonth = today.getMonth() + 1; // Add 1 because getMonth() returns 0-indexed values
        const currentYear = today.getFullYear();
        let lastDay;

        // Determine the first and last date of the previous trimester
        if (currentMonth <= 3) {
            lastDay = new Date(currentYear - 1, 12, 0); // December 31st of previous year
        } else if (currentMonth <= 6) {
            lastDay = new Date(currentYear, 3, 0); // March 31th of the current year
        } else if (currentMonth <= 9) {
            lastDay = new Date(currentYear, 6, 0); // June 30st of the current year
        } else {
            lastDay = new Date(currentYear, 9, 0); // September 30st of the current year
        }

        return lastDay;
    },
    getLastXDays: function (lastXDays) {
        const firstDay = new Date();
        firstDay.setDate(firstDay.getDate() - lastXDays);

        return firstDay;
    },
};

export default date;
