const paths = [
  {
      path: '/command-execution-log',
      title: 'COMMAND_EXECUTION_LOG',
      name: 'ExecutedCommandsLog',
      icon: 'fa fa-history',
      menu: 'analysis',
      component: () => import(/* webpackChunkName: "executedCommandsLog" */ /* webpackMode: "lazy" */ '@/pages/executedCommandsLog/ExecutedCommandsLog.vue'),
  },
];

export default paths;
