const paths = [
    {
        path: '/availability-monitor',
        name: 'AvailabilityMonitor',
        menu: 'ticket',
        title: 'AVAILABILITY_MONITOR',
        component: () => import(/* webpackChunkName: "availability" */ /* webpackMode: "lazy" */ '@/pages/ticket/availabilityMonitor/AvailabilityMonitor.vue'),

    },
];
export default paths;
