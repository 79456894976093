import Vue from 'vue';
import i18n from '@/i18n';
import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
const { $autocomplete, $util, } = Vue.prototype;
export class GetAssetMaintenanceMonitorData extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarDadosAtendimentoPSMonitor',
            type: 'SEARCH',
            node: 'MONITOR',
            fields: {
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('WORKSITE'),
                        entity: $autocomplete.worksite,
                        'entity-params': { ATIVO: 1 },
                        multiple: true,
                    },
                }),
                startDate: new Field({
                    name: 'DATA_INICIO',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('INITIAL_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                endDate: new Field({
                    name: 'DATA_FIM',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('ENDING_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                cityId: new Field({
                    name: 'ID_MUNICIPIO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('CITY'),
                        entity: $autocomplete.cities,
                        'entity-params': { SOMENTE_ATIVOS: 1 },
                        'require-params': ['ID_PARQUE_SERVICO'],
                        clearable: true,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.cityId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        },
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.cityId.props.disabled = !fields.worksiteId.value;
                            },
                        },
                    ],
                }),
                regionId: new Field({
                    name: 'ID_REGIAO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('REGION'),
                        entity: $autocomplete.region,
                        'entity-params': {},
                        'require-params': ['ID_PARQUE_SERVICO'],
                        clearable: true,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.regionId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        },
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.regionId.props.disabled = !fields.worksiteId.value;
                            },
                        },
                    ],
                }),
                maintenanceReasonId: new Field({
                    name: 'ID_MOTIVO_ATENDIMENTO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('REASON'),
                        entity: $autocomplete.assitanceMotive,
                        'entity-params': {},
                        'require-params': ['ID_PARQUE_SERVICO'],
                        clearable: true,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.maintenanceReasonId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        },
                    ],
                }),
                userCompanyId: new Field({
                    name: 'ID_EMPRESA_USUARIA',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('USER_COMPANY'),
                        entity: $autocomplete.userCompany,
                    },
                }),
                locationGroupingId: new Field({
                    name: 'ID_AGRUPAMENTO_LOCALIZACAO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('ALLOWED_LOCATIONS'),
                        entity: $autocomplete.locationGrouping,
                    },
                }),
                version: new Field({
                    name: 'VERSAO',
                    value: 2,
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetMaintenanceRegions extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarRegioesAtendimento',
            type: 'SEARCH',
            node: 'REGIOES.REGIAO',
            fields: {
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('WORKSITE'),
                        entity: $autocomplete.worksite,
                        'entity-params': { ATIVO: 1 },
                    },
                }),
                startDate: new Field({
                    name: 'DATA_INICIO',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('INITIAL_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                endDate: new Field({
                    name: 'DATA_FIM',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('ENDING_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                cityId: new Field({
                    name: 'ID_MUNICIPIO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('CITY'),
                        entity: $autocomplete.cities,
                        'entity-params': { SOMENTE_ATIVOS: 1 },
                        'require-params': ['ID_PARQUE_SERVICO'],
                        clearable: true,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.cityId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        },
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.cityId.props.disabled = !fields.worksiteId.value;
                            },
                        },
                    ],
                }),
                maintenanceReasonId: new Field({
                    name: 'ID_MOTIVO_ATENDIMENTO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('REASON'),
                        entity: $autocomplete.assitanceMotive,
                        'entity-params': {},
                        'require-params': ['ID_PARQUE_SERVICO'],
                        clearable: true,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.maintenanceReasonId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        },
                    ],
                }),
                userCompanyId: new Field({
                    name: 'ID_EMPRESA_USUARIA',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('USER_COMPANY'),
                        entity: $autocomplete.userCompany,
                    },
                }),
                locationGroupingId: new Field({
                    name: 'ID_AGRUPAMENTO_LOCALIZACAO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('ALLOWED_LOCATIONS'),
                        entity: $autocomplete.locationGrouping,
                    },
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetMaintenanceDistricts extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarBairrosAtendimento',
            type: 'SEARCH',
            node: 'BAIRROS.BAIRRO',
            fields: {
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                }),
                regionId: new Field({
                    name: 'ID_REGIAO',
                }),
                startDate: new Field({
                    name: 'DATA_INICIO',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('INITIAL_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                endDate: new Field({
                    name: 'DATA_FIM',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('ENDING_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                cityId: new Field({
                    name: 'ID_MUNICIPIO',
                }),
                maintenanceReasonId: new Field({
                    name: 'ID_MOTIVO_ATENDIMENTO',
                }),
                userCompanyId: new Field({
                    name: 'ID_EMPRESA_USUARIA',
                }),
                locationGroupingId: new Field({
                    name: 'ID_AGRUPAMENTO_LOCALIZACAO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
