const paths = [
    {
        path: '/project-location-type-list',
        name: 'ProjectLocationTypeList',
        title: 'PROJECT_LOCATION_TYPES',
        icon: 'widgets',
        menu: 'construction',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "projectLocationType" */ /* webpackMode: "lazy" */ '@/pages/project/projectLocationType/ProjectLocationTypeList'),
    },
    {
        path: '/project-location-type',
        name: 'ProjectLocationType',
        title: 'PROJECT_LOCATION_TYPES',
        favCategory: 'construction',
        component: () => import(/* webpackChunkName: "projectLocationType" */ /* webpackMode: "lazy" */ '@/pages/project/projectLocationType/ProjectLocationType'),
    },
    {
        path: '/project-location-type-general',
        name: 'ProjectLocationTypeGeneral',
        title: 'PROJECT_LOCATION_TYPE_GENERAL',
        component: () => import(/* webpackChunkName: "projectLocationType" */ /* webpackMode: "lazy" */ '@/pages/project/projectLocationType/ProjectLocationTypeGeneral'),
    },
    {
        path: '/project-location-type-layers-list',
        name: 'ProjectLocationTypeLayersList',
        title: 'PROJECT_LOCATION_TYPE_LAYERS_LIST',
        component: () => import(/* webpackChunkName: "projectLocationType" */ /* webpackMode: "lazy" */ '@/pages/project/projectLocationType/ProjectLocationTypeLayersList'),
    },
    {
        path: '/project-location-type-layer-general',
        name: 'ProjectLocationTypeLayersGeneral',
        title: 'PROJECT_LOCATION_TYPE_LAYERS_General',
        component: () => import(/* webpackChunkName: "projectLocationType" */ /* webpackMode: "lazy" */ '@/pages/project/projectLocationType/ProjectLocationTypeLayersGeneral'),
    },
];

export default paths;
