import { CallCommand } from '@/util/dynamicForm/dynamic-form';
import { parseSuccessResponse, showErrorsResponse } from '@/pages/remoteManagement/services/utils';
export class GetAuthInformation extends CallCommand {
    constructor() {
        super({
            name: 'get_auth_information',
            type: 'NONE',
            node: 'RESPONSE',
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(config, debug) {
        return super.callCommand({}, { silent: true, ...config }, debug);
    }
}
