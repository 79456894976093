import Vue from 'vue';
import { GetHelp } from '@/pages/help/services/request.ts';

export default {
    state: {
        helpOptions: {},
        showHelpActions: false,
    },
    mutations: {
        SET_HELP_OPTIONS: function (state, helpOptions) {
            state.helpOptions = helpOptions;
        },
        SET_SHOW_HELP_ACTIONS: function (state, showHelpActions) {
            state.showHelpActions = showHelpActions;
        },
    },
    actions: {
        loadHelpOptions: async function ({ commit, getters }) {
            const resolve = await new GetHelp().callCommand({ videoLanguage: getters.locale });
            const helpList = Vue.prototype.$_aura.getArray(resolve.RAIZ.AJUDAS.AJUDA);
            const showHelpActions = Vue.prototype.$lodash.get(resolve.RAIZ, 'MOSTRAR_ACOES_AJUDA');

            const helpOptions = {};
            let screenName;
            helpList.forEach(helpOption => {
                screenName = helpOption.NOME_TELA;
                if (helpOptions[screenName] === undefined) {
                    helpOptions[screenName] = [];
                }
                helpOptions[screenName].push(helpOption);
            });
            commit('SET_HELP_OPTIONS', helpOptions);
            commit('SET_SHOW_HELP_ACTIONS', showHelpActions);
        },
    },
    getters: {
        getHelpOptions: state => state.helpOptions,
        getHelpList: state => state.helpList,
        getShowHelpActions: state => state.showHelpActions,
    },
};
