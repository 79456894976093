<template>
    <a-container ref='container' v-resize='onResize' class='container' style='height: 80vh'>
        <a-row>
            <a-spacer />
            <a-col cols='1'>
                <a-dynamic-command-filter
                    ref='filter'
                    :key='getCashFlow.name'
                    filter-group='all'
                    exhibition-mode='sidebar'
                    :_fab='false'
                    class='pivot-filter'
                    :_command='getCashFlow'
                    @updateValues='updateData'
                />
            </a-col>
        </a-row>
        <chart
            ref='chart'
            :_options='chartOptions'
            style='height: 95%;'
        />
    </a-container>
</template>

<script>
    import { GetCashFlow } from './services/request';

    export default {
        name: 'CashFlowChart',
        props: {
            /**
             * Prop to show if page is currently active
             * @type {Boolean}
             * @default {false}
            */
            _activePage: {
                type: Boolean,
                default: false,
            },
            /**
             * Prop to sync filters between data-tables
             * @type {Array}
             * @default {[]}
             */
            _filters: {
                type: Array,
                default: () => ([]),
            },
        },
        data: function () {
            return {
                categoryData: [],
                reportData: [],
                filters: [],
                schedules: {},
                // Chart instance, used for the on() event
                chart: undefined,

                // Default chart before it's loaded with data
                chartOptions: {
                    xAxis: {
                        type: 'category',
                        name: this.$t('DATE'),
                    },
                    yAxis: {
                        type: 'value',
                        name: this.$t('COSTS'),
                    },
                },

                currentPeriodicity: undefined,
            };
        },
        computed: {
            getCashFlow: function () {
                const command = new GetCashFlow();
                command.values = {
                    searchType: 3,
                    performed: 0,
                    withoutAccount: 1,
                    selectionMonth: undefined,
                    endDate: undefined,
                };
                return command;
            },
        },
        watch: {
            _activePage: {
                handler: function () {
                    this.onResize();
                },
            },
            _filters: {
                immediate: true,
                handler: function (val) {
                    if (val) {
                        const allowedFilters = ['DATA_FIM', 'CONTAS_FINANCEIRAS'];
                        val = val.filter(elem => allowedFilters.includes(elem.key));
                        this.$util.loadCommandFilter(this.getCashFlow, val);
                    }
                },
            },
            filters: {
                deep: true,
                handler: function (val) {
                    this.$emit('update:_filters', val);
                },
            },
        },
        mounted: function () {
            this.load();
        },
        methods: {
            onResize: function () {
                this.$nextTick(this.$refs.chart.resize);
            },
            load: async function () {
                this.onResize();
                this.chart = this.$refs.chart.getChart();
                await this.loadSchedules();
                this.formatToChart(this.schedules);
                this.loadChart();
                this.onResize();
                this.$refs.filter.refresh(true);
            },
            loadSchedules: async function () {
                const result = await this.getCashFlow.callCommand();
                const schedules = this.$util.getArrayByNode(result, this.getCashFlow.node);
                this.schedules = schedules;
            },
            loadChart: function () {
                this.$refs.chart.reset();
                this.currentPeriodicity = this.getCashFlow.fields.searchType.value;
                this.setChartOption();
                this.addTooltip();
                this.addSerie();
                this.chart.on('click', { seriesIndex: 0 }, this.openFinancialSchedule);
            },
            formatToChart: function (data) {
                this.reportData = [];
                this.categoryData = [];
                Object.keys(data).forEach((i) => {
                    const item = data[i];
                    item.name = item.DATA_PAGA;
                    item.value = [item.OPEN, item.CLOSE, item.LOW, item.HIGH];

                    this.reportData[i] = item;
                    this.categoryData[i] = item.name;
                });
            },
            setChartOption: function () {
                const chartOptions = {
                    maintainAspectRatio: false,
                    grid: {
                        containLabel: true,
                        bottom: '20',
                    },
                    xAxis: {
                        type: 'category',
                        name: this.$t('DATE'),
                        data: this.categoryData,
                        axisLabel: {
                            show: true,
                            position: 'bottom',
                            formatter: '{value}',
                            color: '#000000',
                            padding: [30, 31, 32, 33],
                        },
                    },
                    yAxis: {
                        type: 'value',
                        name: this.$t('OPEN_ISSUES'),
                        axisLabel: {
                            formatter: '{value}',
                        },
                    },
                };

                this.$refs.chart.setOption(chartOptions);
            },
            addSerie: function () {
                this.$refs.chart.addSerie({
                    id: 1,
                    type: 'candlestick',
                    data: this.reportData,
                    itemStyle: {
                        color: '#314656', // up color
                        color0: '#c23531', // down color
                        borderColor: '#314656', // up border color
                        borderColor0: '#c23531', // down border color
                    },
                    animation: true,
                    left: 'right',
                    top: 'middle',
                });
            },
            addTooltip: function () {
                const tooltip = {
                    trigger: 'axis',
                    formatter: this.tooltipFormatter,
                    confine: true,
                    axisPointer: {
                        type: 'line',
                    },
                };
                this.$refs.chart.setTooltip(tooltip);
            },
            tooltipFormatter: function (params) {
                params = params[0];
                const date = params.data.DATA_PAGA;
                const opening = params.data.OPEN;
                const closing = params.data.CLOSE;
                const high = params.data.HIGH;
                const low = params.data.LOW;

                let label = `${(this.currentPeriodicity === 3 ? this.$t('DAY') : this.$t('MONTH'))}: ${date}<br />`;
                label += `${this.$t('OPENING')}: ${opening}<br />`;
                label += `${this.$t('CLOSING')}: ${closing}<br />`;
                label += `${this.$t('HIGH')}: ${high}<br />`;
                label += `${this.$t('LOW')}: ${low}<br />`;
                return label;
            },
            updateData: function (items) {
                this.loadData(items);
            },
            loadData: async function (items) {
                try {
                    this.formatToChart(items);
                    this.loadChart();
                } catch (reject) {
                }
            },
            openFinancialSchedule: async function (params) {
                if (params.data.DATA_MES) {
                    const command = new GetCashFlow();
                    command.values = {
                        searchType: 3,
                        performed: 0,
                        withoutAccount: 1,
                        selectionMonth: params.data.DATA_MES,
                    };

                    const result = await command.callCommand();
                    const schedules = this.$util.getArrayByNode(result, this.getCashFlow.node);
                    this.schedules = [];
                    this.schedules = schedules;
                    this.loadData(this.schedules);
                    return;
                }

                const filters = this.$util.getFiltersByCommand(this.getCashFlow);
                filters.find(f => f.key === 'DATA_FIM').value = params.data.DATA_PAGA;
                filters.find(f => f.key === 'SEM_CONTA').value = 1;
                filters.find(f => f.key === 'REALIZADAS').value = 0;
                filters.find(f => f.key === 'TIPO_CONSULTA').value = 2;
                filters.find(f => f.key === 'CONTAS_FINANCEIRAS').value = undefined;
                this.$util.openPage({ name: 'CashFlowScheduling' }, {
                    isDialog: true,
                    props: {
                        _filters: filters,
                    },
                });
            },
            getFilters: function () {
                this.filters = this.$util.getFiltersByCommand(this.getCashFlow);
            },
        },
    };
</script>
