const paths = [
  {
    path: '/area-list',
    name: 'AreaList',
    title: 'AREAS',
    icon: 'mdi-file-document',
    menu: 'analysis',
    component: () => import(/* webpackChunkName: "areas" */ /* webpackMode: "lazy" */ '@/pages/location/areas/AreaList.vue'),
  },
  {
    path: '/area-details',
    name: 'AreaDetails',
    title: 'AREA',
    favCategory: 'registration',
    component: () => import(/* webpackChunkName: "areas" */ /* webpackMode: "lazy" */ '@/pages/location/areas/AreaDetails.vue'),
  },
  {
      path: '/area-general',
      name: 'AreaGeneral',
      title: 'AREA',
      favCategory: 'registration',
      component: () => import(/* webpackChunkName: "areas" */ /* webpackMode: "lazy" */ '@/pages/location/areas/AreaGeneral.vue'),
  },
  {
      path: '/area-linked-public-place-list',
      name: 'AreaLinkedPublicPlaceList',
      title: 'PUBLIC_PLACE_LIST',
      favCategory: 'registration',
      component: () => import(/* webpackChunkName: "areas" */ /* webpackMode: "lazy" */ '@/pages/location/areas/AreaLinkedPublicPlaceList.vue'),
  },
  {
      path: '/maintenance-status-event-model-list',
      name: 'AreaPublicPlaceGeneral',
      title: 'PUBLIC_PLACE',
      favCategory: 'registration',
      component: () => import(/* webpackChunkName: "areas" */ /* webpackMode: "lazy" */ '@/pages/location/areas/AreaPublicPlaceGeneral.vue'),
  },
  {
      path: '/area_type_list',
      name: 'AreaTypeList',
      title: 'AREA_TYPE_LIST',
      menu: 'analysis',
      icon: 'widgets',
      categories: [
          'configurations',
      ],
      component: () => import(/* webpackChunkName: "areaType" */ /* webpackMode: "lazy" */ '@/pages/location/areas/AreaTypeList.vue'),
  },
  {
      path: '/area-type-general',
      name: 'AreaTypeGeneral',
      title: 'AREA_TYPE',
      favCategory: 'registration',
      component: () => import(/* webpackChunkName: "areaType" */ /* webpackMode: "lazy" */ '@/pages/location/areas/AreaTypeGeneral.vue'),
  },
];

export default paths;
