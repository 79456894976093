import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import { parseSuccessResponse, showErrorsResponse } from '../utils';
export class UpdateGeneralConfigsDefaults extends CallCommand {
    constructor() {
        super({
            name: 'post_general-configs-defaults',
            type: 'NONE',
            fields: {
                runin_log_interval_in_days: new Field({
                    name: 'runin_log_interval_in_days',
                }),
            },
            customRequestParser: function (params) {
                return {
                    BODY: JSON.stringify({
                        runin_log_interval_in_days: params.runin_log_interval_in_days,
                    }),
                };
            },
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        return response;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
