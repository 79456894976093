<script>
    export default {
        name: 'AIconCertificateFile',
        functional: true,
        render: function (createElement, { data, parent }) {
            delete data.attrs['certificate-file'];
            data.attrs.color = data.attrs.color || 'primary';
            const children = ['mdi-file-certificate'];
            if ((!data.scopedSlots || !data.scopedSlots['tooltip-body']) && !data.attrs['_tooltip-text']) {
                data.attrs['_tooltip-text'] = parent.$t('VIEW_CERTIFICATE_FILE');
            }
            return createElement('AIconDefault', data, children);
        },
    };
</script>
