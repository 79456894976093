const paths = [
    {
        path: '/maintenance-status-issue-type-link',
        name: 'MaintenanceStatusIssueTypeLink',
        title: 'MAINTENANCE_STATUS_ISSUE_TYPE_LINK',
        icon: 'fa fa-link',
        menu: 'maintenance',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "maintenanceStatusIssueTypeLink" */ /* webpackMode: "lazy" */ '@/pages/maintenanceStatusIssueTypeLink/MaintenanceStatusIssueTypeLink.vue'),
    },
];

export default paths;
