const paths = [
    {
        path: '/super-modules-list',
        title: 'SUPER_MODULES',
        name: 'SuperModulesList',
        icon: 'widgets',
        menu: 'construction',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "superModules" */ /* webpackMode: "lazy" */ '@/pages/superModules/SuperModulesList.vue'),
    },
    {
        path: '/super-modules',
        title: 'SUPER_MODULE',
        name: 'SuperModules',
        component: () => import(/* webpackChunkName: "superModules" */ /* webpackMode: "lazy" */ '@/pages/superModules/SuperModules.vue'),
    },
    {
        path: '/super-modules-general',
        name: 'SuperModulesGeneral',
        component: () => import(/* webpackChunkName: "superModules" */ /* webpackMode: "lazy" */ '@/pages/superModules/SuperModulesGeneral.vue'),
    },
    {
        path: '/super-modules-general-composition',
        name: 'SuperModulesGeneralComposition',
        component: () => import(/* webpackChunkName: "superModules" */ /* webpackMode: "lazy" */ '@/pages/superModules/SuperModulesGeneralComposition.vue'),
    },
    {
        path: '/super-modules-structure-pattern',
        name: 'SuperModulesStructurePattern',
        component: () => import(/* webpackChunkName: "superModules" */ /* webpackMode: "lazy" */ '@/pages/superModules/SuperModulesStructurePattern.vue'),
    },
];

export default paths;
