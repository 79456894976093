import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import { showErrorsResponse, parseSuccessResponse } from '@/pages/remoteManagement/services/utils';
export class GetDeviceConfigSchedules extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_device-config-schedule',
            type: 'NONE',
            fields: {
                filter: new Field({
                    name: 'BODY',
                    parser: (value) => `filter=${encodeURIComponent(JSON.stringify(value))}`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveAllDeviceConfigSchedule extends CallCommand {
    constructor() {
        super({
            name: 'post_comp_device-config-schedule_create-all',
            type: 'SAVE',
            fields: {
                configSchedules: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, debug) {
        return super.callCommand(param, { silent: true }, debug);
    }
}
export class SaveAllDeviceConfigScheduleWithUpdateContent extends CallCommand {
    constructor() {
        super({
            name: 'post_comp_device-config-schedule_create-all-with-content',
            type: 'SAVE',
            node: 'RESPONSE',
            fields: {
                configSchedules: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, debug) {
        return super.callCommand(param, { silent: true }, debug);
    }
}
export class DeleteDeviceConfigSchedule extends CallCommand {
    constructor() {
        super({
            name: 'delete_comp_device-config-schedule_0_1',
            type: 'DELETE',
            fields: {
                pathParams: new Field({
                    name: 'PATH_PARAMS',
                    value: {},
                    parser: (value) => `{ PARAM_0: ${value.deviceId}, PARAM_1: ${value.deviceConfigScheduleId} }`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
