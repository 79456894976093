const paths = [
    {
        path: '/call-center',
        name: 'CallCenter',
        title: 'CALL_CENTER',
        icon: 'mdi-message-alert-outline',
        menu: 'ticket',
        component: () => import(/* webpackChunkName: "callCenter" */ /* webpackMode: "lazy" */ '@/pages/callCenter/CallCenter.vue'),
    },
    {
        path: '/call-center-search-data',
        name: 'CallCenterSearchData',
        title: 'CALL_CENTER',
        component: () => import(/* webpackChunkName: "callCenter" */ /* webpackMode: "lazy" */ '@/pages/callCenter/CallCenterSearchData.vue'),
    },
];

export default paths;
