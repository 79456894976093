const components = {
    area: () => import('./area/AAutocompleteArea'),
    bank: () => import('./bank/AAutocompleteBank'),
    city: () => import('./city/AAutocompleteCity'),
    districtRegistration: () => import('./district/AAutocompleteDistrict'),
    cityRegistration: () => import('./locations/AAutocompleteCity'),
    publicPlace: () => import('./publicPlace/AAutocompletePublicPlace'),
    trafficViolation: () => import('./trafficViolation/AAutocompleteTrafficViolation'),
    userCompany: () => import('./userCompany/AAutocompleteUserCompany'),
    family: () => import('./family/AAutocompleteFamily'),
};

export default components;
