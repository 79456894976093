const paths = [
  {
    path: '/stock-integration',
    name: 'StockIntegration',
    title: 'STOCK_INTEGRATION',
    icon: 'widgets',
    menu: 'stock',
    categories: [
      'configurations',
    ],
    component: () => import(/* webpackChunkName: "stockIntegration" */ /* webpackMode: "lazy" */ '@/pages/stockIntegration/StockIntegration.vue'),
  },
];

export default paths;
