import Vue from 'vue';

const events = {
    global: null,
    login: 'showLogin',
    initializeOptions: function () {
        this.global = new Vue();
    },
    toggleLogin: function (value) {
        this.global.$emit(this.login, value);
    },
    updateRequests: function (value) {
        this.global.$emit('updateRequests', value);
    },
};

export default events;
