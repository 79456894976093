<script>
    export default {
        name: 'AIconAdd',
        functional: true,
        render: function (createElement, { data, parent }) {
            delete data.attrs.add;
            data.attrs.color = data.attrs.color || 'primary';
            
            if (data.attrs.small === undefined) {
                data.attrs.small = false;
            }

            const children = ['add'];
            
            if ((!data.scopedSlots || !data.scopedSlots['tooltip-body']) && !data.attrs['_tooltip-text']) {
                data.attrs['_tooltip-text'] = parent.$t('ADD');
            }
            return createElement('AIconDefault', data, children);
        },
    };
</script>
