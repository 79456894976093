const paths = [
    {
        path: '/deadline-windows-scheme-list',
        name: 'DeadlineWindowsSchemeList',
        title: 'DEADLINE_WINDOW_SCHEME',
        icon: 'widgets',
        menu: 'ticket',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "deadlineWindow" */ /* webpackMode: "lazy" */ '@/pages/deadlineWindow/DeadlineWindowsSchemeList.vue'),
    },
    {
        path: '/deadline_window_scheme_detail',
        title: 'DEADLINE_WINDOWS',
        name: 'DeadlineWindowSchemeDetail',
        component: () => import(/* webpackChunkName: "deadlineWindow" */ /* webpackMode: "lazy" */ '@/pages/deadlineWindow/DeadlineWindowSchemeDetail.vue'),
    },
    {
        path: '/deadline_window_type',
        title: 'DEADLINE_WINDOW_TYPE',
        name: 'DeadlineWindowType',
        component: () => import(/* webpackChunkName: "deadlineWindow" */ /* webpackMode: "lazy" */ '@/pages/deadlineWindow/DeadlineWindowType.vue'),
    },
];

export default paths;
