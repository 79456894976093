const paths = [
    {
        path: '/asset-life-span',
        name: 'Asset Life Span',
        title: 'ASSETS_LIFESPAN_REPORT',
        icon: 'fas fa-clipboard',
        menu: 'asset',
        categories: [
            'reports',
        ],
        component: () => import(/* webpackChunkName: "assetLifeSpan" */ /* webpackMode: "lazy" */ '@/pages/assetLifeSpan/AssetLifeSpan.vue'),
    },
    {
        path: '/asset-life-span-map',
        name: 'Asset Life Span Map',
        component: () => import(/* webpackChunkName: "assetLifeSpan" */ /* webpackMode: "lazy" */ '@/pages/assetLifeSpan/AssetLifeSpanMap.vue'),
    },
];

export default paths;
