export default [
    {
        path: '/issue-rejection-list',
        title: 'ISSUE_REJECTIONS',
        name: 'IssueRejectionList',
        icon: 'mdi-do-not-disturb',
        menu: 'ticket',
        component: () => import(/* webpackChunkName: "issueRejection" */ /* webpackMode: "lazy" */ '@/pages/issue/issueRejection/IssueRejectionList.vue'),
    },
];
