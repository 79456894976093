const paths = [
    {
        path: '/point-of-interest-list',
        title: 'POINTS_OF_INTEREST',
        name: 'PointOfInterestList',
        icon: 'fa fa-map-marker-alt',
        menu: 'asset',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "pointOfInterest" */ /* webpackMode: "lazy" */ '@/pages/pointOfInterest/PointOfInterestList.vue'),
    },
    {
        path: '/point-of-interest',
        title: 'POINT_OF_INTEREST',
        name: 'PointOfInterestDetails',
        component: () => import(/* webpackChunkName: "pointOfInterest" */ /* webpackMode: "lazy" */ '@/pages/pointOfInterest/PointOfInterestDetails.vue'),
    },
];

export default paths;
