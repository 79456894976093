const paths = [
    {
        path: '/project-issue-type-list',
        name: 'ProjectIssueTypeList',
        title: 'PROJECT_ISSUE_TYPES',
        icon: 'widgets',
        menu: 'construction',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "projectIssueType" */ /* webpackMode: "lazy" */ '@/pages/project/projectIssueType/ProjectIssueTypeList'),
    },
    {
        path: '/project-issue-type-general',
        name: 'ProjectIssueTypeGeneral',
        title: 'PROJECT_ISSUE_TYPE_GENERAL',
        favCategory: 'construction',
        component: () => import(/* webpackChunkName: "projectIssueType" */ /* webpackMode: "lazy" */ '@/pages/project/projectIssueType/ProjectIssueTypeGeneral'),
    },
];

export default paths;
