const paths = [
    {
        path: '/categorization-list',
        name: 'CategorizationList',
        title: 'CATEGORIZATIONS',
        icon: 'fas fa-cash-register',
        menu: 'financial',
        component: () => import(/* webpackChunkName: "categorization" */ /* webpackMode: "lazy" */ '@/pages/categorization/CategorizationList.vue'),
    },
    {
        path: '/categorization-invalidation',
        name: 'CategorizationInvalidation',
        title: 'INVALIDATE_CATEGORIZATION',
        component: () => import(/* webpackChunkName: "categorization" */ /* webpackMode: "lazy" */ '@/pages/categorization/CategorizationInvalidation.vue'),
    },
    {
        path: '/categorization-status-change',
        name: 'CategorizationStatusChange',
        title: 'CHOOSE_YOUR_ACTION',
        component: () => import(/* webpackChunkName: "categorization" */ /* webpackMode: "lazy" */ '@/pages/categorization/CategorizationStatusChange.vue'),
    },
    {
        path: '/categorization-wizard',
        name: 'CategorizationWizard',
        title: 'EDIT_CATEGORIZATION',
        component: () => import(/* webpackChunkName: "categorization" */ /* webpackMode: "lazy" */ '@/pages/categorization/CategorizationWizard.vue'),
    },
];

export default paths;
