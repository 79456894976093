<template>
    <a-container ref='container' v-resize='onResize' class='container' style='height: 100%;'>
        <a-dynamic-command-filter
            ref='filter'
            :key='getAssetMaintenanceMonitorData.name'
            :_sidebar-props='{ _hideActions: true }'
            :_loading.sync='loading'
            filter-group='all'
            exhibition-mode='sidebar'
            :_fab='false'
            class='pivot-filter'
            :_command='getAssetMaintenanceMonitorData'
            @updateValues='updateDashData'
        />
        <chart
            ref='chart'
            :_options='chartOptions'
            style='height: 100%;'
        />
    </a-container>
</template>

<script>
    import consts from './services/consts';
    import { GetAssetMaintenanceMonitorData } from './services/request';

    export default {
        name: 'MaintenanceByAssetTypeMonitor',
        props: {
            /**
             * Prop to show if page is currently active
             * @type {Boolean}
             * @default {false}
            */
            _activePage: {
                type: Boolean,
                default: false,
            },
            /**
             * Command filter values sent from the Dashboard
             * @type {Object}
             * @default {}
             */
            _dashdata: {
                type: Object,
                default: () => ({
                    filters: [],
                }),
            },
            /**
             * Parameter to determine the chart's height
             * @type {[String, Number]}
             * @default {undefined}
             */
            _chartHeight: {
                type: [String, Number],
                default: undefined,
            },
            /**
             * sync with parent loading
             * @type {Boolean}
             * @default {false}
             */
            _loading: {
                type: Boolean,
                default: false,
            },
            /**
             * sync with parent empty-data
             * @type {Boolean}
             * @default {false}
             */
            _emptyData: {
                type: Boolean,
                default: false,
            },
        },
        data: function () {
            return {
                dashdata: this._dashdata,
                reportData: {},

                chartOptions: {
                    maintainAspectRatio: false,
                    tooltip: {
                        trigger: 'item',
                        formatter: consts.DEFAULT_TOOLTIP,
                        confine: true,
                    },
                },

                consts: consts,
                chartHeight: this._chartHeight || consts.DEFAULT_HEIGHT,
            };
        },
        computed: {
            getAssetMaintenanceMonitorData: function () {
                const command = new GetAssetMaintenanceMonitorData();
                command.initializeLoading = false;
                command.node = 'MONITOR.TIPOS_PONTO.TIPO_PONTO';
                return command;
            },
            loading: {
                get: function () {
                    return this._loading;
                },
                set: function (value) {
                    this.$emit('update:_loading', value);
                },
            },
            emptyData: {
                get: function () {
                    return this._emptyData;
                },
                set: function (value) {
                    this.$emit('update:_emptyData', value);
                },
            },
        },
        watch: {
            dashdata: {
                deep: true,
                handler: function (value) {
                    this.$emit('update-dashdata', value);
                },
            },
            _chartHeight: {
                handler: function (value) {
                    this.chartHeight = value;
                    this.$nextTick(this.onResize);
                },
            },
            _activePage: {
                handler: function () {
                    this.$nextTick(this.onResize);
                },
            },
        },
        mounted: function () {
            this.load();
        },
        methods: {
            onResize: function () {
                this.$refs.chart.resize({ height: this.chartHeight });
            },

            load: async function () {
                this.$nextTick(this.onResize);

                if (this.dashdata.filters.length === 0) {
                    return;
                }
                this.$util.loadCommandFilter(this.getAssetMaintenanceMonitorData, this.dashdata.filters);
                this.$refs.filter.refresh(true);
            },

            updateDashData: function (items) {
                this.emptyData = !items.length;
                this.dashdata.filters = this.$util.getFiltersByCommand(this.getAssetMaintenanceMonitorData);
                this.loadData(items);
            },

            loadData: async function (items) {
                this.reportData = this.formatToChart(items);
                this.loadChart();
            },

            formatToChart: function (data) {
                Object.keys(data).forEach((i) => {
                    data[i].name = data[i].DESC_TIPO_PONTO_SERVICO;
                    data[i].value = data[i].TOTAL_TIPO_PONTO;
                });
                const formattedData = data;
                return formattedData;
            },

            loadChart: function () {
                this.$refs.chart.reset();
                this.$refs.chart.addSerie({
                    id: 1,
                    type: 'pie',
                    data: this.reportData,
                    animation: true,
                    left: 'center',
                    top: 'middle',
                    radius: consts.DEFAULT_RADIUS,
                    width: '100%',
                    height: '100%',
                    label: {
                        formatter: consts.DEFAULT_LABEL,
                    },
                });
            },
        },
    };
</script>
