import Vue from 'vue';
import i18n from '@/i18n';
import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
const { $autocomplete, $util, } = Vue.prototype;
export class GetAppliedMaterialCostPerContractGrouped extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarCustoMateriaisAplicadosPorContratoAgrupado',
            type: 'SEARCH',
            node: 'MATERIAIS_AGRUPADOS.MATERIAL_AGRUPADO',
            fields: {
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('WORKSITE'),
                        entity: $autocomplete.worksite,
                        'entity-params': { ATIVO: 1 },
                        clearable: false,
                    },
                }),
                searchMaterial: new Field({
                    name: 'BUSCA_MATERIAL',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('SEARCH'),
                    },
                }),
                startDate: new Field({
                    name: 'DATA_INICIAL',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('INITIAL_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                endDate: new Field({
                    name: 'DATA_FINAL',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('ENDING_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                clientId: new Field({
                    name: 'ID_CLIENTE',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('CLIENT'),
                        entity: $autocomplete.clients,
                        clearable: false,
                    },
                }),
                contractId: new Field({
                    name: 'ID_CONTRATO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('CONTRACT'),
                        entity: $autocomplete.contract,
                        'entity-params': { ATIVO: 1 },
                    },
                    dependsOn: [
                        {
                            fieldName: ['ID_CLIENTE'],
                            handler: (fields) => {
                                fields.contractId.props.disabled = !fields.clientId.value;
                                fields.contractId.props['entity-params'] = {
                                    ATIVO: 1,
                                    ID_CLIENTE: fields.clientId.value,
                                };
                            },
                        },
                    ],
                }),
                userCompanyId: new Field({
                    name: 'ID_EMPRESA_USUARIA',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('USER_COMPANY'),
                        entity: $autocomplete.userCompany,
                    },
                }),
                locationGroupingId: new Field({
                    name: 'ID_AGRUPAMENTO_LOCALIZACAO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('ALLOWED_LOCATIONS'),
                        entity: $autocomplete.locationGrouping,
                    },
                }),
                familyId: new Field({
                    name: 'ID_FAMILIA',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('FAMILY'),
                        entity: $autocomplete.families,
                        'entity-params': { ITEM_OBRA: 0 },
                        clearable: true,
                    },
                }),
                districtId: new Field({
                    name: 'ID_BAIRRO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('DISTRICT'),
                        entity: $autocomplete.district,
                        'entity-params': {},
                        clearable: true,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.districtId.props.disabled = !fields.worksiteId.value;
                                fields.districtId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        },
                    ],
                }),
                maintenanceReasonId: new Field({
                    name: 'ID_MOTIVO_ATENDIMENTO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('REASON'),
                        entity: $autocomplete.assitanceMotive,
                        'entity-params': {},
                        'require-params': ['ID_PARQUE_SERVICO'],
                        clearable: true,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.maintenanceReasonId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        },
                    ],
                }),
                maintenanceSolutionId: new Field({
                    name: 'ID_SOLUCAO_ATENDIMENTO_PS',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('SOLUTION'),
                        entity: $autocomplete.assitanceSolution,
                        clearable: true,
                        disabled: false,
                    },
                    dependsOn: [{
                            fieldName: ['ID_MOTIVO_ATENDIMENTO', 'ID_PARQUE_SERVICO'],
                            handler: (fields) => {
                                fields.maintenanceSolutionId.props['entity-params'] = {
                                    ID_MOTIVO_ATENDIMENTO_PS: fields.maintenanceReasonId.value,
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                                fields.maintenanceSolutionId.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                }),
                grouper: new Field({
                    name: 'AGRUPADOR',
                }),
                attributeFilter: new Field({
                    name: 'FILTRO_ATRIBUTOS',
                    component: () => import('@components/attribute/AttributeFilter.vue'),
                    parser: $util.attributesToString,
                    props: {
                        _sgi: 1,
                    },
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetAppliedMaterialCostGrouped extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarCustoMateriaisAplicadosAgrupado',
            type: 'SEARCH',
            node: 'MATERIAIS_AGRUPADOS.MATERIAL_AGRUPADO',
            fields: {
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                }),
                worksitesId: new Field({
                    name: 'IDS_PARQUE_SERVICO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('WORKSITE'),
                        entity: $autocomplete.worksite,
                        'entity-params': { ATIVO: 1 },
                        clearable: false,
                        multiple: true,
                    },
                }),
                searchMaterial: new Field({
                    name: 'BUSCA_MATERIAL',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('SEARCH'),
                    },
                }),
                startDate: new Field({
                    name: 'DATA_INICIAL',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('INITIAL_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                endDate: new Field({
                    name: 'DATA_FINAL',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('ENDING_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                userCompanyId: new Field({
                    name: 'ID_EMPRESA_USUARIA',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('USER_COMPANY'),
                        entity: $autocomplete.userCompany,
                    },
                }),
                familyId: new Field({
                    name: 'ID_FAMILIA',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('FAMILY'),
                        entity: $autocomplete.families,
                        'entity-params': { ITEM_OBRA: 0 },
                        clearable: true,
                    },
                }),
                locationGroupingId: new Field({
                    name: 'ID_AGRUPAMENTO_LOCALIZACAO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('ALLOWED_LOCATIONS'),
                        entity: $autocomplete.locationGrouping,
                    },
                }),
                districtId: new Field({
                    name: 'ID_BAIRRO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('DISTRICT'),
                        entity: $autocomplete.district,
                        'entity-params': {},
                        clearable: true,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.districtId.props.disabled = !fields.worksiteId.value;
                                fields.districtId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        },
                    ],
                }),
                maintenanceReasonId: new Field({
                    name: 'ID_MOTIVO_ATENDIMENTO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('REASON'),
                        entity: $autocomplete.assitanceReason,
                        'entity-params': {},
                        'require-params': ['ID_PARQUE_SERVICO'],
                        clearable: true,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.maintenanceReasonId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        },
                    ],
                }),
                maintenanceSolutionId: new Field({
                    name: 'ID_SOLUCAO_ATENDIMENTO_PS',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('SOLUTION'),
                        entity: $autocomplete.assitanceSolution,
                        clearable: true,
                        disabled: false,
                    },
                    dependsOn: [{
                            fieldName: ['ID_MOTIVO_ATENDIMENTO', 'ID_PARQUE_SERVICO'],
                            handler: (fields) => {
                                fields.maintenanceSolutionId.props['entity-params'] = {
                                    ID_MOTIVO_ATENDIMENTO_PS: fields.maintenanceReasonId.value,
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                                fields.maintenanceSolutionId.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                }),
                grouper: new Field({
                    name: 'AGRUPADOR',
                }),
                attributeFilter: new Field({
                    name: 'FILTRO_ATRIBUTOS',
                    component: () => import('@components/attribute/AttributeFilter.vue'),
                    parser: $util.attributesToString,
                    props: {
                        _sgi: 1,
                    },
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
