<template>
    <a-container ref='container' v-resize='onResize' style='height: 100%;' class='pa-0 ma-0'>
        <a-dynamic-command-filter
            v-show='false'
            ref='filter'
            :key='getMaintenanceReasonsByPeriod.name'
            filter-group='all'
            exhibition-mode='sidebar'
            class='pivot-filter'
            :_sidebar-props='{ _hideActions: true }'
            :_loading.sync='loading'
            :_fab='false'
            :_command='getMaintenanceReasonsByPeriod'
            @updateValues='updateDashData'
        />
        <chart
            ref='pieChart'
            :_options='getChartOptions()'
            style='height: 100%;'
        />
    </a-container>
</template>

<script>
    import { GetMaintenanceReasonsByPeriod } from '@/pages/maintenance/services/request';

    export default {
        name: 'MaintenanceProblemsFound',
        props: {
            /**
             * Prop to show if page is currently active
             * @type {Boolean}
             * @default {false}
            */
            _activePage: {
                type: Boolean,
                default: false,
            },
            /**
             * Command filter values sent from the Dashboard
             * @type {Object}
             * @default {}
             */
            _dashdata: {
                type: Object,
                default: () => ({
                    filters: [],
                }),
            },
            /**
             * Parameter to determine the chart's height
             * @type {[String, Number]}
             * @default {undefined}
             */
            _chartHeight: {
                type: [String, Number],
                default: undefined,
            },
            /**
             * Parameter to determine whether or not to show chart legend
             * @type {[Boolean]}
             * @default {false}
             */
            _showLegend: {
                type: [Boolean],
                default: false,
            },
            /**
             * sync with parent loading
             * @type {Boolean}
             * @default {false}
             */
            _loading: {
                type: Boolean,
                default: false,
            },
            /**
             * sync with parent empty-data
             * @type {Boolean}
             * @default {false}
             */
            _emptyData: {
                type: Boolean,
                default: false,
            },
        },
        data: function () {
            return {
                dashdata: this._dashdata,
                reportData: {},
                chartHeight: this._chartHeight || 'auto',
            };
        },
        computed: {
            getMaintenanceReasonsByPeriod: function () {
                const command = new GetMaintenanceReasonsByPeriod();
                command.initializeLoading = false;
                return command;
            },
            loading: {
                get: function () {
                    return this._loading;
                },
                set: function (value) {
                    this.$emit('update:_loading', value);
                },
            },
            emptyData: {
                get: function () {
                    return this._emptyData;
                },
                set: function (value) {
                    this.$emit('update:_emptyData', value);
                },
            },
        },
        watch: {
            dashdata: {
                deep: true,
                handler: function (value) {
                    this.$emit('update-dashdata', value);
                },
            },
            _chartHeight: {
                handler: function (value) {
                    this.chartHeight = value;
                    this.$nextTick(this.onResize);
                },
            },
            _activePage: {
                handler: function () {
                    this.$nextTick(this.onResize);
                },
            },
        },
        mounted: function () {
            this.load();
        },
        methods: {
            onResize: function () {
                this.$refs.pieChart.resize({ height: this.chartHeight });
            },

            load: async function () {
                this.$nextTick(this.onResize);

                if (this.dashdata.filters.length === 0) {
                    return;
                }
                this.$util.loadCommandFilter(this.getMaintenanceReasonsByPeriod, this.dashdata.filters);
                this.$refs.filter.refresh(true);
            },

            updateDashData: function (items) {
                this.emptyData = !items.length;
                this.dashdata.filters = this.$util.getFiltersByCommand(this.getMaintenanceReasonsByPeriod);
                this.loadData(items);
            },

            loadData: function (items) {
                this.reportData = this.formatToChart(items);
                this.loadChart();
            },

            formatToChart: function (data) {
                Object.keys(data).forEach((i) => {
                    data[i].name = data[i].NOME_MOTIVO;
                    data[i].value = (Number(data[i].PORCENTAGEM_MOTIVO) * 100).toFixed(2);
                });
                const formattedData = data;
                return formattedData;
            },

            loadChart: function () {
                this.$refs.pieChart.reset();
                const serie = {
                    id: 1,
                    type: 'pie',
                    radius: '65%',
                    center: ['50%', '50%'],
                    data: this.reportData,
                    label: {
                        formatter: (params) => {
                            let label = '';
                            label += `${params.name}`;
                            label += `\n${this.$t('TOTAL')}: ${params.data.TOTAL_MOTIVO}`;
                            label += `\n${params.value}%`;
                            return label;
                        },
                    },
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                        },
                    },
                };
                this.$refs.pieChart.addSerie(serie);
            },
            getChartOptions: function () {
                const options = {};
                const tooltip = {
                    trigger: 'item',
                    formatter: (params) => {
                        let label = '';
                        label += `<b>${params.name}</b>`;
                        label += `<br />${this.$t('TOTAL')}: ${params.data.TOTAL_MOTIVO}`;
                        label += `<br />${params.value}%`;
                        return label;
                    },
                    confine: true,
                };
                const legend = {
                    orient: 'vertical',
                    top: 'middle',
                    bottom: 10,
                    left: 'left',
                    data: this.reportData,
                };
                options.tooltip = tooltip;
                options.legend = this._showLegend ? legend : undefined;
                return options;
            },
        },
    };
</script>
