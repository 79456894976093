const paths = [
    {
        path: '/refuelling-authorization-list',
        name: 'RefuellingAuthorizationList',
        title: 'REFUELLING_AUTHORIZATION',
        icon: 'widgets',
        menu: 'fleet',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "refuellingAuthorization" */ /* webpackMode: "lazy" */ '@/pages/refuellingAuthorization/RefuellingAuthorizationList.vue'),
    },
    {
        path: '/refuelling-authorization-editor',
        name: 'RefuellingAuthorizationEditor',
        component: () => import(/* webpackChunkName: "refuellingAuthorization" */ /* webpackMode: "lazy" */ '@/pages/refuellingAuthorization/RefuellingAuthorizationEditor.vue'),
    },
    {
      path: '/refuelling-authorization-datails',
      name: 'RefuellingAuthorizationDetails',
      component: () => import(/* webpackChunkName: "refuellingAuthorization" */ /* webpackMode: "lazy" */ '@/pages/refuellingAuthorization/RefuellingAuthorizationDetails.vue'),
    },
    {
      path: '/approval-request',
      name: 'ApprovalRequest',
      component: () => import(/* webpackChunkName: "refuellingAuthorization" */ /* webpackMode: "lazy" */ '@/pages/refuellingAuthorization/ApprovalRequest.vue'),
    },
];

export default paths;
