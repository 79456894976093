const paths = [
  {
    path: '/change-log-list',
    name: 'ChangeLogList',
    title: 'CHANGE_LOG',
    component: () => import(/* webpackChunkName: "config" */ /* webpackMode: "lazy" */ '@/pages/changeLog/ChangeLogList.vue'),
  },
];

export default paths;
