import i18n from '@/i18n';

export default function issueType (provider) {
    return new Promise((resolve) => {
        const resultProvider = [];
        resultProvider.push({ ID_PARQUE_SERVICO: -1, NOME_PARQUE_SERVICO: i18n.t('ALL') });
        provider.forEach((item) => {
            resultProvider.push({ ID_PARQUE_SERVICO: parseInt(item.ID_PARQUE_SERVICO, 10), NOME_PARQUE_SERVICO: item.NOME_PARQUE_SERVICO });
        });

        resolve(resultProvider);
    });
}
