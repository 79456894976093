const paths = [
    {
        path: '/maintenance-solution-list',
        name: 'MaintenanceSolutionList',
        title: 'MAINTENANCE_SOLUTION',
        menu: 'maintenance',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "maintenanceSolution" */ /* webpackMode: "lazy" */ '@/pages/maintenanceSolution/MaintenanceSolutionList'),
    },
    {
        path: '/maintenance-solution-general',
        name: 'MaintenanceSolutionGeneral',
        title: 'MAINTENANCE_SOLUTION',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "maintenanceSolution" */ /* webpackMode: "lazy" */ '@/pages/maintenanceSolution/MaintenanceSolutionGeneral.vue'),
    },
];

export default paths;
