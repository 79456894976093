<script>
    export default {
        name: 'AIconSettings',
        functional: true,
        render: function (createElement, { data, parent }) {
            delete data.attrs.tablesearch;
            const children = ['mdi-table-search'];
            if ((!data.scopedSlots || !data.scopedSlots['tooltip-body']) && !data.attrs['_tooltip-text']) {
                data.attrs['_tooltip-text'] = parent.$t('TABLE_SEARCH');
            }
            return createElement('AIconDefault', data, children);
        },
    };
</script>
