const paths = [

        {
            path: '/executions-bulletins',
            title: 'EXECUTION_REPORTS',
            name: 'ExecutionsBulletins',
            icon: 'fas fa-file-invoice',
            menu: 'construction',
            component: () => import(/* webpackChunkName: "executionBulletins" */ /* webpackMode: "lazy" */ '@/pages/executionBulletins/ExecutionsBulletinsList.vue'),
        },

];

export default paths;
