import i18n from '@/i18n';

export default function ticketStatus (provider) {
    return new Promise((resolve, reject) => {
        const resultProvider = [];
        resultProvider.push({ KEY: -2, VALUE: i18n.t('ALL_ACTIVE') });
        resultProvider.push({ KEY: -1, VALUE: i18n.t('ALL') });
        if (provider.length > 0) {
            provider.forEach((item) => {
                resultProvider.push({ KEY: item.ID_STATUS_SOLICITACAO, VALUE: item.DESC_STATUS_SOLICITACAO });
            });

            resolve(resultProvider);
        } else {
            reject(resultProvider);
        }
    });
}
