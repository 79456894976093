const paths = [
    {
        path: '/geoserver-style-list',
        name: 'GeoserverStyleList',
        title: 'GEOSERVER_STYLE',
        component: () => import(/* webpackChunkName: "geoserverStyle" */ /* webpackMode: "lazy" */ '@/pages/geoserverStyle/GeoserverStyleList.vue'),
    },
    {
        path: '/geoserver-style',
        name: 'GeoserverStyle',
        title: 'GEOSERVER_STYLE',
        component: () => import(/* webpackChunkName: "geoserverStyle" */ /* webpackMode: "lazy" */ '@/pages/geoserverStyle/GeoserverStyle.vue'),
    },
];

export default paths;
