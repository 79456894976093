<script>
    export default {
        name: 'AIcon',
        functional: true,
        render: function (createElement, { data, children }) {
            data.attrs = data.attrs || {};
            if (data.attrs['_icon-type']) {
                data.attrs[data.attrs['_icon-type']] = true;
                delete data.attrs['_icon-type'];
                delete data.attrs.default;
            }

            if (Object.keys(data.attrs).includes('default') && data.attrs.default !== false && data.attrs.default !== 'false') {
                return createElement('AIconDefault', data, children);
            } if (Object.keys(data.attrs).includes('add') && data.attrs.add !== false && data.attrs.add !== 'false') {
                return createElement('AIconAdd', data, children);
            } if (Object.keys(data.attrs).includes('delete') && data.attrs.delete !== false && data.attrs.delete !== 'false') {
                return createElement('AIconDelete', data, children);
            } if (Object.keys(data.attrs).includes('edit') && data.attrs.edit !== false && data.attrs.edit !== 'false') {
                return createElement('AIconEdit', data, children);
            } if (Object.keys(data.attrs).includes('export') && data.attrs.export !== false && data.attrs.export !== 'false') {
                return createElement('AIconExport', data, children);
            } if (Object.keys(data.attrs).includes('save') && data.attrs.save !== false && data.attrs.save !== 'false') {
                return createElement('AIconSave', data, children);
            } if (Object.keys(data.attrs).includes('search') && data.attrs.search !== false && data.attrs.search !== 'false') {
                return createElement('AIconSearch', data, children);
            } if (Object.keys(data.attrs).includes('next') && data.attrs.next !== false && data.attrs.next !== 'false') {
                return createElement('AIconNext', data, children);
            } if (Object.keys(data.attrs).includes('previous') && data.attrs.previous !== false && data.attrs.previous !== 'false') {
                return createElement('AIconPrevious', data, children);
            } if (Object.keys(data.attrs).includes('check') && data.attrs.check !== false && data.attrs.check !== 'false') {
                return createElement('AIconCheck', data, children);
            } if (Object.keys(data.attrs).includes('usercheck') && data.attrs.usercheck !== false && data.attrs.usercheck !== 'false') {
                return createElement('AIconUserCheck', data, children);
            } if (Object.keys(data.attrs).includes('useruncheck') && data.attrs.useruncheck !== false && data.attrs.useruncheck !== 'false') {
                return createElement('AIconUserUncheck', data, children);
            } if (Object.keys(data.attrs).includes('excel') && data.attrs.excel !== false && data.attrs.excel !== 'false') {
                return createElement('AIconExcel', data, children);
            } if (Object.keys(data.attrs).includes('pdf') && data.attrs.pdf !== false && data.attrs.pdf !== 'false') {
                return createElement('AIconPdf', data, children);
            } if (Object.keys(data.attrs).includes('print') && data.attrs.print !== false && data.attrs.print !== 'false') {
                return createElement('AIconPrint', data, children);
            } if (Object.keys(data.attrs).includes('uncheck') && data.attrs.uncheck !== false && data.attrs.uncheck !== 'false') {
                return createElement('AIconUncheck', data, children);
            } if (Object.keys(data.attrs).includes('view') && data.attrs.view !== false && data.attrs.view !== 'false') {
                return createElement('AIconView', data, children);
            } if (Object.keys(data.attrs).includes('filter') && data.attrs.filter !== false && data.attrs.filter !== 'false') {
                return createElement('AIconFilter', data, children);
            } if (Object.keys(data.attrs).includes('refresh') && data.attrs.refresh !== false && data.attrs.refresh !== 'false') {
                return createElement('AIconRefresh', data, children);
            } if (Object.keys(data.attrs).includes('copy') && data.attrs.copy !== false && data.attrs.copy !== 'false') {
                return createElement('AIconCopy', data, children);
            } if (Object.keys(data.attrs).includes('warning') && data.attrs.warning !== false && data.attrs.warning !== 'false') {
                return createElement('AIconWarning', data, children);
            } if (Object.keys(data.attrs).includes('clone') && data.attrs.clone !== false && data.attrs.clone !== 'false') {
                return createElement('AIconClone', data, children);
            } if (Object.keys(data.attrs).includes('ellipsisv') && data.attrs.ellipsisv !== false && data.attrs.ellipsisv !== 'false') {
                return createElement('AIconEllipsisv', data, children);
            } if (Object.keys(data.attrs).includes('ellipsish') && data.attrs.ellipsish !== false && data.attrs.ellipsish !== 'false') {
                return createElement('AIconEllipsish', data, children);
            } if (Object.keys(data.attrs).includes('menu') && data.attrs.menu !== false && data.attrs.menu !== 'false') {
                return createElement('AIconMenu', data, children);
            } if (Object.keys(data.attrs).includes('settings') && data.attrs.settings !== false && data.attrs.settings !== 'false') {
                return createElement('AIconSettings', data, children);
            } if (Object.keys(data.attrs).includes('expandbottom') && data.attrs.expandbottom !== false && data.attrs.expandbottom !== 'false') {
                return createElement('AIconExpandbottom', data, children);
            } if (Object.keys(data.attrs).includes('expandtop') && data.attrs.expandtop !== false && data.attrs.expandtop !== 'false') {
                return createElement('AIconExpandtop', data, children);
            } if (Object.keys(data.attrs).includes('ban') && data.attrs.ban !== false && data.attrs.ban !== 'false') {
                return createElement('AIconBan', data, children);
            } if (Object.keys(data.attrs).includes('calendar') && data.attrs.calendar !== false && data.attrs.calendar !== 'false') {
                return createElement('AIconCalendar', data, children);
            } if (Object.keys(data.attrs).includes('cellphone') && data.attrs.cellphone !== false && data.attrs.cellphone !== 'false') {
                return createElement('AIconCellphone', data, children);
            } if (Object.keys(data.attrs).includes('chat') && data.attrs.chat !== false && data.attrs.chat !== 'false') {
                return createElement('AIconChat', data, children);
            } if (Object.keys(data.attrs).includes('clock') && data.attrs.clock !== false && data.attrs.clock !== 'false') {
                return createElement('AIconClock', data, children);
            } if (Object.keys(data.attrs).includes('close') && data.attrs.close !== false && data.attrs.close !== 'false') {
                return createElement('AIconClose', data, children);
            } if (Object.keys(data.attrs).includes('download') && data.attrs.download !== false && data.attrs.download !== 'false') {
                return createElement('AIconDownload', data, children);
            } if (Object.keys(data.attrs).includes('externallink') && data.attrs.externallink !== false && data.attrs.externallink !== 'false') {
                return createElement('AIconExternallink', data, children);
            } if (Object.keys(data.attrs).includes('fax') && data.attrs.fax !== false && data.attrs.fax !== 'false') {
                return createElement('AIconFax', data, children);
            } if (Object.keys(data.attrs).includes('file') && data.attrs.file !== false && data.attrs.file !== 'false') {
                return createElement('AIconFile', data, children);
            } if (Object.keys(data.attrs).includes('flag') && data.attrs.flag !== false && data.attrs.flag !== 'false') {
                return createElement('AIconFlag', data, children);
            } if (Object.keys(data.attrs).includes('folder') && data.attrs.folder !== false && data.attrs.folder !== 'false') {
                return createElement('AIconFolder', data, children);
            } if (Object.keys(data.attrs).includes('graph') && data.attrs.graph !== false && data.attrs.graph !== 'false') {
                return createElement('AIconGraph', data, children);
            } if (Object.keys(data.attrs).includes('historic') && data.attrs.historic !== false && data.attrs.historic !== 'false') {
                return createElement('AIconHistoric', data, children);
            } if (Object.keys(data.attrs).includes('info') && data.attrs.info !== false && data.attrs.info !== 'false') {
                return createElement('AIconInfo', data, children);
            } if (Object.keys(data.attrs).includes('map') && data.attrs.map !== false && data.attrs.map !== 'false') {
                return createElement('AIconMap', data, children);
            } if (Object.keys(data.attrs).includes('marker') && data.attrs.marker !== false && data.attrs.marker !== 'false') {
                return createElement('AIconMarker', data, children);
            } if (Object.keys(data.attrs).includes('notification') && data.attrs.notification !== false && data.attrs.notification !== 'false') {
                return createElement('AIconNotification', data, children);
            } if (Object.keys(data.attrs).includes('phone') && data.attrs.phone !== false && data.attrs.phone !== 'false') {
                return createElement('AIconPhone', data, children);
            } if (Object.keys(data.attrs).includes('photo') && data.attrs.photo !== false && data.attrs.photo !== 'false') {
                return createElement('AIconPhoto', data, children);
            } if (Object.keys(data.attrs).includes('pin') && data.attrs.pin !== false && data.attrs.pin !== 'false') {
                return createElement('AIconPin', data, children);
            } if (Object.keys(data.attrs).includes('privacy') && data.attrs.privacy !== false && data.attrs.privacy !== 'false') {
                return createElement('AIconPrivacy', data, children);
            } if (Object.keys(data.attrs).includes('star') && data.attrs.star !== false && data.attrs.star !== 'false') {
                return createElement('AIconStar', data, children);
            } if (Object.keys(data.attrs).includes('upload') && data.attrs.upload !== false && data.attrs.upload !== 'false') {
                return createElement('AIconUpload', data, children);
            } if (Object.keys(data.attrs).includes('user') && data.attrs.user !== false && data.attrs.user !== 'false') {
                return createElement('AIconUser', data, children);
            } if (Object.keys(data.attrs).includes('video') && data.attrs.video !== false && data.attrs.video !== 'false') {
                return createElement('AIconVideo', data, children);
            } if (Object.keys(data.attrs).includes('barcode') && data.attrs.barcode !== false && data.attrs.barcode !== 'false') {
                return createElement('AIconBarcode', data, children);
            } if (Object.keys(data.attrs).includes('qrcode') && data.attrs.qrcode !== false && data.attrs.qrcode !== 'false') {
                return createElement('AIconQRCode', data, children);
            } if (Object.keys(data.attrs).includes('keyboard') && data.attrs.keyboard !== false && data.attrs.keyboard !== 'false') {
                return createElement('AIconKeyboard', data, children);
            } if (Object.keys(data.attrs).includes('globe') && data.attrs.globe !== false && data.attrs.globe !== 'false') {
                return createElement('AIconGlobe', data, children);
            } if (Object.keys(data.attrs).includes('tablesearch') && data.attrs.tablesearch !== false && data.attrs.tablesearch !== 'false') {
                return createElement('AIconTablesearch', data, children);
            } if (Object.keys(data.attrs).includes('play') && data.attrs.play !== false && data.attrs.play !== 'false') {
                return createElement('AIconPlay', data, children);
            } if (Object.keys(data.attrs).includes('lock') && data.attrs.lock !== false && data.attrs.lock !== 'false') {
                return createElement('AIconLock', data, children);
            } if (Object.keys(data.attrs).includes('usergroup') && data.attrs.usergroup !== false && data.attrs.usergroup !== 'false') {
                return createElement('AIconUserGroup', data, children);
            } if (Object.keys(data.attrs).includes('migrate') && data.attrs.migrate !== false && data.attrs.migrate !== 'false') {
                return createElement('AIconMigrate', data, children);
            } if (Object.keys(data.attrs).includes('service') && data.attrs.service !== false && data.attrs.service !== 'false') {
                return createElement('AIconService', data, children);
            } if (Object.keys(data.attrs).includes('alarm') && data.attrs.alarm !== false && data.attrs.alarm !== 'false') {
                return createElement('AIconAlarm', data, children);
            } if (Object.keys(data.attrs).includes('select') && data.attrs.select !== false && data.attrs.select !== 'false') {
                return createElement('AIconSelect', data, children);
            } if (Object.keys(data.attrs).includes('unlink') && data.attrs.unlink !== false && data.attrs.unlink !== 'false') {
                return createElement('AIconUnlink', data, children);
            } if (Object.keys(data.attrs).includes('arrowup') && data.attrs.arrowup !== false && data.attrs.arrowup !== 'false') {
                return createElement('AIconArrowUp', data, children);
            } if (Object.keys(data.attrs).includes('arrowdown') && data.attrs.arrowdown !== false && data.attrs.arrowdown !== 'false') {
                return createElement('AIconArrowDown', data, children);
            } if (Object.keys(data.attrs).includes('send') && data.attrs.send !== false && data.attrs.send !== 'false') {
                return createElement('AIconSend', data, children);
            } if (Object.keys(data.attrs).includes('emailreceive') && data.attrs.emailreceive !== false && data.attrs.emailreceive !== 'false') {
                return createElement('AIconEmailreceive', data, children);
            } if (Object.keys(data.attrs).includes('currency') && data.attrs.currency !== false && data.attrs.currency !== 'false') {
                return createElement('AIconCurrency', data, children);
            } if (Object.keys(data.attrs).includes('splitarrow') && data.attrs.splitarrow !== false && data.attrs.splitarrow !== 'false') {
                return createElement('AIconSplitarrow', data, children);
            } if (Object.keys(data.attrs).includes('link') && data.attrs.link !== false && data.attrs.link !== 'false') {
                return createElement('AIconLink', data, children);
            } if (Object.keys(data.attrs).includes('refund') && data.attrs.refund !== false && data.attrs.refund !== 'false') {
                return createElement('AIconRefund', data, children);
            } if (Object.keys(data.attrs).includes('scalebalance') && data.attrs.scalebalance !== false && data.attrs.scalebalance !== 'false') {
                return createElement('AIconScalebalance', data, children);
            } if (Object.keys(data.attrs).includes('assign-user') && data.attrs['assign-user'] !== false && data.attrs['assign-user'] !== 'false') {
                return createElement('AIconAssignUser', data, children);
            } if (Object.keys(data.attrs).includes('dollar-sign') && data.attrs['dollar-sign'] !== false && data.attrs['dollar-sign'] !== 'false') {
                return createElement('AIconDollarSign', data, children);
            } if (Object.keys(data.attrs).includes('link') && data.attrs.link !== false && data.attrs.link !== 'false') {
                return createElement('AIconLink', data, children);
            } if (Object.keys(data.attrs).includes('report') && data.attrs['report'] !== false && data.attrs['report'] !== 'false') {
                return createElement('AIconReport', data, children);
            } if (Object.keys(data.attrs).includes('invoice') && data.attrs['invoice'] !== false && data.attrs['invoice'] !== 'false') {
                return createElement('AIconInvoice', data, children);
            } if (Object.keys(data.attrs).includes('open') && data.attrs.open !== false && data.attrs.open !== 'false') {
                return createElement('AIconOpen', data, children);
            } if (Object.keys(data.attrs).includes('viewslash') && data.attrs.viewslash !== false && data.attrs.viewslash !== 'false') {
                return createElement('AIconViewSlash', data, children);
            } if (Object.keys(data.attrs).includes('lightbulb') && data.attrs.lightbulb !== false && data.attrs.lightbulb !== 'false') {
                return createElement('AIconLightbulb', data, children);
            } if (Object.keys(data.attrs).includes('truck-check') && data.attrs['truck-check'] !== false && data.attrs['truck-check'] !== 'false') {
                return createElement('AIconTruckCheck', data, children);
            } if (Object.keys(data.attrs).includes('certificate') && data.attrs.certificate !== false && data.attrs.certificate !== 'false') {
                return createElement('AIconCertificate', data, children);
            } if (Object.keys(data.attrs).includes('certificate-file') && data.attrs['certificate-file'] !== false && data.attrs['certificate-file'] !== 'false') {
                return createElement('AIconCertificateFile', data, children);
            }

            return createElement('VIcon', data, children);
        },
    };
</script>
