const paths = [
    {
        path: '/team',
        name: 'Team',
        icon: 'mdi-account-group',
        title: 'TEAM',
        favCategory: 'humanResources',
        component: () => import(/* webpackChunkName: "team" */ /* webpackMode: "lazy" */ '@/pages/humansResources/team/Team.vue'),
    },
    {
        path: '/team-list',
        name: 'TeamList',
        icon: 'mdi-account-group',
        title: 'TEAMS',
        menu: 'humanResources',
        component: () => import(/* webpackChunkName: "team" */ /* webpackMode: "lazy" */ '@/pages/humansResources/team/TeamList.vue'),
    },
];

export default paths;
