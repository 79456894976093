import i18n from '@/i18n';
import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import { parseSuccessResponse, showErrorsResponse } from '../utils';
import store from '../store';
export class GetClient extends CallCommand {
    constructor() {
        super({
            node: 'RESPONSE',
            name: 'get_comp_client',
            type: 'NONE',
            fields: {
                include: new Field({
                    name: 'include',
                    value: [
                        { relation: 'company' },
                        { relation: 'apps' },
                    ],
                }),
                companyId: new Field({
                    name: 'company_id',
                    value: null,
                    component: 'a-select',
                    props: {
                        items: store.state.companies,
                        label: i18n.t('COMPANY'),
                        itemText: 'company_name',
                        itemValue: 'company_id',
                        clearable: true,
                        hideDetails: true,
                    },
                }),
                hideDeleted: new Field({
                    name: 'deleted_at',
                    value: true,
                }),
            },
            customRequestParser: function (params) {
                const filter = {
                    where: {},
                };
                if (params.hideDeleted) {
                    filter.where.deleted_at = { eq: null };
                }
                if (params.companyId) {
                    filter.where.company_id = params.companyId;
                }
                if (params.include) {
                    filter.include = params.include;
                }
                return {
                    BODY: `filter=${encodeURIComponent(JSON.stringify(filter))}`,
                };
            },
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        resolve.RAIZ.RESPONSE = response;
        return resolve;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetClientById extends CallCommand {
    constructor() {
        super({
            node: 'RESPONSE',
            name: 'get_comp_client_0',
            type: 'NONE',
            fields: {
                include: new Field({
                    name: 'include',
                    value: [
                        {
                            relation: 'apps',
                        },
                    ],
                }),
                clientId: new Field({
                    name: 'client_id',
                }),
            },
            customRequestParser: function (params) {
                const filter = {
                    include: params.include || [],
                };
                const pathParams = {
                    PARAM_0: params.clientId,
                };
                return {
                    PATH_PARAMS: JSON.stringify(pathParams),
                    BODY: `filter=${encodeURIComponent(JSON.stringify(filter))}`,
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveClient extends CallCommand {
    constructor() {
        super({
            node: 'RESPONSE',
            name: 'post_comp_client',
            type: 'SAVE',
            fields: {
                name: new Field({
                    name: 'name',
                }),
                companyId: new Field({
                    name: 'company_id',
                }),
            },
            customRequestParser: function (params) {
                const data = {
                    name: params.name,
                    company_id: params.companyId || undefined,
                };
                return {
                    BODY: JSON.stringify(data),
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class EditClient extends CallCommand {
    constructor() {
        super({
            node: 'RESPONSE',
            name: 'patch_comp_client_0',
            type: 'SAVE',
            fields: {
                name: new Field({
                    name: 'name',
                }),
                companyId: new Field({
                    name: 'company_id',
                }),
                clientId: new Field({
                    name: 'company_id',
                }),
            },
            customRequestParser: function (params) {
                const data = {
                    name: params.name,
                    company_id: params.companyId || undefined,
                };
                const pathParams = {
                    PARAM_0: params.clientId,
                };
                return {
                    PATH_PARAMS: JSON.stringify(pathParams),
                    BODY: JSON.stringify(data),
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class DeleteClient extends CallCommand {
    constructor() {
        super({
            node: 'RESPONSE',
            name: 'delete_comp_client_0',
            type: 'DELETE',
            fields: {
                clientId: new Field({
                    name: 'client_id',
                }),
            },
            customRequestParser: function (params) {
                const pathParams = {
                    PARAM_0: params.clientId,
                };
                return {
                    PATH_PARAMS: JSON.stringify(pathParams),
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
