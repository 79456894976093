<template>
    <a-container ref='container' v-resize='onResize' class='container' style='width: 100%; height: 100%;'>
        <a-dynamic-command-filter
            ref='filter'
            :key='getAppliedMaterialCost.name'
            filter-group='all'
            exhibition-mode='sidebar'
            class='pivot-filter'
            :_fab='false'
            :_sidebar-props='{ _hideActions: true }'
            :_loading.sync='loading'
            :_command='getAppliedMaterialCost'
            @updateValues='updateDashData'
        />
        <a-row no-gutters>
            <a-col>
                <a-radio-group
                    v-model='chartType'
                    class='mt-0'
                    row
                    dense
                    hide-details
                >
                    <a-radio :label='$t("MONTH")' :value='1' />
                    <a-radio :label='$t("YEAR")' :value='2' />
                </a-radio-group>
            </a-col>
        </a-row>
        <chart
            ref='chart'
            :_options='chartOptions'
            style='height: calc(100% - 28px)'
        />
    </a-container>
</template>

<script>
    import { GetAppliedMaterialCostGrouped } from './services/request';

    export default {
        name: 'AppliedMaterialsCostChart',
        props: {
            /**
             * Prop to show if page is currently active
             * @type {Boolean}
             * @default {false}
            */
            _activePage: {
                type: Boolean,
                default: false,
            },
            /**
             * Command filter values sent from the Dashboard
             * @type {Object}
             * @default {{ filters: [], }}
             */
            _dashdata: {
                type: Object,
                default: () => ({
                    filters: [],
                }),
            },
            /**
             * Prop to determine if the chart is being used from the dashboard
             * Set to false if the component is being used outside of it
             * @type {Boolean}
             * @default {true}
             */
            _dashboardMode: {
                type: Boolean,
                default: true,
            },

            /**
             * Command filter values sent from the AppliedMaterialsCostList page to fill the getAppliedMaterialCost fields
             * @type {Array}
             * @default {[]}
             */
            _filters: {
                type: Array,
                default: () => ([]),
            },
            /**
             * sync with parent loading
             * @type {Boolean}
             * @default {false}
             */
            _loading: {
                type: Boolean,
                default: false,
            },
            /**
             * sync with parent empty-data
             * @type {Boolean}
             * @default {false}
             */
            _emptyData: {
                type: Boolean,
                default: false,
            },
        },
        data: function () {
            return {
                dashdata: this._dashdata,
                categoryData: [],
                reportData: [],
                chartType: 1,
                total: 0,
                filters: null,

                // Chart instance, used for the on() event
                chart: undefined,

                // Default chart before it's loaded with data
                chartOptions: {
                    xAxis: {
                        type: 'category',
                        name: this.timeDescription,
                    },
                    yAxis: {
                        type: 'value',
                        name: `${this.$t('COST')} (${this.$store.getters.getCurrencySymbol})`,
                    },
                },
            };
        },
        computed: {
            getAppliedMaterialCost: function () {
                const command = new GetAppliedMaterialCostGrouped();
                const values = {};
                values.grouper = 1;
                values.startDate = this.$moment().subtract(1, 'months').format('DD/MM/YYYY');

                command.values = values;
                command.initializeLoading = false;
                command.onSuccess = this.processResolve;
                return command;
            },
            periodName: function () {
                switch (this.chartType) {
                    case 1:
                        return this.$t('MONTH');
                    case 2:
                        return this.$t('YEAR');
                    default:
                        return '';
                }
            },
            loading: {
                get: function () {
                    return this._loading;
                },
                set: function (value) {
                    this.$emit('update:_loading', value);
                },
            },
            emptyData: {
                get: function () {
                    return this._emptyData;
                },
                set: function (value) {
                    this.$emit('update:_emptyData', value);
                },
            },
        },
        watch: {
            dashdata: {
                deep: true,
                handler: function (value) {
                    this.$emit('update-dashdata', value);
                },
            },
            _activePage: {
                handler: function () {
                    this.onResize();
                },
            },
            _filters: {
                immediate: true,
                handler: function (value) {
                    if (value) {
                        this.$util.loadCommandFilter(this.getAppliedMaterialCost, value);
                    }
                },
            },
            filters: {
                deep: true,
                handler: function (val) {
                    this.$emit('update:_filters', val);
                },
            },
            chartType: function () {
                this.getAppliedMaterialCost.fields.grouper.value = this.chartType;
                this.getAppliedMaterialCost.refreshData();
            },
        },
        mounted: function () {
            this.load();
        },
        methods: {
            onResize: function () {
                this.$nextTick(this.$refs.chart.resize);
            },
            load: async function () {
                this.onResize();
                this.chart = this.$refs.chart.getChart();

                if (this.dashdata.filters.length !== 0) {
                    this.$util.loadCommandFilter(this.getAppliedMaterialCost, this.dashdata.filters);
                    this.$refs.filter.refresh(true);
                }
            },
            updateDashData: function (items) {
                this.emptyData = !items.length;
                // if the page is being used by the dashboard, update the filters
                if (this._dashboardMode) {
                    this.dashdata.filters = this.$util.getFiltersByCommand(this.getAppliedMaterialCost);
                }
                this.loadData(items);
            },
            loadData: async function (items) {
                this.formatToChart(items);
                this.loadChart();
            },
            formatToChart: function (data) {
                this.reportData = [];
                this.categoryData = [];
                Object.keys(data).forEach((i) => {
                    const item = data[i];
                    item.name = item.PERIODO;
                    item.value = Number(item.CUSTO_TOTAL || 0);

                    this.reportData.push(item);
                    this.categoryData.push(item.name);
                });
            },
            loadChart: function () {
                this.$refs.chart.reset();
                this.setChartOption();
                this.addSerie();
                this.addTooltip();
                this.onResize();
            },
            setChartOption: function () {
                const chartOptions = {
                    maintainAspectRatio: false,
                    grid: {
                        containLabel: true,
                        bottom: '20',
                    },
                    xAxis: {
                        type: 'category',
                        name: this.periodName,
                        data: this.categoryData,
                    },
                    yAxis: {
                        type: 'value',
                        name: this.$t('COST'),
                        axisLabel: {
                            formatter: '{value}',
                        },
                    },
                };

                this.$refs.chart.setOption(chartOptions);
            },
            addSerie: function () {
                this.$refs.chart.addSerie({
                    id: 1,
                    type: 'bar',
                    data: this.reportData,

                    animation: true,
                    left: 'right',
                    top: 'middle',

                    barWidth: '50%',
                });
            },
            addTooltip: function () {
                const tooltip = {
                    trigger: 'axis',
                    formatter: this.tooltipFormatter,
                    confine: true,
                };
                this.$refs.chart.setTooltip(tooltip);
            },
            tooltipFormatter: function (params) {
                params = params[0];
                const period = params.data.PERIODO;
                const totalCost = Number(params.data.CUSTO_TOTAL || 0);
                const percentage = Number((totalCost / Number(this.total)) * 100) || 0;

                let label = `<b>${this.$t('TOTAL_COST')}</b><br />`;
                label += `${this.$t('PERIOD')}: ${period}<br />`;
                label += `${this.$t('COST')}: ${this.$store.getters.getCurrencySymbol} ${this.$util.asMonetary(totalCost)} (${this.$util.numberFormat(percentage)}%)<br />`;
                label += `${this.$t('TOTAL')}: ${this.$store.getters.getCurrencySymbol} ${this.$util.asMonetary(Number(this.total || 0))}<br />`;
                return label;
            },
            processResolve: function (resolve) {
                const materials = this.$util.getArrayByNode(resolve, this.getAppliedMaterialCost.node);
                let total = 0;
                materials.forEach(element => {
                    total += Number(element.CUSTO_TOTAL || 0);
                });
                this.total = total;

                this.filters = this.$util.getFiltersByCommand(this.getAppliedMaterialCost);
                return resolve;
            },
            getFilters: function () {
                this.filters = this.$util.getFiltersByCommand(this.getAppliedMaterialCost);
            },
        },
    };
</script>
