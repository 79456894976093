const paths = [
    {
      path: '/ticket-Dpo-details',
      name: 'TicketDpoDetails',
      title: 'DETAILS',
      component: () => import(/* webpackChunkName: "ticketDpo" */ /* webpackMode: "lazy" */ '@/pages/ticketDpo/TicketDpoDetails.vue'),
    },
    {
      path: '/ticket-Dpo-wizard',
      name: 'TicketDpoWizard',
      title: 'NEW_TICKET',
      component: () => import(/* webpackChunkName: "ticketDpo" */ /* webpackMode: "lazy" */ '@/pages/ticketDpo/TicketDpoWizard.vue'),
    },
    {
        path: '/ticket-Dpo-list',
        name: 'TicketDpoList',
        title: 'DPO_TICKETS',
        icon: 'fa fa-hard-hat',
        menu: 'security',
        component: () => import(/* webpackChunkName: "ticketDpo" */ /* webpackMode: "lazy" */ '@/pages/ticketDpo/TicketDpoList.vue'),
    },
    {
      path: '/ticket-email-data-leakage-form',
      name: 'TicketEmailDataLeakageForm',
      title: 'EMAIL_DATA_LEAKAGE',
      component: () => import(/* webpackChunkName: "ticketDpo" */ /* webpackMode: "lazy" */ '@/pages/ticketDpo/TicketEmailDataLeakageForm.vue'),
    },
    {
      path: '/ticket-Dpo-history',
      name: 'TicketDpoHistory',
      title: 'HISTORY',
      component: () => import(/* webpackChunkName: "ticketDpo" */ /* webpackMode: "lazy" */ '@/pages/ticketDpo/TicketDpoHistory.vue'),
    },
];

export default paths;
