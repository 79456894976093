export default function address (provider) {
    return new Promise((resolve, reject) => {
        const resultProvider = [];
        if (provider.length > 0) {
            provider.forEach((item) => {
                resultProvider.push({ KEY: item.ID_LOGRADOURO, VALUE: `${item.DESC_TIPO_LOGRADOURO} ${item.NOME_LOGRADOURO} (${item.NOME_BAIRRO}) - ${item.NUM_CEP}` });
            });

            resolve(resultProvider);
        } else {
            reject(resultProvider);
        }
    });
}
