const paths = [
    {
        path: '/courses-and-certificates-company',
        name: 'CoursesAndCertificatesCompany',
        title: 'COURSES_AND_CERTIFICATES_COMPANY',
        icon: 'fa fa-briefcase',
        menu: 'humanResources',
        component: () => import(/* webpackChunkName: "coursesAndCertificatesCompany" */ /* webpackMode: "lazy" */ '@/pages/coursesAndCertificatesCompany/CoursesAndCertificatesCompany.vue'),
    },
    {
        path: '/certificate-reports-company',
        name: 'CertificateReportsCompany',
        component: () => import(/* webpackChunkName: "coursesAndCertificatesCompany" */ /* webpackMode: "lazy" */ '@/pages/coursesAndCertificatesCompany/CertificateReportsCompany.vue'),

    },
    {
        path: '/function-reports-company',
        name: 'FunctionReportsCompany',
        component: () => import(/* webpackChunkName: "coursesAndCertificatesCompany" */ /* webpackMode: "lazy" */ '@/pages/coursesAndCertificatesCompany/FunctionReportsCompany.vue'),

    },
];

export default paths;
