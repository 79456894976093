export const SESSION_EVENTS = {
    OPEN_DETAILS: 'devices:open-details',
    RELOAD_ITEMS: 'devices:reload',
    DEVICES_LOADED: 'devices:loaded',
};
export const MARKERS_MODE = {
    CONNECTION: 'connection',
    ALERT: 'alert',
    LAMP_STATE: 'lampState',
};
export const ROLES = {
    ADMIN: 'admin',
};
export default {
    SESSION_EVENTS: SESSION_EVENTS,
    MARKERS_MODE: MARKERS_MODE,
};
