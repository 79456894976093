import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import { parseSuccessResponse, showErrorsResponse } from '../utils';
export class GetFixtures extends CallCommand {
    constructor() {
        super({
            name: 'get_fixtures',
            type: 'SAVE',
            fields: {
                include: new Field({
                    name: 'BODY',
                    value: [],
                }),
            },
            customRequestParser: function (params) {
                const filter = {
                    include: params.include,
                };
                return {
                    BODY: `filter=${encodeURIComponent(JSON.stringify(filter))}`,
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class RegisterFixture extends CallCommand {
    constructor() {
        super({
            name: 'post_fixtures_0_register',
            type: 'SAVE',
            fields: {
                fixtureId: new Field({
                    name: 'PATH_PARAMS',
                }),
            },
            customRequestParser: function (params) {
                return {
                    PATH_PARAMS: JSON.stringify({ PARAM_0: params.fixtureId }),
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class StartFixture extends CallCommand {
    constructor() {
        super({
            name: 'post_fixtures_0_start',
            type: 'SAVE',
            fields: {
                fixtureId: new Field({
                    name: 'PATH_PARAMS',
                }),
            },
            customRequestParser: function (params) {
                return {
                    PATH_PARAMS: JSON.stringify({ PARAM_0: params.fixtureId }),
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class StopFixture extends CallCommand {
    constructor() {
        super({
            name: 'post_fixtures_0_stop',
            type: 'SAVE',
            fields: {
                fixtureId: new Field({
                    name: 'PATH_PARAMS',
                }),
            },
            customRequestParser: function (params) {
                return {
                    PATH_PARAMS: JSON.stringify({ PARAM_0: params.fixtureId }),
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class RestartFixture extends CallCommand {
    constructor() {
        super({
            name: 'post_fixtures_0_restart',
            type: 'SAVE',
            fields: {
                fixtureId: new Field({
                    name: 'PATH_PARAMS',
                }),
            },
            customRequestParser: function (params) {
                return {
                    PATH_PARAMS: JSON.stringify({ PARAM_0: params.fixtureId }),
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        showErrorsResponse(reject);
        return Promise.reject(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
