const getArray = function (value) {
    try {
        if (value === undefined || value === null) {
            return [];
        }
        if (value.constructor === Array) {
            return value;
        } 
        if (value.constructor === Function) {
            const val = value();
            return getArray(val);
        }
        return [value];
    } catch (e) {
        return [];
    }
};

export default getArray;
