const paths = [
    {
        path: '/anniversary-relation',
        name: 'AnniversaryRelation',
        title: 'ANNIVERSARY_RELATION',
        menu: 'humanResources',
        icon: 'mdi-account-group',
        component: () => import(/* webpackChunkName: "anniversaryRelation" */ /* webpackMode: "lazy" */ '@/pages/anniversary/AnniversaryRelation.vue'),
    },
];
export default paths;
