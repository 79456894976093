const paths = [
    {
        path: '/thematic-map',
        name: 'ThematicMap',
        title: 'THEMATIC_MAP',
        menu: 'customization',
        icon: 'fa fa-map-marked-alt',
        component: () => import(/* webpackChunkName: "thematicMap" */ /* webpackMode: "lazy" */ '@/pages/thematicMap/ThematicMap.vue'),
    },
];

export default paths;
