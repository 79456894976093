const paths = [
    {
        path: '/purchase-order-list',
        name: 'PurchaseOrderList',
        title: 'PURCHASE_ORDERS',
        icon: 'fa fa-shopping-basket',
        menu: 'shopping',
        component: () => import(/* webpackChunkName: "purchaseOrder" */ /* webpackMode: "lazy" */ '@/pages/purchaseOrder/PurchaseOrderList.vue'),
    },
    {
        path: '/purchase-order',
        name: 'PurchaseOrder',
        title: 'PURCHASE_ORDER',
        favCategory: 'shopping',
        component: () => import(/* webpackChunkName: "purchaseOrder" */ /* webpackMode: "lazy" */ '@/pages/purchaseOrder/PurchaseOrder.vue'),
    },
    {
        path: '/purchase-order-items-wizard',
        name: 'PurchaseOrderItemsWizard',
        title: 'PURCHASE_ORDER',
        component: () => import(/* webpackChunkName: "purchaseOrder" */ /* webpackMode: "lazy" */ '@/pages/purchaseOrder/PurchaseOrderItemsWizard.vue'),
    },
];

export default paths;
