import i18n from '@/i18n';
import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import localStore from '@/pages/remoteManagement/services/store';
import { parseSuccessResponse, showErrorsResponse } from '../utils';
export class GetDeviceConnectionSearch extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_device-connection_search',
            type: 'SEARCH',
            node: 'RESPONSE',
            fields: {
                limit: new Field({
                    name: 'limit',
                }),
                serialNumber: new Field({
                    name: 'serial_number',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('SERIAL_NUMBER'),
                    },
                }),
                reference: new Field({
                    name: 'reference',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('REFERENCE'),
                    },
                }),
                companyId: new Field({
                    name: 'companyId',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('COMPANY'),
                        items: localStore.state.companies || [],
                        itemText: 'company_name',
                        itemValue: 'company_id',
                        clearable: true,
                    },
                }),
                minDate: new Field({
                    name: 'min_date',
                    component: 'ADateTimePicker',
                    props: {
                        'text-field': true,
                        label: i18n.t('MIN_DATE'),
                        'use-seconds': true,
                    },
                }),
                maxDate: new Field({
                    name: 'max_date',
                    component: 'ADateTimePicker',
                    props: {
                        'text-field': true,
                        label: i18n.t('MAX_DATE'),
                        'use-seconds': true,
                    },
                }),
                action: new Field({
                    name: 'action',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('ACTION'),
                    },
                }),
                ipv6Address: new Field({
                    name: 'ipv6_address',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('IPV6'),
                    },
                }),
            },
            customRequestParser: function (params) {
                const filter = {
                    serial_number: params.serialNumber || null,
                    min_date: params.minDate || null,
                    max_date: params.maxDate || null,
                    action: params.action || null,
                    ipv6_address: params.ipv6Address || null,
                    limit: params.limit || null,
                    reference: params.reference || null,
                    company_id: params.companyId || null,
                };
                return {
                    BODY: `searchFilter=${encodeURIComponent(JSON.stringify(filter))}`,
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetDeviceConnectionReconnectionHistory extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_device-connection_reconnection-history_0',
            type: 'SEARCH',
            node: 'RESPONSE',
            fields: {
                deviceId: new Field({
                    name: 'device_id',
                }),
                connected: new Field({
                    name: 'connected',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('CONNECTION'),
                        items: [
                            { text: i18n.t('NO_CONNECTION'), value: 'false' },
                            { text: i18n.t('CONNECTED'), value: 'true' },
                        ],
                        itemText: 'text',
                        itemValue: 'value',
                        clearable: true,
                    },
                }),
                minDate: new Field({
                    name: 'min_date',
                    component: 'ADateTimePicker',
                    props: {
                        'text-field': true,
                        label: i18n.t('MIN_DATE'),
                    },
                }),
                maxDate: new Field({
                    name: 'max_date',
                    component: 'ADateTimePicker',
                    props: {
                        'text-field': true,
                        label: i18n.t('MAX_DATE'),
                    },
                }),
                limit: new Field({
                    name: 'limit',
                }),
            },
            customRequestParser: function (params) {
                const filter = {
                    connected: params.connected || null,
                    min_date: params.minDate || null,
                    max_date: params.maxDate || null,
                    limit: params.limit || null,
                };
                const pathParams = {
                    PARAM_0: params.deviceId,
                };
                return {
                    PATH_PARAMS: JSON.stringify(pathParams),
                    BODY: `searchFilter=${encodeURIComponent(JSON.stringify(filter))}`,
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(config, debug) {
        return super.callCommand(config, debug);
    }
}
export class GetDeviceConnection extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_device-connection',
            type: 'NONE',
            node: 'RESPONSE',
            fields: {
                serialNumber: new Field({
                    name: 'serial_number',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('SERIAL_NUMBER'),
                    },
                }),
                companyId: new Field({
                    name: 'companyId',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('COMPANY'),
                        items: localStore.state.companies || [],
                        itemText: 'company_name',
                        itemValue: 'company_id',
                        clearable: true,
                    },
                }),
                minDate: new Field({
                    name: 'min_date',
                    component: 'ADatePicker',
                    props: {
                        'text-field': true,
                        label: i18n.t('MIN_DATE'),
                    },
                }),
                maxDate: new Field({
                    name: 'max_date',
                    component: 'ADatePicker',
                    props: {
                        'text-field': true,
                        label: i18n.t('MAX_DATE'),
                    },
                }),
                action: new Field({
                    name: 'action',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('ACTION'),
                    },
                }),
                visualizationType: new Field({
                    name: 'visualization_type',
                    component: 'ASelect',
                    value: 'last-connection-by-device',
                    props: {
                        label: i18n.t('VISUALIZATION_TYPE'),
                        items: [
                            { name: i18n.t('LAST_CONNECTION_BY_DEVICE'), value: 'last-connection-by-device' },
                            { name: i18n.t('ALL_DEVICES_CONNECTIONS'), value: 'all-devices-connections' },
                        ],
                        itemText: 'name',
                        itemValue: 'value',
                        clearable: false,
                    },
                }),
                limit: new Field({
                    name: 'limit',
                }),
            },
            customRequestParser: function (params) {
                const filter = {
                    serial_number: params.serialNumber || null,
                    visualization_type: params.visualizationType || null,
                    company_id: params.companyId || null,
                    min_date: params.minDate || null,
                    max_date: params.maxDate || null,
                    min_value: params.minValue || null,
                    max_value: params.maxValue || null,
                    action: params.action || null,
                    limit: params.limit || null,
                };
                return {
                    BODY: `searchFilter=${encodeURIComponent(JSON.stringify(filter))}`,
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetDeviceConnectionRate extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarTaxaConexaoLuminarias',
            type: 'SEARCH',
            node: 'ITEMS.ITEM',
            fields: {
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                }),
                initialDate: new Field({
                    name: 'initial_date',
                    component: 'ADateTimePicker',
                    props: {
                        'text-field': true,
                        label: i18n.t('INITIAL_DATE'),
                    },
                }),
                finalDate: new Field({
                    name: 'final_date',
                    component: 'ADateTimePicker',
                    props: {
                        'text-field': true,
                        label: i18n.t('ENDING_DATE'),
                    },
                }),
                reference: new Field({
                    name: 'reference',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('REFERENCE'),
                    },
                }),
                lampDesc: new Field({
                    name: 'LAMP_DESC',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('LAMP_DESCRIPTION'),
                    },
                }),
                identification: new Field({
                    name: 'IDENTIFICACAO',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('IDENTIFICATION'),
                    },
                }),
                minConnectionRate: new Field({
                    name: 'min_connection_rate',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('MIN_CONNECTION_RATE'),
                    },
                }),
                maxConnectionRate: new Field({
                    name: 'max_connection_rate',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('MAX_CONNECTION_RATE'),
                    },
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
