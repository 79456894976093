const paths = [
    {
        path: '/traffic-violation',
        title: 'TRAFFIC_VIOLATION',
        name: 'TrafficViolation',
        component: () => import(/* webpackChunkName: "registers" */ /* webpackMode: "lazy" */ '@/pages/trafficViolation/TrafficViolation.vue'),
    },
];

export default paths;
