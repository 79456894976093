const paths = [
    {
        path: '/project-structure-type-selection',
        name: 'ProjectStructureTypeSelection',
        title: 'SELECT_A_PROJECT_LOCATION_TYPE',
        component: () => import(/* webpackChunkName: "projectStructure" */ /* webpackMode: "lazy" */ '@/pages/project/projectStructure/ProjectStructureTypeSelection.vue'),
    },
    {
        path: '/project-structure-asset',
        name: 'ProjectStructureAsset',
        title: 'ADD_ASSETS',
        component: () => import(/* webpackChunkName: "projectStructure" */ /* webpackMode: "lazy" */ '@/pages/project/projectStructure/ProjectStructureAsset.vue'),
    },
    {
        path: '/project-structure-asset-location',
        name: 'ProjectStructureAssetLocation',
        title: 'ADD_ASSET',
        component: () => import(/* webpackChunkName: "projectStructure" */ /* webpackMode: "lazy" */ '@/pages/project/projectStructure/ProjectStructureAssetLocation.vue'),
    },
    {
        path: '/project-structure-replication',
        name: 'ProjectStructureReplication',
        title: 'STRUCTURE_REPLICATION',
        component: () => import(/* webpackChunkName: "projectStructure" */ /* webpackMode: "lazy" */ '@/pages/project/projectStructure/ProjectStructureReplication.vue'),
    },
    {
        path: '/project-structure-composition',
        name: 'ProjectStructureComposition',
        title: 'ADD_STRUCTURE',
        component: () => import(/* webpackChunkName: "projectStructure" */ /* webpackMode: "lazy" */ '@/pages/project/projectStructure/ProjectStructureComposition.vue'),
    },
    {
        path: '/project-structure-parameters-fields',
        name: 'ProjectStructureParametersFields',
        title: 'EDIT_PARAMETERS',
        component: () => import(/* webpackChunkName: "projectStructure" */ /* webpackMode: "lazy" */ '@/pages/project/projectStructure/ProjectStructureParametersFields.vue'),
    },
    {
        path: '/project-structure-tabs-details-duplicate',
        name: 'ProjectStructureTabsDetailsDuplicate',
        title: 'CHANGE_QUANTITY',
        component: () => import(/* webpackChunkName: "projectStructure" */ /* webpackMode: "lazy" */ '@/pages/project/projectStructure/ProjectStructureTabsDetailsDuplicate.vue'),
    },
    {
        path: '/project-structure-import',
        name: 'ProjectStructureImport',
        title: 'IMPORT_STRUCTURE_DESIGN',
        component: () => import(/* webpackChunkName: "projectStructure" */ /* webpackMode: "lazy" */ '@/pages/project/projectStructure/ProjectStructureImport.vue'),
    },
    {
        path: '/project-structure-import-copel-file',
        name: 'ProjectStructureImportCopelFile',
        title: 'IMPORT_STRUCTURE_DESIGN',
        component: () => import(/* webpackChunkName: "projectStructure" */ /* webpackMode: "lazy" */ '@/pages/project/projectStructure/ProjectStructureImportCopelFile.vue'),
    },
    {
        path: '/project-structure-tabs-details-item',
        name: 'ProjectStructureTabsDetailsItem',
        title: 'ITEM',
        component: () => import(/* webpackChunkName: "projectStructure" */ /* webpackMode: "lazy" */ '@/pages/project/projectStructure/ProjectStructureTabsDetailsItem.vue'),
    },
    {
        path: '/project-structure-print-map',
        name: 'ProjectStructurePrintMap',
        title: 'PRINT_PROJECT_STRUCTURE_ASSETS',
        component: () => import(/* webpackChunkName: "projectStructure" */ /* webpackMode: "lazy" */ '@/pages/project/projectStructure/ProjectStructurePrintMap.vue'),
    },
    {
        path: '/project-structure-print-map-caption',
        name: 'ProjectStructureMapCaption',
        title: 'CAPTION',
        component: () => import(/* webpackChunkName: "projectStructure" */ /* webpackMode: "lazy" */ '@/pages/project/projectStructure/ProjectStructureMapCaption.vue'),
    },
    {
        path: '/project-structure-as-built',
        name: 'ProjectStructureAsBuilt',
        title: 'EXPORT_AS_BUILT_PROJECT',
        component: () => import(/* webpackChunkName: "projectStructure" */ /* webpackMode: "lazy" */ '@/pages/project/projectStructure/ProjectStructureAsBuilt.vue'),
    },
    {
        path: '/project-structure-as-built-caption',
        name: 'ProjectStructureAsBuiltCaption',
        title: 'CAPTION',
        component: () => import(/* webpackChunkName: "projectStructure" */ /* webpackMode: "lazy" */ '@/pages/project/projectStructure/ProjectStructureAsBuiltCaption.vue'),
    },
    {
        path: '/project-structure-as-built-preview',
        name: 'ProjectStructureAsBuiltPreview',
        component: () => import(/* webpackChunkName: "projectStructure" */ /* webpackMode: "lazy" */ '@/pages/project/projectStructure/ProjectStructureAsBuiltPreview.vue'),
    },
    {
        path: '/project-structure-history',
        name: 'ProjectStructureHistory',
        title: 'PROJECT_STRUCTURE_HISTORY',
        component: () => import(/* webpackChunkName: "projectStructure" */ /* webpackMode: "lazy" */ '@/pages/project/projectStructure/ProjectStructureHistory.vue'),
    },
];

export default paths;
