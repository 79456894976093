import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import { parseSuccessResponse, showErrorsResponse } from '../utils';
export class SaveAlertTypeRules extends CallCommand {
    constructor() {
        super({
            name: 'post_comp_alert-type-rule_create-all_0',
            type: 'SAVE',
            fields: {
                alertTypeId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => JSON.stringify({ PARAM_0: value }),
                }),
                alertTypeRules: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class EditAlertTypeRules extends CallCommand {
    constructor() {
        super({
            name: 'put_comp_alert-type-rule_replace-all_0',
            type: 'SAVE',
            fields: {
                alertTypeId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
                alertTypeRules: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
