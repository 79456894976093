export default [
    {
        path: '/panel-process',
        title: 'PANEL_PROCESS',
        name: 'PanelProcess',
        icon: 'fa fa-hard-hat',
        menu: 'processes',
        component: () => import(/* webpackChunkName: "PanelProcess" */ /* webpackMode: "lazy" */ '@/pages/process/PanelProcess.vue'),
    },
    {
        path: '/process-wizard',
        name: 'ProcessWizard',
        component: () => import(/* webpackChunkName: "ProcessWizard" */ /* webpackMode: "lazy" */ '@/pages/process/ProcessWizard.vue'),
    },
];
