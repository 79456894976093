<template>
    <a-container ref='container' v-resize='onResize' class='fill-height'>
        <a-spacer></a-spacer>
        <span>
            <a-row>
                <template v-if='!chartType'>
                    <a-col cols='auto'>
                        <a-checkbox
                            v-model='byClients'
                            :label='$t("BY_CLIENT")'
                            :true-value='1'
                            :false-value='0'
                        />
                        <a-text-field
                            v-model='relevantClients'
                            type='number'
                            clearable
                            counter
                            :rules='[rules.counter]'
                        />
                    </a-col>
                </template>
                <a-col cols='auto'>
                    <a-radio-group v-model='chartType'>
                        <a-radio :label='$t("MONTH_BY_MONTH")' :value='0' />
                        <a-radio v-if='!byClients':label='$t("BILLING")' :value='1' />
                    </a-radio-group>
                </a-col>
                <a-col cols='auto'>
                    <a-radio-group v-model='valueType'>
                        <a-radio :label='$t("GROSS_VALUE")' :value='1' />
                        <a-radio :label='$t("LIQUID_VALUE")' :value='2' />
                    </a-radio-group>
                </a-col>
                <a-col cols='auto'>
                    <a-dynamic-command-filter
                        ref='filter'
                        :key='getRevenuesReport.name'
                        filter-group='all'
                        exhibition-mode='sidebar'
                        :_fab='false'
                        class='pivot-filter'
                        :_command='getRevenuesReport'
                        @updateValues='updateDashData'
                    />
                </a-col>
            </a-row>
        </span>
        <a-row justify='center' style='width: 100%; height: 100%; position:relative;'>
            <chart
                ref='chart'
                :_options='chartOptions'
                style='height: 70%;'
            />
        </a-row>
    </a-container>
</template>

<script>
    import { GetRevenuesReport } from '@/components/charts/revenuesReport/services/request.ts';

    export default {
        name: 'RevenuesReportChart',
        props: {
            /**
             * Prop to show if page is currently active
             * @type {Boolean}
             * @default {false}
            */
            _activePage: {
                type: Boolean,
                default: false,
            },
            /**
             * Command filter values sent from the Dashboard
             * @type {Object}
             * @default {{ filters: [], }}
             */
            _dashdata: {
                type: Object,
                default: () => ({
                    filters: [],
                }),
            },
            /**
             * Prop to determine if the chart is being used from the dashboard
             * Set to false if the component is being used outside of it
             * @type {Boolean}
             * @default {true}
             */
            _dashboardMode: {
                type: Boolean,
                default: true,
            },

            /**
             * Command filter values sent from the RevenuesReport page to fill the getRevenuesReport fields
             * @type {Array}
             * @default {[]}
             */
            _filters: {
                type: Array,
                default: () => ([]),
            },
        },
        data: function () {
            return {
                dashdata: this._dashdata,
                categoryData: [],
                reportData: [],
                reportDataAux: {},
                colors: [],
                years: [],
                clients: [],
                byClients: 0,
                relevantClients: 2,
                chartType: 0,
                valueType: 1,
                filters: null,
                rules: {
                    counter: value => ((value <= 10) && value > 0) || this.$t('VALUE_TO_VALUE', { value1: 0, value2: 10 }),
                },
                // Chart instance, used for the on() event
                chart: undefined,

                // Default chart before it's loaded with data
                chartOptions: {
                    grid: {
                        containLabel: true,
                        left: '5%',
                    },
                    title: {
                        text: this.$t('REVENUES_REPORT'),
                    },
                    tooltip: {
                        trigger: 'axis',
                        confine: true,
                    },
                    xAxis: {
                        type: 'category',
                        name: this.timeDescription,
                    },
                    yAxis: {
                        type: 'value',
                        name: this.$t('VALUE'),
                    },
                },
            };
        },
        computed: {
            getRevenuesReport: function () {
                const command = new GetRevenuesReport();
                const values = {};
                values.grouper = 0;
                values.relevantClients = this.relevantClients;
                values.byClients = this.byClients;
                values.startDate = this.$moment().subtract(1, 'years').format('DD/MM/YYYY');
                values.endDate = this.$moment().format('DD/MM/YYYY');
                command.values = values;
                command.node = this.byClients ? 'FATURAMENTOS.CLIENTE' : 'FATURAMENTOS.FATURAMENTO';
                command.initializeLoading = false;
                command.onSuccess = this.processResolve;
                return command;
            },
            periodName: function () {
                switch (this.chartType) {
                    case 0:
                        return this.$t('MONTH');
                    case 1:
                        return this.$t('YEAR');
                    default:
                        return '';
                }
            },
            valueName: function () {
                switch (this.valueType) {
                    case 1:
                        return this.$t('GROSS_VALUE');
                    case 2:
                        return this.$t('LIQUID_VALUE');
                    default:
                        return '';
                }
            },
        },
        watch: {
            dashdata: {
                deep: true,
                handler: function (value) {
                    this.$emit('update-dashdata', value);
                },
            },
            _activePage: {
                handler: function () {
                    this.onResize();
                },
            },
            _filters: {
                immediate: true,
                handler: function (value) {
                    if (value) {
                        this.$util.loadCommandFilter(this.getRevenuesReport, value);
                    }
                },
            },
            filters: {
                deep: true,
                handler: function (val) {
                    this.$emit('update:_filters', val);
                },
            },
        },
        mounted: function () {
            this.load();
        },
        methods: {
            load: async function () {
                this.onResize();
                this.chart = this.$refs.chart.getChart();

                if (this.dashdata.filters.length !== 0) {
                    this.$util.loadCommandFilter(this.getRevenuesReport, this.dashdata.filters);
                    this.$refs.filter.refresh(true);
                }
            },
            loadData: async function (items) {
                try {
                    this.formatToChart(items);
                    this.loadChart();
                } catch (reject) {
                }
            },
            loadChart: function () {
                this.$refs.chart.reset();
                this.setChartOption();
                this.addSerie();
                this.addTooltip();
                this.onResize();
            },
            onResize: function () {
                this.$nextTick(this.$refs.chart.resize);
            },
            formatBarChart: function (data) {
                Object.keys(data).forEach((i) => {
                    const item = data[i];
                    item.name = item.MES;
                    item.value = this.valueType === 1 ? item.VALOR_TOTAL : item.VALOR_LIQUIDO;
                    if (!item.value) {
                        item.value = 0;
                    }
                    this.reportData.push(item);
                    this.categoryData.push(item.name);
                });
            },
            formatLineChart: function (data) {
                let years = [];
                this.colors = [];
                const valuesByYear = [];
                data.forEach(el => {
                    years.push(el.ANO_ATUAL);
                    this.years.push(el.ANO_ATUAL.toString());
                });
                this.years = [...new Set(this.years)];
                years = [...new Set(years)];
                years.forEach(el => {
                    valuesByYear.push(data.filter(value => value.ANO_ATUAL === el));
                    this.colors.push(this.$util.getRandomColor());
                });
                Object.keys(valuesByYear).forEach((i) => {
                    this.reportDataAux = [];
                    Object.keys(valuesByYear[i]).forEach((el) => {
                        const item = valuesByYear[i][el];
                        item.name = item.MES.slice(0, 2);
                        item.value = this.valueType === 1 ? item.VALOR_TOTAL : item.VALOR_LIQUIDO;
                        if (!item.value) {
                            item.value = 0;
                        }
                        this.reportDataAux[el] = item;
                        this.categoryData.push(item.name);
                    });
                    this.reportData[i] = Object.values(this.reportDataAux);
                    this.categoryData = [...new Set(this.categoryData)];
                });
            },
            formatByClients: function (data) {
                const clients = [];
                this.colors = [];
                data.forEach(el => {
                    clients.push(el.NOME_CLIENTE);
                    this.clients.push(el.NOME_CLIENTE);
                });
                data.forEach((client, i) => {
                    this.reportDataAux = [];
                    this.colors.push(this.$util.getRandomColor());
                    client.FATURAMENTO.forEach((revenues, index) => {
                        const item = revenues;
                        item.name = item.MES;
                        item.value = this.valueType === 1 ? item.VALOR_TOTAL : item.VALOR_LIQUIDO;
                        if (!item.value) {
                            item.value = 0;
                        }
                        this.reportDataAux[index] = item;
                        this.categoryData.push(item.name);
                    });
                    this.reportData[i] = Object.values(this.reportDataAux);
                    this.categoryData = [...new Set(this.categoryData)];
                });
            },
            formatToChart: function (data) {
                this.reportData = [];
                this.categoryData = [];
                if (this.chartType === 0 && !this.byClients) {
                    this.formatBarChart(data);
                }
                if (this.chartType === 1 && !this.byClients) {
                    this.formatLineChart(data);
                }
                if (!this.chartType && this.byClients) {
                    this.formatByClients(data);
                }
            },
            addSerie: function () {
                if (this.chartType === 0 && !this.byClients) {
                    this.$refs.chart.addSerie({
                        id: 1,
                        type: 'bar',
                        data: this.reportData,

                        animation: true,
                        left: 'right',
                        top: 'middle',

                        barWidth: '50%',
                    });
                }
                if (this.chartType === 1 && !this.byClients) {
                    this.reportData.forEach((el, i) => {
                        this.$refs.chart.addSerie({
                            id: i + 1,
                            name: this.years[i],
                            type: 'line',
                            data: el,
                            color: this.colors[i],

                            animation: true,
                            left: 'right',
                            top: 'middle',
                        });
                    });
                }
                if (!this.chartType && this.byClients) {
                    this.reportData.forEach((el, i) => {
                        this.$refs.chart.addSerie({
                            id: i + 1,
                            name: this.clients[i],
                            type: 'bar',
                            data: el,
                            color: this.colors[i],

                            animation: true,
                            left: 'right',
                            top: 'middle',
                        });
                    });
                }
            },
            processResolve: function (resolve) {
                this.filters = this.$util.getFiltersByCommand(this.getRevenuesReport);
                return resolve;
            },
            setChartOption: function () {
                const chartOptions = {
                    maintainAspectRatio: false,
                    grid: {
                        containLabel: true,
                        bottom: '20',
                    },
                    legend: {
                        orient: 'vertical',
                        top: 'middle',
                        bottom: 10,
                        left: 'left',
                        data: this.byClients ? this.clients : this.years,
                    },
                    xAxis: {
                        type: 'category',
                        name: this.periodName,
                        data: this.categoryData,
                        axisLabel: {
                            show: true,
                            position: 'bottom',
                            formatter: '{value}',
                            color: '{value}',
                        },
                    },
                    yAxis: {
                        type: 'value',
                        name: this.$t('VALUE'),
                        axisLabel: {
                            formatter: '{value}',
                        },
                    },
                };

                this.$refs.chart.setOption(chartOptions);
            },
            updateDashData: function (items) {
                // if the page is being used by the dashboard, update the filters
                if (this._dashboardMode) {
                    this.dashdata.filters = this.$util.getFiltersByCommand(this.getRevenuesReport);
                }
                this.loadData(items);
            },
            addTooltip: function () {
                const tooltip = {
                    trigger: 'axis',
                    confine: true,
                };
                this.$refs.chart.setTooltip(tooltip);
            },
        },
    };
</script>
