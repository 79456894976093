import i18n from '@/i18n';

export default function issueStatus (provider) {
    return new Promise((resolve) => {
        resolve([
            {
                ID_SUB_OBRA: '-1',
                DESC_SUB_OBRA: i18n.t('ALL'),
            },
            ...provider,
        ]);
    });
}
