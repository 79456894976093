import Toastr from 'toastr';
import getArray from '../util/helper/getArray';
import store from '@/store/store';

const notification = {
    options: {
        closeButton: true,
        newestOnTop: false,
        progressBar: true,
        positionClass: 'toast-bottom-left',
        preventDuplicates: false,
        onclick: null,
        showDuration: '300',
        hideDuration: '1000',
        timeOut: '5000',
        extendedTimeOut: '1000',
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
    },
    batch: {
        warningMessage: null, 
        errorMessage: null,
        successMessage: null
    },
    errors: [],
    success: [],
    warning: [],
    maxBatchMessage: 10,
    page: null,
    dialogPage: null,

    initializeOptions: function(options) {
        this.options = {
            ...this.options,
            ...options
        };
        Toastr.options = this.options;
    },

    infoMessage: function(message, title = '', options) {
        Toastr.info(message, title, options);
    },

    successMessage: function(message, title = '', options) {
        Toastr.success(message, title, options);
    },

    warningMessage: function(message, title = '', options) {
        Toastr.warning(message, title, options);
    },

    errorMessage: function(message, title = '', options) {
        Toastr.error(message, title, options);
    },

    showNotifications: function(notifications) {
        this.setMessages(notifications);
        this.showErrorNotifications();
        this.showWarningNotifications();
        this.showSuccessNotifications();
    },

    remove: function() {
        Toastr.remove();
    },

    clear: function() {
        Toastr.clear();
    },

    showSuccessNotifications: function() {
        const success = this.getSuccess();
        if (success) {
            if (success && success.length >= 1) {
                this.suppressMessages(success, 'successMessage', {
                    timeOut: 4000,
                    extendedTimeOut: 3000,
                    newestOnTop: true,
                    preventDuplicates: false,
                });
            }
        }
    },

    showErrorNotifications: function() {
        const error = this.getErrors();
        if (error) {
            if (error && error.length >= 1) {
                this.suppressMessages(error, 'errorMessage', {
                    timeOut: 5000,
                    extendedTimeOut: 5000,
                    newestOnTop: true,
                    preventDuplicates: false,
                });
            }
        }
    },

    showWarningNotifications: function() {
        const warning = this.getWarning();
        if (warning) {
            if (warning && warning.length >= 1) {
                this.suppressMessages(warning, 'warningMessage', {
                    timeOut: 4000,
                    extendedTimeOut: 2000,
                    newestOnTop: true,
                    preventDuplicates: false,
                });
            }
        }
    },

    removeDuplicates: function(arr) {
        return arr.filter((item, index) => arr.indexOf(item) === index);
    },

    suppressMessages: function(messages, messageType, options) {
        this.page = store.getters.getCurrentPage;
        this.dialogPage = store.getters.getCurrentDialog;
        const showMessage = this[messageType];

        let comeco = 0;
        let fimBatch = this.batch[messageType];

        while (comeco < fimBatch && comeco < messages.length) {
            showMessage(messages[comeco], '', options);
            comeco++;
        }

        const id = setInterval(() => {
            const actualPage = store.getters.getCurrentPage;
            comeco = fimBatch;
            fimBatch = comeco + 5;
            do {
                if (messages[comeco] && messages[comeco] !== '') {
                    showMessage(messages[comeco], '', options);
                }
                comeco++;
            } while (comeco < fimBatch && comeco < messages.length);

            if (this.page !== actualPage) {
                this.page = null;
                this.dialogPage = null;
                clearInterval(id);
            }

            if (comeco >= messages.length) {
                clearInterval(id);
            }
        }, 2000);
    },

    setMessages: function(notifications) {
        if (notifications.ERRORS) {
            this.setErrors(notifications.ERRORS);
        }
        if (notifications.WARNINGS) {
            this.setWarning(notifications.WARNINGS);
        }
        if (notifications.INFORMATIONS) {
            this.setSuccess(notifications.INFORMATIONS);
        }

        this.setMaxBatch();
    },

    setErrors: function(messages) {
        this.errors = this.removeDuplicates(getArray(messages));
    },

    getErrors: function() {
        return this.errors;
    },
    setSuccess: function(messages) {
        this.success = this.removeDuplicates(getArray(messages));
    },

    getSuccess: function() {
        return this.success;
    },

    setWarning: function(messages) {
        this.warning = this.removeDuplicates(getArray(messages));
    },

    getWarning: function() {
        return this.warning;
    },
    setMaxBatch: function () {
        const errors = this.errors;
        const warnings = this.warning;

        this.batch.errorMessage = this.maxBatchMessage;
        this.batch.warningMessage = this.maxBatchMessage - errors.length;
        this.batch.successMessage =  this.batch.warningMessage - warnings.length;
    },
};

export default notification;