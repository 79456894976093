const paths = [
    {
        path: '/material-list',
        name: 'MaterialList',
        title: 'MATERIAL_LIST',
        icon: 'widgets',
        menu: 'stock',
        component: () => import(/* webpackChunkName: "material" */ /* webpackMode: "lazy" */ '@/pages/entries/material/MaterialList.vue'),
    },
    {
        path: '/material',
        name: 'Material',
        title: 'MATERIAL',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "material" */ /* webpackMode: "lazy" */ '@/pages/entries/material/Material.vue'),
    },
    {
        path: '/material-general',
        title: 'MaterialGeneral',
        name: 'MaterialGeneral',
        component: () => import(/* webpackChunkName: "material" */ /* webpackMode: "lazy" */ '@/pages/entries/material/MaterialDataGeneral.vue'),
    },
    {
        path: '/item-register-wizard',
        title: 'ItemRegisterWizard',
        name: 'ItemRegisterWizard',
        component: () => import(/* webpackChunkName: "material" */ /* webpackMode: "lazy" */ '@/pages/entries/general/ItemRegisterWizard.vue'),
    },
    {
        path: '/material-manufacturer-general',
        title: 'MaterialManufacturerGeneral',
        name: 'MaterialManufacturerGeneral',
        component: () => import(/* webpackChunkName: "material" */ /* webpackMode: "lazy" */ '@/pages/entries/material/MaterialManufacturerGeneral.vue'),
    },
    {
        path: '/material-warranty-lot-general',
        title: 'MaterialWarrantyLotGeneral',
        name: 'MaterialWarrantyLotGeneral',
        component: () => import(/* webpackChunkName: "material" */ /* webpackMode: "lazy" */ '@/pages/entries/material/MaterialWarrantyLotGeneral.vue'),
    },
    {
        path: '/item-bulletin-execution-wizzard',
        title: 'ITEM_BULLETIN_EXECUTION_WIZZARD',
        name: 'ItemBulletinExecutionWizzard',
        component: () => import(/* webpackChunkName: "service" */ /* webpackMode: "lazy" */ '@/pages/entries/service/ItemBulletinExecutionWizzard.vue'),
    },
    {
        path: '/material-delete',
        title: 'MaterialDelete',
        name: 'MaterialDelete',
        component: () => import(/* webpackChunkName: "material" */ /* webpackMode: "lazy" */ '@/pages/entries/material/MaterialDelete.vue'),
    },
    {
        path: '/material-equivalents',
        title: 'MATERIAL_EQUIVALENTS',
        name: 'MaterialEquivalents',
        component: () => import(/* webpackChunkName: "material" */ /* webpackMode: "lazy" */ '@/pages/entries/material/MaterialEquivalents.vue'),
    },
    {
        path: '/material-manufacturer',
        title: 'MANUFACTURER_LIST',
        name: 'MaterialManufacturer',
        component: () => import(/* webpackChunkName: "material" */ /* webpackMode: "lazy" */ '@/pages/entries/material/MaterialManufacturer.vue'),
    },
    {
        path: '/material-warranty-lot-qr-code',
        title: 'MATERIAL_WARRANTY_LOT_QR_CODE',
        name: 'MaterialWarrantyLotQRCode',
        component: () => import(/* webpackChunkName: "material" */ /* webpackMode: "lazy" */ '@/pages/entries/material/MaterialWarrantyLotQRCode.vue'),
    },
    {
        path: '/material-clone',
        title: 'CLONE_MATERIAL',
        name: 'MaterialClone',
        component: () => import(/* webpackChunkName: "material" */ /* webpackMode: "lazy" */ '@/pages/entries/material/MaterialClone.vue'),
    },
];

export default paths;
