import Vue from 'vue';
import i18n from '@/i18n';

export default function getLocale () {
    const locate = {};

    Vue.prototype.$lodash.set(locate, 'draw.toolbar.actions.title', i18n.t('DRAW_TOOLBAR_ACTIONS_TITLE'));
    Vue.prototype.$lodash.set(locate, 'draw.toolbar.actions.text', i18n.t('DRAW_TOOLBAR_ACTIONS_TEXT'));
    Vue.prototype.$lodash.set(locate, 'draw.toolbar.finish.title', i18n.t('DRAW_TOOLBAR_FINISH_TITLE'));
    Vue.prototype.$lodash.set(locate, 'draw.toolbar.finish.text', i18n.t('DRAW_TOOLBAR_FINISH_TEXT'));
    Vue.prototype.$lodash.set(locate, 'draw.toolbar.undo.title', i18n.t('DRAW_TOOLBAR_UNDO_TITLE'));
    Vue.prototype.$lodash.set(locate, 'draw.toolbar.undo.text', i18n.t('DRAW_TOOLBAR_UNDO_TEXT'));
    Vue.prototype.$lodash.set(locate, 'draw.toolbar.buttons.polyline', i18n.t('DRAW_TOOLBAR_BUTTONS_POLYLINE'));
    Vue.prototype.$lodash.set(locate, 'draw.toolbar.buttons.polygon', i18n.t('DRAW_TOOLBAR_BUTTONS_POLYGON'));
    Vue.prototype.$lodash.set(locate, 'draw.toolbar.buttons.rectangle', i18n.t('DRAW_TOOLBAR_BUTTONS_RECTANGLE'));
    Vue.prototype.$lodash.set(locate, 'draw.toolbar.buttons.circle', i18n.t('DRAW_TOOLBAR_BUTTONS_CIRCLE'));
    Vue.prototype.$lodash.set(locate, 'draw.toolbar.buttons.marker', i18n.t('DRAW_TOOLBAR_BUTTONS_MARKER'));
    Vue.prototype.$lodash.set(locate, 'draw.toolbar.buttons.circlemarker', i18n.t('DRAW_TOOLBAR_BUTTONS_CIRCLEMARKER'));
    Vue.prototype.$lodash.set(locate, 'draw.handlers.circle.tooltip.start', i18n.t('DRAW_HANDLERS_CIRCLE_TOOLTIP_START'));
    Vue.prototype.$lodash.set(locate, 'draw.handlers.circle.radius', i18n.t('DRAW_HANDLERS_CIRCLE_RADIUS'));
    Vue.prototype.$lodash.set(locate, 'draw.handlers.circlemarker.tooltip.start', i18n.t('DRAW_HANDLERS_CIRCLEMARKER_TOOLTIP_START'));
    Vue.prototype.$lodash.set(locate, 'draw.handlers.marker.tooltip.start', i18n.t('DRAW_HANDLERS_MARKER_TOOLTIP_START'));
    Vue.prototype.$lodash.set(locate, 'draw.handlers.polygon.tooltip.start', i18n.t('DRAW_HANDLERS_POLYGON_TOOLTIP_START'));
    Vue.prototype.$lodash.set(locate, 'draw.handlers.polygon.tooltip.cont', i18n.t('DRAW_HANDLERS_POLYGON_TOOLTIP_CONT'));
    Vue.prototype.$lodash.set(locate, 'draw.handlers.polygon.tooltip.end', i18n.t('DRAW_HANDLERS_POLYGON_TOOLTIP_END'));
    Vue.prototype.$lodash.set(locate, 'draw.handlers.polyline.error', i18n.t('DRAW_HANDLERS_POLYLINE_ERROR'));
    Vue.prototype.$lodash.set(locate, 'draw.handlers.polyline.tooltip.start', i18n.t('DRAW_HANDLERS_POLYLINE_TOOLTIP_START'));
    Vue.prototype.$lodash.set(locate, 'draw.handlers.polyline.tooltip.cont', i18n.t('DRAW_HANDLERS_POLYLINE_TOOLTIP_CONT'));
    Vue.prototype.$lodash.set(locate, 'draw.handlers.polyline.tooltip.end', i18n.t('DRAW_HANDLERS_POLYLINE_TOOLTIP_END'));
    Vue.prototype.$lodash.set(locate, 'draw.handlers.rectangle.tooltip.start', i18n.t('DRAW_HANDLERS_RECTANGLE_TOOLTIP_START'));
    Vue.prototype.$lodash.set(locate, 'draw.handlers.simpleshape.tooltip.end', i18n.t('DRAW_HANDLERS_SIMPLESHAPE_TOOLTIP_END'));
    Vue.prototype.$lodash.set(locate, 'edit.toolbar.actions.save.title', i18n.t('EDIT_TOOLBAR_ACTIONS_SAVE_TITLE'));
    Vue.prototype.$lodash.set(locate, 'edit.toolbar.actions.save.text', i18n.t('EDIT_TOOLBAR_ACTIONS_SAVE_TEXT'));
    Vue.prototype.$lodash.set(locate, 'edit.toolbar.actions.cancel.title', i18n.t('EDIT_TOOLBAR_ACTIONS_CANCEL_TITLE'));
    Vue.prototype.$lodash.set(locate, 'edit.toolbar.actions.cancel.text', i18n.t('EDIT_TOOLBAR_ACTIONS_CANCEL_TEXT'));
    Vue.prototype.$lodash.set(locate, 'edit.toolbar.actions.clearAll.title', i18n.t('EDIT_TOOLBAR_ACTIONS_CLEARALL_TITLE'));
    Vue.prototype.$lodash.set(locate, 'edit.toolbar.actions.clearAll.text', i18n.t('EDIT_TOOLBAR_ACTIONS_CLEARALL_TEXT'));
    Vue.prototype.$lodash.set(locate, 'edit.toolbar.buttons.edit', i18n.t('EDIT_TOOLBAR_BUTTONS_EDIT'));
    Vue.prototype.$lodash.set(locate, 'edit.toolbar.buttons.editDisabled', i18n.t('EDIT_TOOLBAR_BUTTONS_EDITDISABLED'));
    Vue.prototype.$lodash.set(locate, 'edit.toolbar.buttons.remove', i18n.t('EDIT_TOOLBAR_BUTTONS_REMOVE'));
    Vue.prototype.$lodash.set(locate, 'edit.toolbar.buttons.removeDisabled', i18n.t('EDIT_TOOLBAR_BUTTONS_REMOVEDISABLED'));
    Vue.prototype.$lodash.set(locate, 'edit.handlers.edit.tooltip.text', i18n.t('EDIT_HANDLERS_EDIT_TOOLTIP_TEXT'));
    Vue.prototype.$lodash.set(locate, 'edit.handlers.edit.tooltip.subtext', i18n.t('EDIT_HANDLERS_EDIT_TOOLTIP_SUBTEXT'));
    Vue.prototype.$lodash.set(locate, 'edit.handlers.remove.tooltip.text', i18n.t('EDIT_HANDLERS_REMOVE_TOOLTIP_TEXT'));

    return locate;
}
