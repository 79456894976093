<template>
    <a-card :dark='dark' :height='height' class='card-viewer d-flex flex-column'>
        <a-card-actions class='pb-0 flex-grow-0'>
            <a-toolbar v-show='loaded' dense flat>
                <a-app-bar-nav-icon v-if='!hideSideBar' @click='sidebar = !sidebar' />
                <a-toolbar-title>
                    <a-row align='center' justify='center' dense>
                        <a-col class='pr-3 flex-shrink-1'>
                            <a-edit-dialog>
                                <a-autocomplete hide-details v-model='page' :items='getPages' style='width: 70px; padding: 0' />
                            </a-edit-dialog>
                        </a-col>/
                        <a-col class='pl-3 pr-2 flex-shrink-1'>{{numPages}}</a-col>
                        <a-btn text icon small :disabled='page <= 1' class='ml-2 mr-1' @click='page--'>
                            <a-icon small>fa fa-chevron-left</a-icon>
                        </a-btn>
                        <a-btn text icon small :disabled='page >= numPages' class='ml-1 mr-2' @click='page++'>
                            <a-icon small>fa fa-chevron-right</a-icon>
                        </a-btn>
                        <a-btn text icon small class='ml-2 mr-1' @click='rotate -= 90'>
                            <a-icon small>fa fa-redo-alt fa-flip-horizontal</a-icon>
                        </a-btn>
                        <a-btn text icon small class='ml-1 mr-2' @click='rotate += 90'>
                            <a-icon small>fa fa-redo-alt</a-icon>
                        </a-btn>
                        <a-btn text icon small :disabled='scaleIndex >= (scales.length - 1)' class='ml-2 mr-1' @click='zoomUp'>
                            <a-icon small>fa fa-search-plus</a-icon>
                        </a-btn>
                        <a-btn text icon small :disabled='scaleIndex <= 0' class='ml-1 mr-2'  @click='zoomDown'>
                            <a-icon small>fa fa-search-minus</a-icon>
                        </a-btn>
                    </a-row>
                </a-toolbar-title>
                <a-spacer></a-spacer>
                <a-btn text icon small @click='download'>
                    <a-icon small>fa fa-download</a-icon>
                </a-btn>
            </a-toolbar>
        </a-card-actions>
        <a-card-text style='position: relative' class='pa-1 flex-grow-1 overflow-auto'>
            <a-row align='center' justify='center' class='absolute-content' v-show='!loaded'>
                <a-col cols='auto' class='font-weight-bold text-h5'>{{$t('LOADING_')}}</a-col>
            </a-row>
            <a-row align='center' justify='center' class='absolute-content' v-show='loaded'>
                <a-col v-if='sidebar && !hideSideBar' :style='{ "max-height": `calc(${height} - 70px)` }' class='sidebar overflow-y-auto'>
                    <a-row class='flex-column'>
                        <a-row v-for='index in numPages' :key='index'>
                            <a-card class='click-page' @click='setPage(index)'>
                                <a-row align='center' justify='center'>
                                    <pdf class='pdf-viewer' :src='src' :page='index' />
                                </a-row>
                                <a-card-text class='text-xs-center pa-1'>{{index}}</a-card-text>
                            </a-card>
                        </a-row>
                    </a-row>
                </a-col>
                <pdf :style='{ width: `${scales[scaleIndex]}%` }' class='pdf-viewer' ref='pdf' :src='src' :page='page' :rotate='rotate' @progress='loaded = false' @loaded='loaded = true' @num-pages='numPages = $event' />
            </a-row>
        </a-card-text>
    </a-card>
</template>

<script>
    import pdf from 'vue-pdf';

    export default {
        name: 'APdfViewer',
        components: {
            pdf: pdf,
        },
        props: {
            src: {
                type: [Object, String],
                require: true,
                default: undefined,
            },
            dark: {
                type: Boolean,
                require: true,
                default: false,
            },
            height: {
                type: String,
                default: '100%',
            },
            hideSideBar: {
                type: Boolean,
                default: false,
            },
        },
        data: function () {
            return {
                loaded: false,
                sidebar: false,
                page: 1,
                rotate: 0,
                scaleIndex: 2,
                scales: [25, 50, 75, 100],
                numPages: '',
            };
        },
        computed: {
            getPages: function () {
                const items = [];
                for (let i = 0; i < parseInt(this.numPages, 10); i++) {
                    items.push(i + 1);
                }

                return items;
            },
        },
        methods: {
            setPage: function (value) {
                this.page = this.validInteger(value);
            },
            validInteger: function (value) {
                const number = Number(value);
                if (number) {
                    if (number > this.numPages) return this.numPages;
                    if (number < 1) return 1;
                    return number - (number % 1);
                }
                return '';
            },
            download: function () {
                if (typeof this.src === 'string') {
                    const link = document.createElement('a');
                    link.href = this.src;
                    link.target = '_blank';
                    link.download = 'file.pdf';
                    link.click();
                }
            },
            zoomUp: function () {
                if (this.scaleIndex < (this.scales.length - 1)) {
                    this.scaleIndex++;
                }
            },
            zoomDown: function () {
                if (this.scaleIndex > 0) {
                    this.scaleIndex--;
                }
            },
        },
    };
</script>

<style lang='scss' scoped>
    ::v-deep.wd-100 {
        width: 100%;
        height: 100%;
    }
    .card-viewer {
        border-radius: 0 !important;
    }
    ::v-deep.pdf-viewer {
        padding: 1%;
    }
    .click-page:hover {
        cursor: pointer;
    }
    .sidebar {
        width: 300px;
        overflow-y: auto;
        ::-webkit-scrollbar {
            width: 3px;
            background-color: #f8f8f8;
        }
        ::-webkit-scrollbar-thumb {
            background-color: #c6c6c6;
        }
    }
    .absolute-content {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
</style>
