<template>
    <chart
        :_options='chartOptions'
        :_canvas-width='_width'
        :_canvas-height='_height'
    />
</template>

<script>
    import chart from '@/components/charts/Chart';
    import consts from './services/consts';

    export default {
        name: 'LightProfileChart',
        components: {
            chart: chart,
        },
        props: {
            _height: {
                type: [String, Number],
                required: false,
                default: null,
            },
            _width: {
                type: [String, Number],
                required: false,
                default: null,
            },
            _chartData: {
                type: Array,
                required: true,
                default: null,
            },
            _chartLegend: {
                type: Array,
                required: false,
                default: null,
            },
        },
        data: function () {
            return {
                colors: consts.CHART_COLORS,
                chartOptions: {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'none',
                        },
                    },
                    legend: {
                        data: this._chartLegend,
                        show: !!this._chartLegend || false,
                    },
                    grid: {
                        show: false,
                        top: '10%',
                        left: '-19px',
                        right: '-19px',
                        bottom: '0',
                        containLabel: false,
                    },
                    xAxis: {
                        type: 'category',
                        show: false,
                    },
                    yAxis: {
                        type: 'value',
                        show: false,
                    },
                    series: [],
                },
            };
        },
        mounted: function () {
            this.load();
        },
        methods: {
            load: function () {
                this.generateSeries();
            },
            generateSeries: function () {
                this._chartData.map((item, itemIndex) => {
                    if (item.ignore) {
                        return 1;
                    }
                    const chartItem = {
                        name: 'Direct',
                        stack: 'LampProfileChart',
                        color: this.getLineColor(item, itemIndex),
                        lineStyle: {
                            width: consts.CHART_LINE_WIDTH,
                        },
                        areaStyle: item.active ? {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: this.getLineColor(item, itemIndex),
                                }, {
                                    offset: 1, color: '#ffffff00',
                                }],
                                global: false,
                            },
                        } : null,
                        symbol: 'none',
                        smooth: true,
                        type: 'line',
                        label: {
                            show: false,
                        },
                        emphasis: {
                            focus: 'series',
                        },
                        data: item.data,
                    };

                    this.chartOptions.series.push(chartItem);
                    return 0;
                });
            },
            getLineColor: function (item, i) {
                let color = null;
                color = item.color ? item.color : consts.CHART_COLORS[i % consts.CHART_COLORS.length];

                if (!item.active) {
                    return `${color.slice(0, 7)}33`;
                }
                return color;
            },
        },
    };
</script>
