import store from '../store/store';

export const PageFunctionsMixin = {
    data: function () {
        return {
            mixinPageName: null,
        };
    },
    created: function () {
        if (this.$store && this.$store.getters) {
            this.mixinPageName = this.$store.getters.getCurrentPage;
        }
    },
    methods: {
        mixinSetPageName: function (title) {
            store.dispatch('SET_PAGE_NAME', { newTitle: title, name: this.mixinPageName });
        },
    },
};
