const paths = [
    {
        path: '/material-lifespan-list',
        title: 'MATERIALS_LIFESPAN',
        name: 'MaterialLifespanList',
        menu: 'stock',
        categories: [
          'reports',
        ],
        component: () => import(/* webpackChunkName: "materialLifespan" */ /* webpackMode: "lazy" */ '@/pages/materialLifespan/MaterialLifespanList.vue'),
    },
];

export default paths;
