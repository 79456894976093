const paths = [
    {
        path: '/issue-origin-type-list',
        name: 'IssueOriginTypeList',
        title: 'ISSUE_ORIGIN_TYPES',
        icon: 'widgets',
        menu: 'ticket',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "issueOriginType" */ /* webpackMode: "lazy" */ '@/pages/issueOriginType/IssueOriginTypeList.vue'),
    },
    {
        path: '/issue-origin-type-general',
        name: 'IssueOriginTypeGeneral',
        title: 'ISSUE_ORIGIN_TYPE',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "issueOriginType" */ /* webpackMode: "lazy" */ '@/pages/issueOriginType/IssueOriginTypeGeneral.vue'),
    },
];

export default paths;
