const paths = [
    {
        path: '/allocation-history',
        title: 'ALLOCATION_HISTORY',
        name: 'AllocationHistory',
        icon: 'mdi-file-document',
        menu: 'remoteManagement',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/allocation/AllocationHistory.vue'),
    },
    {
        path: '/allocation-history-device',
        name: 'AllocationHistoryDevice',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/allocation/AllocationHistoryDevice.vue'),
    },
    {
        path: '/lamp-operation-time',
        title: 'LAMP_OPERATION_TIME',
        name: 'LampOperationTime',
        icon: 'mdi-file-document',
        menu: 'remoteManagement',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/lampReports/LampOperationTime.vue'),
    },
    {
        path: '/company-creation',
        title: 'CREATE_COMPANY',
        name: 'CompanyCreation',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/company/CompanyCreation.vue'),
    },
    {
        path: '/company-edit',
        title: 'COMPANY',
        name: 'Company',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/company/Company.vue'),
    },
    {
        path: '/company-management',
        title: 'COMPANY_MANAGEMENT',
        name: 'CompanyManagement',
        icon: 'domain',
        menu: 'remoteManagement',
        allowed: 'admin',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/company/CompanyManagement.vue'),
    },
    {
        path: '/command-uri-picker',
        title: 'NEW_COMMAND_URI',
        name: 'CommandUriPicker',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/company/CommandUriPicker.vue'),
    },
    {
        path: '/command-uri-edit',
        title: 'EDIT',
        name: 'CommandUriEdit',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/company/CommandUriEdit.vue'),
    },
    {
        path: '/defect-reasons',
        title: 'DEFECT_REASONS',
        name: 'DefectReasons',
        icon: 'widgets',
        menu: 'remoteManagement',
        allowed: 'admin',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/defect/DefectReasons.vue'),
    },
    {
        path: '/manage-template',
        title: 'MANAGE_TEMPLATE',
        name: 'ManageTemplate',
        icon: 'mdi-wrench',
        menu: 'remoteManagement',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/manageTemplates/ManageTemplates.vue'),
    },
    {
        path: '/create-br',
        title: 'CREATE_BR',
        name: 'CreateBr',
        icon: 'mdi-newspaper-plus',
        menu: 'remoteManagement',
        allowed: 'admin',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/createBr/CreateBr.vue'),
    },
    {
        path: '/view-template',
        title: 'TEMPLATE',
        name: 'ViewTemplate',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/manageTemplates/ViewTemplate.vue'),
    },
    {
        path: '/device-list',
        title: 'DEVICES',
        name: 'DeviceList',
        icon: 'settings_remote',
        menu: 'remoteManagement',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/device-list/DeviceList.vue'),
    },
    {
        path: '/device-details',
        title: 'DEVICE_DETAILS',
        name: 'DeviceDetails',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/device-details/Details.vue'),
    },
    {
        path: '/device-template',
        title: 'DEVICE_TYPE',
        name: 'DeviceTemplate',
        icon: 'view_compact',
        menu: 'remoteManagement',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/template/TemplateList.vue'),
    },
    {
        path: '/device-template-single',
        title: 'DEVICE_TEMPLATE',
        name: 'DeviceTemplateSingle',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/template/single/Single.vue'),
    },
    {
        path: '/alert-type',
        title: 'DEVICE_ALERT_TYPE',
        name: 'AlertType',
        icon: 'warning',
        menu: 'remoteManagement',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/alertType/AlertType'),
    },
    {
        path: '/data-export',
        title: 'EXPORT_TELEMANAGEMENT_DATA',
        name: 'DataExport',
        icon: 'fas fa-file-excel',
        menu: 'remoteManagement',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/data-export/DataExport'),
    },
    {
        path: '/device-schedule',
        title: 'DEVICE_SCHEDULES',
        name: 'DeviceSchedule',
        icon: 'event',
        menu: 'remoteManagement',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/schedule/ScheduleList'),
    },
    {
        path: '/device-schedule-single',
        title: 'DEVICE_SCHEDULE',
        icon: 'schedule',
        name: 'DeviceScheduleSingle',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/schedule/ScheduleSingle'),
    },
    {
        path: '/device-measurement-config',
        title: 'DEVICE_MEASUREMENT_CONFIGS',
        icon: 'mdi-chart-line',
        menu: 'remoteManagement',
        name: 'DeviceMeasurementConfig',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/measurementConfig/list/List.vue'),
    },
    {
        path: '/device-measurement-config-single',
        title: 'DEVICE_MEASUREMENT_CONFIG',
        name: 'DeviceMeasurementConfigSingle',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/measurementConfig/single/Single.vue'),
    },
    {
        path: '/device-billing-report',
        title: 'DEVICE_BILLING_REPORT',
        icon: 'mdi-clipboard',
        menu: 'remoteManagement',
        name: 'DeviceBillingReport',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/billingReport/BillingReport.vue'),
    },
    {
        path: '/device-billing-estimation',
        title: 'DEVICE_BILLING_ESTIMATION_REPORT',
        icon: 'mdi-clipboard',
        menu: 'remoteManagement',
        name: 'DeviceBillingEstimation',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/billingEstimation/BillingEstimation.vue'),
    },
    {
        path: '/device-groups',
        title: 'DEVICE_GROUP_LIST',
        name: 'DeviceGroup',
        icon: 'group_work',
        menu: 'remoteManagement',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/deviceGroup/GroupList.vue'),
    },
    {
        path: '/device-transfer',
        title: 'DEVICE_TRANSFER',
        name: 'DeviceTransfer',
        icon: 'double_arrow',
        menu: 'remoteManagement',
        allowed: 'admin',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/companyDevice/DeviceTransfer.vue'),
    },
    {
        path: '/confirm-transfer',
        title: 'CONFIRM_TRANSFER',
        name: 'ConfirmTransfer',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/companyDevice/ConfirmTransfer.vue'),
    },
    {
        path: '/device-fixtures-list',
        title: 'FIXTURE_LIST',
        name: 'FixtureList',
        icon: 'mdi-flask',
        menu: 'remoteManagement',
        allowed: 'factory',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/fixture/FixtureList.vue'),
    },
    {
        path: '/device-fixtures-current-testing',
        title: 'FIXTURE_CURRENT_TESTING',
        name: 'FixtureCurrentTesting',
        icon: 'mdi-flask',
        menu: 'remoteManagement',
        allowed: 'factory',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/fixture/FixtureCurrentTesting.vue'),
    },
    {
        path: '/board-list',
        title: 'BOARD_LIST',
        name: 'BoardList',
        icon: 'developer_board',
        menu: 'remoteManagement',
        allowed: 'factory',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/board/BoardList.vue'),
    },
    {
        path: '/board-single',
        title: 'BOARD',
        name: 'BoardSingle',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/board/BoardSingle.vue'),
    },
    {
        path: '/functioning-history-report',
        title: 'FUNCTIONING_HISTORY_REPORT',
        icon: 'mdi-file-document',
        menu: 'remoteManagement',
        name: 'FunctioningHistoryReport',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/functioning/FunctioningHistoryReport.vue'),
    },
    {
        path: '/functioning-history-report-return-device',
        title: 'FUNCTIONING_HISTORY_REPORT_RETURN_DEVICE',
        name: 'FunctioningHistoryReportReturnDevice',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/functioning/FunctioningHistoryReportReturnDevice.vue'),
    },
    {
        path: '/exati-manufacture',
        title: 'EXATI_MANUFACTURE',
        name: 'ExatiManufacture',
        icon: 'mdi-factory',
        menu: 'remoteManagement',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/exatiManufacture/ExatiManufacture.vue'),
    },
    {
        path: '/state-change',
        name: 'StateChange',
        title: 'CHANGE_DEVICE_STATE',
        component: () => import(/* webpackChunkName: "assets" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ '@/pages/remoteManagement/deviceState/StateChange.vue'),
    },
    {
        path: '/user-management',
        title: 'USER_MANAGEMENT',
        icon: 'person',
        menu: 'remoteManagement',
        name: 'UserManagement',
        allowed: 'admin',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/user/UserManagement.vue'),
    },
    {
        path: '/user-creation',
        title: 'USER_CREATION',
        name: 'UserCreation',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/user/UserCreation.vue'),
    },
    {
        path: '/user',
        title: 'USER',
        name: 'User',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/user/User.vue'),
    },
    {
        path: '/webhook-client-list',
        title: 'WEBHOOK_CLIENTS',
        icon: 'mdi-webhook',
        menu: 'remoteManagement',
        name: 'WebhookClientList',
        allowed: 'admin',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/webhookClients/ClientList.vue'),
    },
    {
        path: '/webhook-client-app-list',
        title: 'WEBHOOKS_CLIENTS',
        name: 'WebhookClientAppList',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/webhookClients/AppList.vue'),
    },
    {
        path: '/remote-management-alert-type-list',
        title: 'REMOTE_MANAGEMENT_TYPE_ALERTS',
        name: 'RemoteManagementAlertTypeList',
        icon: 'fa fa-link',
        menu: 'remoteManagement',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/alerts/RemoteManagementAlertTypeList.vue'),
    },
    {
        path: '/remote-management-alert-type',
        title: 'REMOTE_MANAGEMENT_TYPE_ALERTS',
        name: 'RemoteManagementAlertType',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/alerts/RemoteManagementAlertType.vue'),
    },
    {
        path: '/role-management',
        title: 'ROLE_MANAGEMENT',
        name: 'RoleManagement',
        icon: 'lock',
        menu: 'remoteManagement',
        allowed: 'admin',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/role/RoleManagement.vue'),
    },
    {
        path: '/template-value-attributes-dialog',
        title: 'ATTRIBUTES',
        name: 'TemplateValueAttributesDialog',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/allocation/TemplateValueAttributesDialog.vue'),
    },
    {
        path: '/webhook-list',
        title: 'WEBHOOKS',
        icon: 'mdi-webhook',
        menu: 'remoteManagement',
        name: 'WebhookList',
        allowed: 'admin',
        component: () => import(/* webpackChunkName: "remoteManagement" */ /* webpackMode: "lazy" */ '@/pages/remoteManagement/webhooks/WebhookList.vue'),
    },
];

export default paths;
