const paths = [
    {
        path: '/item-family-migration',
        name: 'ItemFamilyMigration',
        title: 'ITEM_FAMILY_MIGRATION',
        component: () => import(/* webpackChunkName: "item" */ /* webpackMode: "lazy" */ '@/pages/item/ItemFamilyMigration.vue'),
    },
];

export default paths;
