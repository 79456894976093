import VueShortkey from 'vue-shortkey';
import aura from './aura/aura';
import * as components from './components/index';
import VueTelInputVuetify from './lib/vue-tel-input-vuetify';

const AuraVue = {
    install: function (vue, options = {}) {
        aura.initializeOptions(options, vue);
        vue.prototype.$_aura = aura;
        if (options.inputs) {
            if (options.inputs.autocomplete) {
                vue.prototype.$autocomplete = options.inputs.autocomplete;
            }
            if (options.inputs.select) {
                vue.prototype.$select = options.inputs.select;
            }
            if (options.inputs.combobox) {
                vue.prototype.$combobox = options.inputs.combobox;
            }
            if (options.inputs.editDialog && options.inputs.editDialog.util) {
                vue.prototype.$editDialog = options.inputs.editDialog.util;
            }
        }

        Object.keys(components).forEach((componentName) => {
            vue.component(componentName, components[componentName]);
        });

        vue.use(VueShortkey);
        vue.use(VueTelInputVuetify);
    },
};

export * from './components/index';
export default AuraVue;
