// eslint-disable-next-line max-classes-per-file
import Vue from 'vue';
/**
 * Field
 * @param {string | string[]} name - Request param name
 * @param {string} component - Component name that will render
 * @param {object} props - Component props
 * @param {object} props - Component props sync
 * @param {object} events - Component events
 * @param {any} value - Init value
 * @param {(value: any) => any} parser - Formatting for the request
 * @param {boolean} construct - v-if option
 * @param {boolean} disabled - Disable field for Filter Sidebar request
 * @param {IDependsOn[]} dependsOn - List of field listeners
 *
 */
export class Field {
    name;
    component;
    props;
    propsSync;
    events;
    parser;
    construct;
    disabled;
    dependsOn;
    _value;
    constructor(i) {
        this.name = i.name;
        this.component = i.component || undefined;
        this.props = i.props || {};
        this.propsSync = i.propsSync || {};
        this.events = i.events || {};
        this.value = i.value;
        this.parser = i.parser;
        this.construct = i.construct === undefined ? true : i.construct;
        this.disabled = i.disabled === undefined ? false : i.disabled;
        this.dependsOn = i.dependsOn || [];
        this.dependsOn.forEach((value) => {
            value.fieldName = typeof value.fieldName === 'string' ? [value.fieldName] : value.fieldName;
        });
    }
    onChange = () => Promise.resolve();
    async onSuccess(resolve) {
        return Promise.resolve(resolve);
    }
    get value() {
        return this._value;
    }
    set value(value) {
        this._value = value;
        this.onChange(value);
    }
}
/**
 * CallCommand
 * @param {string} name - Command Name
 * @param {CommandType} type - Command Type (SEARCH' | 'SAVE' | 'DELETE' | 'NONE')
 * @param {string} node - node
 * @param {object} config - Request configs
 * @param {Field[]} fields - Fields (filters)
 * @param {function} request - Default request function
 * @param {function} callCommand - Custom request function
 */
export class CallCommand {
    _name;
    _type;
    _node;
    _config;
    _fields;
    _values;
    _constructs;
    _methods;
    _initializeLoading;
    _showFilters;
    _sidebarProps;
    _debug;
    _noSetProvider;
    _disableds;
    _paramsRequested;
    _initValue;
    _customRequestParser;
    _paginate;
    _noResetPage;
    constructor(i) {
        this.name = i.name;
        this.type = i.type;
        this.node = i.node;
        this.config = i.config || {};
        this.fields = i.fields || {};
        this.methods = i.methods || {};
        this.initializeLoading = i.initializeLoading != null ? i.initializeLoading : true;
        this.debug = !!i.debug;
        this.showFilters = i.showFilters === undefined ? true : i.showFilters;
        this.sidebarProps = i.sidebarProps || {};
        this.noSetProvider = !!i.noSetProvider;
        this.paramsRequested = {};
        this.initValue = {};
        this.customRequestParser = i.customRequestParser;
        this.paginate = i.paginate != null ? i.paginate : false;
    }
    async onSuccess(resolve) {
        return Promise.resolve(resolve);
    }
    async onError(reject) {
        return Promise.resolve(reject);
    }
    async requireRequest() {
        return Promise.resolve(true);
    }
    async callCommand(param, config, debug, authorization) {
        let request = false;
        if (config) {
            request = !!config.request;
            if (Object.keys(config).includes('request')) {
                delete config.request;
            }
        }
        config = { ...this.config, ...config };
        debug = debug == null ? this.debug : debug;
        let params = {};
        if (config.type === 'navigation') {
            params = param;
        }
        else {
            if (this.paginate && !this._noResetPage) {
                this.resetPage();
            }
            else {
                this._noResetPage = false;
            }
            param = param || {};
            const fields = {};
            if (!request) {
                Object.keys(this.fields).forEach((key) => {
                    const field = this.fields[key];
                    if (!Object.keys(param).includes(key) && !field.disabled) {
                        fields[key] = field.value;
                    }
                });
            }
            param = { ...fields, ...param };
            if (this.customRequestParser) {
                params = this.customRequestParser.call(this, param);
            }
            else if (param) {
                Object.keys(param).forEach((key) => {
                    const field = this.fields[key];
                    if (field?.name) {
                        let obj = field.parser ? field.parser.call(param, param[key]) : param[key];
                        let names = [];
                        if (typeof field.name === 'string' && field.name) {
                            names.push(field.name);
                            obj = [obj];
                        }
                        else if (Array.isArray(field.name)) {
                            names.push(...field.name);
                        }
                        else {
                            names = [];
                            obj = [];
                        }
                        if (obj) {
                            Object.entries(names).forEach(([k, name]) => {
                                params[name] = obj[k];
                            });
                        }
                    }
                });
            }
        }
        try {
            this.paramsRequested = Vue.prototype.$lodash.cloneDeep(param);
            const result = await Vue.prototype.$_aura.callCommand(this.name, params, config, debug, authorization);
            return await this.onSuccess(result);
        }
        catch (error) {
            throw await this.onError(error);
        }
    }
    async request(noResetPage) {
        const resultRequireRequest = await this.requireRequest.call(this, this.fields, this.methods);
        if (resultRequireRequest != null && typeof resultRequireRequest !== 'string' && !!resultRequireRequest) {
            const param = {};
            if (this.paginate && !noResetPage) {
                this.resetPage();
            }
            else {
                this._noResetPage = true;
            }
            Object.keys(this.fields).forEach((key) => {
                const field = this.fields[key];
                if (!field.disabled) {
                    param[key] = field.value;
                }
            });
            return this.callCommand.call(this, param, { request: true });
        }
        if (resultRequireRequest && typeof resultRequireRequest === 'string') {
            Vue.prototype.$_aura.showErrorMessage(resultRequireRequest);
        }
        return Promise.reject(new Error());
    }
    resetPage() {
        Vue.prototype.$util.loadCommandFilter(this, [{ key: 'PAGE', value: 0 }]);
        const field = Vue.prototype.$util.getCommandPageField(this);
        if (field && !field.component) {
            const { handler } = field.dependsOn[0];
            if (handler) {
                handler();
            }
        }
    }
    setValuesInField() {
        const keysValues = Object.keys(this.values);
        Object.entries(this.fields).forEach(([key, value]) => {
            if (keysValues.includes(key)) {
                value.value = this.values[key];
            }
        });
    }
    setConstructsInField() {
        const keysConstructs = Object.keys(this.constructs);
        Object.entries(this.fields).forEach(([key, value]) => {
            value.construct = keysConstructs.includes(key) ? this.constructs[key] : false;
        });
    }
    setDisabledsInField() {
        const keysDisableds = Object.keys(this.disableds);
        Object.entries(this.fields).forEach(([key, value]) => {
            value.disabled = keysDisableds.includes(key) ? this.disableds[key] : false;
        });
    }
    get name() {
        return this._name;
    }
    set name(value) {
        this._name = value;
    }
    get type() {
        return this._type;
    }
    set type(value) {
        this._type = value;
    }
    get node() {
        return this._node;
    }
    set node(value) {
        this._node = value;
    }
    get config() {
        return this._config;
    }
    set config(value) {
        this._config = value;
    }
    get fields() {
        return this._fields;
    }
    set fields(value) {
        this._fields = value;
    }
    get values() {
        return this._values;
    }
    set values(value) {
        this._values = value;
        this.setValuesInField();
    }
    get constructs() {
        return this._constructs;
    }
    set constructs(value) {
        this._constructs = value;
        this.setConstructsInField();
    }
    get methods() {
        return this._methods;
    }
    set methods(value) {
        this._methods = value;
    }
    get initializeLoading() {
        return this._initializeLoading;
    }
    set initializeLoading(value) {
        this._initializeLoading = value;
    }
    get showFilters() {
        return this._showFilters;
    }
    set showFilters(value) {
        this._showFilters = value;
    }
    get sidebarProps() {
        return this._sidebarProps;
    }
    set sidebarProps(value) {
        this._sidebarProps = value;
    }
    get debug() {
        return this._debug;
    }
    set debug(value) {
        this._debug = value;
    }
    get noSetProvider() {
        return this._noSetProvider;
    }
    set noSetProvider(value) {
        this._noSetProvider = value;
    }
    get disableds() {
        return this._disableds;
    }
    set disableds(value) {
        this._disableds = value;
        this.setDisabledsInField();
    }
    get paramsRequested() {
        return this._paramsRequested;
    }
    set paramsRequested(value) {
        this._paramsRequested = value;
    }
    get initValue() {
        return this._initValue;
    }
    set initValue(value) {
        this._initValue = value;
    }
    get customRequestParser() {
        return this._customRequestParser || undefined;
    }
    set customRequestParser(value) {
        this._customRequestParser = value || undefined;
    }
    get paginate() {
        return this._paginate;
    }
    set paginate(value) {
        this._paginate = value;
    }
}
