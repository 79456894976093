<script>
    import { getBooleanFromProp } from '../../util/helpers';

    export default {
        name: 'ATimePicker',
        functional: true,
        render: function (createElement, { data, children }) {
            data.attrs = data.attrs || {};
            if (Object.keys(data.attrs).indexOf('text-field') !== -1 && getBooleanFromProp(data.attrs['text-field'])) {
                return createElement('ATimePickerCustom', data, children);
            }

            return createElement('VTimePicker', data, children);
        },
    };
</script>
