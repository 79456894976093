const paths = [
  {
      path: '/standard-composition-list',
      title: 'STANDARD_COMPOSITION_LIST',
      name: 'StandardCompositionList',
      icon: 'fa fa-hard-hat',
      menu: 'maintenance',
      categories: [
        'configurations',
      ],
      component: () => import(/* webpackChunkName: "standardComposition" */ /* webpackMode: "lazy" */ '@/pages/standardComposition/StandardCompositionList.vue'),
  },
  {
      path: '/standard-composition-details',
      title: 'STANDARD_COMPOSITION_DETAILS',
      name: 'StandardCompositionDetails',
      favCategory: 'registration',
      component: () => import(/* webpackChunkName: "standardComposition" */ /* webpackMode: "lazy" */ '@/pages/standardComposition/StandardCompositionDetails.vue'),
  },
  {
      path: '/standard-composition-wizard',
      title: 'STANDARD_COMPOSITION_WIZARD',
      name: 'StandardCompositionWizard',
      component: () => import(/* webpackChunkName: "standardComposition" */ /* webpackMode: "lazy" */ '@/pages/standardComposition/StandardCompositionWizard.vue'),
  },
  {
      path: '/standard-composition-details-replace-assets-structure',
      title: 'REPLACE_ASSETS_STRUCTURE',
      name: 'StandardCompositionDetailsReplaceAssetsStructure',
      component: () => import(/* webpackChunkName: "standardComposition" */ /* webpackMode: "lazy" */ '@/pages/standardComposition/StandardCompositionDetailsReplaceAssetsStructure.vue'),
  },
  {
      path: '/standard-composition-details-clone',
      title: 'CLONE_ASSET_STRUCTURE',
      name: 'StandardCompositionDetailsClone',
      component: () => import(/* webpackChunkName: "standardComposition" */ /* webpackMode: "lazy" */ '@/pages/standardComposition/StandardCompositionDetailsClone.vue'),
  },
];

export default paths;
