const paths = [
    {
        path: '/maintenance-status-reason-solution-link',
        name: 'MaintenanceStatusReasonSolutionLink',
        title: 'STATUS_REASON_SOLUTION_LINK',
        icon: 'fa fa-link',
        menu: 'maintenance',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "maintenanceStatusReasonSolutionLink" */ /* webpackMode: "lazy" */ '@/pages/maintenanceStatusReasonSolutionLink/MaintenanceStatusReasonSolutionLink.vue'),
    },
    {
      path: '/maintenance-photo-configuration',
      name: 'MaintenancePhotoConfiguration',
      component: () => import(/* webpackChunkName: "maintenanceStatusReasonSolutionLink" */ /* webpackMode: "lazy" */ '@/pages/maintenanceStatusReasonSolutionLink/MaintenancePhotoConfiguration.vue'),
  },
];

export default paths;
