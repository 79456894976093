const paths = [
    {
      path: '/custom_map',
      title: 'CUSTOM_MAP',
      name: 'CustomMap',
      component: () => import(/* webpackChunkName: "customMap" */ /* webpackMode: "lazy" */ '@/components/core/customMap/CustomMap.vue'),
    },
];

export default paths;
