import i18n from '@/i18n';
import { pickBy } from 'lodash';
import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import { showErrorsResponse, parseSuccessResponse } from '@/pages/remoteManagement/services/utils';
import store from '../store';
export class GetDeviceGroups extends CallCommand {
    constructor() {
        super({
            name: 'get_comp_device-groups',
            type: 'NONE',
            node: 'RESPONSE',
            fields: {
                companyId: new Field({
                    name: 'companyId',
                    value: null,
                    component: 'a-select',
                    props: {
                        items: store.state.companies,
                        label: i18n.t('COMPANY'),
                        itemText: 'company_name',
                        itemValue: 'company_id',
                        clearable: true,
                        hideDetails: true,
                    },
                }),
                hideDeleted: new Field({
                    name: 'deleted_at',
                    value: true,
                }),
            },
            customRequestParser: function (params) {
                const filters = pickBy(params, (v) => !!v);
                let deleted_at = null;
                if (filters.hideDeleted) {
                    deleted_at = { eq: null };
                }
                const body = {
                    filter: JSON.stringify({
                        where: {
                            deleted_at: deleted_at,
                            company_id: filters.companyId,
                        },
                    }),
                };
                const query = new URLSearchParams(body);
                return {
                    BODY: query.toString(),
                };
            },
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        resolve.RAIZ.RESPONSE = response;
        return resolve;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class SaveDeviceGroup extends CallCommand {
    constructor() {
        super({
            name: 'post_comp_device-groups',
            type: 'SAVE',
            fields: {
                description: new Field({
                    name: 'group_desc',
                }),
            },
            customRequestParser: function (params) {
                const data = {
                    group_desc: params.description,
                    company_id: params.companyId || undefined,
                };
                return {
                    BODY: JSON.stringify(data),
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class EditDeviceGroup extends CallCommand {
    constructor() {
        super({
            name: 'patch_comp_device-groups_0',
            type: 'SAVE',
            fields: {
                deviceGroupId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
                groupDescription: new Field({
                    name: 'BODY',
                    parser: (value) => JSON.stringify({ group_desc: value }),
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class DeleteDeviceGroup extends CallCommand {
    constructor() {
        super({
            name: 'delete_comp_device-groups_0',
            type: 'DELETE',
            fields: {
                deviceGroupId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
