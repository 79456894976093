const paths = [
    {
        path: '/page_constructors',
        name: 'PageConstructors',
        title: 'PAGE_CONSTRUCTOR',
        menu: 'customization',
        icon: 'mdi-cube-scan',
        component: () => import(/* webpackChunkName: "pageConstructor" */ /* webpackMode: "lazy" */ '@/pages/pageConstructor/PageConstructorList.vue'),
    },
    {
        path: '/page_constructor_details',
        title: 'PAGE_CONSTRUCTOR_DETAILS',
        name: 'PageConstructorDetails',
        favCategory: 'customization',
        component: () => import(/* webpackChunkName: "pageConstructor" */ /* webpackMode: "lazy" */ '@/pages/pageConstructor/PageConstructorDetails.vue'),
    },
];

export default paths;
