import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import { parseSuccessResponse } from '../utils';
export class SaveHardwareAlertTypeAction extends CallCommand {
    constructor() {
        super({
            name: 'put_comp_hardware-alert-type-action_0',
            type: 'NONE',
            fields: {
                hardwareAlertTypeId: new Field({
                    name: 'HARDWARE_ALERT_TYPE_ID',
                }),
                hardwareAlertTypeAction: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
            },
            customRequestParser: ({ hardwareAlertTypeId, hardwareAlertTypeAction }) => {
                const pathParams = `{ PARAM_0: ${hardwareAlertTypeId.toString()}}`;
                return {
                    BODY: JSON.stringify(hardwareAlertTypeAction),
                    PATH_PARAMS: pathParams,
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
