const paths = [
    {
        path: '/contributor-function-list',
        name: 'ContributorFunctionList',
        title: 'POSITIONS_EMPLOYEES',
        icon: 'fa fa-user-circle',
        menu: 'humanResources',
        component: () => import(/* webpackChunkName: "positions" */ /* webpackMode: "lazy" */ '@/pages/positions/ContributorFunctionList.vue'),

    },
    {
        path: '/contributor-function',
        name: 'ContributorFunction',
        component: () => import(/* webpackChunkName: "positions" */ /* webpackMode: "lazy" */ '@/pages/positions/ContributorFunction.vue'),
    },
    {
        path: '/certificate-type-list',
        name: 'CertificateTypeList',
        component: () => import(/* webpackChunkName: "positions" */ /* webpackMode: "lazy" */ '@/pages/positions/CertificateTypeList.vue'),
    },
    {
        path: '/wage-policy',
        name: 'WagePolicy',
        component: () => import(/* webpackChunkName: "positions" */ /* webpackMode: "lazy" */ '@/pages/positions/WagePolicy.vue'),
    },
    {
        path: '/wage-policy-details',
        name: 'WagePolicyDetails',
        component: () => import(/* webpackChunkName: "positions" */ /* webpackMode: "lazy" */ '@/pages/positions/WagePolicyDetails.vue'),
    },
];

export default paths;
