const paths = [
    {
        path: '/policy-list',
        name: 'Policy',
        title: 'POLICY_LIST',
        icon: 'fa fa-briefcase',
        menu: 'contracts',
        component: () => import(/* webpackChunkName: "contractPolicy" */ /* webpackMode: "lazy" */ '@/pages/contractPolicy/Policy.vue'),
    },
    {
        path: '/policy-details',
        name: 'PolicyDetails',
        title: 'POLICY_DETAILS',
        component: () => import(/* webpackChunkName: "contractPolicy" */ /* webpackMode: "lazy" */ '@/pages/contractPolicy/PolicyDetails.vue'),
    },
];

export default paths;
