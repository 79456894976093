const paths = [
    {
        path: '/material-dispensation-list',
        name: 'MaterialDispensationList',
        title: 'MATERIAL_DISPENSATION',
        icon: 'fas fa-truck-loading',
        menu: 'stock',
        component: () => import(/* webpackChunkName: "stock" */ /* webpackMode: "lazy" */ '@/pages/stock/MaterialDispensationList.vue'),
    },
    {
        path: '/material-dispensation-list-return',
        name: 'MaterialDispensationListReturn',
        title: 'MATERIAL_RETURN',
        icon: 'fas fa-truck-loading',
        menu: 'stock',
        component: () => import(/* webpackChunkName: "stock" */ /* webpackMode: "lazy" */ '@/pages/stock/MaterialDispensationReturn.vue'),
    },
    {
        path: '/material-inventory-list',
        name: 'MaterialInventoryList',
        title: 'MATERIAL_INVENTORY',
        icon: 'fas fa-clipboard',
        menu: 'stock',
        component: () => import(/* webpackChunkName: "stock" */ /* webpackMode: "lazy" */ '@/pages/stock/MaterialInventoryList.vue'),
    },
    {
        path: '/material-special-movement-list',
        name: 'MaterialSpecialMovementList',
        title: 'MATERIAL_SPECIAL_MOVEMENT',
        icon: 'fas fa-clipboard',
        menu: 'stock',
        component: () => import(/* webpackChunkName: "stock" */ /* webpackMode: "lazy" */ '@/pages/stock/MaterialSpecialMovementList.vue'),
    },
    {
        path: '/physical_stock_list',
        name: 'PhysicalStockList',
        title: 'PHYSICAL_STOCK_LIST',
        icon: 'fas fa-clipboard',
        menu: 'stock',
        component: () => import(/* webpackChunkName: "stock" */ /* webpackMode: "lazy" */ '@/pages/stock/PhysicalStockList.vue'),
    },
    {
        path: '/historical_material_handling',
        name: 'HistoricalMaterialHandling',
        title: 'HISTORICAL_MATERIAL_HANDLING',
        component: () => import(/* webpackChunkName: "stock" */ /* webpackMode: "lazy" */ '@/pages/stock/HistoricalMaterialHandling.vue'),
    },
    {
        path: '/material_physical_flow',
        name: 'MaterialPhysicalFlow',
        title: 'MATERIAL_PHYSICAL_FLOW',
        component: () => import(/* webpackChunkName: "stock" */ /* webpackMode: "lazy" */ '@/pages/stock/MaterialPhysicalFlow.vue'),
    },
    {
        path: '/material-dispensation',
        name: 'MaterialDispensation',
        title: 'MATERIAL_DISPENSATION',
        component: () => import(/* webpackChunkName: "stock" */ /* webpackMode: "lazy" */ '@/pages/stock/MaterialDispensation.vue'),
    },
    {
        path: '/stock_team_contributor',
        name: 'StockTeamContributor',
        title: 'STOCK_TEAM_CONTRIBUTOR',
        icon: 'fas fa-clipboard',
        menu: 'stock',
        component: () => import(/* webpackChunkName: "stock" */ /* webpackMode: "lazy" */ '@/pages/stock/StockTeamContributor.vue'),
    },
    {
        path: '/stock_team_contributor_historical_stock_detail',
        name: 'StockTeamContributorHistoricalStockDetail',
        title: 'HANDLING_DETAILS',
        component: () => import(/* webpackChunkName: "stock" */ /* webpackMode: "lazy" */ '@/pages/stock/StockTeamContributorHistoricalStockDetail.vue'),
    },
    {
        path: '/material_special_movement',
        name: 'MaterialSpecialMovement',
        title: 'SPECIAL_STOCK_MOVEMENT',
        component: () => import(/* webpackChunkName: "stock" */ /* webpackMode: "lazy" */ '@/pages/stock/MaterialSpecialMovement.vue'),
    },
    {
        path: '/material-moviment-details',
        name: 'MaterialMovimentDetails',
        title: 'MOVEMENT',
        component: () => import(/* webpackChunkName: "stock" */ /* webpackMode: "lazy" */ '@/pages/stock/MaterialMovimentDetails.vue'),
    },
    {
        path: '/material_special_movement_items_wizzard',
        name: 'MaterialSpecialMovementItemsWizzard',
        title: 'ITEMS_INCLUSION',
        component: () => import(/* webpackChunkName: "stock" */ /* webpackMode: "lazy" */ '@/pages/stock/MaterialSpecialMovementItemsWizzard.vue'),
    },
    {
        path: '/material-inventory-wizard',
        name: 'MaterialInventoryWizard',
        title: 'NEW_INVENTORY',
        component: () => import(/* webpackChunkName: "stock" */ /* webpackMode: "lazy" */ '@/pages/stock/MaterialInventoryWizard.vue'),
    },
    {
        path: '/material-inventory-details',
        name: 'MaterialInventoryDetails',
        title: 'INVENTORY',
        favCategory: 'stock',
        component: () => import(/* webpackChunkName: "stock" */ /* webpackMode: "lazy" */ '@/pages/stock/MaterialInventoryDetails.vue'),
    },
    {
        path: '/material-dispensation-separated-material',
        name: 'MaterialDispensationSeparatedMaterial',
        title: 'SEPARATED_MATERIAL',
        component: () => import(/* webpackChunkName: "stock" */ /* webpackMode: "lazy" */ '@/pages/stock/MaterialDispensationSeparatedMaterial.vue'),
    },
    {
        path: '/material-dispensation-kit',
        name: 'MaterialDispensationKit',
        title: 'KITS',
        component: () => import(/* webpackChunkName: "stock" */ /* webpackMode: "lazy" */ '@/pages/stock/MaterialDispensationKit.vue'),
    },
    {
        path: '/material-dispensation-suggestion',
        name: 'MaterialDispensationSuggestion',
        title: 'SUGGESTIONS',
        component: () => import(/* webpackChunkName: "stock" */ /* webpackMode: "lazy" */ '@/pages/stock/MaterialDispensationSuggestion.vue'),
    },
    {
        path: '/material-dispensation-wizard',
        name: 'MaterialDispensationWizard',
        title: 'MATERIAL_DISPENSATION',
        component: () => import(/* webpackChunkName: "stock" */ /* webpackMode: "lazy" */ '@/pages/stock/MaterialDispensationWizard.vue'),
    },
    {
        path: '/material-dispensation-confirm-delivered',
        name: 'MaterialDispensationConfirmDelivered',
        title: 'ADJUSTING_RESERVED_QUANTITY',
        component: () => import(/* webpackChunkName: "stock" */ /* webpackMode: "lazy" */ '@/pages/stock/MaterialDispensationConfirmDelivered.vue'),
    },
    {
        path: '/material-dispensation-confirm-applied',
        name: 'MaterialDispensationConfirmApplied',
        title: 'ADJUSTING_APPLIED_QUANTITY',
        component: () => import(/* webpackChunkName: "stock" */ /* webpackMode: "lazy" */ '@/pages/stock/MaterialDispensationConfirmApplied.vue'),
    },
    {
        path: '/material-dispensation-confirm-request',
        name: 'MaterialDispensationConfirmRequest',
        title: 'ADJUSTING_RESERVED_QUANTITY',
        component: () => import(/* webpackChunkName: "stock" */ /* webpackMode: "lazy" */ '@/pages/stock/MaterialDispensationConfirmRequest.vue'),
    },
    {
        path: '/auxiliary-stock-movement',
        name: 'AuxiliaryStockMovement',
        title: 'ADJUSTING_STOCK_QUANTITY',
        component: () => import(/* webpackChunkName: "stock" */ /* webpackMode: "lazy" */ '@/pages/stock/AuxiliaryStockMovement.vue'),
    },
    {
        path: '/closing-materials',
        name: 'ClosingMaterials',
        title: 'INCLUDE_MATERIAL_CLOSING',
        icon: 'mdi-clipboard-check-outline',
        menu: 'stock',
        component: () => import(/* webpackChunkName: "stock" */ /* webpackMode: "lazy" */ '@/pages/stock/ClosingMaterials.vue'),
    },
    {
        path: '/closing-materials-list',
        name: 'ClosingMaterialsList',
        icon: 'fas fa-clipboard',
        menu: 'stock',
        title: 'CLOSING_MATERIAL_LIST',
        component: () => import(/* webpackChunkName: "stock" */ /* webpackMode: "lazy" */ '@/pages/stock/ClosingMaterialsList.vue'),
    },
    {
        path: '/historical-material-moviment',
        name: 'HistoricalMaterialMoviment',
        icon: 'fas fa-clipboard',
        menu: 'stock',
        title: 'MOVEMENT_MATERIALS',
        component: () => import(/* webpackChunkName: "stock" */ /* webpackMode: "lazy" */ '@/pages/stock/HistoricalMaterialMoviment.vue'),
    },
    {
        path: '/qr-code-model-list',
        name: 'QRCodeModelList',
        icon: 'fas fa-qrcode',
        menu: 'stock',
        title: 'QRCODE_MODELS',
        component: () => import(/* webpackChunkName: "stock" */ /* webpackMode: "lazy" */ '@/pages/stock/QRCodeModelList.vue'),
    },
    {
        path: '/qr-code-model-data',
        name: 'QRCodeModelData',
        title: 'QRCODE_MODEL',
        component: () => import(/* webpackChunkName: "stock" */ /* webpackMode: "lazy" */ '@/pages/stock/QRCodeModelData.vue'),
    },
    {
        path: '/qr-code-model-fields',
        name: 'QrCodeModelFields',
        title: 'QRCODE_MODEL',
        component: () => import(/* webpackChunkName: "stock" */ /* webpackMode: "lazy" */ '@/pages/stock/QrCodeModelFields.vue'),
    },
];

export default paths;
