const paths = [
  {
      path: '/material-loan-devolution-list',
      title: 'WORKSITE_MATERIAL_LOAN_DEVOLUTION',
      name: 'WorksiteMaterialLoanDevolutionList',
      menu: 'stock',
      categories: [
        'reports',
      ],
      component: () => import(/* webpackChunkName: "materialLoan" */ /* webpackMode: "lazy" */ '@/pages/materialLoan/WorksiteMaterialLoanDevolutionList.vue'),
  },
  {
    path: '/material-loan-devolution-details',
    title: 'INCLUDE_WORKSITE_MATERIAL_LOAN_DEVOLUTION',
    name: 'WorksiteMaterialLoanDevolutionDetails',
    component: () => import(/* webpackChunkName: "materialLoan" */ /* webpackMode: "lazy" */ '@/pages/materialLoan/WorksiteMaterialLoanDevolutionDetails.vue'),
  },
];

export default paths;
