import i18n from '@/i18n';
import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import store from '../store';
import { parseSuccessResponse, showErrorsResponse } from '../utils';
export class GetSchedules extends CallCommand {
    constructor() {
        super({
            name: 'get_schedule',
            type: 'NONE',
            node: 'RESPONSE',
            fields: {
                include: new Field({
                    name: 'include',
                    value: [
                        { relation: 'company' },
                    ],
                }),
                companyId: new Field({
                    name: 'company_id',
                    value: null,
                    component: 'a-select',
                    props: {
                        items: store.state.companies,
                        label: i18n.t('COMPANY'),
                        itemText: 'company_name',
                        itemValue: 'company_id',
                        clearable: true,
                    },
                }),
                hideDeleted: new Field({
                    name: 'deleted_at',
                    value: true,
                }),
            },
            customRequestParser: function (params) {
                const filter = {
                    where: {},
                };
                if (params.hideDeleted) {
                    filter.where.deleted_at = { eq: null };
                }
                if (params.companyId) {
                    filter.where.company_id = params.companyId;
                }
                if (params.include) {
                    filter.include = params.include;
                }
                return {
                    BODY: `filter=${encodeURIComponent(JSON.stringify(filter))}`,
                };
            },
        });
    }
    async onSuccess(resolve) {
        const { response } = await parseSuccessResponse(resolve);
        resolve.RAIZ.RESPONSE = response;
        return resolve;
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class GetScheduleById extends CallCommand {
    constructor() {
        super({
            name: 'get_schedule_0',
            type: 'NONE',
            fields: {
                scheduleId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
                filter: new Field({
                    name: 'BODY',
                    parser: (value) => `filter=${encodeURIComponent(JSON.stringify(value))}`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class GetScheduleStatus extends CallCommand {
    constructor() {
        super({
            name: 'get_schedule_status',
            type: 'NONE',
            fields: {},
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class SaveSchedule extends CallCommand {
    constructor() {
        super({
            name: 'post_schedule',
            type: 'SAVE',
            fields: {
                schedule: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class EditSchedule extends CallCommand {
    constructor() {
        super({
            name: 'patch_schedule_0',
            type: 'SAVE',
            fields: {
                scheduleId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
                schedule: new Field({
                    name: 'BODY',
                    parser: JSON.stringify,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class CloneSchedule extends CallCommand {
    constructor() {
        super({
            name: 'post_schedule_clone_0',
            type: 'SAVE',
            fields: {
                scheduleId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class DeleteSchedule extends CallCommand {
    constructor() {
        super({
            name: 'delete_schedule_0',
            type: 'DELETE',
            fields: {
                scheduleId: new Field({
                    name: 'PATH_PARAMS',
                    parser: (value) => `{ PARAM_0: ${String(value)} }`,
                }),
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
