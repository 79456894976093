export default {
    state: {
        inactivityTime: null,
    },
    getters: {
        getInactivityTime: state => state.inactivityTime,
    },
    mutations: {
        SET_INACTIVITY_TIME: function (state, value) {
            state.inactivityTime = value;
        },
    },
    actions: {
        SET_INACTIVITY_TIME_ACTION: function ({ commit }, value) {
            commit('SET_INACTIVITY_TIME', value);
        },
    },
};
