const paths = [
    {
        path: '/maintenance-schedule-list',
        name: 'MaintenanceScheduleList',
        title: 'MAINTENANCE_SCHEDULES',
        icon: 'event',
        menu: 'fleet',
        favCategory: 'fleet',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "fleet" */ /* webpackMode: "lazy" */ '@/pages/fleet/maintenanceSchedule/MaintenanceScheduleList.vue'),
    },
    {
        path: '/maintenance-schedule-general',
        name: 'MaintenanceScheduleGeneral',
        title: 'MAINTENANCE_SCHEDULE',
        component: () => import(/* webpackChunkName: "fleet" */ /* webpackMode: "lazy" */ '@/pages/fleet/maintenanceSchedule/MaintenanceScheduleGeneral.vue'),
    },
    {
        path: '/preventive-maintenance-revision-list',
        name: 'PreventiveMaintenanceRevisionList',
        title: 'PREVENTIVE_MAINTENANCES_REVISIONS',
        component: () => import(/* webpackChunkName: "fleet" */ /* webpackMode: "lazy" */ '@/pages/fleet/maintenanceSchedule/PreventiveMaintenanceRevisionList.vue'),
    },
];

export default paths;
