import i18n from '@/i18n';

export default function materialRequestStatusCustom (provider) {
    return new Promise((resolve) => {
        const resultProvider = [];
        resultProvider.push({ ID_STATUS_SOLIC_MATERIAL: -2, DESC_STATUS_SOLIC_MATERIAL: i18n.t('ALL_ACTIVE') });
        resultProvider.push({ ID_STATUS_SOLIC_MATERIAL: -1, DESC_STATUS_SOLIC_MATERIAL: i18n.t('ALL') });

        provider.forEach((item) => {
            resultProvider.push({ ID_STATUS_SOLIC_MATERIAL: item.ID_STATUS_SOLIC_MATERIAL, DESC_STATUS_SOLIC_MATERIAL: item.DESC_STATUS_SOLIC_MATERIAL });
        });

        resolve(resultProvider);
    });
}
