const paths = [
    {
        path: '/purchase-order-permission-list',
        name: 'PurchaseOrderPermissionList',
        title: 'PURCHASE_ORDER_PERMISSIONS',
        icon: 'fa fa-briefcase',
        menu: 'shopping',
        categories: [
            'configurations',
        ],
        component: () => import(/* webpackChunkName: "purchaseOrderPermission" */ /* webpackMode: "lazy" */ '@/pages/purchaseOrderPermission/PurchaseOrderPermissionList.vue'),
    },
    {
        path: '/purchase-order-permission',
        name: 'PurchaseOrderPermission',
        title: 'PURCHASE_ORDER_PERMISSION',
        favCategory: 'shopping',
        component: () => import(/* webpackChunkName: "purchaseOrderPermission" */ /* webpackMode: "lazy" */ '@/pages/purchaseOrderPermission/PurchaseOrderPermission.vue'),
    },
];

export default paths;
